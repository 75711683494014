define("teelt-frontend/helpers/table/complex-filter-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.complexFilterLabel = complexFilterLabel;
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function complexFilterLabel(_ref
  /*, hash*/
  ) {
    var _ref2 = _slicedToArray(_ref, 1),
        filter = _ref2[0];

    if (filter.valueLabel) return filter.valueLabel;

    if (Array.isArray(filter.value1)) {
      return filter.value1.map(function (v) {
        return v.label;
      }).join(', ');
    }

    if (filter.value1 && _typeof(filter.value1) === 'object' && filter.value1.constructor === Object) {
      return filter.value1.label;
    }

    return filter.value1;
  }

  var _default = Ember.Helper.helper(complexFilterLabel);

  _exports.default = _default;
});