define("teelt-frontend/components/talents/talent-team", ["exports", "teelt-frontend/config/environment", "filterizr", "jquery"], function (_exports, _environment, _filterizr, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talent-team'],
    defaultProfileImageUrl: _environment.default.APP.DEFAULT_PROFILE_IMAGE_URL,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.addObserver(this, 'team', this, 'initFilterizr');
      this.initFilterizr();
    },
    destroy: function destroy() {
      Ember.removeObserver(this, 'team', this, 'initFilterizr');

      this._super.apply(this, arguments);
    },
    initFilterizr: function initFilterizr() {
      var _this = this;

      Ember.run.later(function () {
        var options = {
          animationDuration: 0.3,
          multifilterLogicalOperator: 'or',
          gutterPixels: 3
        };
        var filterizr = new _filterizr.default('.members', options);
        Ember.set(_this, "filterizr", filterizr);

        _this.send('searchMember', _this.search);
      }, 500);
    },
    actions: {
      searchMember: function searchMember(value) {
        Ember.set(this, 'search', value);
        value = value ? value.toLowerCase().trim() : undefined;
        var selected = 'all';

        if (value && value.length) {
          selected = [];

          if (this.team.users) {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = this.team.users[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var user = _step.value;
                var name = (user.firstname ? user.firstname.toLowerCase() : '') + ' ' + (user.lastname ? user.lastname.toLowerCase() : '');

                if (name.indexOf(value) >= 0 || user.username.toLowerCase().indexOf(value) >= 0 || user.position && user.position.name.toLowerCase().indexOf(value) >= 0) {
                  selected.push('' + user.id);
                }
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }
          }
        }

        var options = this.filterizr.options;
        options.filter = selected;
        this.filterizr.setOptions(options);

        if (this.onSearch) {
          this.onSearch(value);
        }
      }
    }
  });

  _exports.default = _default;
});