define("teelt-frontend/templates/components/form/field/field-opinion-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FD8QNypu",
    "block": "{\"symbols\":[\"mark\"],\"statements\":[[7,\"div\"],[11,\"class\",\"opinion-marks\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"make-array-from-to\",[[23,[\"field\",\"properties\",\"fromValue\"]],[23,[\"field\",\"properties\",\"toValue\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[12,\"class\",[28,[\"opinion-mark \",[27,\"if\",[[27,\"equals\",[[23,[\"value\"]],[22,1,[]]],null],\"selected\"],null]]]],[3,\"action\",[[22,0,[]],\"onMarkClick\",[22,1,[]]]],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"opinon-mark-layout\"],[9],[0,\"\\n          \"],[1,[22,1,[]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"opinion-labels clear\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"field\",\"properties\",\"labels\",\"left\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"opinion-label left-label\"],[9],[1,[23,[\"field\",\"properties\",\"labels\",\"left\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"field\",\"properties\",\"labels\",\"right\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"opinion-label right-label\"],[9],[1,[23,[\"field\",\"properties\",\"labels\",\"right\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"haveError\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"helper-text g-error\"],[9],[1,[23,[\"field\",\"validationError\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/form/field/field-opinion-preview.hbs"
    }
  });

  _exports.default = _default;
});