define("teelt-frontend/routes/board/form/form-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        formId: params.form_id,
        form: this.store.findRecord('form', params.form_id, {
          reload: true
        })
      });
    },
    actions: {
      close: function close() {
        this.transitionTo('board.templates.forms');
      }
    }
  });

  _exports.default = _default;
});