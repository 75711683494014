define("teelt-frontend/templates/components/form/field/complex/field-social-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XTP58svn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"input-field\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"value\",\"class\",\"maxlength\",\"disabled\",\"focus-out\"],[[23,[\"value\"]],[27,\"if\",[[27,\"or\",[[23,[\"haveError\"]],[23,[\"haveCustomError\"]]],null],\"invalid\"],null],\"25\",[27,\"and\",[[23,[\"responseMode\"]],[27,\"not\",[[23,[\"canEdit\"]]],null]],null],[27,\"action\",[[22,0,[]],\"onFocusOut\"],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[27,\"or\",[[23,[\"haveError\"]],[23,[\"haveCustomError\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"helper-text g-error\"],[9],[1,[23,[\"field\",\"validationError\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/form/field/complex/field-social-number.hbs"
    }
  });

  _exports.default = _default;
});