define("teelt-frontend/routes/board/emails", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    userService: Ember.inject.service(),
    session: Ember.inject.service(),
    getSelectedMenuFromTransition: function getSelectedMenuFromTransition(transition) {
      var selectedMenu = 0;

      if (transition && transition.targetName === 'board.emails.notifications-system-list') {
        selectedMenu = 1;
      }

      return selectedMenu;
    },
    model: function model(params, transition) {
      var selectedMenu = this.getSelectedMenuFromTransition(transition);
      var sliderMenu = [{
        name: this.intl.t('emails.menu.notifications'),
        route: "board.emails.notifications-list"
      }, {
        name: this.intl.t('emails.menu.notifications_system'),
        route: "board.emails.notifications-system-list"
      }];

      if (this.userService.hasAnyRole(this.session.data.authenticated.user, 'ROLE_ADMIN')) {
        sliderMenu = [{
          name: this.intl.t('emails.menu.notifications_system'),
          route: "board.emails.notifications-system-list"
        }];
        selectedMenu = 0;
      }

      return {
        selectedMenu: selectedMenu,
        sliderMenu: sliderMenu
      };
    },
    actions: {
      willTransition: function willTransition(transition) {
        var selectedMenu = this.getSelectedMenuFromTransition(transition);
        Ember.set(this.currentModel, "selectedMenu", selectedMenu);
      }
    }
  });

  _exports.default = _default;
});