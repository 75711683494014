define("teelt-frontend/templates/components/talents/modal/talents-update-date-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YnEjeT3q",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"modal-dialog\",null,[[\"onClose\",\"translucentOverlay\",\"containerClass\",\"onClickOverlay\"],[[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"show\"]]],null],false],null],true,\"talents-action-modal\",[27,\"action\",[[22,0,[]],\"onClickOverlay\"],null]]],{\"statements\":[[7,\"div\"],[11,\"class\",\"modal-container\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"btn-close btn-transparent foreground-primary-color\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"show\"]]],null],false]],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"close\"],[10],[10],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"foreground-primary-color\"],[9],[0,\" \"],[1,[21,\"title\"],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"inner-container talent-update-welcomedate-container\"],[9],[0,\"\\n        \"],[1,[27,\"talents/talent-date-edit\",null,[[\"talent\",\"dateKey\",\"label1\"],[[23,[\"talent\"]],[23,[\"dateKey\"]],[23,[\"label\"]]]]],false],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"controls right\"],[9],[0,\"\\n            \"],[7,\"a\"],[11,\"class\",\"btn-flat border-primary-color foreground-primary-color btn-rounded waves-effect waves-light\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"show\"]]],null],false]],[9],[1,[27,\"t\",[\"word.cancel\"],null],false],[10],[0,\"\\n            \"],[7,\"a\"],[11,\"class\",\"btn background-primary-color btn-rounded waves-effect waves-light\"],[3,\"action\",[[22,0,[]],\"save\"]],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"word.modify\"],null]],null],false],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/modal/talents-update-date-modal.hbs"
    }
  });

  _exports.default = _default;
});