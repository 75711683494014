define("teelt-frontend/components/form/forms-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['forms-list'],
    classNameBindings: ['displayMode:no-orders'],
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    formZipService: Ember.inject.service(),
    offset: 0,
    limit: 10,
    canLoadMore: false,
    search: '',
    order: undefined,
    init: function init() {
      this._super.apply(this, arguments);

      this.store.unloadAll('form');

      if (this.forms) {
        this.set('canLoadMore', false);
        Ember.set(this, 'displayMode', true);
      } else {
        this.send("loadMore");
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.eventBus.on('SCROLLBOTTOM', this, 'onScrollBottom');
    },
    destroy: function destroy() {
      this.eventBus.off('SCROLLBOTTOM', this, 'onScrollBottom');

      this._super.apply(this, arguments);
    },
    onScrollBottom: function onScrollBottom() {
      if (this.canLoadMore) {//this.send("loadMore");
      }
    },
    initSelected: function initSelected() {
      var _this = this;

      if (!this.selectedIds) return;
      this.forms.forEach(function (f) {
        if (_this.selectedIds.includes(Number(f.id))) {
          Ember.set(f, 'selected', true);
        }
      });
    },
    actions: {
      reloadAfterOrder: function reloadAfterOrder() {
        this.set('forms', undefined);
        this.set('offset', 0);
        this.set('canLoadMore', false);
        this.send('loadMore');
      },
      searchForms: function searchForms(search) {
        this.set('search', search);
        this.set('forms', undefined);
        this.set('offset', 0);
        this.set('canLoadMore', false);
        this.send('loadMore');
      },
      loadMore: function loadMore() {
        var _this2 = this;

        var orderType = "id";
        var orderDirection = "DESC";

        if (this.order && this.order.type) {
          orderType = this.order.type;
          orderDirection = this.order.direction;
        }

        this.store.query('form', {
          offset: this.offset,
          limit: this.limit,
          search: this.search,
          orderType: orderType,
          orderDirection: orderDirection,
          adminForms: this.adminForms
        }).then(function (forms) {
          Ember.set(_this2, "canLoadMore", true);
          var formsArray = forms.toArray();
          var currentForms = _this2.forms;

          if (!currentForms) {
            currentForms = formsArray;
          } else if (formsArray.length > 0) {
            currentForms = currentForms.concat(formsArray);
          }

          if (formsArray.length < _this2.limit) {
            Ember.set(_this2, "canLoadMore", false);
          }

          Ember.set(_this2, 'forms', currentForms);
          _this2.offset += _this2.limit;

          _this2.initSelected();
        });
      },
      openForm: function openForm(form) {
        if (this.displayMode) return;

        if (this.assignMode) {
          Ember.set(form, 'selected', !form.selected);

          if (this.selectedIds) {
            if (form.selected) {
              this.selectedIds.addObject(Number(form.id));
            } else {
              this.selectedIds.removeObject(Number(form.id));
            }
          }
        } else {
          if (this.adminForms) {
            this.router.transitionTo('board.admin-form.form-detail', form.id);
          } else {
            this.router.transitionTo('board.form.form-detail', form.id);
          }
        }
      },
      changeOrder: function changeOrder(type) {
        if (this.displayMode) return;

        if (this.order) {
          if (this.order.type == type) {
            if (this.order.direction == 'ASC') {
              Ember.set(this.order, 'direction', 'DESC');
              this.send('reloadAfterOrder');
            } else if (this.order.direction == 'DESC') {
              Ember.set(this, 'order', undefined);
              this.send('reloadAfterOrder');
            }
          } else {
            Ember.set(this, 'order', {
              type: type,
              direction: "ASC"
            });
            this.send('reloadAfterOrder');
          }
        } else {
          Ember.set(this, 'order', {
            type: type,
            direction: "ASC"
          });
          this.send('reloadAfterOrder');
        }
      },
      openFormResults: function openFormResults(form) {
        this.router.transitionTo('board.form.form-results', form.id);
      },
      downloadResults: function downloadResults(form) {
        var fileName = form.title;
        fileName = fileName.replace(' ', '-') + '.zip';
        this.formZipService.downloadFormZip([form.id], fileName);
      }
    }
  });

  _exports.default = _default;
});