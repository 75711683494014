define("teelt-frontend/templates/components/content/conditions-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OBnEvtaQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"popup-background content-conditions-popup-background\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showContentConditionsModal\"]]],null],false]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"content-conditions-popup g-window\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showContentConditionsModal\"]]],null],true],[[\"bubbles\"],[false]]],[9],[0,\"\\n   \"],[7,\"a\"],[11,\"href\",\"#0\"],[11,\"class\",\"g-window-close\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showContentConditionsModal\"]]],null],false],[[\"bubbles\"],[false]]],[9],[7,\"i\"],[11,\"class\",\"material-icons grey-text\"],[9],[0,\"clear\"],[10],[10],[0,\"\\n\\n   \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[1,[27,\"t\",[\"content.conditions.popup.title\"],null],false],[10],[0,\"\\n   \"],[7,\"p\"],[9],[1,[27,\"t\",[\"content.conditions.popup.desc\"],[[\"talent\"],[[27,\"overridable-label\",[\"talentLabel\",\"word.talent\"],null]]]],false],[10],[0,\"\\n\\n   \"],[7,\"div\"],[11,\"class\",\"controls center\"],[9],[0,\"\\n     \"],[7,\"a\"],[11,\"class\",\"btn btn-red waves-effect waves-light\"],[11,\"style\",\"min-width: 100px\"],[3,\"action\",[[22,0,[]],\"setConfidential\",false]],[9],[1,[27,\"t\",[\"word.no\"],null],false],[10],[0,\"\\n     \"],[7,\"a\"],[11,\"class\",\"btn btn-primary waves-effect waves-light\"],[3,\"action\",[[22,0,[]],\"setConfidential\",true]],[9],[1,[27,\"t\",[\"content.conditions.popup.confirm\"],null],false],[10],[0,\"\\n   \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/content/conditions-popup.hbs"
    }
  });

  _exports.default = _default;
});