define("teelt-frontend/templates/components/icon/check-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2Hw9P96H",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"width\",\"15\"],[11,\"height\",\"12\"],[11,\"viewBox\",\"0 0 12 9\"],[11,\"fill\",\"none\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[9],[0,\"\\n  \"],[7,\"path\"],[11,\"d\",\"M11.125 1.37344L3.625 8.87344L0.1875 5.43594L1.06875 4.55469L3.625 7.10469L10.2438 0.492188L11.125 1.37344Z\"],[11,\"fill\",\"#00C48C\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/icon/check-svg.hbs"
    }
  });

  _exports.default = _default;
});