define("teelt-frontend/components/activity/activity-email", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['activity-list'],
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    actions: {
      resendNotif: function resendNotif() {
        Ember.set(this, 'showNotifModal', true);
        var params = JSON.parse(this.activity.customParams);
        var email = '';

        if (this.talent[params.recipient]) {
          email = this.talent[params.recipient].proEmail;
        } else if (params.recipient === 'officeManager') {
          if (this.talent && this.talent.officeManagers && this.talent.officeManagers.length > 0) {
            if (this.talent.officeManagers[0].proEmail && this.talent.officeManagers.length === 1) {
              email = this.talent.officeManagers[0].proEmail;
            } else {
              email = ' : ' + this.talent.officeManagers[0].proEmail;

              for (var i = 1; i < this.talent.officeManagers.length; i++) {
                email += ', ' + this.talent.officeManagers[i].proEmail;
              }
            }
          }
        } else if (params.recipient === 'rh' || params.recipient === 'adminMRH' || params.recipient === 'administrative' || params.recipient.startsWith('role_consult-')) {
          var roleName = this.rolecustomService.getRoleName(params.recipient);
          email = roleName ? roleName : params.recipient;
        } else if (params.recipient === 'talent') {
          email = this.talent.proEmail;
        }

        Ember.set(this, 'email', email);
      },
      unblock: function unblock() {
        var _this = this;

        var path = _environment.default.APP.API_HOST + "/activities/" + this.activity.id + "/unblock";
        this.ajax.requestPromise(path, "DELETE").then(function (result) {
          console.log('RESULT UNBLOCK', result);

          if (result) {
            _this.notify.info(_this.intl.t('activities.email_unblock_success'), {
              closeAfter: 5000
            });
          } else {
            _this.notify.error(_this.intl.t('activities.email_unblock_fail'), {
              closeAfter: 5000
            });
          }
        });
      },
      send: function send() {
        var _this2 = this;

        Ember.set(this, 'blocked', true);
        var params = JSON.parse(this.activity.customParams);
        var notifId = params.notifId;
        var path = _environment.default.APP.API_HOST + '/notifications/' + notifId + '/users/' + this.talent.id;

        if (params.recipient) {
          path += '?recipient=';

          if (params.recipient === 'officeManager') {
            path += 'OFFICE_MANAGER';
          } else if (params.recipient === 'adminMRH') {
            path += 'ADMIN_MRH';
          } else {
            path += params.recipient.toUpperCase();
          }
        }

        this.ajax.requestWithBodyPromise(path, 'POST').then(function (result) {
          Ember.set(_this2, 'blocked', false);

          if (result) {
            _this2.notify.info(_this2.intl.t('talent.messages.notification_sent'), {
              closeAfter: 5000
            });

            Ember.set(_this2, 'showNotifModal', false);
          } else {
            _this2.notify.error(_this2.intl.t('talent.errors.notification_failed'), {
              closeAfter: 5000
            });
          }
        }).catch(function () {
          Ember.set(_this2, 'blocked', false);

          _this2.notify.error(_this2.intl.t('talent.errors.notification_failed'), {
            closeAfter: 5000
          });
        });
      },
      onClickOverlay: function onClickOverlay() {}
    }
  });

  _exports.default = _default;
});