define("teelt-frontend/components/talents/talent-document-block", ["exports", "teelt-frontend/components/talents/talent-document"], function (_exports, _talentDocument) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _talentDocument.default.extend({
    classNames: ['talent-documents-block'],
    classNameBindings: ['filled:filled'],
    actions: {
      uploadFile: function uploadFile() {
        this.$('#input-' + this.field.id + ' > .drop-zone > .btn').click();
      }
    }
  });

  _exports.default = _default;
});