define("teelt-frontend/templates/talent/integration/team/my-team", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YR+vmBr3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"model\",\"team\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"talents/talent-team\",null,[[\"team\"],[[23,[\"model\",\"team\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/talent/integration/team/my-team.hbs"
    }
  });

  _exports.default = _default;
});