define("teelt-frontend/templates/talent/profile/detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jVAPtDqs",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"talents/talent-profile-edit\",null,[[\"talent\"],[[23,[\"model\",\"talent\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/talent/profile/detail.hbs"
    }
  });

  _exports.default = _default;
});