define("teelt-frontend/components/ihm/explore-office/explore-office-modal-field", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    actions: {
      validateCode: function validateCode() {
        var _this = this;

        var value = document.getElementById('field-value-' + this.field.id).value;

        if (value && /^\d+$/.test(value)) {
          var path = _environment.default.APP.API_HOST + '/exploreoffices/' + this.exploreOfficeId + '/response/' + this.talentId;
          var request = {
            fields: [{
              id: this.field.id,
              value: value
            }]
          };
          this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(request)).then(function (response) {
            if (response['code'] === 'invalid') {
              Ember.set(_this.field, 'invalidCode', true);
            }

            if (response['code'] === 'valid') {
              Ember.set(_this.field, 'isValid', true);

              if (response['completed']) {
                Ember.run.later(function () {
                  if (_this.onExploreOfficeCompleted) _this.onExploreOfficeCompleted();
                }, 0);
              }
            }
          });
        } else {
          Ember.set(this.field, 'invalidCode', true);
        }
      }
    }
  });

  _exports.default = _default;
});