define("teelt-frontend/templates/components/talents/talent-information-block-progression-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D2dYvD6z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"progression-gauge\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"progression-fill\"],[9],[0,\"\\n\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"progression-text\"],[9],[0,\"\\n    \"],[1,[21,\"level\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-information-block-progression-bar.hbs"
    }
  });

  _exports.default = _default;
});