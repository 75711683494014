define("teelt-frontend/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    queryParams: {
      token: ''
    },
    model: function model(param) {
      var _this = this;

      if (!param.token || param.token == 'undefined') {
        if (this.get("session.isAuthenticated")) {
          this.transitionTo('board.dashboard');
        } else {
          this.transitionTo('login.form');
        }
      } else {
        this.session.authenticate('authenticator:bearer', param.token).then(function () {
          _this.transitionTo('board.dashboard');
        }).catch(function () {
          _this.transitionTo('login.form');
        });
      }
    }
  });

  _exports.default = _default;
});