define("teelt-frontend/templates/components/icon/flag/fr-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VH5QYy4d",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"width\",\"14\"],[11,\"height\",\"14\"],[11,\"viewBox\",\"0 0 64 64\"],[11,\"fill\",\"none\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[9],[0,\"\\n  \"],[7,\"path\"],[11,\"d\",\"M1.8999 32.0002C1.8999 45.1002 10.2999 56.2002 21.8999 60.3002V3.7002C10.2999 7.8002 1.8999 18.9002 1.8999 32.0002Z\"],[11,\"fill\",\"#428BC1\"],[9],[10],[0,\"\\n  \"],[7,\"path\"],[11,\"d\",\"M61.8999 32.0002C61.8999 18.9002 53.5999 7.8002 41.8999 3.7002V60.3002C53.5999 56.2002 61.8999 45.1002 61.8999 32.0002\"],[11,\"fill\",\"#ED4C5C\"],[9],[10],[0,\"\\n  \"],[7,\"path\"],[11,\"d\",\"M21.8999 60.3C24.9999 61.4 28.3999 62 31.8999 62C35.3999 62 38.7999 61.4 41.8999 60.3V3.7C38.7999 2.6 35.4999 2 31.8999 2C28.2999 2 24.9999 2.6 21.8999 3.7V60.3\"],[11,\"fill\",\"white\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/icon/flag/fr-svg.hbs"
    }
  });

  _exports.default = _default;
});