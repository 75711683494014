define("teelt-frontend/components/template/template-campaign-assign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    allIds: [],
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'allIds', []);
      var selectedTalentIds = [];
      Ember.set(this.module, 'selectedTalentIds', selectedTalentIds);
    },
    getUserSearchParams: function getUserSearchParams(params) {
      return {
        //ids: this.allIds,
        query: params.query,
        complexFilters: params.complexFilters ? JSON.stringify(params.complexFilters) : undefined,
        selectAll: params.selectAll
      };
    },
    actions: {
      onTalentsSelected: function onTalentsSelected(params) {
        Ember.set(this, 'allIds', params.selecteds);
        var userSearchRequest = this.getUserSearchParams(params);
        userSearchRequest.ids = this.allIds;
        userSearchRequest.limit = this.allIds.length;
        Ember.set(this, 'assignRequest', userSearchRequest);
      },
      save: function save() {
        var _this = this;

        Ember.set(this.module, 'assignRequest', this.assignRequest);
        this.module.save().then(function () {
          if (_this.onSave) {
            _this.onSave();
          }
        });
      }
    }
  });

  _exports.default = _default;
});