define("teelt-frontend/components/talents/table/talents-custom-table-filter", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Component.extend({
    classNames: ['custom-table-filter', 'custom-talent-filter'],
    intl: Ember.inject.service(),
    uiUtils: Ember.inject.service('ui-utils'),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    staticlikeData: Ember.inject.service('staticlike-data'),
    userService: Ember.inject.service(),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    customLabelsService: Ember.inject.service('custom-labels-service'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var keyFilters = [{
        key: 'proEmail',
        type: 'text',
        operator: 'LIKE'
      }, {
        key: 'firstname',
        type: 'text',
        operator: 'LIKE'
      }, {
        key: 'lastname',
        type: 'text',
        operator: 'LIKE'
      }, {
        key: 'position',
        type: 'text',
        operator: 'LIKE'
      }, {
        key: 'welcomeDate',
        type: 'date',
        operator: 'EQUAL'
      }, {
        key: 'endDate',
        type: 'date',
        operator: 'EQUAL'
      }, {
        key: 'migrationDate',
        type: 'date',
        operator: 'EQUAL'
      }, {
        key: 'team',
        type: 'text',
        operator: 'LIKE'
      }, {
        key: 'contract',
        type: 'select',
        operator: 'EQUAL'
      }, {
        key: 'xp',
        type: 'select',
        operator: 'EQUAL'
      }, {
        key: 'role',
        type: 'select',
        operator: 'EQUAL'
      }, {
        key: 'manager',
        type: 'searchable',
        operator: 'EQUAL'
      }, {
        key: 'lang',
        type: 'select',
        operator: 'EQUAL'
      }
      /*{
        key: 'emailIncident',
        type: 'boolean',
        operator: 'EQUAL'
      },
      {
        key: 'missingData',
        type: 'select',
        operator: 'IN'
      },*/
      ];

      if (!this.userService.hasAnyRole(this.session.data.authenticated.user, "ROLE_CONSULT")) {
        keyFilters.push({
          key: 'template',
          type: 'select',
          operator: 'EQUAL'
        });
        keyFilters.push({
          key: 'campaign',
          type: 'select',
          operator: 'EQUAL'
        });
      }

      if (this.session.data.authenticated.user.company.activeOMRole) {
        keyFilters.push({
          key: 'officeManager',
          type: 'searchable',
          operator: 'EQUAL'
        });
      }

      if (this.session.data.authenticated.user.company.activeBuddyRole) {
        keyFilters.push({
          key: 'sponsor',
          type: 'searchable',
          operator: 'EQUAL'
        });
      }

      if (this.filterToRemove) {
        keyFilters = keyFilters.filter(function (k) {
          return !_this.filterToRemove.includes(k.key);
        });
      }

      Ember.set(this, 'keyFilters', keyFilters);
      this.getTalentDetailCustomFields();
    },
    getTalentDetailCustomFields: function getTalentDetailCustomFields() {
      var customFieldFilters = [];
      var companyCustomFields = this.staticlikeData.companyCustomFields;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = companyCustomFields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var customField = _step.value;

          if (customField.type != 'file') {
            var operator = 'EQUAL';

            if (!['opinion_scale', 'autocomplete_statics'].includes(customField.type)) {
              operator = ['multi_autocomplete_users', 'multiple_choice'].includes(customField.type) ? 'IN' : 'LIKE';
            }

            customFieldFilters.push({
              key: "" + customField.id,
              field: customField,
              type: customField.type,
              operator: operator,
              label: customField.name,
              customFieldId: customField.id,
              customFieldType: customField.type,
              multipleSelection: customField.properties && customField.properties.allow_multiple_selection ? customField.properties.allow_multiple_selection : false
            });
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      Ember.set(this, 'customFieldFilters', customFieldFilters);
    },
    initToDateDatepicker: function initToDateDatepicker(key) {
      var _this2 = this;

      setTimeout(function () {
        _this2.uiUtils.initDatePicker(_this2.$('#datepicker-input'), function (date) {
          Ember.set(_this2.selectedKey, "value1", moment(date).format('DD/MM/YYYY'));

          _this2.send('validate');
        });
      });
    },
    initSelectTemplate: function initSelectTemplate() {
      this.clearFilterParams();
      var path = _environment.default.APP.API_HOST + "/templates?light=true";
      Ember.set(this, 'keyAtt', 'id');
      Ember.set(this, 'keyValue', 'name');
      Ember.set(this, 'searchPath', path);
    },
    initSelectCampaign: function initSelectCampaign() {
      this.clearFilterParams();
      var path = _environment.default.APP.API_HOST + "/events/campaigns?light=true";
      Ember.set(this, 'keyAtt', 'id');
      Ember.set(this, 'keyValue', 'name');
      Ember.set(this, 'searchPath', path);
    },
    initSelectRole: function initSelectRole() {
      var _this3 = this;

      this.clearFilterParams();
      var roles = this.rolecustomService.getRoles();
      var consultRoles = this.rolecustomService.getConsultRoles();

      if (consultRoles) {
        roles = roles.concat(consultRoles);
      }

      var indexToInsert = roles.findIndex(function (role) {
        return role.roleType.startsWith('ROLE_CONSULT');
      });
      var newRole = {
        id: Math.max.apply(Math, _toConsumableArray(roles.map(function (r) {
          return r.id;
        }))) + 1,
        roleType: 'ROLE_USER_ONLY'
      };

      if (indexToInsert !== -1) {
        roles.splice(indexToInsert, 0, newRole);
      } else {
        roles.push(newRole);
      }

      var selectValues = roles.filter(function (r) {
        return r.roleType != 'ROLE_BUDDY';
      }).map(function (r) {
        var label = '';

        if (r.roleType != 'ROLE_USER_ONLY') {
          label = _this3.rolecustomService.getRoleName(r.roleType);
        } else {
          label = _this3.intl.t('settings.roles.default.ROLE_USER_ONLY', {
            talent: _this3.getTalentLabel()
          });
          label = label.charAt(0).toUpperCase() + label.slice(1);
        }

        return {
          key: r.roleType,
          label: label
        };
      });
      setTimeout(function () {
        Ember.set(_this3, "selectValues", selectValues);
      }, 100);
    },
    initSelectMissingData: function initSelectMissingData() {
      var _this4 = this;

      this.clearFilterParams();
      var selectValues = [];
      this.ajax.requestPromise(_environment.default.APP.API_HOST + '/companies/required-user-fields', 'GET').then(function (response) {
        if (response.firstnameRequired) {
          selectValues.push({
            key: 'firstnameRequired',
            label: _this4.intl.t('talent.table.keys.firstname')
          });
        }

        if (response.lastnameRequired) {
          selectValues.push({
            key: 'lastnameRequired',
            label: _this4.intl.t('talent.table.keys.lastname')
          });
        }

        if (response.persoEmailRequired) {
          selectValues.push({
            key: 'persoEmailRequired',
            label: _this4.intl.t('talent.perso_email_lite')
          });
        }

        if (response.proEmailRequired) {
          selectValues.push({
            key: 'proEmailRequired',
            label: _this4.intl.t('talent.pro_email_lite')
          });
        }

        if (response.phoneNumberRequired) {
          selectValues.push({
            key: 'phoneNumberRequired',
            label: _this4.intl.t('talent.table.keys.phoneNumber')
          });
        }

        if (response.teamIdRequired) {
          selectValues.push({
            key: 'teamIdRequired',
            label: _this4.intl.t('talent.table.keys.team')
          });
        }

        if (response.positionIdRequired) {
          selectValues.push({
            key: 'positionIdRequired',
            label: _this4.intl.t('talent.position_job')
          });
        }

        if (response.contractIdRequired) {
          selectValues.push({
            key: 'contractIdRequired',
            label: _this4.intl.t('talent.contract')
          });
        }

        if (response.welcomeDateRequired) {
          selectValues.push({
            key: 'welcomeDateRequired',
            label: _this4.intl.t('talent.table.keys.welcomeDate')
          });
        }

        if (response.endDateRequired) {
          selectValues.push({
            key: 'endDateRequired',
            label: _this4.intl.t('talent.table.keys.endDate')
          });
        }

        if (response.migrationDateRequired) {
          selectValues.push({
            key: 'migrationDateRequired',
            label: _this4.intl.t('talent.table.keys.migrationDate')
          });
        }

        if (response.trialPeriodEndDateRequired) {
          selectValues.push({
            key: 'trialPeriodEndDateRequired',
            label: _this4.intl.t('talent.table.keys.trialPeriodEndDate')
          });
        }

        if (response.managerIdRequired) {
          selectValues.push({
            key: 'managerIdRequired',
            label: _this4.intl.t('talent.table.keys.manager')
          });
          Ember.set(_this4.selectedKey, 'label', _this4.intl.t('talent.table.keys.manager'));
        } else {
          Ember.set(_this4.selectedKey, 'label', _this4.intl.t('talent.table.keys.firstname'));
          Ember.set(_this4.selectedKey, 'value1', 'firstnameRequired');
        }

        _this4.ajax.requestPromise(_environment.default.APP.API_HOST + "/roles?consultRoles=true&required=true", 'GET').then(function (result) {
          if (result) {
            result.forEach(function (role) {
              selectValues.push({
                key: role.roleType + '-Required',
                label: role.label
              });
            });
          }

          _this4.ajax.requestPromise(_environment.default.APP.API_HOST + '/custom-fields?required=true', 'GET').then(function (customFields) {
            if (customFields) {
              customFields.forEach(function (customField) {
                selectValues.push({
                  key: 'CF-' + customField.id + '-Required',
                  label: customField.name
                });
              });
            }

            setTimeout(function () {
              Ember.set(_this4, "selectValues", selectValues);
            }, 100);
          });
        });
      });
    },
    getTalentLabel: function getTalentLabel() {
      var customLabels = this.customLabelsService.customLabels;

      if (customLabels && customLabels['talentLabel']) {
        return customLabels['talentLabel'];
      } else {
        return this.intl.t('word.talents');
      }
    },
    initSelectManager: function initSelectManager() {
      this.clearFilterParams();
      var path = this.userService.getUsersSearchPathForSelect('ROLE_MANAGER');
      Ember.set(this, 'keyAtt', 'id');
      Ember.set(this, 'searchPath', path);
    },
    initSelectOfficeManager: function initSelectOfficeManager() {
      this.clearFilterParams();
      var path = this.userService.getUsersSearchPathForSelect('ROLE_CUSTOM_OFFICE_MANAGER');
      Ember.set(this, 'keyAtt', 'id');
      Ember.set(this, 'searchPath', path);
    },
    initSelectSponsor: function initSelectSponsor() {
      this.clearFilterParams();
      var path = _environment.default.APP.API_HOST + "/users";
      Ember.set(this, 'keyAtt', 'id');
      Ember.set(this, 'keyValue', 'username');
      Ember.set(this, 'searchPath', path);
    },
    initSelectLang: function initSelectLang() {
      var _this5 = this;

      this.clearFilterParams();
      var selectValues = this.intl.locales.map(function (locale) {
        var label = _this5.intl.t("langs." + locale);

        return {
          key: locale,
          label: label
        };
      });
      Ember.set(this, "selectValues", selectValues);
    },
    initSelectContract: function initSelectContract() {
      var _this6 = this;

      this.clearFilterParams();
      var path = _environment.default.APP.API_HOST + "/statics/contract";
      this.ajax.requestPromise(path).then(function (contracts) {
        var selectValues = contracts.map(function (c) {
          var contractName = _this6.intl.t('contracts.' + c.name);

          return {
            key: c.id,
            label: contractName
          };
        });
        Ember.set(_this6, "selectValues", selectValues);
      });
    },
    initSelectXP: function initSelectXP() {
      var _this7 = this;

      this.clearFilterParams();
      var path = _environment.default.APP.API_HOST + "/statics/experience";
      this.ajax.requestPromise(path).then(function (xps) {
        var selectValues = xps.map(function (x) {
          return {
            key: x.id,
            label: x.name
          };
        });
        Ember.set(_this7, "selectValues", selectValues);
      });
    },
    initSelectCustomField: function initSelectCustomField(customId) {
      var _this8 = this;

      this.clearFilterParams();
      var path = _environment.default.APP.API_HOST + "/custom-fields/" + customId + "/possiblesresponses";
      this.ajax.requestPromise(path).then(function (vs) {
        var selectValues = vs.map(function (x) {
          return {
            key: x.value,
            label: x.label ? x.label : x.value
          };
        });
        Ember.set(_this8, "selectValues", selectValues);
      });
    },
    clearFilterParams: function clearFilterParams() {
      Ember.set(this, 'searchPath', null);
      Ember.set(this, 'selectValues', null);
    },
    actions: {
      setComplexFilter: function setComplexFilter(key) {
        var _this9 = this;

        Ember.set(this, "selectValues", undefined);
        Ember.set(this, 'selectedKey', JSON.parse(JSON.stringify(key)));
        setTimeout(function () {
          _this9.$('.select').formSelect();
        });

        if (key.type == 'date') {
          this.initToDateDatepicker(key);
        }

        if (key.type == 'select' && key.key == 'template') {
          this.initSelectTemplate();
        }

        if (key.type == 'select' && key.key == 'campaign') {
          this.initSelectCampaign();
        }

        if (key.type == 'select' && key.key == 'role') {
          this.initSelectRole();
        }

        if (key.type == 'searchable' && key.key == 'manager') {
          this.initSelectManager();
        }

        if (key.type == 'select' && key.key == 'lang') {
          this.initSelectLang();
        }

        if (key.type == 'searchable' && key.key == 'officeManager') {
          this.initSelectOfficeManager();
        }

        if (key.type == 'searchable' && key.key == 'sponsor') {
          this.initSelectSponsor();
        }

        if (key.type == 'select' && key.key == 'contract') {
          this.initSelectContract();
        }

        if (key.type == 'select' && key.key == 'xp') {
          this.initSelectXP();
        }

        if (key.type == 'multiple_choice' && key.field) {
          this.initSelectCustomField(key.field.id);
        }

        if (key.type == 'boolean' && key.key == 'emailIncident') {
          this.clearFilterParams();
          Ember.set(this.selectedKey, "value1", true);
        }

        if (key.type == 'select' && key.key == 'missingData') {
          this.initSelectMissingData();
        }
      },
      onOperatorChange: function onOperatorChange(operator) {
        Ember.set(this.selectedKey, 'operator', operator);
      },
      validate: function validate() {
        this.onFilterChanged(this.selectedKey);
        Ember.set(this, 'selectedKey', undefined);
        Ember.set(this, 'showFilters', false);
      },
      onSelectChanged: function onSelectChanged(value) {
        Ember.set(this.selectedKey, "value1", value);
      },
      convertSearchableResult: function convertSearchableResult(result) {
        if (this.selectedKey.key == 'manager' || this.selectedKey.key == 'officeManager' || this.selectedKey.key == 'sponsor') {
          return this.userService.getTalentName(result);
        }

        return result[this.keyValue];
      }
    }
  });

  _exports.default = _default;
});