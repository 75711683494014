define("teelt-frontend/routes/talent/integration/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    afterModel: function afterModel(model) {
      Ember.set(model, 'showContents', true);
      Ember.set(model, 'showResources', true);
      Ember.set(model, 'hasContents', true);
      Ember.set(model, 'hasResources', true);
      var sectionHome = 'home';

      if (model.homepage && model.homepage.templateId) {
        sectionHome = 'hometemplate' + model.homepage.templateId;
      }

      Ember.set(model, 'sectionHome', sectionHome);
    },
    actions: {
      onShowMoreResources: function onShowMoreResources() {
        this.transitionTo('talent.integration.resources');
      }
    }
  });

  _exports.default = _default;
});