define("teelt-frontend/components/company/new-company-form", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['new-company-form'],
    store: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    customErrorService: Ember.inject.service('custom-error'),
    userService: Ember.inject.service('user-service'),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.company) {
        var company = this.store.createRecord('company', {
          rhEmails: [],
          offices: []
        });
        Ember.set(this, 'company', company);
      } else {
        this.loadLicense();
        Ember.set(this.company, 'rhEmails', []);

        if (this.company.users) {
          Ember.set(this.company, 'rhEmails', this.company.users.map(function (user) {
            return {
              id: user.id,
              name: user.username
            };
          }));
        }

        if (!this.company.offices) {
          Ember.set(this.company, 'offices', []);
        }

        if (!this.company.talentSoftSetting) {
          Ember.set(this.company, 'talentSoftSetting', {});
        }
      }

      if (this.company.activeMeetPeople || this.company.activeExploreOffice) {
        // || other ihm modules
        Ember.set(this, 'activeIhm', true);
      }
    },
    loadLicense: function loadLicense() {
      var _this = this;

      var path = _environment.default.APP.API_HOST + '/companies/' + this.company.id + "/license";
      this.ajax.requestPromise(path).then(function (license) {
        Ember.set(_this.company, 'nbRH', license.nbRH);
        Ember.set(_this.company, 'nbMRH', license.nbMRH);
        Ember.set(_this.company, 'nbManager', license.nbManager);
        Ember.set(_this.company, 'nbConsult', license.nbConsult);
      });
    },
    actions: {
      save: function save() {
        var _this2 = this;

        // console.log(this.company);
        if (!this.company.name) {
          return;
        }

        Ember.set(this.company, 'selectedToolIds', []);

        if (this.company.tools) {
          Ember.set(this.company, 'selectedToolIds', this.company.tools.map(function (tool) {
            return tool.id;
          }));
        }

        if (!this.activeIhm) {
          Ember.set(this.company, 'activeMeetPeople', false);
          Ember.set(this.company, 'activeExploreOffice', false); // other ihm modules
        }

        this.company.save().then(function (company) {
          // wait 500ms to give time to API to create RH (it's async)
          Ember.run.later(function () {
            _this2.router.transitionTo("board.company.company-detail", company.id);
          }, 500);
        }).catch(function (err) {
          _this2.customErrorService.handleAjaxError(err);
        });
      },
      onLogoChanged: function onLogoChanged(file) {
        var _this3 = this;

        if (!file) return;
        Ember.set(this, 'blockSave', true);
        this.fileUploadService.signAndUpload(file, false, function (e) {
          return _this3.onLogoProgress(e);
        }).then(function (fileUrl) {
          _this3.company.set('logoUrl', fileUrl);
        }).finally(function () {
          Ember.set(_this3, 'blockSave', false);
        });
      },
      onLogoOnbgcolorChanged: function onLogoOnbgcolorChanged(file) {
        var _this4 = this;

        if (!file) return;
        Ember.set(this, 'blockSave', true);
        this.fileUploadService.signAndUpload(file, false, function (e) {
          return _this4.onLogoOnbgcolorProgress(e);
        }).then(function (fileUrl) {
          _this4.company.set('logoOnBackgroundColorUrl', fileUrl);
        }).finally(function () {
          Ember.set(_this4, 'blockSave', false);
        });
      },
      onBackgroundVideoChanged: function onBackgroundVideoChanged(event) {
        var _this5 = this;

        var file = event;
        if (!file) return;

        if (file.size <= 50000000) {
          Ember.set(this, 'backgroundVideo-size', false);

          if (file.type === "video/mp4") {
            Ember.set(this, 'backgroundVideo-type', false);
            Ember.set(this, 'blockSave', true);
            this.fileUploadService.signAndUpload(file, false, function (e) {
              return _this5.onBackgroundVideoProgress(e);
            }).then(function (fileUrl) {
              Ember.set(_this5.company, 'backgroundVideoUrl', fileUrl);
            }).finally(function () {
              Ember.set(_this5, 'blockSave', false);
            });
          } else {
            Ember.set(this, 'backgroundVideo-type', true);
          }
        } else {
          Ember.set(this, 'backgroundVideo-size', true);
        }
      },
      onBackgroundChanged: function onBackgroundChanged(file) {
        var _this6 = this;

        if (!file) return;
        Ember.set(this, 'blockSave', true);
        this.fileUploadService.signAndUpload(file, false, function (e) {
          return _this6.onBackgroundProgress(e);
        }).then(function (fileUrl) {
          _this6.company.set('backgroundUrl', fileUrl);
        }).finally(function () {
          Ember.set(_this6, 'blockSave', false);
        });
      },
      reinvitRH: function reinvitRH(keyword) {
        var found = false;
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.company.rhEmails[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var rh = _step.value;

            if (rh.name === keyword.name) {
              found = true;
              break;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        if (found) {
          Ember.set(this, "reinvitEmail", keyword.name);
          Ember.set(this, "reinvitMessage", this.intl.t("company.messages.reinvit_rh", {
            email: keyword.name
          }));
          Ember.set(this, "showReInvitPopup", true);
        }
      },
      reinvitConfirmation: function reinvitConfirmation(response) {
        if (response) {
          this.userService.sendReinvitation(this.reinvitEmail);
        }
      }
    },
    onLogoProgress: function onLogoProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'logoProgress', progress);
      }
    },
    onLogoOnbgcolorProgress: function onLogoOnbgcolorProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'logoOnbgcolorProgress', progress);
      }
    },
    onBackgroundProgress: function onBackgroundProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'backgroundProgress', progress);
      }
    },
    onBackgroundVideoProgress: function onBackgroundVideoProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'backgroundVideoProgress', progress);
      }
    }
  });

  _exports.default = _default;
});