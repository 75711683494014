define("teelt-frontend/routes/talent/integration/resources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      contentId: ''
    },
    resetController: function resetController(controller) {
      Ember.set(this.currentModel, 'contentId', null);
      controller.set('contentId', null);
    },
    afterModel: function afterModel(model, params) {
      Ember.set(model, 'showResources', true);
      Ember.set(model, 'showDocumentResourcesToDownload', true);
      Ember.set(model, 'showDocumentResourcesToConsult', true);

      if (params.queryParams && params.queryParams.contentId) {
        Ember.set(model, 'contentId', params.queryParams.contentId);
      }
    }
  });

  _exports.default = _default;
});