define("teelt-frontend/routes/board/setting/privacy-security", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    userService: Ember.inject.service(),
    ajax: Ember.inject.service(),
    actions: {
      handleFocusOut: function handleFocusOut() {
        this.currentModel.company.save();
      },
      interceptEnter: function interceptEnter(e1, e2) {
        if (e2.keyCode == 13) {
          this.send('handleFocusOut', e2);
          return false;
        }

        return true;
      },
      inactivityInterceptEnter: function inactivityInterceptEnter(e1, e2) {
        if (e2.which > 48 && e2.which < 53) {
          e2.preventDefault();
        }

        this.send("interceptEnter", e1, e2);
      },
      generateToken: function generateToken() {
        var _this = this;

        this.userService.generateApiToken().then(function (data) {
          Ember.set(_this.currentModel, 'token', data);
          Ember.set(_this.currentModel, 'showTokenModal', true);
        });
      },
      revealToken: function revealToken() {
        var pw = document.getElementById("tokenInput");

        if (pw.type == "password") {
          pw.type = "text";
        } else {
          pw.type = "password";
        }
      },
      exportDocumentValidationLogs: function exportDocumentValidationLogs() {
        var exportFormat = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'csv';
        Ember.set(this.currentModel, 'showExportDocumentValidationLogsModal', false);
        var pathOTP = _environment.default.APP.API_HOST + "/users/otp";
        this.ajax.requestPromise(pathOTP).then(function (otp) {
          setTimeout(function () {
            var path = _environment.default.APP.API_HOST + "/activities/users/export_document_validation/" + exportFormat + "?otp=" + otp;
            var element = document.createElement('a');
            element.setAttribute('href', path);
            element.setAttribute("target", "_blank");
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          }, 100);
        });
      },
      didTransition: function didTransition() {
        this._super.apply(this, arguments);

        if (this.currentModel.company.essential) {
          setTimeout(function () {
            var elements = document.querySelectorAll('.items-container');

            if (elements && elements.length > 2) {
              for (var i = 0; i < elements.length; i++) {
                if (i > 1) {
                  elements[i].style.display = 'none';
                }
              }
            }
          }, 0);
        }
      }
    }
  });

  _exports.default = _default;
});