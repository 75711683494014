define("teelt-frontend/components/settings/table/scheduled-sftp-export-table-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['perimeter-list'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    formToEdit: {},
    actions: {
      initFormSchedule: function initFormSchedule(result) {
        if (!result.scheduleSftpAutoExport || !this.allowScheduledForm) return;
        this.eventBus.trigger('SCHEDULED_SFTP', result);
      },
      initDeleteFormSchedule: function initDeleteFormSchedule(result) {
        this.eventBus.trigger('TRIGGER_DELETE_SCHEDULED_SFTP', result);
      },
      edit: function edit(result) {
        var _this = this;

        if (!this.allowScheduledForm) return;
        this.store.findRecord('form', result.id).then(function (form) {
          Ember.set(form, 'scheduleSftpAutoExport', !result.scheduleSftpAutoExport);

          if (!result.scheduleSftpAutoExport) {
            Ember.set(form, 'scheduleTrigger', null);
            Ember.set(form, 'scheduleTriggerUnit', null);
          }

          form.save().then(function () {
            _this.eventBus.trigger('REFRESH_SCHEDULED_SFTP');
          });
        });
      }
    }
  });

  _exports.default = _default;
});