define("teelt-frontend/components/utils/color-picker", ["exports", "teelt-frontend/utils/string-util", "jquery"], function (_exports, _stringUtil, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['color-picker'],
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'uuid', (0, _stringUtil.default)().generateUUID());
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var _this = this;

      (0, _jquery.default)("#picker-" + this.uuid).toggle(function () {
        var joe = colorjoe.rgb("picker-" + _this.uuid, _this.color);
        joe.on("change", function (color) {
          return Ember.set(_this, 'color', color.css());
        });

        if (_this.onDone) {
          joe.on("done", function (color) {
            return _this.onDone(color.css());
          });
        }
      });
    },
    actions: {
      toggle: function toggle() {
        (0, _jquery.default)("#picker-" + this.uuid).toggle(500);
      }
    }
  });

  _exports.default = _default;
});