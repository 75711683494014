define("teelt-frontend/components/event/table/campaigns-custom-table-content", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['campaigns-custom-table-content'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var path = _environment.default.APP.API_HOST + "/events/campaigns/" + this.result.id + "/userstats";
      this.ajax.requestPromise(path).then(function (stats) {
        Ember.set(_this, 'userstats', stats);
        Ember.set(_this, 'moreAffected', stats.totalUsers - stats.userAvatarsUrl.length);
      });
    },
    actions: {
      edit: function edit() {
        Ember.set(this, 'showActions', false);
        this.router.transitionTo('board.event.campaign-edit', this.result.id);
      },
      duplicate: function duplicate() {
        Ember.set(this, 'showActions', false);
        this.router.transitionTo('board.event.campaign-new', {
          queryParams: {
            duplicateId: this.result.id
          }
        });
      },
      prepareDeleteCampaign: function prepareDeleteCampaign() {
        Ember.set(this, 'showActions', false);
        Ember.set(this, 'campaignToDelete', this.result);
      },
      removeCampaign: function removeCampaign(response) {
        var _this2 = this;

        if (!response) return;
        this.campaignToDelete.deleteRecord();
        this.campaignToDelete.save().then(function () {
          _this2.eventBus.trigger('REFRESH_CAMPAIGNS');
        });
      },
      assign: function assign() {
        Ember.set(this, 'showActions', false);
        this.router.transitionTo('board.event.campaign-assign', this.result.id);
      }
    }
  });

  _exports.default = _default;
});