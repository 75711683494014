define("teelt-frontend/components/talents/talent-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      copyContent: function copyContent(content, element) {
        var tempElem = document.createElement("input");
        document.body.appendChild(tempElem);
        tempElem.setAttribute('value', content);
        tempElem.select();
        document.execCommand("copy");
        document.body.removeChild(tempElem);
        this.notify.info(this.intl.t('talent.copy_element_to_clipboard', {
          element: element
        }), {
          closeAfter: 5000
        });
      }
    }
  });

  _exports.default = _default;
});