define("teelt-frontend/templates/components/icon/downloading-loader-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cWLPPctJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[11,\"style\",\"margin: auto; background: rgb(255, 255, 255) none repeat scroll 0% 0%; display: block; shape-rendering: auto;\"],[11,\"width\",\"200px\"],[11,\"height\",\"200px\"],[11,\"viewBox\",\"0 0 100 100\"],[11,\"preserveAspectRatio\",\"xMidYMid\"],[9],[0,\"\\n\"],[7,\"g\"],[11,\"transform\",\"translate(50,50)\"],[9],[0,\"\\n  \"],[7,\"g\"],[11,\"transform\",\"scale(0.7)\"],[9],[0,\"\\n  \"],[7,\"circle\"],[11,\"cx\",\"0\"],[11,\"cy\",\"0\"],[11,\"r\",\"50\"],[11,\"fill\",\"#1800FF\"],[9],[10],[0,\"\\n  \"],[7,\"circle\"],[11,\"cx\",\"0\"],[11,\"cy\",\"-28\"],[11,\"r\",\"15\"],[11,\"fill\",\"#2dcccd\"],[9],[0,\"\\n    \"],[7,\"animateTransform\"],[11,\"attributeName\",\"transform\"],[11,\"dur\",\"1s\"],[11,\"repeatCount\",\"indefinite\"],[11,\"keyTimes\",\"0;1\"],[11,\"values\",\"0 0 0;360 0 0\"],[11,\"type\",\"rotate\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[2,\" [ldio] generated by https://loading.io/ \"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/icon/downloading-loader-svg.hbs"
    }
  });

  _exports.default = _default;
});