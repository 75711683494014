define("teelt-frontend/templates/components/utils/map-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z8RlUyB/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"embedUrl\"]]],null,{\"statements\":[[7,\"iframe\"],[12,\"width\",[28,[[21,\"width\"]]]],[12,\"height\",[28,[[21,\"height\"]]]],[11,\"frameborder\",\"0\"],[11,\"style\",\"border:0\"],[12,\"src\",[28,[[21,\"embedUrl\"]]]],[11,\"allowfullscreen\",\"\"],[9],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/map-view.hbs"
    }
  });

  _exports.default = _default;
});