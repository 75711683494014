define("teelt-frontend/components/dashboard/custom-bar-chart", ["exports", "teelt-frontend/config/environment", "teelt-frontend/utils/jquery-utils", "jquery"], function (_exports, _environment, _jqueryUtils, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Component.extend((_EmberComponent$exte = {
    classNames: ['custom-bar-chart'],
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    templateFilter: 'all',
    filtersLength: 0,
    filterAll: true,
    filters: {},
    datas: [],
    baseColors: ['#1ABC9C', '#3498DB', '#9B59B6', '#E74C3C', '#F39C12', '#27AE60', '#2980B9', '#8E44AD', '#C0392B', '#F1C40F'],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.results) {
        Ember.set(this, 'datas', this.results);
      }

      if (this.dataPath) {
        this.send('selectFilter', 'all');
        this.filterData();
      } else {
        this.setColors(this.datas.values);
      }

      if (this.canSelectItems) {
        var filters = this.filters;
        var i = 0;
        this.datas.labels.forEach(function (f) {
          i++;

          if (_this.maxSelectItems && i > _this.maxSelectItems) {
            Ember.set(filters, f, false);
          } else {
            Ember.set(filters, f, true);
          }
        });
        var entries = Object.entries(filters);
        var sortedEntries = [].concat(_toConsumableArray(entries.filter(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              key = _ref2[0],
              value = _ref2[1];

          return value === true;
        })), _toConsumableArray(entries.filter(function (_ref3) {
          var _ref4 = _slicedToArray(_ref3, 2),
              key = _ref4[0],
              value = _ref4[1];

          return value === false;
        })));
        Ember.set(this, 'filters', Object.fromEntries(sortedEntries));
        this.filterDataByFilters();
      }

      ;
      this.maxSelectItems ? Ember.set(this, 'filtersLengthTotal', this.maxSelectItems) : Ember.set(this, 'filtersLengthTotal', Object.values(this.filters).length);
    },
    destroy: function destroy() {
      this.eventBus.off('CLICK_EVENT', this, 'checkToCloseModal');

      this._super.apply(this, arguments);
    },
    checkToCloseModal: function checkToCloseModal(e) {
      if (!(0, _jqueryUtils.default)().checkIfClickInElement(e, ["possible-filters", "possible-filters-btn"])) {
        if (this.showPossibleFilters) {
          Ember.set(this, 'showPossibleFilters', false);
        }
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.updateWidthHeight();
      setTimeout(function () {
        _this2.eventBus.on('CLICK_EVENT', _this2, 'checkToCloseModal');
      }, 300);
    }
  }, _defineProperty(_EmberComponent$exte, "filtersLength", Ember.computed('filters', function () {
    this.filterDataByFilters();
    return Object.values(this.filters).filter(function (f) {
      return f === true;
    }).length;
  })), _defineProperty(_EmberComponent$exte, "setColors", function setColors(datas) {
    var colors = [];

    for (var i = 0; i < datas.length; i++) {
      var color = this.baseColors[i % 10];
      colors.push(color);
    }

    Ember.set(this, 'colors', colors);
  }), _defineProperty(_EmberComponent$exte, "updateWidthHeight", function updateWidthHeight() {
    Ember.set(this, 'canvaHeight', (0, _jquery.default)('.custom-bar-chart').height() - 50);
    Ember.set(this, 'canvaWidth', (0, _jquery.default)('.custom-bar-chart').width() - 20);
  }), _defineProperty(_EmberComponent$exte, "filterData", function filterData() {
    var _this3 = this;

    Ember.set(this, 'showChart', false);

    if (this.dataPath) {
      var path = _environment.default.APP.API_HOST + this.dataPath;

      if (this.showTemplateFilters) {
        path += '?search=' + this.templateFilter;
      }

      var params = this.params ? this.params : {};
      this.ajax.requestWithUrlParamPromise(path, 'GET', params).then(function (data) {
        var labels = [];
        var values = [];

        if (_this3.isMonthLabels) {
          var orderedMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

          for (var _i2 = 0, _orderedMonths = orderedMonths; _i2 < _orderedMonths.length; _i2++) {
            var month = _orderedMonths[_i2];

            if (month in data) {
              labels.push(month);
              values.push(data[month]);
            }
          }
        } else {
          for (var item in data) {
            labels.push(item);
            values.push(data[item]);
          }
        }

        Ember.set(_this3, 'datas', {
          labels: labels,
          values: values
        });

        _this3.setColors(values);
      });
    }

    Ember.set(this, 'showChart', true);
  }), _defineProperty(_EmberComponent$exte, "filterDataByFilters", function filterDataByFilters() {
    var _this4 = this;

    Ember.set(this, 'showChart', false);
    Ember.set(this, 'datas', undefined);
    var labels = [];
    var values = [];
    var filters = this.filters;
    this.results.labels.forEach(function (label, index) {
      if (filters[label]) {
        labels.push(label);
        values.push(_this4.results.values[index]);
      }
    });
    setTimeout(function () {
      Ember.set(_this4, 'datas', {
        labels: labels,
        values: values
      });

      _this4.setColors(values);

      Ember.set(_this4, 'showChart', true);
    });
  }), _defineProperty(_EmberComponent$exte, "actions", {
    selectFilter: function selectFilter(templateFilter) {
      Ember.set(this, 'templateFilter', templateFilter);
      Ember.set(this, 'possibleTemplateType', false);
      this.filterData();
    },
    onClickOverlay: function onClickOverlay() {
      Ember.set(this, 'showModal', false);
    },
    toggleAll: function toggleAll() {
      var enable = !this.filterAll;
      var filters = this.filters;

      if (!enable) {
        for (var f in filters) {
          Ember.set(filters, f, false);
        }
      }

      if (enable) {
        var i = 0;

        for (var _f in filters) {
          i++;

          if (this.maxSelectItems && i > this.maxSelectItems) {
            Ember.set(filters, _f, false);
          } else {
            Ember.set(filters, _f, true);
          }
        }
      }

      Ember.set(this, 'filterAll', enable);
      Ember.set(this, 'filters', undefined);
      Ember.set(this, 'filters', filters);
    },
    filter: function filter(_filter) {
      var filters = this.filters;
      var enable = !filters[_filter];

      if (!this.maxSelectItems || this.maxSelectItems && this.filtersLength < this.maxSelectItems || !enable) {
        Ember.set(filters, _filter, enable);
      }

      var allEnable = true;

      for (var f in filters) {
        if (!filters[f]) {
          allEnable = false;
          break;
        }
      }

      if (this.maxSelectItems) {
        allEnable = Object.values(filters).filter(function (f) {
          return f === true;
        }).length === this.maxSelectItems;
      }

      Ember.set(this, 'filterAll', allEnable);
      Ember.set(this, 'filters', undefined);
      Ember.set(this, 'filters', filters);
    }
  }), _EmberComponent$exte));

  _exports.default = _default;
});