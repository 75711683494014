define("teelt-frontend/templates/components/tag/tags-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/ojJcwxE",
    "block": "{\"symbols\":[\"tag\",\"index\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col s8 l4\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"value\",\"placeholder\",\"class\"],[[23,[\"tagToAdd\"]],[27,\"t\",[\"tags.add_tag\"],null],\"input-add\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col s4 l2\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-primary waves-effect waves-light\"],[3,\"action\",[[22,0,[]],\"addTag\"]],[9],[0,\"+\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row tags\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"tags\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"tag\"],[9],[1,[22,1,[\"name\"]],false],[0,\" \"],[7,\"span\"],[11,\"class\",\"material-icons clickable\"],[3,\"action\",[[22,0,[]],\"deleteTag\",[22,1,[]],[22,2,[]]]],[9],[0,\"close\"],[10],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/tag/tags-form.hbs"
    }
  });

  _exports.default = _default;
});