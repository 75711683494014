define("teelt-frontend/components/talents/talent-detail-block/talent-information-block", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    uiUtils: Ember.inject.service('ui-utils'),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.canCloseWithoutSaving) this.canCloseWithoutSaving(true);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      setTimeout(function () {
        (0, _jquery.default)('.select-contract').formSelect();

        _this.store.findAll('statics/team').then(function (teams) {
          _this.uiUtils.initAutocomplete((0, _jquery.default)('#autocomplete-team'), teams, "name", function () {
            Ember.set(_this, "newTeam", false);
          });
        });

        (0, _jquery.default)('.select-lang').formSelect();
      }, 100);
    },
    actions: {
      onInputChanged: function onInputChanged() {
        if (this.canCloseWithoutSaving) this.canCloseWithoutSaving(false);
      },
      onContractChanged: function onContractChanged(value) {
        if (this.canCloseWithoutSaving) this.canCloseWithoutSaving(false);
        Ember.set(this.talent, 'contract', {
          name: value
        });
      },
      onTeamChanged: function onTeamChanged(e) {
        if (this.canCloseWithoutSaving) this.canCloseWithoutSaving(false);
        Ember.set(this, 'newTeam', true);
      },
      onLangChanged: function onLangChanged(value) {
        if (this.canCloseWithoutSaving) this.canCloseWithoutSaving(false);
        Ember.set(this.talent, "lang", value);
      },
      onTrialPeriodUnitChanged: function onTrialPeriodUnitChanged(value) {
        if (this.canCloseWithoutSaving) this.canCloseWithoutSaving(false);
        Ember.set(this.talent, "trialPeriodUnit", value);
      }
    }
  });

  _exports.default = _default;
});