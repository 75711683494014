define("teelt-frontend/components/utils/all-done-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["all-done-image"]
  });

  _exports.default = _default;
});