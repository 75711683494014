define("teelt-frontend/templates/components/talents/talent-documents-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CCnFiU8W",
    "block": "{\"symbols\":[\"field\",\"fullValue\"],\"statements\":[[4,\"if\",[[23,[\"talent\",\"company\",\"customFields\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"talent\",\"company\",\"customFields\"]]],null,{\"statements\":[[4,\"if\",[[27,\"and\",[[27,\"eq\",[[22,1,[\"section\"]],\"talent-profile\"],null],[27,\"eq\",[[22,1,[\"type\"]],\"file\"],null]],null]],null,{\"statements\":[[4,\"let\",[[27,\"get\",[[27,\"get\",[[27,\"object-at\",[[23,[\"talent\",\"allCustomFieldsInitialized\"]],[27,\"get-index-id\",[[23,[\"talent\",\"allCustomFieldsInitialized\"]],[22,1,[\"id\"]]],null]],null],\"user_customfield\"],null],\"fullValue\"],null]],null,{\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"unFilledOnly\"]],[27,\"not\",[[22,2,[]]],null]],null]],null,{\"statements\":[[0,\"          \"],[1,[27,\"talents/talent-document\",null,[[\"talent\",\"field\",\"fullValue\",\"actionLabel\"],[[23,[\"talent\"]],[22,1,[]],[22,2,[]],[27,\"t\",[\"talent.documents.action_send\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"filledOnly\"]],[22,2,[]]],null]],null,{\"statements\":[[0,\"          \"],[1,[27,\"talents/talent-document\",null,[[\"talent\",\"field\",\"fullValue\",\"actionLabel\"],[[23,[\"talent\"]],[22,1,[]],[22,2,[]],[27,\"t\",[\"talent.documents.action_resend\"],null]]]],false],[0,\"\\n        \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[2]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-documents-list.hbs"
    }
  });

  _exports.default = _default;
});