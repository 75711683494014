define("teelt-frontend/components/notification/notification-slack-content", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['notification-content-block', 'sms-block'],
    showSlackContent: false,
    getSlackNotification: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.setSlackContentFromContents();

      if (this.notification.disabled) {
        this.send('onDisable');
      }

      if (this.withoutHeader) {
        this.send('toggleShowSlackContent');
      }
    },
    updateContent: Ember.observer('notification.name', 'showContentForOfficeId', 'showContentForTagId', function () {
      Ember.set(this, 'slack', this.getSlackContentFromContents());
    }),
    setSlackContentFromContents: function setSlackContentFromContents() {
      var slackContent = this.getSlackContentFromContents();

      if (slackContent) {
        Ember.set(this, "slack", slackContent);
      } else {
        var _slackContent = {
          contentType: "SLACK",
          body: "",
          disabled: true
        };
        this.notification.contents.push(_slackContent);
        Ember.set(this, "slack", _slackContent);
      }
    },
    getSlackContentFromContents: function getSlackContentFromContents() {
      if (!this.notification.contents) return;

      for (var i = 0; i < this.notification.contents.length; i++) {
        var content = this.notification.contents[i];
        if (content.contentType === "SLACK" && (this.tagMode && content.tagId == (this.showContentForTagId ? this.showContentForTagId : null) || !this.tagMode && content.officeId == (this.showContentForOfficeId ? this.showContentForOfficeId : null))) return this.notification.contents[i];
      }
    },
    actions: {
      toggleShowSlackContent: function toggleShowSlackContent() {
        var _this = this;

        Ember.set(this, 'showSlackContent', !this.showSlackContent);

        if (this.showSlackContent) {
          Ember.run.later(function () {
            _this.$('#templateMarkersSlackSelect').select2({});

            _this.bindOnChangeTemplateMarker();
          }, 0);
        }
      },
      onActivate: function onActivate() {
        if (!this.notification.contents) return;
        this.notification.contents.forEach(function (c) {
          if (c.contentType === "SLACK") {
            Ember.set(c, "disabled", false);
          }
        });
      },
      onDisable: function onDisable() {
        if (!this.notification.contents) return;
        this.notification.contents.forEach(function (c) {
          if (c.contentType === "SLACK") {
            Ember.set(c, "disabled", true);
          }
        });
      },
      onBodyChanged: function onBodyChanged() {
        this.get('getSlackNotification').setData(this.slack);

        if (this.onBodyChanged) {
          this.onBodyChanged();
        }
      }
    },
    bindOnChangeTemplateMarker: function bindOnChangeTemplateMarker() {
      var self = this;
      this.$('#templateMarkersSlackSelect').on('change', function () {
        if (!(0, _jquery.default)(this).find(':selected').data('marker')) {
          return;
        }

        Ember.set(self.slack, "body", self.slack.body + (0, _jquery.default)(this).find(':selected').data('marker'));
        self.send('onBodyChanged');
        (0, _jquery.default)(this).val("");
        (0, _jquery.default)(this).trigger('change'); //Reset the dropdown
      });
    }
  });

  _exports.default = _default;
});