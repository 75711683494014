define("teelt-frontend/components/menu/talent-menu-profile", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talent-menu-profile'],
    defaultProfileImageUrl: _environment.default.APP.DEFAULT_PROFILE_IMAGE_URL,
    session: Ember.inject.service(),
    talent: Ember.computed('session.data.authenticated.user', function () {
      return this.session.data.authenticated.user;
    })
  });

  _exports.default = _default;
});