define("teelt-frontend/components/talents/feedback-completed-nb", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    count: " ",
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    defaultProfileImageUrl: _environment.default.APP.DEFAULT_PROFILE_IMAGE_URL,
    filter: 'today',
    range: {
      startDate: null,
      endDate: null
    },
    params: {
      dateFilter: 'today',
      startDate: null,
      endDate: null
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.filterByCompletedFeedback();
    },
    filterByCompletedFeedback: function filterByCompletedFeedback(data) {
      var _this = this;

      Ember.set(this, 'loading', true);
      Ember.set(this, 'params', {});

      if (data) {
        Ember.set(this, 'params', {
          dateFilter: 'today',
          startDate: null,
          endDate: null,
          targetDateField: 'createdAt'
        });
        this.params.dateFilter = data.dateFilter;
        this.params.startDate = data.startDate;
        this.params.endDate = data.endDate;
        this.params.targetDateField = data.targetDateField;
      }

      this.ajax.requestWithUrlParamPromise(_environment.default.APP.API_HOST + '/users/count/feedbackCompleted', 'GET', this.params).then(function (data) {
        Ember.set(_this, 'users', data);
        Ember.set(_this, 'count', data.length);
        Ember.set(_this, 'loading', false);
      }).catch(function (error) {
        Ember.set(_this, 'loading', false);
      });
    },
    actions: {
      goto_arriving: function goto_arriving(label) {
        this.router.transitionTo('board.talent.talent-by-period', {
          queryParams: {
            dateFilter: this.params.dateFilter,
            startDate: this.params.startDate,
            endDate: this.params.endDate,
            label: label,
            filterType: 'countByCompletedFeedback',
            targetDateField: ''
          }
        });
      },
      loadData: function loadData(data) {
        this.filterByCompletedFeedback(data);
      }
    }
  });

  _exports.default = _default;
});