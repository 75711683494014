define("teelt-frontend/components/talents/modal/talents-update-officemanager-modal", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var path = this.userService.getUsersSearchPathForSelect('ROLE_CUSTOM_OFFICE_MANAGER');
      Ember.set(this, 'officeManagerPath', path);
    },
    actions: {
      generateManagerName: function generateManagerName(result) {
        return this.userService.getTalentName(result);
      },
      onClickOverlay: function onClickOverlay() {},
      onSelectChanged: function onSelectChanged(officeManagers) {
        Ember.set(this, 'selectedOfficeManager', officeManagers);
      },
      save: function save() {
        if (!this.selectedOfficeManager) return;
        this.updateTalentsOfficeManager(this.selectedOfficeManager);
        Ember.set(this, 'show', false);
      }
    }
  });

  _exports.default = _default;
});