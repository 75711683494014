define("teelt-frontend/components/communication/communication-result-send", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['communication-result-send'],
    ajax: Ember.inject.service(),
    keys: ['firstname', 'lastname', 'deliverDate', 'openDate', 'clickDate'],
    tooltips: {
      deliverDate: 'communication.tooltip.delivered',
      openDate: 'communication.tooltip.opened',
      clickDate: 'communication.tooltip.clicked'
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var path = _environment.default.APP.API_HOST + '/communications/' + this.campaign.id + '/sends/' + this.sendId;
      this.ajax.requestPromise(path).then(function (send) {
        Ember.set(_this, 'send', send);
      });
    },
    openCount: Ember.computed('send.mails', function () {
      var count = 0;

      if (this.send.mails) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.send.mails[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var email = _step.value;

            if (email.openDate) {
              count++;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      return count;
    }),
    clickCount: Ember.computed('send.mails', function () {
      var count = 0;

      if (this.send.mails) {
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = this.send.mails[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var email = _step2.value;

            if (email.clickDate) {
              count++;
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      }

      return count;
    })
  });

  _exports.default = _default;
});