define("teelt-frontend/components/talents/modal/talents-update-date-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var talent = _defineProperty({}, this.dateKey, moment().set("hour", 9).set("minute", 0));

      Ember.set(this, 'talent', talent);
    },
    actions: {
      onClickOverlay: function onClickOverlay() {},
      save: function save() {
        this.updateTalentsDate(this.talent[this.dateKey], this.dateKey);
        Ember.set(this, 'show', false);
      }
    }
  });

  _exports.default = _default;
});