define("teelt-frontend/components/form/field/field-choice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['field-choice'],
    actions: {
      delete: function _delete() {
        if (this.onDelete) this.onDelete(this.choice);
      },
      switchValid: function switchValid() {
        Ember.set(this.choice, 'isValid', !this.choice.isValid);
        if (this.onChangeValid) this.onChangeValid(this.index);
      }
    }
  });

  _exports.default = _default;
});