define("teelt-frontend/templates/components/utils/banner/meet-people-inactivity-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oq2/vYfv",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"showBanner\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"banner-content\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"btn-close btn-transparent foreground-primary-color\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showBanner\"]]],null],false]],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"close\"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"banner-body\"],[9],[0,\"\\n      \"],[7,\"span\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"meetpeople.inactivity_title\"],null],true],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"clickable\"],[3,\"action\",[[22,0,[]],\"redirectToIhm\"]],[9],[0,\"\\n          | \"],[7,\"b\"],[9],[7,\"i\"],[9],[7,\"u\"],[9],[1,[27,\"t\",[\"meetpeople.inactivity_button\"],null],false],[10],[10],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/banner/meet-people-inactivity-banner.hbs"
    }
  });

  _exports.default = _default;
});