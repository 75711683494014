define("teelt-frontend/templates/components/ihm/meet-people/meet-people-participants-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oLo5wB6y",
    "block": "{\"symbols\":[\"participant\"],\"statements\":[[7,\"h1\"],[9],[1,[27,\"t\",[\"meetpeople.participant_list_title\"],[[\"count\"],[[23,[\"meetPeople\",\"nbParticipants\"]]]]],false],[10],[0,\"\\n\"],[7,\"h6\"],[11,\"class\",\"mt-20\"],[9],[1,[27,\"t\",[\"meetpeople.participant_list_subtitle1\"],null],false],[10],[0,\"\\n\"],[7,\"h6\"],[9],[1,[27,\"t\",[\"meetpeople.participant_list_subtitle2\"],null],false],[10],[0,\"\\n\"],[7,\"h6\"],[9],[1,[27,\"t\",[\"meetpeople.participant_list_subtitle3\"],null],false],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"center mt-20\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn-primary waves-effect waves-light background-primary-color clickable \"],[3,\"action\",[[22,0,[]],\"showGallery\"]],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"material-icons left\"],[11,\"style\",\"float:left!important\"],[9],[0,\"collections\"],[10],[0,\"\\n      \"],[1,[27,\"t\",[\"meetpeople.gallery_lite\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[28,[\"participant-container mt-20 \",[21,\"class\"]]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"meetPeople\",\"participants\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"talents/talent-card\",null,[[\"talent\",\"showAction\"],[[22,1,[]],[23,[\"showTalentCardAction\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/ihm/meet-people/meet-people-participants-list.hbs"
    }
  });

  _exports.default = _default;
});