define("teelt-frontend/components/utils/search-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['search-bar'],
    intl: Ember.inject.service(),
    search_ph: "",
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, "search_ph", this.intl.t("word.search"));
    },
    actions: {
      keyUpAction: function keyUpAction() {
        var _this = this;

        if (this.keyUpAction) {
          Ember.run.cancel(this.keyUpAction);
        }

        var keyUpAction = Ember.run.later(function () {
          _this.searchAction(_this.searchValue);
        }, 500);
        Ember.set(this, "keyUpAction", keyUpAction);
      }
    }
  });

  _exports.default = _default;
});