define("teelt-frontend/models/task", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    assign: _emberData.default.attr('string'),
    period: _emberData.default.attr('string'),
    auto: _emberData.default.attr('boolean'),
    type: _emberData.default.attr('string'),
    mandatory: _emberData.default.attr('boolean'),
    startOffset: _emberData.default.attr(),
    startOffsetUnit: _emberData.default.attr(),
    endOffset: _emberData.default.attr(),
    form: _emberData.default.attr(),
    content: _emberData.default.attr(),
    notification: _emberData.default.attr(),
    createdById: _emberData.default.attr(),
    signContent: _emberData.default.attr(),
    extendedAssigns: _emberData.default.attr(),
    meetPeople: _emberData.default.attr(),
    exploreOffice: _emberData.default.attr()
  });

  _exports.default = _default;
});