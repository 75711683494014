define("teelt-frontend/routes/board/company/company-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.session.data.authenticated.user.roles.includes('ROLE_ADMIN')) {
        this.router.transitionTo('board.talents');
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        companyId: params.company_id,
        company: this.store.findRecord('company', params.company_id)
      });
    }
  });

  _exports.default = _default;
});