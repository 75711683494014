define("teelt-frontend/components/communication/communication-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['communication-edit'],
    router: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    step: 'selectAudience',
    canContinue: Ember.computed('campaign.{audience,name,scheduleDate}', 'step', function () {
      if (this.step == 'selectAudience') {
        if (!this.campaign.audience) return false;
      }

      return true;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.campaign) {
        var campaign = this.store.createRecord('communication', {
          notification: {}
        });
        Ember.set(this, 'campaign', campaign);
        Ember.set(this, 'edited', false);
      }
    },
    prepareNewNotification: function prepareNewNotification() {
      if (this.newNotification) return;
      var notification = this.store.createRecord('notification', {
        type: 'CUSTOM',
        recipient: 'TALENT',
        triggerInitiator: 'action_start',
        contents: [{
          contentType: "EMAIL",
          lang: 'fr',
          disabled: true,
          subject: "",
          body: "",
          replyTo: ""
        }, {
          contentType: "SMS",
          lang: 'fr',
          disabled: true,
          body: ""
        }]
      });
      Ember.set(this, 'newNotification', notification);
    },
    actions: {
      previous: function previous() {
        switch (this.step) {
          case 'selectAudience':
            this.router.transitionTo('board.communications');
            break;

          case 'selectNotification':
            Ember.set(this, 'step', 'selectAudience');
            break;

          case 'newNotification':
            Ember.set(this, 'step', 'selectNotification');
            break;

          case 'planning':
            if (this.onlyPlanning) {
              this.router.transitionTo('board.communications');
              break;
            }

            if (this.campaign.notification.id) {
              Ember.set(this, 'step', 'selectNotification');
            } else {
              Ember.set(this, 'step', 'newNotification');
            }

            break;
        }
      },
      next: function next() {
        if (!this.canContinue) return;

        switch (this.step) {
          case 'selectAudience':
            Ember.set(this, 'step', 'selectNotification');
            break;

          case 'selectNotification':
            if (this.campaign.notification.id) {
              Ember.set(this, 'step', 'planning');
            } else {
              this.prepareNewNotification();
              Ember.set(this, 'step', 'newNotification');
            }

            break;

          case "newNotification":
            Ember.set(this, 'step', 'planning');
            break;
        }
      },
      selectNotif: function selectNotif(notif) {
        if (notif) {
          Ember.set(this.campaign.notification, 'id', notif.id);
          Ember.set(this.campaign, 'name', notif.name);
          Ember.set(this, 'lastStep', true);
        } else {
          Ember.set(this.campaign.notification, 'id', undefined);
          Ember.set(this, 'lastStep', false);
        }
      },
      save: function save() {
        var _this = this;

        if (this.campaign.notification.id) {
          this.campaign.save().then(function () {
            _this.notify.info(_this.intl.t("communication.notify.created"));

            _this.router.transitionTo('board.communications');
          });
        } else {
          this.newNotification.save().then(function (notif) {
            Ember.set(_this.campaign, 'name', notif.name);
            Ember.set(_this.campaign.notification, 'id', notif.id);

            _this.campaign.save().then(function () {
              _this.notify.info(_this.intl.t("communication.notify.created"));

              _this.router.transitionTo('board.communications');
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});