define("teelt-frontend/components/ihm/explore-office/explore-office-field-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['explore-office-field-preview'],
    init: function init() {
      this._super.apply(this, arguments);
    },
    valueObserver: Ember.observer('value', function () {
      if (this.responseIndex != undefined) {
        var fieldValue = '' + (this.field.value ? this.field.value : '');
        var tmp = fieldValue.split('||');

        for (var i = tmp.length; i < this.responseIndex + 1; i++) {
          tmp.push('');
        }

        tmp[this.responseIndex] = Array.isArray(this.value) ? JSON.stringify(this.value) : this.value;
        Ember.set(this.field, 'value', tmp.join('||'));
      } else {
        Ember.set(this.field, 'value', this.value);
      }
    }),
    didInsertElement: function didInsertElement() {},
    actions: {}
  });

  _exports.default = _default;
});