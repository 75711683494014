define("teelt-frontend/templates/components/talents/talents-periods-slick-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f1fcCzL3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"title\"],[9],[1,[23,[\"period\",\"name\"]],false],[10],[0,\"\\n\"],[4,\"link-to\",[\"talent.integration.tasks\"],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"btn g-talent-btn selected\"],[3,\"action\",[[22,0,[]],\"onActionTriggered\"]],[9],[0,\" \\n\"],[4,\"if\",[[23,[\"highlight\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"talent.open_actions_done\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[21,\"actionLabel\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talents-periods-slick-card.hbs"
    }
  });

  _exports.default = _default;
});