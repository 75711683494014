define("teelt-frontend/components/content/content-detail-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    initSlideshow: function initSlideshow() {
      if (this.contentDetail.type === 'slideshow' && this.contentDetail.fullValue && !this.contentDetail.imageUrlsArray) {
        Ember.set(this.contentDetail, 'imageUrlsArray', this.contentDetail.fullValue.split(';'));
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.initSlideshow();
    },
    destroy: function destroy() {
      this.$('.content-detail-popup-background').off('mousedown touchstart', this.onMouseDown);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});