define("teelt-frontend/templates/board/setting/integration/lucca", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mhyTMD6e",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"lucca\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"section-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"g-section-title foreground-primary-color\"],[9],[0,\"\\n            \"],[7,\"h1\"],[9],[0,\"Lucca\"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"baseline\"],[9],[1,[27,\"t\",[\"settings.integrations.lucca.baseline2\"],null],false],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n       \"],[1,[21,\"settings/integrations/lucca-settings\"],false],[0,\"\\n      \\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/setting/integration/lucca.hbs"
    }
  });

  _exports.default = _default;
});