define("teelt-frontend/components/content/contents-generic-list", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['contents-generic-list'],
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    customErrorService: Ember.inject.service('custom-error'),
    session: Ember.inject.service(),
    search: '',
    allCategoriesSelected: true,
    nbSelected: Ember.computed('contents', function () {
      var nb = 0;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.contents[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var content = _step.value;
          if (content.selected) nb++;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return nb;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.canManage && this.session.data.authenticated.user.company.tagMode) {
        Ember.set(this, 'forceTag', -1);
        this.loadExistingTags();
      }

      this.loadGenericContents();
    },
    loadExistingTags: function loadExistingTags() {
      var _this = this;

      var path = _environment.default.APP.API_HOST + '/tags';
      this.ajax.requestPromise(path, 'GET').then(function (result) {
        var tags = result && result.length ? result : [];
        Ember.set(_this, 'existingTags', tags);
        setTimeout(function () {
          _this.$('#tag-select').formSelect();
        }, 0);
      });
    },
    extractCategories: function extractCategories() {
      var categories = [];
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = this.contents[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var content = _step2.value;

          if (content.category) {
            if (!categories.includes(content.category.name)) {
              categories.push(content.category.name);
            }
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      Ember.set(this, 'categories', categories);

      if (!this.selectedCategories) {
        Ember.set(this, 'selectedCategories', [].concat(categories));
      }
    },
    loadGenericContents: function loadGenericContents() {
      var _this2 = this;

      this.store.query('content', {
        filter: 'generic',
        search: this.search
      }).then(function (contents) {
        var contentsArray = contents.toArray();
        var currentContents = _this2.contents;

        if (!currentContents) {
          currentContents = contentsArray;
        }

        Ember.set(_this2, 'contents', currentContents);

        _this2.selectContents();

        _this2.extractCategories();
      });
    },
    selectContents: function selectContents() {
      this.contents.forEach(function (c) {
        Ember.set(c, 'selected', undefined);
        Ember.set(c, 'disabled', undefined);
      });
      var contentsAddedIds = [];

      if (this.selectedContents) {
        var ids = this.selectedContents.map(function (c) {
          if (c.genericContent) {
            contentsAddedIds.push(c.genericContent.id);
            return Number(c.genericContent.id);
          }

          return Number(c.id);
        });
        this.contents.forEach(function (c) {
          if (ids.indexOf(Number(c.id)) >= 0) {
            Ember.set(c, 'selected', true);
          }

          if (contentsAddedIds.indexOf(Number(c.id)) >= 0) {
            Ember.set(c, 'disabled', true);
          }
        });
        var selecteds = this.contents.filter(function (c) {
          return c.selected;
        }).filter(function (c) {
          return !c.disabled;
        });
        var disableds = this.contents.filter(function (c) {
          return c.disabled;
        });
        Ember.set(this, 'selectedContents', selecteds);
        Ember.set(this, 'originalSelectedContents', disableds);
      }
    },
    actions: {
      searchContent: function searchContent() {
        this.set('contents', undefined);
        this.set('offset', 0);
        this.loadGenericContents();
      },
      loadMore: function loadMore() {
        this.loadGenericContents();
      },
      editContent: function editContent(contentId) {
        this.router.transitionTo('board.content.content-edit', contentId);
      },
      removeContent: function removeContent(contentId) {
        Ember.set(this, "contentToRemoveId", contentId);
        Ember.set(this, "showRemovePopup", true);
      },
      removeConfirm: function removeConfirm(response) {
        var _this3 = this;

        if (response) {
          var path = _environment.default.APP.API_HOST + '/contents/' + this.contentToRemoveId;
          this.ajax.requestPromise(path, 'DELETE').then(function () {
            _this3.set('contents', undefined);

            _this3.set('offset', 0);

            _this3.loadGenericContents();
          });
        }
      },
      onTagChanged: function onTagChanged(tagId) {
        var _this4 = this;

        Ember.set(this, 'forceTag', Number(tagId));
        setTimeout(function () {
          Ember.set(_this4, 'rand', new Date());
        });
      },
      switchCategory: function switchCategory(category) {
        if (this.selectedCategories.includes(category)) {
          var categories = this.selectedCategories.filter(function (value, index, arr) {
            return value !== category;
          });
          Ember.set(this, 'selectedCategories', categories);
        } else {
          var _categories = this.selectedCategories;

          _categories.push(category);

          Ember.set(this, 'selectedCategories', undefined);
          Ember.set(this, 'selectedCategories', _categories);
        }

        if (this.categories.length === this.selectedCategories.length) {
          Ember.set(this, 'allCategoriesSelected', true);
          return;
        }

        Ember.set(this, 'allCategoriesSelected', false);
      },
      selectAllCategories: function selectAllCategories() {
        if (typeof this.selectedCategories !== "undefined") {
          if (this.categories.length === this.selectedCategories.length) {
            Ember.set(this, 'selectedCategories', []);
            Ember.set(this, 'allCategoriesSelected', false);
            return;
          }
        }

        Ember.set(this, 'selectedCategories', this.categories);
        Ember.set(this, 'allCategoriesSelected', true);
      }
    }
  });

  _exports.default = _default;
});