define("teelt-frontend/routes/board/custom-field/custom-field-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        fieldId: params.field_id,
        field: this.store.findRecord('custom-field', params.field_id)
      });
    }
  });

  _exports.default = _default;
});