define("teelt-frontend/components/utils/modal/basic-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onConfirm: function onConfirm() {
        if (this.onConfirm) {
          this.onConfirm();
        }
      }
    }
  });

  _exports.default = _default;
});