define("teelt-frontend/routes/board/setting/integration/slack", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    queryParams: {
      code: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      if (params.code) {
        var url = _environment.default.APP.API_HOST + "/integrations/slack/callback";
        this.ajax.requestWithBodyPromise(url, 'POST', 'application/json', JSON.stringify({
          code: params.code,
          state: "params.state"
        })).then(function (response) {
          console.log(response);

          if (response == 'true') {
            Ember.set(_this.session.data.authenticated.user.company, 'isSlackToken', true);

            _this.session.store.persist(_this.session.data);

            _this.transitionTo('board.setting.integrations');
          } else {
            _this.transitionTo('board.setting.integrations');
          }
        }).catch(function (error) {
          console.log(error);

          _this.transitionTo('board.setting.integrations');
        });
      } else {
        this.transitionTo('board.setting.integrations');
      }
    }
  });

  _exports.default = _default;
});