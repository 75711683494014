define("teelt-frontend/components/utils/expendable-text", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mobileView: window.innerWidth < 600,
    init: function init() {
      this._super.apply(this, arguments);

      var id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
      });
      Ember.set(this, 'id', id);
      Ember.set(this, 'defaultContainerHeight', (0, _jquery.default)("#" + this.containerId).height());
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      Ember.set(this, 'currentContainerHeight', (0, _jquery.default)("#" + this.containerId).height());
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.checkExtendable();
    },
    onTextChanged: Ember.observer('text', function () {
      this.checkExtendable();
    }),
    onLineThrough: Ember.observer('lineThrough', function () {
      var _this = this;

      if (this.lineThrough) {
        Ember.set(this, 'lineThroughAnimate', true);
        Ember.run.later(function () {
          Ember.set(_this, 'lineThroughAnimate', false);
        }, 500);
      }
    }),
    checkExtendable: function checkExtendable() {
      Ember.set(this, 'expendableText', undefined);

      if (this.text) {
        Ember.set(this, 'expendableText', this.text);
        this.limitExpendableText();
      }
    },
    limitExpendableText: function limitExpendableText() {
      if (this.mobileView) {
        var mobileLength = this.mobileLength ? this.mobileLength : 70;

        if (this.expendableText.length > mobileLength) {
          Ember.set(this, 'expendableText', this.expendableText.substring(0, mobileLength));
          Ember.set(this, 'showReadMore', true);
          Ember.set(this, 'isOverflow', true);
        }
      } else {
        (0, _jquery.default)('#' + this.id).css('visibility', 'hidden'); //later(() => {

        if (this.isElementOverflowing()) {
          var newText = this.expendableText.substring(0, this.expendableText.length - 5);
          Ember.set(this, 'isOverflow', true);

          if (!this.dontReadMore) {
            Ember.set(this, 'showReadMore', true);
          }

          Ember.set(this, 'expendableText', newText);
          this.limitExpendableText();
        } else {
          (0, _jquery.default)('#' + this.id).css('visibility', 'visible');
        } //});

      }
    },
    resizeContainer: function resizeContainer() {
      var container = (0, _jquery.default)('#' + this.containerId);

      if (this.defaultContainerHeight !== this.oldContainerHeight || this.currentContainerHeight !== container.height()) {
        (0, _jquery.default)("#" + this.containerId).css('height', 'fit-content');
      } else {
        (0, _jquery.default)("#" + this.containerId).css('height', '');
      }
    },
    isElementOverflowing: function isElementOverflowing() {
      if (this.expendableText.length < 50) return false;
      var element = this.$();
      var container = (0, _jquery.default)('#' + this.containerId);
      var computedContainerHeight = parseInt(window.getComputedStyle(document.querySelector('#' + this.containerId)).height);

      if (container && element && (container.height() < element.height() || container.height() < computedContainerHeight)) {
        // your element have overflow
        return true;
      } else {
        //your element don't have overflow
        return false;
      }
    },
    actions: {
      readMore: function readMore() {
        Ember.set(this, 'oldContainerHeight', (0, _jquery.default)("#" + this.containerId).height());
        Ember.set(this, 'oldExpendableText', this.expendableText);
        (0, _jquery.default)("#" + this.containerId).css({
          height: 'auto',
          maxHeight: 'none'
        });
        Ember.set(this, 'expendableText', this.text);
        Ember.set(this, 'showReadMore', false);
        Ember.set(this, 'showReadLess', true);

        if (this.onAction) {
          this.onAction('MORE', this.onActionParam);
        }
      },
      readLess: function readLess() {
        Ember.set(this, 'showReadLess', false);
        Ember.set(this, 'showReadMore', true);
        Ember.set(this, 'expendableText', this.oldExpendableText);
        this.resizeContainer();

        if (this.onAction) {
          this.onAction('LESS', this.onActionParam);
        }
      }
    }
  });

  _exports.default = _default;
});