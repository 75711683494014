define("teelt-frontend/templates/components/icon/flag/it-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8UdyG9nl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"width\",\"14\"],[11,\"height\",\"14\"],[11,\"viewBox\",\"0 0 64 64\"],[11,\"fill\",\"none\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[9],[0,\"\\n  \"],[7,\"path\"],[11,\"d\",\"M1.7002 32.0002C1.7002 45.1002 10.1002 56.2002 21.7002 60.3002V3.7002C10.1002 7.8002 1.7002 18.9002 1.7002 32.0002Z\"],[11,\"fill\",\"#75A843\"],[9],[10],[0,\"\\n  \"],[7,\"path\"],[11,\"d\",\"M61.7002 32.0002C61.7002 18.9002 53.3002 7.8002 41.7002 3.7002V60.3002C53.4002 56.2002 61.7002 45.1002 61.7002 32.0002Z\"],[11,\"fill\",\"#ED4C5C\"],[9],[10],[0,\"\\n  \"],[7,\"path\"],[11,\"d\",\"M21.7002 60.3C24.8002 61.4 28.2002 62 31.7002 62C35.2002 62 38.6002 61.4 41.7002 60.3V3.7C38.6002 2.6 35.2002 2 31.7002 2C28.2002 2 24.8002 2.6 21.7002 3.7V60.3Z\"],[11,\"fill\",\"white\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/icon/flag/it-svg.hbs"
    }
  });

  _exports.default = _default;
});