define("teelt-frontend/components/utils/date-time-picker", ["exports", "teelt-frontend/utils/string-util"], function (_exports, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['date-time-picker'],
    uiUtils: Ember.inject.service('ui-utils'),
    times: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'],
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'uuid', (0, _stringUtil.default)().generateUUID());
      var hours = 9;
      var minutes = 0;

      if (this.defaultDate) {
        Ember.set(this, 'date', moment(this.defaultDate));
        hours = moment(this.defaultDate).hours();
        minutes = moment(this.defaultDate).minutes();
      } else {
        Ember.set(this, 'date', '');
      }

      Ember.set(this, 'time', (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.initDatepicker();
      this.initTimepicker();
    },
    initDatepicker: function initDatepicker() {
      var _this = this;

      setTimeout(function () {
        var options = {
          autoClose: false,
          setDefaultDate: true,
          defaultDate: _this.defaultDate ? _this.defaultDate : new Date()
        };

        if (_this.minDate) {
          options.minDate = _this.minDate;
        }

        _this.uiUtils.initDatePicker(_this.$('#date-picker-' + _this.uuid), function (result) {
          if (!result) {
            result = _this.defaultDate ? _this.defaultDate : new Date();
          }

          Ember.set(_this, 'date', moment.tz(result, moment.tz.guess()));
          setTimeout(function () {
            _this.updateTalentDate();
          }, 300);
        }, options);

        _this.$('.datepicker-footer').before(_this.$('#time-block'));

        if (_this.openAtInit) {
          _this.$('#date-picker-' + _this.uuid).datepicker('open');
        }
      });
    },
    updateTalentDate: function updateTalentDate() {
      var date = this.date;
      if (!date) return;
      var timeTmp = this.time.split(':');
      var dateTime = date.clone();
      dateTime.hour(Number(timeTmp[0]));
      dateTime.minute(Number(timeTmp[1]));
      Ember.set(this, 'defaultDate', dateTime.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'));

      if (this.onChange) {
        if (this.withoutUtc) {
          this.onChange(dateTime.format('YYYY-MM-DDTHH:mm:ssZ'));
        } else {
          this.onChange(dateTime.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'));
        }
      }
    },
    initTimepicker: function initTimepicker() {
      var _this2 = this;

      setTimeout(function () {
        _this2.$('.select').formSelect();

        _this2.$('.time-block .dropdown-trigger').on('click', function () {
          var container = _this2.$('.time-block .dropdown-content');

          if (container) {
            var targetElement = container.find('li span').filter(function (index, span) {
              return _this2.$(span).text().trim() === '08:00';
            }).parent();

            if (targetElement.length > 0) {
              var offsetTop = targetElement[0].offsetTop;
              container.scrollTop(offsetTop);
            }
          }
        });
      });
    },
    actions: {
      onTimeChanged: function onTimeChanged(time) {
        Ember.set(this, 'time', time);
      }
    }
  });

  _exports.default = _default;
});