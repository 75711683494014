define("teelt-frontend/services/notification-marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    staticlikeData: Ember.inject.service('staticlike-data'),
    session: Ember.inject.service(),
    getMarkers: function getMarkers(notification) {
      if (notification.type === 'NEW_CAMPAIGN') {
        return {
          campaign: [{
            name: 'campaign_name',
            marker: '%CAMPAIGN_NAME%'
          }, {
            name: 'campaign_place',
            marker: '%CAMPAIGN_PLACE%'
          }, {
            name: 'campaign_date_start',
            marker: '%CAMPAIGN_DATE_START%'
          }, {
            name: 'campaign_date_end',
            marker: '%CAMPAIGN_DATE_END%'
          }, {
            name: 'campaign_events',
            marker: '%CAMPAIGN_EVENTS%'
          }]
        };
      }

      if (notification.type === "NEW_ONE_TO_ONE" || notification.type === "REMINDER_EVENTS") {
        return {
          events: [{
            name: 'events',
            marker: '%EVENTS%'
          }]
        };
      }

      var talentMarkers = [{
        name: 'talent_firstname',
        marker: '%TALENT_FIRST_NAME%'
      }, {
        name: 'talent_lastname',
        marker: '%TALENT_LAST_NAME%'
      }, {
        name: 'talent_proEmail',
        marker: '%TALENT_EMAIL_PRO%'
      }, {
        name: 'talent_persoEmail',
        marker: '%TALENT_EMAIL_PERSO%'
      }, {
        name: 'talent_welcomeDate',
        marker: '%TALENT_WELCOME_DATE%'
      }, {
        name: 'talent_welcomeHour',
        marker: '%TALENT_WELCOME_HOUR%'
      }, {
        name: 'talent_endDate',
        marker: '%TALENT_END_DATE%'
      }, {
        name: 'talent_endHour',
        marker: '%TALENT_END_HOUR%'
      }, {
        name: 'talent_transferDate',
        marker: '%TALENT_TRANSFER_DATE%'
      }, {
        name: 'talent_transferHour',
        marker: '%TALENT_TRANSFER_HOUR%'
      }, {
        name: 'talent_phoneCode',
        marker: '%TALENT_PHONE_CODE%'
      }, {
        name: 'talent_phoneNumber',
        marker: '%TALENT_PHONE_NUMBER%'
      }, {
        name: 'talent_position',
        marker: '%TALENT_FUNCTION%'
      }, {
        name: 'talent_experience',
        marker: '%TALENT_EXPERIENCE%'
      }, {
        name: 'talent_contract',
        marker: '%TALENT_CONTRACT%'
      }, {
        name: 'talent_team',
        marker: '%TALENT_TEAM%'
      }, {
        name: 'talent_task_list_period_before',
        marker: '%TALENT_TASK_LIST_PERIOD_BEFORE%'
      }, {
        name: 'talent_task_list_period_onboarding',
        marker: '%TALENT_TASK_LIST_PERIOD_ONBOARDING%'
      }, {
        name: 'talent_task_list_period_after',
        marker: '%TALENT_TASK_LIST_PERIOD_AFTER%'
      }, {
        name: 'talent_invit_link',
        marker: '%TALENT_INVIT_LINK%'
      }, {
        name: 'company_name',
        marker: '%COMPANY_NAME%'
      }, {
        name: 'platform_link',
        marker: '%PLATFORM_LINK%'
      }, {
        name: 'manager_firstname',
        marker: '%MANAGER_FIRST_NAME%'
      }, {
        name: 'manager_lastname',
        marker: '%MANAGER_LAST_NAME%'
      }, {
        name: 'manager_email',
        marker: '%MANAGER_EMAIL%'
      }, {
        name: 'link_talent_board_account',
        marker: '%LINK_TALENT_BOARD_ACCOUNT%'
      }, {
        name: 'campaign_name',
        marker: '%CAMPAIGN_NAME%'
      }, {
        name: 'campaign_place',
        marker: '%CAMPAIGN_PLACE%'
      }, {
        name: 'campaign_date_start',
        marker: '%CAMPAIGN_DATE_START%'
      }, {
        name: 'campaign_date_end',
        marker: '%CAMPAIGN_DATE_END%'
      }, {
        name: 'campaign_events',
        marker: '%CAMPAIGN_EVENTS%'
      }, {
        name: 'consult_role',
        marker: '%CONSULT_ROLE%'
      }, {
        name: 'talent_trialPeriodEndDate',
        marker: '%TALENT_TRIAL_PERIOD_END_DATE%'
      }, {
        name: 'document_sign_url',
        marker: '%DOC_SIGN_URL%'
      }, {
        name: 'email_incident',
        marker: '%LIST_INCIDENT_EMAILS%'
      }];

      if (this.session.data.authenticated.user.company.activeOMRole) {
        talentMarkers.push({
          name: 'officemanager_firstname',
          marker: '%OFFICEMANAGER_FIRST_NAME%'
        }, {
          name: 'officemanager_lastname',
          marker: '%OFFICEMANAGER_LAST_NAME%'
        }, {
          name: 'officemanager_email',
          marker: '%OFFICEMANAGER_EMAIL%'
        }, {
          name: 'officemanager_fullname',
          marker: '%OFFICEMANAGER_FULLNAME%'
        });
      }

      if (this.session.data.authenticated.user.company.activeBuddyRole) {
        talentMarkers.push({
          name: 'buddy_firstname',
          marker: '%BUDDY_FIRST_NAME%'
        }, {
          name: 'buddy_lastname',
          marker: '%BUDDY_LAST_NAME%'
        }, {
          name: 'buddy_email',
          marker: '%BUDDY_EMAIL%'
        });
      }

      if (this.staticlikeData.companyCustomFields) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.staticlikeData.companyCustomFields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var field = _step.value;
            if (field.type === 'file' || field.type === 'select_users' || field.type === 'autocomplete_users' || field.type === 'multi_autocomplete_users') continue;
            var fieldNameNoSpaces = field.name.replace(' ', '_');
            talentMarkers.push({
              name: 'CF_' + field.id + '_' + fieldNameNoSpaces,
              customName: field.name,
              marker: '%' + 'CF_' + field.id + '_' + fieldNameNoSpaces + '%'
            });
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      if (notification.type == "INVALID_DOCUMENTS") {
        talentMarkers.push({
          name: 'invalid_documents',
          marker: '%INVALID_DOCUMENTS%'
        }, {
          name: 'invalid_documents_form_link',
          marker: '%INVALID_DOCUMENTS_FORM_LINK%'
        });
      }

      if (notification.type == "LOW_OPINION") {
        talentMarkers.push({
          name: 'form_link',
          marker: '%FORM_LINK%'
        }, {
          name: 'fields_with_low_opinion',
          marker: '%FIELDS_WITH_LOW_OPINION%'
        });
      }

      if (notification.type == "MISSING_DATA") {
        talentMarkers.push({
          name: 'talents_with_missing_data',
          marker: '%TALENTS_WITH_MISSING_DATA%'
        }, {
          name: 'talents_with_missing_data_link',
          marker: '%TALENTS_WITH_MISSING_DATA_LINK%'
        });
      }

      talentMarkers.push({
        name: 'email_signature',
        marker: '%EMAIL_SIGNATURE%'
      });
      return {
        talent: talentMarkers
      };
    }
  });

  _exports.default = _default;
});