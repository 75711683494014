define("teelt-frontend/helpers/task/is-task-assign-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isTaskAssignTo = isTaskAssignTo;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function isTaskAssignTo(_ref
  /*, hash*/
  ) {
    var _ref2 = _slicedToArray(_ref, 3),
        task = _ref2[0],
        possiblesAssign = _ref2[1],
        hideNotifications = _ref2[2];

    var taskAssign = task.assign;

    if (taskAssign == 'system' && task.periodNotificationRecipient && !hideNotifications) {
      taskAssign = task.periodNotificationRecipient.toLowerCase();
    }

    var canView = possiblesAssign.indexOf(taskAssign) >= 0 || possiblesAssign.indexOf(task.assign.split('-')[0]) >= 0;

    if (!canView && task.type == 'sign' && task.signContent && task.signContent.signatories) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = task.signContent.signatories[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var signatory = _step.value;

          if (signatory == "ROLE_TALENT") {
            canView = canView || possiblesAssign.indexOf('talent') >= 0;
          } else if (signatory == "ROLE_MANAGER") {
            canView = canView || possiblesAssign.indexOf('manager') >= 0;
          } else if (signatory == "ROLE_RH") {
            canView = canView || possiblesAssign.indexOf('rh') >= 0;
          } else if (signatory == "ROLE_CUSTOM_OFFICE_MANAGER") {
            canView = canView || possiblesAssign.indexOf('officeManager') >= 0;
          } else if (signatory == "ROLE_MRH") {
            canView = canView || possiblesAssign.indexOf('adminMRH') >= 0;
          } else if (signatory == "ROLE_ADMINISTRATIVE") {
            canView = canView || possiblesAssign.indexOf('administrative') >= 0;
          } else if (signatory.startsWith('ROLE_CONSULT-')) {
            signAssign.push(signatory);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }

    return canView;
  }

  var _default = Ember.Helper.helper(isTaskAssignTo);

  _exports.default = _default;
});