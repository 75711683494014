define("teelt-frontend/components/talents/dashboard-talents-list", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dashboard-talents-list'],
    defaultProfileImageUrl: _environment.default.APP.DEFAULT_PROFILE_IMAGE_URL,
    router: Ember.inject.service(),
    actions: {
      openTalent: function openTalent(id) {
        this.router.transitionTo('board.talent.talent-detail', id);
      }
    }
  });

  _exports.default = _default;
});