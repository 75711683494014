define("teelt-frontend/components/content/content-document", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['content-document'],
    classNameBindings: ['overrideByOffice'],
    fileUploadService: Ember.inject.service('file-upload'),
    customErrorService: Ember.inject.service('custom-error'),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    notify: Ember.inject.service(),
    contentService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      openFile: function openFile() {
        var _this = this;

        if (!this.content.fullValue) return;
        var downloadWaitingUrl = window.location.protocol + "//" + window.location.host + "/downloading";
        var tabOpen = window.open(downloadWaitingUrl, 'newtab');
        this.fileUploadService.getObjectUrl(this.content.fullValue).then(function (data) {
          var url = data.url;

          if (!_this.content.toDownload) {
            url += "&inline";
          }

          tabOpen.location = url;
        }).catch(function (err) {
          _this.customErrorService.handleAjaxError(err);
        });
      },
      showContentConditionsModal: function showContentConditionsModal(content) {
        Ember.set(this, 'currentConditionsContent', content);
        Ember.set(this, 'showContentConditionsModal', true);
      },
      removeContent: function removeContent(contentId) {
        Ember.set(this, "contentToRemoveId", contentId);
        Ember.set(this, "showRemovePopup", true);
      },
      removeConfirm: function removeConfirm(response) {
        var _this2 = this;

        if (response) {
          var path = _environment.default.APP.API_HOST + '/contents/' + this.contentToRemoveId;
          this.ajax.requestPromise(path, 'DELETE').then(function () {
            if (_this2.onChange) _this2.onChange();
          });
        }
      }
    }
  });

  _exports.default = _default;
});