define("teelt-frontend/components/notification/notifications-list", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['notifications-list'],
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    ajax: Ember.inject.service(),
    customErrorService: Ember.inject.service('custom-error'),
    session: Ember.inject.service(),
    offset: 0,
    limit: 10,
    canLoadMore: false,
    search: '',
    order: undefined,
    displayMode: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.notifications) {
        Ember.set(this, 'displayMode', true);
      } else {
        this.store.unloadAll('notification');
        this.send("loadMore");
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.eventBus.on('SCROLLBOTTOM', this, function () {
        if (_this.canLoadMore) {//this.send("loadMore");
        }
      });
    },
    destroy: function destroy() {
      this.eventBus.off('SCROLLBOTTOM');

      this._super.apply(this, arguments);
    },
    actions: {
      searchNotification: function searchNotification(search) {
        this.set('search', search);
        this.set('notifications', undefined);
        this.set('offset', 0);
        this.set('canLoadMore', false);
        this.send('loadMore');
      },
      loadMore: function loadMore() {
        var _this2 = this;

        var orderType = "id";
        var orderDirection = "DESC";

        if (this.order && this.order.type) {
          orderType = this.order.type;
          orderDirection = this.order.direction;
        }

        var type = ['CUSTOM'];

        if (this.showSystemEmails) {
          type = ['NEW_ACCOUNT_RH', 'NEW_ACCOUNT_MANAGER', 'NEW_ACCOUNT_TALENT', 'FORGOT_PASSWORD', 'NEW_CAMPAIGN', 'NEW_ONE_TO_ONE', 'REMINDER_EVENTS', 'WEEKLY_REPORT_RH', 'WEEKLY_REPORT_OM', 'WEEKLY_REPORT_MANAGER', 'NEW_ACCOUNT_ADMINISTRATIVE', 'ADMIN_FORM_DISPO', 'NEW_ACCOUNT_CONSULT'];
        }

        var params = {
          search: this.search,
          orderType: orderType,
          orderDirection: orderDirection,
          type: type.join()
        };

        if (!this.showSystemEmails) {
          params.offset = this.offset;
          params.limit = this.limit;
        }

        this.store.query('notification', params).then(function (notifications) {
          Ember.set(_this2, "canLoadMore", true);
          var notificationsArray = notifications.toArray();
          var currentNotifications = _this2.notifications;

          if (!currentNotifications) {
            currentNotifications = notificationsArray;
          } else if (notificationsArray.length > 0) {
            currentNotifications = currentNotifications.concat(notificationsArray);
          }

          if (_this2.showSystemEmails || notificationsArray.length < _this2.limit) {
            Ember.set(_this2, "canLoadMore", false);
          }

          Ember.set(_this2, 'notifications', currentNotifications);
          _this2.offset += _this2.limit;
        });
      },
      openNotification: function openNotification(id) {
        this.router.transitionTo('board.notification.notification-detail', id);
      },
      changeOrder: function changeOrder(type) {
        if (this.order) {
          if (this.order.type == type) {
            if (this.order.direction == 'ASC') {
              Ember.set(this.order, 'direction', 'DESC');
              this.send('searchNotification', this.search);
            } else if (this.order.direction == 'DESC') {
              Ember.set(this, 'order', undefined);
              this.send('searchNotification', this.search);
            }
          } else {
            Ember.set(this, 'order', {
              type: type,
              direction: "ASC"
            });
            this.send('searchNotification', this.search);
          }
        } else {
          Ember.set(this, 'order', {
            type: type,
            direction: "ASC"
          });
          this.send('searchNotification', this.search);
        }
      },
      onActivate: function onActivate(notification) {
        var _this3 = this;

        var path = _environment.default.APP.API_HOST + '/notifications/' + notification.id + '/disable?disable=false';
        this.ajax.requestPromise(path, 'POST').catch(function (err) {
          Ember.set(notification, 'disabled', !notification.disabled);

          _this3.customErrorService.handleAjaxError(err);
        });
      },
      onDisable: function onDisable(notification) {
        var _this4 = this;

        var path = _environment.default.APP.API_HOST + '/notifications/' + notification.id + '/disable?disable=true';
        this.ajax.requestPromise(path, 'POST').catch(function (err) {
          Ember.set(notification, 'disabled', !notification.disabled);

          _this4.customErrorService.handleAjaxError(err);
        });
      }
    }
  });

  _exports.default = _default;
});