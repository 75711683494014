define("teelt-frontend/routes/board/notification/notification-new", ["exports", "teelt-frontend/routes/parent/confirm-before-transition"], function (_exports, _confirmBeforeTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _confirmBeforeTransition.default.extend({
    queryParams: {
      duplicateId: ''
    },
    model: function model(params) {
      var parentModel = this._super(params);

      parentModel.duplicateId = params.duplicateId;
      return parentModel;
    },
    resetController: function resetController(controller) {
      controller.set('duplicateId', null);
    }
  });

  _exports.default = _default;
});