define("teelt-frontend/templates/components/utils/slideshow-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M9KDEuv8",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\"],[11,\"class\",\"glide\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"glide__track\"],[11,\"data-glide-el\",\"track\"],[9],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"glide__slides\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[11,\"class\",\"glide__slide\"],[9],[0,\"\\n          \"],[7,\"img\"],[12,\"src\",[28,[[22,1,[]]]]],[11,\"alt\",\"\"],[11,\"class\",\"glide_item\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n \"],[7,\"div\"],[11,\"class\",\"glide__arrows\"],[11,\"data-glide-el\",\"controls\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"glide__arrow glide__arrow--left\"],[11,\"data-glide-dir\",\"<\"],[9],[0,\"<\"],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"glide__arrow glide__arrow--right\"],[11,\"data-glide-dir\",\">\"],[9],[0,\">\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/slideshow-view.hbs"
    }
  });

  _exports.default = _default;
});