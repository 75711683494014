define("teelt-frontend/components/utils/get-role-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userService: Ember.inject.service('user-service'),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    customLabelsService: Ember.inject.service('custom-labels-service'),
    eventBus: Ember.inject.service(),
    classNames: ['get-role-icon'],
    init: function init() {
      this._super.apply(this, arguments);

      this.eventBus.on('ROLELIST_UPDATED', this, 'onRolesRefresh');

      if (this.talent) {
        if (this.userService.isRH(this.talent)) {
          Ember.set(this, 'role', 'rh');
        } else if (this.userService.isOfficeManager(this.talent)) {
          Ember.set(this, 'role', 'officeManager');
        } else if (this.userService.isAdministrative(this.talent)) {
          Ember.set(this, 'role', 'administrative');
        } else if (this.userService.isAdminMRH(this.talent)) {
          Ember.set(this, 'role', 'adminMRH');
        } else if (this.userService.isManager(this.talent)) {
          Ember.set(this, 'role', 'manager');
        } else if (this.userService.getConsultUserRole(this.talent)) {
          Ember.set(this, 'role', this.userService.getConsultUserRole(this.talent));
        } else {
          Ember.set(this, 'role', 'talent');
        }
      }

      this.updateAcronym();
    },
    destroy: function destroy() {
      this.eventBus.off('ROLELIST_UPDATED', this, 'onRolesRefresh');

      this._super.apply(this, arguments);
    },
    onRolesRefresh: function onRolesRefresh() {
      this.updateAcronym();
    },
    onRoleChanged: Ember.observer('role', function () {
      this.updateAcronym();
    }),
    updateAcronym: function updateAcronym() {
      var roleType = this.role;

      switch (this.role) {
        case 'rh':
          roleType = 'ROLE_RH';
          break;

        case 'officeManager':
        case 'office_manager':
          roleType = 'ROLE_CUSTOM_OFFICE_MANAGER';
          break;

        case 'administrative':
          roleType = 'ROLE_ADMINISTRATIVE';
          break;

        case 'adminMRH':
          roleType = 'ROLE_MRH';
          break;

        case 'manager':
          roleType = 'ROLE_MANAGER';
          break;

        case 'talent':
          roleType = 'ROLE_TALENT';
          break;
      }

      if (roleType) {
        if (roleType == 'ROLE_TALENT' && this.tooltip) {
          var customLabel = this.customLabelsService.talentLabel;
          Ember.set(this, 'label', customLabel);
        } else {
          var customRole = this.rolecustomService.getCustomRole(roleType);

          if (customRole) {
            Ember.set(this, 'acronym', customRole.acronym);
            if (this.tooltip) Ember.set(this, 'label', customRole.label);
          }
        }
      }
    }
  });

  _exports.default = _default;
});