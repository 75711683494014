define("teelt-frontend/components/audience/audience-multi-selection", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['audience-multi-selection'],
    audiencePath: _environment.default.APP.API_HOST + "/audiences",
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.audiences) {
        Ember.set(this, 'audiences', []);
      }
    },
    actions: {
      addAudienceShow: function addAudienceShow() {
        Ember.set(this, 'showAddAudience', true);
      },
      onAudienceSelected: function onAudienceSelected(selected) {
        var _this = this;

        var audience = {
          id: selected.key,
          name: selected.label
        };
        var isPresent = this.audiences.some(function (a) {
          return a.id == audience.id;
        });

        if (!isPresent) {
          var audiences = this.audiences;
          audiences.push(audience);
          Ember.set(this, 'audiences', undefined);
          setTimeout(function () {
            return Ember.set(_this, 'audiences', audiences);
          });

          if (this.onSelectionChanged) {
            this.onSelectionChanged(audiences);
          }
        }

        Ember.set(this, 'showAddAudience', false);
      },
      delete: function _delete(index) {
        var _this2 = this;

        var audiences = this.audiences;
        audiences.splice(index, 1);
        Ember.set(this, 'audiences', undefined);
        setTimeout(function () {
          return Ember.set(_this2, 'audiences', audiences);
        });

        if (this.onSelectionChanged) {
          this.onSelectionChanged(audiences);
        }
      }
    }
  });

  _exports.default = _default;
});