define("teelt-frontend/templates/components/input/files-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gt+3Ac3Y",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[11,\"for\",\"logo\"],[11,\"class\",\"bmd-label-floating\"],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"file-field input-field\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"btn btn-primary waves-effect waves-light\"],[9],[0,\"\\n     \"],[7,\"span\"],[9],[1,[27,\"t\",[\"input.file.browse\"],null],false],[10],[0,\"\\n     \"],[7,\"input\"],[11,\"name\",\"files[]\"],[11,\"class\",\"files-container\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],[23,[\"onChanged\"]]],null]],[11,\"multiple\",\"multiple\"],[11,\"type\",\"file\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"file-path-wrapper\"],[9],[0,\"\\n     \"],[7,\"input\"],[11,\"class\",\"file-path\"],[12,\"placeholder\",[21,\"placeholder\"]],[11,\"type\",\"text\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"utils/progress-bar-file-input\",null,[[\"value\",\"hideOnZero\"],[[23,[\"progress\"]],true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/input/files-input.hbs"
    }
  });

  _exports.default = _default;
});