define("teelt-frontend/components/custom-field/add-custom-field-form", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['add-custom-field-form'],
    store: Ember.inject.service(),
    customErrorService: Ember.inject.service('custom-error'),
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.field) {
        var field = this.store.createRecord('custom-field', {
          type: 'text',
          section: 'talent-profile',
          task: {
            period: 'BEFORE'
          }
        });
        Ember.set(this, 'field', field);
      } else {
        Ember.set(this, 'editMode', true);

        if (!this.field.task) {
          Ember.set(this.field, 'task', {
            period: 'BEFORE'
          });
        }
      }
    },
    didInsertElement: function didInsertElement() {
      (0, _jquery.default)('#field-type').formSelect();
      (0, _jquery.default)('#field-section').formSelect();
    },
    actions: {
      save: function save() {
        var _this = this;

        if (!this.validateField()) return;
        this.field.save().then(function () {
          _this.router.transitionTo("board.admin.custom-field");
        }).catch(function (err) {
          _this.customErrorService.handleAjaxError(err);
        });
      },
      close: function close() {
        this.router.transitionTo('board.admin.custom-field');
      },
      onTypeChanged: function onTypeChanged(value) {
        Ember.set(this.field, "type", value);
      },
      onSectionChanged: function onSectionChanged(value) {
        Ember.set(this.field, "section", value);
      }
    },
    validateField: function validateField() {
      this.resetErrors();

      if (!this.field.name) {
        Ember.set(this, 'nameIsNull', true);
        return false;
      }

      return true;
    },
    resetErrors: function resetErrors() {
      Ember.set(this, 'nameIsNull', undefined);
    }
  });

  _exports.default = _default;
});