define("teelt-frontend/components/talents/modal/new-talent-modal", ["exports", "libphonenumber-js"], function (_exports, _libphonenumberJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    userService: Ember.inject.service(),
    customErrorService: Ember.inject.service('custom-error'),
    init: function init() {
      this._super.apply(this, arguments);

      var talent = this.store.createRecord('user', {
        position: {},
        experience: {},
        contract: {},
        team: {},
        lang: this.intl.locale[0].substring(0, 2),
        customFields: []
      });
      Ember.set(this, 'talent', talent);
      this.resetErrors();
      var path = this.userService.getUsersSearchPathForSelect('ROLE_MANAGER');
      Ember.set(this, "managerPath", path);
    },
    validateEmail: function validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validatePhoneNumber: function validatePhoneNumber() {
      if (!this.talent.phoneNumber) return;
      var country = this.getCountryForPhoneCode(this.talent.phoneCode).toUpperCase();
      var phoneNumberArray = this.talent.phoneNumber.split(' ');
      Ember.set(this.talent, "phoneNumber", phoneNumberArray.join(''));

      if (country === "FR") {
        return /^\d+$/.test(this.talent.phoneNumber) && (0, _libphonenumberJs.isValidPhoneNumber)(phoneNumberArray.join(''), country);
      }

      return (0, _libphonenumberJs.isValidPhoneNumber)(phoneNumberArray.join(''), country);
    },
    getCountryForPhoneCode: function getCountryForPhoneCode(phoneCode) {
      if (!phoneCode) {
        return;
      }

      var countryData = window.intlTelInputGlobals.getCountryData();
      var phoneCodeIso = phoneCode.substring(1);
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = countryData[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var country = _step.value;

          if (country.dialCode == phoneCodeIso) {
            return country.iso2;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },
    resetErrors: function resetErrors() {
      Ember.set(this, 'firstnameIsNull', false);
      Ember.set(this, 'lastnameIsNull', false);
      Ember.set(this, 'proEmailIsNull', false);
      Ember.set(this, 'invalidPhoneNumber', false);
      Ember.set(this, 'invalidProEmailError', false);
      Ember.set(this, 'invalidPersoEmailError', false);
    },
    validateForm: function validateForm() {
      this.resetErrors();
      var isOk = true;

      if (!this.talent.firstname || this.talent.firstname.length == 0) {
        Ember.set(this, 'firstnameIsNull', true);
        isOk = false;
      }

      if (!this.talent.lastname || this.talent.lastname.length == 0) {
        Ember.set(this, 'lastnameIsNull', true);
        isOk = false;
      }

      if (!this.talent.proEmail || this.talent.proEmail.length == 0) {
        Ember.set(this, 'proEmailIsNull', true);
        isOk = false;
      }

      if (this.talent.proEmail && !this.validateEmail(this.talent.proEmail)) {
        Ember.set(this, 'invalidProEmailError', true);
        isOk = false;
      }

      if (this.talent.persoEmail != null && this.talent.persoEmail.length == 0) {
        Ember.set(this.talent, 'persoEmail', null);
      }

      if (this.talent.persoEmail && !this.validateEmail(this.talent.persoEmail)) {
        Ember.set(this, 'invalidPersoEmailError', true);
        isOk = false;
      }

      if (this.proEmailAlreadyExistsError) {
        isOk = false;
      }

      if (this.persoEmailAlreadyExistsError) {
        isOk = false;
      }

      if (this.talent.phoneNumber && !this.validatePhoneNumber()) {
        Ember.set(this, 'invalidPhoneNumber', true);
        isOk = false;
      }

      return isOk;
    },
    actions: {
      onClickOverlay: function onClickOverlay() {},
      proEmailFocusOut: function proEmailFocusOut() {
        var _this = this;

        Ember.set(this, 'proEmailAlreadyExistsError', false);

        if (this.validateEmail(this.talent.proEmail)) {
          this.store.query('user', {
            username: this.talent.proEmail
          }).then(function (users) {
            if (users && users.length) {
              Ember.set(_this, 'proEmailAlreadyExistsError', true);
            }
          });
        }
      },
      persoEmailFocusOut: function persoEmailFocusOut() {
        var _this2 = this;

        Ember.set(this, 'persoEmailAlreadyExistsError', false);

        if (this.validateEmail(this.talent.persoEmail)) {
          this.store.query('user', {
            persoEmail: this.talent.persoEmail
          }).then(function (users) {
            if (users && users.length) {
              Ember.set(_this2, 'persoEmailAlreadyExistsError', true);
            }
          });
        }
      },
      save: function save() {
        var _this3 = this;

        if (!this.validateForm()) return;
        this.talent.set('username', this.talent.proEmail);

        if (this.userService.isOnlyOfficeManager(this.session.data.authenticated.user)) {
          Ember.set(this.talent, 'officeManagerIds', this.session.data.authenticated.user.id);
        }

        this.talent.save().then(function (talent) {
          // handle OM creating talent for other OM and don't have access after creation.
          var authenticatedUser = _this3.session.data.authenticated.user;

          if (_this3.userService.isRH(authenticatedUser) || _this3.userService.isOfficeManager(authenticatedUser) || _this3.userService.isAdminMRH(authenticatedUser)) {
            _this3.router.transitionTo("board.talent.talent-detail", talent.id);
          } else {
            _this3.router.transitionTo("board.talents");
          }
        }).catch(function (err) {
          console.error(err);

          _this3.customErrorService.handleAjaxError(err);
        });
      },
      onWelcomeDateChanged: function onWelcomeDateChanged() {},
      generateManagerName: function generateManagerName(result) {
        return this.userService.getTalentName(result);
      },
      onManagerChanged: function onManagerChanged(result) {
        if (!result.key) return;
        Ember.set(this.talent, 'manager', {
          id: result.key
        });
        console.log('onManagerChanged', this.talent.toJSON());
      }
    }
  });

  _exports.default = _default;
});