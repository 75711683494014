define("teelt-frontend/components/talents/talent-tasks-by-period-mobile", ["exports", "teelt-frontend/components/talents/talent-tasks-by-period"], function (_exports, _talentTasksByPeriod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _talentTasksByPeriod.default.extend({});

  _exports.default = _default;
});