define("teelt-frontend/templates/talent/integration/tasks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0EEQzUTm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"hide-on-small-only\"],[9],[0,\"\\n  \"],[1,[27,\"talents/talent-tasks-by-period\",null,[[\"talent\",\"companyCustomFields\",\"forceContentTaskId\"],[[23,[\"model\",\"talent\"]],[23,[\"model\",\"companyCustomFields\"]],[23,[\"model\",\"contentTaskId\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"show-on-small\"],[9],[0,\"\\n  \"],[1,[27,\"talents/talents-periods-slick\",null,[[\"talent\"],[[23,[\"model\",\"talent\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/talent/integration/tasks.hbs"
    }
  });

  _exports.default = _default;
});