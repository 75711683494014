define("teelt-frontend/components/settings/perimeter-edit", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['perimeter-edit'],
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    templatePath: _environment.default.APP.API_HOST + "/templates",
    audiencePath: _environment.default.APP.API_HOST + "/audiences",
    init: function init() {
      this._super.apply(this, arguments);
    },
    verifyPerimeter: function verifyPerimeter() {
      Ember.set(this, 'errorName', false);
      var ok = true;

      if (!this.perimeter.name || this.perimeter.name.trim().length == 0) {
        Ember.set(this, 'errorName', true);
        ok = false;
      }

      return ok;
    },
    actions: {
      close: function close() {
        Ember.set(this, 'perimeter', undefined);
      },
      save: function save() {
        var _this = this;

        if (this.verifyPerimeter()) {
          this.perimeter.save().then(function () {
            _this.eventBus.trigger('REFRESH_PERIMETERS');
          });
        }
      },
      onTemplateChanged: function onTemplateChanged(newTemplate) {
        var _this2 = this;

        var templates = this.perimeter.templates;

        if (!templates) {
          templates = [];
        }

        var found = false;
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = templates[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var template = _step.value;

            if (template.id == newTemplate.key) {
              found = true;
              break;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        if (!found) {
          templates.push({
            id: newTemplate.key,
            name: newTemplate.label
          });
          Ember.set(this.perimeter, 'templates', undefined);
          setTimeout(function () {
            Ember.set(_this2.perimeter, 'templates', templates);
          });
        }

        Ember.set(this, 'addTemplate', false);
      },
      deleteTemplate: function deleteTemplate(template) {
        var _this3 = this;

        var templates = this.perimeter.templates;
        var index = templates.findIndex(function (t) {
          return t.id == template.id;
        });

        if (index >= 0) {
          templates.splice(index, 1);
          Ember.set(this.perimeter, 'templates', undefined);
          setTimeout(function () {
            Ember.set(_this3.perimeter, 'templates', templates);
          });
        }
      },
      onAudienceChanged: function onAudienceChanged(newAudience) {
        var _this4 = this;

        var audiences = this.perimeter.audiences;

        if (!audiences) {
          audiences = [];
        }

        var found = false;
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = audiences[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var audience = _step2.value;

            if (audience.id == newAudience.key) {
              found = true;
              break;
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        if (!found) {
          audiences.push({
            id: newAudience.key,
            name: newAudience.label
          });
          Ember.set(this.perimeter, 'audiences', undefined);
          setTimeout(function () {
            Ember.set(_this4.perimeter, 'audiences', audiences);
          });
        }

        Ember.set(this, 'addAudience', false);
      },
      deleteAudience: function deleteAudience(audience) {
        var _this5 = this;

        var audiences = this.perimeter.audiences;
        var index = audiences.findIndex(function (t) {
          return t.id == audience.id;
        });

        if (index >= 0) {
          audiences.splice(index, 1);
          Ember.set(this.perimeter, 'audiences', undefined);
          setTimeout(function () {
            Ember.set(_this5.perimeter, 'audiences', audiences);
          });
        }
      }
    }
  });

  _exports.default = _default;
});