define("teelt-frontend/components/sftp/sftp-configuration-edit", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['saml-configuration-edit'],
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var path = _environment.default.APP.API_HOST + '/sftps';
      this.ajax.requestPromise(path, 'GET').then(function (sftp) {
        if (!sftp) {
          sftp = {};
        }

        Ember.set(_this, 'sftp', sftp);
        setTimeout(function () {
          var textareas = document.querySelector('.materialize-textarea');

          if (textareas) {
            M.textareaAutoResize(document.querySelector('.materialize-textarea'));
          }
        });
      });
    },
    actions: {
      saveSftp: function saveSftp() {
        var _this2 = this;

        var path = _environment.default.APP.API_HOST + '/sftps';

        if (!this.sftp.username || !this.sftp.hostname || !this.sftp.port) {
          return;
        }

        this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(this.sftp)).then(function (sftp) {
          Ember.set(_this2, 'sftp', sftp);

          _this2.notify.info(_this2.intl.t('talent.modifications_saved'));
        });
      },
      deleteSftp: function deleteSftp() {
        var _this3 = this;

        var path = _environment.default.APP.API_HOST + '/sftps';
        this.ajax.requestPromise(path, 'DELETE').then(function () {
          Ember.set(_this3, 'sftp', {});
        });
      },
      testConnexion: function testConnexion() {
        var _this4 = this;

        var path = _environment.default.APP.API_HOST + '/sftps/testconnexion';
        Ember.set(this, 'isLoading', true);
        this.ajax.requestPromise(path, 'GET').then(function () {
          Ember.set(_this4, 'isLoading', false);

          _this4.notify.info(_this4.intl.t('form.validation.sftp.test_connection_success'), {
            closeAfter: 5000
          });
        }).catch(function (e) {
          Ember.set(_this4, 'isLoading', false);

          _this4.notify.error(_this4.intl.t('form.validation.sftp.test_connection_error'), {
            closeAfter: 10000
          });
        });
      }
    }
  });

  _exports.default = _default;
});