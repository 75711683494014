define("teelt-frontend/components/form/form-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-detail'],
    router: Ember.inject.service(),
    actions: {
      edit: function edit() {
        if (this.adminForm) {
          this.router.transitionTo('board.admin-form.form-edit', this.form.id);
        } else {
          this.router.transitionTo('board.form.form-edit', this.form.id);
        }
      },
      remove: function remove() {
        Ember.set(this, "showRemoveConfirm", true);
      },
      removeConfirmation: function removeConfirmation(response) {
        var _this = this;

        if (response) {
          this.form.deleteRecord();
          this.form.save().then(function () {
            if (_this.adminForm) {
              _this.router.transitionTo('board.templates.admin-forms');
            } else {
              _this.router.transitionTo('board.templates.forms');
            }
          });
        }
      },
      close: function close() {
        if (this.adminForm) {
          this.router.transitionTo('board.templates.admin-forms');
        } else {
          this.router.transitionTo('board.templates.forms');
        }
      }
    }
  });

  _exports.default = _default;
});