define("teelt-frontend/components/task/new-task/new-task-ihm-select", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    page: 0,
    limit: 6,
    canLoadMore: true,
    query: '',
    ihmType: "meetPeople",
    lastModules: [],
    selectedOnFirstPage: false,
    onTypeChanged: Ember.observer('task.type', function () {
      this.loadPage();
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.session.data.authenticated.user.company.activeMeetPeople) Ember.set(this, 'showMeetPeopleBtn', true);
      if (this.session.data.authenticated.user.company.activeExploreOffice) Ember.set(this, 'showExploreOfficeBtn', true);
      if (this.task.exploreOffice && this.task.exploreOffice.id || this.session.data.authenticated.user.company.activeExploreOffice && !this.session.data.authenticated.user.company.activeMeetPeople) Ember.set(this, 'ihmType', 'exploreOffice');

      if (this.task.meetPeople && this.task.meetPeople.id || this.task.exploreOffice && this.task.exploreOffice.id) {
        if (this.task.meetPeople && this.task.meetPeople.id) {
          this.ajax.requestPromise(_environment.default.APP.API_HOST + '/meetpeoples/' + this.task.meetPeople.id, 'GET').then(function (data) {
            _this.send('selectIhm', data);
          }).finally(function () {
            _this.loadPage();
          });
        }

        if (this.task.exploreOffice && this.task.exploreOffice.id) {
          this.ajax.requestPromise(_environment.default.APP.API_HOST + '/exploreoffices/' + this.task.exploreOffice.id, 'GET').then(function (data) {
            _this.send('selectIhm', data);
          }).finally(function () {
            _this.loadPage();
          });
        }
      } else {
        this.loadPage();
      }
    },
    loadPage: function loadPage() {
      var _this2 = this;

      var params = {};
      params.offset = this.page * this.limit;
      params.limit = this.limit;

      if (this.query) {
        params.search = this.query;
      }

      Ember.set(this, 'canLoadMore', false);
      var type = this.ihmType === 'exploreOffice' ? 'exploreoffices' : 'meetpeoples';
      this.ajax.requestWithUrlParamPromise(_environment.default.APP.API_HOST + '/' + type, 'GET', params).then(function (data) {
        if (data && data.length >= _this2.limit) {
          Ember.set(_this2, 'canLoadMore', true);
        }

        var ihmModules = _this2.getIhmModulesWithSelectedFirst(data.toArray());

        Ember.set(_this2, "ihmModules", ihmModules);
      }).finally(function () {
        Ember.set(_this2, 'isLoading', false);
      });
    },
    getIhmModulesWithSelectedFirst: function getIhmModulesWithSelectedFirst(modules) {
      var _this3 = this;

      if (!(this.task.meetPeople && this.task.meetPeople.id || this.task.exploreOffice && this.task.exploreOffice.id) || this.selectedOnFirstPage) return modules;
      var moduleIndex = modules.findIndex(function (module) {
        return module.id === _this3.ihmSelectedId;
      });

      if (moduleIndex === 0 && this.page === 0) {
        Ember.set(this, "selectedOnFirstPage", true);
        return modules;
      }

      if (moduleIndex > 0 && this.page === 0) {
        var moduleToMove = modules.splice(moduleIndex, 1)[0];
        modules.unshift(moduleToMove);
        Ember.set(this, "selectedOnFirstPage", true);
        return modules;
      }

      if (moduleIndex < 0 && this.page === 0) {
        var lastModule = modules.pop();
        var lastModules = this.lastModules;

        if (!lastModules.some(function (entry) {
          return entry.page === _this3.page;
        })) {
          lastModules.push({
            page: this.page,
            module: lastModule
          });
        }

        Ember.set(this, "lastModules", lastModules);
        var newModule = this.task.meetPeople ? this.task.meetPeople : this.task.exploreOffice;
        modules.unshift(newModule);
        return modules;
      }

      if (this.lastModules.length > 0) {
        var lastModuleEntry = this.lastModules.find(function (entry) {
          return entry.page === _this3.page - 1;
        });

        if (lastModuleEntry && lastModuleEntry.id !== this.ihmSelectedId) {
          modules.unshift(lastModuleEntry.module);

          if (moduleIndex > 0) {
            modules.splice(moduleIndex + 1, 1);
          } else {
            var _lastModule = modules.pop();

            if (!this.lastModules.some(function (entry) {
              return entry.page === _this3.page;
            })) {
              this.lastModules.push({
                page: this.page,
                module: _lastModule
              });
            }

            Ember.set(this, "lastModules", this.lastModules);
          }
        }

        return modules;
      }

      return modules;
    },
    actions: {
      onSearchKeyUp: function onSearchKeyUp() {
        var _this4 = this;

        if (this.keyUpAction) {
          Ember.run.cancel(this.keyUpAction);
        }

        var keyUpAction = Ember.run.later(function () {
          Ember.set(_this4, 'page', 0);

          _this4.loadPage();
        }, 300);
        Ember.set(this, 'keyUpAction', keyUpAction);
      },
      next: function next() {
        Ember.set(this, 'page', this.page + 1);
        this.loadPage();
      },
      prev: function prev() {
        Ember.set(this, 'page', this.page - 1);
        this.loadPage();
      },
      selectIhm: function selectIhm(ihm) {
        if (!ihm) {
          Ember.set(this, 'ihmSelectedId', undefined);
          Ember.set(this.task, "noModuleSelected", true);
        } else {
          Ember.set(this, 'ihmSelectedId', ihm.id);
          Ember.set(this.task, this.ihmType, ihm);
          Ember.set(this.task, "noModuleSelected", undefined);
        }
      },
      changeIhmType: function changeIhmType(type) {
        Ember.set(this, "ihmType", type);
        this.loadPage();
      }
    }
  });

  _exports.default = _default;
});