define("teelt-frontend/templates/talent/integration/tools", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CGmRwrHo",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"talents/talent-tools\",null,[[\"tools\"],[[23,[\"model\",\"talent\",\"selectedTemplate\",\"tools\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/talent/integration/tools.hbs"
    }
  });

  _exports.default = _default;
});