define("teelt-frontend/templates/components/input/validation-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7S0TW7mJ",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[1,[27,\"input\",null,[[\"id\",\"value\",\"autofocus\",\"autocomplete\",\"class\",\"key-up\"],[[23,[\"customId\"]],[23,[\"value\"]],[23,[\"autofocus\"]],\"off\",[23,[\"classes\"]],[27,\"action\",[[22,0,[]],\"keyUpAction\"],null]]]],false],[0,\"\\n\"],[7,\"i\"],[11,\"class\",\"material-icons close\"],[3,\"action\",[[22,0,[]],[23,[\"onClose\"]]]],[9],[0,\"close\"],[10],[0,\"\\n\"],[7,\"i\"],[11,\"class\",\"material-icons check\"],[3,\"action\",[[22,0,[]],[23,[\"onValidate\"]]]],[9],[0,\"check\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"label\"]]],null,{\"statements\":[[7,\"label\"],[12,\"class\",[28,[[27,\"if\",[[23,[\"value\"]],\"active\",\"\"],null]]]],[12,\"for\",[21,\"customId\"]],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\"],[11,\"class\",\"proposals\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"data\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"proposal-label\"],[9],[1,[21,\"proposalLabel\"],false],[10],[0,\"\\n    \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"data\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[11,\"class\",\"item\"],[3,\"action\",[[22,0,[]],\"addProposalItem\",[22,1,[]]]],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/input/validation-input.hbs"
    }
  });

  _exports.default = _default;
});