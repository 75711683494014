define("teelt-frontend/utils/email-variables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = emailVariables;

  function emailVariables() {
    return {
      extractVariablesFromEmail: function extractVariablesFromEmail(body) {
        var regex = /%[A-Z_]+%/g;
        var found = body.match(regex);
        return found;
      }
    };
  }
});