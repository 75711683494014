define("teelt-frontend/components/form/satisfaction-rate-forms-list", ["exports", "teelt-frontend/config/environment", "teelt-frontend/utils/jquery-utils"], function (_exports, _environment, _jqueryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['modules-list'],
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    notify: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    customErrorService: Ember.inject.service('custom-error'),
    canLoadMore: true,
    page: 0,
    offset: 0,
    limit: 20,
    allForms: [],
    allPages: 0,
    showAllPages: false,
    updatePages: true,
    query: '',
    forms: Ember.computed('allForms.length', 'page', 'limit', 'canLoadMore', function () {
      var uniques = this.allForms.reduce(function (filter, current) {
        var dk = filter.find(function (item) {
          return item.id === current.id && item.type === current.type;
        });

        if (!dk) {
          return filter.concat([current]);
        } else {
          return filter;
        }
      }, []);
      var forms = uniques.slice(this.page * this.limit, this.page * this.limit + 16);

      if (this.canLoadMore && forms.length < 16) {
        this.loadMoreForms();
      }

      return forms;
    }),
    lastPage: Ember.computed('allForms.length', 'forms', 'page', function () {
      return this.page >= this.allForms.length / 16 || this.forms.length < 16;
    }),
    updatePagesLinks: function updatePagesLinks() {
      var nbPages = Math.ceil(this.allForms.length / 16);
      var minPage = Math.max(this.page - 3, 0);
      var maxPage = Math.min(this.page + 3, nbPages - 1);
      var pages = [];

      for (var i = minPage; i <= maxPage; i++) {
        pages.push(i);
      }

      Ember.set(this, 'pages', pages);
    },
    showCurrentPage: function showCurrentPage() {
      var allDatas = this.allForms;
      var results = allDatas.slice(this.page * 16, (this.page + 1) * 16);
      Ember.set(this, 'forms', results);

      if (this.forms.length < 16) {
        this.loadMoreForms();
      }

      this.updatePagesLinks();
    },
    destroy: function destroy() {
      this.eventBus.off('CLICK_EVENT', this, 'checkToCloseModal');

      this._super.apply(this, arguments);
    },
    checkToCloseModal: function checkToCloseModal(e) {
      if (!(0, _jqueryUtils.default)().checkIfClickInElement(e, ["module-actions-dialog", "module-actions-dialog-btn"])) {
        if (this.showActions) {
          Ember.set(this, 'showActions', false);
        }
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      setTimeout(function () {
        _this.eventBus.on('CLICK_EVENT', _this, 'checkToCloseModal');
      }, 300);
    },
    loadMoreForms: function loadMoreForms() {
      var _this2 = this;

      var path = _environment.default.APP.API_HOST + '/forms/satisfaction-rate-forms?search=' + this.query + '&offset=' + this.offset + "&limit=" + this.limit;
      this.ajax.requestPromise(path, 'GET').then(function (forms) {
        if (forms) {
          forms.forEach(function (form) {
            if (form.type === 'ADMINISTRATIVE') {
              form.type = 'administrative';
            } else {
              form.type = 'form';
            }
          });
        }

        Ember.set(_this2, 'offset', _this2.offset + _this2.limit);
        var currentForms = _this2.allForms;

        if (!currentForms) {
          currentForms = forms;
          Ember.set(_this2, 'allForms', currentForms);
        } else {
          if (forms && forms.length > 0) {
            if (_this2.allPages != 0) Ember.set(_this2, 'updatePages', false);
            currentForms = currentForms.concat(forms);
            Ember.set(_this2, 'allForms', currentForms);

            _this2.showCurrentPage();
          } else {
            Ember.set(_this2, 'canLoadMore', false);
          }
        }
      });
    },
    cleanResults: function cleanResults() {
      Ember.set(this, 'canLoadMore', true);
      Ember.set(this, 'offset', 0);
      Ember.set(this, 'page', 0);
      Ember.set(this, 'allForms', []);
    },
    actions: {
      onClickOverlay: function onClickOverlay() {
        Ember.set(this, 'showPreviewDialog', false);
      },
      showForm: function showForm(form) {
        var _this3 = this;

        this.store.findRecord('form', form.id, {
          reload: true
        }).then(function (result) {
          Ember.set(_this3, 'previewType', form.type);
          Ember.set(_this3, 'preview', result);
          Ember.set(_this3, 'showPreviewDialog', true);
        });
      },
      onSearchKeyUp: function onSearchKeyUp() {
        var _this4 = this;

        if (this.keyUpAction) {
          Ember.run.cancel(this.keyUpAction);
        }

        var keyUpAction = Ember.run.later(function () {
          _this4.cleanResults();

          _this4.showCurrentPage();
        }, 300);
        Ember.set(this, 'keyUpAction', keyUpAction);
      },
      changePage: function changePage(newPage) {
        if (newPage < 0 || newPage * 16 >= this.allForms.length) return;
        Ember.set(this, 'page', newPage);
        this.showCurrentPage();
      },
      openFormActions: function openFormActions(form) {
        var id = form.type + '-' + form.id;

        if (this.showActions == id) {
          Ember.set(this, 'showActions', false);
        } else {
          Ember.set(this, 'showActions', id);
        }
      },
      closeFormActions: function closeFormActions() {
        $(document).off('click');
        Ember.set(this, 'showActions', false);
      },
      prepareDeleteForm: function prepareDeleteForm(form) {
        if (form.templates && form.templates.length > 0) return;
        Ember.set(this, 'formToDelete', form);
      },
      removeForm: function removeForm(response) {
        var _this5 = this;

        if (response) {
          var formId = this.formToDelete.id;
          var path = _environment.default.APP.API_HOST + '/modules/' + this.formToDelete.type + '/' + formId;
          this.ajax.requestPromise(path, 'DELETE').then(function () {
            _this5.cleanResults();

            _this5.loadMoreForms();
          }).catch(function (error) {
            _this5.notify.error(_this5.intl.t('module.no_delete_if_template'), {
              closeAfter: 5000
            });
          });
        }
      },
      editForm: function editForm(form) {
        var _this6 = this;

        Ember.set(this, 'showActions', false);

        switch (form.type) {
          case 'form':
            this.store.findRecord('form', form.id, {
              reload: true
            }).then(function (form) {
              Ember.set(_this6, 'updateFormType', 'form');
              Ember.set(_this6, 'updateForm', form);
            });
            break;

          case 'administrative':
            this.store.findRecord('form', form.id, {
              reload: true
            }).then(function (form) {
              Ember.set(_this6, 'updateFormType', 'administrative');
              Ember.set(_this6, 'updateForm', form);
            });
            break;
        }
      },
      duplicateForm: function duplicateForm(form) {
        var _this7 = this;

        Ember.set(this, 'showActions', false);
        var formType = 'form';
        this.store.findRecord(formType, form.id, {
          reload: true
        }).then(function (formToDuplicate) {
          var formJSON = formToDuplicate.toJSON();
          formJSON['id'] = undefined;

          if (formJSON.contents) {
            formJSON.contents.forEach(function (content) {
              content.id = null;
            });
          }

          formJSON['title'] = _this7.intl.t('module.duplicate.prefix') + ' ' + formJSON['title'];

          var duplicatedForm = _this7.store.createRecord(formType, formJSON);

          duplicatedForm.save().then(function (savedDuplicatedForm) {
            _this7.store.findRecord(formType, savedDuplicatedForm.id, {
              reload: true
            }).then(function (editDuplicatedForm) {
              Ember.set(_this7, 'updateFormType', form.type);
              Ember.set(_this7, 'updateForm', editDuplicatedForm);
              Ember.set(_this7, 'canLoadMore', true);
              Ember.set(_this7, 'offset', 0);
              Ember.set(_this7, 'allForms', []);

              _this7.loadMoreForms();
            });
          }).catch(function (err) {
            _this7.customErrorService.handleAjaxError(err);
          });
        });
      },
      onFinish: function onFinish() {
        Ember.set(this, 'updateForm', undefined);
        this.cleanResults();
        this.loadMoreForms();
      },
      previous: function previous() {
        Ember.set(this, 'updateForm', undefined);
      },
      editVersion: function editVersion(form) {
        var _this8 = this;

        Ember.set(this, 'showActions', false);
        Ember.set(this, 'updateForm', undefined);
        Ember.set(this, 'updateVersion', undefined);
        Ember.set(this, 'updateVersionForm', form);
        this.store.findRecord('form', form.id).then(function (form) {
          Ember.set(_this8, 'updateFormType', 'form');
          Ember.set(_this8, 'updateVersion', form);
        });
      },
      openEditVersion: function openEditVersion(form) {
        Ember.set(this, 'updateForm', undefined);
        Ember.set(this, 'updateVersion', form);
      },
      showAll: function showAll() {
        Ember.set(this, 'showAllPages', true);
      }
    }
  });

  _exports.default = _default;
});