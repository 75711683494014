define("teelt-frontend/components/settings/users-management", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['users-management'],
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    session: Ember.inject.service(),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    defaultProfileImageUrl: _environment.default.APP.DEFAULT_PROFILE_IMAGE_URL,
    perimeterPath: _environment.default.APP.API_HOST + "/perimeters",
    countUrl: _environment.default.APP.API_HOST + "/users/count",
    offset: 0,
    limit: 10,
    search: '',
    canLoadMore: false,
    order: undefined,
    displayMode: false,
    selectedRole: 'all',
    modelName: 'user',
    modelFilter: {},
    customKeys: [{
      key: 'photo',
      mandatory: true,
      selected: true
    }, {
      key: 'firstname',
      selected: true
    }, {
      key: 'lastname',
      selected: true
    }, {
      key: 'role',
      selected: true
    }, {
      key: 'createdAt',
      selected: true
    }],
    sortableKeys: ['firstname', 'lastname', 'createdAt'],
    init: function init() {
      this._super.apply(this, arguments);

      this.eventBus.on('ROLELIST_UPDATED', this, 'onRolesRefresh');
      var allRoles = ["ROLE_RH"];

      if (this.session.data.authenticated.user.company.activeOMRole) {
        allRoles.push("ROLE_CUSTOM_OFFICE_MANAGER");
      }

      allRoles.push("ROLE_MANAGER");

      if (this.session.data.authenticated.user.company.activeAdministrative) {
        allRoles.push("ROLE_ADMINISTRATIVE");
      }

      if (this.session.data.authenticated.user.company.activeMRH) {
        allRoles.push("ROLE_MRH");
      }

      var roles = this.rolecustomService.getConsultRoles();

      if (roles) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = roles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var role = _step.value;
            allRoles.push("ROLE_CONSULT-" + role.id);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      Ember.set(this, 'allRoles', allRoles);
      Ember.set(this, 'complexFilters', [{
        "key": "role",
        "type": "select",
        "operator": "IN",
        "value1": allRoles.join(",")
      }]);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      setTimeout(function () {
        _this.$('.select').formSelect();
      }, 0);
    },
    destroy: function destroy() {
      this.eventBus.off('ROLELIST_UPDATED', this, 'onRolesRefresh');

      this._super.apply(this, arguments);
    },
    onRolesRefresh: function onRolesRefresh() {
      var _this2 = this;

      var allRoles = this.allRoles;
      Ember.set(this, 'allRoles', undefined);
      setTimeout(function () {
        Ember.set(_this2, 'allRoles', allRoles);
        setTimeout(function () {
          _this2.$('.select').formSelect();
        });
      });
    },
    actions: {
      onClickOverlay: function onClickOverlay() {},
      onRoleChanged: function onRoleChanged(role) {
        Ember.set(this, 'selectedRole', role);

        if (role == 'all') {
          Ember.set(this, 'complexFilters', [{
            "key": "role",
            "type": "select",
            "operator": "IN",
            "value1": this.allRoles.join(",")
          }]);
        } else {
          Ember.set(this, 'complexFilters', [{
            "key": "role",
            "type": "select",
            "operator": "IN",
            "value1": role
          }]);
        }

        Ember.set(this, 'reloadTimestamp', new Date());
      },
      openAddModal: function openAddModal() {
        Ember.set(this, 'updateUser', false);
        Ember.set(this, 'addRequest', {
          roles: [],
          emails: [''],
          perimeter: undefined
        });
        Ember.set(this, 'showAddModal', true);
      },
      addRemoveRoleToAddRequest: function addRemoveRoleToAddRequest(role) {
        var _this3 = this;

        var roles = this.addRequest.roles;
        var index = roles.indexOf(role);

        if (index >= 0) {
          roles.splice(index, 1);
        } else {
          roles.push(role);
        }

        Ember.set(this.addRequest, "roles", undefined);
        setTimeout(function () {
          Ember.set(_this3.addRequest, "roles", roles);
        });
      },
      handleFocusOut: function handleFocusOut(index, value) {
        var _this4 = this;

        var emails = this.addRequest.emails;
        emails[index] = value;
        Ember.set(this.addRequest, "emails", undefined);
        setTimeout(function () {
          Ember.set(_this4.addRequest, "emails", emails);
        });
      },
      interceptEnter: function interceptEnter(index, e1, e2) {
        if (e2.keyCode == 13) {
          this.send('handleFocusOut', index, e1);
          return false;
        }

        return true;
      },
      addNewEmail: function addNewEmail() {
        var _this5 = this;

        var emails = this.addRequest.emails;
        emails.push('');
        Ember.set(this.addRequest, "emails", undefined);
        setTimeout(function () {
          Ember.set(_this5.addRequest, "emails", emails);
        });
      },
      onRowSelected: function onRowSelected(user) {
        Ember.set(this, "updateUser", true);
        Ember.set(this, 'addRequest', {
          roles: user.roles,
          emails: [user.username],
          perimeter: user.perimeter
        });
        Ember.set(this, 'showAddModal', true);
      },
      onPerimeterChanged: function onPerimeterChanged(perimeter) {
        Ember.set(this.addRequest, 'perimeter', {
          id: perimeter.key,
          name: perimeter.label
        });
        Ember.set(this, 'mustSelectPerimeter', false);

        if (this.addRequest.roles.includes("ROLE_MRH") && (this.addRequest.perimeter == null || this.addRequest.perimeter.id == 0)) {
          Ember.set(this, 'mustSelectPerimeter', true);
        }
      },
      add: function add() {
        var _this6 = this;

        Ember.set(this, 'mustSelectPerimeter', false);

        if (this.addRequest.roles.includes("ROLE_MRH") && (this.addRequest.perimeter == null || this.addRequest.perimeter.id == 0)) {
          Ember.set(this, 'mustSelectPerimeter', true);
          this.notify.error(this.intl.t('settings.perimeter.no_perimeter_selected_for_mrh'), {
            closeAfter: 3000
          });
          return;
        }

        var path = _environment.default.APP.API_HOST + '/users/roles/users';
        this.ajax.requestWithBodyPromise(path, 'POST', 'application/json', JSON.stringify(this.addRequest)).then(function (result) {
          Ember.set(_this6, 'reloadTimestamp', Date.now());
          Ember.set(_this6, 'showAddModal', false);
        });
      }
    }
  });

  _exports.default = _default;
});