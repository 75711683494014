define("teelt-frontend/mixins/domain-redirect", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var url = window.location.href;
      if (!_environment.default.APP.DOMAIN_REDIRECT) return;
      var tmp = url.match(/\?.*/);
      var params = tmp && tmp.length > 0 ? new URLSearchParams(tmp[0]) : undefined;
      var user = this.session.data.authenticated.user;

      if (user && user.company && user.company.domain && user.company.domain !== 'default') {
        var currentSubdomain = getSubdomain();

        if (currentSubdomain != user.company.domain) {
          var redirectUrl = "//" + user.company.domain + ".teelt.io";
          var port = getPort();

          if (port != 80 && port != 443) {
            redirectUrl += ":" + port;
          }

          var token = user.token;

          if (params && params.get('token')) {
            token = params.get('token');
          }

          redirectUrl += "?token=" + token;
          window.location.href = redirectUrl;
        }
      }
    }
  });

  _exports.default = _default;

  function getSubdomain() {
    var hostname = window.location.hostname;
    return hostname.split('.')[0];
  }

  function getPort() {
    return window.location.port;
  }
});