define("teelt-frontend/templates/components/utils/content-editable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g3CgjeL5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"editable\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[12,\"id\",[28,[[21,\"uuid\"]]]],[11,\"class\",\"body\"],[12,\"contenteditable\",[28,[[21,\"edit\"]]]],[12,\"data-attribute\",[28,[[21,\"attribute\"]]]],[9],[0,\"\\n      \"],[1,[27,\"xss-protect\",[[23,[\"value\"]]],null],true],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"edit\"]]],null,{\"statements\":[[0,\"    \"],[7,\"svg\"],[11,\"class\",\"svg-action clickable\"],[11,\"width\",\"12\"],[11,\"height\",\"12\"],[11,\"viewBox\",\"0 0 24 24\"],[11,\"fill\",\"none\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[3,\"action\",[[22,0,[]],\"activeContentEditable\"]],[9],[0,\"\\n      \"],[7,\"path\"],[11,\"d\",\"M0 18.5945V23.4489H4.99931L19.7439 9.13164L14.7446 4.27724L0 18.5945ZM23.6101 5.37757C24.13 4.87271 24.13 4.05718 23.6101 3.55232L20.4905 0.523174C19.9706 0.0183169 19.1307 0.0183169 18.6107 0.523174L16.1711 2.89212L21.1704 7.74652L23.6101 5.37757Z\"],[11,\"fill\",\"#1800FF\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/content-editable.hbs"
    }
  });

  _exports.default = _default;
});