define("teelt-frontend/templates/components/form/field/complex/field-french-city", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NOEgav+3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"input-field\"],[9],[0,\"\\n      \"],[1,[27,\"input/generic-autocomplete\",null,[[\"customId\",\"path\",\"valueKey\",\"idKey\",\"selectedValue\",\"preselectedText\",\"onChange\"],[[23,[\"uuid\"]],[23,[\"path\"]],\"name\",\"code\",[23,[\"value\"]],[23,[\"value\"]],[27,\"action\",[[22,0,[]],\"onCityChanged\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"haveError\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"helper-text g-error\"],[9],[1,[23,[\"field\",\"validationError\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/form/field/complex/field-french-city.hbs"
    }
  });

  _exports.default = _default;
});