define("teelt-frontend/components/utils/input-phonenumber", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    refreshTelInput: function refreshTelInput() {
      var _this = this;

      if (this.telInput) {
        this.telInput.destroy();
      }

      var getCountryPromise = new Promise(function (resolve, reject) {
        var initialCountry = _this.getCountryForPhoneCode(_this.phoneCode);

        if (!initialCountry) {
          _jquery.default.get("https://ipinfo.io", function () {}, "jsonp").always(function (resp) {
            var countryCode = resp && resp.country ? resp.country : "";
            resolve(countryCode);
          });
        } else {
          resolve(initialCountry);
        }
      });
      getCountryPromise.then(function (initialCountry) {
        var input = document.querySelector("#phonenumber");
        var telInput = window.intlTelInput(input, {
          initialCountry: initialCountry,
          customPlaceholder: function customPlaceholder(selectedCountryPlaceholder, selectedCountryData) {
            return _this.intl.t("word.phone") + " (" + selectedCountryPlaceholder + ")";
          }
        });
        var data = telInput.getSelectedCountryData();
        Ember.set(_this, "phoneCode", "+" + data.dialCode);
        input.addEventListener("countrychange", function () {
          var data = telInput.getSelectedCountryData();
          Ember.set(_this, "phoneCode", "+" + data.dialCode);
        });
        Ember.set(_this, "telInput", telInput);
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.refreshTelInput();
    },
    getCountryForPhoneCode: function getCountryForPhoneCode(phoneCode) {
      if (!phoneCode) {
        return;
      }

      var countryData = window.intlTelInputGlobals.getCountryData();
      var phoneCodeIso = phoneCode.substring(1);
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = countryData[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var country = _step.value;

          if (country.dialCode == phoneCodeIso) {
            return country.iso2;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },
    onInitialPhoneCodeChanged: Ember.observer('initialPhoneCode', function () {
      this.refreshTelInput();
    })
  });

  _exports.default = _default;
});