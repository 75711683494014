define("teelt-frontend/components/ihm/meet-people/audience-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['add-ihm-form'],
    store: Ember.inject.service(),
    page: 0,
    limit: 6,
    search: '',
    canSelectFromIds: true,
    addlIds: [],
    selectedAudienceIds: [],
    audienceUserCountList: [],
    init: function init() {
      this._super.apply(this, arguments);

      if (this.audienceIds) {
        Ember.set(this, 'selectedAudienceIds', this.audienceIds.map(function (id) {
          return Number(id);
        }));
      }

      Ember.set(this, 'audienceUserCountList', []);
      this.loadAudiences();
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      setTimeout(function () {
        if (_this.audienceIds) {
          _this.selectedAudienceIds.forEach(function (audienceId) {
            _this.countAudienceUsers(audienceId);
          });
        }
      }, 1000);
    },
    loadAudiences: function loadAudiences() {
      var _this2 = this;

      Ember.set(this, 'canLoadMore', false);
      this.store.query('audience', {
        offset: this.page * this.limit,
        limit: this.limit,
        search: this.search,
        selectedIds: this.selectedAudienceIds ? this.selectedAudienceIds.join(',') : ''
      }).then(function (audiences) {
        if (audiences && audiences.length >= _this2.limit) {
          Ember.set(_this2, 'canLoadMore', true);
        } //set selected to false


        audiences.forEach(function (audience) {
          Ember.set(audience, 'selected', false);
        });

        if (_this2.selectedAudienceIds) {
          audiences.forEach(function (audience) {
            if (_this2.selectedAudienceIds.includes(Number(audience.id))) {
              Ember.set(audience, 'selected', true);
            }
          });
        }

        Ember.set(_this2, 'audiences', audiences);
      });
    },
    countAudienceUsers: function countAudienceUsers(audienceId) {
      var audienceUserCountList = this.audienceUserCountList;
      this.audiences.forEach(function (a) {
        if (a.id == audienceId) {
          audienceUserCountList.push(a.userCount);
        }
      });
      Ember.set(this, 'audienceUserCountList', audienceUserCountList);
    },
    actions: {
      next: function next() {
        Ember.set(this, 'page', this.page + 1);
        this.loadAudiences();
      },
      prev: function prev() {
        Ember.set(this, 'page', this.page - 1);
        this.loadAudiences();
      },
      selectAudience: function selectAudience(audience) {
        var audienceIds = this.selectedAudienceIds || [];
        var audienceUserCountList = this.audienceUserCountList || [];
        this.audiences.forEach(function (a) {
          if (a.id == audience.id) {
            Ember.set(a, 'selected', !a.selected);

            if (a.selected) {
              audienceIds.push(Number(a.id));
              audienceUserCountList.push(a.userCount);
            } else {
              var idIndex = audienceIds.indexOf(Number(a.id));

              if (idIndex > -1) {
                audienceIds.splice(idIndex, 1);
              }

              var userCountIndex = audienceUserCountList.indexOf(a.userCount);

              if (userCountIndex > -1) {
                audienceUserCountList.splice(userCountIndex, 1);
              }
            }
          }
        });
        Ember.set(this, 'selectedAudienceIds', audienceIds);
        Ember.set(this, 'audienceUserCountList', audienceUserCountList);
      },
      validate: function validate() {
        if (this.onFinish) {
          this.onFinish(this.selectedAudienceIds.map(function (id) {
            return Number(id);
          }), this.audienceUserCountList);
        }
      },
      onSearchKeyUp: function onSearchKeyUp() {
        var _this3 = this;

        if (this.searchCall) {
          clearTimeout(this.searchCall);
        }

        var searchCall = setTimeout(function () {
          _this3.loadAudiences();
        }, 300);
        Ember.set(this, 'searchCall', searchCall);
      }
    }
  });

  _exports.default = _default;
});