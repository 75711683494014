define("teelt-frontend/routes/board/task/task-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        taskId: params.task_id,
        task: this.store.findRecord('task', params.task_id, {
          reload: true
        })
      });
    }
  });

  _exports.default = _default;
});