define("teelt-frontend/components/ihm/explore-office/explore-office-modal", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.loadExploreOffice();
    },
    loadExploreOffice: function loadExploreOffice() {
      var _this = this;

      var path = _environment.default.APP.API_HOST + '/exploreoffices/' + this.exploreOffice.id + '/response/' + this.talent.id;
      this.ajax.requestPromise(path, 'GET').then(function (exploreOffice) {
        Ember.set(_this, 'fields', exploreOffice.fields);
      });
    },
    close: function close() {
      if (this.exploreOfficeCompleted) {
        Ember.set(this, 'show', false);
        Ember.set(this, 'exploreOfficeCompleted', false);
        window.location.reload();
      } else {
        Ember.set(this, 'show', false);
        Ember.set(this, 'exploreOfficeCompleted', false);
      }
    },
    actions: {
      onClose: function onClose() {
        this.close();
      },
      onExploreOfficeCompleted: function onExploreOfficeCompleted() {
        Ember.set(this, 'exploreOfficeCompleted', true);
      }
    }
  });

  _exports.default = _default;
});