define("teelt-frontend/components/talents/import/searchable-select", ["exports", "teelt-frontend/utils/jquery-utils", "teelt-frontend/utils/string-util"], function (_exports, _jqueryUtils, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['searchable-select'],
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    customErrorService: Ember.inject.service('custom-error'),
    userService: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    limit: 10,
    eventBus: Ember.inject.service('event-bus'),
    search: '',
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'uuid', (0, _stringUtil.default)().generateUUID());
      this.filterValues();
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      setTimeout(function () {
        _this.eventBus.on('CLICK_EVENT', _this, 'checkToCloseModal');
      }, 300);
      this.values.forEach(function (element) {
        if (_this.headerObj.header == element.key && element.selected == element.label) {
          _this.$('.select-dropdown').val(_this.intl.t("talent.table.keys." + element.label));
        }
      });
    },
    destroy: function destroy() {
      this.eventBus.off('CLICK_EVENT', this, 'checkToCloseModal');

      this._super.apply(this, arguments);
    },
    checkToCloseModal: function checkToCloseModal(e) {
      if (!(0, _jqueryUtils.default)().checkIfClickInElement(e, ["searchable-select-list-value-" + this.uuid, this.uuid])) {
        if (this.showList) {
          Ember.set(this, 'showList', false);
        }
      }
    },
    filterValues: function filterValues() {
      var _this2 = this;

      if (this.values) {
        if (this.search.length == 0) {
          Ember.set(this, 'filteredValues', this.values.slice(0, this.limit));
        } else {
          Ember.set(this, 'filteredValues', this.values.filter(function (value) {
            return _this2.intl.t("talent.table.keys." + value.label).toLowerCase().indexOf(_this2.search.toLowerCase()) >= 0;
          }));
        }
      }
    },
    isSelected: function isSelected(value) {
      if (!this.selected) return false;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.selected[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var sel = _step.value;
          if (sel.key == value.key) return true;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return false;
    },
    actions: {
      onSearchChange: function onSearchChange() {
        this.filterValues();
      },
      select: function select(value) {
        Ember.set(this, 'selected', value);
        Ember.set(this, 'showList', false);

        if (this.onValueChange) {
          this.onValueChange(value, this.headerObj);
        }

        if (value) {
          this.$('.select-dropdown').val(this.intl.t("talent.table.keys." + value.label));
        } else {
          this.$('.select-dropdown').val("");
        }
      },
      onAddAction: function onAddAction() {
        if (this.canAddAction) {
          this.canAddAction();
        }
      }
    }
  });

  _exports.default = _default;
});