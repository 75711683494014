define("teelt-frontend/templates/components/talents/talent-information-block-tasks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eICHjaxZ",
    "block": "{\"symbols\":[\"task\",\"index\"],\"statements\":[[4,\"if\",[[23,[\"tasks\",\"length\"]]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"tasks\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"info-block-title\"],[9],[1,[27,\"t\",[\"task.to_realise\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"info-task-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"tasks\"]]],null,{\"statements\":[[4,\"if\",[[27,\"gt\",[[22,2,[]],0],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"separator\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\"],[11,\"class\",\"task\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"truncate task-name\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"task\"]]],null,{\"statements\":[[0,\"        \"],[1,[22,1,[\"task\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[22,1,[\"event\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n\"],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"left\"],[9],[0,\"\\n    \"],[4,\"link-to\",[\"talent.integration.tasks\"],null,{\"statements\":[[1,[27,\"t\",[\"word.see_complete_list\"],null],false]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[7,\"div\"],[11,\"class\",\"tasks success\"],[9],[0,\"\\n  \"],[1,[27,\"utils/all-done-image\",null,[[\"title\",\"description\",\"imageSrc\"],[[27,\"t\",[\"task.congrats_all_done\"],null],[27,\"t\",[\"task.no_more_to_perform\"],null],\"/img/icons/all_tasks_done.png\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-information-block-tasks.hbs"
    }
  });

  _exports.default = _default;
});