define("teelt-frontend/models/statics/event-category", ["exports", "teelt-frontend/models/statics/static"], function (_exports, _static) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _static.default.extend({});

  _exports.default = _default;
});