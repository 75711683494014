define("teelt-frontend/components/translation/translation-popup", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //classNames: ['period-new'],
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.send('initLang');
    },
    actions: {
      initLang: function initLang() {
        var _this = this;

        Ember.set(this, 'traductionTitle', this.elementToUpdate.name);
        Ember.set(this, 'langs', {
          de: '',
          en: '',
          es: '',
          fr: '',
          it: '',
          pl: '',
          pt: ''
        });
        this.ajax.requestPromise(_environment.default.APP.API_HOST + '/' + this.routePrefix + '/' + this.elementToUpdate.id + '/translations', 'GET').then(function (elementTranslation) {
          Ember.set(_this.langs, 'de', elementTranslation.de);
          Ember.set(_this.langs, 'en', elementTranslation.en);
          Ember.set(_this.langs, 'es', elementTranslation.es);
          Ember.set(_this.langs, 'fr', elementTranslation.fr);
          Ember.set(_this.langs, 'it', elementTranslation.it);
          Ember.set(_this.langs, 'pl', elementTranslation.pl);
          Ember.set(_this.langs, 'pt', elementTranslation.pt);
          Ember.set(_this, 'showModal', true);
        });
      },
      saveTranslations: function saveTranslations() {
        var _this2 = this;

        this.ajax.requestWithBodyPromise(_environment.default.APP.API_HOST + '/' + this.routePrefix + '/' + this.elementToUpdate.id + '/translations', 'PATCH', 'application/json', JSON.stringify(this.langs)).then(function () {
          Ember.set(_this2, 'showModal', false);

          if (_this2.onFinish) {
            _this2.onFinish(_this2.langs[_this2.session.data.authenticated.user.lang]);
          }
        });
      }
    }
  });

  _exports.default = _default;
});