define("teelt-frontend/templates/form-print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TX+fh1H3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"form-print\"],[11,\"class\",\"form-print\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"print-container\"],[11,\"id\",\"print-container\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"not\",[[23,[\"noTitle\"]]],null]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"form-name\"],[9],[0,\"\\n                \"],[7,\"h4\"],[9],[1,[23,[\"model\",\"form\",\"title\"]],false],[10],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"span\"],[9],[1,[23,[\"model\",\"talent\",\"firstname\"]],false],[0,\" \"],[1,[23,[\"model\",\"talent\",\"lastname\"]],false],[10],[0,\"\\n            \"],[7,\"span\"],[9],[1,[27,\"t\",[\"talent.table.header.welcomeDate\"],null],false],[0,\" : \"],[1,[27,\"moment-format\",[[23,[\"model\",\"talent\",\"welcomeDate\"]],\"DD/MM/YYYY\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"showFinalScore\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"final-score\"],[9],[0,\"\\n                \"],[1,[23,[\"model\",\"form\",\"result\"]],false],[0,\" / \"],[1,[23,[\"model\",\"form\",\"maxResult\"]],false],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"fields-container\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"showResults\"]]],null,{\"statements\":[[0,\"                \"],[1,[27,\"form/form-preview\",null,[[\"noTitle\",\"form\",\"allOpened\",\"isDraft\",\"isPdf\",\"canRespond\",\"responseMode\",\"canEdit\",\"talentId\"],[true,[23,[\"model\",\"form\"]],true,false,true,false,true,false,[23,[\"model\",\"talent\",\"id\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/form-print.hbs"
    }
  });

  _exports.default = _default;
});