define("teelt-frontend/templates/components/utils/modal/basic-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g5fYz9M9",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"onClose\",\"translucentOverlay\",\"containerClass\"],[[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"show\"]]],null],false],null],true,\"basic-confirm\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"inner-container\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n    \"],[7,\"p\"],[9],[1,[21,\"message\"],false],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"controls right\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"class\",\"btn btn-white waves-effect waves-light\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"show\"]]],null],false]],[9],[1,[27,\"if\",[[23,[\"cancelLabel\"]],[23,[\"cancelLabel\"]],[27,\"t\",[\"word.cancel\"],null]],null],false],[10],[0,\"\\n      \"],[7,\"a\"],[11,\"class\",\"btn btn-confirm waves-effect waves-light\"],[3,\"action\",[[22,0,[]],\"onConfirm\"]],[9],[1,[27,\"if\",[[23,[\"confirmLabel\"]],[23,[\"confirmLabel\"]],[27,\"t\",[\"word.yes\"],null]],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/modal/basic-confirm.hbs"
    }
  });

  _exports.default = _default;
});