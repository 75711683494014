define("teelt-frontend/routes/talent/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    beforeModel: function beforeModel() {
      // old route, new one is located under integration
      if (!this.session.data.authenticated.user.company.canCreateHome) {
        this.transitionTo('talent.integration.tasks');
      } else {
        this.transitionTo('talent.integration.home');
      }
    }
  });

  _exports.default = _default;
});