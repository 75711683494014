define("teelt-frontend/components/event/add-campaign-form", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['add-campaign-form'],
    customErrorService: Ember.inject.service('custom-error'),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    uiUtils: Ember.inject.service('ui-utils'),
    dateFormat: 'DD/MM/YYYY',
    page: 1,
    maxPage: 2,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.campaign) {
        if (this.duplicateId) {
          this.store.findRecord('events/campaign', this.duplicateId, {
            reload: true
          }).then(function (campaignToDuplicate) {
            var campaignJSON = campaignToDuplicate.toJSON();
            campaignJSON['id'] = undefined;
            campaignJSON['name'] = undefined;
            campaignJSON['selectedTalentIds'] = [];
            Ember.set(_this, 'duplicateInitFromDate', campaignJSON.fromDate);

            var campaign = _this.store.createRecord('events/campaign', campaignJSON);

            Ember.set(_this, 'campaign', campaign);

            var fromDate = _this.campaign.get('fromDate');

            _this.setFromDate(fromDate);

            Ember.set(_this.campaign, "tempFromDate", (0, _moment.default)(fromDate).format(_this.dateFormat));

            var toDate = _this.campaign.get('toDate');

            _this.setToDate(toDate);

            Ember.set(_this.campaign, "tempToDate", (0, _moment.default)(toDate).format(_this.dateFormat));
          });
        } else {
          var campaign = this.store.createRecord('events/campaign', {
            selectedTalentIds: []
          });
          Ember.set(this, 'campaign', campaign);
        }
      } else {
        var selectedTalentIds = [];

        if (this.campaign.users) {
          this.campaign.users.forEach(function (talent) {
            selectedTalentIds.push(talent.id);
          });
        }

        Ember.set(this.campaign, 'selectedTalentIds', selectedTalentIds);
        var fromDate = this.campaign.get('fromDate');

        if ((0, _moment.default)().startOf('day').isAfter((0, _moment.default)(fromDate).startOf('day'))) {
          Ember.set(this, 'blockDateEdit', true);
        }

        this.setFromDate(fromDate);
        Ember.set(this.campaign, "tempFromDate", (0, _moment.default)(fromDate).format(this.dateFormat));
        var toDate = this.campaign.get('toDate');
        this.setToDate(toDate);
        Ember.set(this.campaign, "tempToDate", (0, _moment.default)(toDate).format(this.dateFormat));
      }
    },
    setFromDate: function setFromDate(date) {
      date = (0, _moment.default)(date).toDate();
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var dateZ = year + '-' + (month > 9 ? month : '0' + month) + '-' + (day > 9 ? day : '0' + day) + ' 00:00:00.000Z'; // In duplicate mode we move events

      if (this.duplicateId && this.fromDate) {
        var diffDays = _moment.default.utc(date).diff(_moment.default.utc(this.fromDate), 'days') + 1;

        if (this.campaign.events) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = this.campaign.events[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var event = _step.value;
              event.start = _moment.default.utc(event.start).add(diffDays, 'days').format();

              if (event.end) {
                event.end = _moment.default.utc(event.end).add(diffDays, 'days').format();
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        }

        if (this.toDate) {
          var toDate = (0, _moment.default)(this.toDate).add(diffDays, 'days').format('YYYY-MM-DD');
          this.setToDate(toDate);
          Ember.set(this.campaign, "tempToDate", (0, _moment.default)(toDate).format(this.dateFormat));
        }
      }

      this.blockDateEdit ? Ember.set(this, "fromDate", date) : Ember.set(this, "fromDate", dateZ);
    },
    setToDate: function setToDate(date) {
      date = (0, _moment.default)(date).toDate();
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var dateZ = year + '-' + (month > 9 ? month : '0' + month) + '-' + (day > 9 ? day : '0' + day) + ' 00:00:00.000Z';
      this.blockDateEdit ? Ember.set(this, "toDate", date) : Ember.set(this, "toDate", dateZ);
      Ember.set(this, "toDateIsBeforeFromDate", undefined);
    },
    storeCalendarEventOnCampaign: function storeCalendarEventOnCampaign() {
      if (this.page == 2 && this.calendar) {
        var events = this.calendar.getDBEvents();
        this.campaign.set('events', events);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.didInsertElementPage1();
    },
    initToDateDatepicker: function initToDateDatepicker(minDate) {
      var _this2 = this;

      this.uiUtils.initDatePicker(this.$('#campaign-toDate'), function (date) {
        _this2.setToDate(date);
      }, {
        minDate: this.blockDateEdit ? this.toDate : minDate,
        defaultDate: this.toDate ? this.toDate : minDate,
        autoClose: false
      });
    },
    didInsertElementPage1: function didInsertElementPage1() {
      var _this3 = this;

      setTimeout(function () {
        _this3.uiUtils.initDatePicker(_this3.$('#campaign-fromDate'), function (date) {
          _this3.setFromDate(date);

          _this3.initToDateDatepicker(date);
        }, {
          minDate: _this3.blockDateEdit ? _this3.fromDate : _moment.default.now(),
          defaultDate: _this3.fromDate,
          autoClose: false
        });

        _this3.initToDateDatepicker(_moment.default.now());
      }, 0);
    },
    validateCampaign: function validateCampaign() {
      this.resetErrors();
      var ok = true;

      if (!this.campaign.name) {
        Ember.set(this, 'nameIsNull', true);
        ok = false;
      }

      if (!this.fromDate) {
        Ember.set(this, "fromDateIsNull", true);
        ok = false;
      }

      if (!this.toDate) {
        Ember.set(this, "toDateIsNull", true);
        ok = false;
      }

      if (this.fromDate && this.toDate) {
        if (_moment.default.utc(this.fromDate).isAfter(_moment.default.utc(this.toDate))) {
          Ember.set(this, "toDateIsBeforeFromDate", true);
          ok = false;
        }
      }

      return ok;
    },
    resetErrors: function resetErrors() {
      Ember.set(this, 'nameIsNull', undefined);
      Ember.set(this, "fromDateIsNull", undefined);
      Ember.set(this, "toDateIsNull", undefined);
      Ember.set(this, "toDateIsBeforeFromDate", undefined);
    },
    actions: {
      save: function save() {
        var _this4 = this;

        if (!this.validateCampaign()) return;
        this.campaign.set('fromDate', (0, _moment.default)(this.fromDate).format('YYYY-MM-DD'));
        this.campaign.set('toDate', (0, _moment.default)(this.toDate).format('YYYY-MM-DD'));
        this.storeCalendarEventOnCampaign();
        this.campaign.save().then(function (campaign) {
          Ember.set(_this4, 'saved', true);

          _this4.send('close');
        }).catch(function (err) {
          _this4.customErrorService.handleAjaxError(err);
        });
      },
      close: function close() {
        this.router.transitionTo('board.events');
      },
      next: function next() {
        if (this.page == 2) {
          this.send('save');
          return;
        }

        if (this.page == 1 && !this.validateCampaign()) return;
        this.storeCalendarEventOnCampaign();
        Ember.set(this, 'page', this.page + 1);
        Ember.set(this, "shouldOpenModal", true);
      },
      previous: function previous() {
        if (this.page == 1) {
          this.send('close');
          return;
        }

        this.storeCalendarEventOnCampaign();
        Ember.set(this, 'page', this.page - 1);
        Ember.set(this, "shouldOpenModal", false);

        if (this.page == 1) {
          this.didInsertElementPage1();
        }
      },
      shareCalendar: function shareCalendar(calendar) {
        Ember.set(this, "calendar", calendar);
      },
      unsavedConfirm: function unsavedConfirm(response) {
        if (response != null) {
          if (response) {
            this.send('save');
          } else {
            this.send('close');
          }
        }
      }
    }
  });

  _exports.default = _default;
});