define("teelt-frontend/components/ihm/explore-office/explore-office-blocks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['explore-office-blocks'],
    userService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.talent) {
        var periods = this.userService.getTalentPeriods(this.talent);
        Ember.set(this, 'periods', periods);
        Ember.set(this, 'talentCurrentPeriod', this.userService.getTalentCurrentPeriod(this.talent, periods));
        Ember.set(this, 'currentPeriod', periods[this.talentCurrentPeriod]);
      }
    },
    actions: {
      toggleCurrentPeriod: function toggleCurrentPeriod(period) {
        Ember.set(this, 'currentPeriod', period);
      },
      onClickOverlay: function onClickOverlay() {}
    }
  });

  _exports.default = _default;
});