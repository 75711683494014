define("teelt-frontend/components/custom-field/display/with-fetch/talent-name", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.talentId || !this.codeKey) return;
      var url = _environment.default.APP.API_HOST + "/users/" + this.talentId + "/fromCustomLink/" + this.codeKey;
      this.ajax.requestPromise(url, 'GET').then(function (talent) {
        Ember.set(_this, 'talent', talent);
      });
    }
  });

  _exports.default = _default;
});