define("teelt-frontend/templates/components/session/auto-disconnect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EsTY12Ks",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"showIncommingDisconnect\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"onClose\",\"translucentOverlay\",\"containerClass\",\"onClickOverlay\"],[[27,\"action\",[[22,0,[]],\"onClose\"],null],true,\"basic-confirm\",[27,\"action\",[[22,0,[]],\"onClickOverlay\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"inner-container\"],[9],[0,\"\\n      \"],[7,\"h1\"],[9],[1,[27,\"t\",[\"auto_disconnect.modal.title\"],null],false],[10],[0,\"\\n      \"],[7,\"p\"],[9],[1,[27,\"t\",[\"auto_disconnect.modal.message\"],null],false],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"controls center\"],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"class\",\"btn btn-confirm waves-effect waves-light\"],[3,\"action\",[[22,0,[]],\"onClose\"]],[9],[1,[27,\"t\",[\"auto_disconnect.modal.confirm\"],null],false],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/session/auto-disconnect.hbs"
    }
  });

  _exports.default = _default;
});