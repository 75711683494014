define("teelt-frontend/templates/components/icon/flag/de-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fZkuk129",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"width\",\"14\"],[11,\"height\",\"14\"],[11,\"viewBox\",\"0 0 64 64\"],[11,\"fill\",\"none\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[9],[0,\"\\n  \"],[7,\"path\"],[11,\"d\",\"M31.9001 2C18.8001 2 7.7001 10.4 3.6001 22H60.2001C56.1001 10.4 45.0001 2 31.9001 2Z\"],[11,\"fill\",\"#3E4347\"],[9],[10],[0,\"\\n  \"],[7,\"path\"],[11,\"d\",\"M31.9001 62C45.0001 62 56.1001 53.7 60.2001 42H3.6001C7.7001 53.7 18.8001 62 31.9001 62Z\"],[11,\"fill\",\"#FFE62E\"],[9],[10],[0,\"\\n  \"],[7,\"path\"],[11,\"d\",\"M3.5999 22C2.4999 25.1 1.8999 28.5 1.8999 32C1.8999 35.5 2.4999 38.9 3.5999 42H60.1999C61.2999 38.9 61.8999 35.5 61.8999 32C61.8999 28.5 61.2999 25.1 60.1999 22H3.5999Z\"],[11,\"fill\",\"#ED4C5C\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/icon/flag/de-svg.hbs"
    }
  });

  _exports.default = _default;
});