define("teelt-frontend/components/input/validation-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['validation-input', 'input-field'],
    actions: {
      keyUpAction: function keyUpAction() {
        if (this.onKeyUp) {
          this.onKeyUp(this.value);
        }
      },
      addProposalItem: function addProposalItem(item) {
        if (this.onAddProposalItem) {
          this.onAddProposalItem(item);
        }
      }
    }
  });

  _exports.default = _default;
});