define("teelt-frontend/components/talents/talent-information-block-message", ["exports", "teelt-frontend/config/environment", "jquery", "moment"], function (_exports, _environment, _jquery, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    defaultProfileImageUrl: _environment.default.APP.DEFAULT_PROFILE_IMAGE_URL,
    mobileView: window.innerWidth < 600,
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    userService: Ember.inject.service(),
    customCompanyPropertyService: Ember.inject.service('custom-company-property'),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.addObserver(this.talent, 'remainingTalentTaskNumber', this, 'onTaskNumberChange');
      Ember.addObserver(this.router, 'currentRouteName', this, 'onRouteChanged');
      Ember.addObserver(this.customCompanyPropertyService, 'properties', this, 'onPropertiesChanged');

      if (this.homepage) {
        Ember.addObserver(this.homepage, 'managerMessage', this, 'onPropertiesChanged');
      }

      Ember.addObserver(this, 'homepage', this, 'onPropertiesChanged');
      this.updateDisplay();
    },
    destroy: function destroy() {
      Ember.removeObserver(this.talent, 'remainingTalentTaskNumber', this, 'onTaskNumberChange');
      Ember.removeObserver(this.router, 'currentRouteName', this, 'onRouteChanged');
      Ember.removeObserver(this.customCompanyPropertyService, 'properties', this, 'onPropertiesChanged');

      if (this.homepage) {
        Ember.removeObserver(this.homepage, 'managerMessage', this, 'onPropertiesChanged');
      }

      Ember.removeObserver(this, 'homepage', this, 'onPropertiesChanged');

      this._super.apply(this, arguments);
    },
    talentName: Ember.computed('talent', function () {
      return this.userService.getTalentFirstname(this.talent);
    }),
    onTaskNumberChange: function onTaskNumberChange() {
      var _this = this;

      Ember.run.later(function () {
        if (_this.isDestroyed) return;

        _this.setMessage();
      });
    },
    onRouteChanged: function onRouteChanged() {
      var _this2 = this;

      Ember.run.later(function () {
        if (_this2.isDestroyed) return;

        _this2.setMessage();

        _this2.updateDisplay();
      });
    },
    onPropertiesChanged: function onPropertiesChanged() {
      var _this3 = this;

      Ember.run.later(function () {
        if (_this3.isDestroyed) return;

        _this3.setMessage();
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.talent.manager) {
        Ember.set(this, 'managerName', this.talent.manager.firstname ? this.userService.getTalentName(this.talent.manager, false) : this.intl.t('talent.roles.ROLE_MANAGER.default'));
        Ember.set(this, 'managerImage', this.talent.manager.profilePictureUrl ? this.talent.manager.profilePictureUrl : undefined);
      }

      this.setMessage();
    },
    updateDisplay: function updateDisplay() {
      Ember.set(this, 'showPeriodProgression', true);

      if (this.mobileView) {
        if (this.router.currentRouteName != 'talent.integration.tasks') {
          Ember.set(this, 'showPeriodProgression', false);
        }
      }
    },
    setMessage: function setMessage() {
      var _this4 = this;

      var managerMessage = undefined;

      if (this.homepage && this.homepage.managerMessage) {
        managerMessage = this.replaceMarkersInText(this.homepage.managerMessage);
      } else {
        var completed = false;
        var rand = this.getRandomInt(3);
        var eventnumber = this.talent.totalEventNumber - this.talent.pastEventNumber;
        var tasknumber = eventnumber + this.talent.remainingTalentTaskNumber;
        var routeName = this.router.get('currentRouteName');
        routeName = routeName.replace(/\./g, '_'); // redirect old route

        if (routeName === "talent_integration_home") {
          routeName = "talent_home";
        } // default team message to home


        if (routeName.startsWith("talent_integration_team")) {
          routeName = "talent_home";
        }

        if (tasknumber == 0 && routeName === 'talent_integration_tasks') {
          completed = true;
        }

        if (eventnumber == 0 && routeName === 'talent_integration_events') {
          completed = true;
        }

        var name = this.userService.getTalentFirstname(this.talent);
        var company = this.talent.company.name;
        var customManagerMessages = this.customCompanyPropertyService.properties ? this.customCompanyPropertyService.properties.managerMessages : undefined;

        if (!this.talent.selectedTemplateId) {
          managerMessage = this.intl.t('manager_messages.talent_home.message0', {
            name: name,
            company: company
          });
        } else if (customManagerMessages && customManagerMessages[routeName]) {
          if (completed && customManagerMessages[routeName].completed) {
            managerMessage = customManagerMessages[routeName].completed;
          } else {
            if (customManagerMessages[routeName]["message" + rand]) {
              managerMessage = customManagerMessages[routeName]["message" + rand];
            } else {
              managerMessage = customManagerMessages[routeName]["message0"];
            }
          }

          managerMessage = this.replaceMarkersInText(managerMessage);
        } else {
          var translateKey = undefined;

          if (completed) {
            if (this.intl.exists('manager_messages.' + routeName + '.completed')) {
              translateKey = 'manager_messages.' + routeName + '.completed';
            }
          } else {
            if (this.intl.exists('manager_messages.' + routeName + '.message' + rand)) {
              translateKey = 'manager_messages.' + routeName + '.message' + rand;
            } else if (this.intl.exists('manager_messages.' + routeName + '.message0')) {
              translateKey = 'manager_messages.' + routeName + '.message0';
            }
          }

          managerMessage = this.intl.t(translateKey, {
            name: name,
            tasknumber: tasknumber,
            eventnumber: eventnumber,
            company: company
          });
        }
      }

      Ember.set(this, 'managerMessage', managerMessage);
      Ember.run.later(function () {
        (0, _jquery.default)('.talent-information-block .tasknumber').click(function () {
          _this4.router.transitionTo('talent.integration.tasks');
        });
      });
    },
    getRandomInt: function getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
    replaceMarkersInText: function replaceMarkersInText(text) {
      if (!text || !this.talent) return;
      var eventnumber = this.talent.totalEventNumber - this.talent.pastEventNumber;
      var tasknumber = eventnumber + this.talent.remainingTalentTaskNumber;
      var name = this.userService.getTalentFirstname(this.talent);
      var company = this.talent.company.name;
      var welcomeDate = this.talent.welcomeDate;

      var welcomeDateDiff = _moment.default.duration((0, _moment.default)(new Date()).diff(welcomeDate)).humanize();

      return text.replace(/{name}/g, name).replace(/{company}/g, company).replace(/{tasknumber}/g, tasknumber).replace(/{eventnumber}/g, eventnumber).replace(/{welcomedate}/g, (0, _moment.default)(welcomeDate).format('DD/MM/YYYY')).replace(/{welcomehour}/g, (0, _moment.default)(welcomeDate).format('HH:mm')).replace(/{welcomedatediff}/g, welcomeDateDiff);
    }
  });

  _exports.default = _default;
});