define("teelt-frontend/routes/talent/integration/tasks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      contentTaskId: ''
    },
    resetController: function resetController(controller) {
      Ember.set(this.currentModel, 'contentTaskId', null);
      controller.set('contentTaskId', null);
    },
    afterModel: function afterModel(model, params) {
      if (params.queryParams && params.queryParams.contentTaskId) {
        Ember.set(model, 'contentTaskId', params.queryParams.contentTaskId);
      }
    }
  });

  _exports.default = _default;
});