define("teelt-frontend/routes/board/setting/teelt-ai", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        aiDescription: this.ajax.requestPromise(_environment.default.APP.API_HOST + '/companies/' + this.session.data.authenticated.user.company.id + "/aidescription", 'GET').then(function (aiDescription) {
          return aiDescription.aiDescription;
        })
      });
    },
    saveAIDescription: function saveAIDescription() {
      var path = _environment.default.APP.API_HOST + '/companies/' + this.session.data.authenticated.user.company.id + "/aidescription";
      var param = {
        aiDescription: this.currentModel.aiDescription
      };
      this.ajax.requestWithBodyPromise(path, "PATCH", "application/json", JSON.stringify(param));
    },
    actions: {
      handleKeyUp: function handleKeyUp() {
        var _this = this;

        var timeout = this.currentEditTimeout;

        if (timeout) {
          clearTimeout(timeout);
        }

        timeout = setTimeout(function () {
          _this.saveAIDescription();
        }, 500);
        Ember.set(this, 'currentEditTimeout', timeout);
      }
    }
  });

  _exports.default = _default;
});