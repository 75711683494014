define("teelt-frontend/templates/talent/integration/forms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7z6yU97X",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"form/forms-list-talent-integration\",null,[[\"talent\",\"forceFormId\"],[[23,[\"model\",\"talent\"]],[23,[\"model\",\"formId\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/talent/integration/forms.hbs"
    }
  });

  _exports.default = _default;
});