define("teelt-frontend/templates/components/talents/talent-information-block-period-progression", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FCHHR05K",
    "block": "{\"symbols\":[\"period\",\"index\"],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"talent\",\"talentProgression\"]],100],null]],null,{\"statements\":[[0,\"  \"],[7,\"img\"],[11,\"class\",\"frame\"],[11,\"src\",\"/img/icons/svg/launch-journey-drawing-completed.svg\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"img\"],[11,\"class\",\"frame\"],[11,\"src\",\"/img/icons/svg/launch-journey-drawing.svg\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"progression-line\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"progression-inner-line\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"ul\"],[11,\"class\",\"progression-steps\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"periods\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[12,\"class\",[28,[\"progression-step step-\",[22,2,[]],\" \",[27,\"if\",[[27,\"eq\",[[27,\"mod\",[[22,2,[]],2],null],0],null],\"inversed\"],null],\" \",[27,\"if\",[[27,\"lte\",[[22,2,[]],[23,[\"talentCurrentPeriod\"]]],null],\"active\"],null]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"step-bubble\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"step-text\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-information-block-period-progression.hbs"
    }
  });

  _exports.default = _default;
});