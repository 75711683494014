define("teelt-frontend/components/emails/multiple-offset-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['multiple-offset-picker'],
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.offsets) {
        Ember.set(this, 'offsets', [{}]);
      }
    },
    actions: {
      addNewOffset: function addNewOffset() {
        this.offsets.pushObject({});
      },
      onDeleteOffset: function onDeleteOffset(offset) {
        this.offsets.removeObject(offset);
      }
    }
  });

  _exports.default = _default;
});