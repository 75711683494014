define("teelt-frontend/components/company/companies-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['companies-list'],
    store: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    offset: 0,
    limit: 10,
    canLoadMore: false,
    search: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.store.unloadAll('company');
      this.send("loadMore");
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.eventBus.on('SCROLLBOTTOM', this, function () {
        if (_this.canLoadMore) {//this.send("loadMore");
        }
      });
    },
    destroy: function destroy() {
      this.eventBus.off('SCROLLBOTTOM');

      this._super.apply(this, arguments);
    },
    actions: {
      loadMore: function loadMore() {
        var _this2 = this;

        this.store.query('company', {
          offset: this.offset,
          limit: this.limit,
          search: this.search
        }).then(function (companies) {
          var companiesArray = companies.toArray();
          var currentCompanies = _this2.companies;

          if (!currentCompanies) {
            currentCompanies = companiesArray;
          } else {
            if (companies && companiesArray.length > 0) {
              currentCompanies = currentCompanies.concat(companiesArray);
            } else {
              Ember.set(_this2, "canLoadMore", false);
            }
          }

          if (companiesArray.length < _this2.limit) {
            Ember.set(_this2, "canLoadMore", false);
          } else {
            Ember.set(_this2, "canLoadMore", true);
          }

          Ember.set(_this2, 'companies', currentCompanies);
          _this2.offset += _this2.limit;
        });
      },
      searchCompanies: function searchCompanies(search) {
        Ember.set(this, 'search', search);
        Ember.set(this, 'companies', undefined);
        Ember.set(this, 'offset', 0);
        this.send('loadMore');
      }
    }
  });

  _exports.default = _default;
});