define("teelt-frontend/components/utils/simple-percent-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['simple-percent-bar'],
    value: 0,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.changePercent();
    },
    changePercent: function changePercent() {
      var _this = this;

      setTimeout(function () {
        var percent = _this.$('.percent');

        percent.animate({
          width: _this.value + '%'
        });
      }, 0);
    },
    valueChanged: Ember.observer('value', function () {
      this.changePercent();
    })
  });

  _exports.default = _default;
});