define("teelt-frontend/templates/components/analytics/form-analytics-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pavdiRia",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"template-list-table-layout\"],[11,\"style\",\"padding: 0 .75rem;\"],[9],[0,\"\\n  \"],[1,[27,\"utils/default-table\",null,[[\"storageKeysName\",\"modelName\",\"modelFilter\",\"prefixTranslateKey\",\"customResultContentComponent\",\"filters\",\"countUrl\",\"lineHeight\",\"noResultMessage\",\"sortableKeys\",\"reload\",\"sortedKeyInit\",\"sortedDirectionInit\",\"onSelected\",\"search_ph\",\"keys\",\"complexFilters\"],[\"form_reports\",[23,[\"modelName\"]],[23,[\"modelFilter\"]],\"template.table.keys.\",\"analytics/table/form-custom-table-content\",[23,[\"filters\"]],[23,[\"countUrl\"]],58,[27,\"t\",[\"analytics.no_report\"],null],[23,[\"sortableKeys\"]],[23,[\"reloadTimestamp\"]],\"id\",\"DESC\",[27,\"action\",[[22,0,[]],\"onRowSelected\"],null],[27,\"t\",[\"template.search_ph\"],null],[23,[\"keys\"]],[23,[\"complexFilters\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/analytics/form-analytics-list.hbs"
    }
  });

  _exports.default = _default;
});