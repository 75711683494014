define("teelt-frontend/components/settings/integrations/flatchr-settings", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['flatchr-setting'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.session.data.authenticated.user.company.activeFlatchr) {
        this.router.transitionTo('board.settings');
        return;
      }

      var path = _environment.default.APP.API_HOST + "/integrations/flatchr/settings";
      this.ajax.requestPromise(path).then(function (flatchr) {
        if (!flatchr) {
          flatchr = {};
        }

        Ember.set(_this, 'flatchr', flatchr);
      });
    },
    verifyFlatchr: function verifyFlatchr() {
      var ok = true;
      var errors = {};

      if (!this.flatchr.companyKey || this.flatchr.companyKey.length == 0) {
        errors.companyKey = true;
        ok = false;
      }

      if (!this.flatchr.id && (!this.flatchr.apikey || this.flatchr.apikey.length == 0)) {
        errors.apikey = true;
        ok = false;
      }

      Ember.set(this, 'errors', errors);
      return ok;
    },
    actions: {
      previous: function previous() {
        this.router.transitionTo('board.setting.integrations');
      },
      save: function save() {
        var _this2 = this;

        if (!this.verifyFlatchr()) return;
        var path = _environment.default.APP.API_HOST + "/integrations/flatchr/settings";
        var method = 'PATCH';
        this.ajax.requestWithBodyPromise(path, method, 'application/json', JSON.stringify(this.flatchr)).then(function () {
          _this2.router.transitionTo('board.setting.integrations');
        });
      }
    }
  });

  _exports.default = _default;
});