define("teelt-frontend/templates/components/talents/talent-detail-block/talent-main-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o93mZg4m",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"input-field-v2 \"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"for\",\"position-name\"],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"talent.position_job\"],null]],null],false],[10],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"id\",\"value\",\"autofocus\",\"maxlength\",\"key-up\"],[\"position-name\",[23,[\"talent\",\"position\",\"name\"]],\"true\",\"255\",[27,\"action\",[[22,0,[]],\"onInputChanged\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"input-field-v2\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"\"],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"talent.arrive_at\"],null]],null],false],[10],[0,\"\\n  \"],[1,[27,\"talents/talent-date-edit\",null,[[\"talent\",\"dateKey\",\"openAtInit\",\"onChange\"],[[23,[\"talent\"]],\"welcomeDate\",false,[27,\"action\",[[22,0,[]],\"onInputChanged\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"input-field-v2 \"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"\"],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"talent.migration_at\"],null]],null],false],[10],[0,\"\\n  \"],[1,[27,\"talents/talent-date-edit\",null,[[\"talent\",\"dateKey\",\"openAtInit\",\"onChange\"],[[23,[\"talent\"]],\"migrationDate\",false,[27,\"action\",[[22,0,[]],\"onInputChanged\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"input-field-v2 \"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"\"],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"talent.departure_at\"],null]],null],false],[10],[0,\"\\n  \"],[1,[27,\"talents/talent-date-edit\",null,[[\"talent\",\"dateKey\",\"openAtInit\",\"onChange\"],[[23,[\"talent\"]],\"endDate\",false,[27,\"action\",[[22,0,[]],\"onInputChanged\"],null]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-detail-block/talent-main-block.hbs"
    }
  });

  _exports.default = _default;
});