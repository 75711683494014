define("teelt-frontend/templates/components/utils/circle-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BQahnIPm",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"checkIfZero\"]],[27,\"eq\",[[23,[\"value\"]],0],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"value-number check-icon\"],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"check\"],[10],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"value-number\"],[9],[1,[21,\"value\"],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[4,\"if\",[[27,\"not\",[[23,[\"forceDisablePercent\"]]],null]],null,{\"statements\":[[7,\"svg\"],[12,\"width\",[28,[[27,\"add\",[[23,[\"width\"]],10],null]]]],[12,\"height\",[28,[[27,\"add\",[[23,[\"width\"]],10],null]]]],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[9],[0,\"\\n    \"],[7,\"circle\"],[11,\"id\",\"progress\"],[12,\"r\",[28,[[27,\"div\",[[23,[\"width\"]],2],null]]]],[12,\"cx\",[28,[[27,\"div\",[[27,\"add\",[[23,[\"width\"]],10],null],2],null]]]],[12,\"cy\",[28,[[27,\"div\",[[27,\"add\",[[23,[\"width\"]],10],null],2],null]]]],[12,\"stroke-width\",[28,[[27,\"if\",[[23,[\"strokeWidth\"]],[23,[\"strokeWidth\"]],5],null]]]],[11,\"stroke\",\"#fff\"],[11,\"fill\",\"none\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/circle-number.hbs"
    }
  });

  _exports.default = _default;
});