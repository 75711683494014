define("teelt-frontend/templates/board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u3mOBqgJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"board\"],[9],[0,\"\\n  \"],[1,[27,\"menu/main-menu\",null,[[\"openedMenu\"],[[23,[\"openedMenu\"]]]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"right-pan \",[27,\"if\",[[23,[\"openedMenu\"]],\"menu-opened\"],null]]]],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"],[4,\"if\",[[23,[\"model\",\"customCSS\",\"css\"]]],null,{\"statements\":[[0,\"  \"],[7,\"style\"],[9],[0,\"\\n    \"],[1,[27,\"xss-protect\",[[23,[\"model\",\"customCSS\",\"css\"]]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board.hbs"
    }
  });

  _exports.default = _default;
});