define("teelt-frontend/templates/components/talents/talent-select-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CMguvLv1",
    "block": "{\"symbols\":[\"subTemplate\"],\"statements\":[[4,\"if\",[[23,[\"talent\",\"selectedTemplate\"]]],null,{\"statements\":[[4,\"if\",[[27,\"and\",[[27,\"eq\",[[23,[\"talent\",\"template\",\"type\"]],\"multi\"],null],[27,\"gt\",[[23,[\"talent\",\"template\",\"subTemplates\",\"length\"]],1],null]],null]],null,{\"statements\":[[0,\"        \"],[7,\"select\"],[11,\"id\",\"templateSelect\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"onTemplateChanged\"],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"talent\",\"template\",\"subTemplates\"]]],null,{\"statements\":[[0,\"                \"],[7,\"option\"],[12,\"value\",[22,1,[\"template\",\"id\"]]],[12,\"selected\",[27,\"eq\",[[27,\"number\",[[23,[\"talent\",\"selectedTemplateId\"]]],null],[27,\"number\",[[22,1,[\"template\",\"id\"]]],null]],null]],[9],[1,[27,\"xss-protect\",[[22,1,[\"template\",\"name\"]]],null],true],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[27,\"xss-protect\",[[23,[\"talent\",\"selectedTemplate\",\"name\"]]],null],true],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-select-template.hbs"
    }
  });

  _exports.default = _default;
});