define("teelt-frontend/templates/components/utils/viewer/basic-image-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g8vO2h8R",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"img\"],[12,\"src\",[28,[[21,\"baseUrl\"]]]],[12,\"data-original\",[28,[[21,\"baseUrl\"]]]],[12,\"class\",[28,[[21,\"class\"]]]],[12,\"id\",[28,[\"image-container-\",[21,\"uuid\"]]]],[11,\"style\",\"cursor: pointer;\"],[9],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/viewer/basic-image-viewer.hbs"
    }
  });

  _exports.default = _default;
});