define("teelt-frontend/components/content/content-form", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['content-form'],
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    customErrorService: Ember.inject.service('custom-error'),
    userService: Ember.inject.service(),
    uiUtils: Ember.inject.service("ui-utils"),
    session: Ember.inject.service(),
    showEditor: true,
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.content) {
        var content = this.store.createRecord('content', {
          category: {},
          type: 'textcard',
          section: this.section,
          template: {
            id: this.templateId
          },
          offices: [],
          toDownload: false,
          isVisible: true
        });
        Ember.set(this, 'content', content);
        this.loadCategories();
      } else {
        Ember.set(this, 'section', this.content.section);

        if (!this.content.category) {
          Ember.set(this.content, 'category', {});
        }

        Ember.set(this, 'showMapPreview', true);
        this.loadCategories();
      }

      if (this.content.type === "slideshow") {
        this.initSlideshow();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.content.rollbackAttributes();

      this._super.apply(this, arguments);
    },
    loadCategories: function loadCategories() {
      var _this = this;

      return this.store.findAll('statics/content-category', {
        reload: true
      }).then(function (categories) {
        if (categories) {
          Ember.set(_this, 'categories', categories);

          _this.uiUtils.initAutocomplete((0, _jquery.default)('#autocomplete-category'), categories, "name", function () {// set(this, "newPosition", false);
          });
        } else {
          Ember.set(_this, 'categories', []);
        }
      });
    },
    didInsertElement: function didInsertElement() {
      (0, _jquery.default)('#field-type').formSelect();
    },
    initSlideshow: function initSlideshow() {
      var content = this.content;

      if (content.type === 'slideshow') {
        var urls = content.fullValue;

        if (urls !== "") {
          Ember.set(content, "imageUrlsArray", urls.split(";"));
          Ember.set(this, "splittedUrls", urls.split(";"));
        } else {
          Ember.set(content, 'fullValue', "");
          Ember.set(content, 'mediaUrl', "");
          Ember.set(content, "imageUrlsArray", "");
          Ember.set(this, "splittedUrls", "");
        }
      }
    },
    actions: {
      save: function save() {
        var _this2 = this;

        if (!this.validate()) return;
        this.content.save().then(function (content) {
          if (_this2.onFinish) {
            _this2.onFinish(content);
          }
        }).catch(function (err) {
          _this2.customErrorService.handleAjaxError(err);
        });
      },
      onTypeChanged: function onTypeChanged(value) {
        Ember.set(this.content, 'type', value);
        Ember.set(this.content, 'fullValue', '');
        Ember.set(this.content, 'mediaUrl', '');
      },
      onCategoryChanged: function onCategoryChanged() {},
      onMediaChanged: function onMediaChanged(file) {
        var _this3 = this;

        if (!file) return;
        Ember.set(this, 'blockSave', true);
        var content = this.content;
        this.fileUploadService.signAndUpload(file, false, function (e) {
          return _this3.onMediaProgress(e);
        }).then(function (fileUrl) {
          Ember.set(content, 'mediaUrl', fileUrl);
        }).finally(function () {
          Ember.set(_this3, 'blockSave', false);
        });
      },
      onSlideshowImagesAdded: function onSlideshowImagesAdded() {
        var _this4 = this;

        var filesLimitNumber = 15;
        var content = this.content;
        var files = this.$('#slideshow-file-input .files-container')[0].files;
        if (!files) return;

        if (content.fullValue.split(";").length + files.length > filesLimitNumber) {
          this.notify.alert(this.intl.t('content.alerts.too_many_files'), {
            closeAfter: 5000
          });
          this.$('#slideshow-file-input .file-container')[0].value = "";
          return;
        }

        var filesMaxIndex = files.length - 1;

        for (var i = 0; i <= filesMaxIndex; i++) {
          var file = this.$('#slideshow-file-input .files-container')[0].files[i];
          if (!file) return;

          if (file.type !== 'image/gif' && file.type !== 'image/png' && file.type !== 'image/jpeg') {
            this.notify.alert(this.intl.t('content.alerts.not_slideshow'), {
              closeAfter: 5000
            });
            this.$('#slideshow-file-input .files-container')[0].value = "";
            return;
          }

          Ember.set(this, 'blockSave', true);
          this.fileUploadService.signAndUpload(file, false, function (e) {
            return _this4.onMediaProgress(e);
          }).then(function (fileUrl) {
            var urls = content.fullValue;

            if (!content.mediaUrl) {
              Ember.set(content, "mediaUrl", fileUrl);
              urls += fileUrl;
            } else {
              urls += ";" + fileUrl;
            }

            Ember.set(content, 'fullValue', urls);
            Ember.set(content, "imageUrlsArray", urls.split(";"));
            Ember.set(_this4, "splittedUrls", urls.split(";"));
          }).finally(function () {
            Ember.set(_this4, 'blockSave', false);
            _this4.$('#slideshow-file-input .files-container')[0].value = "";
            Ember.set(_this4, 'mediaProgress', 0);

            _this4.initSlideshow();
          });
        }
      },
      onSlideshowImageRemoved: function onSlideshowImageRemoved(imageUrl) {
        var content = this.content;
        if (content.type !== "slideshow") return;
        var urlsArray = content.fullValue.split(";");
        var currentFullValue = "";
        urlsArray = urlsArray.filter(function (item) {
          return item !== imageUrl;
        });
        urlsArray.forEach(function (singleUrl) {
          if (currentFullValue === "") {
            currentFullValue += singleUrl;
          } else {
            currentFullValue += ";" + singleUrl;
          }
        });

        if (content.mediaUrl === imageUrl) {
          Ember.set(content, "mediaUrl", urlsArray[0]);
        }

        Ember.set(content, "fullValue", currentFullValue);
        Ember.set(content, "imageUrlsArray", urlsArray);
        Ember.set(this, "splittedUrls", urlsArray);
      },
      onContentFileChanged: function onContentFileChanged(file) {
        var _this5 = this;

        if (!file) return;
        Ember.set(this, 'blockSave', true);
        var content = this.content;
        this.fileUploadService.signAndUpload(file, true, function (e) {
          return _this5.onContentFileProgress(e);
        }).then(function (fileUrl) {
          Ember.set(content, 'fullValue', fileUrl);
        }).finally(function () {
          Ember.set(_this5, 'blockSave', false);
        });
      },
      onVideoChanged: function onVideoChanged(file) {
        var _this6 = this;

        if (!file) return;

        if (file.type !== 'video/mp4' && file.type !== 'video/avi') {
          this.notify.alert(this.intl.t('content.alerts.not_video'), {
            closeAfter: 5000
          });
          this.$('#video-file-input .file-container')[0].value = "";
          return;
        }

        if (file.size >= 100 * 1024 * 1024) {
          this.notify.alert(this.intl.t('content.alerts.size_overflow', {
            size: 100
          }), {
            closeAfter: 5000
          });
          this.$('#video-file-input')[0].value = "";
          return;
        }

        Ember.set(this, 'blockSave', true);
        var content = this.content;
        this.fileUploadService.signAndUpload(file, false, function (e) {
          return _this6.onVideoProgress(e);
        }).then(function (fileUrl) {
          Ember.set(content, 'mediaUrl', fileUrl);
        }).finally(function () {
          Ember.set(_this6, 'blockSave', false);
        });
      },
      onMapFocusIn: function onMapFocusIn() {
        Ember.set(this, 'showMapPreview', false);
      },
      onMapFocusOut: function onMapFocusOut() {
        Ember.set(this, 'showMapPreview', true);
      },
      onOfficeChanged: function onOfficeChanged(officeId) {
        var _this7 = this;

        Ember.set(this, 'officeId', officeId);
        Ember.set(this, 'showEditor', false);
        setTimeout(function () {
          Ember.set(_this7, 'showEditor', true);
        }, 0);
        Ember.set(this, 'mediaProgress', undefined);
        Ember.set(this, 'videoProgress', undefined);
        Ember.set(this, 'contentFileProgress', undefined);
        this.initSlideshow();
      },
      onTagChanged: function onTagChanged(tagId) {
        var _this8 = this;

        Ember.set(this, 'tagId', tagId);
        Ember.set(this, 'showEditor', false);
        setTimeout(function () {
          Ember.set(_this8, 'showEditor', true);
        }, 0);
        Ember.set(this, 'mediaProgress', undefined);
        Ember.set(this, 'videoProgress', undefined);
        Ember.set(this, 'contentFileProgress', undefined);
        this.initSlideshow();
      },
      previous: function previous() {
        this.onPrevious();
      },
      openEditVersion: function openEditVersion() {
        var _this9 = this;

        if (!this.validate()) return;
        this.content.save().then(function () {
          if (_this9.openEditVersion) {
            _this9.openEditVersion(_this9.content);
          }
        });
      },
      onThumbnailChanged: function onThumbnailChanged(file) {
        var _this10 = this;

        if (!file) return;
        Ember.set(this, 'blockSave', true);
        var content = this.content;
        this.fileUploadService.signAndUpload(file, false, function (e) {
          return _this10.onThumbnailProgress(e);
        }).then(function (fileUrl) {
          Ember.set(content, 'thumbnailUrl', fileUrl);
        }).finally(function () {
          Ember.set(_this10, 'blockSave', false);
        });
      }
    },
    validate: function validate() {
      this.resetErrors();

      if (!this.content.section) {
        console.error('missing section');
        return false;
      }

      if (!this.content.title) {
        Ember.set(this, 'titleIsNull', true);
        this.notify.error(this.intl.t('content.errors.title_is_null'), {
          closeAfter: 5000
        });
        return false;
      }

      if (!this.content.category || !this.content.category.name) {
        this.notify.alert(this.intl.t('content.alerts.select_category'), {
          closeAfter: 5000
        });
        return false;
      }

      return true;
    },
    resetErrors: function resetErrors() {
      Ember.set(this, 'titleIsNull', undefined);
    },
    onMediaProgress: function onMediaProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'mediaProgress', progress);
      }
    },
    onVideoProgress: function onVideoProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'videoProgress', progress);
      }
    },
    onContentFileProgress: function onContentFileProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'contentFileProgress', progress);
      }
    },
    onThumbnailProgress: function onThumbnailProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'thumbnailProgress', progress);
      }
    }
  });

  _exports.default = _default;
});