define("teelt-frontend/routes/talent/integration/team/same-month", ["exports", "teelt-frontend/routes/talent/integration/team/members-list"], function (_exports, _membersList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _membersList.default.extend({
    session: Ember.inject.service(),
    model: function model() {
      return {
        welcomeDate: this.session.data.authenticated.user.welcomeDate
      };
    }
  });

  _exports.default = _default;
});