define("teelt-frontend/components/talents/talent-information-block", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talent-information-block'],
    mobileView: window.innerWidth < 600,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // let blockWidth = $('.talent-information-block').width() - 40;
      // let extremityWidth = blockWidth / 4 * 1.5;
      // let progressWidth = blockWidth / 4;
      // let useMobileSlick = false;
      // if (extremityWidth < 250) {
      //   extremityWidth = 250;
      //   progressWidth = 160;
      //   useMobileSlick = true;
      // }
      // $('.talent-information-block .manager-message').width(extremityWidth);
      // $('.talent-information-block .progress-block').width(progressWidth);
      // $('.talent-information-block .tasks').width(extremityWidth - 30); // Padding 15 * 2
      // $('.talent-information-block .referents').width(extremityWidth);
      // later(() => {
      //   $('.talent-information-block .manager-message').width(extremityWidth);
      //   $('.talent-information-block .progress-block').width(progressWidth);
      //   $('.talent-information-block .tasks').width(extremityWidth - 30); // Padding 15 * 2
      //   $('.talent-information-block .referents').width(extremityWidth);
      // }, 500);
      // let slickParam = {
      //   infinite: false,
      //   slidesToShow: 3,
      //   variableWidth: true,
      //   dots: false
      // };
      // if (useMobileSlick) {
      //   slickParam = {
      //     infinite: false,
      //     speed: 300,
      //     slidesToShow: 1,
      //     centerMode: true,
      //     variableWidth: true,
      //     dots:true
      //   };
      // }
      // $('.talent-information-block .talent-blocks').slick(slickParam);
      // $('.talent-information-block .talent-blocks').slick('slickGoTo', 1);

    }
  });

  _exports.default = _default;
});