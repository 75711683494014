define("teelt-frontend/templates/board/setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xftiyvLG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"board.settings\"],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"back-settings\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"material-icons left\"],[11,\"style\",\"float:left!important\"],[9],[0,\"chevron_left\"],[10],[1,[27,\"t\",[\"settings.general.back\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/setting.hbs"
    }
  });

  _exports.default = _default;
});