define("teelt-frontend/models/custom-field", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    companyId: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    fieldValue: _emberData.default.attr('string'),
    section: _emberData.default.attr('string'),
    selected: _emberData.default.attr('boolean'),
    task: _emberData.default.attr(),
    codeKey: _emberData.default.attr('string'),
    dataFilter: _emberData.default.attr(),
    required: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});