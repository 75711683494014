define("teelt-frontend/components/task/all-tasks-list", ["exports", "teelt-frontend/config/environment", "teelt-frontend/utils/jquery-utils"], function (_exports, _environment, _jqueryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    notify: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    session: Ember.inject.service(),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    customErrorService: Ember.inject.service('custom-error'),
    fileUploadService: Ember.inject.service('file-upload'),
    classNames: ['all-tasks-list'],
    canLoadMore: true,
    page: 0,
    offset: 0,
    limit: 20,
    allTasks: [],
    query: '',
    showRolesDropdown: false,
    activeRolesFilters: [],
    roles: [],
    filters: {
      all: true,
      ROLE_RH: true,
      ROLE_TALENT: true,
      ROLE_MANAGER: true
    },
    filterLength: 0,
    taskFilters: {
      all: true,
      form: true,
      administrative: true,
      content: true
    },
    taskFiltersLength: 0,
    tasks: Ember.computed('allTasks.length', 'page', 'limit', 'canLoadMore', function () {
      var tasks = this.allTasks.slice(this.page * this.limit, this.page * this.limit + 16);

      if (this.canLoadMore && tasks.length < 16) {
        this.loadMoreTasks();
      }

      return tasks;
    }),
    lastPage: Ember.computed('allTasks.length', 'tasks', 'page', function () {
      return this.page >= this.allTasks.length % 16 || this.tasks.length < 16;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.eventBus.on('ROLELIST_UPDATED', this, 'loadRoles');
      this.loadRoles(true);
      var taskFilters = this.taskFilters;

      if (this.session.data.authenticated.user.company.activeSignContent) {
        taskFilters['sign'] = true;
      }

      if (this.session.data.authenticated.user.company.activeMeetPeople) {
        taskFilters['meetpeople'] = true;
      }

      if (this.session.data.authenticated.user.company.activeExploreOffice) {
        taskFilters['explore_office'] = true;
      }

      taskFilters['none'] = true;
      Ember.set(this, 'taskFilters', taskFilters);
    },
    destroy: function destroy() {
      this.eventBus.off('CLICK_EVENT', this, 'checkToCloseModal');
      this.eventBus.off('ROLELIST_UPDATED', this, 'loadRoles');

      this._super.apply(this, arguments);
    },
    loadRoles: function loadRoles() {
      var init = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var roles = [{
        name: "all",
        label: "talent.roles.all",
        filter: "all",
        value: true
      }, {
        name: "rh",
        filter: "ROLE_RH",
        value: true
      }, {
        name: "manager",
        filter: "ROLE_MANAGER",
        value: true
      }, {
        name: "talent",
        filter: "ROLE_TALENT",
        value: true
      }];

      if (this.session.data.authenticated.user.company.activeMRH) {
        roles.push({
          name: "adminMRH",
          filter: "ROLE_MRH",
          value: true
        });

        if (init) {
          Ember.set(this.filters, 'ROLE_MRH', true);
        }
      }

      if (this.session.data.authenticated.user.company.activeOMRole) {
        roles.push({
          name: "officeManager",
          filter: "ROLE_CUSTOM_OFFICE_MANAGER",
          value: true
        });

        if (init) {
          Ember.set(this.filters, 'ROLE_CUSTOM_OFFICE_MANAGER', true);
        }
      }

      if (this.session.data.authenticated.user.company.activeAdministrative) {
        roles.push({
          name: "administrative",
          filter: "ROLE_ADMINISTRATIVE",
          value: true
        });

        if (init) {
          Ember.set(this.filters, 'ROLE_ADMINISTRATIVE', true);
        }
      }

      if (this.session.data.authenticated.user.company.activeBuddyRole) {
        roles.push({
          name: "buddy",
          filter: "BUDDY",
          value: true
        });

        if (init) {
          Ember.set(this.filters, 'BUDDY', true);
        }
      }

      var consultRoles = this.rolecustomService.getConsultRoles();

      if (consultRoles) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = consultRoles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var role = _step.value;
            roles.push({
              name: "ROLE_CONSULT-" + role.id,
              filter: "ROLE_CONSULT-" + role.id,
              value: true
            });

            if (init) {
              Ember.set(this.filters, "ROLE_CONSULT-" + role.id, true);
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      Ember.set(this, 'roles', roles);
    },
    checkToCloseModal: function checkToCloseModal(e) {
      if (this.isDestroyed) return;

      if (!(0, _jqueryUtils.default)().checkIfClickInElement(e, ["roles", "show-roles-btn", "show-types-btn"])) {
        Ember.set(this, 'showRolesDropdown', false);
        Ember.set(this, 'showtypesDropdown', false);

        if (this.showActions) {
          Ember.set(this, 'showActions', false);
        }
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      setTimeout(function () {
        _this.eventBus.on('CLICK_EVENT', _this, 'checkToCloseModal');
      }, 300);
    },
    loadMoreTasks: function loadMoreTasks() {
      var _this2 = this;

      var path = _environment.default.APP.API_HOST + '/tasks?search=' + this.query + '&offset=' + this.offset + "&limit=" + this.limit;

      if (!this.filters.all) {
        var filters = [];

        for (var f in this.filters) {
          if (f !== 'all' && this.filters[f]) {
            filters.push(f);
          }
        }

        if (filters.length == 0) {
          this.cleanResults();
          return;
        }

        var filtersTmp = filters.join(',');
        path += '&filters=' + encodeURI(filtersTmp);
      }

      if (!this.taskFilters.all) {
        var taskFilters = [];

        for (var _f in this.taskFilters) {
          if (_f !== 'all' && this.taskFilters[_f]) {
            if (_f === 'meetpeople') _f = 'meetPeople';
            if (_f === 'explore_office') _f = 'exploreOffice';
            taskFilters.push(_f);
          }
        }

        if (taskFilters.length == 0) {
          this.cleanResults();
          return;
        }

        var _filtersTmp = taskFilters.join(',');

        path += '&taskFilters=' + encodeURI(_filtersTmp);
      }

      this.ajax.requestPromise(path, 'GET').then(function (tasks) {
        Ember.set(_this2, 'offset', _this2.offset + _this2.limit);
        var currentTasks = _this2.allTasks;

        if (!currentTasks) {
          currentTasks = tasks;
        } else {
          if (tasks && tasks.length > 0) {
            currentTasks = currentTasks.concat(tasks); // Remove duplicates

            currentTasks = currentTasks.filter(function (task, index, self) {
              return index === self.findIndex(function (t) {
                return t.id === task.id;
              });
            });
            Ember.set(_this2, 'allTasks', []);
            Ember.set(_this2, 'allTasks', currentTasks);

            _this2.showCurrentPage();
          } else {
            Ember.set(_this2, "canLoadMore", false);
          }
        }
      });
    },
    cleanResults: function cleanResults() {
      Ember.set(this, 'canLoadMore', true);
      Ember.set(this, 'offset', 0);
      Ember.set(this, 'page', 0);
      Ember.set(this, 'allTasks', []);
    },
    updatePagesLinks: function updatePagesLinks() {
      var nbPages = Math.ceil(this.allTasks.length / 16);
      var minPage = Math.max(this.page - 3, 0);
      var maxPage = Math.min(this.page + 3, nbPages - 1);
      var pages = [];

      for (var i = minPage; i <= maxPage; i++) {
        pages.push(i);
      }

      Ember.set(this, 'pages', pages);
    },
    showCurrentPage: function showCurrentPage() {
      var allDatas = this.allTasks;
      var results = allDatas.slice(this.page * 16, (this.page + 1) * 16);
      Ember.set(this, 'tasks', results);

      if (this.tasks.length < 16) {
        this.loadMoreTasks();
      }

      this.updatePagesLinks();
    },
    openFileCard: function openFileCard(content) {
      var _this3 = this;

      if (!content.fullValue || content.fullValue.trim() == '') return; // WARNING : Always open tab before fetch temporary url because Firefox authorize opened tab by user click only and not in promise resolve

      Ember.set(this, "showDownloadingModal", true);
      this.fileUploadService.getObjectUrl(content.fullValue).then(function (data) {
        var url = data.url;

        if (!content.toDownload) {
          url += "&inline";
        }

        window.open(url, 'newtab');
        Ember.set(_this3, "showDownloadingModal", false);
      }).catch(function (err) {
        _this3.customErrorService.handleAjaxError(err);
      });
    },
    actions: {
      onSearchKeyUp: function onSearchKeyUp() {
        var _this4 = this;

        if (this.keyUpAction) {
          Ember.run.cancel(this.keyUpAction);
        }

        var keyUpAction = Ember.run.later(function () {
          _this4.cleanResults();

          _this4.showCurrentPage();
        }, 300);
        Ember.set(this, 'keyUpAction', keyUpAction);
      },
      changePage: function changePage(newPage) {
        if (newPage < 0 || newPage * 16 >= this.allTasks.length) return;
        Ember.set(this, 'page', newPage);
        this.showCurrentPage();
      },
      openTaskActions: function openTaskActions(taskId) {
        if (this.showActions == taskId) {
          Ember.set(this, 'showActions', false);
        } else {
          Ember.set(this, 'showActions', taskId);
        }
      },
      prepareDeleteTask: function prepareDeleteTask(task) {
        /* if (task.templateNb > 0) return; */
        Ember.set(this, 'taskToDelete', task);
        return;
      },
      removeTask: function removeTask(response) {
        var _this5 = this;

        if (response) {
          var taskId = this.taskToDelete.id;
          var path = _environment.default.APP.API_HOST + '/tasks/' + taskId;
          this.ajax.requestPromise(path, 'DELETE').then(function () {
            var allTasks = _this5.allTasks;

            for (var i = 0; i < allTasks.length; i++) {
              if (allTasks[i].id == taskId) {
                allTasks.splice(i, 1);
                break;
              }
            }

            _this5.cleanResults();

            _this5.loadMoreTasks();
          }).catch(function (e) {
            if (e.responseJSON, e.responseJSON.code && e.responseJSON.code == 'ALREADY_IN_TEMPLATE') {
              _this5.notify.error(_this5.intl.t('task.errors.already_in_template'));
            }
          });
        }
      },
      filter: function filter(_filter) {
        var enable = !this.filters[_filter];

        if (_filter == "all" && this.filters[_filter]) {
          for (var f in this.filters) {
            Ember.set(this.filters, f, false);
          }

          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = this.roles[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var role = _step2.value;
              Ember.set(role, 'value', false);
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }
        }

        if (enable && _filter == 'all') {
          for (var _f2 in this.filters) {
            Ember.set(this.filters, _f2, true);
          }

          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = this.roles[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var _role = _step3.value;
              Ember.set(_role, 'value', true);
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }
        } else {
          Ember.set(this.filters, _filter, enable);
          Ember.set(this.roles.find(function (role) {
            return role.filter == _filter;
          }), 'value', enable);
          var allEnable = true;

          for (var _f3 in this.filters) {
            if (!this.filters[_f3] && _f3 !== 'all') {
              allEnable = false;
              break;
            }
          }

          Ember.set(this.filters, 'all', allEnable);
          Ember.set(this.roles.find(function (role) {
            return role.filter == 'all';
          }), 'value', allEnable);
        }

        var filterLength = 0;

        for (var _f4 in this.filters) {
          if (_f4 !== 'all' && this.filters[_f4]) {
            filterLength++;
          }
        }

        Ember.set(this, 'filterLength', filterLength);
        this.cleanResults(); //this.loadMoreTasks();

        this.showCurrentPage();
      },
      toggleRoleFilter: function toggleRoleFilter(role) {
        var value = role.value ? false : true;
        Ember.set(role, 'value', value);
        this.send('filter', role.filter);
      },
      editTask: function editTask(task) {
        Ember.set(this, 'editTask', task);
        Ember.set(this, 'editFromTaskList', true);
        Ember.set(this, 'showNewTask', true);
        Ember.set(this, 'showActions', undefined);
      },
      translateTask: function translateTask(task) {
        Ember.set(this, 'editTask', task);
        Ember.set(this, 'showTaskTranslation', true);
        Ember.set(this, 'showActions', undefined);
      },
      duplicateTask: function duplicateTask(task) {
        var _this6 = this;

        Ember.set(this, 'showActions', false);
        this.store.findRecord("task", task.id, {
          reload: true
        }).then(function (taskToDuplicate) {
          var taskJSON = taskToDuplicate.toJSON();
          taskJSON['id'] = undefined;

          var prefix = _this6.intl.t("task.duplicate.prefix");

          var newTaskName = '';
          newTaskName = prefix + " " + taskJSON['name'];
          taskJSON['name'] = newTaskName;

          var duplicatedTask = _this6.store.createRecord('task', taskJSON);

          duplicatedTask.save().then(function (savedDuplicatedTask) {
            _this6.store.findRecord('task', savedDuplicatedTask.id).then(function (editduplicatedTask) {
              _this6.send('editTask', editduplicatedTask);

              _this6.cleanResults();

              _this6.loadMoreTasks();
            });
          }).catch(function (err) {
            console.error(err);

            _this6.customErrorService.handleAjaxError(err);
          });
        });
      },
      onFinish: function onFinish() {
        this.cleanResults();
        this.loadMoreTasks();
      },
      previous: function previous() {
        Ember.set(this, 'updateTask', undefined);
      },
      taskFilter: function taskFilter(filter) {
        var enable = !this.taskFilters[filter];

        if (filter == "all" && this.taskFilters[filter]) {
          for (var f in this.taskFilters) {
            Ember.set(this.taskFilters, f, false);
          }
        }

        if (enable && filter == 'all') {
          for (var _f5 in this.taskFilters) {
            Ember.set(this.taskFilters, _f5, true);
          }
        } else {
          Ember.set(this.taskFilters, filter, enable);
          var allEnable = true;

          for (var _f6 in this.taskFilters) {
            if (!this.taskFilters[_f6] && _f6 !== 'all') {
              allEnable = false;
              break;
            }
          }

          Ember.set(this.taskFilters, 'all', allEnable);
        }

        this.cleanResults(); //this.loadMoreTasks();

        this.showCurrentPage();
        var filterLength = 0;

        for (var _f7 in this.taskFilters) {
          if (_f7 !== 'all' && this.taskFilters[_f7]) {
            filterLength++;
          }
        }

        Ember.set(this, 'taskFiltersLength', filterLength);
      },
      onClickOverlay: function onClickOverlay() {
        Ember.set(this, 'showPreviewDialog', false);
      },
      showModule: function showModule(task) {
        var _this7 = this;

        Ember.set(this, "previewType", task.type);

        if (task.form) {
          this.store.findRecord("form", task.form.id, {
            reload: true
          }).then(function (result) {
            Ember.set(_this7, "preview", result);
            Ember.set(_this7, "showPreviewDialog", true);
          });
          return;
        }

        if (task.signContent) {
          Ember.set(this, "preview", task.signContent);
          Ember.set(this, "showPreviewDialog", true);
          return;
        }

        if (task.content) {
          this.store.findRecord("content", task.content.id, {
            reload: true
          }).then(function (result) {
            Ember.set(_this7, "preview", result);

            if (result.type === "textcard" || result.type === "video" || result.type === "slideshow") {
              Ember.set(_this7, "showContentDetailPopup", true);
            } else if (result.type === "filecard") {
              _this7.openFileCard(result);
            }
          });
          return;
        }

        if (task.notification) {
          this.store.findRecord("notification", task.notification.id, {
            reload: true
          }).then(function (result) {
            Ember.set(_this7, "preview", result);
            Ember.set(_this7, "showPreviewDialog", true);
          });
        }

        if (task.meetPeople) {
          Ember.set(this, "preview", task.meetPeople);
          Ember.set(this, "showPreviewDialog", true);
          return;
        }

        if (task.exploreOffice) {
          Ember.set(this, "preview", task.exploreOffice);
          Ember.set(this, "showPreviewDialog", true);
          return;
        }
      },
      showTask: function showTask(task) {
        Ember.set(this, "task", task);
        Ember.set(this, "showTaskPreview", true);
      }
    }
  });

  _exports.default = _default;
});