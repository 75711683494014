define("teelt-frontend/components/utils/banner/meet-people-inactivity-banner", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["top-banner"],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    showBanner: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var routeName = this.router.get('currentRouteName');

      if (routeName.startsWith('talent.integration')) {
        this.ajax.requestPromise(_environment.default.APP.API_HOST + '/meetpeoples/count-meet-people-with-no-images', 'GET').then(function (count) {
          count > 0 ? Ember.set(_this, 'showBanner', true) : Ember.set(_this, 'showBanner', false);
        });
      }
    },
    actions: {
      redirectToIhm: function redirectToIhm() {
        Ember.set(this, 'showBanner', false);
        this.router.transitionTo('talent.integration.ihm', {
          queryParams: {
            openGallery: true
          }
        });
      }
    }
  });

  _exports.default = _default;
});