define("teelt-frontend/routes/board/talents", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    userService: Ember.inject.service('user-service'),
    session: Ember.inject.service(),
    queryParams: {
      search: '',
      complexFilter: '',
      complexFilterKey: ''
    },
    resetController: function resetController(controller) {
      controller.set('search', null);
    },
    model: function model(params) {
      return {
        search: params.search,
        complexFilter: params.complexFilter,
        complexFilterKey: params.complexFilterKey
      };
    },
    afterModel: function afterModel(model) {
      Ember.set(model, 'canCompanyImportCSV', this.session.data.authenticated.user.company.canImportCSV);
      var url = _environment.default.APP.API_HOST + "/users/count";
      return this.ajax.requestWithBodyPromise(url, 'GET').then(function (response) {
        if (response) {
          Ember.set(model, 'countUsers', response);
        }
      });
    },
    actions: {
      openTalent: function openTalent(id) {
        this.transitionTo('board.talent.talent-detail', id);
      },
      newTalent: function newTalent() {
        Ember.set(this.currentModel, 'showAddTalentForm', true);
        Ember.set(this.currentModel, 'openNewTalentOptions', false);
      },
      onCountSelected: function onCountSelected(count) {
        Ember.set(this.currentModel, "selectedCount", count);
      }
    }
  });

  _exports.default = _default;
});