define("teelt-frontend/components/communication/table/communications-custom-table-content", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['notifications-custom-table-content'],
    talentPath: _environment.default.APP.API_HOST + "/users",
    store: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.result.audience) {
        Ember.set(this, 'talentPath', _environment.default.APP.API_HOST + "/users?audienceId=" + this.result.audience.id);
      }
    },
    openCount: Ember.computed('result.sends', function () {
      if (this.result.sends && this.result.sends.length) {
        var lastSend = this.result.sends[0];

        if (lastSend.mails && lastSend.mails.length) {
          var count = 0;
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = lastSend.mails[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var mail = _step.value;
              if (mail.openDate) count++;
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          return count;
        }
      }

      return 0;
    }),
    clickCount: Ember.computed('result.sends', function () {
      if (this.result.sends && this.result.sends.length) {
        var lastSend = this.result.sends[0];

        if (lastSend.mails && lastSend.mails.length) {
          var count = 0;
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = lastSend.mails[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var mail = _step2.value;
              if (mail.clickDate) count++;
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          return count;
        }
      }

      return 0;
    }),
    sentDate: Ember.computed('result.sends', function () {
      if (this.result.sends && this.result.sends.length) {
        var lastSend = this.result.sends[0];
        return lastSend.createdAt;
      }
    }),
    canTest: Ember.computed('testRequest.{talentTestId,phoneNumber,email}', function () {
      if (!this.testRequest) return false;
      var canTest = this.testRequest.talentTestId != undefined;
      if (!canTest) return false;
      if (this.testRequest.email) return true;
      if (this.testRequest.phoneNumber) return true;
      return false;
    }),
    actions: {
      prepareToSend: function prepareToSend() {
        Ember.set(this, 'toSend', this.result);
        Ember.set(this, 'showActions', false);
      },
      send: function send(response) {
        var _this = this;

        if (!response) return;
        Ember.set(this, 'showActions', false);
        var path = _environment.default.APP.API_HOST + '/communications/' + this.result.id + '/send';
        this.ajax.requestPromise(path, 'POST').then(function () {
          _this.notify.info(_this.intl.t('communication.send_ok'));
        }).catch(function (err) {
          _this.notify.error(_this.intl.t('communication.send_ko'), {
            closeAfter: 5000
          });

          console.error(err);
        });
      },
      schedule: function schedule() {
        Ember.set(this, 'showActions', false);
        this.router.transitionTo('board.communication.communication-planning', this.result);
      },
      prepareToDelete: function prepareToDelete() {
        Ember.set(this, 'toDelete', this.result);
        Ember.set(this, 'showActions', false);
      },
      prepareRename: function prepareRename() {
        Ember.set(this, 'nameToEdit', this.result.name);
        Ember.set(this, 'showNameEditModal', true);
        Ember.set(this, 'showActions', false);
      },
      rename: function rename() {
        Ember.set(this.result, 'name', this.nameToEdit);
        this.result.save();
        Ember.set(this, 'showNameEditModal', false);
      },
      edit: function edit() {
        Ember.set(this, 'showActions', false);
        this.router.transitionTo('board.communication.communication-edit', this.result);
      },
      delete: function _delete(response) {
        var _this2 = this;

        if (!response) return;
        this.result.deleteRecord();
        this.result.save().then(function () {
          _this2.eventBus.trigger('REFRESH_COMMUNICATIONS');
        }).catch(function (e) {
          _this2.notify.error(_this2.intl.t('communication.delete_fail'), {
            closeAfter: 5000
          });
        });
      },
      prepareTest: function prepareTest() {
        if (!this.result.notification) return;
        Ember.set(this, 'testRequest', {});
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = this.result.notification.contents[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var content = _step3.value;

            if (content.contentType == 'SMS') {
              Ember.set(this.testRequest, 'phoneCode', '+33');
            }
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }

        Ember.set(this, 'showTestModal', true);
        Ember.set(this, 'showActions', false);
      },
      onTalentTestChanged: function onTalentTestChanged(talent) {
        if (talent) {
          Ember.set(this.testRequest, 'talentTestId', talent.key);
        } else {
          Ember.set(this.testRequest, 'talentTestId', undefined);
        }
      },
      sendTest: function sendTest() {
        var _this3 = this;

        Ember.set(this, 'showTestModal', false);
        var path = _environment.default.APP.API_HOST + "/communications/" + this.result.id + "/sendtest";
        this.ajax.requestWithBodyPromise(path, 'POST', 'application/json', JSON.stringify(this.testRequest)).then(function () {
          _this3.notify.info(_this3.intl.t('communication.sendtest_ok'));
        }).catch(function (err) {
          _this3.notify.error(_this3.intl.t('communication.sendtest_ko'), {
            closeAfter: 5000
          });

          console.error(err);
        });
      }
    }
  });

  _exports.default = _default;
});