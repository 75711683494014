define("teelt-frontend/components/ihm/meet-people/meet-people-participants-list", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['meet-people-participants-list'],
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    session: Ember.inject.service(),
    userService: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.ajax.requestPromise(_environment.default.APP.API_HOST + '/meetpeoples/' + this.meetPeople.id + '/response/' + this.talentId, 'GET').then(function (res) {
        Ember.set(_this.meetPeople, 'participants', res.participants);
        Ember.set(_this, 'showParticipantsModal', true);
        Ember.set(_this, 'showActions', false);
      });
    },
    actions: {
      showGallery: function showGallery() {
        if (this.onShowGallery) {
          this.showAllGallery ? this.onShowGallery(this.meetPeople, true) : this.onShowGallery(this.meetPeople, true);
        }
      }
    }
  });

  _exports.default = _default;
});