define("teelt-frontend/routes/board/contents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    getSelectedMenuFromTransition: function getSelectedMenuFromTransition(transition) {
      var selectedMenu = 0;

      if (transition && transition.targetName === "board.contents.home") {
        selectedMenu = 1;
      }

      if (transition && transition.targetName === "board.contents.tags") {
        selectedMenu = 2;
      }

      return selectedMenu;
    },
    model: function model(params, transition) {
      var selectedMenu = this.getSelectedMenuFromTransition(transition);
      var sliderMenu = [{
        name: this.intl.t('menu.content_list'),
        route: "board.contents.contents-list"
      }];

      if (this.session.data.authenticated.user.company.canCreateHome) {
        sliderMenu.push({
          name: this.intl.t('menu.home'),
          route: "board.contents.home"
        });
      }

      if (this.session.data.authenticated.user.company.tagMode) {
        sliderMenu.push({
          name: this.intl.t('menu.tags'),
          route: "board.contents.tags"
        });
      }

      return {
        selectedMenu: selectedMenu,
        sliderMenu: sliderMenu
      };
    },
    actions: {
      willTransition: function willTransition(transition) {
        var selectedMenu = this.getSelectedMenuFromTransition(transition);
        Ember.set(this.currentModel, "selectedMenu", selectedMenu);
      }
    }
  });

  _exports.default = _default;
});