define("teelt-frontend/components/settings/settings-items", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend((_EmberComponent$exte = {
    classNames: ['settings-items'],
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service()
  }, _defineProperty(_EmberComponent$exte, "session", Ember.inject.service()), _defineProperty(_EmberComponent$exte, "allowSftpStorage", false), _defineProperty(_EmberComponent$exte, "init", function init() {
    var _this = this;

    this._super.apply(this, arguments);

    Ember.set(this, "companyId", this.session.data.authenticated.user.companyId);
    this.ajax.requestPromise(_environment.default.APP.API_HOST + '/sftps', 'GET').then(function (sftp) {
      if (!sftp || !_this.session.data.authenticated.user.company.activeSftp) {
        Ember.set(_this, 'allowSftpStorage', false);
      } else {
        Ember.set(_this, 'allowSftpStorage', true);
      }
    });
  }), _defineProperty(_EmberComponent$exte, "actions", {
    open: function open(page) {
      this.router.transitionTo('board.setting.' + page);
    }
  }), _EmberComponent$exte));

  _exports.default = _default;
});