define("teelt-frontend/components/ai/new-module-by-ai/new-module-by-ai-form", ["exports", "teelt-frontend/config/environment", "teelt-frontend/components/ai/new-module-by-ai"], function (_exports, _environment, _newModuleByAi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _newModuleByAi.default.extend({
    classNames: ['new-module-by-ai-form'],
    moduleType: 'form',
    intl: Ember.inject.service(),
    initalized: false,
    onShowAIChanged: Ember.observer('showAI', function () {
      var _this = this;

      if (this.showAI && !this.initalized) {
        setTimeout(function () {
          _this.createIAMessage(_this.intl.t('ai.module.form.init'));
        });
        Ember.set(this, 'initalized', true);
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});