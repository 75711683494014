define("teelt-frontend/components/ihm/meet-people/meet-people-blocks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['meet-people-blocks'],
    userService: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.talent) {
        var periods = this.userService.getTalentPeriods(this.talent);
        Ember.set(this, 'periods', periods);
        Ember.set(this, 'talentCurrentPeriod', this.userService.getTalentCurrentPeriod(this.talent, periods));
        Ember.set(this, 'currentPeriod', periods[this.talentCurrentPeriod]);
      }

      this.eventBus.on('OPEN_MEEPEOPLE_GALLERY', this, 'showGallery');
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.forceOpenGallery && this.forceOpenGallery == 'true') {
        Ember.set(this, 'forceOpenGallery', false);
        this.showGallery();
      }
    },
    destroy: function destroy() {
      this.eventBus.off('OPEN_MEEPEOPLE_GALLERY', this, 'showGallery');

      this._super.apply(this, arguments);
    },
    showGallery: function showGallery() {
      Ember.set(this, 'showModal', true);
    },
    actions: {
      toggleCurrentPeriod: function toggleCurrentPeriod(period) {
        Ember.set(this, 'currentPeriod', period);
      },
      onClickOverlay: function onClickOverlay() {}
    }
  });

  _exports.default = _default;
});