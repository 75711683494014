define("teelt-frontend/helpers/make-array-from-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeArrayFromTo = makeArrayFromTo;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function makeArrayFromTo(_ref
  /*, hash*/
  ) {
    var _ref2 = _slicedToArray(_ref, 2),
        from = _ref2[0],
        to = _ref2[1];

    if (from == undefined || to == undefined || from > to) return [];
    var array = [];

    for (var i = from; i <= to; i++) {
      array.pushObject(i);
    }

    return array;
  }

  var _default = Ember.Helper.helper(makeArrayFromTo);

  _exports.default = _default;
});