define("teelt-frontend/templates/talent/integration/ihm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "npLMcms9",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"ihm/ihm-list-talent-integration\",null,[[\"talent\",\"forceMeetPeopleId\",\"forceOpenGallery\"],[[23,[\"model\",\"talent\"]],[23,[\"model\",\"meetPeopleId\"]],[23,[\"openGallery\"]]]]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/talent/integration/ihm.hbs"
    }
  });

  _exports.default = _default;
});