define("teelt-frontend/routes/board/module/module-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    model: function model(params) {
      return {
        step: 1,
        type: 'form'
      };
    },
    specificActionByStep: function specificActionByStep() {
      if (this.currentModel.step <= 0) {
        this.transitionTo('board.modules');
      } else if (this.currentModel.step == 1) {
        this.refresh();
      } else if (this.currentModel.step > 1) {
        this.createModule();
      }
    },
    createModule: function createModule() {
      switch (this.currentModel.type) {
        case "form":
        case "administrative":
          var form = this.store.createRecord('form', {
            type: this.currentModel.type == 'administrative' ? 'ADMINISTRATIVE' : 'FEEDBACK',
            maxResponseNumber: 1,
            showToManager: false,
            task: {
              period: 'BEFORE',
              assign: 'talent'
            },
            fields: [{
              type: "short_text",
              properties: {},
              required: false,
              inputErrors: {}
            }]
          });
          Ember.set(this.currentModel, 'updateModule', form);
          break;

        case "notification":
          var notification = this.store.createRecord('notification', {
            type: 'CUSTOM',
            recipient: 'TALENT',
            triggerInitiator: 'action_start',
            contents: [{
              contentType: "EMAIL",
              officeId: null,
              tagId: null,
              lang: this.selectedLang,
              disabled: true,
              subject: "",
              body: "",
              replyTo: ""
            }, {
              contentType: "SMS",
              officeId: null,
              tagId: null,
              lang: this.selectedLang,
              disabled: true,
              subject: "",
              body: "",
              replyTo: ""
            }],
            schedules: [{}],
            associatedRole: null
          });
          Ember.set(this.currentModel, 'updateModule', notification);
          break;

        case "content":
          var content = this.store.createRecord('content', {
            category: {},
            type: 'textcard',
            section: 'generic',
            template: {
              id: this.templateId
            },
            offices: [],
            toDownload: false,
            isVisible: true
          });
          Ember.set(this.currentModel, 'updateModule', content);
          break;

        case "tool":
          var tool = this.store.createRecord('tool');
          Ember.set(this.currentModel, 'updateModule', tool);
          break;

        case "sign":
          var sign = this.store.createRecord('signcontent', {
            category: {}
          });
          Ember.set(this.currentModel, 'updateModule', sign);
          break;

        case "ihm":
          var ihm = this.store.createRecord('ihm', {
            type: 'ihm'
          });
          Ember.set(this.currentModel, 'updateModule', ihm);

          if (this.session.data.authenticated.user.company.activeMeetPeople) {
            var meetpeople = this.store.createRecord('meetpeople', {
              type: 'meet_people'
            });
            Ember.set(this.currentModel, 'meetpeople', meetpeople);
          }

          if (this.session.data.authenticated.user.company.activeExploreOffice) {
            var exploreoffice = this.store.createRecord('exploreoffice', {
              type: 'explore_office',
              fields: [{
                type: 'none',
                inputErrors: {}
              }]
            });
            Ember.set(this.currentModel, 'exploreoffice', exploreoffice);
          }

          break;
      }
    },
    actions: {
      next: function next() {
        Ember.set(this.currentModel, 'step', this.currentModel.step + 1);
        this.specificActionByStep();
      },
      previous: function previous() {
        Ember.set(this.currentModel, 'step', this.currentModel.step - 1);
        this.specificActionByStep();
      },
      onFinish: function onFinish() {
        this.transitionTo('board.modules');
      }
    }
  });

  _exports.default = _default;
});