define("teelt-frontend/components/utils/searchable-insertable-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['searchable-insertable-select'],
    search: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.filterValues();
    },
    filterValues: function filterValues() {
      var _this = this;

      if (this.search.length == 0) {
        Ember.set(this, 'filteredValues', this.values.slice(0, 5));
      } else {
        Ember.set(this, 'filteredValues', this.values.filter(function (value) {
          return value.toLowerCase().indexOf(_this.search.toLowerCase()) >= 0;
        }));
      }
    },
    actions: {
      onSearchChange: function onSearchChange() {
        this.filterValues();
      },
      addNewValue: function addNewValue(value) {
        if (!this.values.includes(value)) {
          this.values.push(value);
          this.filterValues();
        }
      },
      select: function select(value) {
        Ember.set(this, 'selected', value);
        this.$('.select-dropdown').val(value);
        Ember.set(this, 'showList', false);

        if (this.onValueChange) {
          this.onValueChange(value);
        }
      }
    }
  });

  _exports.default = _default;
});