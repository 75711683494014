define("teelt-frontend/components/communication/communication-result", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['communication-result'],
    sendIndex: 0,
    showResult: true,
    currentSendId: Ember.computed('sendIndex', 'campaign.sends', function () {
      if (this.campaign && this.campaign.sends && this.campaign.sends.length > this.sendIndex) {
        return this.campaign.sends[this.sendIndex].id;
      }
    }),
    actions: {
      previous: function previous() {
        var _this = this;

        Ember.set(this, 'showResult', false);
        Ember.set(this, 'sendIndex', this.sendIndex + 1);
        setTimeout(function () {
          Ember.set(_this, 'showResult', true);
        });
      },
      next: function next() {
        var _this2 = this;

        Ember.set(this, 'showResult', false);
        Ember.set(this, 'sendIndex', this.sendIndex - 1);
        setTimeout(function () {
          Ember.set(_this2, 'showResult', true);
        });
      }
    }
  });

  _exports.default = _default;
});