define("teelt-frontend/components/dashboard/missing-data", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['missing-data'],
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    defaultProfileImageUrl: _environment.default.APP.DEFAULT_PROFILE_IMAGE_URL,
    selectedKey: {
      key: 'missingData',
      operator: 'IN',
      type: 'select',
      value1: 'managerIdRequired'
    },
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var selectValues = [];
      this.ajax.requestPromise(_environment.default.APP.API_HOST + '/companies/required-user-fields', 'GET').then(function (response) {
        if (response.firstnameRequired) {
          selectValues.push({
            key: 'firstnameRequired',
            label: _this.intl.t('talent.table.keys.firstname')
          });
        }

        if (response.lastnameRequired) {
          selectValues.push({
            key: 'lastnameRequired',
            label: _this.intl.t('talent.table.keys.lastname')
          });
        }

        if (response.persoEmailRequired) {
          selectValues.push({
            key: 'persoEmailRequired',
            label: _this.intl.t('talent.perso_email_lite')
          });
        }

        if (response.proEmailRequired) {
          selectValues.push({
            key: 'proEmailRequired',
            label: _this.intl.t('talent.pro_email_lite')
          });
        }

        if (response.phoneNumberRequired) {
          selectValues.push({
            key: 'phoneNumberRequired',
            label: _this.intl.t('talent.table.keys.phoneNumber')
          });
        }

        if (response.teamIdRequired) {
          selectValues.push({
            key: 'teamIdRequired',
            label: _this.intl.t('talent.table.keys.team')
          });
        }

        if (response.positionIdRequired) {
          selectValues.push({
            key: 'positionIdRequired',
            label: _this.intl.t('talent.position_job')
          });
        }

        if (response.contractIdRequired) {
          selectValues.push({
            key: 'contractIdRequired',
            label: _this.intl.t('talent.contract')
          });
        }

        if (response.welcomeDateRequired) {
          selectValues.push({
            key: 'welcomeDateRequired',
            label: _this.intl.t('talent.table.keys.welcomeDate')
          });
        }

        if (response.endDateRequired) {
          selectValues.push({
            key: 'endDateRequired',
            label: _this.intl.t('talent.table.keys.endDate')
          });
        }

        if (response.migrationDateRequired) {
          selectValues.push({
            key: 'migrationDateRequired',
            label: _this.intl.t('talent.table.keys.migrationDate')
          });
        }

        if (response.trialPeriodEndDateRequired) {
          selectValues.push({
            key: 'trialPeriodEndDateRequired',
            label: _this.intl.t('talent.table.keys.trialPeriodEndDate')
          });
        }

        if (response.managerIdRequired) {
          selectValues.push({
            key: 'managerIdRequired',
            label: _this.intl.t('talent.table.keys.manager')
          });
          Ember.set(_this.selectedKey, 'label', _this.intl.t('talent.table.keys.manager'));
        } else {
          Ember.set(_this.selectedKey, 'label', _this.intl.t('talent.table.keys.firstname'));
          Ember.set(_this.selectedKey, 'value1', 'firstnameRequired');
        }

        if (response.templateIdRequired) {
          selectValues.push({
            key: 'templateIdRequired',
            label: _this.intl.t('talent.table.keys.template')
          });
        }

        if (response.campaignIdRequired) {
          selectValues.push({
            key: 'campaignIdRequired',
            label: _this.intl.t('talent.table.keys.campaign')
          });
        }

        if (response.officeManagerIdsRequired) {
          selectValues.push({
            key: 'officeManagerIdsRequired',
            label: _this.intl.t('talent.table.keys.officeManager')
          });
        }

        if (response.buddyIdRequired) {
          selectValues.push({
            key: 'buddyIdRequired',
            label: _this.intl.t('talent.table.keys.buddy')
          });
        }
      });
      var roleCustomPath = _environment.default.APP.API_HOST + "/roles?consultRoles=true&required=true";
      this.ajax.requestPromise(roleCustomPath, 'GET').then(function (result) {
        if (result) {
          result.forEach(function (role) {
            selectValues.push({
              key: role.roleType + '-Required',
              label: role.label
            });
          });
        }

        _this.ajax.requestPromise(_environment.default.APP.API_HOST + '/custom-fields?required=true', 'GET').then(function (customFields) {
          if (customFields) {
            customFields.forEach(function (customField) {
              selectValues.push({
                key: 'CF-' + customField.id + '-Required',
                label: customField.name
              });
            });
          }

          setTimeout(function () {
            Ember.set(_this, "selectValues", selectValues);

            _this.showUsersWithMissingData();
          }, 100);
        });
      });
    },
    showUsersWithMissingData: function showUsersWithMissingData() {
      var _this2 = this;

      var params = {
        complexFilters: JSON.stringify([{
          key: this.selectedKey.key,
          type: this.selectedKey.type,
          operator: this.selectedKey.operator,
          value1: this.selectedKey.value1
        }]),
        selectAll: true
      };
      Ember.set(this, 'loading', true);
      this.ajax.requestWithUrlParamPromise(_environment.default.APP.API_HOST + '/users/dashboard-missing-data', 'GET', params).then(function (result) {
        Ember.set(_this2, 'datas', result);
        Ember.set(_this2, 'loading', false);

        if (_this2.onFilterChange) {
          _this2.onMissingFilterChange(_this2.selectedKey);
        } //let key = this.selectedKey.value1.replace(/-?Required$/, '');
        //this.countMissingDataForUsers(result, [key]);

      }).catch(function (error) {
        Ember.set(_this2, 'loading', false);
      });
    },
    countMissingDataForUsers: function countMissingDataForUsers(datas, keys) {
      var count = 0;
      datas.forEach(function (data) {
        keys.forEach(function (key) {
          if (!data[key]) {
            count++;
          }
        });
      });
      Ember.set(this, 'missingDataCount', count);
    },
    actions: {
      onSelectChanged: function onSelectChanged(value) {
        Ember.set(this.selectedKey, 'value1', value.key);
        Ember.set(this.selectedKey, 'label', value.label);
        this.showUsersWithMissingData();
      },
      gotoList: function gotoList() {
        this.router.transitionTo('board.talents', {
          queryParams: {
            complexFilter: 'missingData',
            complexFilterKey: this.selectedKey.value1
          }
        }).then(function () {
          window.location.reload();
        });
      }
    }
  });

  _exports.default = _default;
});