define("teelt-frontend/services/storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'data', {});
    },
    store: function store(key, value) {
      var persist = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

      if (!Ember.isNone(key)) {
        if (persist) {
          localStorage.setItem(key, value);
        } else {
          this.get('data')[key] = value;
        }
      }
    },
    find: function find(key) {
      var persist = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (!Ember.isNone(key)) {
        if (persist) {
          return localStorage.getItem(key);
        } else {
          return this.get('data')[key];
        }
      }
    },
    remove: function remove(key) {
      var persist = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (!Ember.isNone(key)) {
        if (persist) {
          localStorage.removeItem(key);
        } else {
          delete this.get('data')[key];
        }
      }
    },
    storeJson: function storeJson(key, value) {
      var persist = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

      if (!Ember.isNone(key)) {
        if (persist) {
          localStorage.setItem(key, JSON.stringify(value));
        } else {
          this.get('data')[key] = value;
        }
      }
    },
    findJson: function findJson(key) {
      var persist = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (!Ember.isNone(key)) {
        if (persist) {
          var result = localStorage.getItem(key);

          if (result) {
            return JSON.parse(localStorage.getItem(key));
          }

          return undefined;
        } else {
          return this.get('data')[key];
        }
      }
    }
  });

  _exports.default = _default;
});