define("teelt-frontend/router", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('downloading');
    this.route('login', function () {
      this.route('forgot-password');
      this.route('form');
      this.route('recover-password');
    });
    this.route('form-print', {
      path: '/:form_id/print/:talent_id'
    });
    this.route('board', function () {
      this.route('talents');
      this.route('experiences');
      this.route('experience', function () {
        this.route('experience-models', {
          path: '/models'
        });
        this.route('experience-new', {
          path: '/new'
        });
        this.route('experience-new-model', {
          path: '/new-model'
        });
        this.route('experience-edit', {
          path: '/:template_id'
        });
      });
      this.route('modules');
      this.route('module', function () {
        this.route('module-new', {
          path: '/new'
        });
      });
      this.route('audiences');
      this.route('audience', function () {
        this.route('audience-new', {
          path: '/new'
        });
        this.route('audience-edit', {
          path: '/:audience_id/edit'
        });
      });
      this.route('company', function () {
        this.route('company-new', {
          path: '/new'
        });
        this.route('company-detail', {
          path: '/:company_id'
        });
        this.route('company-edit', {
          path: '/:company_id/edit'
        });
      });
      this.route('talent', function () {
        this.route('talent-detail', {
          path: '/:talent_id'
        }, function () {
          this.route('activities');
          this.route('actions');
          this.route('my-actions');
          this.route('forms');
          this.route('administrative');
          this.route('calendar');
          this.route('ihm');
        });
        this.route('talent-by-period');
        this.route('talent-edit', {
          path: '/:user_id/edit'
        });
        this.route('talent-import');
      });
      this.route('tasks');
      this.route('task', function () {
        this.route('task-new', {
          path: '/new'
        });
        this.route('task-edit', {
          path: "/:task_id/edit"
        });
        this.route('my-tasks');
      });
      this.route('admin', function () {
        this.route('company');
        this.route('tool');
        this.route('custom-field');
      });
      this.route('events');
      this.route('event', function () {
        this.route('campaign-new', {
          path: '/new'
        });
        this.route('campaign-edit', {
          path: "/:campaign_id/edit"
        });
        this.route('campaign-detail', {
          path: "/:campaign_id"
        });
        this.route('campaign-assign', {
          path: "/:campaign_id/assign"
        });
      });
      this.route('custom-field', function () {
        this.route('custom-field-new', {
          path: '/new'
        });
        this.route('custom-field-edit', {
          path: "/:field_id/edit"
        });
      });
      this.route('contents', function () {
        this.route('home');
        this.route('contents-list');
        this.route('tags');
      });
      this.route('emails', function () {
        this.route('notifications-list');
        this.route('notifications-system-list');
      });
      this.route('email', function () {
        this.route('email-new');
        this.route('email-detail', {
          path: "/:email_id"
        });
        this.route('email-edit', {
          path: "/:email_id/edit"
        });
        this.route('email-rule-new');
        this.route('email-rule-detail', {
          path: "/rules/:rule_id"
        });
        this.route('email-rule-edit', {
          path: "/rules/:rule_id/edit"
        });
      });
      this.route('dashboard');
      this.route('analytics');
      this.route('analytic', function () {
        this.route('form-analytic-detail', {
          path: '/:form_report_id/detail'
        });
        this.route('form-analytic-new', {
          path: '/new'
        });
        this.route('form-analytic-edit', {
          path: '/:form_report_id/edit'
        });
      });
      this.route('notification', function () {
        this.route('notification-detail', {
          path: "/:notification_id"
        });
        this.route('notification-new');
        this.route('notification-edit', {
          path: "/:notification_id/edit"
        });
        this.route('send-notification', {
          path: "/send/:talent_id"
        });
        this.route('notification-template-assign', {
          path: '/:notification_id/assign'
        });
      });
      this.route('form', function () {
        this.route('form-new');
        this.route('form-detail', {
          path: "/:form_id"
        });
        this.route('form-edit', {
          path: "/:form_id/edit"
        });
        this.route('form-results', {
          path: "/:form_id/results"
        });
        this.route('satisfaction-rate-forms');
      });
      this.route('admin-form', function () {
        this.route('form-new');
        this.route('form-detail', {
          path: "/:form_id"
        });
        this.route('form-edit', {
          path: "/:form_id/edit"
        });
      });
      this.route('administrative', function () {
        this.route('administrative-new');
        this.route('administrative-detail', {
          path: "/:administrative_id"
        });
        this.route('administrative-edit', {
          path: "/:administrative_id/edit"
        });
      });
      this.route('settings', function () {});
      this.route('setting', function () {
        this.route('user-management');
        this.route('talent-data');
        this.route('privacy-security');
        this.route('general');
        this.route('notifications');
        this.route('design');
        this.route('experience');
        this.route('periods', {
          path: "/periods/:type"
        });
        this.route('period-new', {
          path: "/period-new/:type"
        });
        this.route('period-edit', {
          path: "/period/:period_id"
        });
        this.route('homepage-default');
        this.route('perimeter-user-management');
        this.route('perimeter');
        this.route('roles');
        this.route('role-edit', {
          path: "/role/:id"
        });
        this.route('integrations');
        this.route('integration', function () {
          this.route('lucca');
          this.route('slack');
          this.route('talentsoft');
          this.route('agrume');
          this.route('flatchr');
        });
        this.route('schedule-sftp-export');
      });
      this.route('myaccount');
      this.route('communications');
      this.route('communication', function () {
        this.route('communication-new', {
          path: '/new'
        });
        this.route('communication-edit', {
          path: '/:communication_id'
        });
        this.route('communication-planning', {
          path: '/:communication_id/planning'
        });
        this.route('communication-result', {
          path: '/:communication_id/result'
        });
      });
    });
    this.route('tool', function () {});
    this.route('talent', function () {
      this.route('home');
      this.route('integration', function () {
        this.route('events');
        this.route('tasks');
        this.route('form', {
          path: "/forms/:form_id"
        });
        this.route('tools');
        this.route('resources');
        this.route('forms');
        this.route('home');
        this.route('administratives');
        this.route('ihm');
        this.route('team', function () {
          this.route('my-team');
          this.route('members-list');
          this.route('same-month');
          this.route('same-template');
        });
      });
      this.route('profile', function () {
        this.route('detail');
        this.route('documents');
      });
      this.route('team', function () {
        this.route('members-list');
        this.route('my-team');
      });
    });
    this.route('template', function () {});
    this.route('page-not-found', {
      path: '/*wildcard'
    });
    this.route('parent', function () {
      this.route('confirm-before-transition');
    });
  });
  Router.reopen({});
  var _default = Router;
  _exports.default = _default;
});