define("teelt-frontend/components/utils/confirmation-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      close: function close() {
        Ember.set(this, "show", false);
      },
      yes: function yes() {
        this.response(true);
        this.send('close');
      },
      no: function no() {
        this.response(false);
        this.send('close');
      }
    }
  });

  _exports.default = _default;
});