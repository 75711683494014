define("teelt-frontend/templates/components/utils/keyword-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vCn5TAVo",
    "block": "{\"symbols\":[\"keyword\",\"index\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"keyword-input clickable\"],[3,\"action\",[[22,0,[]],\"focusOnInput\"]],[9],[0,\"\\n    \"],[7,\"li\"],[11,\"class\",\"keyword-new\"],[12,\"id\",[28,[\"keywords-new-\",[21,\"name\"]]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"keywords\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\"],[12,\"class\",[28,[\"keywords keywords-\",[21,\"name\"]]]],[9],[7,\"span\"],[3,\"action\",[[22,0,[]],\"click\",[22,2,[]]]],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n                \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[3,\"action\",[[22,0,[]],\"deleteKeyword\",[22,2,[]]]],[9],[0,\"clear\"],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"        \"],[1,[27,\"input\",null,[[\"autocomplete\",\"value\",\"type\",\"class\",\"key-up\"],[\"off\",[23,[\"tempValue\"]],\"text\",\"browser-default\",[27,\"action\",[[22,0,[]],\"onKeyUp\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/keyword-input.hbs"
    }
  });

  _exports.default = _default;
});