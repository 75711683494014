define("teelt-frontend/services/custom-labels-service", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    customLabels: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.loadCustomLabels();
      this.eventBus.on('REFRESH_CUSTOM_LABELS', this, 'loadCustomLabels');
      this.eventBus.on('AUTHENTICATION_CHANGED', this, 'loadCustomLabels');
      var interval = setInterval(function () {
        _this.loadCustomLabels();
      }, 15 * 60 * 1000);
      Ember.set(this, 'interval', interval);
    },
    talentLabel: Ember.computed('customLabels', function () {
      var customLabels = this.customLabels;

      if (customLabels && customLabels['talentLabel']) {
        return customLabels['talentLabel'];
      } else {
        return this.intl.t('word.talents');
      }
    }),
    destroy: function destroy() {
      this.eventBus.off('REFRESH_CUSTOM_LABELS', this, 'loadCustomLabels');
      this.eventBus.off('AUTHENTICATION_CHANGED', this, 'loadCustomLabels');

      if (this.interval) {
        clearInterval(this.interval);
      }

      this._super.apply(this, arguments);
    },
    loadCustomLabels: function loadCustomLabels() {
      var _this2 = this;

      if (this.session.isAuthenticated) {
        var path = _environment.default.APP.API_HOST + '/customlabels';
        this.ajax.requestPromise(path, 'GET').then(function (customLabels) {
          Ember.set(_this2, 'customLabels', customLabels);
        });
      }
    }
  });

  _exports.default = _default;
});