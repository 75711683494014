define("teelt-frontend/services/google-calendar", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    isConnected: function isConnected() {
      var url = _environment.default.APP.API_HOST + "/googleapis/calendar/connected";
      return this.ajax.requestPromise(url, "GET");
    }
  });

  _exports.default = _default;
});