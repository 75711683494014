define("teelt-frontend/components/custom-field/field/custom-autocomplete", ["exports", "teelt-frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajaxService: Ember.inject.service('ajax'),
    customErrorService: Ember.inject.service('custom-error'),
    uiUtils: Ember.inject.service('ui-utils'),
    ajax: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.set(this, 'searchPath', _environment.default.APP.API_HOST + '/custom-fields/' + this.field.id + '/data');

      if (this.selectedValue && this.field.type === 'autocomplete_users') {
        Ember.set(this, 'waitingTalent', true);
        var url = _environment.default.APP.API_HOST + "/users/" + this.selectedValue + "/fromCustomLink/" + this.field.codeKey;
        this.ajaxService.requestPromise(url, 'GET').then(function (talent) {
          Ember.set(_this, 'talent', talent);
          Ember.set(_this, 'waitingTalent', false);
        });
      }
    },
    actions: {
      onSelectChanged: function onSelectChanged(value) {
        Ember.set(this, 'selectedValue', value.key);
        Ember.set(this, 'selectedLabel', value.label);
      }
    }
  });

  _exports.default = _default;
});