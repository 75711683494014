define("teelt-frontend/components/content/contents-list", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['contents-list'],
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    customErrorService: Ember.inject.service('custom-error'),
    fileUploadService: Ember.inject.service('file-upload'),
    contentService: Ember.inject.service(),
    session: Ember.inject.service(),
    haveToDownload: Ember.computed('contents', function () {
      if (!this.contents) return false;
      var have = false;
      this.contents.forEach(function (content) {
        have = have || content.toDownload;
      });
      return have;
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.loadContents();
      Ember.set(this, 'selected', ["0"]);

      if (this.session.data.authenticated.user.company.essential) {
        setTimeout(function () {
          var addContentCard = _this.$('.add-content-card');

          if (addContentCard) {
            addContentCard.hide();
          }
        }, 0);
      }
    },
    clearDrake: function clearDrake() {
      if (this.drake) {
        this.drake.destroy();
        Ember.set(this, 'drake', undefined);
      }
    },
    initDrake: function initDrake() {
      var _this2 = this;

      setTimeout(function () {
        if (_this2.canMove) {
          _this2.clearDrake();

          var self = _this2;

          var containersQuery = _this2.$(".contents-movable");

          var containers = [];
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = containersQuery[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var container = _step.value;
              containers.push(container);
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          var tmp = _this2.$('.categories-contents-movable');

          if (tmp && tmp.length) {
            containers.push(tmp[0]);
          }

          var drake = dragula({
            containers: containers,
            revertOnSpill: false,
            accepts: function accepts(el, target, source, sibling) {
              // accept drags only if the drop target is the same
              // as the source container the element came from
              return target == source;
            }
          });
          drake.on('drop', function (el, container, source, sibling) {
            if (container.classList.contains('categories-contents-movable')) {
              var orders = [];
              var _iteratorNormalCompletion2 = true;
              var _didIteratorError2 = false;
              var _iteratorError2 = undefined;

              try {
                for (var _iterator2 = container.childNodes[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                  var child = _step2.value;

                  if (child.getAttribute && child.getAttribute('data-index')) {
                    var index = child.getAttribute("data-index");
                    orders.push(self.categoriesComplete[index].id);
                  }
                }
              } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                    _iterator2.return();
                  }
                } finally {
                  if (_didIteratorError2) {
                    throw _iteratorError2;
                  }
                }
              }

              if (orders.length > 1) {
                var path = _environment.default.APP.API_HOST + '/contents/categories/orders';
                self.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(orders));
              }
            } else {
              var _orders = [];
              var _iteratorNormalCompletion3 = true;
              var _didIteratorError3 = false;
              var _iteratorError3 = undefined;

              try {
                for (var _iterator3 = container.childNodes[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                  var _child = _step3.value;

                  if (_child.getAttribute && _child.getAttribute("data-id")) {
                    var id = _child.getAttribute("data-id");

                    _orders.push(id);
                  }
                }
              } catch (err) {
                _didIteratorError3 = true;
                _iteratorError3 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
                    _iterator3.return();
                  }
                } finally {
                  if (_didIteratorError3) {
                    throw _iteratorError3;
                  }
                }
              }

              if (_orders.length > 1) {
                var _path = _environment.default.APP.API_HOST + '/contents/orders';

                self.ajax.requestWithBodyPromise(_path, 'PATCH', 'application/json', JSON.stringify(_orders));
              }
            }
          });
          Ember.set(_this2, 'drake', drake);
        }
      }, 1000);
    },
    loadContents: function loadContents(refreshCategories) {
      var _this3 = this;

      this.store.query('content', {
        filter: this.section,
        overrideWithAudience: this.overrideWithAudience
      }).then(function (contents) {
        var routeName = _this3.router.get('currentRouteName');

        contents = routeName.startsWith('board') ? contents : _this3.contentService.hideNotVisiblesContents(contents);

        if (_this3.displayMode === "resources") {
          contents = _this3.filterExcludeFileCardToDonwload(contents);
        }

        if ((!contents || contents.length === 0) && _this3.show) {
          Ember.set(_this3, 'show', false);
          return;
        }

        _this3.setCategories(contents, refreshCategories);

        Ember.set(_this3, 'allContents', contents);

        if (_this3.displayMaxItems && contents.length > _this3.displayMaxItems && !_this3.isDisplayingAll) {
          Ember.set(_this3, 'contents', contents.slice(0, _this3.displayMaxItems));
          Ember.set(_this3, 'canDisplayAll', true);
        } else {
          Ember.set(_this3, 'contents', contents);
        }

        _this3.initDrake();
      });
    },
    setCategories: function setCategories(contents) {
      var _this4 = this;

      var refreshCategories = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var categories = [];
      var categoriesComplete = [];
      contents.forEach(function (content) {
        var category = undefined;

        if (!_this4.showDownloads || !content.toDownload) {
          if (content.genericContent && content.genericContent.category) {
            category = content.genericContent.category;
          } else if (content.category) {
            category = content.category;
          }

          if (category && categories.indexOf(category.name) < 0) {
            categories.push(category.name);
            categoriesComplete.push(category);
          }
        }
      });
      categoriesComplete.sort(function (a, b) {
        return a.order > b.order ? 1 : -1;
      });
      categories = [];

      for (var _i = 0, _categoriesComplete = categoriesComplete; _i < _categoriesComplete.length; _i++) {
        var categoryComplete = _categoriesComplete[_i];
        categories.push(categoryComplete.name);
      }

      Ember.set(this, 'categories', categories);
      Ember.set(this, 'categoriesComplete', categoriesComplete);

      if (!this.selectedCategories || refreshCategories) {
        Ember.set(this, 'selectedCategories', categories);
      }
    },
    filterExcludeFileCardToDonwload: function filterExcludeFileCardToDonwload(contents) {
      var newArray = [];
      contents.forEach(function (content) {
        var toDownload = content.genericContent ? content.genericContent.toDownload : content.toDownload;

        if (content.type !== 'filecard' || !toDownload) {
          newArray.pushObject(content);
        }
      });
      return newArray;
    },
    actions: {
      removeContent: function removeContent(contentId) {
        Ember.set(this, "contentToRemoveId", contentId);
        Ember.set(this, "showRemovePopup", true);
      },
      removeConfirm: function removeConfirm(response) {
        var _this5 = this;

        if (response) {
          var path = _environment.default.APP.API_HOST + '/contents/' + this.contentToRemoveId;
          this.ajax.requestPromise(path, 'DELETE').then(function () {
            _this5.loadContents();
          });
        }
      },
      showAll: function showAll() {
        if (this.overrideShowMoreAction) {
          this.overrideShowMoreAction();
          return;
        }

        Ember.set(this, 'contents', this.allContents);
        Ember.set(this, 'canDisplayAll', false);
        Ember.set(this, 'isDisplayingAll', true);
      },
      editContent: function editContent() {},
      showContentConditionsModal: function showContentConditionsModal(content) {
        Ember.set(this, 'currentConditionsContent', content);
        Ember.set(this, 'showContentConditionsModal', true);
      },
      addExistingContent: function addExistingContent() {
        var selectedContents = [];

        if (this.allContents) {
          this.allContents.forEach(function (content) {
            var contentJSObj = content.toJSON();
            contentJSObj.id = Number(content.id);
            selectedContents.push(contentJSObj);
          });
        }

        Ember.set(this, 'selectedContents', selectedContents);
        Ember.set(this, 'showAddExistingContentPopup', true);
      },
      onAddFromPopup: function onAddFromPopup() {
        this.loadContents(true);
        if (this.onAdd) this.onAdd();
      },
      onFilter: function onFilter(filter) {
        if (this.isDisplayingAll) return;
        this.send('showAll');
      },
      openContentDetail: function openContentDetail(content) {
        Ember.set(this, 'contentDetail', content);
        Ember.set(this, 'showContentDetailPopup', true);
      },
      switchCategory: function switchCategory(category) {
        if (this.selectedCategories.includes(category)) {
          var categories = this.selectedCategories.filter(function (value, index, arr) {
            return value !== category;
          });
          Ember.set(this, 'selectedCategories', categories);
        } else {
          var _categories = this.selectedCategories;

          _categories.push(category);

          Ember.set(this, 'selectedCategories', undefined);
          Ember.set(this, 'selectedCategories', _categories);
        }
      }
    }
  });

  _exports.default = _default;
});