define("teelt-frontend/components/talents/modify-my-password-modal", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    customErrorService: Ember.inject.service('custom-error'),
    intl: Ember.inject.service(),
    notify: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      Ember.set(this, 'data', {
        oldPassword: "",
        newPassword: ""
      });
    },
    validatePassword: function validatePassword() {
      var newPassword = this.data.newPassword;

      if (newPassword.length < 9) {
        Ember.set(this, "error", this.intl.t('recover_password.errors.min_size_8'));
        return false;
      }

      var specialCharacterFormat = /[!@#$%^&*()_+\-\[\]{}'"|,.<>?]+/;
      var specialCharatersDisallowed = /[\/\\:;=]/;

      if (specialCharatersDisallowed.test(newPassword)) {
        Ember.set(this, "error", this.intl.t('recover_password.rules.special_characters_disallowed'));
        return;
      }

      if (!newPassword.match(/[A-Z]/, 'g') || !newPassword.match(/[a-z]/, 'g') || !newPassword.match(/[0-9]/, 'g') || !specialCharacterFormat.test(newPassword)) {
        Ember.set(this, "error", this.intl.t('recover_password.errors.not_secure_password'));
        return false;
      }

      return true;
    },
    actions: {
      save: function save() {
        var _this = this;

        Ember.set(this, "error", undefined);
        if (!this.validatePassword()) return;
        var path = _environment.default.APP.API_HOST + '/users/me/password';
        this.ajax.requestWithBodyPromise(path, 'POST', 'application/json', JSON.stringify(this.data)).then(function () {
          Ember.set(_this, 'show', false);

          _this.notify.info(_this.intl.t('talent.password_updated'), {
            closeAfter: 5000
          });
        }).catch(function (err) {
          _this.customErrorService.handleAjaxError(err);
        });
      },
      onPasswordInput: function onPasswordInput(value) {
        var rulesItems = document.querySelectorAll('.fieldRules li');
        var rules = {
          "one_lowercase": /[a-z]/,
          "one_uppercase": /[A-Z]/,
          "one_number": /[0-9]/,
          "one_special_character": /[^a-z0-9\/\\:;=]/i,
          "min_size": /.{9,}/
        };
        rulesItems.forEach(function (elm) {
          var valid,
              ruleName = elm.id;

          if (rules[ruleName]) {
            valid = new RegExp(rules[ruleName]).test(value);
            elm.classList.toggle('pass', valid);
          }
        });
      },
      togglePasswordVisibility: function togglePasswordVisibility(isNew) {
        var eye = document.getElementById(isNew ? 'new-password-eye' : 'old-password-eye');
        var input = document.getElementById(isNew ? 'newPassword' : 'oldPassword');

        if (eye.textContent === "visibility") {
          eye.textContent += "_off";
          input.type = "text";
        } else {
          eye.textContent = eye.textContent.replace("_off", "");
          input.type = "password";
        }
      }
    }
  });

  _exports.default = _default;
});