define("teelt-frontend/templates/components/talents/talent-detail-actions-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zokqBX2T",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"not\",[[23,[\"hideTalentTask\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"actions-container\"],[9],[0,\"\\n    \"],[1,[27,\"task/tasks-list\",null,[[\"showHeader\",\"showTalentProgress\",\"forceAssign\",\"talent\",\"hideNotifications\"],[true,true,\"talent\",[23,[\"talent\"]],true]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"not\",[[23,[\"hideMyTask\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"actions-container\"],[9],[0,\"\\n    \"],[1,[27,\"task/tasks-list\",null,[[\"showHeader\",\"talent\",\"canValidate\",\"hideNotifications\"],[false,[23,[\"talent\"]],true,true]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-detail-actions-block.hbs"
    }
  });

  _exports.default = _default;
});