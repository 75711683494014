define("teelt-frontend/routes/talent/integration/team/my-team", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    getTeamUrl: _environment.default.APP.API_HOST + "/users/me/team",
    getTalentCompanyUrl: _environment.default.APP.API_HOST + "/users/me/team/company",
    ajax: Ember.inject.service(),
    afterModel: function afterModel(model) {
      this.ajax.requestPromise(this.getTeamUrl).then(function (team) {
        Ember.set(model, "team", team);
      });
    }
  });

  _exports.default = _default;
});