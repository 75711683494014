define("teelt-frontend/components/dashboard/custom-date-filters", ["exports", "teelt-frontend/utils/jquery-utils"], function (_exports, _jqueryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['custom-date-filters'],
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    userService: Ember.inject.service('user-service'),
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    filter: 'today',
    range: {
      startDate: null,
      endDate: null
    },
    params: {
      dateFilter: 'today',
      startDate: null,
      endDate: null,
      targetDateField: 'createdAt'
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.eventBus.on('CLICK_EVENT', this, 'checkToCloseModal');
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.set(this, 'filter', this.defaultFilter ? this.defaultFilter : 'today');
      Ember.set(this.params, 'dateFilter', this.defaultFilter ? this.defaultFilter : 'today');

      if (this.onFilterChangedAction) {
        this.loadData();
      }
    },
    loadData: function loadData() {
      Ember.set(this, 'params', {
        dateFilter: this.defaultFilter ? this.defaultFilter : 'today',
        startDate: null,
        endDate: null,
        targetDateField: this.targetDateField ? this.targetDateField : 'createdAt'
      });
      this.params.dateFilter = this.filter;

      if (this.filter == "dateRange") {
        this.params.startDate = this.range.startDate;
        this.params.endDate = this.range.endDate;
      }

      this.onFilterChangedAction(this.params);
    },
    checkToCloseModal: function checkToCloseModal(e) {
      if (!(0, _jqueryUtils.default)().checkIfClickInElement(e, ["possibles-list"])) {
        var el = document.querySelector('.possibles-list');

        if (el && (el.offsetParent !== null || el.offsetWidth > 0 || el.offsetHeight > 0)) {
          return false;
        }

        if (this.showPossibleFilters) {
          Ember.set(this, 'showPossibleFilters', false);
        }
      }
    },
    actions: {
      initDateRangeFilter: function initDateRangeFilter() {
        if (this.onFilterChangedAction) {
          this.loadData();
        }
      },
      selectFilter: function selectFilter(filter) {
        Ember.set(this, 'filter', filter);
        Ember.set(this, 'showPossibleFilters', false);

        if (this.filter == "dateRange") {
          Ember.set(this, 'showDateRangePicker', true);
        } else {
          Ember.set(this, 'showDateRangePicker', false);
          Ember.set(this, 'range.startDate', null);
          Ember.set(this, 'range.endDate', null);

          if (this.onFilterChangedAction) {
            this.loadData();
          }
        }
      },
      showPossibleFilters: function showPossibleFilters() {
        var el = document.querySelector('.possibles-list');

        if (el && (el.offsetParent !== null || el.offsetWidth > 0 || el.offsetHeight > 0)) {
          el.parentElement.firstElementChild.firstElementChild.innerHTML = "expand_more";
          el.remove();
        }

        if (!this.showPossibleFilters) {
          Ember.set(this, 'showPossibleFilters', true);
        }
      }
    }
  });

  _exports.default = _default;
});