define("teelt-frontend/components/analytics/my-talents", ["exports", "teelt-frontend/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    classNames: ['my-talents'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.content) {
        var content = this.store.createRecord('content', {
          date_filter: 'daily',
          from_date: '',
          to_date: ''
        });
        Ember.set(this, 'content', content);
      }

      this.myChart = {};
      this.loadTalents();
    },
    loadTalents: function loadTalents() {
      var _this = this;

      Promise.all([this.getCompanyTalents()]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
            talents = _ref2[0];

        talents.forEach(function (talent) {
          Ember.set(talent, "company", _this.session.data.authenticated.user.company);
        });
        Ember.set(_this, "talents", talents);

        _this.loadTalentsChart();
      });
    },
    getCompanyTalents: function getCompanyTalents() {
      var ANALYTICS_API_URL = _environment.default.APP.API_HOST + "/analytics/?type=" + this.content.date_filter + "&from_date=" + this.content.from_date + "&to_date=" + this.content.to_date;
      return this.ajax.requestPromise(ANALYTICS_API_URL, 'GET');
    },
    actions: {
      onDateFilterChange: function onDateFilterChange(value) {
        this.myChart.destroy();
        Ember.set(this.content, 'date_filter', value);
        this.loadTalents();
      },
      onFromDateFilterChange: function onFromDateFilterChange(value) {
        this.myChart.destroy();
        Ember.set(this.content, 'from_date', value);
        this.loadTalents();
      },
      onToDateFilterChange: function onToDateFilterChange(value) {
        this.myChart.destroy();
        Ember.set(this.content, 'to_date', value);
        this.loadTalents();
      }
    },
    getDateByWeek: function getDateByWeek(weeks, year) {
      var date = new Date(year, 0, 1);
      var dayNum = date.getDay();
      weeks = parseInt(weeks, 10);
      var requiredDate = --weeks * 7; // If 1 Jan is Friday to Sunday, go to next week 

      if (dayNum != 0 || dayNum > 4) {
        requiredDate += 7;
      } // Add required number of days


      date.setDate(1 - date.getDay() + ++requiredDate);
      return date;
    },
    loadTalentsChart: function loadTalentsChart() {
      var _this2 = this;

      var unit = 'day';
      var tooltipFormat = 'DD/MM/YYYY';

      switch (this.content.date_filter) {
        case 'monthly':
          unit = 'month';
          tooltipFormat = 'MM/YYYY';
          break;

        case 'weekly':
          unit = 'week';
          tooltipFormat = 'DD/MM/YYYY';
          break;

        default:
          unit = 'day';
          tooltipFormat = 'DD/MM/YYYY';
          break;
      }

      var preparedData = this.talents.map(function (t) {
        var date = '';

        if (_this2.content.date_filter == 'monthly') {
          date = new Date(t.year + '-' + t.month);
        } else if (_this2.content.date_filter == 'daily') {
          date = new Date(t.year + '-' + t.month + '-' + t.day);
        } else if (_this2.content.date_filter == 'weekly') {
          date = new Date(_this2.getDateByWeek(t.week, t.year));
        }

        return {
          x: date,
          y: t.count
        };
      });
      var ctx = document.getElementById('talents-chart');
      this.myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          datasets: [{
            barThickness: 10,
            backgroundColor: '#1970bb',
            type: 'bar',
            label: 'New talents',
            data: preparedData,
            spanGaps: false
          }]
        },
        options: {
          legend: {
            display: false
          },
          tooltips: {
            enabled: true
          },
          scales: {
            xAxes: [{
              type: 'time',
              unitStepSize: 1,
              distribution: 'series',
              time: {
                parser: 'YYYY-MM-DD',
                unit: unit,
                tooltipFormat: tooltipFormat,
                displayFormats: {
                  'day': 'DD/MM/YYYY',
                  'month': 'MMM YYYY'
                }
              },
              ticks: {
                source: 'data'
              }
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      });
    }
  });

  _exports.default = _default;
});