define("teelt-frontend/templates/components/utils/search-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m/kdfD19",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,[\"search_ph\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\"],[11,\"class\",\"material-icons icon-search foreground-primary-color\"],[9],[0,\"search\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[1,[27,\"input\",null,[[\"type\",\"class\",\"id\",\"value\",\"placeholder\",\"key-up\",\"focus-in\",\"focus-out\",\"maxlength\"],[\"text\",\"browser-default foreground-primary-color\",[23,[\"inputId\"]],[23,[\"searchValue\"]],[23,[\"search_ph\"]],[27,\"action\",[[22,0,[]],\"keyUpAction\"],null],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"search_ph\"]]],null],\"\"],null],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"search_ph\"]]],null],[27,\"t\",[\"word.search\"],null]],null],\"255\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/search-bar.hbs"
    }
  });

  _exports.default = _default;
});