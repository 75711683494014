define("teelt-frontend/components/task/tasks-list/talent-timeline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'currentUserCompany', this.session.data.authenticated.user.company);
    }
  });

  _exports.default = _default;
});