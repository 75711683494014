define("teelt-frontend/components/form/form-builder", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-builder'],
    initDragula: function initDragula() {
      var self = this;
      var container = document.querySelector('.form-items-container');
      var drake = dragula({
        containers: [container],
        direction: 'vertical',
        revertOnSpill: false
      });
      drake.on('drag', function () {
        (0, _jquery.default)(document).on('mousemove', function (e) {
          self.updateScrollPos(e);
        });
      });
      drake.on('dragend', function () {
        (0, _jquery.default)(document).off('mousemove');
      });
      drake.on('drop', function (el, container, source, sibling) {
        self.reorderAfterDrop(container);
      });
      Ember.set(this, 'drake', drake);
    },
    clearDrake: function clearDrake() {
      if (this.drake) {
        this.drake.destroy();
        Ember.set(this, 'drake', undefined);
      }
    },
    didInsertElement: function didInsertElement() {
      this.initDragula();
    },
    willDestroyElement: function willDestroyElement() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval);
      }
    },
    reorderAfterDrop: function reorderAfterDrop(container) {
      var _this = this;

      var elems = Array.from(container.children);
      elems.forEach(function (elem, i) {
        var index = Number(elem.getAttribute('index'));
        Ember.set(_this.form.fields.objectAt(index), 'order', i);
      });
      var newFields = this.form.fields.sortBy('order');
      newFields.forEach(function (f) {
        return Ember.set(f, 'order', undefined);
      });
      Ember.set(this.form, 'fields', newFields);
    },
    updateScrollPos: function updateScrollPos(e) {
      if ((0, _jquery.default)(window).height() - e.clientY < 80) {
        if (!this.scrollInterval) {
          this.startScroll(1);
        }
      } else if (e.clientY < 120) {
        if (!this.scrollInterval) {
          this.startScroll(-1);
        }
      } else {
        this.stopScroll();
      }
    },
    startScroll: function startScroll(direction) {
      Ember.set(this, 'scrollInterval', setInterval(function () {
        (0, _jquery.default)(window).scrollTop((0, _jquery.default)(window).scrollTop() + direction * 4);
      }, 10));
    },
    stopScroll: function stopScroll() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval);
        Ember.set(this, 'scrollInterval', undefined);
      }
    },
    actions: {
      addNewField: function addNewField() {
        var _this2 = this;

        var scrollPosition = (0, _jquery.default)('.update-module-page') ? (0, _jquery.default)('.update-module-page').scrollTop() : 0;
        this.clearDrake();
        var fields = this.form.fields;
        Ember.set(this.form, 'fields', undefined);
        fields.pushObject({
          type: "short_text",
          properties: {},
          required: false,
          inputErrors: {}
        });
        setTimeout(function () {
          Ember.set(_this2.form, 'fields', fields);
          setTimeout(function () {
            if (scrollPosition) {
              (0, _jquery.default)('.update-module-page').scrollTop(scrollPosition + 300);
            }

            _this2.initDragula();
          });
        });
      },
      onDeleteItem: function onDeleteItem(item) {
        var _this3 = this;

        var scrollPosition = (0, _jquery.default)('.update-module-page') ? (0, _jquery.default)('.update-module-page').scrollTop() : 0;
        this.clearDrake();
        var fields = this.form.fields;
        Ember.set(this.form, 'fields', undefined);
        fields.removeObject(item);
        setTimeout(function () {
          Ember.set(_this3.form, 'fields', fields);
          setTimeout(function () {
            if (scrollPosition) {
              (0, _jquery.default)('.update-module-page').scrollTop(scrollPosition - 300);
            }

            _this3.initDragula();
          });
        });
      },
      onDuplicateItem: function onDuplicateItem(item) {
        var copy = {
          title: item.title,
          type: item.type,
          properties: {},
          required: item.required,
          allowedForSftp: item.allowedForSftp,
          points: item.points
        };

        if (item.properties) {
          copy.properties.description = item.properties.description;
          copy.properties.allow_multiple_selection = item.properties.allow_multiple_selection;
          copy.properties.fromValue = item.properties.fromValue;
          copy.properties.toValue = item.properties.toValue;

          if (item.properties.labels) {
            copy.properties.labels = {
              left: item.properties.labels.left,
              right: item.properties.labels.right
            };
          }
        }

        if (item.possibleResponses) {
          copy.possibleResponses = [];
          item.possibleResponses.forEach(function (pr) {
            copy.possibleResponses.pushObject({
              value: pr.value,
              isValid: pr.isValid
            });
          });
        }

        this.form.fields.pushObject(copy);
        setTimeout(function () {
          (0, _jquery.default)(window).scrollTop((0, _jquery.default)(document).height());
        }, 0);
      }
    }
  });

  _exports.default = _default;
});