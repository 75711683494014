define("teelt-frontend/templates/components/event/event-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a9FgG2Du",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"calendar\"],[9],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showNewEvent\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"event/add-event\",null,[[\"showNewEvent\",\"blockEdit\",\"newEvent\",\"addEvent\",\"removeEvent\",\"fromDate\",\"toDate\",\"addParticipants\"],[[23,[\"showNewEvent\"]],[23,[\"blockEdit\"]],[23,[\"newEvent\"]],[27,\"action\",[[22,0,[]],\"addEvent\"],null],[27,\"action\",[[22,0,[]],\"removeEvent\"],null],[23,[\"fromDate\"]],[23,[\"toDate\"]],[23,[\"addParticipants\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/event/event-calendar.hbs"
    }
  });

  _exports.default = _default;
});