define("teelt-frontend/helpers/notification/notification-offset-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    staticlikeData: Ember.inject.service(),
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          notification = _ref2[0],
          refresh = _ref2[1];

      if (notification.startOffsetDirection && notification.startOffsetUnit || notification.triggerInitiator == "custom_date" && notification.customDate) {
        var startline = "";

        if (notification.startOffset) {
          startline = this.intl.t("task.unit." + notification.startOffsetUnit);

          if (notification.startOffsetDirection == "BEFORE") {
            startline += "-";
          } else {
            startline += "+";
          }

          startline += notification.startOffset + " " + this.intl.t("emails.rules.offset_picker.direction." + notification.startOffsetDirection).toLowerCase() + " ";
        }

        if (notification.triggerInitiator && notification.triggerInitiator == "custom_field") {
          var customField = this.staticlikeData.companyCustomFields.find(function (cf) {
            return cf.id == notification.customFieldId;
          });

          if (customField) {
            startline += this.intl.t("emails.rules.trigger.after_completion_of", {
              name: customField.name
            });
          } else {
            startline += this.intl.t("emails.rules.trigger." + notification.triggerInitiator);
          }
        } else if (notification.triggerInitiator && notification.triggerInitiator == "custom_date" && notification.customDate) {
          var date = moment(notification.customDate).format('DD/MM/YYYY');
          var hours = moment(notification.customDate).hours();
          var minutes = moment(notification.customDate).minutes();
          var time = (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
          startline += this.intl.t("emails.rules.trigger.custom_date.on", {
            date: date,
            time: time
          });
        } else {
          startline += this.intl.t("emails.rules.trigger." + notification.triggerInitiator);
        }

        return startline;
      }
    }
  });

  _exports.default = _default;
});