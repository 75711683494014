define("teelt-frontend/routes/talent/integration/ihm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      meetPeopleId: '',
      openGallery: false,
      exploreOfficeId: ''
    },
    resetController: function resetController(controller) {
      Ember.set(this.currentModel, 'meetPeopleId', null);
      controller.set('meetPeopleId', null);
      Ember.set(this.currentModel, 'exploreOfficeId', null);
      controller.set('exploreOfficeId', null);
    },
    afterModel: function afterModel(model, params) {
      if (params.queryParams && params.queryParams.meetPeopleId) {
        this.openMeetPeople(params.queryParams.meetPeopleId, params.queryParams.openGallery);
      }

      if (params.queryParams && params.queryParams.exploreOfficeId) {
        Ember.set(model, 'exploreOfficeId', params.queryParams.exploreOfficeId);
      }
    },
    openMeetPeople: function openMeetPeople(meetPeopleId, openGallery) {
      var _this = this;

      setTimeout(function () {
        Ember.set(_this.currentModel, 'meetPeopleId', meetPeopleId);
        Ember.set(_this.currentModel, 'openGallery', openGallery);
      }, 300);
    }
  });

  _exports.default = _default;
});