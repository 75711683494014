define("teelt-frontend/components/talents/talent-information-block-period-progression", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talent-information-block-period-progression'],
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    userService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var periods = this.userService.getTalentPeriods(this.talent);
      Ember.set(this, 'periods', periods);
      Ember.set(this, 'talentCurrentPeriod', this.userService.getTalentCurrentPeriod(this.talent, periods));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.updateProgression();
    },
    updateProgression: function updateProgression() {
      this.$('.progression-steps li.progression-step').animate({
        width: 65 / this.periods.length + '%'
      });
      this.$('.progression-line').animate({
        "margin-left": 65 / this.periods.length / 2 + "%"
      });

      if (this.talent.talentProgression == 100) {
        this.$('.progression-inner-line').animate({
          width: "100%"
        });
      } else {
        this.$('.progression-inner-line').animate({
          width: 65 / this.periods.length * this.talentCurrentPeriod * 100 / (100 - 65 / this.periods.length / 2) + "%"
        });
      }
    },
    progressionObserver: Ember.observer('talent.talentProgression', function () {
      this.updateProgression();
    })
  });

  _exports.default = _default;
});