define("teelt-frontend/templates/components/ihm/explore-office/explore-office-sample-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9nOS1fT3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"onClose\",\"translucentOverlay\",\"containerClass\",\"onClickOverlay\"],[[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showExploreOfficeSampleModal\"]]],null],false],null],true,\"select-form-roles-container\",[27,\"action\",[[22,0,[]],\"onClickOverlay\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"id\",\"explore-office-sample-modal\"],[11,\"class\",\"modal-container\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"btn-close btn-transparent foreground-primary-color\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showExploreOfficeSampleModal\"]]],null],false]],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"close\"],[10],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"modal-content\"],[9],[0,\"\\n      \"],[7,\"h1\"],[11,\"class\",\"foreground-primary-color\"],[9],[0,\"\\n        \"],[1,[21,\"icon/module/explore-office-svg\"],false],[0,\"\\n        \"],[1,[27,\"t\",[\"task.type.explore_office.title\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"baseline\"],[9],[1,[27,\"t\",[\"task.type.explore_office.sample_modal_baseline\"],null],false],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"g-window\"],[9],[0,\"\\n        \"],[7,\"img\"],[11,\"class\",\"explore-office-sample\"],[11,\"src\",\"/img/exploreoffice/explore_office_sample.png\"],[11,\"alt\",\"explore_office_sample\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/ihm/explore-office/explore-office-sample-modal.hbs"
    }
  });

  _exports.default = _default;
});