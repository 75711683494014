define("teelt-frontend/templates/components/talents/talent-detail-block/talent-template-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PDxbBwId",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"input-field-v2\"],[9],[0,\"\\n  \"],[7,\"label\"],[9],[0,\" \"],[1,[27,\"t\",[\"talent.detail.journey\"],null],false],[0,\" \"],[10],[0,\"\\n  \"],[1,[27,\"utils/searchable-select\",null,[[\"searchPath\",\"defaultValue\",\"keyAtt\",\"keyValue\",\"multiple\",\"onValueChange\"],[[23,[\"templatePath\"]],[23,[\"defaultTemplate\"]],\"id\",\"name\",false,[27,\"action\",[[22,0,[]],\"onTemplateChanged\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"input-field-v2\"],[9],[0,\"\\n  \"],[7,\"label\"],[9],[0,\" \"],[1,[27,\"capitalize\",[[27,\"t\",[\"word.campaign\"],null]],null],false],[0,\" \"],[10],[0,\"\\n  \"],[1,[27,\"utils/searchable-select\",null,[[\"searchPath\",\"defaultValue\",\"keyAtt\",\"keyValue\",\"multiple\",\"onValueChange\"],[[23,[\"campaignPath\"]],[23,[\"defaultCampaign\"]],\"id\",\"name\",false,[27,\"action\",[[22,0,[]],\"onCampaignChanged\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-detail-block/talent-template-block.hbs"
    }
  });

  _exports.default = _default;
});