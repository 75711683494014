define("teelt-frontend/templates/components/login-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rfapwTbu",
    "block": "{\"symbols\":[\"@src\"],\"statements\":[[7,\"div\"],[11,\"class\",\"center-align\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"login.form\"],null,{\"statements\":[[0,\"    \"],[7,\"img\"],[11,\"class\",\"teelt-logo\"],[12,\"src\",[22,1,[]]],[11,\"alt\",\"teelt-logo\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/login-logo.hbs"
    }
  });

  _exports.default = _default;
});