define("teelt-frontend/components/content/content-versions-edit", ["exports", "jquery", "teelt-frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['content-versions-edit'],
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.loadVersions();
    },
    audiences: Ember.computed('allAudiences', 'versions', function () {
      if (!this.allAudiences) return;

      if (this.versions) {
        var audiencesUsed = this.versions.map(function (v) {
          return v.audience.id;
        });
        var audiences = this.allAudiences.filter(function (a) {
          return audiencesUsed.indexOf(Number(a.key)) < 0;
        });
        return audiences;
      }

      return this.allAudiences;
    }),
    loadVersions: function loadVersions() {
      var _this = this;

      var path = _environment.default.APP.API_HOST + '/contents/' + this.defaultContent.id + '/versions';
      this.ajax.requestPromise(path).then(function (versions) {
        Ember.set(_this, 'versions', versions);
      });
    },
    loadAudiences: function loadAudiences() {
      var _this2 = this;

      this.store.findAll('audience').then(function (audiences) {
        var selectValues = [];
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = audiences.toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var audience = _step.value;
            selectValues.push({
              key: audience.id,
              label: audience.name
            });
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        Ember.set(_this2, 'allAudiences', selectValues);
      });
    },
    actions: {
      createNewVersion: function createNewVersion() {
        var dataNewContent = this.defaultContent.toJSON();
        dataNewContent.genericContent = {
          id: this.defaultContent.id
        };
        var newContent = this.store.createRecord('content', dataNewContent);
        this.loadAudiences();
        Ember.set(this, 'newContent', newContent);
      },
      editContent: function editContent(id) {
        var _this3 = this;

        Ember.set(this, 'showActions', false);
        this.store.findRecord('content', id).then(function (content) {
          Ember.set(_this3, 'editContent', content);
        });
      },
      duplicateContent: function duplicateContent(content) {
        Ember.set(this, 'showActions', false);

        if (!content) {
          content = this.defaultContent.toJSON();
        }

        var dataNewContent = JSON.parse(JSON.stringify(content));
        dataNewContent.genericContent = {
          id: this.defaultContent.id
        };
        dataNewContent.id = undefined;
        var newContent = this.store.createRecord('content', dataNewContent);
        this.loadAudiences();
        Ember.set(this, 'newContent', newContent);
      },
      switchVisibility: function switchVisibility(content) {
        Ember.set(this, 'showActions', false);
        this.store.findRecord('content', content.id).then(function (c) {
          Ember.set(c, "isVisible", !c.isVisible);
          c.save();
          Ember.set(content, 'isVisible', c.isVisible);
        });
      },
      openDeleteContent: function openDeleteContent(content) {
        Ember.set(this, 'showActions', false);
        Ember.set(this, 'contentToDelete', content);
      },
      removeContent: function removeContent(response) {
        var _this4 = this;

        if (response) {
          this.store.findRecord('content', this.contentToDelete.id).then(function (content) {
            content.deleteRecord();
            content.save().then(function () {
              _this4.loadVersions();
            });
          });
        }

        Ember.set(this, 'contentToDelete', undefined);
      },
      onClickOverlay: function onClickOverlay() {},
      onAudienceChanged: function onAudienceChanged(audience) {
        Ember.set(this.newContent, 'audience', {
          id: Number(audience.key),
          name: audience.label
        });
      },
      continueToEditContent: function continueToEditContent() {
        if (!this.newContent.audience || !this.newContent.audience.id) return;
        Ember.set(this, 'editContent', this.newContent);
        Ember.set(this, 'newContent', false);
      },
      onFinish: function onFinish() {
        Ember.set(this, 'editContent', false);
        this.loadVersions();
      },
      previous: function previous() {
        Ember.set(this, 'editContent', false);
      }
    }
  });

  _exports.default = _default;
});