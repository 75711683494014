define("teelt-frontend/components/input/generic-autocomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.initField();
    },
    initField: function initField() {
      var _this = this;

      if (this.path) {
        this.$('#' + this.customId).select2({
          ajax: {
            url: this.path,
            contentType: 'application/json;charset=utf-8',
            dataType: 'json',
            headers: {
              'x-auth-token': this.ajax.xAuthToken
            },
            xhrFields: {
              withCredentials: true
            },
            data: function data(params) {
              var query = {
                search: params.term
              };
              return query;
            },
            processResults: function processResults(data) {
              if (!data) return;
              var results = data.map(function (record) {
                return {
                  id: _this.idKey ? record[_this.idKey] : record.id,
                  text: _this.valueKey ? record[_this.valueKey] : record.name
                };
              });

              if (_this.canSelectAll) {
                results.unshift({
                  id: "all",
                  text: _this.allSelectedText
                });
              } else {
                results.unshift({
                  id: "",
                  text: ""
                });
              }

              return {
                results: results
              };
            }
          }
        });

        if (this.selectedValue && this.preselectedText) {
          var option = new Option(this.preselectedText, this.selectedValue, true, true);
          this.$('#' + this.customId).append(option).trigger('change');
          this.$('#' + this.customId).trigger({
            type: 'select2:select',
            params: {
              data: {
                id: this.selectedValue
              }
            }
          });
        }
      } else {
        this.$('#' + this.customId).select2();
      }
    },
    actions: {
      onChange: function onChange(value) {
        Ember.set(this, 'selectedValue', value);
        var text = undefined;

        if (this.$('#' + this.customId).select2('data').length > 0 && this.$('#' + this.customId).select2('data')[0].text) {
          text = this.$('#' + this.customId).select2('data')[0].text;
        }

        if (this.onChange) this.onChange(value, text);
      }
    }
  });

  _exports.default = _default;
});