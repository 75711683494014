define("teelt-frontend/models/form-report", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    form: _emberData.default.attr(),
    meetPeople: _emberData.default.attr(),
    template: _emberData.default.attr(),
    createdAt: _emberData.default.attr('date'),
    responseCount: _emberData.default.attr('number'),
    responseCountTotal: _emberData.default.attr('number'),
    responseNbByMonth: _emberData.default.attr(),
    completionRate: _emberData.default.attr('string'),
    connexionRate: _emberData.default.attr('string'),
    templateTypeAbortRate: _emberData.default.attr('string'),
    uncompletedTasksAfterTemplateType: _emberData.default.attr('string'),
    countEmailSent: _emberData.default.attr('string'),
    countEmailOpened: _emberData.default.attr('string'),
    countEmailClicked: _emberData.default.attr('string'),
    formFields: _emberData.default.attr(),
    complexFilters: _emberData.default.attr('string')
  });

  _exports.default = _default;
});