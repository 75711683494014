define("teelt-frontend/routes/board", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "jquery", "teelt-frontend/config/environment"], function (_exports, _authenticatedRouteMixin, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authenticationRoute: 'login.form',
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    userService: Ember.inject.service('user-service'),
    customCompanyPropertyService: Ember.inject.service('custom-company-property'),
    staticlikeData: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    ajax: Ember.inject.service(),
    onRouteChanged: Ember.observer('router.currentRouteName', function () {
      this.updateRouteName();
    }),
    updateRouteName: function updateRouteName() {
      if (this.router.currentRouteName) {
        (0, _jquery.default)('#board').removeClass().addClass(this.router.currentRouteName.replaceAll('.', '_'));
      }

      if (this.router.currentRouteName.startsWith('board.communication')) {
        if (this.session.isAuthenticated && !this.session.data.authenticated.user.company.activeCommunication) {
          this.transitionTo('board.talents');
        }
      }
    },
    checkClick: function checkClick() {
      var _this = this;

      (0, _jquery.default)("body").on('click', function (event) {
        _this.eventBus.trigger('CLICK_EVENT', event);
      });
    },
    activate: function activate() {
      var _this2 = this;

      this.customCompanyPropertyService.reload();
      this.staticlikeData.refreshCompanyCustomFields();
      setTimeout(function () {
        _this2.updateRouteName();

        _this2.checkClick();
      });
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.session.isAuthenticated) {
        if (this.session.data.authenticated.user.roles.length == 1 && this.session.data.authenticated.user.roles[0] === 'ROLE_USER') {
          this.transitionTo('talent.home');
        }
      } else {
        console.log('ACCESS BOARD WITHOUT AUTHENTICATION !');
        this.transitionTo('login.form');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        customCSS: this.ajax.requestPromise(_environment.default.APP.API_HOST + '/companies/' + this.session.data.authenticated.user.company.id + "/customcss", 'GET')
      });
    },
    actions: {
      logout: function logout() {
        var _this3 = this;

        this.session.invalidate().then(function () {
          _this3.transitionTo('login.form');
        });
      }
    }
  });

  _exports.default = _default;
});