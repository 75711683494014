define("teelt-frontend/components/utils/basic-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['basic-filter'],
    intl: Ember.inject.service(),
    selectedLabel: Ember.computed('filter.selected', function () {
      if (!this.filter.selected || this.filter.selected === 'ALL') {
        return this.filter.allLabel ? this.filter.allLabel : this.intl.t("word.all");
      }

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.filter.values[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var value = _step.value;

          if (value.key === this.filter.selected) {
            return value.label;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return this.filter.allLabel ? this.filter.allLabel : this.intl.t("word.all");
    }),
    actions: {
      select: function select(key) {
        Ember.set(this, 'opened', false);
        Ember.set(this.filter, 'selected', key);

        if (this.onFilter) {
          this.onFilter(this.filter, key);
        }
      }
    }
  });

  _exports.default = _default;
});