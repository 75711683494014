define("teelt-frontend/templates/components/talents/talent-document-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G1TdZKXQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"field-file\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex aligncenter\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"info-container\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"field-name g-talent-table-name truncate\"],[9],[7,\"span\"],[12,\"class\",[28,[\"field-name-action \",[27,\"if\",[[23,[\"fullValue\"]],\"clickable\"],null]]]],[3,\"action\",[[22,0,[]],\"openFile\"]],[9],[1,[23,[\"field\",\"name\"]],false],[10],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"desc-button\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"task-description truncate\"],[9],[1,[23,[\"field\",\"task\",\"description\"]],false],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"icon-container\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"filled\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"circle clickable\"],[9],[0,\"\\n          \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"done\"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"bubble-container clickable\"],[3,\"action\",[[22,0,[]],\"uploadFile\"]],[9],[0,\"\\n          \"],[1,[21,\"icon/paper-plane-svg\"],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"file-progress\"],[12,\"style\",[28,[\"width:\",[21,\"progress\"],\"%\"]]],[9],[10],[0,\"\\n \\n  \"],[1,[27,\"custom-field/input-field\",null,[[\"talent\",\"fullValue\",\"field\",\"onFileUploaded\",\"progress\"],[[23,[\"talent\"]],[23,[\"fullValue\"]],[23,[\"field\"]],[27,\"action\",[[22,0,[]],\"onFileUploaded\"],null],[23,[\"progress\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n  \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-document-block.hbs"
    }
  });

  _exports.default = _default;
});