define("teelt-frontend/templates/board/communication/communication-planning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qHaWqq4C",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"talents\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"board.communications\"],[[\"class\"],[\"back foreground-primary-color\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"close\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[11,\"class\",\"section-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n      \"],[1,[27,\"communication/communication-edit\",null,[[\"onlyPlanning\",\"campaign\",\"step\"],[true,[23,[\"model\"]],\"planning\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/communication/communication-planning.hbs"
    }
  });

  _exports.default = _default;
});