define("teelt-frontend/components/talents/talent-tasks", ["exports", "moment", "teelt-frontend/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talent-tasks', 'clear'],
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    showTaskToRealise: true,
    showTaskCompleted: true,
    periodFilter: 'ALL',
    init: function init() {
      this._super.apply(this, arguments);

      this.initTasks();
      this.calculateCurrentPeriod();
      var currentUserCompany = this.session.data.authenticated.user.company;
      var periodBefore = currentUserCompany.periodBeforeNameKey ? this.intl.t(currentUserCompany.periodBeforeNameKey) : this.intl.t("task.period.BEFORE");
      var periodFirst = currentUserCompany.periodFirstNameKey ? this.intl.t(currentUserCompany.periodFirstNameKey) : this.intl.t("task.period.FIRST");
      var periodAfter = currentUserCompany.periodAfterNameKey ? this.intl.t(currentUserCompany.periodAfterNameKey) : this.intl.t("task.period.AFTER");
      var periodFilter = [{
        key: 'BEFORE',
        label: periodBefore
      }, {
        key: 'FIRST',
        label: periodFirst
      }, {
        key: 'AFTER',
        label: periodAfter
      }];
      var filters = [];
      filters.push({
        id: 'period-filter',
        values: periodFilter,
        allKey: "ALL",
        allLabel: this.intl.t("event.filter.date.all"),
        selected: this.periodFilter
      });
      Ember.set(this, 'filters', filters);
    },
    initTasks: function initTasks() {
      var _this = this;

      var toRealise = [];
      var completed = [];
      this.talent.remainingTalentTasks.forEach(function (task) {
        Ember.set(task, 'completed', false);
        toRealise.push({
          task: task
        });
      });
      this.talent.validatedTalentTasks.forEach(function (task) {
        Ember.set(task, 'completed', true);
        completed.push({
          task: task
        });
      });
      this.talent.remainingEvents.forEach(function (event) {
        var start = event.allDay ? _moment.default.utc(event.start).startOf('day') : _moment.default.utc(event.start);

        _this.assignEventToPeriod(event, start);

        toRealise.push({
          event: event
        });
      });
      this.talent.pastEvents.forEach(function (event) {
        var start = event.allDay ? _moment.default.utc(event.start).startOf('day') : _moment.default.utc(event.start);

        _this.assignEventToPeriod(event, start);

        Ember.set(event, 'completed', true);
        completed.push({
          event: event
        });
      });
      this.sortTasks(toRealise);
      this.sortTasks(completed);
      Ember.set(this, 'tasksToRealise', toRealise);
      Ember.set(this, 'tasksCompleted', completed);
    },
    talentObserver: Ember.observer('talent.validatedAutoTasks.[]', function () {
      var _this2 = this;

      Ember.run.later(function () {
        _this2.initTasks();
      });
    }),
    calculateCurrentPeriod: function calculateCurrentPeriod() {
      if (this.talent.welcomeDate) {
        var welcomeDate = (0, _moment.default)(this.talent.welcomeDate);
        var now = (0, _moment.default)();

        if (now.isBefore(welcomeDate)) {
          Ember.set(this, 'periodFilter', 'BEFORE');
        } else if (now.isBefore(welcomeDate.add(7, 'days'))) {
          Ember.set(this, 'periodFilter', 'FIRST');
        } else {
          Ember.set(this, 'periodFilter', 'AFTER');
        }
      } else {
        Ember.set(this, 'periodFilter', 'ALL');
      }
    },
    assignEventToPeriod: function assignEventToPeriod(event, start) {
      if (this.talent.welcomeDate) {
        var welcomeDate = (0, _moment.default)(this.talent.welcomeDate);

        if (start.isBefore(welcomeDate)) {
          Ember.set(event, 'period', 'BEFORE');
        } else if (start.isBefore(welcomeDate.add(7, 'days'))) {
          Ember.set(event, 'period', 'FIRST');
        } else {
          Ember.set(event, 'period', 'AFTER');
        }
      } else {
        Ember.set(event, 'period', 'AFTER');
      }

      var descComplete = '';

      if (event.allDay) {
        descComplete += this.intl.t('word.all_the_day');
        Ember.set(event, 'date', start.format('DD/MM'));
      } else {
        Ember.set(event, 'date', start.format('DD/MM - HH[h]mm'));
      }

      if (event.room) {
        descComplete += descComplete === '' ? event.room : ' - ' + event.room;
      }

      if (event.description) {
        descComplete += descComplete === '' ? event.description : ' - ' + event.description;
      }

      Ember.set(event, 'descComplete', descComplete);
    },
    sortTasks: function sortTasks(tasks) {
      var periods = {
        'BEFORE': 0,
        'FIRST': 1,
        'AFTER': 2
      };

      var compare = function compare(t1, t2) {
        var period1 = t1.task ? t1.task.period : t1.event.period;
        var period2 = t2.task ? t2.task.period : t2.event.period;

        if (period1 == period2) {
          var type1, type2;

          if (t1.task) {
            type1 = t1.task.auto ? 2 : t1.task.formId ? 1 : 0;
          } else {
            type1 = 3;
          }

          if (t2.task) {
            type2 = t2.task.auto ? 2 : t2.task.formId ? 1 : 0;
          } else {
            type2 = 3;
          }

          if (type1 == 3 && type1 == type2) {
            var date1 = _moment.default.utc(t1.event.start);

            var date2 = _moment.default.utc(t2.event.start);

            return date1.isBefore(date2) ? -1 : 1;
          }

          return type1 - type2;
        } else {
          return periods[period1] - periods[period2];
        }
      };

      tasks.sort(compare);
    },
    taskValidated: function taskValidated(task) {
      Ember.set(task, 'completed', true);
      var tasksToRealise = this.tasksToRealise;
      var tasksCompleted = this.tasksCompleted;

      for (var i = 0; i < tasksToRealise.length; i++) {
        if (tasksToRealise[i].task && tasksToRealise[i].task.id == task.id) {
          tasksToRealise.splice(i, 1);
        }
      }

      var taskValidations = this.talent.taskValidations;
      taskValidations.pushObject(task); // Undefined before in order to trigger user observer

      Ember.set(this.talent, "taskValidations", undefined);
      Ember.set(this.talent, "taskValidations", taskValidations);
      tasksCompleted.push({
        task: task
      });
      this.sortTasks(tasksCompleted);
      this.eventBus.trigger('FORCE_RELOAD_TALENT');
    },
    validateUnvalidateTask: function validateUnvalidateTask(task, validate) {
      var _this3 = this;

      if (task.inAnimation) {
        return;
      }

      var url = _environment.default.APP.API_HOST + "/tasks/" + task.id + "/validate/" + this.talent.id;
      var tasksCompleted = this.tasksCompleted;
      var tasksToRealise = this.tasksToRealise;
      Ember.set(task, "inAnimation", true);

      if (validate) {
        this.ajax.requestPromise(url, "POST").then(function () {
          _this3.taskValidated(task);

          Ember.run.later(function () {
            Ember.set(task, "inAnimation", false);
            Ember.set(_this3, "tasksCompleted", null);
            Ember.set(_this3, "tasksToRealise", null);
            Ember.set(_this3, "tasksCompleted", tasksCompleted);
            Ember.set(_this3, "tasksToRealise", tasksToRealise);

            _this3.eventBus.trigger('REFRESH_TASKS_COUNT');
          }, 2000);
        });
      } else {
        this.ajax.requestPromise(url, "DELETE").then(function () {
          Ember.set(task, 'completed', false);

          for (var i = 0; i < tasksCompleted.length; i++) {
            if (tasksCompleted[i].task && tasksCompleted[i].task.id == task.id) {
              tasksCompleted.splice(i, 1);
              break;
            }
          }

          var taskValidations = _this3.talent.taskValidations;

          for (var _i = 0; _i < taskValidations.length; _i++) {
            if (taskValidations[_i].id == task.id) {
              taskValidations.splice(_i, 1);
              break;
            }
          } // Undefined before in order to trigger user observer


          Ember.set(_this3.talent, "taskValidations", undefined);
          Ember.set(_this3.talent, "taskValidations", taskValidations);
          tasksToRealise.push({
            task: task
          });

          _this3.sortTasks(tasksToRealise);

          _this3.eventBus.trigger('FORCE_RELOAD_TALENT');

          Ember.run.later(function () {
            Ember.set(task, "inAnimation", false);
            Ember.set(_this3, "tasksCompleted", null);
            Ember.set(_this3, "tasksToRealise", null);
            Ember.set(_this3, "tasksCompleted", tasksCompleted);
            Ember.set(_this3, "tasksToRealise", tasksToRealise);

            _this3.eventBus.trigger('REFRESH_TASKS_COUNT');
          }, 2000);
        });
      }
    },
    gotoCustomFieldSection: function gotoCustomFieldSection(field) {
      if (!field) return;

      if (field.section === "talent-profile") {
        if (field.type === "file") {
          this.router.transitionTo('talent.profile.documents');
        } else {
          this.router.transitionTo('talent.profile.detail').then(function () {
            Ember.run.later(function () {
              var el = document.getElementById('input-' + field.id);
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              });
            }, 500);
          });
        }
      }
    },

    /*
    gotoForm(formId) {
      this.router.transitionTo('talent.integration.forms').then(() => {
        later(() => {
            let el = document.getElementById('form-' + formId);
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 500);
      })
    },
    */
    openForm: function openForm(task) {
      Ember.set(this, 'formId', task.task.formId);
      Ember.set(this, "showFormModal", true);
      Ember.set(this, 'currentTaskForm', task);
    },
    goToEvent: function goToEvent(event) {
      this.router.transitionTo('talent.integration.events').then(function () {
        Ember.run.later(function () {
          var el = document.getElementById('event-' + event.id);
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }, 500);
      });
    },
    actions: {
      completeTask: function completeTask(task) {
        if (task.task) {
          if (!task.task.auto && !task.task.formId) {
            this.validateUnvalidateTask(task.task, true);
          } else if (task.task.field) {
            this.gotoCustomFieldSection(task.task.field);
          } else if (task.task.formId) {
            this.openForm(task); //this.gotoForm(task.task.formId);
          }
        } else if (task.event) {
          this.goToEvent(task.event);
        }
      },
      uncompleteTask: function uncompleteTask(task) {
        if (task.task) {
          if (!task.task.auto && !task.task.formId) {
            this.validateUnvalidateTask(task.task, false);
          }
        }
      },
      onPeriodFilter: function onPeriodFilter(filter, key) {
        Ember.set(this, "periodFilter", key);
      },
      onFormResponseSent: function onFormResponseSent() {
        this.taskValidated(this.currentTaskForm);
      }
    }
  });

  _exports.default = _default;
});