define("teelt-frontend/components/talents/detail/custom-field-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['custom-field-detail'],
    fileUploadService: Ember.inject.service('file-upload'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.field.type === 'multi_autocomplete_users' && this.fullValue) {
        this.set('fullValueArray', this.fullValue.split(';'));
      }
    },
    actions: {
      getFile: function getFile(url) {
        var _this = this;

        if (url == 'DELETED') return;
        var downloadWaitingUrl = window.location.protocol + "//" + window.location.host + "/downloading";
        var tabOpen = window.open(downloadWaitingUrl, 'newtab');
        this.fileUploadService.getObjectUrl(url).then(function (data) {
          var url = data.url + "&inline";
          tabOpen.location = url;
        }).catch(function (err) {
          _this.customErrorService.handleAjaxError(err);
        });
      },
      onLabelCkick: function onLabelCkick() {
        if (this.onLabelCkick) {
          this.onLabelCkick();
        }
      }
    }
  });

  _exports.default = _default;
});