define("teelt-frontend/routes/board/notification/notification-edit", ["exports", "teelt-frontend/routes/parent/confirm-before-transition"], function (_exports, _confirmBeforeTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _confirmBeforeTransition.default.extend({
    model: function model(params) {
      var parentModel = this._super(params);

      parentModel.notificationId = params.notification_id;
      parentModel.notification = this.store.findRecord('notification', params.notification_id, {
        reload: true
      });
      return Ember.RSVP.hash(parentModel);
    }
  });

  _exports.default = _default;
});