define("teelt-frontend/templates/board/admin/company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eyvsjsi3",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"company/companies-list\",null,[[\"openCompany\"],[[27,\"route-action\",[\"openCompany\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/admin/company.hbs"
    }
  });

  _exports.default = _default;
});