define("teelt-frontend/components/utils/scrollbottom-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    eventBus: Ember.inject.service('event-bus'),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      window.onscroll = function (ev) {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
          _this.eventBus.trigger('SCROLLBOTTOM');
        }
      };
    }
  });

  _exports.default = _default;
});