define("teelt-frontend/components/form/form-modal", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.loadForm();
    },
    loadForm: function loadForm() {
      var _this = this;

      var path = _environment.default.APP.API_HOST + '/forms/' + this.formId + '/response/' + this.talentId;
      this.ajax.requestPromise(path, 'GET').then(function (form) {
        if (_this.responseMode || form.isDraft || form.mustBeReviewed) {
          Ember.set(_this, 'form', form);
        } else {
          _this.store.findRecord('form', _this.formId).then(function (form) {
            Ember.set(_this, 'form', form);
          });
        }
      });
    },
    actions: {
      onClickOverlay: function onClickOverlay() {
        // prevent close from overlay click
        return;
      },
      close: function close() {
        Ember.set(this, 'show', false);
      },
      onResponseSent: function onResponseSent() {
        if (this.onResponseSent) this.onResponseSent();
      }
    }
  });

  _exports.default = _default;
});