define("teelt-frontend/routes/board/contents/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      if (!this.session.data.authenticated.user.company.canCreateHome) {
        this.transitionTo('board.contents.contents-list');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        company: this.store.findRecord('company', this.session.data.authenticated.user.company.id),
        showContents: true
      });
    }
  });

  _exports.default = _default;
});