define("teelt-frontend/templates/board/setting/periods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RwFTt+xf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"periods\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"section-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"g-section-title foreground-primary-color\"],[9],[0,\"\\n            \"],[7,\"h1\"],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"settings.periods.title\"],null]],null],false],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"baseline\"],[9],[1,[27,\"t\",[\"settings.periods.baseline\"],null],false],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col right add-period-block\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"board.setting.period-new\",[23,[\"model\",\"type\"]]],[[\"class\"],[\"new-period-button waves-effect waves-light btn-large background-primary-color\"]],{\"statements\":[[0,\"              \"],[7,\"i\"],[11,\"class\",\"material-icons left\"],[11,\"style\",\"float:left!important\"],[9],[0,\"add\"],[10],[0,\"\\n              \"],[1,[27,\"t\",[\"settings.periods.new_period\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[1,[27,\"settings/periods-list\",null,[[\"triggerDate\"],[[27,\"concat\",[[23,[\"model\",\"type\"]],\"Date\"],null]]]],false],[0,\"\\n\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/setting/periods.hbs"
    }
  });

  _exports.default = _default;
});