define("teelt-frontend/components/talents/all-talent-nb", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['all-talent-nb'],
    count: " ",
    ajax: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.ajax.requestPromise(_environment.default.APP.API_HOST + '/users/count/onboarding', 'GET').then(function (data) {
        Ember.set(_this, 'count', data.count);
      });
    }
  });

  _exports.default = _default;
});