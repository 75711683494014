define("teelt-frontend/templates/components/ihm/explore-office/explore-office-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jT7UPcSD",
    "block": "{\"symbols\":[\"field\",\"index\"],\"statements\":[[4,\"each\",[[23,[\"exploreoffice\",\"fields\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"ihm/explore-office/explore-office-field-preview\",null,[[\"field\",\"index\"],[[22,1,[]],[22,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/ihm/explore-office/explore-office-preview.hbs"
    }
  });

  _exports.default = _default;
});