define("teelt-frontend/components/notification/notification-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['notification-form'],
    session: Ember.inject.service(),
    activeSlack: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.session.data.authenticated.user.company.activeSlack) {
        Ember.set(this, 'activeSlack', true);
      }

      this.setEmailFromContents();
      this.setSmsContentFromContents();
      this.setSlackContentFromContents();
    },
    getEmailContentFromContents: function getEmailContentFromContents() {
      if (!this.notification.contents) return;

      for (var i = 0; i < this.notification.contents.length; i++) {
        var content = this.notification.contents[i];

        if (content.contentType === "EMAIL") {
          return this.notification.contents[i];
        }
      }
    },
    setEmailFromContents: function setEmailFromContents() {
      var _this = this;

      var emailContent = this.getEmailContentFromContents();

      if (emailContent) {
        Ember.set(this, "email", emailContent);

        if (emailContent.sourceCode) {
          setTimeout(function () {
            _this.initIframeContent(emailContent.sourceCode);
          }, 3000);
        }
      } else {
        var _emailContent = {
          contentType: "EMAIL",
          subject: "",
          body: "",
          disabled: true,
          replyTo: "",
          officeId: null,
          tagId: null,
          cc: ""
        };
        this.notification.contents.push(_emailContent);
        Ember.set(this, "email", _emailContent);
      }
    },
    setSmsContentFromContents: function setSmsContentFromContents() {
      var smsContent = this.getSmsContentFromContents();

      if (smsContent) {
        Ember.set(this, "sms", smsContent);
      } else {
        var _smsContent = {
          contentType: "SMS",
          body: "",
          disabled: true
        };
        this.notification.contents.push(_smsContent);
        Ember.set(this, "sms", _smsContent);
      }
    },
    getSmsContentFromContents: function getSmsContentFromContents() {
      if (!this.notification.contents) return;

      for (var i = 0; i < this.notification.contents.length; i++) {
        var content = this.notification.contents[i];
        if (content.contentType === "SMS") return this.notification.contents[i];
      }
    },
    setSlackContentFromContents: function setSlackContentFromContents() {
      var slackContent = this.getSlackContentFromContents();

      if (slackContent) {
        Ember.set(this, "slack", slackContent);
      } else {
        var _slackContent = {
          contentType: "SLACK",
          body: "",
          disabled: true
        };
        this.notification.contents.push(_slackContent);
        Ember.set(this, "slack", _slackContent);
      }
    },
    getSlackContentFromContents: function getSlackContentFromContents() {
      if (!this.notification.contents) return;

      for (var i = 0; i < this.notification.contents.length; i++) {
        var content = this.notification.contents[i];
        if (content.contentType === "SLACK") return this.notification.contents[i];
      }
    },
    initIframeContent: function initIframeContent(sourceCode) {
      var previewFrame = document.getElementById('preview-code-source-iframe');
      if (!previewFrame) return;
      var iframeDocument = previewFrame.contentDocument || previewFrame.contentWindow.document;
      iframeDocument.open();
      iframeDocument.write(sourceCode);
      iframeDocument.close();
    },
    actions: {
      toggleShowNotification: function toggleShowNotification(value) {
        if (value == "showEmail") {
          if (this.showEmail) {
            Ember.set(this, 'showEmail', false);
            return;
          }

          Ember.set(this, 'showEmail', true);
          Ember.set(this, 'showSms', false);
          Ember.set(this, 'showSlack', false);
        } else if (value == "showSms") {
          if (this.showSms) {
            Ember.set(this, 'showSms', false);
            return;
          }

          Ember.set(this, 'showEmail', false);
          Ember.set(this, 'showSlack', false);
          Ember.set(this, 'showSms', true);
        } else {
          if (this.showSlack) {
            Ember.set(this, 'showSlack', false);
            return;
          }

          Ember.set(this, 'showEmail', false);
          Ember.set(this, 'showSms', false);
          Ember.set(this, 'showSlack', true);
        }
      }
    }
  });

  _exports.default = _default;
});