define("teelt-frontend/routes/talent/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    afterModel: function afterModel(model, transition) {
      var sliderMenu = [];
      sliderMenu.push({
        name: this.intl.t('user.detail'),
        route: "talent.profile.detail"
      });
      var haveDocs = false;

      if (model.talent.company && model.talent.company.customFields) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = model.talent.company.customFields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var customField = _step.value;

            if (customField.section == 'talent-profile' && customField.type == 'file') {
              haveDocs = true;
              break;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      if (haveDocs) {
        sliderMenu.push({
          name: this.intl.t('user.documents'),
          route: "talent.profile.documents"
        });
      }

      var selectedMenu = this.getSelectedMenuFromTransition(transition);
      Ember.set(model, 'sliderMenu', sliderMenu);
      Ember.set(model, 'selectedMenu', selectedMenu);
    },
    getSelectedMenuFromTransition: function getSelectedMenuFromTransition(transition) {
      var selectedMenu = 0;

      if (transition && transition.targetName === "talent.profile.documents") {
        selectedMenu = 1;
      }

      return selectedMenu;
    },
    actions: {
      willTransition: function willTransition(transition) {
        var selectedMenu = this.getSelectedMenuFromTransition(transition);
        Ember.set(this.currentModel, "selectedMenu", selectedMenu);
      },
      goTo: function goTo(menuItem) {
        if (menuItem.queryParams) {
          this.router.transitionTo(menuItem.route, {
            queryParams: menuItem.queryParams
          });
        } else {
          this.router.transitionTo(menuItem.route);
        }
      }
    }
  });

  _exports.default = _default;
});