define("teelt-frontend/components/tool/add-tool-form", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    customErrorService: Ember.inject.service('custom-error'),
    uiUtils: Ember.inject.service(),
    newCategory: true,
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.tool) {
        var tool = this.store.createRecord('tool');
        Ember.set(this, 'tool', tool);
        this.loadCategories();
      } else {
        if (!this.tool.category) {
          Ember.set(this.tool, 'category', {});
        }

        Ember.set(this, 'newCategory', false);
        this.loadCategories();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var textareas = document.querySelector('.materialize-textarea');

      if (textareas) {
        M.textareaAutoResize(textareas);
      }
    },
    loadCategories: function loadCategories() {
      var _this = this;

      return this.store.findAll('statics/tool-category').then(function (categories) {
        if (categories) {
          Ember.set(_this, 'categories', categories);

          _this.uiUtils.initAutocomplete((0, _jquery.default)('#autocomplete-category'), categories, "name", function () {// set(this, "newPosition", false);
          });
        } else {
          Ember.set(_this, 'categories', []);
        }
      });
    },
    actions: {
      save: function save() {
        var _this2 = this;

        if (!this.validateTool()) return;
        this.tool.save().then(function (tool) {
          if (_this2.onFinish) {
            _this2.onFinish();
          }
        }).catch(function (err) {
          _this2.customErrorService.handleAjaxError(err);
        });
      },
      onCategoryChanged: function onCategoryChanged() {},
      onLogoChanged: function onLogoChanged(file) {
        var _this3 = this;

        if (!file) return;
        Ember.set(this, 'blockSave', true);
        this.fileUploadService.signAndUpload(file, false, function (e) {
          return _this3.onLogoProgress(e);
        }).then(function (fileUrl) {
          _this3.tool.set('logoUrl', fileUrl);
        }).finally(function () {
          Ember.set(_this3, 'blockSave', false);
        });
      }
    },
    onLogoProgress: function onLogoProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'logoProgress', progress);
      }
    },
    validateTool: function validateTool() {
      this.resetErrors();

      if (!this.tool.name) {
        Ember.set(this, 'nameIsNull', true);
        return false;
      }

      return true;
    },
    resetErrors: function resetErrors() {
      Ember.set(this, 'nameIsNull', undefined);
    }
  });

  _exports.default = _default;
});