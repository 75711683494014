define("teelt-frontend/routes/talent/integration/administratives", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      formId: ''
    },
    resetController: function resetController(controller) {
      Ember.set(this.currentModel, 'formId', null);
      controller.set('formId', null);
    },
    afterModel: function afterModel(model, params) {
      if (params.queryParams && params.queryParams.formId) {
        Ember.set(model, 'formId', params.queryParams.formId);
      }
    }
  });

  _exports.default = _default;
});