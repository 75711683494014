define("teelt-frontend/templates/components/utils/get-role-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YZd4ewCK",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"not-eq\",[[23,[\"role\"]],\"none\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"simple-user-role \",[27,\"role-assign-convert\",[[23,[\"role\"]]],null]]]],[12,\"data-tooltip\",[27,\"if\",[[23,[\"tooltip\"]],[23,[\"label\"]]],null]],[12,\"data-position\",[27,\"if\",[[23,[\"tooltip\"]],[23,[\"tooltip\"]]],null]],[9],[0,\"\\n\"],[4,\"if\",[[27,\"or\",[[23,[\"forceAcronym\"]],[23,[\"acronym\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"if\",[[23,[\"forceAcronym\"]],[23,[\"forceAcronym\"]],[23,[\"acronym\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"t-withdefault\",[[27,\"concat\",[\"task.assign.icon.\",[27,\"role-assign-convert\",[[23,[\"role\"]]],null]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/get-role-icon.hbs"
    }
  });

  _exports.default = _default;
});