define("teelt-frontend/components/dashboard/email-not-sent-alert", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['email-not-sent-alert'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    count: 0,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.set(this, 'loading', true);
      var params = {
        complexFilters: JSON.stringify([{
          key: 'emailIncident',
          type: 'boolean',
          operator: 'EQUAL',
          value1: 'true'
        }])
      };
      this.ajax.requestWithUrlParamPromise(_environment.default.APP.API_HOST + '/users', 'GET', params).then(function (users) {
        if (!users || users.length === 0) return;
        var getCountPromises = users.map(function (user) {
          var path = _environment.default.APP.API_HOST + "/activities/users/" + user.id;
          return _this.ajax.requestPromise(path).then(function (groups) {
            var count = 0;
            groups.forEach(function (group) {
              if (group.activities) {
                group.activities.forEach(function (activity) {
                  if (activity.activityType === 'email_blocked' || activity.activityType === 'email_bounce' || activity.activityType === 'email_spam' || activity.activityType === 'email_invalid') {
                    count++;
                  }
                });
              }
            });
            return count;
          });
        });
        return Promise.all(getCountPromises).then(function (countArray) {
          var totalCount = countArray.reduce(function (total, count) {
            return total + count;
          }, 0);
          Ember.set(_this, 'count', totalCount);
        });
      }).finally(function () {
        Ember.set(_this, 'loading', false);
      });
    },
    actions: {
      gotoList: function gotoList() {
        this.router.transitionTo('board.talents', {
          queryParams: {
            complexFilter: 'emailIncident'
          }
        }).then(function () {
          window.location.reload();
        });
      }
    }
  });

  _exports.default = _default;
});