define("teelt-frontend/components/template/table/templates-custom-table-content", ["exports", "teelt-frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['templates-custom-table-content'],
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.key == 'users') {
        var path = _environment.default.APP.API_HOST + '/templates/' + this.result.id + '/users';
        this.ajax.requestPromise(path).then(function (users) {
          Ember.set(_this, 'users', users);
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('.template-actions').hide();
    },
    actions: {
      edit: function edit() {
        Ember.set(this, 'showActions', false);
        this.router.transitionTo('board.experience.experience-edit', this.result.id);
      },
      assign: function assign() {
        Ember.set(this, 'showActions', false);
        this.router.transitionTo('board.experience.experience-edit', this.result.id).then(function (route) {
          route.setForceTemplatePage('talents');
        });
      },
      duplicate: function duplicate() {
        var _this2 = this;

        Ember.set(this, 'showActions', false);
        Ember.set(this, 'isLoading', true);
        this.ajax.requestPromise(_environment.default.APP.API_HOST + '/templates/' + this.result.id + '/duplicate', 'POST').then(function (savedTemplate) {
          Ember.set(_this2, 'isLoading', false);

          _this2.router.transitionTo('board.experience.experience-edit', savedTemplate.id);
        }).catch(function () {
          Ember.set(_this2, 'isLoading', false);

          _this2.notify.error(_this2.intl.t('template.errors.error_500'), {
            closeAfter: 5000
          });
        });
      },
      makeTemplate: function makeTemplate(response) {
        var _this3 = this;

        if (!response) return;
        Ember.set(this, 'showActions', false);
        Ember.set(this, 'isLoading', true);
        this.ajax.requestPromise(_environment.default.APP.API_HOST + '/templates/' + this.result.id + '/model', 'POST').then(function (savedTemplate) {
          Ember.set(_this3, 'isLoading', false);

          _this3.router.transitionTo('board.experience.experience-edit', savedTemplate.id);
        }).catch(function () {
          Ember.set(_this3, 'isLoading', false);

          _this3.notify.error(_this3.intl.t('template.errors.error_500'), {
            closeAfter: 5000
          });
        });
      },
      prepareDeleteExperience: function prepareDeleteExperience() {
        Ember.set(this, 'showActions', false);
        Ember.set(this, 'experienceToDelete', this.result);
      },
      removeExperience: function removeExperience(response) {
        var _this4 = this;

        if (!response) return;
        this.experienceToDelete.deleteRecord();
        this.experienceToDelete.save().then(function () {
          _this4.eventBus.trigger('REFRESH_EXPERIENCES');
        });
      },
      openDelegateModal: function openDelegateModal() {
        var _this5 = this;

        Ember.set(this, 'selectedDelegate', undefined);
        var params = {
          complexFilters: JSON.stringify([{
            "key": "role",
            "type": "select",
            "operator": "IN",
            "value1": "ROLE_MRH"
          }])
        };
        this.ajax.requestWithUrlParamPromise(_environment.default.APP.API_HOST + '/users', 'GET', params).then(function (adminMRHs) {
          var list = [{
            id: -1
          }].concat(adminMRHs);
          Ember.set(_this5, 'adminMRHs', list);
          Ember.set(_this5, 'delegateModal', true);
          setTimeout(function () {
            (0, _jquery.default)('#adminmrh-list').formSelect();
          });
        });
      },
      onDelegateChanged: function onDelegateChanged(adminID) {
        Ember.set(this, 'selectedDelegate', adminID);
      },
      delegate: function delegate() {
        var _this6 = this;

        if (!this.selectedDelegate || this.selectedDelegate < 0) return;
        Ember.set(this, 'delegateModal', false);
        var path = _environment.default.APP.API_HOST + "/templates/" + this.result.id + "/delegate/" + this.selectedDelegate;
        this.ajax.requestPromise(path, 'PATCH').then(function () {
          _this6.notify.info(_this6.intl.t('word.modif_confirmed'), {
            closeAfter: 5000
          });
        }).catch(function () {
          _this6.notify.error(_this6.intl.t('template.errors.error_500'), {
            closeAfter: 5000
          });
        });
      },
      toggleStatus: function toggleStatus(template, status) {
        var _this7 = this;

        var path = _environment.default.APP.API_HOST + "/templates/" + template.id + "/toggle-status/" + status;
        this.ajax.requestPromise(path, 'PATCH').then(function (res) {
          Ember.set(template, 'status', res);
          Ember.set(_this7, 'showActions', false);

          _this7.notify.info(_this7.intl.t('word.modif_confirmed'), {
            closeAfter: 5000
          });
        }).catch(function () {
          _this7.notify.error(_this7.intl.t('template.errors.error_500'), {
            closeAfter: 5000
          });
        });
      }
    }
  });

  _exports.default = _default;
});