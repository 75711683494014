define("teelt-frontend/components/talents/talent-information-block-tasks", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var result = [];
      this.talent.remainingTalentTasks.forEach(function (task) {
        result.push({
          task: task
        });
      });
      this.talent.remainingEvents.forEach(function (event) {
        _this.completeEventDesc(event);

        result.push({
          event: event
        });
      });
      result = result.slice(0, 4);
      Ember.set(this, 'tasks', result);
    },
    completeEventDesc: function completeEventDesc(event) {
      var start = event.allDay ? _moment.default.utc(event.start).startOf('day') : _moment.default.utc(event.start);
      var descComplete = '';

      if (event.allDay) {
        descComplete += this.intl.t('word.all_the_day');
        Ember.set(event, 'date', start.format('DD/MM'));
      } else {
        Ember.set(event, 'date', start.format('DD/MM - HH[h]mm'));
      }

      if (event.room) {
        descComplete += descComplete === '' ? event.room : ' - ' + event.room;
      }

      if (event.description) {
        descComplete += descComplete === '' ? event.description : ' - ' + event.description;
      }

      Ember.set(event, 'descComplete', descComplete);
    }
  });

  _exports.default = _default;
});