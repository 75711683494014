define("teelt-frontend/templates/components/custom-field/field/custom-autocomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fVlHN97Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"not\",[[23,[\"waitingTalent\"]]],null]],null,{\"statements\":[[1,[27,\"utils/searchable-select\",null,[[\"searchPath\",\"selectedValue\",\"defaultValue\",\"keyAtt\",\"keyValue\",\"multiple\",\"onValueChange\"],[[23,[\"searchPath\"]],[23,[\"selectedValue\"]],[27,\"talent-name\",[[23,[\"talent\"]]],null],\"id\",\"value\",false,[27,\"action\",[[22,0,[]],\"onSelectChanged\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/custom-field/field/custom-autocomplete.hbs"
    }
  });

  _exports.default = _default;
});