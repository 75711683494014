define("teelt-frontend/templates/components/icon/flag-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ua89X2Bw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"width\",\"11\"],[11,\"height\",\"14\"],[11,\"viewBox\",\"0 0 11 14\"],[11,\"fill\",\"none\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[9],[0,\"\\n\"],[7,\"path\"],[11,\"d\",\"M3.99848 0.796098C3.81277 0.858002 3.33301 1.13657 2.93063 1.41514L2.20325 1.94134L2.2961 5.9961L2.38896 10.0663H1.67706C1.07348 10.0663 0.903246 10.1282 0.516341 10.5461C0.160389 10.964 0.0830078 11.1806 0.0830078 11.8771C0.0830078 13.1151 0.144913 13.1306 2.66753 12.9294C3.82825 12.8211 4.80325 12.7282 4.8342 12.6818C4.86515 12.6509 4.92706 12.2794 4.98896 11.8461C5.06634 11.1187 5.03539 11.0259 4.60206 10.5925C4.23063 10.2056 3.99848 10.1128 3.50325 10.1128H2.86872V9.02943V7.96157L3.5342 7.55919C4.52467 6.98657 5.11277 7.04848 6.18063 7.80681C7.72825 8.90562 8.96634 9.09134 10.3437 8.39491L10.9163 8.10086V4.99015C10.9163 2.51395 10.8699 1.87943 10.6997 1.81753C10.5913 1.7711 10.1271 1.84848 9.69372 2.00324C8.62586 2.34372 7.8211 2.21991 6.83063 1.53895C5.60801 0.70324 4.92706 0.533002 3.99848 0.796098ZM6.22706 2.26634C7.21753 2.97824 7.97586 3.21038 9.07467 3.10205L9.98777 3.02467V5.33062C9.98777 7.52824 9.97229 7.63657 9.66277 7.80681C9.04372 8.13181 7.89848 8.02348 7.09372 7.55919C5.39134 6.56872 5.28301 6.53777 4.58658 6.59967C4.23063 6.6461 3.71991 6.81634 3.47229 7.00205L3.02348 7.34253V4.86634V2.40562L3.45682 2.12705C3.68896 1.95681 3.98301 1.78657 4.10682 1.72467C4.5711 1.49253 5.49967 1.72467 6.22706 2.26634ZM4.16872 11.1342C4.30801 11.258 4.41634 11.5056 4.41634 11.6604C4.41634 11.8151 4.46277 12.0628 4.52467 12.2021C4.60206 12.4342 4.52467 12.4651 3.8592 12.3878C3.44134 12.3259 2.63658 12.2485 2.06396 12.2021C1.11991 12.1092 1.01158 12.0628 1.01158 11.7687C1.01158 11.0723 1.29015 10.9485 3.05444 10.9021C3.67348 10.8866 3.99848 10.964 4.16872 11.1342Z\"],[11,\"fill\",\"black\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/icon/flag-svg.hbs"
    }
  });

  _exports.default = _default;
});