define("teelt-frontend/templates/components/form/forms-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fxvM1HEv",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[4,\"each\",[[23,[\"forms\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"form/forms-list-table-element\",null,[[\"form\",\"actionLabel\",\"actionAction\"],[[22,1,[]],[23,[\"actionLabel\"]],[23,[\"actionAction\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/form/forms-list-table.hbs"
    }
  });

  _exports.default = _default;
});