define("teelt-frontend/routes/parent/confirm-before-transition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return {
        saved: false,
        showConfirmNotSavedModal: false
      };
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (this.currentModel && this.currentModel.saved === false) {
          Ember.set(this.currentModel, 'showConfirmNotSavedModal', true);
          Ember.set(this, 'attemptedTransition', transition);
          transition.abort();
        } else {
          return true;
        }
      },
      onTransitionConfirmed: function onTransitionConfirmed() {
        if (!this.attemptedTransition) return;
        Ember.set(this.currentModel, 'saved', true);
        this.attemptedTransition.retry();
      }
    }
  });

  _exports.default = _default;
});