define("teelt-frontend/components/module/modules-list", ["exports", "teelt-frontend/config/environment", "teelt-frontend/utils/jquery-utils"], function (_exports, _environment, _jqueryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend((_EmberComponent$exte = {
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    notify: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    customErrorService: Ember.inject.service('custom-error'),
    fileUploadService: Ember.inject.service('file-upload'),
    session: Ember.inject.service(),
    classNames: ['modules-list'],
    canLoadMore: true,
    page: 0,
    offset: 0,
    limit: 20,
    allModules: [],
    allPages: 0,
    showAllPages: false,
    updatePages: true,
    query: '',
    audienceQuery: '',
    toolQuery: '',
    templateQuery: '',
    filtersLength: 0,
    audienceFiltersLength: 0,
    toolFiltersLength: 0,
    templateFiltersLength: 0,
    onlyOneFilterSelected: {
      isActive: false,
      selected: '',
      subFiltersLength: 0,
      canShowSubFilters: false,
      canShowAudienceFilters: false,
      canShowToolFilters: false
    },
    audienceFilters: {
      all: false
    },
    toolFilters: {
      all: false
    },
    templateFilters: {
      all: false
    },
    formFilters: {
      all: false,
      QUIZZ: false,
      ASTONISHMENT: false,
      FEEDBACK: false,
      MANAGER_INTERVIEW: false
    },
    notificationFilters: {
      all: false,
      EMAIL: false,
      SMS: false
    },
    contentFilters: {
      all: false,
      textcard: false,
      filecard: false,
      video: false,
      map: false,
      slideshow: false
    }
  }, _defineProperty(_EmberComponent$exte, "toolFilters", {
    all: false,
    Administratif: false,
    Collaboration: false,
    Communication: false,
    ressourceHumaines: false
  }), _defineProperty(_EmberComponent$exte, "filters", {
    all: true,
    form: true,
    notification: true,
    administrative: true,
    tool: true,
    content: true,
    none: true
  }), _defineProperty(_EmberComponent$exte, "modules", Ember.computed('allModules.length', 'page', 'limit', 'canLoadMore', function () {
    var uniques = this.allModules.reduce(function (filter, current) {
      var dk = filter.find(function (item) {
        return item.id === current.id && item.moduleType === current.moduleType;
      });

      if (!dk) {
        return filter.concat([current]);
      } else {
        return filter;
      }
    }, []);
    var modules = uniques.slice(this.page * this.limit, this.page * this.limit + 16);

    if (this.canLoadMore && modules.length < 16) {
      this.loadMoreModules();
    }

    return modules;
  })), _defineProperty(_EmberComponent$exte, "lastPage", Ember.computed('allModules.length', 'modules', 'page', function () {
    return this.page >= this.allModules.length / 16 || this.modules.length < 16;
  })), _defineProperty(_EmberComponent$exte, "filtersLength", Ember.computed('filters', function () {
    if (!this.filters.all) {
      var filters = [];

      for (var f in this.filters) {
        if (f !== 'all' && this.filters[f]) {
          filters.push(f);
        }
      }

      return filters.length;
    }

    return 0;
  })), _defineProperty(_EmberComponent$exte, "updatePagesLinks", function updatePagesLinks() {
    var nbPages = Math.ceil(this.allModules.length / 16);
    var minPage = Math.max(this.page - 3, 0);
    var maxPage = Math.min(this.page + 3, nbPages - 1);
    var pages = [];

    for (var i = minPage; i <= maxPage; i++) {
      pages.push(i);
    }

    Ember.set(this, 'pages', pages);
  }), _defineProperty(_EmberComponent$exte, "showCurrentPage", function showCurrentPage() {
    var allDatas = this.allModules;
    var results = allDatas.slice(this.page * 16, (this.page + 1) * 16);
    Ember.set(this, 'modules', results);

    if (this.modules.length < 16) {
      this.loadMoreModules();
    }

    this.updatePagesLinks();
  }), _defineProperty(_EmberComponent$exte, "init", function init() {
    this._super.apply(this, arguments);

    this.showAudiences();
    this.showToolCategorie();
    this.showTemplates();

    if (this.session.data.authenticated.user.company.activeSignContent && !this.filters.hasOwnProperty('sign')) {
      Ember.set(this.filters, 'sign', true);
    }

    if (this.session.data.authenticated.user.company.activeMeetPeople && !this.filters.hasOwnProperty('meetPeople')) {
      Ember.set(this.filters, 'meetPeople', true);
    }

    if (this.session.data.authenticated.user.company.activeExploreOffice && !this.filters.hasOwnProperty('exploreOffice')) {
      Ember.set(this.filters, 'exploreOffice', true);
    }
  }), _defineProperty(_EmberComponent$exte, "destroy", function destroy() {
    this.eventBus.off('CLICK_EVENT', this, 'checkToCloseModal');

    this._super.apply(this, arguments);
  }), _defineProperty(_EmberComponent$exte, "checkToCloseModal", function checkToCloseModal(e) {
    if (!(0, _jqueryUtils.default)().checkIfClickInElement(e, ["possible-filters", "possible-journeys", "possible-audiences", "possible-tools", "possible-sub-filters", "module-actions-dialog", "possible-filters-btn", "possible-journeys-btn", "possible-audiences-btn", "possible-tools-btn", "possible-sub-filters-btn", "module-actions-dialog-btn", "possible-journeys-list"])) {
      Ember.set(this, 'showPossibleFilters', false);
      Ember.set(this, 'showPossibleJourney', false);
      Ember.set(this, 'showPossibleAudienceFilters', false);
      Ember.set(this, 'showPossibleTool', false);
      Ember.set(this, 'showPossibleSubFilters', false);

      if (this.showActions) {
        Ember.set(this, 'showActions', false);
      }
    }
  }), _defineProperty(_EmberComponent$exte, "didInsertElement", function didInsertElement() {
    var _this = this;

    this._super.apply(this, arguments);

    setTimeout(function () {
      _this.eventBus.on('CLICK_EVENT', _this, 'checkToCloseModal');
    }, 300);
  }), _defineProperty(_EmberComponent$exte, "loadMoreModules", function loadMoreModules() {
    var _this2 = this;

    var path = _environment.default.APP.API_HOST + '/modules?search=' + this.query + '&offset=' + this.offset + "&limit=" + this.limit;

    if (!this.filters.all) {
      var filters = []; //get seleted filters

      for (var f in this.filters) {
        if (f !== 'all' && this.filters[f]) {
          filters.push(f);
        }
      } //if no filter selected, clean all filters and subFilters and audiences Filters


      if (filters.length == 0) {
        this.cleanResults();

        if (this.onlyOneFilterSelected.selected) {
          var filterType = this.onlyOneFilterSelected.selected + "Filters";

          for (var _f in this[filterType]) {
            Ember.set(this[filterType], _f, false);
          }

          for (var _f2 in this.audienceFilters) {
            Ember.set(this.audienceFilters, _f2, false);
          }
        }

        this.cleanFilters();
        return;
      } //add selected filters to query path


      var filtersTmp = filters.join(',');
      path += '&filters=' + encodeURI(filtersTmp); //if only one filter selected, show subFilters buttons and audience button

      if (filters.length == 1) {
        Ember.set(this, 'onlyOneFilterSelected.isActive', true);
        Ember.set(this, 'onlyOneFilterSelected.selected', filters[0]);

        if (['form', 'notification', 'content'].includes(this.onlyOneFilterSelected.selected)) {
          Ember.set(this, 'onlyOneFilterSelected.canShowSubFilters', true);
        } else {
          Ember.set(this, 'onlyOneFilterSelected.canShowSubFilters', false);
        } //show audience filters for notification and content


        if (['notification', 'content', 'tool'].includes(this.onlyOneFilterSelected.selected)) {
          Ember.set(this, 'onlyOneFilterSelected.canShowAudienceFilters', true);
          var audienceFilters = [];

          for (var _f3 in this.audienceFilters) {
            if (_f3 !== 'all' && this.audienceFilters[_f3]) {
              audienceFilters.push(_f3);
            }
          }

          Ember.set(this, 'audienceFiltersLength', audienceFilters.length);

          if (audienceFilters.length > 0) {
            var _filtersTmp = audienceFilters.join(',');

            path += '&audienceFilters=' + encodeURI(_filtersTmp);
          }
        } else {
          Ember.set(this, 'onlyOneFilterSelected.canShowAudienceFilters', false);
        } //show categorie filters for tool


        if (this.onlyOneFilterSelected.selected === 'tool') {
          Ember.set(this, 'onlyOneFilterSelected.canShowToolFilters', true);
          var toolFilters = [];

          for (var _f4 in this.toolFilters) {
            if (_f4 !== 'all' && this.toolFilters[_f4]) {
              toolFilters.push(_f4);
            }
          }

          Ember.set(this, 'toolFiltersLength', toolFilters.length);
        } else {
          Ember.set(this, 'onlyOneFilterSelected.canShowToolFilters', false);
        } //get selected subFilter


        var _filterType = this.onlyOneFilterSelected.selected + "Filters";

        var filterArray = this[_filterType];

        if (_filterType !== "filters" && _filterType) {
          var subFilters = [];

          for (var _f5 in filterArray) {
            if (_f5 !== 'all' && filterArray[_f5]) {
              subFilters.push(_f5);
            }
          }

          Ember.set(this, 'onlyOneFilterSelected.subFiltersLength', subFilters.length); // add selected subFilters attributes to query path

          if (subFilters.length > 0) {
            var _filtersTmp2 = subFilters.join(',');

            path += '&' + _filterType + '=' + encodeURI(_filtersTmp2);
          }
        }
      } else {
        // if more than one filter selected
        var _filterType2 = this.onlyOneFilterSelected.selected + "Filters";

        if (this.onlyOneFilterSelected.selected) {
          for (var _f6 in this[_filterType2]) {
            Ember.set(this[_filterType2], _f6, false);
          }

          for (var _f7 in this.audienceFilters) {
            Ember.set(this.audienceFilters, _f7, false);
          }
        }

        this.cleanFilters();
      }
    } else {
      // if all filters selected
      var _filterType3 = this.onlyOneFilterSelected.selected + "Filters";

      if (this.onlyOneFilterSelected.selected) {
        for (var _f8 in this[_filterType3]) {
          Ember.set(this[_filterType3], _f8, false);
        }

        for (var _f9 in this.audienceFilters) {
          Ember.set(this.audienceFilters, _f9, false);
        }
      }

      this.cleanFilters();
    } //add selected template to query path


    var templateFilters = [];

    for (var _f10 in this.templateFilters) {
      if (_f10 !== 'all' && this.templateFilters[_f10]) {
        templateFilters.push(_f10);
      }
    }

    if (templateFilters.length > 0) {
      var _filtersTmp3 = templateFilters.join(',');

      path += '&templateFilters=' + encodeURI(_filtersTmp3);
    }

    Ember.set(this, 'templateFiltersLength', templateFilters.length);
    this.ajax.requestPromise(path, 'GET').then(function (modules) {
      Ember.set(_this2, 'offset', _this2.offset + _this2.limit);
      var currentModules = _this2.allModules;

      if (!currentModules) {
        currentModules = modules;
        Ember.set(_this2, 'allModules', currentModules);
      } else {
        if (modules && modules.length > 0) {
          if (_this2.allPages != 0) {
            Ember.set(_this2, 'updatePages', false);
          }

          currentModules = currentModules.concat(modules);
          Ember.set(_this2, 'allModules', currentModules);

          _this2.showCurrentPage();
        } else {
          Ember.set(_this2, "canLoadMore", false);
        }
      }
    });
  }), _defineProperty(_EmberComponent$exte, "cleanResults", function cleanResults() {
    Ember.set(this, 'canLoadMore', true);
    Ember.set(this, 'offset', 0);
    Ember.set(this, 'page', 0);
    Ember.set(this, 'allModules', []);
  }), _defineProperty(_EmberComponent$exte, "cleanFilters", function cleanFilters() {
    //clean subFilters
    Ember.set(this, 'onlyOneFilterSelected.isActive', false);
    Ember.set(this, 'onlyOneFilterSelected.selected', "");
    Ember.set(this, 'onlyOneFilterSelected.subFiltersLength', 0);
    Ember.set(this, 'onlyOneFilterSelected.canShowSubFilters', false);
    Ember.set(this, 'onlyOneFilterSelected.canShowAudienceFilters', false);
    Ember.set(this, 'onlyOneFilterSelected.canShowToolFilters', false);
    Ember.set(this, 'audienceFiltersLength', 0);
    Ember.set(this, 'templateFiltersLength', 0);
    Ember.set(this, 'toolFiltersLength', 0);
  }), _defineProperty(_EmberComponent$exte, "showAudiences", function showAudiences() {
    var _this3 = this;

    var audiencePath = _environment.default.APP.API_HOST + '/audiences?search=' + this.audienceQuery;
    Ember.set(this, 'audienceFiltersLength', 0);

    for (var key in this.audienceFilters) {
      if (key !== 'all') {
        delete this.audienceFilters[key];
      } else {
        Ember.set(this.audienceFilters, key, false);
      }
    }

    this.ajax.requestPromise(audiencePath, 'GET').then(function (audiences) {
      if (audiences) {
        audiences.forEach(function (element) {
          Ember.set(_this3.audienceFilters, element.name, false);
        });
      }
    });
    this.cleanResults();
  }), _defineProperty(_EmberComponent$exte, "showTemplates", function showTemplates() {
    var _this4 = this;

    var templatePath = _environment.default.APP.API_HOST + '/templates?search=' + this.templateQuery;
    Ember.set(this, 'templateFiltersLength', 0);

    for (var key in this.templateFilters) {
      if (key !== 'all') {
        delete this.templateFilters[key];
      } else {
        Ember.set(this.templateFilters, key, false);
      }
    }

    this.ajax.requestPromise(templatePath, 'GET').then(function (templates) {
      if (templates) {
        templates.forEach(function (element) {
          Ember.set(_this4.templateFilters, element.name, false);
        });
      }
    });
    this.cleanResults();
  }), _defineProperty(_EmberComponent$exte, "showToolCategorie", function showToolCategorie() {
    var _this5 = this;

    var toolPath = _environment.default.APP.API_HOST + '/statics/tool-category?search=' + this.toolQuery;
    Ember.set(this, 'toolFiltersLength', 0);

    for (var key in this.toolFilters) {
      if (key !== 'all') {
        delete this.toolFilters[key];
      } else {
        Ember.set(this.toolFilters, key, false);
      }
    }

    this.ajax.requestPromise(toolPath, 'GET').then(function (tools) {
      if (tools) {
        tools.forEach(function (element) {
          Ember.set(_this5.toolFilters, element.name, false);
        });
      }
    });
    this.cleanResults();
  }), _defineProperty(_EmberComponent$exte, "openFileCard", function openFileCard(content) {
    var _this6 = this;

    if (!content.fullValue || content.fullValue.trim() == '') return; // WARNING : Always open tab before fetch temporary url because Firefox authorize opened tab by user click only and not in promise resolve

    Ember.set(this, "showDownloadingModal", true);
    this.fileUploadService.getObjectUrl(content.fullValue).then(function (data) {
      var url = data.url;

      if (!content.toDownload) {
        url += "&inline";
      }

      window.open(url, 'newtab');
      Ember.set(_this6, "showDownloadingModal", false);
    }).catch(function (err) {
      _this6.customErrorService.handleAjaxError(err);
    });
  }), _defineProperty(_EmberComponent$exte, "actions", {
    onClickOverlay: function onClickOverlay() {
      Ember.set(this, 'showPreviewDialog', false);
      Ember.set(this, 'showTemplatesDialog', false);
    },
    showModule: function showModule(module) {
      var _this7 = this;

      var model = module.moduleType;

      if (module.moduleType == 'administrative') {
        model = 'form';
      } else if (module.moduleType == 'sign') {
        model = 'signcontent';
      } else if (module.moduleType == 'meetPeople') {
        model = 'meetpeople';
      } else if (module.moduleType == 'exploreOffice') {
        model = 'exploreoffice';
      }

      this.store.findRecord(model, module.id, {
        reload: true
      }).then(function (result) {
        Ember.set(_this7, 'previewType', module.moduleType);
        Ember.set(_this7, 'preview', result);

        if (module.moduleType != 'content') {
          Ember.set(_this7, 'showPreviewDialog', true);
        } else {
          if (_this7.preview.type === "textcard" || _this7.preview.type === "video" || _this7.preview.type === "slideshow") {
            Ember.set(_this7, 'showContentDetailPopup', true);
          } else if (_this7.preview.type === "filecard") {
            _this7.openFileCard(result);
          }
        }
      });
    },
    showModuleTemplates: function showModuleTemplates(module) {
      Ember.set(this, 'showTemplatesDialog', true);
      Ember.set(this, 'moduleToShow', module);
    },
    onSearchKeyUp: function onSearchKeyUp() {
      var _this8 = this;

      if (this.keyUpAction) {
        Ember.run.cancel(this.keyUpAction);
      }

      var keyUpAction = Ember.run.later(function () {
        _this8.cleanResults();

        _this8.showCurrentPage();
      }, 300);
      Ember.set(this, 'keyUpAction', keyUpAction);
    },
    onAudienceSearchKeyUp: function onAudienceSearchKeyUp() {
      var _this9 = this;

      if (this.keyUpAction) {
        Ember.run.cancel(this.keyUpAction);
      }

      var keyUpAction = Ember.run.later(function () {
        _this9.showAudiences();
      }, 300);
      Ember.set(this, 'keyUpAction', keyUpAction);
    },
    onTemplateSearchKeyUp: function onTemplateSearchKeyUp() {
      var _this10 = this;

      if (this.keyUpAction) {
        Ember.run.cancel(this.keyUpAction);
      }

      var keyUpAction = Ember.run.later(function () {
        _this10.showTemplates();
      }, 300);
      Ember.set(this, 'keyUpAction', keyUpAction);
    },
    onToolSearchKeyUp: function onToolSearchKeyUp() {
      var _this11 = this;

      if (this.keyUpAction) {
        Ember.run.cancel(this.keyUpAction);
      }

      var keyUpAction = Ember.run.later(function () {
        _this11.showToolCategorie();
      }, 300);
      Ember.set(this, 'keyUpAction', keyUpAction);
    },
    changePage: function changePage(newPage) {
      if (newPage < 0 || newPage * 16 >= this.allModules.length) return;
      Ember.set(this, 'page', newPage);
      this.showCurrentPage();
    },
    openModuleActions: function openModuleActions(module) {
      var id = module.moduleType + '-' + module.id;

      if (this.showActions == id) {
        Ember.set(this, 'showActions', false);
      } else {
        Ember.set(this, 'showActions', id);
      }
    },
    closeModuleActions: function closeModuleActions() {
      $(document).off('click');
      Ember.set(this, 'showActions', false);
    },
    prepareDeleteModule: function prepareDeleteModule(module) {
      if (module.templates && module.templates.length > 0) return;
      Ember.set(this, 'moduleToDelete', module);
    },
    removeModule: function removeModule(response) {
      var _this12 = this;

      if (response) {
        var moduleId = this.moduleToDelete.id;
        var path = _environment.default.APP.API_HOST + '/modules/' + this.moduleToDelete.moduleType + '/' + moduleId;
        this.ajax.requestPromise(path, 'DELETE').then(function () {
          _this12.cleanResults();

          _this12.loadMoreModules();
        }).catch(function (error) {
          _this12.notify.error(_this12.intl.t('module.no_delete_if_template'), {
            closeAfter: 5000
          });
        });
      }
    },
    filter: function filter(_filter, filterType) {
      var filters = this.get(filterType);
      var enable = !filters[_filter];

      if (_filter == "all" && filters[_filter]) {
        for (var f in filters) {
          Ember.set(filters, f, false);
        }
      }

      if (enable && _filter == 'all') {
        for (var _f11 in filters) {
          Ember.set(filters, _f11, true);
        }
      } else {
        Ember.set(filters, _filter, enable);
        var allEnable = true;

        for (var _f12 in filters) {
          if (!filters[_f12] && _f12 !== 'all') {
            allEnable = false;
            break;
          }
        }

        Ember.set(filters, 'all', allEnable);
        Ember.set(this, filterType, undefined);
        Ember.set(this, filterType, filters);
      }

      this.cleanResults(); //this.loadMoreModules();

      this.showCurrentPage();
    },
    editModule: function editModule(module) {
      var _this13 = this;

      Ember.set(this, 'showActions', false);

      switch (module.moduleType) {
        case 'form':
          this.store.findRecord('form', module.id, {
            reload: true
          }).then(function (form) {
            Ember.set(_this13, 'updateModuleType', 'form');
            Ember.set(_this13, 'updateModule', form);
          });
          break;

        case 'administrative':
          this.store.findRecord('form', module.id, {
            reload: true
          }).then(function (form) {
            Ember.set(_this13, 'updateModuleType', 'administrative');
            Ember.set(_this13, 'updateModule', form);
          });
          break;

        case 'notification':
          this.store.findRecord('notification', module.id, {
            reload: true
          }).then(function (notif) {
            Ember.set(_this13, 'updateModuleType', 'notification');
            Ember.set(_this13, 'updateModule', notif);
          });
          break;

        case 'content':
          this.store.findRecord('content', module.id, {
            reload: true
          }).then(function (content) {
            Ember.set(_this13, 'updateModuleType', 'content');
            Ember.set(_this13, 'updateModule', content);
          });
          break;

        case 'tool':
          this.store.findRecord('tool', module.id, {
            reload: true
          }).then(function (tool) {
            Ember.set(_this13, 'updateModuleType', 'tool');
            Ember.set(_this13, 'updateModule', tool);
          });
          break;

        case 'sign':
          this.store.findRecord('signcontent', module.id, {
            reload: true
          }).then(function (sign) {
            Ember.set(_this13, 'updateModuleType', 'sign');
            Ember.set(_this13, 'updateModule', sign);
          });
          break;

        case 'meetPeople':
          this.store.findRecord('meetpeople', module.id, {
            reload: true
          }).then(function (meetPeople) {
            Ember.set(_this13, 'updateModuleType', 'meetPeople');
            Ember.set(_this13, 'updateModule', meetPeople);
          });
          break;

        case 'exploreOffice':
          this.store.findRecord('exploreoffice', module.id, {
            reload: true
          }).then(function (exploreOffice) {
            Ember.set(_this13, 'updateModuleType', 'exploreOffice');
            Ember.set(_this13, 'updateModule', exploreOffice);
          });
          break;
      }
    },
    duplicateModule: function duplicateModule(module) {
      var _this14 = this;

      Ember.set(this, 'showActions', false);
      var moduleType = "";

      switch (module.moduleType) {
        case 'administrative':
          moduleType = 'form';
          break;

        case 'meetPeople':
          moduleType = 'meetpeople';
          break;

        case 'exploreOffice':
          moduleType = 'exploreoffice';
          break;

        default:
          moduleType = module.moduleType;
          break;
      }

      this.store.findRecord(moduleType, module.id, {
        reload: true
      }).then(function (moduleToDuplicate) {
        var moduleJSON = moduleToDuplicate.toJSON();
        moduleJSON['id'] = undefined;

        if (moduleJSON.contents) {
          moduleJSON.contents.forEach(function (content) {
            content.id = null;
          });
        }

        var prefix = _this14.intl.t("module.duplicate.prefix");

        var newModuleName = '';

        if (['notification', 'tool'].includes(module.moduleType)) {
          newModuleName = prefix + " " + moduleJSON['name'];
          moduleJSON['name'] = newModuleName;
        } else {
          newModuleName = prefix + " " + moduleJSON['title'];
          moduleJSON['title'] = newModuleName;
        }

        var duplicatedModule = _this14.store.createRecord(moduleType, moduleJSON);

        duplicatedModule.save().then(function (savedDuplicatedModule) {
          _this14.store.findRecord(moduleType, savedDuplicatedModule.id, {
            reload: true
          }).then(function (editDuplicatedModule) {
            Ember.set(_this14, 'updateModuleType', module.moduleType);
            Ember.set(_this14, 'updateModule', editDuplicatedModule);
            Ember.set(_this14, 'canLoadMore', true);
            Ember.set(_this14, 'offset', 0);
            Ember.set(_this14, 'allModules', []);

            _this14.loadMoreModules();
          });
        }).catch(function (err) {
          _this14.customErrorService.handleAjaxError(err);
        });
      });
    },
    onFinish: function onFinish() {
      Ember.set(this, 'updateModule', undefined);
      this.cleanResults();
      this.loadMoreModules();
    },
    previous: function previous() {
      Ember.set(this, 'updateModule', undefined);
    },
    editVersion: function editVersion(module) {
      var _this15 = this;

      Ember.set(this, 'showActions', false);
      Ember.set(this, 'updateModule', undefined);
      Ember.set(this, 'updateVersion', undefined);
      Ember.set(this, 'updateVersionModule', module);

      switch (module.moduleType) {
        case 'content':
          this.store.findRecord('content', module.id).then(function (content) {
            Ember.set(_this15, 'updateModuleType', 'content');
            Ember.set(_this15, 'updateVersion', content);
          });
          break;

        case 'notification':
          this.store.findRecord('notification', module.id).then(function (notification) {
            Ember.set(_this15, 'updateModuleType', 'notification');
            Ember.set(_this15, 'updateVersion', notification);
          });
          break;

        case 'form':
        case 'administrative':
          this.store.findRecord('form', module.id).then(function (form) {
            Ember.set(_this15, 'updateModuleType', 'form');
            Ember.set(_this15, 'updateVersion', form);
          });
          break;

        case 'tool':
          this.store.findRecord('tool', module.id).then(function (tool) {
            Ember.set(_this15, 'updateModuleType', 'tool');
            Ember.set(_this15, 'updateVersion', tool);
          });
          break;

        case 'sign':
          this.store.findRecord('signcontent', module.id).then(function (sign) {
            Ember.set(_this15, 'updateModuleType', 'sign');
            Ember.set(_this15, 'updateVersion', sign);
          });
          break;
      }
    },
    openEditVersion: function openEditVersion(module) {
      Ember.set(this, 'updateModule', undefined);
      Ember.set(this, 'updateVersion', module);
    },
    downloadAll: function downloadAll(module) {
      var pathOTP = _environment.default.APP.API_HOST + "/users/otp";
      this.ajax.requestPromise(pathOTP).then(function (otp) {
        var path = _environment.default.APP.API_HOST + '/forms/download/form/' + module.id + "?otp=" + otp; //creating an invisible element

        var element = document.createElement('a');
        element.setAttribute('href', path);
        element.setAttribute("target", "_blank");
        document.body.appendChild(element); //onClick property

        element.click();
        document.body.removeChild(element);
      });
    },
    showAll: function showAll() {
      Ember.set(this, 'showAllPages', true);
    },
    showMin: function showMin() {
      Ember.set(this, 'showAllPages', false);
    },
    refreshFilters: function refreshFilters() {
      var allFilters = ['filters', 'formFilters', 'notificationFilters', 'contentFilters', 'toolFilters', 'audienceFilters', 'templateFilters']; //set all filters to false

      for (var f = 0; f < allFilters.length; f++) {
        if (allFilters[f] !== 'filters') {
          for (var i in this[allFilters[f]]) {
            Ember.set(this, allFilters[f] + '.' + i, false);
          }
        } else {
          for (var _i in this[allFilters[f]]) {
            Ember.set(this, allFilters[f] + '.' + _i, true);
          }
        }
      }

      Ember.set(this, 'audienceQuery', '');
      Ember.set(this, 'toolQuery', '');
      Ember.set(this, 'templateQuery', '');
      this.showAudiences();
      this.showTemplates();
      this.cleanFilters();
      this.cleanResults();
      this.loadMoreModules();
    },
    showMeetPeopleGallery: function showMeetPeopleGallery(module) {
      Ember.set(this, "showMeetPeopleGallery", true);
      Ember.set(this, "meetPeopleId", module.id);
      Ember.set(this, "showGalleryModal", true);
    },
    downloadExploreOfficePdf: function downloadExploreOfficePdf(module) {
      Ember.set(this, "exploreofficeId", module.id);
      Ember.set(this, "exploreOfficePdfDl", true);
    },
    triggerExploreOfficePdfDownload: function triggerExploreOfficePdfDownload() {
      var exploreOfficePdf = this.get('exploreOfficePdf');

      if (exploreOfficePdf) {
        exploreOfficePdf.send('downloadPdf');
      }
    },
    registerExploreOfficePdf: function registerExploreOfficePdf(exploreOfficePdf) {
      Ember.set(this, 'exploreOfficePdf', exploreOfficePdf);
    },
    resetDownloadState: function resetDownloadState() {
      Ember.set(this, "exploreOfficePdfDl", false);
    }
  }), _EmberComponent$exte));

  _exports.default = _default;
});