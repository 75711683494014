define("teelt-frontend/helpers/sign/get-sign-transaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Helper.extend({
    session: Ember.inject.service(),
    compute: function compute(_ref) {
      var _this = this;

      var _ref2 = _slicedToArray(_ref, 2),
          talent = _ref2[0],
          task = _ref2[1];

      if (task.signContent && talent.userSignContents) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          var _loop = function _loop() {
            var userSignContent = _step.value;

            if (userSignContent.id == task.signContent.id) {
              var participants = userSignContent.participants;
              var roles = _this.session.data.authenticated.user.roles;

              if (roles) {
                var authenticatedUserRole = _this.getHighestRole(_this.session.data.authenticated.user.roles);

                participants.forEach(function (p) {
                  if (p.role === authenticatedUserRole) {
                    if (p.state === "completed") {
                      Ember.set(userSignContent, "pending", true);
                    }
                  }
                });
              }

              return {
                v: userSignContent
              };
            }
          };

          for (var _iterator = talent.userSignContents[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var _ret = _loop();

            if (_typeof(_ret) === "object") return _ret.v;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }
    },
    getHighestRole: function getHighestRole(roles) {
      var rolePriority = ["ROLE_USER", "ROLE_MANAGER", "ROLE_CUSTOM_OFFICE_MANAGER", "ROLE_ADMINISTRATIVE", "ROLE_RH", "ROLE_ADMIN"];
      var maxIndex = 0;

      for (var i = 0; i < roles.length; i++) {
        var roleIndex = rolePriority.indexOf(roles[i].roleId);

        if (roleIndex > maxIndex) {
          maxIndex = roleIndex;
        }
      }

      return rolePriority[maxIndex];
    }
  });

  _exports.default = _default;
});