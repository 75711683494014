define("teelt-frontend/templates/components/icon/lang-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "96+E/OkP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"width\",\"26\"],[11,\"height\",\"26\"],[11,\"viewBox\",\"0 0 26 26\"],[11,\"fill\",\"none\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg0\",\"http://www.w3.org/2000/xmlns/\"],[9],[0,\"\\n  \"],[7,\"path\"],[11,\"d\",\"M9.98215 14.604H15.6786M8.6875 17.5632L9.98215 14.604L8.6875 17.5632ZM16.9732 17.5632L15.6786 14.604L16.9732 17.5632ZM9.98215 14.604L12.8304 8.09375L15.6786 14.604H9.98215Z\"],[11,\"stroke\",\"#1800FF\"],[11,\"stroke-width\",\"2\"],[11,\"stroke-linecap\",\"round\"],[11,\"stroke-linejoin\",\"round\"],[9],[10],[0,\"\\n  \"],[7,\"path\"],[11,\"d\",\"M24.2 10.4694C23.1033 5.06652 18.3265 1 12.6 1C6.87346 1 2.09672 5.06652 1 10.4694L4.55103 9.28573\"],[11,\"stroke\",\"#1800FF\"],[11,\"stroke-width\",\"2\"],[11,\"stroke-linecap\",\"round\"],[11,\"stroke-linejoin\",\"round\"],[9],[10],[0,\"\\n  \"],[7,\"path\"],[11,\"d\",\"M1 15.2051C2.09672 20.608 6.87346 24.6745 12.6 24.6745C18.3265 24.6745 23.1033 20.608 24.2 15.2051L21.1225 16.3888\"],[11,\"stroke\",\"#1800FF\"],[11,\"stroke-width\",\"2\"],[11,\"stroke-linecap\",\"round\"],[11,\"stroke-linejoin\",\"round\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/icon/lang-svg.hbs"
    }
  });

  _exports.default = _default;
});