define("teelt-frontend/templates/components/input/generic-autocomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fPGv9qxS",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\"],[11,\"class\",\"input-field\"],[9],[0,\"\\n  \"],[7,\"label\"],[12,\"class\",[28,[[27,\"if\",[[23,[\"selectedValue\"]],\"active\",\"\"],null]]]],[12,\"for\",[21,\"customId\"]],[9],[1,[21,\"label\"],false],[10],[0,\"\\n  \"],[7,\"select\"],[12,\"id\",[21,\"customId\"]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"onChange\"],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"items\"]]],null,{\"statements\":[[0,\"      \"],[7,\"option\"],[12,\"value\",[22,1,[\"id\"]]],[12,\"selected\",[27,\"eq\",[[23,[\"selectedValue\"]],[27,\"concat\",[\"\",[22,1,[\"id\"]]],null]],null]],[9],[1,[22,1,[\"value\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/input/generic-autocomplete.hbs"
    }
  });

  _exports.default = _default;
});