define("teelt-frontend/components/talents/count-talent-by-period", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    count: " ",
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    defaultProfileImageUrl: _environment.default.APP.DEFAULT_PROFILE_IMAGE_URL,
    label: undefined,
    params: {},
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'loading', true);
      this.filterTalents();
    },
    filterTalents: function filterTalents(data) {
      var _this = this;

      Ember.set(this, 'loading', true);

      if (data) {
        Ember.set(this, 'params', {
          dateFilter: 'today',
          startDate: null,
          endDate: null,
          targetDateField: 'welcomeDate'
        });
        this.params.dateFilter = data.dateFilter;
        this.params.startDate = data.startDate;
        this.params.endDate = data.endDate;
        this.params.targetDateField = data.targetDateField;
      }

      this.ajax.requestWithUrlParamPromise(_environment.default.APP.API_HOST + '/users/count/period', 'GET', this.params).then(function (data) {
        Ember.set(_this, 'users', data);
        Ember.set(_this, 'count', data.length);
        Ember.set(_this, 'loading', false);
      }).catch(function (error) {
        Ember.set(_this, 'loading', false);
      });
    },
    actions: {
      goto_arriving: function goto_arriving(label) {
        this.router.transitionTo('board.talent.talent-by-period', {
          queryParams: {
            dateFilter: this.params.dateFilter,
            startDate: this.params.startDate,
            endDate: this.params.endDate,
            targetDateField: this.params.targetDateField,
            label: label,
            filterType: 'countByPeriod'
          }
        });
      },
      loadData: function loadData(data) {
        this.filterTalents(data);
      }
    }
  });

  _exports.default = _default;
});