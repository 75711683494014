define("teelt-frontend/routes/form-print", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        formId: params.form_id,
        talentId: params.talent_id,
        form: this.store.findRecord('form', params.form_id, {
          reload: true
        }),
        talent: this.store.findRecord('user', params.talent_id),
        showFinalScore: false
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      var path = _environment.default.APP.API_HOST + '/forms/' + model.formId + '/response/' + model.talentId;
      this.ajax.requestPromise(path, 'GET').then(function (data) {
        if (data.fields) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = data.fields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var field = _step.value;

              if (field.value && field.value.length > 0) {
                var tmp = field.value.split('||');
                Ember.set(model.form, 'maxResponseNumber', tmp.length);
                break;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        }

        Ember.set(model.form, 'fields', data.fields);

        if (data.maxResult !== 0) {
          Ember.set(model, 'showFinalScore', true);
        }

        Ember.set(model, 'form', data);
        Ember.set(model, 'showResults', true);
        setTimeout(function () {
          _this.print();
        }, 2000);
      });
    },
    print: function print() {
      var elem = document.getElementById("form-print");
      var opt = {
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        html2canvas: {
          scrollX: 0,
          scrollY: 0,
          windowWidth: '100%'
        },
        jsPDF: {
          orientation: 'portrait'
        },
        margin: [0, 0],
        autoTable: {
          startY: 15,
          margin: {
            left: 10,
            right: 10
          },
          avoidPageSplit: true
        }
      };
      html2pdf().set(opt).from(elem).toPdf().output('datauristring').then(function (pdf) {
        var iframe = "<iframe style=' width:100%; height:100%'  src='" + pdf + "#zoom=90'></iframe>";
        document.write(iframe);
      });
      /*
          html2pdf().set(opt).from(elem).toPdf().outputPdf().then(function(pdf) {
            var pdfData = new Blob([pdf], {
              type: 'application/pdf'
            });
            var pdfURL = URL.createObjectURL(pdfData);
            var a = document.createElement('a');
            a.href = pdfURL;
            a.download = 'myfile.pdf';
            document.body.appendChild(a);
            a.click();
          });
      */
      //window.print()
    }
  });

  _exports.default = _default;
});