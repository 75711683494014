define("teelt-frontend/templates/components/utils/viewer/pdf-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8PHaf7RL",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n  \"],[7,\"div\"],[12,\"id\",[28,[\"pdf-viewer-\",[21,\"uuid\"]]]],[12,\"class\",[28,[\"viewer-container \",[27,\"if\",[[23,[\"isThumbnail\"]],\" thubmnail \",\"\"],null],\"  \"]]],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"viewer-pdf-container\"],[9],[0,\"\\n      \"],[7,\"div\"],[12,\"id\",[28,[\"pdf-container-\",[21,\"uuid\"]]]],[11,\"data-pdfjs\",\"\"],[9],[0,\" \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[27,\"and\",[[23,[\"pagination\"]],[27,\"not\",[[23,[\"isFullScreen\"]]],null],[23,[\"pageNb\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"zoom-controls\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"add-zoom clickable\"],[3,\"action\",[[22,0,[]],\"zoomIn\"]],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[11,\"style\",\"float:left!important\"],[9],[0,\"add\"],[10],[0,\"\\n      \"],[10],[7,\"div\"],[11,\"class\",\"remove-zoom clickable\"],[3,\"action\",[[22,0,[]],\"zoomOut\"]],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[11,\"style\",\"float:left!important\"],[9],[0,\"remove\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[\"pdf-pages \",[27,\"if\",[[23,[\"topPagination\"]],\"top-bar\",\"bottom-bar\"],null]]]],[9],[0,\"\\n        \"],[7,\"span\"],[9],[1,[21,\"currentPage\"],false],[0,\"/\"],[1,[21,\"pageNb\"],false],[10],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"material-icons clickable\"],[3,\"action\",[[22,0,[]],\"previousPage\"]],[9],[0,\"chevron_left\"],[10],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"material-icons clickable\"],[3,\"action\",[[22,0,[]],\"nextPage\"]],[9],[0,\"chevron_right\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/viewer/pdf-viewer.hbs"
    }
  });

  _exports.default = _default;
});