define("teelt-frontend/templates/board/experience/experience-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HRoPckE1",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"template/template-detail\",null,[[\"templateId\",\"forceTemplatePage\"],[[23,[\"model\",\"templateId\"]],[23,[\"model\",\"forceTemplatePage\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/experience/experience-edit.hbs"
    }
  });

  _exports.default = _default;
});