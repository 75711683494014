define("teelt-frontend/templates/components/talents/dashboard-talents-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2R4pFTri",
    "block": "{\"symbols\":[\"talent\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"talent-block\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"talents\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[11,\"class\",\"talent-block-name clear\"],[3,\"action\",[[22,0,[]],\"openTalent\",[22,1,[\"id\"]]]],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"profilePictureUrl\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"profileImageUrl\"],[12,\"style\",[27,\"background-cover\",[[22,1,[\"profilePictureUrl\"]]],null]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"defaultProfileImage\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"profileImageUrl\"],[12,\"style\",[27,\"background-cover\",[[23,[\"defaultProfileImageUrl\"]]],null]],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[7,\"div\"],[11,\"class\",\"talent-info left floating\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"talent-name truncate\"],[9],[1,[27,\"talent/talent-name\",[[22,1,[]]],null],false],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"talent-position truncate\"],[9],[1,[22,1,[\"position\",\"name\"]],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"floating-r\"],[9],[0,\"\\n        \"],[1,[27,\"utils/circle-number\",null,[[\"value\"],[[27,\"get-model-attribute\",[[22,1,[]],\"remainingRHOrManagerTaskNumber\",\"user\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/dashboard-talents-list.hbs"
    }
  });

  _exports.default = _default;
});