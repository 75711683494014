define("teelt-frontend/components/utils/viewer/pdf-viewer", ["exports", "jquery", "teelt-frontend/utils/string-util", "pdfjs-dist"], function (_exports, _jquery, _stringUtil, _pdfjsDist) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pdf-viewer'],
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    customErrorService: Ember.inject.service('custom-error'),
    userService: Ember.inject.service(),
    uiUtils: Ember.inject.service("ui-utils"),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    url: "",
    page: 1,
    zoomFactor: 0.7,
    rotationAngle: 0,
    isFullScreen: false,
    pdfContainer: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'uuid', (0, _stringUtil.default)().generateUUID());
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var _this = this;

      this.fileUploadService.getObjectBase64(_this.baseUrl).then(function (data) {
        _pdfjsDist.default.GlobalWorkerOptions.workerSrc = '/assets/pdf.worker.min.3.11.174.js';
        var decodedData = atob(data.data);
        var pdfData = new Uint8Array(decodedData.length);

        for (var i = 0; i < decodedData.length; i++) {
          pdfData[i] = decodedData.charCodeAt(i);
        }

        _pdfjsDist.default.getDocument(pdfData).promise.then(function (pdf) {
          console.log('PDF loaded', pdf);
          Ember.set(_this, 'pageNb', pdf.numPages);
          Ember.set(_this, 'pdf', pdf);

          _this.showPage(1);
        });
      }).catch(function (err) {
        _this2.customErrorService.handleAjaxError(err);
      });
    },
    showPage: function showPage(nb) {
      var _this3 = this;

      var pdfContainer = document.getElementById('pdf-container-' + this.uuid);
      var parent = pdfContainer.parentNode.parentNode;
      var oldCanvas = pdfContainer.querySelector("canvas");

      if (oldCanvas) {
        pdfContainer.removeChild(oldCanvas);
      }

      Ember.set(this, 'currentPage', nb);
      this.pdf.getPage(nb).then(function (page) {
        var pdfCanvas = document.createElement('canvas');
        var context = pdfCanvas.getContext('2d');
        pdfCanvas.id = 'pdf-canvas' + _this3.uuid;
        var scale = 2;
        var scl = 2.6;

        if (_this3.isThumbnail) {
          scale = 0.5;
          scl = 0.5;
        }

        var viewport = page.getViewport({
          scale: scale
        });
        var pdfWidth = viewport.width;
        var pdfHeight = viewport.height;
        var pdfContainerWidth = parent.clientWidth;
        var pdfContainerHeight = pdfContainerWidth * (pdfHeight / pdfWidth);
        pdfCanvas.width = pdfContainerWidth * scale;
        pdfCanvas.height = pdfContainerHeight * scale;
        var renderContext = {
          canvasContext: context,
          viewport: viewport,
          intent: "display"
        };
        var scaleRatio = pdfContainerWidth / pdfWidth * scale;
        renderContext.viewport = page.getViewport({
          scale: scale * scaleRatio
        });
        page.render(renderContext);
        pdfCanvas.classList.add('pdf-canvas');
        pdfContainer.appendChild(pdfCanvas);

        _this3.initDragDrop();

        _this3.initZoom();
      });
    },
    initDragDrop: function initDragDrop() {
      var _this4 = this;

      if (this.isThumbnail) return;
      var pdfContainer = document.getElementById('pdf-container-' + this.uuid);
      var canvas = pdfContainer.querySelector('.pdf-canvas');
      var isDragging = false;
      var startX, startY;
      var offsetX = 0;
      var offsetY = 0;
      var overflowLimit = 0.9;
      canvas.addEventListener('mousedown', function (event) {
        isDragging = true;
        startX = event.clientX;
        startY = event.clientY;
      });
      document.addEventListener('mousemove', function (event) {
        if (isDragging) {
          var x = event.clientX;
          var y = event.clientY;
          offsetX += x - startX;
          offsetY += y - startY;
          startX = x;
          startY = y;
          var canvasRect = canvas.getBoundingClientRect();
          var pdfRect = pdfContainer.getBoundingClientRect();
          var canvasWidth = canvasRect.width;
          var canvasHeight = canvasRect.height;
          var pdfWidth = pdfRect.width;
          var pdfHeight = pdfRect.height;
          var maxOffsetX = canvasWidth * _this4.zoomFactor - pdfWidth * (1 - overflowLimit);
          var maxOffsetY = canvasHeight * _this4.zoomFactor - pdfHeight * (1 - overflowLimit);
          offsetX = Math.min(Math.max(offsetX, -maxOffsetX), maxOffsetX);
          offsetY = Math.min(Math.max(offsetY, -maxOffsetY), maxOffsetY);
          canvas.style.transform = "translate(".concat(offsetX, "px, ").concat(offsetY, "px)");
        }
      });
      document.addEventListener('mouseup', function () {
        isDragging = false;
      });
    },
    initZoom: function initZoom() {
      var _this5 = this;

      if (this.isThumbnail) return;
      var pdfContainer = document.getElementById('pdf-container-' + this.uuid);
      var canvas = pdfContainer.querySelector('.pdf-canvas');
      var zoomFactor = this.zoomFactor;
      pdfContainer.addEventListener('wheel', function (event) {
        event.preventDefault();
        var currentZoomFactor = zoomFactor;

        if (event.deltaY > 0) {
          // Zoom out
          zoomFactor = Math.max(0.40, zoomFactor - 0.03);
        } else {
          // Zoom in
          zoomFactor = Math.min(2.60, zoomFactor + 0.03);
        }

        Ember.set(_this5, 'zoomFactor', zoomFactor);
        var cursorX = event.clientX - pdfContainer.getBoundingClientRect().left;
        var cursorY = event.clientY - pdfContainer.getBoundingClientRect().top;
        var newOffsetX = cursorX - cursorX / currentZoomFactor * zoomFactor;
        var newOffsetY = cursorY - cursorY / currentZoomFactor * zoomFactor;
        pdfContainer.style.transform = "translate(".concat(-newOffsetX, "px, ").concat(-newOffsetY, "px) scale(").concat(zoomFactor, ")");
      }, {
        passive: false
      });
      Ember.set(this, 'pdfContainer', pdfContainer);
    },
    actions: {
      nextPage: function nextPage() {
        if (this.currentPage < this.pageNb) {
          this.showPage(this.currentPage + 1);
        }
      },
      previousPage: function previousPage() {
        if (this.currentPage > 1) {
          this.showPage(this.currentPage - 1);
        }
      },
      zoomIn: function zoomIn() {
        if (this.zoomFactor >= 2.60) return;
        var zoomFactor = this.zoomFactor;
        zoomFactor = Math.min(2.60, zoomFactor + 0.03);
        Ember.set(this, 'zoomFactor', zoomFactor);
        this.pdfContainer.style.transform = "translate(0px, 0px) scale(".concat(zoomFactor, ")");
      },
      zoomOut: function zoomOut() {
        if (this.zoomFactor <= 0.40) return;
        var zoomFactor = this.zoomFactor;
        zoomFactor = Math.max(0.40, zoomFactor - 0.03);
        Ember.set(this, 'zoomFactor', zoomFactor);
        this.pdfContainer.style.transform = "translate(0px, 0px) scale(".concat(zoomFactor, ")");
      }
    }
  });

  _exports.default = _default;
});