define("teelt-frontend/routes/board/myaccount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      menu: ''
    },
    resetController: function resetController(controller) {
      controller.set('menu', null);
    },
    model: function model(params) {
      return {
        menu: params.menu
      };
    }
  });

  _exports.default = _default;
});