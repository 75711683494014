define("teelt-frontend/components/notification/notification-sms-content", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['notification-content-block', 'sms-block'],
    showSmsContent: false,
    getSmsNotification: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.setSmsContentFromContents();

      if (this.notification.disabled) {
        this.send('onDisable');
      }

      if (this.withoutHeader) {
        this.send('toggleShowSmsContent');
      }
    },
    updateContent: Ember.observer('notification.name', 'showContentForOfficeId', 'showContentForTagId', function () {
      Ember.set(this, 'sms', this.getSmsContentFromContents());
    }),
    setSmsContentFromContents: function setSmsContentFromContents() {
      var smsContent = this.getSmsContentFromContents();

      if (smsContent) {
        Ember.set(this, "sms", smsContent);
      } else {
        var _smsContent = {
          contentType: "SMS",
          body: "",
          disabled: true
        };
        this.notification.contents.push(_smsContent);
        Ember.set(this, "sms", _smsContent);
      }
    },
    getSmsContentFromContents: function getSmsContentFromContents() {
      if (!this.notification.contents) return;

      for (var i = 0; i < this.notification.contents.length; i++) {
        var content = this.notification.contents[i];
        if (content.contentType === "SMS" && (this.tagMode && content.tagId == (this.showContentForTagId ? this.showContentForTagId : null) || !this.tagMode && content.officeId == (this.showContentForOfficeId ? this.showContentForOfficeId : null))) return this.notification.contents[i];
      }
    },
    actions: {
      toggleShowSmsContent: function toggleShowSmsContent() {
        var _this = this;

        Ember.set(this, 'showSmsContent', !this.showSmsContent);

        if (this.showSmsContent) {
          Ember.run.later(function () {
            _this.$('#templateMarkersSmsSelect').select2({});

            _this.bindOnChangeTemplateMarker();
          }, 0);
        }
      },
      onActivate: function onActivate() {
        if (!this.notification.contents) return;
        this.notification.contents.forEach(function (c) {
          if (c.contentType === "SMS") {
            Ember.set(c, "disabled", false);
          }
        });
      },
      onDisable: function onDisable() {
        if (!this.notification.contents) return;
        this.notification.contents.forEach(function (c) {
          if (c.contentType === "SMS") {
            Ember.set(c, "disabled", true);
          }
        });
      },
      onBodyChanged: function onBodyChanged() {
        this.get('getSmsNotification').setData(this.sms);

        if (this.onBodyChanged) {
          this.onBodyChanged();
        }
      }
    },
    bindOnChangeTemplateMarker: function bindOnChangeTemplateMarker() {
      var self = this;
      this.$('#templateMarkersSmsSelect').on('change', function () {
        if (!(0, _jquery.default)(this).find(':selected').data('marker')) {
          return;
        }

        Ember.set(self.sms, "body", self.sms.body + (0, _jquery.default)(this).find(':selected').data('marker'));
        self.send('onBodyChanged');
        (0, _jquery.default)(this).val("");
        (0, _jquery.default)(this).trigger('change'); //Reset the dropdown
      });
    }
  });

  _exports.default = _default;
});