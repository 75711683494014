define("teelt-frontend/templates/components/menu/talent-menu-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GvJADIJE",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"talent\",\"profilePictureUrl\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"profileImageUrl\"],[12,\"style\",[27,\"background-cover\",[[23,[\"talent\",\"profilePictureUrl\"]]],null]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"defaultProfileImage\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"profileImageUrl\"],[12,\"style\",[27,\"background-cover\",[[23,[\"defaultProfileImageUrl\"]]],null]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[27,\"not\",[[23,[\"photoOnly\"]]],null]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"talent-informations company-text-color\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"talent-firstname \"],[9],[0,\"\\n    \"],[1,[27,\"talent/talent-firstname\",[[23,[\"talent\"]]],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"talent-position truncate\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"talent\",\"position\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,[\"talent\",\"position\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"talent.no_position\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/menu/talent-menu-profile.hbs"
    }
  });

  _exports.default = _default;
});