define("teelt-frontend/templates/board/setting/period-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BYwEI7cx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"period-new\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"section-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"g-section-title foreground-primary-color\"],[9],[0,\"\\n            \"],[7,\"h1\"],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"settings.periods.new_period\"],null]],null],false],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"baseline\"],[9],[1,[27,\"t\",[\"settings.periods.baseline\"],null],false],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[1,[27,\"settings/period-new\",null,[[\"triggerDate\"],[[27,\"concat\",[[23,[\"model\",\"type\"]],\"Date\"],null]]]],false],[0,\"\\n      \\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/setting/period-new.hbs"
    }
  });

  _exports.default = _default;
});