define("teelt-frontend/components/talents/talent-information-block-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      _moment.default.locale(this.talent.lang);

      if (!this.talent.selectedTemplate) return;
      Ember.set(this, "templateType", this.talent.selectedTemplate.type);

      switch (this.talent.selectedTemplate.type) {
        case 'end':
          if (this.talent.endDate) {
            Ember.set(this, "date", (0, _moment.default)(this.talent.endDate).format('LLLL').replace(/\d{1,2}\:\d{1,2}(\s)?.*/gm, ''));
          }

          break;

        case 'migration':
          if (this.talent.migrationDate) {
            Ember.set(this, "date", (0, _moment.default)(this.talent.migrationDate).format('LLLL').replace(/\d{1,2}\:\d{1,2}(\s)?.*/gm, ''));
          }

          break;

        case 'assignation':
          if (this.talent.template) {
            if (this.talent.template.id == this.talent.selectedTemplate.id) {
              if (this.talent.templateAssignationDate) {
                Ember.set(this, "date", (0, _moment.default)(this.talent.templateAssignationDate).format('LLLL').replace(/\d{1,2}\:\d{1,2}(\s)?.*/gm, ''));
              }
            } else if (this.talent.template.subTemplates) {
              var _iteratorNormalCompletion = true;
              var _didIteratorError = false;
              var _iteratorError = undefined;

              try {
                for (var _iterator = this.talent.template.subTemplates[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                  var subTemplate = _step.value;

                  if (subTemplate.template.id == this.talent.selectedTemplate.id) {
                    if (subTemplate.templateAssignationDate) {
                      Ember.set(this, "date", (0, _moment.default)(subTemplate.templateAssignationDate).format('LLLL').replace(/\d{1,2}\:\d{1,2}(\s)?.*/gm, ''));
                    }
                  }
                }
              } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                  }
                } finally {
                  if (_didIteratorError) {
                    throw _iteratorError;
                  }
                }
              }
            }
          }

          break;

        default:
          if (this.talent.welcomeDate) {
            Ember.set(this, "date", (0, _moment.default)(this.talent.welcomeDate).format('LLLL').replace(/\d{1,2}\:\d{1,2}(\s)?.*/gm, ''));
          }

          break;
      }
    }
  });

  _exports.default = _default;
});