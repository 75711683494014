define("teelt-frontend/templates/board/event/campaign-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cvUjW0Y9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"campaign-new\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"shouldOpenModal\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"back foreground-primary-color\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showUnsavedConfirm\"]]],null],true]],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"close\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[\"board.events\"],[[\"class\"],[\"back foreground-primary-color\"]],{\"statements\":[[0,\"      \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"close\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[7,\"div\"],[11,\"class\",\"section-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"g-section-title  foreground-primary-color\"],[9],[0,\"\\n            \"],[7,\"h1\"],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"event.campaign.new_campaign2\"],null]],null],false],[10],[0,\"\\n             \"],[7,\"div\"],[11,\"class\",\"baseline\"],[9],[1,[27,\"t\",[\"event.campaign.baseline2\"],null],false],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[1,[27,\"event/add-campaign-form\",null,[[\"saved\",\"duplicateId\",\"shouldOpenModal\",\"showUnsavedConfirm\"],[[23,[\"model\",\"saved\"]],[23,[\"model\",\"duplicateId\"]],[23,[\"shouldOpenModal\"]],[23,[\"showUnsavedConfirm\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/event/campaign-new.hbs"
    }
  });

  _exports.default = _default;
});