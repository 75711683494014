define("teelt-frontend/templates/components/event/campaign-assign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zLmd7Owg",
    "block": "{\"symbols\":[],\"statements\":[[0,\" \"],[1,[27,\"talents/talents-audience-assignment\",null,[[\"toAssign\",\"assignModel\",\"edit\"],[[23,[\"campaign\"]],\"campaign\",true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/event/campaign-assign.hbs"
    }
  });

  _exports.default = _default;
});