define("teelt-frontend/templates/components/icon/flag/pl-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "24Tupe11",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"width\",\"14\"],[11,\"height\",\"14\"],[11,\"viewBox\",\"0 0 64 64\"],[11,\"fill\",\"none\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[9],[0,\"\\n  \"],[7,\"path\"],[11,\"d\",\"M32 2C48.6 2 62 15.4 62 32H2C2 15.4 15.4 2 32 2Z\"],[11,\"fill\",\"#F9F9F9\"],[9],[10],[0,\"\\n  \"],[7,\"path\"],[11,\"d\",\"M32 62C15.4 62 2 48.6 2 32H62C62 48.6 48.6 62 32 62Z\"],[11,\"fill\",\"#ED4C5C\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/icon/flag/pl-svg.hbs"
    }
  });

  _exports.default = _default;
});