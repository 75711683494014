define("teelt-frontend/routes/login/forgot-password", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    userService: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    getBackground: Ember.inject.service('get-background'),
    model: function model() {
      return {};
    },
    afterModel: function afterModel(model) {
      var _this = this;

      var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : 'default';
      var path = _environment.default.APP.API_HOST + "/companies/customizations/domain?domain=" + subdomain;
      this.ajax.requestPromise(path, 'GET').then(function (customizations) {
        _this.getBackground.getBackground(model, customizations);
      });
    },
    actions: {
      getPasswordResetEmail: function getPasswordResetEmail() {
        var _this2 = this;

        if (this.get('userService').isValidEmail(this.currentModel.email)) {
          this.get('userService').forgetPassword(this.currentModel.email).then(function () {
            Ember.set(_this2.currentModel, "confirmMessage", _this2.intl.t('forgot_password.messages.email_sent'));
          });
        }
      }
    }
  });

  _exports.default = _default;
});