define("teelt-frontend/models/notification", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr(),
    name: _emberData.default.attr('string'),
    recipient: _emberData.default.attr('string'),
    triggerInitiator: _emberData.default.attr('string'),
    conditions: _emberData.default.attr('string'),
    disabled: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    schedules: _emberData.default.attr(),
    contents: _emberData.default.attr(),
    companyId: _emberData.default.attr('number'),
    customNotificationId: _emberData.default.attr('number'),
    disabledSMS: _emberData.default.attr('boolean'),
    blockDisabled: _emberData.default.attr('boolean'),
    selectedTemplateIds: _emberData.default.attr(),
    templates: _emberData.default.attr(),
    associatedRole: _emberData.default.attr('string'),
    audience: _emberData.default.attr(),
    genericNotification: _emberData.default.attr(),
    isEmailEnabled: Ember.computed('contents', function () {
      if (!this.contents) return undefined;
      var defaultContentArray = this.contents.filter(function (c) {
        return !c.officeId && !c.tagId && c.contentType === "EMAIL" && !c.disabled;
      });
      if (!defaultContentArray || defaultContentArray.length === 0) return false;
      return !!defaultContentArray[0].body;
    }),
    isSMSEnabled: Ember.computed('contents', function () {
      if (!this.contents) return undefined;
      var defaultContentArray = this.contents.filter(function (c) {
        return !c.officeId && !c.tagId && c.contentType === "SMS" && !c.disabled;
      });
      if (!defaultContentArray || defaultContentArray.length === 0) return false;
      return !!defaultContentArray[0].body;
    }),
    isSystem: Ember.computed('type', function () {
      return this.type !== "CUSTOM";
    })
  });

  _exports.default = _default;
});