define("teelt-frontend/components/analytics/table/form-custom-table-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['templates-custom-table-content'],
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    actions: {
      edit: function edit() {
        Ember.set(this, 'showActions', false);
        this.router.transitionTo('board.analytic.form-analytic-edit', this.result.id);
      },
      duplicate: function duplicate() {
        var _this = this;

        Ember.set(this, 'showActions', false);
        this.store.findRecord('form-report', this.result.id, {
          reload: true,
          include: 'light'
        }).then(function (formReportToDuplicate) {
          var fromReportJSON = formReportToDuplicate.toJSON();
          fromReportJSON['id'] = undefined;

          var prefix = _this.intl.t("module.duplicate.prefix");

          var newName = '';
          newName = prefix + " " + fromReportJSON['title'];
          fromReportJSON['title'] = newName;

          var duplicatedFormReport = _this.store.createRecord('form-report', fromReportJSON);

          duplicatedFormReport.save().then(function () {
            _this.eventBus.trigger('REFRESH_FORMREPORTS');
          }).catch(function (err) {
            _this.customErrorService.handleAjaxError(err);
          });
        });
      },
      prepareDeleteFormReport: function prepareDeleteFormReport() {
        Ember.set(this, 'showActions', false);
        Ember.set(this, 'formReportToDelete', this.result);
      },
      removeFormResponse: function removeFormResponse(response) {
        var _this2 = this;

        if (!response) return;
        this.formReportToDelete.deleteRecord();
        this.formReportToDelete.save().then(function () {
          _this2.eventBus.trigger('REFRESH_FORMREPORTS');
        });
      }
    }
  });

  _exports.default = _default;
});