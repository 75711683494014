define("teelt-frontend/components/form/field/field-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['field-preview'],
    isPdf: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.responseIndex != undefined) {
        var fieldValue = '' + (this.field.value ? this.field.value : '');
        var tmp = fieldValue.split('||');
        var value = tmp[this.responseIndex];

        try {
          value = JSON.parse(value);
        } catch (err) {}

        Ember.set(this, 'value', value);
      } else {
        Ember.set(this, 'value', this.field.value);
      }
    },
    valueObserver: Ember.observer('value', function () {
      if (this.responseIndex != undefined) {
        var fieldValue = '' + (this.field.value ? this.field.value : '');
        var tmp = fieldValue.split('||');

        for (var i = tmp.length; i < this.responseIndex + 1; i++) {
          tmp.push('');
        }

        tmp[this.responseIndex] = Array.isArray(this.value) ? JSON.stringify(this.value) : this.value;
        Ember.set(this.field, 'value', tmp.join('||'));

        if (this.field.type == "file") {
          var fileValidations = this.field.fileValidations ? this.field.fileValidations : [];

          if (!fileValidations || fileValidations.length == 0 || !fileValidations[this.responseIndex]) {
            var validation = {
              url: tmp[this.responseIndex],
              isValid: null,
              rejectReason: null
            };
            fileValidations.push(validation);
          } else {
            Ember.set(fileValidations[this.responseIndex], 'url', tmp[this.responseIndex]);
          }

          Ember.set(this.field, 'fileValidations', fileValidations);
        }
      } else {
        Ember.set(this.field, 'value', this.value);

        if (this.field.type == "file") {
          var _fileValidations = this.field.fileValidations ? this.field.fileValidations : [];

          if (!_fileValidations || _fileValidations.length == 0) {
            var _validation = {
              url: this.value,
              isValid: null,
              rejectReason: null
            };

            _fileValidations.push(_validation);
          } else {
            Ember.set(_fileValidations[0], 'url', this.value);
          }

          Ember.set(this.field, 'fileValidations', _fileValidations);
        }
      }
    }),
    didInsertElement: function didInsertElement() {
      var textareas = document.querySelectorAll('.materialize-textarea');

      if (textareas) {
        for (var i = 0; i < textareas.length; i++) {
          M.textareaAutoResize(textareas[i]);
        }
      }
    }
  });

  _exports.default = _default;
});