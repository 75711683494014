define("teelt-frontend/components/custom-field/field/custom-multi-autocomplete", ["exports", "teelt-frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['custom-multi-autocomplete'],
    ajaxService: Ember.inject.service('ajax'),
    customErrorService: Ember.inject.service('custom-error'),
    uiUtils: Ember.inject.service('ui-utils'),
    userService: Ember.inject.service(),
    session: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.selectedValue) {
        var tmp = this.selectedValue.split(';');
        Ember.set(this, 'selectedValueArray', tmp);
        var promises = [];
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = tmp[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var value = _step.value;
            var url = _environment.default.APP.API_HOST + "/users/" + value + "/fromCustomLink/" + this.field.codeKey;
            promises.push(this.ajaxService.requestPromise(url, 'GET'));
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        Promise.all(promises).then(function (users) {
          Ember.set(_this, 'data', users);
        });
      } else {
        Ember.set(this, 'selectedValueArray', []);
        Ember.set(this, 'data', []);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.initField();

      if (this.field.codeKey === 'officeManager') {
        var authenticatedUser = this.session.data.authenticated.user;
        var filterRole = authenticatedUser.roles.filter(function (item) {
          return item.roleId === 'ROLE_CUSTOM_OFFICE_MANAGER';
        });

        if (filterRole.length > 0) {
          this.send("onChange", "" + authenticatedUser.id, authenticatedUser.username);
        }
      }
    },
    initField: function initField() {
      var path = _environment.default.APP.API_HOST + '/custom-fields/' + this.field.id + '/data';
      var self = this;
      this.$('#customField-' + this.field.id).select2({
        ajax: {
          url: path,
          contentType: 'application/json;charset=utf-8',
          dataType: 'json',
          xhrFields: {
            withCredentials: true
          },
          headers: {
            'x-auth-token': this.ajaxService.getXAuthToken()
          },
          data: function data(params) {
            var query = {
              search: params.term
            };
            return query;
          },
          processResults: function processResults(data) {
            if (!data) return;
            var results = data.map(function (record) {
              return {
                id: record.id,
                text: record.value
              };
            });
            results = results.filter(function (item) {
              return !self.isAlreadySelected(item.id);
            });
            return {
              results: results
            };
          }
        }
      });
    },
    isAlreadySelected: function isAlreadySelected(id) {
      if (!this.selectedValueArray) return false;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = this.selectedValueArray[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var selectedId = _step2.value;
          if (Number(selectedId) === id) return true;
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      return false;
    },
    actions: {
      onChange: function onChange(value, forceName) {
        var selectedArray = this.selectedValueArray;

        if (selectedArray.indexOf(value) >= 0) {
          return;
        }

        selectedArray.push(value);
        Ember.set(this, 'selectedValueArray', null);
        Ember.set(this, 'selectedValueArray', selectedArray);
        Ember.set(this, 'selectedValue', selectedArray.join(';'));
        var data = this.data;

        if (forceName) {
          data.push({
            id: value,
            name: forceName
          });
        } else {
          data.push({
            id: value,
            name: this.$('#customField-' + this.field.id + ' option:selected').text()
          });
        }

        Ember.set(this, 'data', null);
        Ember.set(this, 'data', data);
        Ember.set(this, 'selectedLabel', this.data.map(function (d) {
          return d.name;
        }).join(', '));
        (0, _jquery.default)('#customField-' + this.field.id).prop('selectedIndex', -1);
      },
      focusOnInput: function focusOnInput() {
        (0, _jquery.default)("#keywords-new-" + this.field.id + " input").focus();
      },
      deleteUser: function deleteUser(index) {
        var selectedArray = this.selectedValueArray;
        selectedArray.splice(index, 1);
        Ember.set(this, 'selectedValueArray', null);
        Ember.set(this, 'selectedValueArray', selectedArray);
        var data = this.data;
        data.splice(index, 1);
        Ember.set(this, 'data', null);
        Ember.set(this, 'data', data);
        Ember.set(this, 'selectedValue', selectedArray.length ? selectedArray.join(';') : null);
      }
    }
  });

  _exports.default = _default;
});