define("teelt-frontend/components/ihm/meet-people/lauch-meet-people-modal", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend((_EmberComponent$exte = {
    classNames: ['select-people-animation'],
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    session: Ember.inject.service(),
    userService: Ember.inject.service()
  }, _defineProperty(_EmberComponent$exte, "session", Ember.inject.service()), _defineProperty(_EmberComponent$exte, "customErrorService", Ember.inject.service('custom-error')), _defineProperty(_EmberComponent$exte, "showParticipantsModal", true), _defineProperty(_EmberComponent$exte, "step", 1), _defineProperty(_EmberComponent$exte, "loading", true), _defineProperty(_EmberComponent$exte, "init", function init() {
    this._super.apply(this, arguments);
  }), _defineProperty(_EmberComponent$exte, "actions", {
    next: function next() {
      var _this = this;

      Ember.set(this, 'step', this.step + 1);

      if (this.step == 2) {
        this.send('lauchGame', this.meetPeople);
        setTimeout(function () {
          Ember.set(_this, 'loading', false);
        }, 5000);
      }
    },
    onClickOverlay: function onClickOverlay() {},
    lauchGame: function lauchGame(meetPeople) {
      var _this2 = this;

      Ember.set(this, 'meetPeople', meetPeople);
      if (meetPeople.hasBeenLaunched) return;
      this.ajax.requestPromise(_environment.default.APP.API_HOST + '/meetpeoples/' + meetPeople.id + '/lauchgame/' + this.talent.id, 'GET').then(function (res) {
        Ember.set(_this2, 'displayBlock', null);
        var meetPeople = res.meetPeople;
        Ember.set(meetPeople, 'participants', res.participants);
        Ember.set(meetPeople, 'hasBeenLaunched', true);
        Ember.set(_this2, 'meetPeople', meetPeople);

        if (_this2.onFinish) {
          _this2.onFinish(meetPeople);
        }
      }).catch(function (err) {
        _this2.customErrorService.handleAjaxError(err);

        Ember.set(_this2, 'close', false);
      });
    },
    showMeetPeopleParticipants: function showMeetPeopleParticipants() {
      Ember.set(this, 'step', 3);
    },
    showGallery: function showGallery(meetPeople) {
      Ember.set(this, 'meetPeopleToOpenGal', meetPeople);
      Ember.set(this, 'step', 4);
    },
    closeModal: function closeModal() {
      this.step == 4 ? Ember.set(this, 'step', 3) : Ember.set(this, 'close', false);
    }
  }), _EmberComponent$exte));

  _exports.default = _default;
});