define("teelt-frontend/templates/board/contents/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2NbRpiha",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"style\",\"max-width: 1100px;margin: auto;\"],[9],[0,\"\\n  \"],[1,[27,\"homepage/homepage-block\",null,[[\"edit\",\"templateId\"],[true,[23,[\"model\",\"templateId\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/contents/home.hbs"
    }
  });

  _exports.default = _default;
});