define("teelt-frontend/components/template/new-template-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['new-template-selection'],
    store: Ember.inject.service(),
    page: 0,
    limit: 6,
    canLoadMore: true,
    query: '',
    init: function init() {
      this._super.apply(this, arguments);
    },
    onStepChanged: Ember.observer('step', function () {
      if (this.step == 1) {
        this.$('.step1').show(400);
        this.$('.step2').hide(400);
        this.$('.step3').hide(400);
        Ember.set(this, 'modelId', null);
        Ember.set(this.template, 'defaultTemplateId', undefined);
      } else if (this.step == 2) {
        this.loadPage();
        this.$('.step1').hide(400);
        this.$('.step2').show(400);
        this.$('.step3').hide(400);
      } else if (this.step == 3) {
        this.$('.step1').hide(400);
        this.$('.step2').hide(400);
        this.$('.step3').show(400);
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.template && this.template.id) {
        this.loadPage();
        this.$('.step1').hide();
        this.$('.step2').show();
        this.$('.step3').hide();
      } else if (this.template && this.template.defaultTemplateId) {
        Ember.set(this, 'modelId', this.template.defaultTemplateId);
        this.$('.step1').hide();
        this.$('.step2').hide();
        this.$('.step3').show();
      } else {
        this.$('.step1').show();
        this.$('.step2').hide();
        this.$('.step3').hide();
      }
    },
    loadPage: function loadPage() {
      var _this = this;

      var params = {};
      params.offset = this.page * this.limit;
      params.limit = this.limit;
      params.search = this.query;
      params.type = this.template.type;
      params.model = true;
      Ember.set(this, 'canLoadMore', false);
      this.store.query('template', params).then(function (data) {
        if (data && data.length >= _this.limit) {
          Ember.set(_this, 'canLoadMore', true);
        }

        Ember.set(_this, "templateModels", data.toArray());
      }).finally(function () {
        Ember.set(_this, 'isLoading', false);
      });
    },
    actions: {
      changeType: function changeType(type) {
        if (this.triggerChange) {
          this.triggerChange(type);
        } else {
          Ember.set(this.template, 'type', type);
        }
      },
      onSearchKeyUp: function onSearchKeyUp() {
        var _this2 = this;

        if (this.keyUpAction) {
          Ember.run.cancel(this.keyUpAction);
        }

        var keyUpAction = Ember.run.later(function () {
          Ember.set(_this2, 'page', 0);

          _this2.loadPage();
        }, 300);
        Ember.set(this, 'keyUpAction', keyUpAction);
      },
      selectModel: function selectModel(model) {
        if (!model) {
          Ember.set(this, 'modelId', undefined);
          Ember.set(this.template, 'defaultTemplateId', undefined);
        } else {
          Ember.set(this, 'modelId', model.id);
          Ember.set(this.template, 'defaultTemplateId', model.id);
        }
      },
      next: function next() {
        Ember.set(this, 'page', this.page + 1);
        this.loadPage();
      },
      prev: function prev() {
        Ember.set(this, 'page', this.page - 1);
        this.loadPage();
      }
    }
  });

  _exports.default = _default;
});