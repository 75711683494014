define("teelt-frontend/templates/components/session/auto-disconnect-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1uDQe72b",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"showMessage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"disconnected-popup\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"close-popup\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showMessage\"]]],null],false]],[9],[0,\"X\"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"disconnect-message\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"auto_disconnect.disconnect_message\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/session/auto-disconnect-message.hbs"
    }
  });

  _exports.default = _default;
});