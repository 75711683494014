define("teelt-frontend/components/template/table/templates-custom-table-filter", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['custom-table-filter', 'custom-talent-filter'],
    intl: Ember.inject.service(),
    uiUtils: Ember.inject.service('ui-utils'),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    staticlikeData: Ember.inject.service('staticlike-data'),
    userService: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var keyFilters = [{
        key: 'type',
        type: 'select',
        operator: 'IN'
      }];

      if (!this.isModelList) {
        keyFilters.push({
          key: 'status',
          type: 'select',
          operator: 'IN'
        });
      }

      if (this.filterToRemove) {
        keyFilters = keyFilters.filter(function (k) {
          return !_this.filterToRemove.includes(k.key);
        });
      }

      Ember.set(this, 'keyFilters', keyFilters);
    },
    initSelectType: function initSelectType() {
      this.clearFilterParams();
      var selectValues = [{
        key: "welcome",
        label: this.intl.t('template.type.welcome.title')
      }, {
        key: "migration",
        label: this.intl.t('template.type.migration.title')
      }, {
        key: "end",
        label: this.intl.t('template.type.end.title')
      }, {
        key: "assignation",
        label: this.intl.t('template.type.assignation.title')
      }];
      Ember.set(this, "selectValues", selectValues);
    },
    initSelectStatus: function initSelectStatus() {
      this.clearFilterParams();
      var selectValues = [{
        key: "active",
        label: this.intl.t('template.active')
      }, {
        key: "inactive",
        label: this.intl.t('template.inactive')
      }, {
        key: "disabled",
        label: this.intl.t('template.disabled')
      }];
      Ember.set(this, "selectValues", selectValues);
    },
    clearFilterParams: function clearFilterParams() {
      Ember.set(this, 'searchPath', null);
      Ember.set(this, 'selectValues', null);
    },
    actions: {
      setComplexFilter: function setComplexFilter(key) {
        var _this2 = this;

        Ember.set(this, "selectValues", undefined);
        Ember.set(this, 'selectedKey', JSON.parse(JSON.stringify(key)));
        setTimeout(function () {
          _this2.$('.select').formSelect();
        }, 100);
        setTimeout(function () {
          if (key.type == 'select' && key.key == 'type') {
            _this2.initSelectType();
          }

          if (key.type == 'select' && key.key == 'status') {
            _this2.initSelectStatus();
          }
        }, 300);
      },
      onOperatorChange: function onOperatorChange(operator) {
        Ember.set(this.selectedKey, 'operator', operator);
      },
      validate: function validate() {
        this.onFilterChanged(this.selectedKey);
        Ember.set(this, 'selectedKey', undefined);
        Ember.set(this, 'showFilters', false);
      },
      onSelectChanged: function onSelectChanged(value) {
        Ember.set(this.selectedKey, "value1", value);
      },
      convertSearchableResult: function convertSearchableResult(result) {
        if (this.selectedKey.key == 'manager' || this.selectedKey.key == 'officeManager' || this.selectedKey.key == 'sponsor') {
          return this.userService.getTalentName(result);
        }

        return result[this.keyValue];
      }
    }
  });

  _exports.default = _default;
});