define("teelt-frontend/templates/components/ai/new-module-by-ai/new-module-by-ai-administrative", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xbj0ujrX",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[12,\"class\",[28,[\"container-fluid \",[27,\"if\",[[27,\"not\",[[23,[\"showAI\"]]],null],\"invisible-module-ai\"],null]]]],[9],[0,\"\\n   \\n    \"],[7,\"div\"],[11,\"class\",\"g-window\"],[9],[0,\"\\n          \"],[7,\"a\"],[11,\"href\",\"#0\"],[11,\"class\",\"g-window-close\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showAI\"]]],null],false]],[9],[7,\"i\"],[11,\"class\",\"material-icons grey-text\"],[9],[0,\"clear\"],[10],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"col s12\"],[9],[0,\"\\n                \"],[7,\"div\"],[11,\"id\",\"output\"],[9],[10],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"question\"],[9],[0,\"\\n                    \"],[1,[27,\"textarea\",null,[[\"value\",\"maxlength\",\"key-up\",\"disabled\"],[[23,[\"question\"]],\"65000\",[27,\"action\",[[22,0,[]],\"handleKeyUp\"],null],[23,[\"waitingAI\"]]]]],false],[0,\"\\n                    \"],[7,\"div\"],[11,\"class\",\"send-to-ai\"],[9],[0,\"\\n                        \"],[7,\"a\"],[11,\"class\",\"btn-flat foreground-primary-color btn-rounded waves-effect waves-light\"],[12,\"disabled\",[27,\"gte\",[[23,[\"consumption\"]],100],null]],[3,\"action\",[[22,0,[]],\"chatCompletion\"]],[9],[7,\"span\"],[11,\"class\",\"material-icons\"],[9],[0,\"send\"],[10],[10],[0,\"\\n                    \"],[10],[0,\"\\n                \"],[10],[0,\"\\n               \\n            \"],[10],[0,\"\\n        \"],[10],[0,\"         \\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/ai/new-module-by-ai/new-module-by-ai-administrative.hbs"
    }
  });

  _exports.default = _default;
});