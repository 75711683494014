define("teelt-frontend/routes/board/setting/talent-data", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.eventBus.on('RELOAD_CUSTOMFIELDS', this, 'loadCustomFields');
    },
    destroy: function destroy() {
      this.eventBus.off('RELOAD_CUSTOMFIELDS', this, 'loadCustomFields');

      this._super.apply(this, arguments);
    },
    loadCustomFields: function loadCustomFields() {
      var _this = this;

      this.ajax.requestPromise(_environment.default.APP.API_HOST + '/custom-fields', 'GET').then(function (customFields) {
        Ember.set(_this.currentModel, "customFields", customFields);
      });
    },
    afterModel: function afterModel(model) {
      var _this2 = this;

      setTimeout(function () {
        _this2.loadCustomFields();
      });
    },
    actions: {
      openAddCustomModal: function openAddCustomModal() {
        Ember.set(this.currentModel, "updateCustomField", undefined);
        Ember.set(this.currentModel, "showAddCustomModal", true);
      },
      openActions: function openActions(id) {
        Ember.set(this.currentModel, 'showActions', id);
      },
      editCustomField: function editCustomField(customField) {
        Ember.set(this.currentModel, 'showActions', false);
        Ember.set(this.currentModel, "updateCustomField", customField);
        Ember.set(this.currentModel, "showAddCustomModal", true);
      },
      prepareDeleteCustom: function prepareDeleteCustom(customField) {
        Ember.set(this.currentModel, 'showActions', false);
        Ember.set(this.currentModel, 'customToDelete', customField);
      },
      removeCustom: function removeCustom(response) {
        var _this3 = this;

        if (response) {
          var customId = this.currentModel.customToDelete.id;
          var path = _environment.default.APP.API_HOST + '/custom-fields/' + customId;
          this.ajax.requestPromise(path, 'DELETE').then(function () {
            _this3.loadCustomFields();
          });
        }
      }
    }
  });

  _exports.default = _default;
});