define("teelt-frontend/helpers/get-model-attribute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Helper.extend({
    store: Ember.inject.service(),
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 3),
          data = _ref2[0],
          attribute = _ref2[1],
          model = _ref2[2];

      if (data.store) {
        return data.get(attribute);
      } else {
        var entity = this.store.peekRecord(model, data.id);

        if (!entity) {
          entity = this.store.createRecord(model, data);
        }

        return entity.get(attribute);
      }
    }
  });

  _exports.default = _default;
});