define("teelt-frontend/components/utils/progress-bar-file-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['progress-bar-file-input'],
    valueObserver: Ember.observer('value', function () {
      var _this = this;

      setTimeout(function () {
        _this.$('.inner-bar').width(_this.value + '%');
      }, 0);
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.value) {
        this.$('.inner-bar').width(this.value + '%');
      }
    }
  });

  _exports.default = _default;
});