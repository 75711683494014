define("teelt-frontend/templates/components/communication/communication-result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "65gsOcQZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"g-section-title foreground-primary-color\"],[9],[0,\"\\n            \"],[7,\"h1\"],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"communication.result.title\"],[[\"name\"],[[23,[\"campaign\",\"name\"]]]]]],null],false],[10],[0,\"\\n          \"],[10],[0,\"\\n      \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"campaign\",\"sends\",\"length\"]],0],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"selection-send\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"lt\",[[27,\"add\",[[23,[\"sendIndex\"]],1],null],[23,[\"campaign\",\"sends\",\"length\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"previous-send btn background-primary-color floating-l clickable\"],[3,\"action\",[[22,0,[]],\"previous\"]],[9],[0,\"\\n          \"],[1,[27,\"t\",[\"communication.result.previous\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"gt\",[[23,[\"sendIndex\"]],0],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"next-send btn background-primary-color floating-r clickable\"],[3,\"action\",[[22,0,[]],\"next\"]],[9],[0,\"\\n          \"],[1,[27,\"t\",[\"communication.result.next\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \\n\"],[4,\"if\",[[23,[\"showResult\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"communication/communication-result-send\",null,[[\"campaign\",\"sendId\"],[[23,[\"campaign\"]],[23,[\"currentSendId\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/communication/communication-result.hbs"
    }
  });

  _exports.default = _default;
});