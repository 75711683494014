define("teelt-frontend/routes/board/setting/privacy", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    userService: Ember.inject.service(),
    ajax: Ember.inject.service(),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      var allRoles = ["ROLE_RH", "ROLE_MANAGER"];

      if (model.company.activeOMRole) {
        allRoles.push("ROLE_CUSTOM_OFFICE_MANAGER");
      }

      if (model.company.activeAdministrative) {
        allRoles.push("ROLE_ADMINISTRATIVE");
      }

      if (model.company.activeMRH) {
        allRoles.push("ROLE_MRH");
      }

      var path2 = _environment.default.APP.API_HOST + '/roles?consultRoles=true';
      this.ajax.requestPromise(path2).then(function (roles) {
        if (roles) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = roles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var role = _step.value;
              allRoles.push("ROLE_CONSULT-" + role.id);
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        }

        Ember.set(model, 'allRoles', allRoles);
      });
    },
    actions: {
      handleFocusOut: function handleFocusOut() {
        this.currentModel.company.save();
      },
      interceptEnter: function interceptEnter(e1, e2) {
        if (e2.keyCode == 13) {
          this.send('handleFocusOut', e2);
          return false;
        }

        return true;
      },
      inactivityInterceptEnter: function inactivityInterceptEnter(e1, e2) {
        if (e2.which > 48 && e2.which < 53) {
          e2.preventDefault();
        }

        this.send("interceptEnter", e1, e2);
      },
      didTransition: function didTransition() {
        this._super.apply(this, arguments);

        if (this.currentModel.company.essential) {
          setTimeout(function () {
            var elements = document.querySelectorAll('.items-container');

            if (elements && elements.length > 2) {
              for (var i = 0; i < elements.length; i++) {
                if (i > 1) {
                  elements[i].style.display = 'none';
                }
              }
            }
          }, 0);
        }
      },
      openModalRule: function openModalRule() {
        Ember.set(this.currentModel, 'showRolesModal', true);
        Ember.set(this.currentModel, 'selectedRoles', this.currentModel.company.excludedRolesForDeleteUserAfterEndDates);
      },
      addRemoveRole: function addRemoveRole(role) {
        this.addRemoveViewResult(role);
      },
      onClickOverlay: function onClickOverlay() {
        Ember.set(this.currentModel, 'showRolesModal', false);
      },
      saveExcludedRolesForDeleteUserAfterEndDates: function saveExcludedRolesForDeleteUserAfterEndDates() {
        var _this = this;

        this.currentModel.company.save().then(function () {
          Ember.set(_this.currentModel, 'showRolesModal', false);
        });
      }
    },
    addRemoveViewResult: function addRemoveViewResult(role) {
      var excludedRolesForDeleteUserAfterEndDates = this.currentModel.company.excludedRolesForDeleteUserAfterEndDates;

      if (!excludedRolesForDeleteUserAfterEndDates) {
        excludedRolesForDeleteUserAfterEndDates = [];
      }

      var index = excludedRolesForDeleteUserAfterEndDates.indexOf(role);

      if (index >= 0) {
        excludedRolesForDeleteUserAfterEndDates.splice(index, 1);
      } else {
        excludedRolesForDeleteUserAfterEndDates.push(role);
      }

      Ember.set(this.currentModel.company, 'excludedRolesForDeleteUserAfterEndDates', undefined);
      Ember.set(this.currentModel.company, 'excludedRolesForDeleteUserAfterEndDates', excludedRolesForDeleteUserAfterEndDates);
      Ember.set(this.currentModel, 'selectedRoles', undefined);
      Ember.set(this.currentModel, 'selectedRoles', this.currentModel.company.excludedRolesForDeleteUserAfterEndDates);
    }
  });

  _exports.default = _default;
});