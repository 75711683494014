define("teelt-frontend/templates/components/form/form-builder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gok1G/33",
    "block": "{\"symbols\":[\"field\",\"index\"],\"statements\":[[4,\"if\",[[23,[\"form\",\"fields\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"form-items-container\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"form\",\"fields\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"form/form-item\",null,[[\"index\",\"field\",\"formType\",\"onDelete\",\"onDuplicate\",\"adminForm\"],[[22,2,[]],[22,1,[]],[23,[\"form\",\"type\"]],[27,\"action\",[[22,0,[]],\"onDeleteItem\"],null],[27,\"action\",[[22,0,[]],\"onDuplicateItem\"],null],[23,[\"adminForm\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"a\"],[11,\"class\",\"btn btn-white waves-effect waves-light add-new-field-btn\"],[3,\"action\",[[22,0,[]],\"addNewField\"]],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"add\"],[10],[7,\"div\"],[9],[1,[27,\"t\",[\"form.field.add_new\"],null],false],[10],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/form/form-builder.hbs"
    }
  });

  _exports.default = _default;
});