define("teelt-frontend/components/settings/custom-labels", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['custom-labels'],
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var path = _environment.default.APP.API_HOST + '/customlabels';
      this.ajax.requestPromise(path, 'GET').then(function (customLabels) {
        if (!customLabels) {
          customLabels = {};
        }

        Ember.set(_this, 'customLabels', customLabels);
      });
    },
    didInsertElement: function didInsertElement() {
      var textareas = document.querySelector('.materialize-textarea');

      if (textareas) {
        M.textareaAutoResize(document.querySelector('.materialize-textarea'));
      }
    },
    actions: {
      initLang: function initLang(targetLabel) {
        var _this2 = this;

        Ember.set(this, 'targetLabel', targetLabel);

        switch (targetLabel) {
          case 'periodOnboardingLabel':
            Ember.set(this, 'traductionTitle', 'template.type.welcome.title');
            break;

          case 'periodMigrationLabel':
            Ember.set(this, 'traductionTitle', 'template.type.migration.title');
            break;

          case 'periodEndLabel':
            Ember.set(this, 'traductionTitle', 'template.type.end.title');
            break;

          case 'talentLabel':
            Ember.set(this, 'traductionTitle', 'word.talent');
            break;
        }

        Ember.set(this, 'langs', {
          de: '',
          en: '',
          es: '',
          fr: '',
          it: '',
          pl: '',
          pt: ''
        });
        var path = _environment.default.APP.API_HOST + '/customlabels/all';
        this.ajax.requestPromise(path, 'GET').then(function (customLabels) {
          customLabels.forEach(function (customLabel) {
            Ember.set(_this2.langs, customLabel.lang, customLabel[targetLabel]);
          });
          Ember.set(_this2, 'showModal', true);
        });
      },
      save: function save() {
        var _this3 = this;

        Ember.set(this.langs, 'targetLabel', this.targetLabel);
        var path = _environment.default.APP.API_HOST + '/customlabels';
        this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(this.langs)).then(function (customLabels) {
          Ember.set(_this3, 'customLabels', customLabels);

          _this3.eventBus.trigger('REFRESH_CUSTOM_LABELS');

          Ember.set(_this3, 'showModal', false);
        });
      },
      handleFocusOut: function handleFocusOut() {
        var _this4 = this;

        var path = _environment.default.APP.API_HOST + '/customlabels/patchone';
        this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(this.customLabels)).then(function (customLabels) {
          Ember.set(_this4, 'customLabels', customLabels);

          _this4.eventBus.trigger('REFRESH_CUSTOM_LABELS');
        });
      },
      interceptEnter: function interceptEnter(e1, e2) {
        if (e2.keyCode == 13) {
          this.send('handleFocusOut', e2);
          return false;
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});