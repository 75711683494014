define("teelt-frontend/components/utils/keyword-input", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    addNewKeyword: function addNewKeyword(value) {
      var keywords = this.keywords;
      keywords.pushObject({
        name: value
      });
      this.set("keywords", keywords);
      this.set("tempValue", '');
    },
    actions: {
      focusOnInput: function focusOnInput() {
        (0, _jquery.default)("#keywords-new-" + this.get('name') + " input").focus();
      },
      onKeyUp: function onKeyUp(value, key) {
        if (value && value.trim() != '') {
          if (key.keyCode == 13) {
            this.addNewKeyword(value);
          } else if (key.keyCode == 188) {
            this.addNewKeyword(value.substring(0, value.length - 1));
          }
        }
      },
      deleteKeyword: function deleteKeyword(index) {
        var alreadyKeywords = this.keywords;
        var keywords = [];

        for (var i = 0; i < alreadyKeywords.length; i++) {
          if (i !== index) {
            keywords.push(alreadyKeywords[i]);
          }
        }

        this.set("keywords", keywords);
      },
      click: function click(index) {
        if (this.clickOnKeyword) {
          this.clickOnKeyword(this.keywords[index]);
        }
      }
    }
  });

  _exports.default = _default;
});