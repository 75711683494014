define("teelt-frontend/templates/talent/integration/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ipQFQnlx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"not\",[[23,[\"model\",\"mobileView\"]]],null]],null,{\"statements\":[[4,\"link-to\",[\"talent.integration.forms\"],[[\"class\"],[\"talent-return\"]],{\"statements\":[[0,\"      \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"arrow_back\"],[10],[1,[23,[\"model\",\"form\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[27,\"form/form-preview\",null,[[\"form\",\"canRespond\",\"noTitle\",\"talentId\",\"onCancel\",\"onResponseSent\"],[[23,[\"model\",\"form\"]],true,true,[23,[\"model\",\"talent\",\"id\"]],[27,\"route-action\",[\"back\"],null],[27,\"route-action\",[\"reloadUser\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/talent/integration/form.hbs"
    }
  });

  _exports.default = _default;
});