define("teelt-frontend/components/content/content-document-block", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['content-document-block'],
    classNameBindings: ['overrideByOffice'],
    fileUploadService: Ember.inject.service('file-upload'),
    customErrorService: Ember.inject.service('custom-error'),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    contentService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.forceOpen) {
        this.send('openDoc');
      }
    },
    openDetailContentModal: function openDetailContentModal() {
      var _this = this;

      if (this.content.fullValue) {
        Ember.set(this, 'showContentDetailPopup', true);
      } else {
        var path = _environment.default.APP.API_HOST + '/contents/' + this.content.id;
        this.ajax.requestPromise(path).then(function (content) {
          Ember.set(_this.content, 'fullValue', content.fullValue);
          Ember.set(_this, 'showContentDetailPopup', true);
        });
      }
    },
    actions: {
      openFile: function openFile() {
        var _this2 = this;

        if (!this.content.fullValue) return;
        Ember.set(this, "showDownloadingModal", true);
        this.fileUploadService.getObjectUrl(this.content.fullValue).then(function (data) {
          var url = data.url;

          if (!_this2.content.toDownload) {
            url += "&inline";
          }

          window.open(url, 'newtab');
          Ember.set(_this2, "showDownloadingModal", false);
        }).catch(function (err) {
          _this2.customErrorService.handleAjaxError(err);
        });
      },
      openDoc: function openDoc() {
        if (this.content.fullValue && this.content.type == 'filecard') {
          this.send('openFile');
        } else if (this.content.type != 'map') {
          this.send('showContentDetail');
        }
      },
      showContentDetail: function showContentDetail() {
        this.openDetailContentModal();
      },
      showContentConditionsModal: function showContentConditionsModal(content) {
        Ember.set(this, 'currentConditionsContent', content);
        Ember.set(this, 'showContentConditionsModal', true);
      },
      removeContent: function removeContent(contentId) {
        Ember.set(this, "contentToRemoveId", contentId);
        Ember.set(this, "showRemovePopup", true);
      },
      removeConfirm: function removeConfirm(response) {
        var _this3 = this;

        if (response) {
          var path = _environment.default.APP.API_HOST + '/contents/' + this.contentToRemoveId;
          this.ajax.requestPromise(path, 'DELETE').then(function () {
            if (_this3.onChange) _this3.onChange();
          });
        }
      }
    }
  });

  _exports.default = _default;
});