define("teelt-frontend/components/task/task-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['task-box'],
    actions: {
      triggerTask: function triggerTask(task) {
        if (this.triggerTask) this.triggerTask(task);
      },
      showDetail: function showDetail(task) {
        var _this = this;

        if (this.showDetail == task.id) {
          Ember.set(this, "showDetail", undefined);
        } else {
          var description = task.description;
          Ember.set(task, "description", undefined);
          Ember.set(this, "showDetail", task.id);
          setTimeout(function () {
            if (!description) {
              description = _this.intl.t("task.no_description");
            }

            Ember.set(task, "description", description);
          }, 0);
        }
      }
    }
  });

  _exports.default = _default;
});