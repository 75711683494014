define("teelt-frontend/components/settings/table/role-custom-table-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['role-custom-table-content'],
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    actions: {
      edit: function edit(role) {
        var roleId = role.id;

        if (!roleId || role.id <= 0) {
          roleId = role.roleType;
        }

        this.router.transitionTo('board.setting.role-edit', roleId);
      }
    }
  });

  _exports.default = _default;
});