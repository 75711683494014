define("teelt-frontend/templates/talent/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aLHd8wSx",
    "block": "{\"symbols\":[\"menuItem\",\"index\"],\"statements\":[[7,\"div\"],[11,\"id\",\"talent-profile\"],[11,\"class\",\"g-talent-route\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"g-talent-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"model\",\"sliderMenu\",\"length\"]],1],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"col s12 controls center\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"sliderMenu\"]]],null,{\"statements\":[[0,\"            \"],[7,\"button\"],[12,\"class\",[28,[\"btn g-talent-btn \",[27,\"if\",[[27,\"eq\",[[23,[\"model\",\"selectedMenu\"]],[22,2,[]]],null],\"selected\"],null]]]],[3,\"action\",[[22,0,[]],\"goTo\",[22,1,[]]]],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col s12\"],[9],[0,\"\\n        \"],[1,[21,\"outlet\"],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/talent/profile.hbs"
    }
  });

  _exports.default = _default;
});