define("teelt-frontend/components/settings/new-customfield-modal", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['new-customfield-form', 'new-customfield-modal'],
    types: ['text', 'textarea', 'date', 'date_time', 'file', 'multiple_choice', 'opinion_scale', 'email', 'country', 'french_dept', 'french_city', 'iban', 'bic', 'social_number', 'autocomplete_statics'],
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.customfield) {
        Ember.set(this, 'customfield', {
          name: '',
          type: 'text',
          properties: {}
        });
      } else {
        Ember.set(this, 'update', true);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('#cf-type').formSelect();
    },
    actions: {
      onClickOverlay: function onClickOverlay() {},
      onTypeChanged: function onTypeChanged(type) {
        Ember.set(this.customfield, 'type', type);
      },
      close: function close() {
        Ember.set(this, 'show', false);
      },
      save: function save() {
        var _this = this;

        if (this.customfield.name && this.customfield.name.length) {
          if (!this.update) {
            var path = _environment.default.APP.API_HOST + '/custom-fields';
            this.ajax.requestWithBodyPromise(path, 'POST', 'application/json', JSON.stringify(this.customfield)).then(function () {
              _this.eventBus.trigger('RELOAD_CUSTOMFIELDS');

              Ember.set(_this, 'show', false);
            }).catch(function (err) {
              if (err.responseJSON && err.responseJSON.code == 'cf_already_exists') {
                Ember.set(_this, 'alreadyExists', true);
              }
            });
          } else {
            var _path = _environment.default.APP.API_HOST + '/custom-fields/' + this.customfield.id;

            this.ajax.requestWithBodyPromise(_path, 'PATCH', 'application/json', JSON.stringify(this.customfield)).then(function () {
              _this.eventBus.trigger('RELOAD_CUSTOMFIELDS');

              Ember.set(_this, 'show', false);
            });
          }
        }
      },
      cfRequiredChanged: function cfRequiredChanged() {
        Ember.set(this.customfield, 'required', !this.customfield.required);
      }
    }
  });

  _exports.default = _default;
});