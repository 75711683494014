define("teelt-frontend/components/audience/audiences-list", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    countUrl: _environment.default.APP.API_HOST + "/audiences/count",
    audiencesNb: 0,
    classNames: ['audiences-list'],
    modelName: 'audience',
    keys: ['name', 'userCount', 'userCount30d'
    /*, 'templateCount', 'moduleCount'*/
    , 'actions'],
    init: function init() {
      this._super.apply(this, arguments);

      this.refreshCount();
      this.eventBus.on('REFRESH_AUDIENCES', this, 'onAudienceRefresh');
    },
    refreshCount: function refreshCount() {
      var _this = this;

      this.ajax.requestPromise(this.countUrl).then(function (count) {
        Ember.set(_this, 'audiencesNb', count);
      });
    },
    destroy: function destroy() {
      this.eventBus.off('REFRESH_AUDIENCES', this, 'onAudienceRefresh');

      this._super.apply(this, arguments);
    },
    onAudienceRefresh: function onAudienceRefresh() {
      Ember.set(this, 'reloadTimestamp', new Date());
      this.refreshCount();
    },
    actions: {
      onRowSelected: function onRowSelected(audience) {
        this.router.transitionTo('board.audience.audience-edit', audience);
      }
    }
  });

  _exports.default = _default;
});