define("teelt-frontend/components/talents/talents-import", ["exports", "teelt-frontend/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talents-import'],
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    firstLineIgnored: false,
    progress: 0,
    dataType: 'csv',
    step: 1,
    minStep: 1,
    csvFileHeader: [],
    talentKeys: [{
      key: null,
      label: "firstname",
      selected: null
    }, {
      key: null,
      label: "lastname",
      selected: null
    }, {
      key: null,
      label: "proEmail",
      selected: null
    }, {
      key: null,
      label: "persoEmail",
      selected: null
    }, {
      key: null,
      label: "phoneCode",
      selected: null
    }, {
      key: null,
      label: "phoneNumber",
      selected: null
    }, {
      key: null,
      label: "team",
      selected: null
    }, {
      key: null,
      label: "position",
      selected: null
    }, {
      key: null,
      label: "contract",
      selected: null
    }, {
      key: null,
      label: "welcomeDate",
      selected: null
    }, {
      key: null,
      label: "endDate",
      selected: null
    }, {
      key: null,
      label: "migrationDate",
      selected: null
    }, {
      key: null,
      label: "lang",
      selected: null
    }, {
      key: null,
      label: "trialPeriodEndDate",
      selected: null
    }],
    csvDatas: [],
    columns: [],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('.fixed-action-btn').floatingActionButton({
        toolbarEnabled: true
      });

      if (!this.companyId) {
        Ember.set(this, 'companyId', this.session.data.authenticated.user.company.id);
      }

      ;
      this.$('.settings').show(500);
    },
    onProgress100: Ember.observer('progress', function () {
      var _this2 = this;

      if (this.progress === 100) {
        if (this.onImported) {
          setTimeout(function () {
            _this2.onImported(_this2.added);
          }, 1000);
        }
      }
    }),
    initDropzone: function initDropzone() {
      Ember.set(this, 'request', {
        separator: ',',
        ignoreFirstLine: false,
        dateFormat: 'YYYY-MM-DDTHH:mm:ssZ',
        gmtOffset: (0, _moment.default)().utcOffset()
      });

      var _this = this;

      var myDropzone = new Dropzone(".dropzone-action", {
        url: 'https://www.teelt.io',
        uploadMultiple: false,
        maxFiles: 1,
        createImageThumbnails: false,
        addRemoveLinks: true,
        acceptedFiles: 'text/csv',
        addedfile: function addedfile(file) {
          _this.resetAll();

          if (file.name.substring(file.name.length - 3).toLowerCase() === 'csv') {
            Ember.set(_this, 'fileName', file.name);
            Ember.set(_this, 'file', file);
          } else {
            _this.notify.error(_this.intl.t('talent.import.dropzone_badextension'));

            _this.dropzone.removeAllFiles();
          }
        }
      });
      Ember.set(this, 'dropzone', myDropzone);
    },
    seek: function seek(file, offset, CHUNK_SIZE, fr) {
      if (offset >= file.size) {
        Ember.set(this, "progress", 100);
        return true;
      }

      var progress = Math.round(offset * 100 / file.size);
      Ember.set(this, "progress", progress);
      var slice = file.slice(offset, offset + CHUNK_SIZE);
      fr.readAsArrayBuffer(slice);
      return false;
    },
    loadDatas: function loadDatas(customSeparator) {
      var _this3 = this;

      this.resetAll();
      var reader = new FileReader();

      reader.onload = function (e) {
        var contents = e.target.result;
        var lines = contents.split('\n');

        var sep = _this3.detectSeparator(lines[0]);

        Ember.set(_this3.request, 'detectedSeparator', sep);

        if (customSeparator) {
          sep = customSeparator;
        } else {
          Ember.set(_this3.request, 'separator', sep);
        }

        var firstLine = lines[0].split(sep);
        var sampleLine = lines[1].split(sep); //init columns

        var columnObjects = [];
        firstLine.forEach(function (column) {
          var columnObject = {
            key: column,
            talent: null,
            selected: true
          };
          columnObjects.push(columnObject);
        });
        Ember.set(_this3, 'columns', columnObjects); // get all other lines in an array

        var linesArray = [];

        for (var i = 0; i < lines.length; i++) {
          linesArray.push(lines[i].split(_this3.request.separator));
        }

        Ember.set(_this3, 'csvDatas', linesArray);
        Ember.set(_this3, 'csvFileHeader', firstLine);
        var lineObjects = [];
        firstLine.forEach(function (column) {
          var lineObject = {
            header: column,
            keepColumn: true,
            selected: null,
            sample: sampleLine[firstLine.indexOf(column)]
          };
          lineObjects.push(lineObject);
        });
        Ember.set(_this3, 'csvFileHeaderObj', lineObjects);
      };

      if (this.file) {
        reader.readAsText(this.file, 'iso-8859-1');
      }
    },
    treatcSvAndCreateTalentObjectList: function treatcSvAndCreateTalentObjectList() {
      var _this4 = this;

      var talentObjectList = [];
      var csvDatas = this.csvDatas; //create user list from csv with only selected talent keys

      csvDatas.forEach(function (line) {
        var talentObject = {};

        _this4.columns.forEach(function (column) {
          if (column.talent && column.key && column.selected) {
            talentObject[column.talent] = line[_this4.csvFileHeader.indexOf(column.key)];
          }
        });

        talentObjectList.push(talentObject);
      }); //remove lines if all values are empty

      talentObjectList.forEach(function (talentObject) {
        var empty = true;

        for (var key in talentObject) {
          if (talentObject.hasOwnProperty(key)) {
            if (talentObject[key]) {
              empty = false;
            }
          }
        }

        if (empty) {
          talentObjectList.splice(talentObjectList.indexOf(talentObject), 1);
        }
      });
      return talentObjectList;
    },
    validateEmail: function validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    resetErrors: function resetErrors() {
      Ember.set(this, 'requiredFieldsError', false);
      Ember.set(this, 'invalidProEmailError', false);
      Ember.set(this, 'invalidPersoEmailError', false);
    },
    resetAll: function resetAll(withFile) {
      if (withFile) {
        this.send('removeFile');
      }

      Ember.set(this, 'csvDatas', []);
      Ember.set(this, 'columns', []);
      Ember.set(this, 'treatDatas', []);
      Ember.set(this, 'talentColumns', []);
      Ember.set(this, 'csvFileHeader', []);
      Ember.set(this, 'csvFileHeaderObj', []);
      Ember.set(this, 'importType', null);
      Ember.set(this, 'firstLineIgnored', false);
      Ember.set(this, 'allRequiredFieldsSelected', false); //reset talentKeys, set key and selected to null

      var talentKeys = this.talentKeys;
      Ember.set(this, 'talentKeys', null);
      talentKeys.forEach(function (column) {
        Ember.set(column, 'selected', null);
        Ember.set(column, 'key', null);
      });
      Ember.set(this, 'talentKeys', talentKeys);
      this.resetErrors();
    },
    checkDatas: function checkDatas() {
      var _this5 = this;

      var setNullIfInvalid = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.resetErrors();
      var datas = this.treatDatas; //check that all firstname, lastname and proEmail are not empty

      var error = false;
      datas.forEach(function (data) {
        if (!data.firstname || !data.lastname || !data.proEmail) {
          error = true;
          Ember.set(_this5, 'requiredFieldsError', true);
        }
      }); //check that all proEmail are valid

      datas.forEach(function (data) {
        if (data.persoEmail && !_this5.validateEmail(data.persoEmail.trim())) {
          setNullIfInvalid ? Ember.set(data, "persoEmail", null) : Ember.set(data, "persoEmail", "Non conforme");
        }
      }); //check lang is in ca, de, en, es, fr, it, pl, pt

      datas.forEach(function (data) {
        if (data["lang"]) {
          var langs = ["ca", "de", "en", "es", "fr", "it", "pl", "pt"];

          if (data["lang"] != null && data["lang"] != '' && !langs.includes(data["lang"])) {
            //error = true;
            //set(this,'invalidLangError', true);
            Ember.set(data, "lang", 'fr');
          }
        }
      }); //date controls

      var dateKeys = ["welcomeDate", "endDate", "mobilityDate", "migrationDate", "trialPeriodEndDate"]; // if datas contains dateKeys, format them using request.dateFormat and request.gmtOffset

      var possibleFormats = [// ISO formats
      "YYYY-MM-DDTHH:mm:ss.SSSZ", "YYYY-MM-DDTHH:mm:ssZ", "YYYY-MM-DDTHH:mm:ss", "YYYY-MM-DDTHH:mm:ss.SSS", "YYYY-MM-DDTHH:mm:ss.SSS[Z]", "YYYY-MM-DDTHH:mm:ss[Z]", "YYYY-MM-DD HH:mm:ss", // Common formats with different separators
      "YYYY/MM/DD HH:mm:ss", "MM/DD/YYYY HH:mm:ss", "DD/MM/YYYY HH:mm:ss", "MM-DD-YYYY HH:mm:ss", "DD-MM-YYYY HH:mm:ss", "MM.DD.YYYY HH:mm:ss", "DD.MM.YYYY HH:mm:ss", "YYYY/MM/DD", "MM/DD/YYYY", "DD/MM/YYYY", "MM-DD-YYYY", "DD-MM-YYYY", "MM.DD.YYYY", "DD.MM.YYYY", "DD-MM-YYYY", "DD/MM/YY", "DD-MM-YY", "DD/MM/YYYY HH:mm", "DD-MM-YYYY HH:mm", "DD/MM/YY HH:mm", "DD-MM-YY HH:mm", "DD/MM/YYYY HH:mm:ss", "DD-MM-YYYY HH:mm:ss", "DD/MM/YY HH:mm:ss", "DD-MM-YY HH:mm:ss", // More common formats
      "YYYY-MM-DD", "YYYY.MM.DD", "YYYY/MM/DD", "YYYY-MM-DDTHH:mm:ss.SSS", "YYYY-MM-DDTHH:mm:ss", "YYYY/MM/DDTHH:mm:ss", // Common U.S. date formats
      "MM/DD/YY", "MM/DD/YYYY", "MM-DD-YY", "MM-DD-YYYY", // Common European date formats
      "DD/MM/YY", "DD/MM/YYYY", "DD-MM-YY", "DD-MM-YYYY", // Variants with day names
      "ddd, MMM D, YYYY", "dddd, MMMM D, YYYY", "ddd, D MMM YYYY", "dddd, D MMMM YYYY", // 12-hour clock formats
      "YYYY-MM-DD hh:mm:ss A", "YYYY/MM/DD hh:mm:ss A", "MM/DD/YYYY hh:mm:ss A", "DD/MM/YYYY hh:mm:ss A", "MM-DD-YYYY hh:mm:ss A", "DD-MM-YYYY hh:mm:ss A", "YYYY-MM-DD hh:mm A", "YYYY/MM/DD hh:mm A", "MM/DD/YYYY hh:mm A", "DD/MM/YYYY hh:mm A", "MM-DD-YYYY hh:mm A", "DD-MM-YYYY hh:mm A"];
      datas.forEach(function (data) {
        dateKeys.forEach(function (dateKey) {
          if (data[dateKey] != null && data[dateKey] != "" && data[dateKey] != undefined && data[dateKey].length > 1) {
            var parsedDate = (0, _moment.default)(data[dateKey].trim(), possibleFormats, true); // Strict parsing

            if (parsedDate.isValid()) {
              Ember.set(data, dateKey, parsedDate.utcOffset(_this5.request.gmtOffset).format(_this5.request.dateFormat));
            } else {
              setNullIfInvalid ? Ember.set(data, dateKey, null) : Ember.set(data, dateKey, "Non conforme");
            }
          }
        });
      }); //check contract

      var contracts = ["cdi", "cdd", "internship", "apprenticeship", "professional_training_contract_parttime", "professional_training_contract_fulltime", "mission", "freelance", "external", "secondment"];
      datas.forEach(function (data) {
        if (data["contract"]) {
          if (data["contract"] != null && data["contract"] != '' && !contracts.includes(data["contract"].toLowerCase())) {
            setNullIfInvalid ? Ember.set(data, "contract", null) : Ember.set(data, "contract", "Non conforme");
          } else {
            Ember.set(data, "contract", data["contract"].toLowerCase());
          }
        }
      });
      return error;
    },
    detectSeparator: function detectSeparator(input) {
      var separators = [',', ';', '|', '\t'];
      var idx = separators.map(function (separator) {
        return input.indexOf(separator);
      }).reduce(function (prev, cur) {
        return prev === -1 || cur !== -1 && cur < prev ? cur : prev;
      });
      return input[idx] || ',';
    },
    refreshTalentKeys: function refreshTalentKeys() {
      //update talentKeys
      var talentKeys = this.talentKeys;
      talentKeys.forEach(function (column) {
        if (column.key == headerObj.header && !headerState) {
          Ember.set(column, 'selected', null);
          Ember.set(column, 'key', null);
        }
      });
      Ember.set(this, 'talentKeys', talentKeys);
    },
    actions: {
      changeSeparator: function changeSeparator() {
        if (this.request.separator) {
          this.loadDatas(this.request.separator);
        }
      },
      refreshSeparator: function refreshSeparator() {
        this.loadDatas();
      },
      onClickOverlay: function onClickOverlay() {},
      donwloadCsvSample: function donwloadCsvSample() {
        window.open("/csv/import_example.csv", '_blank');
      },
      importTalents: function importTalents() {
        var _this6 = this;

        var datas = this.treatDatas;
        var request = this.request;
        request.users = datas;
        request.importType = this.importType;
        request.companyId = this.companyId;

        if (!this.checkDatas() && this.importType) {
          var importTalentUrl = _environment.default.APP.API_HOST + '/companies/' + this.companyId + "/import";
          Ember.set(this, 'isLoading', true);
          this.ajax.requestWithBodyPromise(importTalentUrl, 'POST', 'application/json', JSON.stringify(request)).then(function (response) {
            //reinit all
            _this6.resetAll(true);

            Ember.set(_this6, 'step', 1);
            Ember.set(_this6, 'isLoading', false);

            if (_this6.onImported) {
              _this6.onImported(response.second, response.first);
            }
          }).catch(function () {
            _this6.resetAll(true);

            Ember.set(_this6, 'step', 1);
            Ember.set(_this6, 'isLoading', false);

            _this6.notify.error(_this6.intl.t('talent.import.error'));
          });
        }
      },
      openSettings: function openSettings() {
        if (!this.settingsOpened) {
          this.$('.settings').show(500);
        } else {
          this.$('.settings').hide(500);
        }

        Ember.set(this, 'settingsOpened', !this.settingsOpened);
      },
      removeFile: function removeFile() {
        var response = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        if (!response) {
          Ember.set(this, 'showRemoveFileConfirm', false);
          return;
        }

        this.resetAll();
        this.dropzone.removeAllFiles();
        Ember.set(this, 'fileName', undefined);
        Ember.set(this, 'file', undefined);
        this.step > 2 ? Ember.set(this, 'step', 2) : null;
      },
      openFileDialog: function openFileDialog() {
        this.dropzone.hiddenFileInput.click();
      },
      changeType: function changeType(type) {
        Ember.set(this, 'type', type);
      },
      next: function next() {
        var _this7 = this;

        Ember.set(this, 'step', this.step + 1);

        if (this.step == 2) {
          setTimeout(function () {
            _this7.initDropzone();
          }, 300);
        }

        if (this.step == 3) {
          if (!this.file) {
            Ember.set(this, 'step', this.step - 1);
            this.notify.error(this.intl.t('talent.import.dropzone_no_file'));
          }

          if (this.file) {
            this.loadDatas();
          }
        }

        if (this.step == 4) {
          setTimeout(function () {
            _this7.$('.select').formSelect();
          }, 300);
        }

        if (this.step == 5) {
          var datas = this.treatcSvAndCreateTalentObjectList();
          datas.shift();
          Ember.set(this, 'treatDatas', datas);
          this.checkDatas(false);
          var talentColumns = [];
          var columns = this.columns;
          columns.forEach(function (column) {
            if (column.talent) {
              talentColumns.push(column.talent);
            }
          });
          Ember.set(this, 'talentColumns', talentColumns);
        }

        if (this.step == 6) {
          if (this.checkDatas()) {
            Ember.set(this, 'step', this.step - 1);
            return;
          }

          if (!this.importType) {
            Ember.set(this, 'importType', 'merge');
          }
        }
      },
      previous: function previous() {
        var _this8 = this;

        Ember.set(this, 'step', this.step - 1);
        this.step < this.minStep ? Ember.set(this, 'step', this.minStep) : null;

        if (this.step == 4) {
          setTimeout(function () {
            _this8.$('.select').formSelect();
          }, 100);
        }
      },
      onSelectTalentColumn2: function onSelectTalentColumn2(key, col) {
        var _this9 = this;

        var csvFileHeaderObj = this.csvFileHeaderObj;
        Ember.set(this, 'csvFileHeaderObj', null);
        var columns = this.columns;
        columns.forEach(function (column) {
          if (column.key == col.header) {
            Ember.set(column, 'talent', key.label);
          }
        }); //remove key from other columns

        columns.forEach(function (column) {
          if (column.key != col.header && column.talent == key.label) {
            Ember.set(column, 'talent', null);
          }
        });
        Ember.set(this, 'columns', columns); //select talentKeys selected column

        var talentKeys = this.talentKeys;
        Ember.set(this, 'talentKeys', null);
        talentKeys.forEach(function (column) {
          if (column.label == key.label) {
            Ember.set(column, 'selected', key.label);
            Ember.set(column, 'key', col.header);
          } //remove key from other columns


          if (column.label != key.label && column.key == col.header) {
            Ember.set(column, 'selected', null);
            Ember.set(column, 'key', null);
          }
        });
        var allRequiredFieldsSelected = true;
        var requiredFields = ["firstname", "lastname", "proEmail"];
        requiredFields.forEach(function (requiredField) {
          var selected = false;
          talentKeys.forEach(function (column) {
            if (column.selected == requiredField) {
              selected = true;
            }
          });

          if (!selected) {
            allRequiredFieldsSelected = false;
          }
        });
        Ember.set(this, 'allRequiredFieldsSelected', allRequiredFieldsSelected); //set(this, 'requiredFieldsError', true);

        setTimeout(function () {
          Ember.set(_this9, 'csvFileHeaderObj', csvFileHeaderObj);
          Ember.set(_this9, 'talentKeys', talentKeys);
          setTimeout(function () {
            var el = document.getElementById(col.header);
            el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }, 100);
        });
      },
      toggleHeader: function toggleHeader(headerObj) {
        var headerState = !headerObj.keepColumn;
        var csvFileHeaderObj = this.csvFileHeaderObj;
        csvFileHeaderObj.forEach(function (header) {
          if (header.header == headerObj.header) {
            Ember.set(header, 'keepColumn', headerState);
          }
        });
        Ember.set(this, 'csvFileHeaderObj', csvFileHeaderObj); //update columns

        var columns = this.columns;
        columns.forEach(function (column) {
          if (column.key == headerObj.header) {
            Ember.set(column, 'selected', headerState);
          }
        });
        Ember.set(this, 'columns', columns);
      },
      showTutorial: function showTutorial(step) {
        Ember.set(this, 'tutorialStep', step);
        Ember.set(this, 'showTutorial', true);
      }
    }
  });

  _exports.default = _default;
});