define("teelt-frontend/templates/board/company/company-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rADyTMwU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"company-new\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"section-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"g-section-title\"],[9],[0,\"\\n        \"],[7,\"h1\"],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"word.company\"],null]],null],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[1,[27,\"company/company-detail\",null,[[\"company\",\"onModify\"],[[23,[\"model\",\"company\"]],[27,\"route-action\",[\"modify\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/company/company-detail.hbs"
    }
  });

  _exports.default = _default;
});