define("teelt-frontend/components/content/content-assign", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['content-assign'],
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    customErrorService: Ember.inject.service('custom-error'),
    showHome: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var path = _environment.default.APP.API_HOST + '/contents/' + this.contentId + '/sections';
      this.ajax.requestPromise(path, 'GET').then(function (sections) {
        var sectionArray = [];
        var templateIds = [];
        sections.forEach(function (section) {
          if (section.startsWith('template')) {
            templateIds.pushObject(Number(section.substring(8)));
          } else {
            sectionArray.addObject(section);
          }
        });
        Ember.set(_this, 'sections', sectionArray);
        Ember.set(_this, 'selectedTemplateIds', templateIds);
        Ember.set(_this, 'sectionsLoaded', true);
      }).catch(function (err) {
        _this.customErrorService.handleAjaxError(err);
      });
      var user = this.session.data.authenticated.user;
      Ember.set(this, 'user', user);
    },
    actions: {
      toggleHome: function toggleHome() {
        if (this.sections.includes('home')) {
          var newSections = this.sections.removeObject('home'); // hack for helper to work

          Ember.set(this, 'sections', undefined);
          Ember.set(this, 'sections', newSections);
        } else {
          var _newSections = this.sections.addObject('home');

          Ember.set(this, 'sections', undefined);
          Ember.set(this, 'sections', _newSections);
        }
      },
      save: function save() {
        var _this2 = this;

        var allArray = this.sections.concat(this.selectedTemplateIds.map(function (id) {
          return 'template' + id;
        }));
        var path = _environment.default.APP.API_HOST + '/contents/' + this.contentId + '/sections';
        this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify({
          sections: allArray
        })).then(function () {
          _this2.router.transitionTo('board.contents.contents-list');
        }).catch(function (err) {
          _this2.customErrorService.handleAjaxError(err);
        });
      }
    }
  });

  _exports.default = _default;
});