define("teelt-frontend/services/content-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    isContentEmpty: function isContentEmpty(content) {
      return !content || !content.title && (!content.fullValue || content.fullValue == "<p><br></p>") && !content.mediaUrl;
    },
    hideNotVisiblesContents: function hideNotVisiblesContents(contents) {
      if (!contents) return;
      contents = contents.filter(function (content) {
        if (content.genericContent) return content.genericContent.isVisible;
        return content.isVisible;
      });
      return contents;
    }
  });

  _exports.default = _default;
});