define("teelt-frontend/components/homepage/homepage-block", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['homepage-block'],
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    contentHomeSection: Ember.computed('homepage', function () {
      if (this.homepage && this.homepage.template && this.homepage.template.id) {
        return 'hometemplate' + this.homepage.template.id;
      }

      return 'home';
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.talent) {
        Ember.set(this, 'talent', this.session.data.authenticated.user);
      }

      var path = _environment.default.APP.API_HOST + '/homepages/';

      if (this.template && this.template.id) {
        path += 'template/' + this.template.id;
      } else {
        path += 'default';
      }

      Ember.set(this, 'path', path);
      this.ajax.requestPromise(path, 'GET').then(function (homepage) {
        Ember.set(_this, 'homepage', homepage);
        Ember.set(_this, 'showContents', true);
      });
    },
    actions: {
      createHomepage: function createHomepage() {
        var _this2 = this;

        this.ajax.requestPromise(this.path, 'POST').then(function (homepage) {
          Ember.set(_this2, 'homepage', homepage);
        });
      },
      deleteHomepage: function deleteHomepage() {
        Ember.set(this, 'showRemovePopup', true);
      },
      removeConfirmation: function removeConfirmation(result) {
        var _this3 = this;

        if (result) {
          var path = _environment.default.APP.API_HOST + '/homepages/' + this.homepage.id;
          this.ajax.requestPromise(path, 'DELETE').then(function () {
            Ember.set(_this3, 'homepage', undefined);
            Ember.set(_this3, 'showRemovePopup', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});