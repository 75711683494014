define("teelt-frontend/components/company/talents-import", ["exports", "teelt-frontend/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talents-import'],
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    firstLineIgnored: false,
    progress: 0,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('.settings').hide();
      Ember.set(this, 'request', {
        separator: ';',
        ignoreFirstLine: false,
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        gmtOffset: (0, _moment.default)().utcOffset()
      });
      this.$('.fixed-action-btn').floatingActionButton({
        toolbarEnabled: true
      });
      this.initDropzone();

      if (!this.companyId) {
        Ember.set(this, 'companyId', this.session.data.authenticated.user.company.id);
      }
    },
    onProgress100: Ember.observer('progress', function () {
      var _this2 = this;

      if (this.progress === 100) {
        if (this.onImported) {
          setTimeout(function () {
            _this2.onImported(_this2.added);
          }, 1000);
        }
      }
    }),
    initDropzone: function initDropzone() {
      var _this = this;

      var myDropzone = new Dropzone(".dropzone-action", {
        url: 'https://www.teelt.io',
        uploadMultiple: false,
        maxFiles: 1,
        createImageThumbnails: false,
        addRemoveLinks: true,
        acceptedFiles: 'text/csv',
        addedfile: function addedfile(file) {
          if (file.name.substring(file.name.length - 3).toLowerCase() === 'csv') {
            Ember.set(_this, 'fileName', file.name);
          } else {
            _this.notify.error(_this.intl.t('talent.import.dropzone_badextension'));

            _this.dropzone.removeAllFiles();
          }
        }
      });
      Ember.set(this, 'dropzone', myDropzone);
    },
    seek: function seek(file, offset, CHUNK_SIZE, fr) {
      if (offset >= file.size) {
        Ember.set(this, "progress", 100);
        return true;
      }

      var progress = Math.round(offset * 100 / file.size);
      Ember.set(this, "progress", progress);
      var slice = file.slice(offset, offset + CHUNK_SIZE);
      fr.readAsArrayBuffer(slice);
      return false;
    },
    treatCSVPart: function treatCSVPart(csvPart) {
      var _this3 = this;

      if (this.request.ignoreFirstLine && !this.firstLineIgnored) {
        csvPart = csvPart.split("\n").slice(1).join("\n");
        Ember.set(this, "firstLineIgnored", true);
      }

      Ember.set(this.request, 'csv', csvPart);
      var getImportTalentUrl = _environment.default.APP.API_HOST + '/companies/' + this.companyId + "/import";
      return this.ajax.requestWithBodyPromise(getImportTalentUrl, 'POST', 'application/json', JSON.stringify(this.request)).then(function (nbAdded) {
        var added = _this3.added;
        Ember.set(_this3, 'added', added + nbAdded);
        return Promise.resolve();
      }).catch(function () {
        Ember.set(_this3, "error", true);
        return Promise.reject();
      });
    },
    actions: {
      importTalents: function importTalents() {
        var file = this.dropzone.files[0];

        if (!file) {
          return;
        }

        Ember.set(this, "error", false);
        Ember.set(this, 'added', 0);
        var fr = new FileReader();
        var CHUNK_SIZE = 4 * 1024;
        var offset = 0;
        var waste = '';

        var _this = this;

        var end = false;

        fr.onload = function () {
          var _this4 = this;

          var buffer = new Uint8Array(fr.result);
          var text = new TextDecoder("utf-8").decode(buffer);
          var lastEndLine = text.lastIndexOf('\n');
          var newPart = text.substring(0, lastEndLine);
          var csvPart = waste + newPart;
          waste = text.substring(lastEndLine + 1);
          csvPart = csvPart.replace(/�/g, '');

          _this.treatCSVPart(csvPart).then(function () {
            offset += CHUNK_SIZE;
            end = _this.seek(file, offset, CHUNK_SIZE, fr);

            if (end && waste && waste.trim() !== '') {
              _this.treatCSVPart(waste);
            }
          }).catch(function (err) {
            console.error(err);
            Ember.set(_this4, "error", true);
            return;
          });
        };

        end = _this.seek(file, offset, CHUNK_SIZE, fr);
      },
      openSettings: function openSettings() {
        if (!this.settingsOpened) {
          this.$('.settings').show(500);
        } else {
          this.$('.settings').hide(500);
        }

        Ember.set(this, 'settingsOpened', !this.settingsOpened);
      },
      removeFile: function removeFile() {
        this.dropzone.removeAllFiles();
        Ember.set(this, 'fileName', undefined);
      },
      openFileDialog: function openFileDialog() {
        this.dropzone.hiddenFileInput.click();
      }
    }
  });

  _exports.default = _default;
});