define("teelt-frontend/components/utils/slideshow-view", ["exports", "@glidejs/glide"], function (_exports, _glide) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var glide = new _glide.default('.glide');
      glide.mount();
    }
  });

  _exports.default = _default;
});