define("teelt-frontend/components/talents/talent-select-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    eventBus: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('#templateSelect').formSelect();
    },
    actions: {
      onTemplateChanged: function onTemplateChanged(templateId) {
        Ember.set(this.talent, 'selectedTemplateId', Number(templateId));
        this.eventBus.trigger('REFRESH_ROUTE');
      }
    }
  });

  _exports.default = _default;
});