define("teelt-frontend/helpers/xss-protect", ["exports", "dompurify"], function (_exports, DOMPurify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          text = _ref2[0];

      if (!text) return;
      var fixedMarker = text.replaceAll('<marker ', '<markery ').replaceAll('</marker>', '</markery>');
      var clean = DOMPurify.sanitize(fixedMarker.replaceAll('marker>', 'markery>'), {
        ADD_TAGS: ['markery']
      });
      return clean.replaceAll('<markery ', '<marker ').replaceAll('</markery>', '</marker>');
    }
  });

  _exports.default = _default;
});