define("teelt-frontend/templates/board/talent/talent-detail/forms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "15KMZeiD",
    "block": "{\"symbols\":[],\"statements\":[[0,\" \"],[1,[27,\"form/forms-list-talent\",null,[[\"talent\",\"formId\",\"donwloadAll\"],[[23,[\"model\",\"talent\"]],[23,[\"model\",\"formId\"]],true]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/talent/talent-detail/forms.hbs"
    }
  });

  _exports.default = _default;
});