define("teelt-frontend/components/analytics/form-analytic-detail", ["exports", "teelt-frontend/config/environment", "teelt-frontend/utils/jquery-utils"], function (_exports, _environment, _jqueryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-analytic-detail'],
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.ajax.requestWithUrlParamPromise(_environment.default.APP.API_HOST + '/form-reports/' + this.formReportId, 'GET', {
        include: 'light'
      }).then(function (formReport) {
        if (formReport.complexFilters != null && formReport.complexFilters != 'null') {
          Ember.set(_this, 'oldFilters', JSON.parse(formReport.complexFilters));
        } else {
          Ember.set(_this, 'oldFilters', []);
        }

        Ember.set(_this, 'res', formReport);
      });
    },
    actions: {}
  });

  _exports.default = _default;
});