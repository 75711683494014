define("teelt-frontend/routes/talent/integration/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function model(params) {
      var parentModel = this.modelFor('talent.integration');
      return Ember.RSVP.hash({
        talent: parentModel.talent,
        form: this.store.findRecord('form', params.form_id),
        mobileView: window.innerWidth < 600
      });
    },
    actions: {
      back: function back() {
        this.transitionTo('talent.integration.forms');
      },
      reloadUser: function reloadUser() {
        var _this = this;

        var parentModel = this.modelFor('talent.integration');
        this.store.findRecord('user', parentModel.talent.id, {
          reload: true,
          include: 'customFields,fullTemplate,events'
        }).then(function (talent) {
          Ember.set(talent, "company.customFields", parentModel.companyCustomFields);
          Ember.set(parentModel, 'talent', talent);
          Ember.set(_this, 'talent', talent);
        });
      }
    }
  });

  _exports.default = _default;
});