define("teelt-frontend/components/form/field/field-opinion-labels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['field-opinion-labels'],
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.field.properties.fromValue) {
        Ember.set(this.field.properties, 'fromValue', 1);
      }

      if (!this.field.properties.toValue) {
        Ember.set(this.field.properties, 'toValue', 10);
      } else {
        this.onFromValueChange();
        this.onToValueChange();
      }

      Ember.addObserver(this.field.properties, 'fromValue', this, 'onFromValueChange');
      Ember.addObserver(this.field.properties, 'toValue', this, 'onToValueChange');
    },
    willDestroyElement: function willDestroyElement() {
      Ember.removeObserver(this.field.properties, 'fromValue', this, 'onFromValueChange');
      Ember.removeObserver(this.field.properties, 'toValue', this, 'onToValueChange');
    },
    onFromValueChange: function onFromValueChange() {
      if (this.field.properties.fromValue > 1) Ember.set(this.field.properties, 'fromValue', 1);else if (this.field.properties.fromValue < 0) Ember.set(this.field.properties, 'fromValue', 0);
    },
    onToValueChange: function onToValueChange() {
      if (!this.field.properties.toValue) return;
      if (this.field.properties.toValue > 10) Ember.set(this.field.properties, 'toValue', 10);else if (this.field.properties.toValue < 5) Ember.set(this.field.properties, 'toValue', 5);
      if (this.onToChange) this.onToChange(this.field.properties.toValue);
    }
  });

  _exports.default = _default;
});