define("teelt-frontend/components/company/company-detail", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['company-detail'],
    router: Ember.inject.service(),
    userService: Ember.inject.service(),
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.loadLicense();
    },
    loadLicense: function loadLicense() {
      var _this = this;

      var path = _environment.default.APP.API_HOST + '/companies/' + this.company.id + "/license";
      this.ajax.requestPromise(path).then(function (license) {
        Ember.set(_this.company, 'nbRH', license.nbRH);
        Ember.set(_this.company, 'nbMRH', license.nbMRH);
        Ember.set(_this.company, 'nbManager', license.nbManager);
        Ember.set(_this.company, 'nbConsult', license.nbConsult);
      });
    },
    actions: {
      assignTools: function assignTools() {
        if (!this.company.selectedToolIds) {
          Ember.set(this.company, 'selectedToolIds', []);
        }

        if (this.company.tools) {
          Ember.set(this.company, 'selectedToolIds', this.company.tools.map(function (tool) {
            return tool.id;
          }));
        }

        Ember.set(this, 'showToolPopup', true);
      },
      assignCustomFields: function assignCustomFields() {
        Ember.set(this, 'selectedCustomFieldIds', []);

        if (!this.company.customFields) {
          Ember.set(this.company, 'customFields', []);
        }

        if (this.company.customFields) {
          Ember.set(this, 'selectedCustomFieldIds', this.company.customFields.map(function (field) {
            return field.id;
          }));
        }

        Ember.set(this, 'showCustomFieldPopup', true);
      },
      save: function save() {
        var _this2 = this;

        Ember.set(this.company, 'rhEmails', []);

        if (this.company.users) {
          Ember.set(this.company, 'rhEmails', this.company.users.map(function (user) {
            return {
              id: user.id,
              name: user.username
            };
          }));
        }

        if (this.selectedCustomFieldIds) {
          Ember.set(this.company, 'customFields', this.selectedCustomFieldIds.map(function (id) {
            return {
              id: id
            };
          }));
        }

        if (!this.company.selectedToolIds && this.company.tools) {
          Ember.set(this.company, 'selectedToolIds', this.company.tools.map(function (tool) {
            return tool.id;
          }));
        }

        this.company.save().then(function (company) {
          Ember.set(_this2, 'showToolPopup', false);
          Ember.set(_this2, 'showCustomFieldPopup', false);
          Ember.set(_this2, 'company', company);
        });
      },
      deleteCompany: function deleteCompany() {
        Ember.set(this, "showRemovePopup", true);
      },
      removeConfirmation: function removeConfirmation(response) {
        var _this3 = this;

        if (response) {
          this.company.deleteRecord();
          this.company.save().then(function () {
            _this3.router.transitionTo('board.admin.company');
          });
        }
      },
      generateToken: function generateToken() {
        var _this4 = this;

        this.userService.generateApiToken().then(function (data) {
          Ember.set(_this4, 'token', data);
          Ember.set(_this4, 'showTokenModal', true);
        });
      },
      revealToken: function revealToken() {
        var pw = document.getElementById("tokenInput");

        if (pw.type == "password") {
          pw.type = "text";
        } else {
          pw.type = "password";
        }
      },
      openAgrumeSettings: function openAgrumeSettings() {
        var _this5 = this;

        if (!this.company.agrumeSetting) {
          Ember.set(this.company, 'agrumeSetting', {});
        }

        var path = _environment.default.APP.API_HOST + '/integrations/agrume/employers';
        this.ajax.requestPromise(path).then(function (employers) {
          Ember.set(_this5, 'agrumeEmployers', employers);

          if (_this5.company.agrumeSetting.possibleEmployers) {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = employers[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var employer = _step.value;
                var _iteratorNormalCompletion2 = true;
                var _didIteratorError2 = false;
                var _iteratorError2 = undefined;

                try {
                  for (var _iterator2 = _this5.company.agrumeSetting.possibleEmployers[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                    var possibleEmployer = _step2.value;

                    if (possibleEmployer.code == employer.id) {
                      Ember.set(employer, 'checked', true);
                    }
                  }
                } catch (err) {
                  _didIteratorError2 = true;
                  _iteratorError2 = err;
                } finally {
                  try {
                    if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                      _iterator2.return();
                    }
                  } finally {
                    if (_didIteratorError2) {
                      throw _iteratorError2;
                    }
                  }
                }
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }
          }

          Ember.set(_this5, 'showAgrumeSetting', true);
        });
      },
      saveAgrume: function saveAgrume() {
        var _this6 = this;

        var selectedEmployers = [];
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = this.agrumeEmployers[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var employer = _step3.value;

            if (employer.checked) {
              selectedEmployers.push(employer.id);
            }
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }

        Ember.set(this.company.agrumeSetting, 'possibleEmployers', selectedEmployers);
        this.company.save().then(function () {
          Ember.set(_this6, 'showAgrumeSetting', false);
        });
      },
      openCustomCSS: function openCustomCSS() {
        var _this7 = this;

        var path = _environment.default.APP.API_HOST + '/companies/' + this.company.id + '/customcss';
        this.ajax.requestPromise(path).then(function (customCss) {
          Ember.set(_this7, 'customCSS', customCss.css);
          Ember.set(_this7, 'openCustomCSSModal', true);
        });
      },
      saveCustomCSS: function saveCustomCSS() {
        var _this8 = this;

        var path = _environment.default.APP.API_HOST + '/companies/' + this.company.id + '/customcss';
        var request = {
          css: this.customCSS
        };
        this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(request)).then(function () {
          Ember.set(_this8, 'openCustomCSSModal', false);
        });
      },
      generateSFTPCertificate: function generateSFTPCertificate() {
        var _this9 = this;

        var path = _environment.default.APP.API_HOST + '/companies/' + this.company.id + '/sftpcertificate';
        this.ajax.requestPromise(path, 'PATCH').then(function () {
          _this9.send('showSFTPCertificate');
        });
      },
      showSFTPCertificate: function showSFTPCertificate() {
        var _this10 = this;

        var path = _environment.default.APP.API_HOST + '/companies/' + this.company.id + '/sftpcertificate';
        this.ajax.requestPromise(path, 'GET').then(function (certificate) {
          Ember.set(_this10, 'sftpCertificate', certificate);
          Ember.set(_this10, 'showSFTPCertificate', true);
        });
      }
    }
  });

  _exports.default = _default;
});