define("teelt-frontend/instance-initializers/session-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(instance) {
    var applicationRoute = instance.lookup('route:application');
    var session = instance.lookup('service:session');
    var eventBus = instance.lookup('service:event-bus');
    var ajax = instance.lookup('service:ajax');
    var rolecustomService = instance.lookup('service:rolecustom-service');
    ajax.reloadSecurityTokensFromStore();
    rolecustomService.reloadCustomRoles();
    session.on('invalidationSucceeded', function () {
      ajax.reloadSecurityTokensFromStore();
      applicationRoute.transitionTo('login.form');
      eventBus.trigger('AUTHENTICATION_CHANGED');
    });
    session.on('authenticationSucceeded', function () {
      eventBus.trigger('AUTHENTICATION_CHANGED');
    });
  }

  var _default = {
    initialize: initialize,
    name: 'session-events',
    after: 'ember-simple-auth'
  };
  _exports.default = _default;
});