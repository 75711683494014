define("teelt-frontend/routes/board/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    model: function model() {
      var selectedMenu = 0;
      return {
        isEssential: this.session.data.authenticated.user.company.essential,
        selectedMenu: selectedMenu,
        sliderMenu: [{
          name: this.intl.t('word.analytics'),
          route: "board.analytics"
        }]
      };
    }
  });

  _exports.default = _default;
});