define("teelt-frontend/templates/components/talents/talent-by-period-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8ni3AuAX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"talents-list-table-layout\"],[9],[0,\"\\n  \"],[1,[27,\"utils/default-table\",null,[[\"storageKeysName\",\"modelName\",\"modelFilter\",\"prefixTranslateKey\",\"customResultContentComponent\",\"filterComponent\",\"filterButtonName\",\"filters\",\"lineHeight\",\"noResultMessage\",\"sortableKeys\",\"reload\",\"sortedKeyInit\",\"sortedDirectionInit\",\"search_ph\",\"customKeys\",\"tableActions\",\"arriving\",\"countParams\",\"complexFilters\",\"onSelected\",\"limitLoad\",\"onActionLaunched\"],[\"talents\",[23,[\"modelName\"]],[23,[\"modelFilter\"]],\"talent.table.keys.\",\"talents/table/talents-custom-table-content\",\"talents/table/talents-custom-table-filter\",[27,\"t\",[\"talent.filter\"],[[\"talent\"],[[27,\"overridable-label\",[\"talentLabel\",\"word.talents\"],null]]]],[23,[\"filters\"]],58,[27,\"t\",[\"talent.no_talents\"],[[\"talent\"],[[27,\"overridable-label\",[\"talentLabel\",\"word.talent\"],null]]]],[23,[\"sortableKeys\"]],[23,[\"reloadTimestamp\"]],\"id\",\"DESC\",[27,\"t\",[\"talent.search_ph\"],[[\"talent\"],[[27,\"overridable-label\",[\"talentLabel\",\"word.talent\"],null]]]],[23,[\"customKeys\"]],[23,[\"talentActions\"]],\"true\",[23,[\"params\"]],[23,[\"complexFilters\"]],[27,\"action\",[[22,0,[]],\"onRowSelected\"],null],1000,[27,\"action\",[[22,0,[]],\"onActionLaunched\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showExportModal\"]]],null,{\"statements\":[[0,\" \"],[1,[27,\"talents/modal/talents-export-modal\",null,[[\"show\",\"customKeys\",\"exportQueryParams\"],[[23,[\"showExportModal\"]],[23,[\"customKeys\"]],[23,[\"exportQueryParams\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-by-period-list.hbs"
    }
  });

  _exports.default = _default;
});