define("teelt-frontend/components/session/auto-disconnect", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    storageKey: "_ADD_",
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var routeName = this.router.get('currentRouteName');
      Ember.set(this, 'currentRouteName', routeName); // Warning don't remove router.get currentRouteName. If removed, observer will not work

      var delay = this.session.data.authenticated.user.company.autoDisconnectAfterDelay ? this.session.data.authenticated.user.company.autoDisconnectAfterDelay : 0;
      Ember.set(this, 'delay', delay);
      this.refreshDelay();

      if (delay > 0) {
        (0, _jquery.default)(document).delegate(":input", "click change keydown", function () {
          _this.refreshDelay();
        });
        var disconnectInterval = setInterval(function () {
          if (_this.isDestroyed) {
            clearInterval(_this.disconnectInterval);
            return;
          }

          var inactivityCountdown = Number(localStorage.getItem(_this.storageKey));
          inactivityCountdown--;
          localStorage.setItem(_this.storageKey, inactivityCountdown);

          if (inactivityCountdown <= 60) {
            Ember.set(_this, 'showIncommingDisconnect', true);
          }

          if (inactivityCountdown <= 0) {
            _this.session.invalidate();

            _this.eventBus.trigger('AUTO_DISCONNECT_AFTER_DELAY');

            clearInterval(_this.disconnectInterval);
          }
        }, 1 * 1000);
        Ember.set(this, 'disconnectInterval', disconnectInterval);
      }
    },
    refreshDelay: function refreshDelay() {
      localStorage.setItem(this.storageKey, this.delay * 60);
    },
    onRouteChanged: Ember.observer('router.currentRouteName', function () {
      if (this.isDestroyed) return;
      this.refreshDelay();
    }),
    actions: {
      onClickOverlay: function onClickOverlay() {
        Ember.set(this, 'showIncommingDisconnect', false);
        this.refreshDelay();
      },
      onClose: function onClose() {
        Ember.set(this, 'showIncommingDisconnect', false);
        this.refreshDelay();
      }
    }
  });

  _exports.default = _default;
});