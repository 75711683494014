define("teelt-frontend/components/talents/talent-tasks-by-period", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talent-tasks-by-period'],
    userService: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    taskView: 'both',
    init: function init() {
      this._super.apply(this, arguments);

      var periods = this.userService.getTalentPeriods(this.talent);
      Ember.set(this, 'periods', periods);
      Ember.set(this, 'talentCurrentPeriod', this.userService.getTalentCurrentPeriod(this.talent, periods));
      this.initTasks();
      this.initShowPeriod();
      this.putAdministrativeTasksFirst();
    },
    initTasks: function initTasks() {
      var _this = this;

      var toRealise = [];
      var completed = [];
      this.talent.remainingTalentTasks.forEach(function (task) {
        if (task.assign == 'talent' || task.signContent && task.signContent.signatories && task.signContent.signatories.indexOf('ROLE_TALENT') >= 0) {
          Ember.set(task, 'completed', false);
          toRealise.push({
            task: task
          });
        }
      });
      this.talent.validatedTalentTasks.forEach(function (task) {
        if (task.assign == 'talent' || task.signContent && task.signContent.signatories && task.signContent.signatories.indexOf('ROLE_TALENT') >= 0) {
          Ember.set(task, 'completed', true);
          completed.push({
            task: task
          });
        }
      });
      this.talent.remainingEvents.forEach(function (event) {
        var start = event.allDay ? _moment.default.utc(event.start).startOf('day') : _moment.default.utc(event.start);

        _this.assignEventToPeriod(event, start);

        toRealise.push({
          event: event
        });
      });
      this.talent.pastEvents.forEach(function (event) {
        var start = event.allDay ? _moment.default.utc(event.start).startOf('day') : _moment.default.utc(event.start);

        _this.assignEventToPeriod(event, start);

        Ember.set(event, 'completed', true);
        completed.push({
          event: event
        });
      });
      Ember.set(this, 'tasksToRealise', toRealise);
      Ember.set(this, 'tasksCompleted', completed);
    },
    putAdministrativeTasksFirst: function putAdministrativeTasksFirst() {
      var _this2 = this;

      var tasks = this.tasksToRealise;
      tasks.forEach(function (task) {
        var currentTask = task.task ? task.task : task.event;

        if (currentTask.formId) {
          _this2.store.findRecord('form', currentTask.formId).then(function (form) {
            if (form.type == "ADMINISTRATIVE") {
              tasks = tasks.filter(function (t) {
                t = t.task ? t.task : t.event;
                return t.id != currentTask.id;
              });
              tasks.unshift(task);
              Ember.set(_this2, "tasksToRealise", tasks);
            }
          });
        }
      });
    },
    assignEventToPeriod: function assignEventToPeriod(event, start) {
      if (!this.periods || !this.periods.length) return;
      var triggerDate = this.periods[0].triggerDate;

      if (this.talent[triggerDate]) {
        for (var i = 0; i < this.periods.length; i++) {
          var period = this.periods[i];
          var startDate = (0, _moment.default)(this.talent[triggerDate]).add(period.offset, 'day');
          var endDate = (0, _moment.default)(this.talent[triggerDate]).add(period.offset, 'day').add(period.duration, 'day');

          if (start.isBetween(startDate, endDate)) {
            Ember.set(event, 'periodId', period.id);
            Ember.set(event, 'periodIndex', i);
          }
        }
      }

      if (!event.periodId) {
        Ember.set(event, 'periodId', this.periods[0].id);
        Ember.set(event, 'periodIndex', 0);
      }

      var descComplete = '';

      if (event.allDay) {
        descComplete += this.intl.t('word.all_the_day');
        Ember.set(event, 'date', start.format('DD/MM'));
      } else {
        Ember.set(event, 'date', start.format('DD/MM - HH[h]mm'));
      }

      if (event.room) {
        descComplete += descComplete === '' ? event.room : ' - ' + event.room;
      }

      if (event.description) {
        descComplete += descComplete === '' ? event.description : ' - ' + event.description;
      }

      Ember.set(event, 'descComplete', descComplete);
    },
    initShowPeriod: function initShowPeriod() {
      var _this3 = this;

      var periods = [];
      this.periods.forEach(function (period) {
        _this3.tasksToRealise.forEach(function (task) {
          var currentTaskToRealise = task.task ? task.task : task.event;

          if (currentTaskToRealise.periodId === period.id && _this3.talentCurrentPeriod >= period.index) {
            Ember.set(period, "showPeriod", true);
          }
        });

        Ember.set(period, "showPeriod", period.showPeriod ? true : false);
        periods.push(period);
      });
      Ember.set(this, "periods", periods);
    },
    actions: {
      toggleTaskView: function toggleTaskView(e) {
        var taskView = this.taskView !== 'both' && this.taskView === e ? 'both' : e === 'done' ? 'done' : 'todo';
        Ember.set(this, "taskView", taskView);
      }
    }
  });

  _exports.default = _default;
});