define("teelt-frontend/templates/board/talent/talent-detail/ihm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "er7wlRwI",
    "block": "{\"symbols\":[],\"statements\":[[0,\" \"],[1,[27,\"ihm/meet-people/meet-people-list-talent\",null,[[\"talent\",\"meetPeopleId\"],[[23,[\"model\",\"talent\"]],[23,[\"model\",\"meetPeopleId\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/talent/talent-detail/ihm.hbs"
    }
  });

  _exports.default = _default;
});