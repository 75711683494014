define("teelt-frontend/components/input/category-picker", ["exports", "teelt-frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['category-picker'],
    uiUtils: Ember.inject.service('ui-utils'),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.items) {
        Ember.set(this, 'items', this.items.toArray());
        this.unSelectAll();
      }
    },
    actions: {
      select: function select(item) {
        if (!this.canSelect) return;
        this.unSelectAll();
        Ember.set(item, 'selected', true);
      },
      switchToAddMode: function switchToAddMode() {
        Ember.set(this, 'newItem', "");
        Ember.set(this, 'addMode', true);
      },
      addNew: function addNew() {
        Ember.set(this, 'newItemIsNull', false);

        if (!this.items) {
          Ember.set(this, 'items', []);
        }

        if (!this.newItem || !this.newItem.trim()) {
          Ember.set(this, 'newItemIsNull', true);
          return;
        } // because items from query are immutable


        var newItems = [];
        this.items.forEach(function (item) {
          newItems.push(item);
        });
        newItems.pushObject({
          name: this.newItem,
          selected: this.canSelect ? true : false
        });
        this.unSelectAll();
        Ember.set(this, 'items', newItems);
        Ember.set(this, 'addMode', false);
      },
      addExisting: function addExisting(item) {
        Ember.set(this, 'newItemIsNull', false);

        if (!this.items) {
          Ember.set(this, 'items', []);
        }

        var newItems = [];
        this.items.forEach(function (item) {
          newItems.push(item);
        });
        newItems.pushObject({
          genericContentCategoryId: item.id,
          name: item.name,
          selected: this.canSelect ? true : false
        });
        this.unSelectAll();
        Ember.set(this, 'items', newItems);
        Ember.set(this, 'addMode', false);
      },
      revertAddMode: function revertAddMode() {
        Ember.set(this, 'addMode', false);
      },
      sortEndAction: function sortEndAction() {
        // fix lib
        this.$('div.is-dragging-object').css('opacity', 1).removeClass('is-dragging-object');
      },
      search: function search(value) {
        var _this = this;

        this.store.query('content-category', {
          generic: true,
          search: value,
          offset: 0,
          limit: 5
        }).then(function (categories) {
          // filter already added
          var categoriesMutable = [];
          categories.forEach(function (c) {
            return categoriesMutable.push(c);
          });
          categoriesMutable.forEach(function (category) {
            for (var i = 0; i < _this.items.length; i++) {
              if (_this.items[i] && _this.items[i].genericContentCategoryId === Number(category.id)) {
                categoriesMutable.removeObject(category);
                break;
              }
            }
          });
          Ember.set(_this, 'genericCategories', categoriesMutable);
        });
      },
      onEdit: function onEdit(item) {
        if (this.onEdit) {
          this.onEdit(item);
        }
      }
    },
    unSelectAll: function unSelectAll() {
      if (!this.items) return;
      this.items.forEach(function (item) {
        Ember.set(item, 'selected', false);
      });
    }
  });

  _exports.default = _default;
});