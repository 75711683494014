define("teelt-frontend/components/material/mdc-switch", ["exports", "MDC-Switch1"], function (_exports, _MDCSwitch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mdc-switch-component'],
    disabled: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var switchControl = new _MDCSwitch.default(document.querySelector('.mdc-switch'));
      Ember.set(this, 'switchControl', switchControl);
    },
    willDestroyElement: function willDestroyElement() {
      this.switchControl.destroy();

      this._super.apply(this, arguments);
    },
    actions: {
      toggleSwitch: function toggleSwitch() {
        Ember.set(this, 'disabled', !this.disabled);
        if (!this.disabled && this.onActivate) this.onActivate();
        if (this.disabled && this.onDisable) this.onDisable();
      }
    }
  });

  _exports.default = _default;
});