define("teelt-frontend/components/talents/completed-tasks", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    defaultProfileImageUrl: _environment.default.APP.DEFAULT_PROFILE_IMAGE_URL,
    label: undefined,
    init: function init() {
      this._super.apply(this, arguments);
    },
    filterTalents: function filterTalents(task) {
      var _this = this;

      Ember.set(this, 'loading', true);
      Ember.set(this, "id", task.id);
      Ember.set(this, "name", task.name);
      var params = {
        includes: "position",
        search: "",
        sortedDirection: "DESC",
        sortedKey: "id"
      };
      var path = _environment.default.APP.API_HOST + '/tasks/' + task.id + '/users';

      if (this.filterType == "completedTasks") {
        path += '?completed=true';
      }

      this.ajax.requestWithUrlParamPromise(path, 'GET', params).then(function (users) {
        Ember.set(_this, "users", users);
        Ember.set(_this, "count", users.length);
        Ember.set(_this, 'loading', false);
      }).catch(function (error) {
        Ember.set(_this, 'loading', false);
      });
    },
    actions: {
      loadData: function loadData(data) {
        this.filterTalents(data);
      },
      goto_arriving: function goto_arriving(label) {
        this.router.transitionTo('board.talent.talent-by-period', {
          queryParams: {
            label: label,
            filterType: this.filterType,
            taskId: this.id,
            taskName: this.name
          }
        });
      }
    }
  });

  _exports.default = _default;
});