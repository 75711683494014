define("teelt-frontend/components/talents/talent-information-block-progression-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talent-information-block-progression-bar'],
    mobileView: window.innerWidth < 600,
    didInsertElement: function didInsertElement() {
      this.updateProgressBar();
    },
    updateProgressBar: function updateProgressBar() {
      if (this.mobileView) {
        this.$('.progression-fill').animate({
          width: this.level
        });
      } else {
        this.$('.progression-fill').animate({
          height: this.level
        });
      }
    },
    levelObserver: Ember.observer('level', function () {
      this.updateProgressBar();
    })
  });

  _exports.default = _default;
});