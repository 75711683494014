define("teelt-frontend/components/ihm/meet-people/meet-people-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.store.findRecord('notification', this.meetpeople.notificationId, {
        reload: true
      }).then(function (result) {
        Ember.set(_this, 'notification', result);
      });
    }
  });

  _exports.default = _default;
});