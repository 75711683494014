define("teelt-frontend/templates/board/custom-field/custom-field-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NILzZE/D",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"custom-field-new\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"section-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"g-section-title\"],[9],[0,\"\\n            \"],[7,\"h1\"],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"custom_field.new_custom_field\"],null]],null],false],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[1,[21,\"custom-field/add-custom-field-form\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/custom-field/custom-field-new.hbs"
    }
  });

  _exports.default = _default;
});