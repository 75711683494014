define("teelt-frontend/adapters/application", ["exports", "ember-data", "teelt-frontend/config/environment", "moment", "jquery"], function (_exports, _emberData, _environment, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    host: _environment.default.APP.API_HOST,
    router: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service(),
    ajax: function ajax(url, method, hash) {
      var _this = this;

      if (!hash) {
        hash = {};
      }

      hash.xhrFields = {
        withCredentials: true
      };

      hash.beforeSend = function (xhr) {
        xhr.setRequestHeader("gmt-offset", (0, _moment.default)().utcOffset());

        var csrfToken = _this.ajaxService.getCSRFToken();

        if (csrfToken) {
          xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
        }

        var xAuthToken = _this.ajaxService.getXAuthToken();

        if (xAuthToken) {
          xhr.setRequestHeader('x-auth-token', xAuthToken);
        }
      };

      return this._super(url, method, hash);
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      return this._super.apply(this, arguments);
    },
    pathForType: function pathForType(type) {
      if (type === 'meetpeople') {
        return 'meetpeoples';
      }

      return this._super.apply(this, arguments);
    },
    handleResponse: function handleResponse(status, header, payload, requestData) {
      if (header && header['x-csrf-token']) {
        var csrfToken = header['x-csrf-token'];
        Ember.set(this.ajaxService, 'csrfToken', csrfToken);
      }

      if (status === 401) {
        this.session.invalidate();
        return;
      }

      if (status === 400) {
        return this._super(status, header, {
          errors: [payload]
        }, requestData);
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});