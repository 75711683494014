define("teelt-frontend/components/saml/saml-configuration-edit", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['saml-configuration-edit'],
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    ssoReplyUrl: _environment.default.APP.SSO_HOST + "/sso/callback",
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var path = _environment.default.APP.API_HOST + '/samls';
      this.ajax.requestPromise(path, 'GET').then(function (saml) {
        if (!saml) {
          saml = {};
        }

        Ember.set(_this, 'saml', saml);
      });
    },
    didInsertElement: function didInsertElement() {
      var textareas = document.querySelector('.materialize-textarea');

      if (textareas) {
        M.textareaAutoResize(document.querySelector('.materialize-textarea'));
      }
    },
    actions: {
      saveSAML: function saveSAML() {
        var _this2 = this;

        var path = _environment.default.APP.API_HOST + '/samls';
        this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(this.saml)).then(function () {
          _this2.notify.info(_this2.intl.t('talent.modifications_saved'));
        });
      },
      deleteSAML: function deleteSAML() {
        var _this3 = this;

        var path = _environment.default.APP.API_HOST + '/samls';
        this.ajax.requestPromise(path, 'DELETE').then(function () {
          Ember.set(_this3, 'saml', {});
        });
      }
    }
  });

  _exports.default = _default;
});