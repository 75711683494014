define("teelt-frontend/templates/components/template/table/templates-users-circles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5M8T+Ukr",
    "block": "{\"symbols\":[\"talentPicture\",\"index\"],\"statements\":[[4,\"if\",[[27,\"gt\",[[23,[\"talentsPictures\",\"length\"]],0],null]],null,{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,[\"talentsPictures\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[12,\"class\",[28,[\"background-talent-picture picture-bg-\",[22,2,[]]]]],[9],[0,\"\\n            \"],[7,\"img\"],[12,\"src\",[22,1,[]]],[12,\"class\",[28,[\"talent-picture picture-\",[22,2,[]]]]],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"moreAffected\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"more-affected\"],[9],[0,\"+ \"],[1,[27,\"t\",[\"template.talents_affected\"],[[\"number\",\"talent\"],[[23,[\"moreAffected\"]],[27,\"overridable-label\",[\"talentLabel\",\"word.talent\"],null]]]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"template.talents_affected\"],[[\"number\",\"talent\"],[[27,\"if\",[[27,\"not\",[[23,[\"users\"]]],null],0,[23,[\"users\",\"length\"]]],null],[27,\"overridable-label\",[\"talentLabel\",\"word.talent\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/template/table/templates-users-circles.hbs"
    }
  });

  _exports.default = _default;
});