define("teelt-frontend/components/utils/html-editor/source-code-editor", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.registerTemplateMarkers();
    },
    actions: {
      onSourceCodeChanged: function onSourceCodeChanged(value) {
        Ember.set(this.email, "sourceCode", value);
        var previewFrame = document.getElementById('code-source-iframe');
        if (!previewFrame) return;
        var iframeDocument = previewFrame.contentDocument || previewFrame.contentWindow.document;
        iframeDocument.open();
        iframeDocument.write(value);
        iframeDocument.close();

        if (this.onTextChanged) {
          this.onTextChanged();
        }
      }
    },
    registerTemplateMarkers: function registerTemplateMarkers() {
      if (this.templateMarkers) {
        this.$('#templateSourceCodeMarkersSelect').select2({});
        var self = this;
        this.$('#templateSourceCodeMarkersSelect').on('change', function () {
          if (!(0, _jquery.default)(this).find(':selected').data('marker')) {
            return;
          }

          var sourceCodeEditor = document.getElementById('source-code-editor');
          var startPosition = sourceCodeEditor.selectionStart;
          var endPosition = sourceCodeEditor.selectionEnd;
          var variableToAdd = '<marker class="templateMarker" data-marker=' + (0, _jquery.default)(this).find(':selected').data('marker') + ' data-deletable="true"><span contenteditable="false">' + (0, _jquery.default)(this).find(':selected').data('marker') + '</span></marker>';
          sourceCodeEditor.value = "".concat(sourceCodeEditor.value.substring(0, startPosition)).concat(variableToAdd).concat(sourceCodeEditor.value.substring(endPosition, sourceCodeEditor.value.length, sourceCodeEditor.focus()));

          if (self.onTemplateMarkerInserted) {
            self.onTemplateMarkerInserted((0, _jquery.default)(this).find(':selected').data('marker'));
          }

          (0, _jquery.default)(this).val("");
          (0, _jquery.default)(this).trigger('change');
          self.send("onSourceCodeChanged", sourceCodeEditor.value);
        });
      }
    }
  });

  _exports.default = _default;
});