define("teelt-frontend/helpers/is-authenticated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Helper.extend({
    session: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.eventBus.on('AUTHENTICATION_CHANGED', this, 'onAuthenticationChanged');
    },
    destroy: function destroy() {
      this.eventBus.off('AUTHENTICATION_CHANGED', this, 'onAuthenticationChanged');

      this._super.apply(this, arguments);
    },
    onAuthenticationChanged: function onAuthenticationChanged() {
      this.recompute();
    },
    compute: function compute(_ref) {
      var _ref2 = _toArray(_ref);

      return this.session.isAuthenticated;
    }
  });

  _exports.default = _default;
});