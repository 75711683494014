define("teelt-frontend/components/settings/role-list", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['allroles-list'],
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    modelName: 'role',
    offset: 0,
    limit: 100,
    search: '',
    canLoadMore: false,
    order: undefined,
    keys: ['icon', 'roleType', 'label', 'licencenb', 'actions'],
    modelFilter: {
      consultRoles: false
    },
    refreshCount: function refreshCount() {
      var _this = this;

      this.ajax.requestPromise(this.countUrl).then(function (count) {
        Ember.set(_this, 'roleNB', count);
      });
    },
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.eventBus.on('REFRESH_ROLELIST', this, 'onRolesRefresh');
      this.eventBus.on('ROLELIST_UPDATED', this, 'onRolesRefresh');
      var path = _environment.default.APP.API_HOST + '/companies/' + this.session.data.authenticated.user.company.id + "/license";
      this.ajax.requestPromise(path).then(function (license) {
        Ember.set(_this2, 'license', license);
      });
      this.onRolesRefresh();
    },
    destroy: function destroy() {
      this.eventBus.off('REFRESH_ROLELIST', this, 'onRolesRefresh');
      this.eventBus.off('ROLELIST_UPDATED', this, 'onRolesRefresh');

      this._super.apply(this, arguments);
    },
    onRolesRefresh: function onRolesRefresh() {
      var _this3 = this;

      Ember.set(this, 'reloadTimestamp', new Date());
      Ember.set(this, 'edit', false);
      this.refreshCount();
      var path = _environment.default.APP.API_HOST + "/roles?consultRoles=true";
      Ember.set(this, 'consultRoles', undefined);
      this.ajax.requestPromise(path).then(function (consultRoles) {
        Ember.set(_this3, 'consultRoles', consultRoles);
      });
    },
    actions: {
      add: function add() {},
      onRowSelected: function onRowSelected(result) {},
      editConsult: function editConsult(role) {
        this.router.transitionTo('board.setting.role-edit', role.id);
      },
      toggleReferent: function toggleReferent(consultRole) {
        var _this4 = this;

        Ember.set(consultRole, 'referent', !consultRole.referent);
        var path = _environment.default.APP.API_HOST + "/roles/" + consultRole.id;
        this.ajax.requestWithBodyPromise(path, "PATCH", 'application/json', JSON.stringify(consultRole)).then(function () {
          _this4.eventBus.trigger('REFRESH_ROLES');
        });
      }
    }
  });

  _exports.default = _default;
});