define("teelt-frontend/components/talents/import/import-tutorial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['meet-people-tutorial-modal'],
    allSteps: 5,
    selectedStep: 1,
    init: function init() {
      this._super.apply(this, arguments);

      this.defaultStep ? Ember.set(this, 'selectedStep', this.defaultStep) : Ember.set(this, 'selectedStep', 1);
      var allSteps = [];

      for (var i = 1; i <= this.allSteps; i++) {
        allSteps.push(i);
      }

      Ember.set(this, 'allSteps', allSteps);
    },
    actions: {
      onClickOverlay: function onClickOverlay() {},
      previous: function previous() {
        if (this.selectedStep > 1) {
          Ember.set(this, 'selectedStep', this.selectedStep - 1);
        }
      },
      next: function next() {
        if (this.selectedStep < 5) {
          Ember.set(this, 'selectedStep', this.selectedStep + 1);
        }
      }
    }
  });

  _exports.default = _default;
});