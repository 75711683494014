define("teelt-frontend/components/translation/edit-translation-modal", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['edit-translation-modal'],
    ajax: Ember.inject.service(),
    translation: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var path = _environment.default.APP.API_HOST + "/translations/" + this.code;
      this.ajax.requestPromise(path).then(function (translations) {
        Ember.set(_this, 'translations', translations);
      });
    },
    actions: {
      save: function save() {
        var _this2 = this;

        var path = _environment.default.APP.API_HOST + "/translations/" + this.code;
        this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(this.translations)).then(function () {
          _this2.translation.loadTranslations(_this2.code);

          Ember.set(_this2, 'show', false);
        });
      }
    }
  });

  _exports.default = _default;
});