define("teelt-frontend/components/talents/talent-detail-block/talent-referent-block", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    uiUtils: Ember.inject.service('ui-utils'),
    userService: Ember.inject.service('user-service'),
    customErrorService: Ember.inject.service('custom-error'),
    customLabelsService: Ember.inject.service('custom-labels-service'),
    ajax: Ember.inject.service(),
    buddyPath: _environment.default.APP.API_HOST + "/users",
    defaultManager: null,
    showManagerSelect: true,
    baseRoleCustomUsersPath: _environment.default.APP.API_HOST + "/roles",
    init: function init() {
      this._super.apply(this, arguments);

      if (this.canCloseWithoutSaving) this.canCloseWithoutSaving(true);
      var path = this.userService.getUsersSearchPathForSelect('ROLE_MANAGER');
      Ember.set(this, "managerPath", path);

      if (this.talent.manager) {
        Ember.set(this, 'defaultManager', this.talent.manager.firstname + ' ' + this.talent.manager.lastname);
      }

      var omPath = this.userService.getUsersSearchPathForSelect('ROLE_CUSTOM_OFFICE_MANAGER');
      Ember.set(this, "officeManagerPath", omPath);
      var omIds = [];

      if (this.talent.officeManagers) {
        omIds = this.talent.officeManagers.map(function (om) {
          return {
            key: om.id
          };
        });
      }

      Ember.set(this, 'officeManagersSelected', omIds);

      if (this.talent.buddy) {
        Ember.set(this, 'defaultBuddy', this.talent.buddy.firstname + ' ' + this.talent.buddy.lastname);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    actions: {
      generateManagerName: function generateManagerName(result) {
        return this.userService.getTalentName(result);
      },
      onManagerChanged: function onManagerChanged(result) {
        if (!result.key) return;
        if (this.canCloseWithoutSaving) this.canCloseWithoutSaving(false);
        Ember.set(this.talent, 'manager', {
          id: result.key
        });
      },
      openAddManagerPopup: function openAddManagerPopup() {
        var managerToAdd = {
          roles: ['ROLE_USER', 'ROLE_MANAGER'],
          company: this.session.data.authenticated.user.company,
          lang: this.session.data.authenticated.user.lang
        };
        Ember.set(this, 'managerToAdd', managerToAdd);
        Ember.set(this, 'showAddManagerPopup', true);
      },
      addNewManager: function addNewManager() {
        var _this = this;

        Ember.set(this, 'showManagerSelect', false);
        this.validateManagerForm();
        this.managerToAdd.proEmail = this.managerToAdd.username;
        this.checkExistingProEmail();
        if (this.managerProEmailAlreadyExistsError) return;
        var manager = this.store.createRecord('user', this.managerToAdd);
        manager.save().then(function (talent) {
          if (manager.invite) {
            _this.inviteManager(talent);
          }

          Ember.set(_this.talent, 'manager', {
            id: talent.id
          });
          Ember.set(_this, 'defaultManager', _this.userService.getTalentName(talent));
          Ember.set(_this, 'showAddManagerPopup', false);
          Ember.set(_this, 'showManagerSelect', true);
        }).catch(function (err) {
          _this.customErrorService.handleAjaxError(err);
        });
      },
      onOfficeManagerChanged: function onOfficeManagerChanged(results) {
        if (!results || !results.length) return;
        if (this.canCloseWithoutSaving) this.canCloseWithoutSaving(false);
        var omIds = results.map(function (result) {
          return result.key;
        });
        Ember.set(this.talent, 'officeManagerIds', omIds.join(';'));
      },
      onBuddyChanged: function onBuddyChanged(result) {
        if (!result.key) return;
        if (this.canCloseWithoutSaving) this.canCloseWithoutSaving(false);
        Ember.set(this.talent, 'buddy', {
          id: result.key,
          username: result.label
        });
      },
      onReferentRoleCustomChanged: function onReferentRoleCustomChanged(roleType, result) {
        var _this2 = this;

        var userRoleCustomReferents = this.talent.userRoleCustomReferents || [];
        var newReferentIds = result.map(function (res) {
          return parseInt(res.key);
        });
        result.forEach(function (res) {
          var referentId = parseInt(res.key);
          var existingReferent = userRoleCustomReferents.find(function (userRoleCustomReferent) {
            return userRoleCustomReferent.roleCustom.roleType === roleType && userRoleCustomReferent.referentId === referentId;
          });

          if (!existingReferent) {
            userRoleCustomReferents.push({
              roleCustom: {
                id: roleType.split('-')[1],
                roleType: roleType
              },
              referentId: referentId,
              userId: _this2.talent.id
            });
          }
        });
        userRoleCustomReferents = userRoleCustomReferents.filter(function (userRoleCustomReferent) {
          return userRoleCustomReferent.roleCustom.roleType !== roleType || newReferentIds.includes(userRoleCustomReferent.referentId);
        });
        Ember.set(this.talent, 'userRoleCustomReferents', userRoleCustomReferents);
        Ember.set(this, 'updateRoleCustoms', true);
      }
    },
    checkExistingProEmail: function checkExistingProEmail() {
      var _this3 = this;

      Ember.set(this, 'managerProEmailAlreadyExistsError', false);

      if (this.validateEmail(this.managerToAdd.proEmail)) {
        this.store.query('user', {
          username: this.managerToAdd.proEmail
        }).then(function (users) {
          if (users && users.length > 0) {
            Ember.set(_this3, 'managerProEmailAlreadyExistsError', true);
          }
        });
      }
    },
    validateManagerForm: function validateManagerForm() {
      Ember.set(this, 'managerProEmailIsNull', false);
      var isOk = true;

      if (!this.managerToAdd.username) {
        Ember.set(this, 'managerProEmailIsNull', true);
        isOk = false;
      }

      return isOk;
    },
    validateEmail: function validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    inviteManager: function inviteManager(manager) {
      var _this4 = this;

      var path = _environment.default.APP.API_HOST + "/notifications/systems/INVIT/users/" + manager.id;
      this.ajax.requestWithBodyPromise(path, 'POST').then(function (result) {
        if (result) {
          _this4.notify.info(_this4.intl.t('talent.messages.talent_invited', {
            talent: _this4.getCustomTalentLabel(['talentLabel', 'word.talent'])
          }), {
            closeAfter: 5000
          });
        } else {
          console.log(err);

          _this4.notify.error(_this4.intl.t('talent.errors.notification_failed'), {
            closeAfter: 5000
          });
        }
      }).catch(function (err) {
        console.log(err);

        _this4.notify.error(_this4.intl.t('talent.errors.notification_failed'), {
          closeAfter: 5000
        });
      });
    },
    getCustomTalentLabel: function getCustomTalentLabel(params) {
      var str = params[1].charAt(params[1].length - 1) === 's' ? 's' : '';
      var customLabels = this.customLabelsService.customLabels;

      if (customLabels && customLabels[params[0]]) {
        return customLabels[params[0]] + str;
      } else {
        return this.intl.t(params[1]);
      }
    }
  });

  _exports.default = _default;
});