define("teelt-frontend/components/form/field/complex/field-french-city", ["exports", "teelt-frontend/utils/string-util", "teelt-frontend/config/environment"], function (_exports, _stringUtil, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['field-french-city'],
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'uuid', (0, _stringUtil.default)().generateUUID());
      Ember.set(this, 'path', _environment.default.APP.API_HOST + '/repositories/french_city');
    },
    actions: {
      onCityChanged: function onCityChanged(value, text) {
        Ember.set(this, 'value', text);
      }
    }
  });

  _exports.default = _default;
});