define("teelt-frontend/templates/components/input/select-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hAzKMIXL",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\"],[11,\"class\",\"select-button-container\"],[11,\"tabindex\",\"-1\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"btn btn-blue-light waves-effect waves-light select-btn\"],[3,\"action\",[[22,0,[]],\"open\"]],[9],[1,[21,\"name\"],false],[0,\" \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[11,\"style\",\"display: block;float: right;\"],[9],[0,\"arrow_drop_down\"],[10],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showDropdown\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"dropdown\"],[9],[0,\"\\n      \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"items\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[3,\"action\",[[22,0,[]],\"selectItem\",[22,1,[]]]],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/input/select-button.hbs"
    }
  });

  _exports.default = _default;
});