define("teelt-frontend/templates/board/talent/talent-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G4JtoWnw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"talent-detail\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"clickable back foreground-primary-color\"],[3,\"action\",[[22,0,[]],\"back\"]],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"close\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"talent\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"col s4\"],[9],[0,\"\\n          \"],[1,[27,\"talents/talent-detail\",null,[[\"talent\",\"onModify\",\"formId\"],[[23,[\"model\",\"talent\"]],[27,\"route-action\",[\"modify\"],null],[23,[\"model\",\"formId\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"col s8 talent-timeline-container\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"right-header\"],[9],[0,\"\\n            \\n            \"],[1,[27,\"talents/talent-detail-navbar\",null,[[\"talent\",\"formId\"],[[23,[\"model\",\"talent\"]],[23,[\"model\",\"formId\"]]]]],false],[0,\"\\n\\n          \"],[10],[0,\"\\n\\n          \"],[7,\"div\"],[11,\"class\",\"right-body\"],[9],[0,\"\\n            \"],[1,[21,\"outlet\"],false],[0,\"\\n          \"],[10],[0,\"\\n\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/talent/talent-detail.hbs"
    }
  });

  _exports.default = _default;
});