define("teelt-frontend/models/template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    selectedToolIds: _emberData.default.attr(),
    selectedTaskIds: _emberData.default.attr(),
    selectedCustomFieldIds: _emberData.default.attr(),
    selectedFormIds: _emberData.default.attr(),
    selectedTalentIds: _emberData.default.attr(),
    tools: _emberData.default.attr(),
    periods: _emberData.default.attr(),
    customFields: _emberData.default.attr(),
    forms: _emberData.default.attr(),
    assignRequest: _emberData.default.attr(),
    notifications: _emberData.default.attr(),
    company: _emberData.default.attr(),
    startAudience: _emberData.default.attr(),
    endAudience: _emberData.default.attr(),
    users: _emberData.default.attr(),
    createdById: _emberData.default.attr(),
    model: _emberData.default.attr(),
    defaultTemplateId: _emberData.default.attr(),
    order: _emberData.default.attr()
  });

  _exports.default = _default;
});