define("teelt-frontend/components/utils/email-sign", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      save: function save() {
        var _this = this;

        this.company.save().then(function () {
          _this.notify.info(_this.intl.t('talent.modifications_saved'));
        });
      }
    }
  });

  _exports.default = _default;
});