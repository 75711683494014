define("teelt-frontend/templates/components/ihm/meet-people/select-people-animation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B6MyLF7J",
    "block": "{\"symbols\":[\"talent\"],\"statements\":[[7,\"div\"],[11,\"class\",\"select-people-animation\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"swiper-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"swiper-wrapper\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"talents\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"swiper-slide\"],[9],[0,\"\\n        \"],[1,[27,\"talents/talent-card\",null,[[\"talent\"],[[22,1,[]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/ihm/meet-people/select-people-animation.hbs"
    }
  });

  _exports.default = _default;
});