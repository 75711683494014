define("teelt-frontend/templates/components/talents/talent-document-by-period", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ClJ0ulkW",
    "block": "{\"symbols\":[\"period\",\"index\"],\"statements\":[[4,\"each\",[[23,[\"periods\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"talents/talent-document-by-period-period\",null,[[\"talent\",\"period\",\"unFilledOnly\",\"filledOnly\",\"highlight\",\"showPeriod\"],[[23,[\"talent\"]],[22,1,[]],[23,[\"unFilledOnly\"]],[23,[\"filledOnly\"]],[27,\"lte\",[[22,1,[\"index\"]],[23,[\"talentCurrentPeriod\",\"index\"]]],null],[27,\"eq\",[[22,1,[\"index\"]],[23,[\"talentCurrentPeriod\",\"index\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-document-by-period.hbs"
    }
  });

  _exports.default = _default;
});