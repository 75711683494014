define("teelt-frontend/models/exploreoffice", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    fields: _emberData.default.attr(),
    task: _emberData.default.attr(),
    createdAt: _emberData.default.attr('date'),
    confirmationMessage: _emberData.default.attr('string'),
    fieldCount: _emberData.default.attr('number'),
    users: _emberData.default.attr(),
    showToManager: _emberData.default.attr('boolean'),
    viewResultRoles: _emberData.default.attr(),
    audience: _emberData.default.attr()
  });

  _exports.default = _default;
});