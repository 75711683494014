define("teelt-frontend/templates/components/icon/pen-edit-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "grrjO+DE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"width\",\"18\"],[11,\"height\",\"19\"],[11,\"viewBox\",\"0 0 18 19\"],[11,\"fill\",\"none\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[9],[0,\"\\n\"],[7,\"path\"],[11,\"fill-rule\",\"evenodd\"],[11,\"clip-rule\",\"evenodd\"],[11,\"d\",\"M12.1612 3.1875C12.005 3.1875 11.8425 3.25286 11.7238 3.37704L10.5802 4.57313L12.9236 7.02413L14.0672 5.82804C14.3109 5.57314 14.3109 5.16137 14.0672 4.90647L12.6049 3.37704C12.4799 3.24632 12.3237 3.1875 12.1612 3.1875ZM9.91154 7.12217L10.4865 7.72348L4.82475 13.6451H4.24983V13.0438L9.91154 7.12217ZM3 12.5013L9.91154 5.27248L12.255 7.72348L5.34342 14.9523H3V12.5013Z\"],[11,\"fill\",\"black\"],[11,\"fill-opacity\",\"0.4\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/icon/pen-edit-svg.hbs"
    }
  });

  _exports.default = _default;
});