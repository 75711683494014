define("teelt-frontend/components/utils/pie-chart", ["exports", "chart.js", "chartjs-plugin-datalabels"], function (_exports, _chart, _chartjsPluginDatalabels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
      });
      Ember.set(this, 'id', id);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.initChartFromProps();
    },
    initChartFromProps: function initChartFromProps() {
      if (!this.data) return;
      var labels = [];
      var title = this.data.title ? this.data.title : "";
      var dataValues = [];
      var colors = this.colors;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var item = _step.value;
          var label = item.label ? item.label : "";
          var value = item.value ? item.value : 0;
          labels.push(label);
          dataValues.push(value);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      if (this.calculatePercent) {
        var total = dataValues.reduce(function (acc, value) {
          return acc + value;
        }, 0);
        dataValues = dataValues.map(function (value) {
          return Math.round(value / total * 100 * 10) / 10;
        });
      }

      var calculatePercent = this.calculatePercent;
      var data = {
        labels: labels,
        datasets: [{
          label: title,
          data: dataValues,
          backgroundColor: colors,
          hoverOffset: 4
        }]
      };
      var config = {
        type: 'doughnut',
        data: data,
        options: {
          cutoutPercentage: 10,
          tooltips: {
            callbacks: {
              label: function label(tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var currentLabel = data.labels[tooltipItem.index];
                var currentValue = dataset.data[tooltipItem.index];
                var finalValue = calculatePercent ? currentLabel + " : " + currentValue + '%' : currentLabel + " : " + currentValue;
                return finalValue;
              }
            }
          },
          plugins: {
            datalabels: {
              color: 'white',
              formatter: function formatter(value) {
                return value + '%';
              }
            }
          }
        }
      };

      if (this.small) {
        config.options.responsive = true;
        config.options.maintainAspectRatio = false;
      }

      var pieChart = new _chart.default(document.getElementById(this.id), config);
    }
  });

  _exports.default = _default;
});