define("teelt-frontend/services/custom-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    handleAjaxError: function handleAjaxError(err) {
      var translated = this.getGenericError();

      if (err && err.responseJSON && err.responseJSON.message && err.responseJSON.code && err.responseJSON.code.indexOf("error_") >= 0) {
        var custom = this.intl.t('custom_errors.' + err.responseJSON.code);

        if (!custom.startsWith("Missing translation")) {
          translated = custom;
        }
      } else if (err && err.errors && err.errors.length > 0 && err.errors[0].code && err.errors[0].code.indexOf("error_") >= 0) {
        var _custom = this.intl.t('custom_errors.' + err.errors[0].code);

        if (!_custom.startsWith("Missing translation")) {
          translated = _custom;
        }
      }

      var html = '<i class="material-icons">error_outline_outlined</i><div class="generic-message">' + this.getGenericError() + '</div>';

      if (this.getGenericError() !== translated) {
        html += '<div class="specific-message">' + translated + '</div>';
      }

      this.notify.error({
        closeAfter: 5000,
        html: html
      });
    },
    getGenericError: function getGenericError() {
      return this.intl.t('custom_errors.error_undefined');
    },
    alert: function alert(customError) {
      var translated = this.getGenericError();
      var custom = this.intl.t('custom_errors.' + customError);

      if (!custom.startsWith("Missing translation")) {
        translated = custom;
      } else {
        console.log('Missing translation', customError);
      }

      this.notify.alert(translated, {
        closeAfter: 5000
      });
    },
    errorWithIcon: function errorWithIcon(message, closeAfter) {
      var html = '<i class="material-icons">error_outline_outlined</i><div class="generic-message">' + message + '</div>';
      this.notify.error({
        closeAfter: closeAfter ? closeAfter : 5000,
        html: html
      });
    }
  });

  _exports.default = _default;
});