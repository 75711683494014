define("teelt-frontend/components/dashboard/user-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['user-info'],
    templateType: 'onboarding',
    actions: {
      changeTemplateType: function changeTemplateType(templateType) {
        Ember.set(this, 'templateType', templateType);
      }
    }
  });

  _exports.default = _default;
});