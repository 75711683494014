define("teelt-frontend/components/talents/talent-information-block-referents", ["exports", "teelt-frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    defaultProfileImageUrl: _environment.default.APP.DEFAULT_PROFILE_IMAGE_URL,
    ajax: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var referents = [];

      if (this.talent.manager) {
        referents.push({
          role: 'manager',
          user: this.talent.manager
        });
      }

      if (this.talent.officeManagers) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.talent.officeManagers[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var officeManager = _step.value;
            referents.push({
              role: 'officeManager',
              user: officeManager
            });
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      if (this.talent.buddy) {
        referents.push({
          role: 'sponsor',
          user: this.talent.buddy
        });
      }

      referents = referents.slice(0, 3);

      if (!this.selected && referents && referents[0]) {
        Ember.set(this, 'selected', referents[0].user.id);
      }

      Ember.set(this, 'referents', referents);
      Ember.run.later(function () {
        if (_this.selected) {
          _this.send('select', _this.selected);
        }
      });
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      if (this.talent.roleCustomReferents) {
        this.talent.roleCustomReferents.forEach(function (role) {
          var ref = _this2.referents;

          _this2.ajax.requestPromise(_environment.default.APP.API_HOST + "/users/" + _this2.talent.id + "/custom-roles/" + role.roleType + "/referents", 'GET').then(function (results) {
            Ember.set(_this2, 'referents', null);
            results.forEach(function (res) {
              ref.push({
                role: role.roleType,
                user: res
              });
            });
            Ember.set(_this2, 'referents', ref);
            Ember.run.later(function () {
              if (_this2.selected) {
                _this2.send('select', _this2.selected);
              }
            });
          });
        });
      }
    },
    actions: {
      select: function select(id) {
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = this.referents[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var referent = _step2.value;

            if (referent.user.id !== id) {
              (0, _jquery.default)('.talent-information-block .info-' + referent.user.id).hide();
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        (0, _jquery.default)('.talent-information-block .info-' + id).fadeIn();
        Ember.set(this, 'selected', id);
      },
      toggle: function toggle() {
        var _this3 = this;

        if (this.canExpand) {
          Ember.set(this, 'expanded', !this.expanded);
          Ember.run.later(function () {
            if (_this3.selected) {
              _this3.send('select', _this3.selected);
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});