define("teelt-frontend/components/task/task-preview", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    templatePeriodTasks: {
      mandatory: false,
      showLimitOffset: false,
      triggerInitiator: "action_start"
    },
    init: function init() {
      this._super.apply(this, arguments);

      if (this.period) {
        this.setTaskViaTemplateData();
      } else {
        this.setTaskDataViaTaskList();
      }
    },
    setTaskViaTemplateData: function setTaskViaTemplateData() {
      var _this = this;

      Ember.set(this, "showPlanning", true);
      var path = _environment.default.APP.API_HOST + '/templates/' + this.template.id + '/periods/' + this.period.id + '/tasks/' + this.task.id;
      this.ajax.requestPromise(path, 'GET').then(function (data) {
        if (data) {
          if (data.startOffset && data.startOffsetUnit && data.startOffsetDirection) {
            Ember.set(_this.task, 'planning', true);
          }

          if (data.notification) {
            Ember.set(_this.task, 'notify', true);
            Ember.set(_this.templatePeriodTasks, 'notification', data.notification);
          }

          if (data.endOffset && data.endOffsetUnit && data.endOffsetDirection) {
            Ember.set(_this.templatePeriodTasks, "showLimitOffset", true);
          } else {
            Ember.set(_this.templatePeriodTasks, "showLimitOffset", false);
          }

          Ember.set(_this.templatePeriodTasks, 'mandatory', data.mandatory);
          Ember.set(_this.templatePeriodTasks, 'id', data.id);
          Ember.set(_this.templatePeriodTasks, 'startOffset', data.startOffset);
          Ember.set(_this.templatePeriodTasks, 'startOffsetUnit', data.startOffsetUnit);
          Ember.set(_this.templatePeriodTasks, 'startOffsetDirection', data.startOffsetDirection);
          Ember.set(_this.templatePeriodTasks, 'endOffset', data.endOffset);
          Ember.set(_this.templatePeriodTasks, 'endOffsetUnit', data.endOffsetUnit);
          Ember.set(_this.templatePeriodTasks, 'endOffsetDirection', data.endOffsetDirection);
        }
      });
    },
    setTaskDataViaTaskList: function setTaskDataViaTaskList() {
      Ember.set(this, "showPlanning", false);
    },
    actions: {
      onClickOverlay: function onClickOverlay() {
        Ember.set(this, "showTaskPreview", false);
      }
    }
  });

  _exports.default = _default;
});