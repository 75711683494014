define("teelt-frontend/components/custom-field/input-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['input-field-component'],
    fileUploadService: Ember.inject.service('file-upload'),
    customErrorService: Ember.inject.service('custom-error'),
    actions: {
      onFileChanged: function onFileChanged(file) {
        var _this = this;

        if (!file) return;
        Ember.set(this, 'blockSave', true);
        this.fileUploadService.signAndUpload(file, true, function (e) {
          return _this.onProgress(e);
        }).then(function (fileUrl) {
          Ember.set(_this, 'fullValue', fileUrl);
          if (_this.onFileUploaded) _this.onFileUploaded(fileUrl); // this.talent.customFields.forEach((talentField) => {
          //   if (talentField.id === field.id) {
          //     set(talentField.user_customfield, 'fullValue', fileUrl);
          //   }
          // });
        }).finally(function () {
          Ember.set(_this, 'blockSave', false);
        });
      },
      openFile: function openFile() {
        var _this2 = this;

        if (!this.fullValue) return;
        var downloadWaitingUrl = window.location.protocol + "//" + window.location.host + "/downloading";
        var tabOpen = window.open(downloadWaitingUrl, 'newtab');
        this.fileUploadService.getObjectUrl(this.fullValue).then(function (data) {
          var url = data.url + "&inline";
          tabOpen.location = url;
        }).catch(function (err) {
          _this2.customErrorService.handleAjaxError(err);
        });
      }
    },
    onProgress: function onProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'progress', progress);
      }
    }
  });

  _exports.default = _default;
});