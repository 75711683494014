define("teelt-frontend/components/task/new-task/new-task-notification-select", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'new-task-notification-select',
    store: Ember.inject.service(),
    page: 0,
    limit: 12,
    canLoadMore: true,
    query: '',
    onTypeChanged: Ember.observer('task.type', function () {
      this.loadPage();
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var notificationId = this.notificationId;

      if (this.isOtherNotificationTask && this.templateNotification && this.templateNotification.notification) {
        notificationId = this.templateNotification.notification.id;
      } else if (this.task && this.task.notification) {
        notificationId = this.task.notification.id;
      }

      Ember.set(this, 'notificationId', notificationId);
      this.loadPage();
    },
    loadPage: function loadPage() {
      var _this = this;

      var params = {};
      params.offset = this.page * this.limit;
      params.limit = this.limit;
      params.type = 'CUSTOM';
      params.selectedId = this.notificationId;

      if (this.query) {
        params.search = this.query;
      }

      Ember.set(this, 'canLoadMore', false);
      this.store.query('notification', params).then(function (data) {
        if (data && data.length >= _this.limit) {
          Ember.set(_this, 'canLoadMore', true);
        }

        Ember.set(_this, "notifications", data.toArray());
      }).finally(function () {
        Ember.set(_this, 'isLoading', false);
      });
    },
    actions: {
      onSearchKeyUp: function onSearchKeyUp() {
        var _this2 = this;

        if (this.keyUpAction) {
          Ember.run.cancel(this.keyUpAction);
        }

        var keyUpAction = Ember.run.later(function () {
          Ember.set(_this2, 'page', 0);

          _this2.loadPage();
        }, 300);
        Ember.set(this, 'keyUpAction', keyUpAction);
      },
      next: function next() {
        Ember.set(this, 'page', this.page + 1);
        this.loadPage();
      },
      prev: function prev() {
        Ember.set(this, 'page', this.page - 1);
        this.loadPage();
      },
      selectNotification: function selectNotification(notification) {
        if (this.onSelectNotification) {
          this.onSelectNotification(notification);

          if (notification) {
            Ember.set(this, 'notificationId', notification.id);
          } else {
            Ember.set(this, 'notificationId', undefined);
          }

          return;
        }

        if (!notification) {
          Ember.set(this, 'notificationId', undefined);

          if (this.task) {
            Ember.set(this.task, 'notification', undefined);
          } else {
            Ember.set(this.templateNotification, 'notification', undefined);
          }
        } else {
          Ember.set(this, 'notificationId', notification.id);

          if (this.task) {
            Ember.set(this.task, 'notification', notification.toJSON({
              includeId: true
            }));
          } else {
            Ember.set(this.templateNotification, 'notification', notification.toJSON({
              includeId: true
            }));
          }
        }
      },
      newNotification: function newNotification() {
        var notification = this.store.createRecord('notification', {
          type: 'CUSTOM',
          recipient: 'TALENT',
          triggerInitiator: 'action_start',
          contents: [{
            contentType: "EMAIL",
            officeId: null,
            tagId: null,
            lang: this.selectedLang,
            disabled: true,
            subject: "",
            body: "",
            replyTo: ""
          }, {
            contentType: "SMS",
            officeId: null,
            tagId: null,
            lang: this.selectedLang,
            disabled: true,
            subject: "",
            body: "",
            replyTo: ""
          }],
          schedules: [{}],
          associatedRole: null
        });
        Ember.set(this, 'newNotif', notification);
        Ember.set(this, 'showNewNotif', true);
      },
      onFinishNewNotif: function onFinishNewNotif() {
        Ember.set(this, 'showNewNotif', false);
        this.loadPage();
      }
    }
  });

  _exports.default = _default;
});