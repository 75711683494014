define("teelt-frontend/routes/talent/integration/team", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    afterModel: function afterModel(model, transition) {
      Ember.set(model, 'router', this.router);

      if (transition.targetName === "talent.integration.team.index") {
        if (model.talent.team) {
          this.transitionTo('talent.integration.team.my-team');
        } else {
          this.transitionTo('talent.integration.team.members-list');
        }
      }
    }
  });

  _exports.default = _default;
});