define("teelt-frontend/templates/board/notification/notification-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X9tqDSwF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"notification-edit\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"section-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"g-section-title\"],[9],[0,\"\\n            \"],[7,\"h1\"],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"emails.edit_notification\"],null]],null],false],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[1,[27,\"notification/notification-form\",null,[[\"notification\",\"saved\"],[[23,[\"model\",\"notification\"]],[23,[\"model\",\"saved\"]]]]],false],[0,\"\\n\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"model\",\"showConfirmNotSavedModal\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"utils/modal/basic-confirm\",null,[[\"show\",\"title\",\"message\",\"cancelLabel\",\"confirmLabel\",\"onConfirm\"],[[23,[\"model\",\"showConfirmNotSavedModal\"]],[27,\"t\",[\"modal.basic_confirm.unsaved_changes.title\"],null],[27,\"t\",[\"modal.basic_confirm.unsaved_changes.message\"],null],[27,\"t\",[\"modal.basic_confirm.unsaved_changes.button.cancel\"],null],[27,\"t\",[\"modal.basic_confirm.unsaved_changes.button.confirm\"],null],[27,\"route-action\",[\"onTransitionConfirmed\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/notification/notification-edit.hbs"
    }
  });

  _exports.default = _default;
});