define("teelt-frontend/components/audience/table/audiences-custom-table-content", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['audiences-custom-table-content'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    actions: {
      edit: function edit() {
        Ember.set(this, 'showActions', false);
        this.router.transitionTo('board.audience.audience-edit', this.result.id);
      },
      prepareDeleteAudience: function prepareDeleteAudience() {
        Ember.set(this, 'showActions', false);
        Ember.set(this, 'audienceToDelete', this.result);
      },
      removeAudience: function removeAudience(response) {
        var _this = this;

        if (!response) return;
        this.audienceToDelete.deleteRecord();
        this.audienceToDelete.save().then(function () {
          _this.eventBus.trigger('REFRESH_AUDIENCES');
        }).catch(function (e) {
          _this.notify.error(_this.intl.t('audience.messages.failed_to_remove'), {
            closeAfter: 5000
          });
        });
      }
    }
  });

  _exports.default = _default;
});