define("teelt-frontend/components/settings/perimeter-list", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['perimeter-list'],
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    store: Ember.inject.service(),
    modelName: 'perimeter',
    perimeterNb: 0,
    countUrl: _environment.default.APP.API_HOST + "/perimeters/count",
    offset: 0,
    limit: 10,
    search: '',
    canLoadMore: false,
    order: undefined,
    keys: ['name', 'templates', 'audiences', 'actions'],
    modelFilter: {},
    editPerimeter: function editPerimeter(perimeter) {
      Ember.set(this, 'edit', perimeter);
    },
    refreshCount: function refreshCount() {
      var _this = this;

      this.ajax.requestPromise(this.countUrl).then(function (count) {
        Ember.set(_this, 'perimeterNb', count);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.refreshCount();
      this.eventBus.on('REFRESH_PERIMETERS', this, 'onPerimetersRefresh');
      this.eventBus.on('EDIT_PERIMETER', this, 'editPerimeter');
      Ember.set(this, 'reloadTimestamp', new Date());
    },
    destroy: function destroy() {
      this.eventBus.off('REFRESH_PERIMETERS', this, 'onPerimetersRefresh');
      this.eventBus.off('EDIT_PERIMETER', this, 'editPerimeter');

      this._super.apply(this, arguments);
    },
    onPerimetersRefresh: function onPerimetersRefresh() {
      Ember.set(this, 'reloadTimestamp', new Date());
      Ember.set(this, 'edit', false);
      this.refreshCount();
    },
    actions: {
      add: function add() {
        var perimeter = this.store.createRecord('perimeter');
        Ember.set(this, 'edit', perimeter);
      },
      onRowSelected: function onRowSelected(result) {
        this.editPerimeter(result);
      }
    }
  });

  _exports.default = _default;
});