define("teelt-frontend/components/task/new-task/new-task-default-module-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    page: 0,
    limit: 6,
    canLoadMore: true,
    query: '',
    onTypeChanged: Ember.observer('task.type', function () {
      this.loadPage();
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.loadPage();

      if (this.task.form && this.task.form.id) {
        this.store.findRecord('form', this.task.form.id).then(function (data) {
          _this.send('selectForm', data);
        });
      }
    },
    loadPage: function loadPage() {
      var _this2 = this;

      var params = {};
      params.offset = this.page * this.limit;
      params.limit = this.limit;

      if (this.query) {
        params.search = this.query;
      }

      Ember.set(this, 'canLoadMore', false);
      this.store.query(this.module, params).then(function (data) {
        if (data && data.length >= _this2.limit) {
          Ember.set(_this2, 'canLoadMore', true);
        }

        Ember.set(_this2, "modules", data.toArray());
      }).finally(function () {
        Ember.set(_this2, 'isLoading', false);
      });
    },
    actions: {
      onSearchKeyUp: function onSearchKeyUp() {
        var _this3 = this;

        if (this.keyUpAction) {
          Ember.run.cancel(this.keyUpAction);
        }

        var keyUpAction = Ember.run.later(function () {
          Ember.set(_this3, 'page', 0);

          _this3.loadPage();
        }, 300);
        Ember.set(this, 'keyUpAction', keyUpAction);
      },
      next: function next() {
        Ember.set(this, 'page', this.page + 1);
        this.loadPage();
      },
      prev: function prev() {
        Ember.set(this, 'page', this.page - 1);
        this.loadPage();
      },
      selectModule: function selectModule(selected) {
        if (!selected) {
          Ember.set(this, 'moduleId', undefined);
          Ember.set(this.task, this.taskField, undefined);
        } else {
          Ember.set(this, 'moduleId', selected.id);
          Ember.set(this.task, this.taskField, selected.toJSON({
            includeId: true
          }));
        }
      }
    }
  });

  _exports.default = _default;
});