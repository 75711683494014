define("teelt-frontend/templates/board/admin/custom-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GHaO9HD/",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"custom-field/custom-fields-list\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/admin/custom-field.hbs"
    }
  });

  _exports.default = _default;
});