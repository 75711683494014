define("teelt-frontend/components/talents/talent-task-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      trigger: function trigger(task) {
        if (this.onTriggered) this.onTriggered(task);
      }
    }
  });

  _exports.default = _default;
});