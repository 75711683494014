define("teelt-frontend/components/dashboard/custom-pie-chart", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    templatePath: _environment.default.APP.API_HOST + "/templates",
    templateFilter: 'all',
    init: function init() {
      this._super.apply(this, arguments);

      if (this.taskToRealise) {
        this.send('selectFilter', 'all');
        this.filterData();
      } else {
        this.setColors(this.pieChartData);
      }
    },
    setColors: function setColors(datas) {
      var colors = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = datas[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var item = _step.value;
          var color = this.generateRandomColor("1800FF");
          colors.push(color);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      Ember.set(this, 'colors', colors);
    },
    generateRandomColor: function generateRandomColor(baseColor) {
      var base = parseInt(baseColor, 16);
      var variation = Math.random() * 0xFFFF;
      var newColor = (base + variation) % 0xFFFFFF;
      var colorHex = '#' + newColor.toString(16).toUpperCase().padStart(6, '0');
      return colorHex.split('.')[0];
    },
    filterData: function filterData() {
      var _this = this;

      Ember.set(this, 'showChart', false);

      if (this.taskToRealise) {
        this.ajax.requestPromise(_environment.default.APP.API_HOST + '/tasks/taskStats?search=' + this.templateFilter, 'GET').then(function (data) {
          var pieChartData = [{
            label: _this.intl.t('task.completed'),
            color: '#385E9D',
            value: data[1]
          }, {
            label: _this.intl.t('task.to_realise'),
            color: '#1800FF',
            value: data[0]
          }];
          Ember.set(_this, 'pieChartData', pieChartData);

          _this.setColors(pieChartData);

          Ember.set(_this, 'showChart', true);
        });
      }

      Ember.set(this, 'showChart', true);
    },
    actions: {
      selectFilter: function selectFilter(templateFilter) {
        Ember.set(this, 'templateFilter', templateFilter);
        Ember.set(this, 'possibleTemplateType', false);
        this.filterData();
      },
      onClickOverlay: function onClickOverlay() {
        Ember.set(this, 'showModal', false);
      }
    }
  });

  _exports.default = _default;
});