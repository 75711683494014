define("teelt-frontend/helpers/linkify", ["exports", "ember-linkify/helpers/linkify"], function (_exports, _linkify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _linkify.default;
    }
  });
  Object.defineProperty(_exports, "linkify", {
    enumerable: true,
    get: function get() {
      return _linkify.linkify;
    }
  });
});