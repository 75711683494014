define("teelt-frontend/components/form/forms-list-table-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['forms-list-table-element'],
    actions: {
      onClick: function onClick() {
        if (this.actionAction) this.actionAction(this.form);
      }
    }
  });

  _exports.default = _default;
});