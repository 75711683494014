define("teelt-frontend/components/task/new-task/new-task-sign-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    page: 0,
    limit: 6,
    canLoadMore: true,
    query: '',
    lastModules: [],
    selectedOnFirstPage: false,
    onTypeChanged: Ember.observer('task.type', function () {
      this.loadPage();
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.task.signContent && this.task.signContent.id) {
        this.store.findRecord('signcontent', this.task.signContent.id).then(function (data) {
          _this.send('selectSign', data);
        }).finally(function () {
          _this.loadPage();
        });
        ;
      } else {
        this.loadPage();
      }
    },
    loadPage: function loadPage() {
      var _this2 = this;

      var params = {};
      params.offset = this.page * this.limit;
      params.limit = this.limit;

      if (this.query) {
        params.search = this.query;
      }

      Ember.set(this, 'canLoadMore', false);
      this.store.query('signcontent', params).then(function (data) {
        if (data && data.length >= _this2.limit) {
          Ember.set(_this2, 'canLoadMore', true);
        }

        var signs = _this2.getSignModulesWithSelectedFirst(data.toArray());

        Ember.set(_this2, "signs", signs);
      }).finally(function () {
        Ember.set(_this2, 'isLoading', false);
      });
    },
    getSignModulesWithSelectedFirst: function getSignModulesWithSelectedFirst(modules) {
      var _this3 = this;

      if (!(this.task.signContent && this.task.signContent.id) || this.selectedOnFirstPage) return modules;
      var moduleIndex = modules.findIndex(function (module) {
        return module.id === _this3.task.signContent.id;
      });

      if (moduleIndex === 0 && this.page === 0) {
        Ember.set(this, "selectedOnFirstPage", true);
        return modules;
      }

      if (moduleIndex > 0 && this.page === 0) {
        var moduleToMove = modules.splice(moduleIndex, 1)[0];
        modules.unshift(moduleToMove);
        Ember.set(this, "selectedOnFirstPage", true);
        return modules;
      }

      if (moduleIndex < 0 && this.page === 0) {
        var lastModule = modules.pop();
        var lastModules = this.lastModules;

        if (!lastModules.some(function (entry) {
          return entry.page === _this3.page;
        })) {
          lastModules.push({
            page: this.page,
            module: lastModule
          });
        }

        Ember.set(this, "lastModules", lastModules);
        var newModule = this.task.signContent;
        modules.unshift(newModule);
        return modules;
      }

      if (this.lastModules.length > 0) {
        var lastModuleEntry = this.lastModules.find(function (entry) {
          return entry.page === _this3.page - 1;
        });

        if (lastModuleEntry && lastModuleEntry.id !== this.task.signContent.id) {
          modules.unshift(lastModuleEntry.module);

          if (moduleIndex > 0) {
            modules.splice(moduleIndex + 1, 1);
          } else {
            var _lastModule = modules.pop();

            if (!this.lastModules.some(function (entry) {
              return entry.page === _this3.page;
            })) {
              this.lastModules.push({
                page: this.page,
                module: _lastModule
              });
            }

            Ember.set(this, "lastModules", this.lastModules);
          }
        }

        return modules;
      }

      return modules;
    },
    actions: {
      onSearchKeyUp: function onSearchKeyUp() {
        var _this4 = this;

        if (this.keyUpAction) {
          Ember.run.cancel(this.keyUpAction);
        }

        var keyUpAction = Ember.run.later(function () {
          Ember.set(_this4, 'page', 0);

          _this4.loadPage();
        }, 300);
        Ember.set(this, 'keyUpAction', keyUpAction);
      },
      next: function next() {
        Ember.set(this, 'page', this.page + 1);
        this.loadPage();
      },
      prev: function prev() {
        Ember.set(this, 'page', this.page - 1);
        this.loadPage();
      },
      selectSign: function selectSign(selected) {
        if (!selected) {
          Ember.set(this, 'signId', undefined);
          Ember.set(this.task, 'signContent', undefined);
        } else {
          Ember.set(this, 'signId', selected.id);
          Ember.set(this.task, 'signContent', selected.toJSON({
            includeId: true
          }));
        }
      }
    }
  });

  _exports.default = _default;
});