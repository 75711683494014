define("teelt-frontend/components/content/contents-documents-blocks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['contents-documents-list', 'contents-documents-blocks'],
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});