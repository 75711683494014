define("teelt-frontend/routes/board/tasks", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    userService: Ember.inject.service('user-service'),
    session: Ember.inject.service(),
    queryParams: {
      search: ''
    },
    resetController: function resetController(controller) {
      controller.set('search', null);
    },
    model: function model(params) {
      return {
        showNewTask: false,
        search: params.search
      };
    },
    afterModel: function afterModel(model) {
      var url = _environment.default.APP.API_HOST + "/users/count/onboarding";
      return this.ajax.requestWithBodyPromise(url, 'GET').then(function (response) {
        if (response) {
          Ember.set(model, 'countOnboarding', response);
        }
      });
    },
    actions: {
      openTask: function openTask(id) {
        this.transitionTo('board.task.task-edit', id);
      }
    }
  });

  _exports.default = _default;
});