define("teelt-frontend/components/custom-field/field/custom-autocomplete-free", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    uiUtils: Ember.inject.service('ui-utils'),
    didInsertElement: function didInsertElement() {
      this.uiUtils.initAutocomplete(this.$('#customField-' + this.field.id), [], "name", function () {// set(this, "newPosition", false);
      });
    },
    actions: {
      onChanged: function onChanged() {
        var _this = this;

        var path = _environment.default.APP.API_HOST + '/statics/cf_' + this.field.id + '?searchKey=' + this.selectedValue;
        this.ajax.requestPromise(path, 'GET').then(function (statics) {
          _this.uiUtils.updateAutocompleteData(_this.$('#customField-' + _this.field.id), statics, "name");
        }).catch(function (err) {
          console.warn(err);
        });
      }
    }
  });

  _exports.default = _default;
});