define("teelt-frontend/components/ihm/meet-people/meet-people-gallery", ["exports", "teelt-frontend/config/environment", "viewerjs"], function (_exports, _environment, _viewerjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend((_EmberComponent$exte = {
    classNames: ['meet-people-gallery'],
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    customErrorService: Ember.inject.service('custom-error'),
    userService: Ember.inject.service(),
    uiUtils: Ember.inject.service("ui-utils"),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    meetPeopleImages: [],
    countSelectedImages: 0,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.meetPeopleId) {
        this.ajax.requestPromise(_environment.default.APP.API_HOST + '/meetpeoples/' + this.meetPeopleId, 'GET').then(function (response) {
          Ember.set(_this, 'meetPeople', response);
        });
      }

      if (this.talent) {
        this.ajax.requestPromise(_environment.default.APP.API_HOST + '/users/meetPeoples', 'GET').then(function (response) {
          if (response.length > 0 && _this.talent.id == _this.session.data.authenticated.user.id) {
            Ember.set(_this, 'canAddImage', true);
          }
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.loadImages();
    },
    loadImages: function loadImages() {
      var _this2 = this;

      /*if(this.meetPeopleId && this.talentId){
        this.ajax.requestPromise( ENV.APP.API_HOST + '/meetpeoples/' + this.meetPeopleId + '/meet-people-images/' + this.talentId , 'GET').then(response => {
          set(this, 'meetPeopleImages', response);
          this.treatMeetPeopleImage();
        });
      }else*/
      if (this.meetPeopleId) {
        this.ajax.requestPromise(_environment.default.APP.API_HOST + '/meetpeoples/' + this.meetPeopleId + '/meet-people-images', 'GET').then(function (response) {
          Ember.set(_this2, 'meetPeopleImages', response);

          _this2.treatMeetPeopleImage();
        });
      } else {
        this.ajax.requestPromise(_environment.default.APP.API_HOST + '/meetpeoples/meet-people-images', 'GET').then(function (response) {
          Ember.set(_this2, 'meetPeopleImages', response);

          _this2.treatMeetPeopleImage();
        });
      }
    }
  }, _defineProperty(_EmberComponent$exte, "countSelectedImages", Ember.computed('meetPeopleImages.@each.selected', function () {
    return this.meetPeopleImages.filter(function (image) {
      return image.selected;
    }).length;
  })), _defineProperty(_EmberComponent$exte, "treatMeetPeopleImage", function treatMeetPeopleImage() {
    var _this3 = this;

    var meetPeopleImages = this.meetPeopleImages;
    meetPeopleImages.forEach(function (meetPeopleImage) {
      if (_this3.talent && meetPeopleImage.uploader && meetPeopleImage.uploader.id == _this3.talent.id) {
        meetPeopleImage.isTalentOwner = true;
      }

      if (meetPeopleImage.uploader && meetPeopleImage.uploader.id === _this3.session.data.authenticated.user.id) {
        meetPeopleImage.isOwner = true;
      }

      if (!meetPeopleImage.uploader && _this3.userService.hasAnyRole(_this3.session.data.authenticated.user, 'ROLE_RH')) {
        meetPeopleImage.canForceDelete = true;
      }
    });

    if (this.onlyUserImages) {
      meetPeopleImages = meetPeopleImages.filter(function (image) {
        return image.isTalentOwner;
      });
    }

    Ember.set(this, 'meetPeopleImages', meetPeopleImages);
    setTimeout(function () {
      var gallery = new _viewerjs.default(document.getElementById('images'));
      gallery.destroy();
      gallery.init();
    }, 100);
  }), _defineProperty(_EmberComponent$exte, "actions", {
    deleteMeetPeopleImage: function deleteMeetPeopleImage(response) {
      var _this4 = this;

      if (response) {
        var selectedImageIds = this.meetPeopleImages.filter(function (image) {
          return image.selected;
        }).map(function (image) {
          return image.id;
        });
        var params = {
          ids: selectedImageIds
        };
        this.ajax.requestWithBodyPromise(_environment.default.APP.API_HOST + '/meetpeoples/delete-meet-people-images', 'DELETE', 'application/json', JSON.stringify(params)).then(function () {
          var meetPeopleImages = _this4.meetPeopleImages.filter(function (image) {
            return !selectedImageIds.includes(image.id);
          });

          setTimeout(function () {
            Ember.set(_this4, 'meetPeopleImages', meetPeopleImages);

            _this4.treatMeetPeopleImage();
          }, 300);
        });
        Ember.set(this, 'showRemoveConfirm', false);
      }
    },
    onClickOverlay: function onClickOverlay() {},
    closeImageModal: function closeImageModal() {
      Ember.set(this, 'showImageModal', false);
      this.loadImages();
    },
    filterImage: function filterImage() {
      var onlyUserImages = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      Ember.set(this, 'onlyUserImages', onlyUserImages);
      this.loadImages();
    }
  }), _EmberComponent$exte));

  _exports.default = _default;
});