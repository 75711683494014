define("teelt-frontend/templates/components/talents/modal/talents-update-manager-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sBcGHpqk",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"modal-dialog\",null,[[\"onClose\",\"translucentOverlay\",\"containerClass\",\"onClickOverlay\"],[[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"show\"]]],null],false],null],true,\"talents-action-modal\",[27,\"action\",[[22,0,[]],\"onClickOverlay\"],null]]],{\"statements\":[[7,\"div\"],[11,\"class\",\"modal-container\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"btn-close btn-transparent foreground-primary-color\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"show\"]]],null],false]],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"close\"],[10],[10],[0,\"\\n\\n    \"],[7,\"h1\"],[11,\"class\",\"foreground-primary-color\"],[9],[1,[27,\"t\",[\"talent.action_modals.update_manager.title\"],null],false],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"inner-container\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"input-field\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"selectValues\"]]],null,{\"statements\":[[0,\"                \"],[1,[27,\"utils/searchable-select\",null,[[\"values\",\"multiple\",\"onValueChange\"],[[23,[\"selectValues\"]],false,[27,\"action\",[[22,0,[]],\"onSelectChanged\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n       \\n        \"],[7,\"div\"],[11,\"class\",\"controls right\"],[9],[0,\"\\n            \"],[7,\"a\"],[11,\"class\",\"btn-flat border-primary-color foreground-primary-color btn-rounded waves-effect waves-light\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"show\"]]],null],false]],[9],[1,[27,\"t\",[\"word.cancel\"],null],false],[10],[0,\"\\n            \"],[7,\"a\"],[11,\"class\",\"btn background-primary-color btn-rounded waves-effect waves-light\"],[3,\"action\",[[22,0,[]],\"save\"]],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"word.modify\"],null]],null],false],[10],[0,\"\\n\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/modal/talents-update-manager-modal.hbs"
    }
  });

  _exports.default = _default;
});