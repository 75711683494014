define("teelt-frontend/components/ai/modal-chat-completion", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    allMessages: [],
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'flags', ['fr', 'us', 'de', 'es', 'it']);
      var selectedFlag = this.intl.locale[0];
      var selectedLang = selectedFlag;

      if (this.flags.indexOf(selectedFlag) < 0) {
        selectedFlag = 'us';
        selectedLang = 'en';
      }

      Ember.set(this, 'selectedFlag', selectedFlag);
      Ember.set(this, 'selectedLang', selectedLang);
      this.getConsumption();
    },
    getConsumption: function getConsumption() {
      var _this = this;

      var path = _environment.default.APP.API_HOST + "/ai/consumption/" + this.session.data.authenticated.user.company.id;
      Ember.set(this, 'showConsumption', false);
      this.ajax.requestPromise(path).then(function (result) {
        if (result) {
          Ember.set(_this, 'consumption', result.consumption);
          Ember.set(_this, 'showConsumption', true);
        }
      });
    },
    actions: {
      handleKeyUp: function handleKeyUp(value, event) {
        if (event.key === 'Enter' && !event.shiftKey) {
          this.send('chatCompletion');
        }
      },
      chatCompletion: function chatCompletion() {
        var _this2 = this;

        if (this.question && !this.waitingAI) {
          var path = _environment.default.APP.API_HOST + "/ai/notification";
          var request = {
            lang: this.selectedLang,
            message: this.question,
            oldMessages: JSON.parse(JSON.stringify(this.allMessages))
          };
          var allMessages = this.allMessages;
          allMessages.push({
            role: 'user',
            content: this.question
          });
          Ember.set(this, 'waitingAI', true);
          Ember.set(this, 'allMessages', undefined);
          setTimeout(function () {
            Ember.set(_this2, 'allMessages', allMessages);
            setTimeout(function () {
              var chat = document.getElementById("chat");
              chat.scrollTop = chat.scrollHeight;
            });
          });
          Ember.set(this, 'question', '');
          this.ajax.requestWithBodyPromise(path, 'POST', 'application/json', JSON.stringify(request)).then(function (result) {
            Ember.set(_this2, 'waitingAI', false);

            _this2.getConsumption();

            if (result.choices) {
              var _iteratorNormalCompletion = true;
              var _didIteratorError = false;
              var _iteratorError = undefined;

              try {
                for (var _iterator = result.choices[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                  var choice = _step.value;
                  allMessages.push(choice.message);
                }
              } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                  }
                } finally {
                  if (_didIteratorError) {
                    throw _iteratorError;
                  }
                }
              }

              Ember.set(_this2, 'allMessages', undefined);
              setTimeout(function () {
                Ember.set(_this2, 'allMessages', allMessages);
                setTimeout(function () {
                  var chat = document.getElementById("chat");
                  chat.scrollTop = chat.scrollHeight;
                });
              });
            }
          }).catch(function (err) {
            console.error(err);
            Ember.set(_this2, 'waitingAI', false);
            var allMessages = _this2.allMessages;
            allMessages.pop();
            Ember.set(_this2, 'allMessages', undefined);
            setTimeout(function () {
              Ember.set(_this2, 'allMessages', allMessages);
            });

            _this2.getConsumption();

            var errorMessage = _this2.intl.t('ai.modal.error_prompt');

            if (err.responseJSON && err.responseJSON.code == 'AI_NEED_TOKENS') {
              errorMessage = _this2.intl.t('ai.modal.error_budget');
            }

            _this2.notify.error(errorMessage, {
              closeAfter: 5000
            });
          });
        }
      },
      onSelectFlag: function onSelectFlag(flag) {
        switch (flag) {
          case 'us':
            Ember.set(this, 'selectedLang', 'en');
            break;

          default:
            Ember.set(this, 'selectedLang', flag);
        }
      },
      copyClipboard: function copyClipboard(message) {
        var _this3 = this;

        navigator.clipboard.writeText(message).then(function () {
          _this3.notify.info(_this3.intl.t("word.copy_clipboard"));
        }).catch(function (err) {
          _this3.notify.info(_this3.intl.t("word.copy_clipboard"));
        });
      },
      clear: function clear() {
        var _this4 = this;

        Ember.set(this, 'allMessages', undefined);
        setTimeout(function () {
          Ember.set(_this4, 'allMessages', []);
        });
      }
    }
  });

  _exports.default = _default;
});