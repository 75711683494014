define("teelt-frontend/components/talents/table/talents-custom-table-content", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talents-custom-table-content'],
    defaultProfileImageUrl: _environment.default.APP.DEFAULT_PROFILE_IMAGE_URL,
    classNameBindings: ['key'],
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, "emailIncididentReportStartDate", moment().subtract(1, 'days').startOf('day').hour(9).toDate());
      Ember.set(this, "emailIncididentReportEndDate", moment().startOf('day').hour(9).toDate());
    },
    actions: {
      downloadAdminForm: function downloadAdminForm(talent) {
        var pathOTP = _environment.default.APP.API_HOST + "/users/otp";
        this.ajax.requestPromise(pathOTP).then(function (otp) {
          var path = _environment.default.APP.API_HOST + '/forms/download/talent/' + talent.id + "?otp=" + otp + "&adminForms=true";
          var element = document.createElement('a');
          element.setAttribute('href', path);
          element.setAttribute("target", "_blank");
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        });
      },
      selectTemplate: function selectTemplate(minus) {
        var subTemplates = this.result.template.subTemplates;

        for (var i = 0; i < subTemplates.length; i++) {
          if (subTemplates[i].template.id == this.result.selectedTemplate.id) {
            if (minus) {
              if (i > 0) {
                Ember.set(this.result, 'selectedTemplateId', subTemplates[i - 1].template.id);
              } else {
                Ember.set(this.result, 'selectedTemplateId', subTemplates[subTemplates.length - 1].template.id);
              }
            } else {
              if (i < subTemplates.length - 1) {
                Ember.set(this.result, 'selectedTemplateId', subTemplates[i + 1].template.id);
              } else {
                Ember.set(this.result, 'selectedTemplateId', subTemplates[0].template.id);
              }
            }

            Ember.set(this, 'rand', new Date());
            return;
          }
        }
      }
    }
  });

  _exports.default = _default;
});