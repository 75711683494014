define("teelt-frontend/authenticators/impersonate", ["exports", "ember-simple-auth/authenticators/base", "teelt-frontend/config/environment"], function (_exports, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    ajax: Ember.inject.service('ajax'),
    store: Ember.inject.service('store'),
    intl: Ember.inject.service(),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    customLabelsService: Ember.inject.service('custom-labels-service'),
    loginEndpoint: _environment.default.APP.API_HOST + "/users/impersonate/",
    logoutEndpoint: _environment.default.APP.API_HOST + "/users/me/logout",
    authenticate: function authenticate(userId, role) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var path = _this.loginEndpoint + userId;

        if (role) {
          path += "?role=" + role;
        }

        _this.ajax.requestWithBodyPromise(path, 'POST').then(function (user) {
          _this.changeLang(user);

          Ember.run(function () {
            setTimeout(function () {
              _this.rolecustomService.reloadCustomRoles();

              _this.customLabelsService.loadCustomLabels();
            }, 2000);
            resolve({
              "mode": "impersonate",
              "username": user.username,
              "user": user
            });
          });
        }).catch(function (err) {
          Ember.run(function () {
            reject({
              status: 401
            });
          });
        });
      });
    },
    restore: function restore(data) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (data.username) {
          setTimeout(function () {
            _this2.rolecustomService.reloadCustomRoles();

            _this2.customLabelsService.loadCustomLabels();
          }, 2000);

          _this2.changeLang(data.user);

          resolve(data);
        } else {
          reject();
        }
      });
    },
    invalidate: function invalidate() {
      // We clear all store
      this.store.unloadAll();
      return this.ajax.requestPromise(this.logoutEndpoint, 'POST');
    },

    /*
     invalidate(data) {
      // We clear all store
      this.store.unloadAll();
      return new RSVP.Promise((resolve, reject) => {
        return this.ajax.requestWithBodyPromise(this.logoutEndpoint, 'POST', 'application/json', JSON.stringify({token:data.token})).then(user => {
          if (!user) {
            run(() => {
                reject({status: 401});
            });
           }
          this.changeLang(user);
            run(() => {
              resolve({
                    "mode": "impersonate",
                    "username": user.username,
                    "user": user,
                    "token": token
                });
            })
          }).catch(err => {
            run(() => {
                  reject({status: 401});
              });
          });
      });
    },
    */
    changeLang: function changeLang(user) {
      if (user.lang) {
        this.intl.setLocale([user.lang, 'en', 'fr']);
      }
    }
  });

  _exports.default = _default;
});