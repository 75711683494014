define("teelt-frontend/mixins/google-tag-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    afterModel: function afterModel() {
      var _this = this;

      this._super.apply(this, arguments);

      setTimeout(function () {
        var authenticatedUser = _this.session.data.authenticated.user;
        var data = {
          'page_title': window.location.pathname,
          'page_path': window.location.pathname
        };

        if (authenticatedUser) {
          data['event'] = 'logged';
          data['user_id'] = authenticatedUser.id;
          data['firstname'] = authenticatedUser.firstname;
          data['company_name'] = authenticatedUser.company.name;
          var roles = [];
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = authenticatedUser.roles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var role = _step.value;
              roles.push(role.roleId);
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          data['role'] = roles.join();
        } //console.log('PUSH DATALAYER', data);


        window.dataLayer.push(data);
      }, 0);
    }
  });

  _exports.default = _default;
});