define("teelt-frontend/components/form/field/complex/field-date", ["exports", "teelt-frontend/utils/string-util", "jquery"], function (_exports, _stringUtil, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['field-date'],
    uiUtils: Ember.inject.service('ui-utils'),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'uuid', (0, _stringUtil.default)().generateUUID());
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.uiUtils.initDatePicker((0, _jquery.default)('#' + this.uuid), function (date) {
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        Ember.set(_this, "value", year + "-" + month + "-" + day);
      }, {
        defaultDate: moment(this.value, 'DD/MM/YYYY').toDate()
      });
    },
    actions: {}
  });

  _exports.default = _default;
});