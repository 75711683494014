define("teelt-frontend/templates/components/icon/home-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "enYbO2rp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"data-name\",\"Layer 1\"],[11,\"viewBox\",\"0 0 100 125\"],[11,\"x\",\"0px\"],[11,\"y\",\"0px\"],[9],[7,\"path\"],[11,\"d\",\"M4,50.74l.15-.11c4.05-3,8-6.05,12-9.07q-.92,19.24-1.38,38.49l-.11,5A9,9,0,0,0,14.76,87,7.87,7.87,0,0,0,15.42,89a8.13,8.13,0,0,0,6.9,4.61l2.66,0,5,.07,20.1.11,20.1-.13,5-.07,1.26,0c.44,0,.72,0,1.57,0a8.11,8.11,0,0,0,6.65-4.94,7.59,7.59,0,0,0,.55-2.09,9.45,9.45,0,0,0,.07-1.07l0-.63-.11-5q-.48-19.12-1.4-38.25c4.06,3.07,8.07,6.2,12.18,9.2h0a1.94,1.94,0,0,0,2.26-2.72l-.13-.12Q87.32,37.71,76.2,27.83a36.19,36.19,0,0,0-.65-21.47,3.81,3.81,0,0,0-1.72-.21,3.87,3.87,0,0,0-1.73.21v0a36.37,36.37,0,0,0-1.64,16.45c-6-5.24-12-10.47-18.08-15.55a3.7,3.7,0,0,0-4.75,0Q24.06,27,1.77,48.09h0A1.57,1.57,0,0,0,2,50.15C2.59,50.86,3.46,51.12,4,50.74Zm45.91,35.7-9.25,0c.52-5.59.95-11.18,1.23-16.78l.22-4.89,0-1.15a.17.17,0,0,1,.06-.08.22.22,0,0,1,.15-.08s.06,0,.06,0h0s0,0,.29,0l2.45,0,4.9,0,4.9,0,2.45,0a2,2,0,0,0,.26,0,.29.29,0,0,1,.14.08.17.17,0,0,1,.05.1l0,1.23.21,4.9c.27,5.56.69,11.11,1.2,16.67ZM50,14.91C60,23.17,70.14,31.17,80.44,39a1.43,1.43,0,0,0-.17.61v.24q-1,20.07-1.48,40.17l-.11,5a1.93,1.93,0,0,1-.06.63,1,1,0,0,1-.13.32,1.33,1.33,0,0,1-.49.48,1.16,1.16,0,0,1-.63.16l-2.36,0-5-.07-6.61,0c.51-5.6.93-11.2,1.21-16.8l.21-4.9,0-1.3a7.27,7.27,0,0,0-3.24-6,7.18,7.18,0,0,0-2.18-1,7.68,7.68,0,0,0-1.18-.22l-.93,0-2.45,0-4.9,0-4.9,0-2.45,0a7.55,7.55,0,0,0-4.36,1.37,7.36,7.36,0,0,0-2.73,3.78,7.63,7.63,0,0,0-.32,2.29l0,1.23.21,4.9c.29,5.56.71,11.12,1.23,16.68l-6.89,0-5,.06-2.2,0a1.26,1.26,0,0,1-.61-.2,1.19,1.19,0,0,1-.57-.82s0-.12,0-.18l0-.61-.11-5q-.48-20.08-1.46-40.18h0a1.36,1.36,0,0,0-.22-.65C29.84,31.15,40,23.16,50,14.91Z\"],[9],[10],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/icon/home-svg.hbs"
    }
  });

  _exports.default = _default;
});