define("teelt-frontend/templates/components/utils/bar-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3KNy78lN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"canvas\"],[12,\"id\",[21,\"id\"]],[12,\"style\",[28,[\"height:\",[21,\"canvaHeight\"],\"px!important; width:\",[21,\"canvaWidth\"],\"px!important; margin:0 auto;\"]]],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/bar-chart.hbs"
    }
  });

  _exports.default = _default;
});