define("teelt-frontend/templates/components/custom-field/display/with-fetch/talent-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qiOljRJP",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"talent/talent-name\",[[23,[\"talent\"]]],null],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/custom-field/display/with-fetch/talent-name.hbs"
    }
  });

  _exports.default = _default;
});