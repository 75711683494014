define("teelt-frontend/templates/components/talents/talent-tasks-by-period-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AmyixhSX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"task-selection\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"right-actions float-right\"],[9],[0,\"\\n    \"],[7,\"button\"],[12,\"class\",[28,[\"btn g-talent-btn \",[27,\"if\",[[27,\"eq\",[[23,[\"taskView\"]],\"todo\"],null],\"selected\"],null]]]],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"taskView\"]]],null],\"todo\"]],[9],[1,[27,\"t\",[\"task.to_realise_short\"],null],false],[10],[0,\"\\n    \"],[7,\"button\"],[12,\"class\",[28,[\"btn g-talent-btn \",[27,\"if\",[[27,\"eq\",[[23,[\"taskView\"]],\"done\"],null],\"selected\"],null]]]],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"taskView\"]]],null],\"done\"]],[9],[1,[27,\"t\",[\"task.done\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"period\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"talents/talent-tasks-by-period-period\",null,[[\"talent\",\"period\",\"taskView\",\"tasksCompleted\",\"tasksToRealise\",\"highlight\",\"showPeriod\"],[[23,[\"talent\"]],[23,[\"period\"]],[23,[\"taskView\"]],[23,[\"tasksCompleted\"]],[23,[\"tasksToRealise\"]],[27,\"lte\",[[23,[\"period\",\"index\"]],[23,[\"talentCurrentPeriod\",\"index\"]]],null],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-tasks-by-period-mobile.hbs"
    }
  });

  _exports.default = _default;
});