define("teelt-frontend/components/settings/integrations/lucca-settings", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['lucca-setting'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      if (!this.session.data.authenticated.user.company.activeLucca) {
        this.router.transitionTo('board.settings');
        return;
      }

      var path = _environment.default.APP.API_HOST + "/integrations/lucca/settings";
      this.ajax.requestPromise(path).then(function (lucca) {
        if (!lucca) {
          lucca = {};
        }

        Ember.set(_this2, 'lucca', lucca);
        Ember.set(_this2, 'canSyncEstablishment', _this2.lucca.establishmentCustomFieldId);
        Ember.set(_this2, 'canSyncDepartment', _this2.lucca.departmentCustomFieldId);
      });
      var customFieldPath = _environment.default.APP.API_HOST + "/custom-fields?type=multiple_choice";
      this.ajax.requestPromise(customFieldPath).then(function (customFields) {
        Ember.set(_this2, 'customFields', customFields);
        Ember.run.later(function () {
          _this2.$('#establishmentField').select2({});

          if (_this2.lucca.establishmentCustomFieldId) {
            _this2.$('#establishmentField').val(_this2.lucca.establishmentCustomFieldId);

            _this2.$('#establishmentField').trigger('change');
          }

          var _this = _this2;
          $('#establishmentField').on('change', function (e) {
            Ember.set(_this.lucca, 'establishmentCustomFieldId', $(this).val());
            Ember.set(_this, 'canSyncEstablishment', false);
          });

          _this2.$('#departmentField').select2({});

          if (_this2.lucca.departmentCustomFieldId) {
            _this2.$('#departmentField').val(_this2.lucca.departmentCustomFieldId);

            _this2.$('#departmentField').trigger('change');
          }

          $('#departmentField').on('change', function (e) {
            Ember.set(_this.lucca, 'departmentCustomFieldId', $(this).val());
            Ember.set(_this, 'canSyncDepartment', false);
          });
        }, 0);
      });
    },
    verifyLucca: function verifyLucca() {
      var ok = true;
      var errors = {};

      if (!this.lucca.domain || this.lucca.domain.length == 0) {
        errors.domain = true;
        ok = false;
      }

      if (!this.lucca.id && (!this.lucca.apikey || this.lucca.apikey.length == 0)) {
        errors.apikey = true;
        ok = false;
      }

      Ember.set(this, 'errors', errors);
      return ok;
    },
    actions: {
      previous: function previous() {
        this.router.transitionTo('board.setting.integrations');
      },
      save: function save() {
        var _this3 = this;

        if (!this.verifyLucca()) return;
        var path = _environment.default.APP.API_HOST + "/integrations/lucca/settings";
        var method = 'PATCH';
        this.ajax.requestWithBodyPromise(path, method, 'application/json', JSON.stringify(this.lucca)).then(function () {
          _this3.router.transitionTo('board.setting.integrations');
        });
      },
      refreshEstablishments: function refreshEstablishments() {
        var _this4 = this;

        var path = _environment.default.APP.API_HOST + "/integrations/lucca/settings/establishments/refresh";
        var method = 'PATCH';
        this.ajax.requestWithBodyPromise(path, method).then(function () {
          _this4.notify.info(_this4.intl.t('settings.integrations.lucca.establishment_refresh_completed'));
        }).catch(function () {
          _this4.notify.error(_this4.intl.t('settings.integrations.lucca.establishment_refresh_failed'));
        });
      },
      refreshDepartments: function refreshDepartments() {
        var _this5 = this;

        var path = _environment.default.APP.API_HOST + "/integrations/lucca/settings/departments/refresh";
        var method = 'PATCH';
        this.ajax.requestWithBodyPromise(path, method).then(function () {
          _this5.notify.info(_this5.intl.t('settings.integrations.lucca.department_refresh_completed'));
        }).catch(function () {
          _this5.notify.error(_this5.intl.t('settings.integrations.lucca.department_refresh_failed'));
        });
      }
    }
  });

  _exports.default = _default;
});