define("teelt-frontend/routes/board/talent/talent-import", ["exports", "teelt-frontend/routes/parent/confirm-before-transition"], function (_exports, _confirmBeforeTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _confirmBeforeTransition.default.extend({
    actions: {
      close: function close() {
        this.transitionTo('board.talents');
      },
      onImported: function onImported(badImports, goodImports) {
        Ember.set(this.currentModel, 'badImports', badImports);
        Ember.set(this.currentModel, 'goodImports', goodImports);
        Ember.set(this.currentModel, 'saved', true);
        Ember.set(this.currentModel, 'showImportedModal', true);
      },
      showUsersPreview: function showUsersPreview(value) {
        if (value == 'goodImport') {
          Ember.set(this.currentModel, 'usersToShow', this.currentModel.goodImports);
        } else {
          Ember.set(this.currentModel, 'usersToShow', this.currentModel.badImports);
        }

        Ember.set(this.currentModel, 'showUsers', true);
      },
      onClickOverlay: function onClickOverlay() {}
    }
  });

  _exports.default = _default;
});