define("teelt-frontend/components/utils/map-view", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    width: 600,
    height: 450,
    googleMapEmbedUrl: 'https://www.google.com/maps/embed/v1/place?key=' + _environment.default.APP.GOOGLE_APIS.api_key,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.addObserver(this, 'iframeSrc', this, 'updateEmbedUrl');
      Ember.addObserver(this, 'latitude', this, 'updateEmbedUrl');
      Ember.addObserver(this, 'longitude', this, 'updateEmbedUrl');
      Ember.addObserver(this, 'query', this, 'updateEmbedUrl');
      this.updateEmbedUrl();
    },
    destroy: function destroy() {
      Ember.removeObserver(this, 'iframeSrc', this, 'updateEmbedUrl');
      Ember.removeObserver(this, 'latitude', this, 'updateEmbedUrl');
      Ember.removeObserver(this, 'longitude', this, 'updateEmbedUrl');
      Ember.removeObserver(this, 'query', this, 'updateEmbedUrl');

      this._super.apply(this, arguments);
    },
    updateEmbedUrl: function updateEmbedUrl() {
      Ember.set(this, 'embedUrl', undefined);
      var embedUrl = this.googleMapEmbedUrl;

      if (this.iframeSrc) {
        embedUrl = this.iframeSrc;
      } else if (this.latitude && this.longitude) {
        embedUrl += "&q=".concat(this.latitude, ",").concat(this.longitude);
      } else if (this.query) {
        var queryFormatUrl = encodeURIComponent(this.query);
        embedUrl += "&q=".concat(queryFormatUrl);
      }

      Ember.set(this, 'embedUrl', embedUrl);
    }
  });

  _exports.default = _default;
});