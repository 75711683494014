define("teelt-frontend/components/content/sign-pending-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['sign-pending-popup'],
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.participants && this.participants.length) {
        var pendingData = [];
        var admins = this.participants.filter(function (p) {
          return p.role === "ROLE_RH" || p.role === "ROLE_ADMIN";
        });
        var managers = this.participants.filter(function (p) {
          return p.role === "ROLE_MANAGER";
        });
        var officeManagers = this.participants.filter(function (p) {
          return p.role === "ROLE_CUSTOM_OFFICE_MANAGER";
        });
        var administratives = this.participants.filter(function (p) {
          return p.role === "ROLE_ADMINISTRATIVE";
        });
        var others = this.participants.filter(function (p) {
          return p.role === undefined;
        });

        if (admins.length) {
          var completed = admins.filter(function (a) {
            return a.state === "completed";
          });
          pendingData.push({
            role: "ROLE_RH",
            label: "ROLE_RH",
            state: completed.length ? "signed" : "pending"
          });
        }

        if (managers.length) {
          var _completed = managers.filter(function (m) {
            return m.state === "completed";
          });

          pendingData.push({
            role: "ROLE_MANAGER",
            label: "ROLE_MANAGER",
            state: _completed.length ? "signed" : "pending"
          });
        }

        if (officeManagers.length) {
          var _completed2 = officeManagers.filter(function (om) {
            return om.state === "completed";
          });

          pendingData.push({
            role: "ROLE_CUSTOM_OFFICE_MANAGER",
            label: "ROLE_CUSTOM_OFFICE_MANAGER",
            state: _completed2.length ? "signed" : "pending"
          });
        }

        if (administratives.length) {
          var _completed3 = administratives.filter(function (a) {
            return a.state === "completed";
          });

          pendingData.push({
            role: "ROLE_ADMINISTRATIVE",
            label: "ROLE_ADMINISTRATIVE",
            state: _completed3.length ? "signed" : "pending"
          });
        }

        if (others.length) {
          others.forEach(function (o) {
            return pendingData.push({
              label: o.label,
              state: o.completed ? "signed" : "pending"
            });
          });
        }

        this.set("pendingData", pendingData);
      }
    },
    actions: {
      onClickOverlay: function onClickOverlay() {
        this.set("showSignPending", false);
      }
    }
  });

  _exports.default = _default;
});