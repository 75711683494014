define("teelt-frontend/templates/components/ihm/explore-office/explore-office-blocks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mAhGUitj",
    "block": "{\"symbols\":[\"exploreOffice\",\"period\"],\"statements\":[[7,\"pre\"],[11,\"class\",\"explore-office-title space-top\"],[9],[1,[27,\"t\",[\"explore_office.name\"],null],false],[10],[0,\"\"],[7,\"div\"],[11,\"class\",\"explore-offices-periods-filter\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"periods\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"category-container\"],[3,\"action\",[[22,0,[]],\"toggleCurrentPeriod\",[22,2,[]]]],[9],[0,\"\\n      \"],[7,\"a\"],[12,\"class\",[28,[\"period-filter \",[27,\"if\",[[27,\"eq\",[[22,2,[]],[23,[\"currentPeriod\"]]],null],\"active\"],null]]]],[9],[1,[27,\"capitalize\",[[22,2,[\"name\"]]],null],false],[10],[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[28,[\"blue-line \",[27,\"if\",[[27,\"eq\",[[22,2,[]],[23,[\"currentPeriod\"]]],null],\"active\"],null]]]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"exploreOffices\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"explore-office-container\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"exploreOffices\"]]],null,{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,1,[\"periodId\"]],[23,[\"currentPeriod\",\"id\"]]],null]],null,{\"statements\":[[0,\"        \"],[1,[27,\"ihm/explore-office/explore-office-block\",null,[[\"exploreOffice\",\"actionLabel\",\"completed\",\"talent\",\"period\",\"onOpenModal\"],[[22,1,[]],[23,[\"actionLabel\"]],[23,[\"completed\"]],[23,[\"talent\"]],[23,[\"currentPeriod\"]],[23,[\"onOpenModal\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/ihm/explore-office/explore-office-blocks.hbs"
    }
  });

  _exports.default = _default;
});