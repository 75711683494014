define("teelt-frontend/components/ihm/explore-office/explore-office-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['explore-office-block'],
    userService: Ember.inject.service(),
    blockByPeriod: Ember.computed('talentCurrentPeriod', 'exploreOffice', function () {
      if (this.exploreOffice.completed) return false;

      if (this.talentCurrentPeriod != undefined && this.exploreOffice.periodIndex != undefined) {
        return this.talentCurrentPeriod < this.exploreOffice.periodIndex;
      }

      return false;
    }),
    blockByStart: Ember.computed('exploreOffice', 'talent', function () {
      if (this.talent) {
        if (this.exploreOffice.completed) return false;

        if (this.exploreOffice.startOffsetUnit && this.exploreOffice.startOffsetDirection) {
          var offset = this.exploreOffice.startOffset;

          if (this.exploreOffice.startOffsetDirection.toLowerCase() == 'before') {
            offset = -1 * offset;
          }

          var date = moment(this.talent.welcomeDate);

          switch (this.exploreOffice.startOffsetUnit.toLowerCase()) {
            case 'hour':
              date.add(offset, 'hours');
              break;

            case 'day':
              date.add(offset, 'days');
              break;

            case 'month':
              date.add(offset, 'months');
              break;
          }

          var now = moment();

          if (now.isBefore(date)) {
            return true;
          }

          return false;
        }
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.talent) {
        var periods = this.userService.getTalentPeriods(this.talent);
        Ember.set(this, 'periods', periods);
        Ember.set(this, 'talentCurrentPeriod', this.userService.getTalentCurrentPeriod(this.talent, periods));
      }
    },
    actions: {
      openModal: function openModal() {
        if (this.blockByPeriod) return;
        if (this.blockByStart) return;
        if (this.onOpenModal) this.onOpenModal(this.exploreOffice);
      }
    }
  });

  _exports.default = _default;
});