define("teelt-frontend/templates/downloading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cHFkmIDQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"page-not-found\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n  \"],[4,\"link-to\",[\"login.form\"],null,{\"statements\":[[7,\"img\"],[11,\"class\",\"teelt-logo\"],[11,\"src\",\"/logo/teelt-logo.png\"],[11,\"alt\",\"teelt-logo\"],[9],[10]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"message-container\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"message center-align\"],[9],[0,\"\\n          \"],[1,[21,\"icon/downloading-loader-svg\"],false],[0,\"\\n          \"],[7,\"p\"],[9],[1,[27,\"t\",[\"downloading.message\"],null],false],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/downloading.hbs"
    }
  });

  _exports.default = _default;
});