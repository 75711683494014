define("teelt-frontend/templates/components/utils/restore-impersonate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZRTS787z",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"restoreImpersonate\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"btn btn-primary waves-effect waves-light\"],[3,\"action\",[[22,0,[]],\"restoreImpersonate\"]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"impersonate-name\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"talent.impersonate_name\"],[[\"name\"],[[23,[\"session\",\"data\",\"authenticated\",\"user\",\"name\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"restore-impersonate-btn-label\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"talent.restore_impersonate\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/restore-impersonate.hbs"
    }
  });

  _exports.default = _default;
});