define("teelt-frontend/templates/components/material/mdc-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2U7FwhUL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"not\",[[23,[\"hideSwitch\"]]],null]],null,{\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"mdc-switch \",[27,\"if\",[[27,\"not\",[[23,[\"disabled\"]]],null],\"mdc-switch--checked\"],null]]]],[3,\"action\",[[22,0,[]],\"toggleSwitch\"],[[\"bubbles\"],[false]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"mdc-switch__track\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"mdc-switch__thumb-underlay\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"mdc-switch__thumb\"],[9],[0,\"\\n        \"],[7,\"input\"],[11,\"id\",\"basic-switch\"],[11,\"class\",\"mdc-switch__native-control\"],[11,\"role\",\"switch\"],[11,\"checked\",\"\"],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"label\"],[11,\"class\",\"label\"],[11,\"for\",\"basic-switch\"],[9],[0,\"\\n  \"],[1,[27,\"if\",[[27,\"not\",[[23,[\"disabled\"]]],null],[23,[\"disabledLabel\"]],[23,[\"activatedLabel\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/material/mdc-switch.hbs"
    }
  });

  _exports.default = _default;
});