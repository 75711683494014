define("teelt-frontend/components/template/table/templates-users-circles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['users-circles'],
    talentsPictures: Ember.computed('users', function () {
      if (!this.users) return [];
      var result = [];

      for (var i = 0; i < this.users.length && result.length < 3; i++) {
        var user = this.users[i];

        if (user.profilePictureUrl) {
          result.push(user.profilePictureUrl);
        }
      }

      return result;
    }),
    moreAffected: Ember.computed('users', 'talentsPictures', function () {
      if (!this.users || !this.talentsPictures) return 0;
      return this.users.length - this.talentsPictures.length;
    }),
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});