define("teelt-frontend/components/activity/activity-task", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var custom = JSON.parse(this.activity.customParams);
      var assign = custom.assign;

      if (assign != 'talent') {
        Ember.set(this, 'assign', assign);
      }
    }
  });

  _exports.default = _default;
});