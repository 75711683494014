define("teelt-frontend/routes/board/event/campaign-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      duplicateId: ''
    },
    model: function model(params) {
      var model = {
        saved: true
      };
      model.duplicateId = params.duplicateId;
      return model;
    }
  });

  _exports.default = _default;
});