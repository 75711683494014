define("teelt-frontend/templates/components/custom-field/field/custom-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ro44nZQo",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"data\"]],[27,\"not\",[[23,[\"waitingTalent\"]]],null]],null]],null,{\"statements\":[[1,[27,\"utils/searchable-select\",null,[[\"values\",\"selectedValue\",\"defaultValue\",\"multiple\",\"onValueChange\"],[[23,[\"data\"]],[23,[\"selectedValue\"]],[27,\"talent-name\",[[23,[\"talent\"]]],null],false,[27,\"action\",[[22,0,[]],\"onSelectChanged\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/custom-field/field/custom-select.hbs"
    }
  });

  _exports.default = _default;
});