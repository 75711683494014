define("teelt-frontend/models/email", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr(),
    name: _emberData.default.attr('string'),
    lang: _emberData.default.attr('string'),
    subject: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    replyTo: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    companyId: _emberData.default.attr('number'),
    allOfficeEmailId: _emberData.default.attr('number'),
    officeId: _emberData.default.attr('number'),
    sourceCode: _emberData.default.attr('string'),
    creationCode: _emberData.default.attr('string')
  });

  _exports.default = _default;
});