define("teelt-frontend/components/session/auto-disconnect-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['auto-disconnect-message'],
    eventBus: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.eventBus.on('AUTO_DISCONNECT_AFTER_DELAY', this, 'onDisconnect');
    },
    destroy: function destroy() {
      this.eventBus.off('AUTO_DISCONNECT_AFTER_DELAY', this, 'onDisconnect');

      this._super.apply(this, arguments);
    },
    onDisconnect: function onDisconnect() {
      Ember.set(this, 'showMessage', true);
    }
  });

  _exports.default = _default;
});