define("teelt-frontend/components/module/new-module-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['new-module'],
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    onStepChanged: Ember.observer('step', function () {
      if (this.step == 1) {
        this.$('.step2').hide(400);
        this.$('.step1').show(400);
      } else if (this.step == 2) {
        this.$('.step1').hide(400);
        this.$('.step2').show(400);
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('.step2').hide();
    },
    actions: {
      changeType: function changeType(type) {
        Ember.set(this, 'type', type);
      }
    }
  });

  _exports.default = _default;
});