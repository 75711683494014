define("teelt-frontend/models/audience", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    filters: _emberData.default.attr('string'),
    userCount: _emberData.default.attr('number'),
    userCount30d: _emberData.default.attr('number'),
    templateCount: _emberData.default.attr('number'),
    moduleCount: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });

  _exports.default = _default;
});