define("teelt-frontend/components/template/template-detail", ["exports", "teelt-frontend/utils/string-util", "teelt-frontend/config/environment", "teelt-frontend/utils/jquery-utils"], function (_exports, _stringUtil, _environment, _jqueryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['template-detail'],
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    userService: Ember.inject.service('user-service'),
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    router: Ember.inject.service(),
    showRolesDropdown: false,
    activeRolesFilters: [],
    query: '',
    roles: [],
    templatePage: 'home',
    pages: ['home', 'tools', 'resources', 'tasks', 'talents'],
    showDescription: true,
    onForceTemplatePageChanged: Ember.observer('forceTemplatePage', function () {
      if (this.forceTemplatePage) {
        this.send('switch', this.forceTemplatePage);
      }
    }),
    periodLinks: Ember.computed('template.periods', function () {
      var periods = this.template.periods;
      if (!periods) return;
      periods.sort(function (p1, p2) {
        return p1.period.offset < p2.period.offset ? -1 : 1;
      });
      return periods;
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.eventBus.on('ROLELIST_UPDATED', this, 'loadRoles');
      this.loadRoles(true);
      this.store.findRecord('template', this.templateId, {
        include: 'full'
      }).then(function (template) {
        Ember.set(_this, 'template', template);
      });

      if (this.userService.hasAnyRole(this.session.data.authenticated.user, 'ROLE_RH', 'ROLE_MRH')) {
        Ember.set(this, 'edit', true);
      } else {
        Ember.set(this, 'edit', false);
      }

      if (this.edit) {
        Ember.set(this, 'talent', {
          firstname: '[' + this.intl.t('word.firstname') + ']',
          manager: {
            profilePictureUrl: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
            firstname: (0, _stringUtil.default)().capitalize(this.intl.t('word.firstname')),
            lastname: this.intl.t('template.pages.home.manager_name')
          },
          company: this.session.data.authenticated.user.company
        });
      }
    },
    loadRoles: function loadRoles() {
      var init = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var roles = [{
        name: "all",
        label: "talent.roles.all",
        filter: "all",
        value: true
      }, {
        name: "rh",
        filter: "ROLE_RH",
        value: true
      }, {
        name: "manager",
        filter: "ROLE_MANAGER",
        value: true
      }, {
        name: "talent",
        filter: "ROLE_TALENT",
        value: true
      }];

      if (this.session.data.authenticated.user.company.activeMRH) {
        roles.push({
          name: "adminMRH",
          filter: "ROLE_MRH",
          value: true
        });
      }

      if (this.session.data.authenticated.user.company.activeOMRole) {
        roles.push({
          name: "officeManager",
          filter: "ROLE_CUSTOM_OFFICE_MANAGER",
          value: true
        });
      }

      if (this.session.data.authenticated.user.company.activeAdministrative) {
        roles.push({
          name: "administrative",
          filter: "ROLE_ADMINISTRATIVE",
          value: true
        });
      }

      if (this.session.data.authenticated.user.company.activeBuddyRole) {
        roles.push({
          name: "buddy",
          filter: "BUDDY",
          value: true
        });
      }

      var consultRoles = this.rolecustomService.getConsultRoles();

      if (consultRoles) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = consultRoles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var role = _step.value;
            roles.push({
              name: "ROLE_CONSULT-" + role.id,
              filter: "ROLE_CONSULT-" + role.id,
              value: true
            });
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      Ember.set(this, 'roles', roles);
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      setTimeout(function () {
        _this2.eventBus.on('CLICK_EVENT', _this2, 'checkToCloseModal');
      }, 300);
    },
    destroy: function destroy() {
      this.eventBus.off('ROLELIST_UPDATED', this, 'loadRoles');
      this.eventBus.off('CLICK_EVENT', this, 'checkToCloseModal');

      this._super.apply(this, arguments);
    },
    checkToCloseModal: function checkToCloseModal(e) {
      if (this.isDestroyed) return;

      if (!(0, _jqueryUtils.default)().checkIfClickInElement(e, ["show-roles-btn", "roles"])) {
        Ember.set(this, 'showRolesDropdown', false);
      }
    },
    updateTemplate: function updateTemplate() {
      var _this3 = this;

      if (!this.template.name.length > 0) {
        Ember.set(this.template, 'name', this.intl.t('word.title'));
      }

      if (!this.template.description || this.template.description.length <= 0) {
        Ember.set(this, 'showDescription', false);
        Ember.set(this.template, 'description', '-');
        setTimeout(function () {
          Ember.set(_this3, 'showDescription', true);
        }, 0);
      }

      this.template.save();
    },
    search: function search() {
      Ember.set(this, 'filteredTasks', undefined);

      if (!this.roles[0].value || this.query && this.query.length > 0) {
        var verifyQuery = this.query && this.query.length > 0;
        var roleFilters = this.roles.filter(function (f) {
          return f.value;
        }).map(function (f) {
          return f.name.toLowerCase();
        });
        var filteredTasks = [-1];
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = this.template.periods[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var period = _step2.value;

            if (period.tasks) {
              var _iteratorNormalCompletion3 = true;
              var _didIteratorError3 = false;
              var _iteratorError3 = undefined;

              try {
                for (var _iterator3 = period.tasks[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                  var task = _step3.value;

                  if (task.assign == 'system') {
                    if (roleFilters.indexOf(task.periodNotificationRecipient.toLowerCase()) >= 0) {
                      if (!verifyQuery || task.notification.name.toLowerCase().indexOf(this.query.toLowerCase()) >= 0) {
                        filteredTasks.push(task.id);
                      }
                    }
                  } else {
                    if (roleFilters.indexOf(task.assign.toLowerCase()) >= 0) {
                      if (!verifyQuery || task.name.toLowerCase().indexOf(this.query.toLowerCase()) >= 0) {
                        filteredTasks.push(task.id);
                      }
                    }
                  }
                }
              } catch (err) {
                _didIteratorError3 = true;
                _iteratorError3 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
                    _iterator3.return();
                  }
                } finally {
                  if (_didIteratorError3) {
                    throw _iteratorError3;
                  }
                }
              }
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        Ember.set(this, 'filteredTasks', filteredTasks);
      }
    },
    isElementInViewport: function isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      var viewportHeight = window.innerHeight || document.documentElement.clientHeight;
      var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
      var tolerance = -50; // marge de tolérance en pixels

      return rect.top - tolerance <= viewportHeight && rect.bottom + tolerance >= 0 && rect.left - tolerance <= viewportWidth && rect.right + tolerance >= 0;
    },
    handleScroll: function handleScroll() {
      var _this4 = this;

      var links = document.querySelectorAll('.link-element');
      var activeLinkSet = false;
      links.forEach(function (link) {
        if (!activeLinkSet) {
          var periodId = link.getAttribute('id');
          var targetElement = document.getElementById(periodId);

          if (targetElement && _this4.isElementInViewport(targetElement)) {
            if (_this4.isDestroyed) return;
            Ember.set(_this4, 'activePeriodLink', '#' + periodId);
            activeLinkSet = true;
          }
        }
      });
    },
    actions: {
      onSearchKeyUp: function onSearchKeyUp() {
        var _this5 = this;

        if (this.keyUpAction) {
          Ember.run.cancel(this.keyUpAction);
        }

        var keyUpAction = Ember.run.later(function () {
          _this5.search();
        }, 300);
        Ember.set(this, 'keyUpAction', keyUpAction);
      },
      filter: function filter(_filter, value) {
        if (_filter == 'all') {
          var _iteratorNormalCompletion4 = true;
          var _didIteratorError4 = false;
          var _iteratorError4 = undefined;

          try {
            for (var _iterator4 = this.roles[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
              var role = _step4.value;
              Ember.set(role, "value", value);
            }
          } catch (err) {
            _didIteratorError4 = true;
            _iteratorError4 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
                _iterator4.return();
              }
            } finally {
              if (_didIteratorError4) {
                throw _iteratorError4;
              }
            }
          }
        } else if (!value) {
          Ember.set(this.roles[0], 'value', false);
        }

        this.search();
      },
      toggleRoleFilter: function toggleRoleFilter(role) {
        var value = role.value ? false : true;
        Ember.set(role, 'value', value);
        this.send('filter', role.filter, value);
      },
      switch: function _switch(page) {
        var _this6 = this;

        Ember.set(this, 'templatePage', page);
        var _iteratorNormalCompletion5 = true;
        var _didIteratorError5 = false;
        var _iteratorError5 = undefined;

        try {
          for (var _iterator5 = this.pages[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
            var p = _step5.value;

            if (p != page) {
              this.$('.block-' + p).hide(400);
            }
          }
        } catch (err) {
          _didIteratorError5 = true;
          _iteratorError5 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion5 && _iterator5.return != null) {
              _iterator5.return();
            }
          } finally {
            if (_didIteratorError5) {
              throw _iteratorError5;
            }
          }
        }

        this.$('.block-' + page).show(400);
        window.removeEventListener('scroll', this.handleScroll);

        if (page == 'tasks') {
          window.addEventListener('scroll', function () {
            Ember.run.debounce(_this6, _this6.handleScroll, 100);
          });
        }
      },
      updateTemplate: function updateTemplate() {
        this.updateTemplate();
      },
      extract: function extract() {
        var _this7 = this;

        var otpURL = _environment.default.APP.API_HOST + "/users/otp";
        this.ajax.requestPromise(otpURL).then(function (otp) {
          setTimeout(function () {
            var downloadUrl = _environment.default.APP.API_HOST + "/templates/" + _this7.template.id + "/export/csv?otp=" + encodeURIComponent(otp);
            var element = document.createElement('a');
            element.setAttribute('href', downloadUrl);
            element.setAttribute("target", "_blank");
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          }, 100);
        });
      },
      navigateToPeriod: function navigateToPeriod(periodId) {
        var targetElement = document.querySelector(periodId);

        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: 'smooth'
          });
        }
      }
    }
  });

  _exports.default = _default;
});