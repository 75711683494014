define("teelt-frontend/services/dynamic-data", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    myTaskNumberUrl: _environment.default.APP.API_HOST + "/users/me/taskNumber",
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service(),
    myTaskNumber: 0,
    myTaskNumberPeriod: 5 * 60 * 1000,
    userService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.refreshUserRemainingTask();
      this.initIntervals();
    },
    refreshUserRemainingTask: function refreshUserRemainingTask() {
      var sessionUser = this.session.data.authenticated.user;

      if (this.userService.isRH(sessionUser) || this.userService.isManager(sessionUser) || this.userService.isOfficeManager(sessionUser)) {
        /*
        this.ajaxService.requestPromise(this.myTaskNumberUrl, 'GET').then(data => {
          set(this, 'myTaskNumber', data.count);
        });
        */
      }
    },
    initIntervals: function initIntervals() {
      var _this = this;

      var sessionUser = this.session.data.authenticated.user;

      if (this.userService.isRH(sessionUser) || this.userService.isManager(sessionUser)) {
        setInterval(function () {
          _this.refreshUserRemainingTask();
        }, this.myTaskNumberPeriod);
      }
    }
  });

  _exports.default = _default;
});