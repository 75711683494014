define("teelt-frontend/templates/board/talent/talent-detail/administrative", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qDZfzdQk",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"form/forms-list-talent\",null,[[\"talent\",\"formId\",\"donwloadAll\",\"isAdministrative\"],[[23,[\"model\",\"talent\"]],[23,[\"model\",\"formId\"]],true,true]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/talent/talent-detail/administrative.hbs"
    }
  });

  _exports.default = _default;
});