define("teelt-frontend/components/talents/talent-tasks-by-period-period", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showPeriod: true,
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    router: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    customErrorService: Ember.inject.service('custom-error'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var tasksToRealise = this.tasksToRealise;
      var tasksCompleted = this.tasksCompleted;
      this.reorderPeriodTasks(tasksToRealise);
      this.reorderPeriodTasks(tasksCompleted);
      Ember.set(this, 'tasksCompleted', tasksCompleted);
      Ember.set(this, 'tasksToRealise', tasksToRealise);

      if (this.forceContentTaskId) {
        var tasks = this.tasksToRealise.concat(this.tasksCompleted);
        var task = tasks.find(function (t) {
          return t.task && t.task.content && t.task.id == _this.forceContentTaskId;
        });

        if (task && this.periodIndex <= 0) {
          this.openContent(task.task);
        }
      }
    },
    currentPeriodTasks: Ember.computed('tasksToRealise', 'period.id', function () {
      var _this2 = this;

      if (!this.tasksToRealise) return;
      var currentPeriodTasks = [];
      this.tasksToRealise.forEach(function (e) {
        if (e.task && e.task.periodId == _this2.period.id) {
          currentPeriodTasks.push(e.task);
        } else if (e.event && e.event.periodId == _this2.period.id) {
          currentPeriodTasks.push(e.event);
        }
      });
      return currentPeriodTasks;
    }),
    mandatoryTaskIndex: Ember.computed('tasksToRealise', function () {
      if (!this.tasksToRealise) return 500;

      for (var i = 0; i < this.tasksToRealise.length; i++) {
        var task = this.tasksToRealise[i];

        if (task.task && task.task.periodMandatory) {
          return i;
        }
      }

      return 500;
    }),
    validateUnvalidateTask: function validateUnvalidateTask(task, validate) {
      var _this3 = this;

      if (task.completed && validate || !task.completed && !validate) return;
      var url = _environment.default.APP.API_HOST + "/templates/" + this.talent.selectedTemplate.id + "/periods/" + task.periodId + "/tasks/" + task.id + "/validate";
      var params = {
        userId: this.talent.id,
        validate: validate
      };

      if (validate) {
        this.ajax.requestWithBodyPromise(url, "POST", 'application/json', JSON.stringify(params)).then(function () {
          _this3.moveTaskToCompleted(task);
        });
      } else {
        this.ajax.requestWithBodyPromise(url, "POST", 'application/json', JSON.stringify(params)).then(function () {
          _this3.moveTaskToToRealise(task);
        });
      }
    },
    reorderPeriodTasks: function reorderPeriodTasks(tasks) {
      tasks.sort(function (t1, t2) {
        var task1 = t1.task;
        var task2 = t2.task;
        var value1 = 0;
        var value2 = 0;

        if (task1 && task2 && task1.periodIndex !== undefined && task2.periodIndex !== undefined) {
          if (task1.periodIndex < task2.periodIndex) return -1;
          if (task1.periodIndex > task2.periodIndex) return 1;
        }

        if (task1 && task1.startOffset && task1.startOffsetUnit && task1.startOffsetDirection) {
          value1 = task1.startOffset;

          if (task1.startOffsetUnit == 'day') {
            value1 = value1 * 24;
          }

          if (task1.startOffsetDirection == 'BEFORE') {
            value1 = -1 * value1;
          }
        }

        if (task2 && task2.startOffset && task2.startOffsetUnit && task2.startOffsetDirection) {
          value2 = task2.startOffset;

          if (task2.startOffsetUnit == 'day') {
            value2 = value2 * 24;
          }

          if (task2.startOffsetDirection == 'BEFORE') {
            value2 = -1 * value2;
          }
        }

        if (value1 == value2) return 0;
        return value1 < value2 ? -1 : 1;
      });
    },
    moveTaskToCompleted: function moveTaskToCompleted(task) {
      var _this4 = this;

      Ember.set(task, 'completed', true);
      var tasksToRealise = this.tasksToRealise;
      var tasksCompleted = this.tasksCompleted;

      for (var i = 0; i < tasksToRealise.length; i++) {
        if (tasksToRealise[i].task && tasksToRealise[i].task.id == task.id) {
          tasksToRealise.splice(i, 1);
        }
      }

      var taskValidations = this.talent.taskValidations;
      taskValidations.pushObject(task);
      tasksCompleted.push({
        task: task
      });
      this.reorderPeriodTasks(tasksToRealise);
      this.reorderPeriodTasks(tasksCompleted);
      Ember.set(this, 'tasksToRealise', undefined);
      Ember.set(this, 'tasksCompleted', undefined);
      setTimeout(function () {
        Ember.set(_this4, 'tasksCompleted', tasksCompleted);
        Ember.set(_this4, 'tasksToRealise', tasksToRealise);

        _this4.eventBus.trigger('FORCE_RELOAD_TALENT');
      }, 100);
    },
    moveTaskToToRealise: function moveTaskToToRealise(task) {
      var _this5 = this;

      Ember.set(task, 'completed', false);
      var tasksCompleted = this.tasksCompleted;
      var tasksToRealise = this.tasksToRealise; // remove from tasksCompleted

      for (var i = 0; i < tasksCompleted.length; i++) {
        if (tasksCompleted[i].task && tasksCompleted[i].task.id == task.id) {
          tasksCompleted.splice(i, 1);
          break;
        }
      } // remove from talent.taskValidatioons


      var taskValidations = this.talent.taskValidations;

      for (var _i = 0; _i < taskValidations.length; _i++) {
        if (taskValidations[_i].id == task.id) {
          taskValidations.splice(_i, 1);
          break;
        }
      } // Undefined before in order to trigger user observer


      Ember.set(this.talent, "taskValidations", undefined);
      Ember.set(this.talent, "taskValidations", taskValidations);
      tasksToRealise.pushObject({
        task: task
      }); // this.sortTasks(tasksToRealise);

      this.reorderPeriodTasks(tasksToRealise);
      this.reorderPeriodTasks(tasksCompleted);
      Ember.set(this, 'tasksToRealise', undefined);
      Ember.set(this, 'tasksCompleted', undefined);
      setTimeout(function () {
        Ember.set(_this5, 'tasksCompleted', tasksCompleted);
        Ember.set(_this5, 'tasksToRealise', tasksToRealise);
      }, 100);
    },
    gotoCustomFieldSection: function gotoCustomFieldSection(field) {
      if (!field) return;

      if (field.section === "talent-profile") {
        this.eventBus.trigger('CLOSE_BOTTOM_PANNEL');

        if (field.type === "file") {
          this.router.transitionTo('talent.profile.documents');
        } else {
          this.router.transitionTo('talent.profile.detail').then(function () {
            Ember.run.later(function () {
              var el = document.getElementById('input-' + field.id);
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              });
            }, 500);
          });
        }
      }
    },
    openForm: function openForm(task) {
      Ember.set(this, 'formToOpen', task.task.form);
      Ember.set(this, "showFormModal", true);
      Ember.set(this, 'currentTaskForm', task);
    },
    openExploreOffice: function openExploreOffice(task) {
      Ember.set(this, 'exploreOfficeToOpen', task.task.exploreOffice);
      Ember.set(this, 'showExploreOfficeModal', true);
    },
    lauchMeetPeopleAndShowParticipants: function lauchMeetPeopleAndShowParticipants(task) {
      if (task.task.completed) {
        this.showMeetPeopleParticipants(task);
      } else {
        Ember.set(this, 'meetPeopleToOpen', task.task.meetPeople);
        if (task.task.meetPeople.hasBeenLaunched) return;
        Ember.set(this, 'showLauchGameModal', true);
      }
    },
    showMeetPeopleParticipants: function showMeetPeopleParticipants(task) {
      var _this6 = this;

      var appearEffect = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      Ember.set(this, 'meetPeopleToOpen', task.task.meetPeople);
      this.ajax.requestPromise(_environment.default.APP.API_HOST + '/meetpeoples/' + task.task.meetPeople.id + '/response/' + this.talent.id, 'GET').then(function (res) {
        Ember.set(_this6.meetPeopleToOpen, 'hasBeenLaunched', res.hasBeenLaunched);
        Ember.set(_this6.meetPeopleToOpen, 'participants', res.participants);
        Ember.set(_this6, 'appearEffect', appearEffect);
        Ember.set(_this6, "showMeetPeopleModal", true);
        Ember.set(_this6, 'displayBlock', 'contact');
      });
    },
    openContent: function openContent(task) {
      if (task.content.fullValue && task.content.type == 'filecard') {
        this.openFile(task.content);
      } else if (task.content.type != 'map') {
        this.showContentDetail(task.content);
      }

      Ember.set(task, 'canValidateContent', true);
    },
    goToEvent: function goToEvent(event) {
      this.eventBus.trigger('CLOSE_BOTTOM_PANNEL');
      this.router.transitionTo('talent.integration.events').then(function () {
        Ember.run.later(function () {
          var el = document.getElementById('event-' + event.id);
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }, 500);
      });
    },
    openFile: function openFile(content) {
      var _this7 = this;

      if (!content.fullValue) return;
      Ember.set(this, "showDownloadingModal", true);
      this.fileUploadService.getObjectUrl(content.fullValue).then(function (data) {
        var url = data.url;

        if (!content.toDownload) {
          url += "&inline";
        }

        window.open(url, 'newtab');
        Ember.set(_this7, "showDownloadingModal", false);
      }).catch(function (err) {
        _this7.customErrorService.handleAjaxError(err);
      });
    },
    showContentDetail: function showContentDetail(content) {
      var _this8 = this;

      if (content.fullValue) {
        Ember.set(this, 'showContentDetailPopup', content);
      } else {
        var path = _environment.default.APP.API_HOST + '/contents/' + content.id;
        this.ajax.requestPromise(path).then(function (c) {
          Ember.set(content, 'fullValue', c.fullValue);
          Ember.set(_this8, 'showContentDetailPopup', content);
        });
      }
    },
    actions: {
      completeTask: function completeTask(task) {
        if (task.task) {
          if (!task.task.auto && !task.task.form && !task.task.content && !task.task.signContent && !task.task.meetPeople && !task.task.exploreOffice || task.task.readContent) {
            this.validateUnvalidateTask(task.task, true);
          } else if (task.task.field) {
            this.gotoCustomFieldSection(task.task.field);
          } else if (task.task.form) {
            this.openForm(task);
          } else if (task.task.content) {
            this.openContent(task.task);
          } else if (task.task.meetPeople) {
            this.lauchMeetPeopleAndShowParticipants(task);
          } else if (task.task.exploreOffice) {
            this.openExploreOffice(task);
          }
        } else if (task.event) {
          this.goToEvent(task.event);
        }
      },
      uncompleteTask: function uncompleteTask(task) {
        if (task.task) {
          if (task.task.type == "meetPeople" && task.task.completed) {
            this.showMeetPeopleParticipants(task);
          } else {
            if (task.task && task.task.form) return;

            if (!task.task.auto && !task.task.formId) {
              this.validateUnvalidateTask(task.task, false);
            }
          }
        }
      },
      showGallery: function showGallery(task) {
        Ember.set(this, 'meetPeopleToOpen', task.task.meetPeople);
        Ember.set(this, 'showMeetPeopleGalleryModal', true);
      },
      showGalleryMeetPeople: function showGalleryMeetPeople(meetPeople) {
        var reOpenParticipantList = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        Ember.set(this, 'reOpenParticipantList', reOpenParticipantList);
        Ember.set(this, 'meetPeopleToOpen', meetPeople);
        Ember.set(this, 'showMeetPeopleGalleryModal', true);
        Ember.set(this, 'showMeetPeopleModal', false);
      },
      onFormResponseSent: function onFormResponseSent() {
        this.moveTaskToCompleted(this.currentTaskForm.task);
      },
      onClickOverlay: function onClickOverlay() {},
      onGameLauched: function onGameLauched(meetPeople) {
        Ember.set(this.meetPeopleToOpen, 'participants', meetPeople.participants);
        var task = this.tasksToRealise.find(function (t) {
          return t.task && t.task.meetPeople && t.task.meetPeople.id == meetPeople.id;
        });
        this.moveTaskToCompleted(task.task);
      },
      closeGalleryModal: function closeGalleryModal() {
        if (this.reOpenParticipantList) {
          Ember.set(this, 'showMeetPeopleModal', true);
          Ember.set(this, 'reOpenParticipantList', false);
        }

        Ember.set(this, 'showMeetPeopleGalleryModal', false);
      },
      showExploreOfficeModal: function showExploreOfficeModal(task) {
        this.openExploreOffice(task);
      }
    }
  });

  _exports.default = _default;
});