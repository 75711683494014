define("teelt-frontend/templates/components/talents/talent-documents-expandable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Eby6Bodw",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"utils/expandable-section-title\",null,[[\"title\",\"expandable\",\"isExpanded\"],[[27,\"t\",[\"talent.documents.to_send\"],null],true,[23,[\"showDocumentsToSend\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showDocumentsToSend\"]]],null,{\"statements\":[[1,[27,\"talents/talent-documents-list\",null,[[\"talent\",\"unFilledOnly\"],[[23,[\"talent\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[27,\"utils/expandable-section-title\",null,[[\"title\",\"expandable\",\"isExpanded\"],[[27,\"t\",[\"talent.documents.sent\"],null],true,[23,[\"showDocumentsSent\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showDocumentsSent\"]]],null,{\"statements\":[[1,[27,\"talents/talent-documents-list\",null,[[\"talent\",\"filledOnly\"],[[23,[\"talent\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-documents-expandable.hbs"
    }
  });

  _exports.default = _default;
});