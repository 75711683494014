define("teelt-frontend/components/event/event-calendar", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["event-calendar"],
    intl: Ember.inject.service(),
    uiUtils: Ember.inject.service('ui-utils'),
    eventBus: Ember.inject.service(),
    session: Ember.inject.service(),
    userService: Ember.inject.service(),
    dateFormat: 'DD/MM/YYYY',
    showMinDate: false,
    showMaxDate: false,
    calendarLoaded: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.eventBus.on('NEW_EVENT_POPUP', this, 'forceOpenNewEvent');
    },
    destroy: function destroy() {
      this.eventBus.off('NEW_EVENT_POPUP', this, 'forceOpenNewEvent');

      this._super.apply(this, arguments);
    },

    /**
     * @params fromDate, toDate, events, shareCalendar (function)
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('.new-event-block').hide();
      this.createCalendar();
    },
    forceOpenNewEvent: function forceOpenNewEvent() {
      this.send('showNewEventForm');
    },
    createCalendar: function createCalendar() {
      var _this = this;

      this.$('#calendar').fullCalendar({
        themeSystem: 'bootstrap4',
        locale: this.intl.locale[0] == 'en' ? 'en-gb' : this.intl.locale[0],
        defaultDate: this.fromDate ? this.fromDate : this.defaultDate,
        initialView: 'dayGridMonth',
        height: 'auto',
        header: {
          left: 'month,agendaWeek,agendaDay,listWeek',
          center: 'title',
          right: 'prev,next'
        },
        customButtons: {
          addEvent: {
            text: this.intl.t('event.add'),
            click: function click() {
              _this.send('showNewEventForm');
            }
          }
        },
        events: this.calEventsFromDB(),
        minTime: '07:00',
        maxTime: '19:00',

        /*
        businessHours: {
            dow: [ 1, 2, 3, 4, 5], // Monday - Friday
             start: '09:00',
            end: '18:00',
        },*/
        validRange: {
          start: this.fromDate ? _moment.default.utc(this.fromDate) : undefined,
          end: this.toDate ? _moment.default.utc(this.toDate).add(1, 'days') : undefined
        },
        navLinks: true,
        eventLimit: true,
        selectable: this.displayMode !== 'READ_ONLY',
        editable: this.displayMode !== 'READ_ONLY',
        eventDurationEditable: this.displayMode !== 'READ_ONLY',
        aspectRatio: this.ratio ? this.ratio : 1.35,
        select: function select(startDate, endDate) {
          var start = startDate.format(_this.dateFormat);

          if (endDate.diff(startDate, 'minutes') <= 30) {
            endDate = (0, _moment.default)(startDate).add(1, 'hours');
          }

          var end = endDate.format(_this.dateFormat);
          var startTime = "ALL_DAY",
              endTime = "ALL_DAY";

          if (startDate.format().length > 10) {
            startTime = startDate.format('HH:mm');
            endTime = endDate.format('HH:mm');
          } else {
            end = endDate.add(-1, 'days').format(_this.dateFormat);
          }

          _this.send('showNewEventForm', {
            start: start,
            end: end,
            startTime: startTime,
            endTime: endTime,
            editable: true
          });
        },
        eventClick: function eventClick(calEvent, jsEvent, view) {
          if (!calEvent.isWelcome) _this.send('showNewEventForm', _this.calEventToEvent(calEvent), calEvent);
        },
        eventDrop: function eventDrop(calEvent) {
          if (_this.onDrop) {
            _this.onDrop(_this.calEventToDBEvent(calEvent));
          }
        },
        eventResize: function eventResize(calEvent) {
          if (_this.onResize) {
            _this.onResize(_this.calEventToDBEvent(calEvent));
          }
        },
        eventAfterRender: function eventAfterRender(event, elem) {
          if (elem.find(".fc-content")[0]) {
            var containerHeight = elem.find(".fc-content")[0].clientHeight;
            var titleHeight = elem.find(".fc-title")[0].clientHeight;

            if (containerHeight < titleHeight) {
              elem.addClass('align-top');
            }
          }

          setTimeout(function () {
            Ember.set(_this, 'calendarLoaded', true);
          }, 500);
        }
      });
      setTimeout(function () {
        _this.addLoadMoreButtons();
      }, 1000);

      if (this.shareCalendar) {
        this.shareCalendar(this);
      }
    },
    addLoadMoreButtons: function addLoadMoreButtons() {
      var _this2 = this;

      if (!this.showMinDate) {
        this.$('.fc-time-grid-container').prepend('<div id="load-before" class="load-more">' + this.intl.t('word.show_more') + '<i class="material-icons">arrow_drop_up</i></div>');
        this.$('#load-before').click(function () {
          _this2.$('#load-before').hide();

          _this2.$('#calendar').fullCalendar('option', 'minTime', '00:00');

          Ember.set(_this2, "showMinDate", true);

          _this2.addLoadMoreButtons();
        });
      }

      if (!this.showMaxDate) {
        this.$('.fc-time-grid-container').append('<div id="load-after" class="load-more">' + this.intl.t('word.show_more') + '<i class="material-icons">arrow_drop_down</i></div>');
        this.$('#load-after').click(function () {
          _this2.$('#load-after').hide();

          _this2.$('#calendar').fullCalendar('option', 'maxTime', '24:00');

          Ember.set(_this2, "showMaxDate", true);

          _this2.addLoadMoreButtons();
        });
      }
    },
    calEventToEvent: function calEventToEvent(calEvent) {
      if (!calEvent.allDay && !calEvent.end) {
        calEvent.end = _moment.default.utc(calEvent.start).add('hours', 2);
      }

      return {
        title: calEvent.title,
        description: calEvent.description,
        category: calEvent.category,
        mediaUrl: calEvent.mediaUrl,
        place: calEvent.place,
        room: calEvent.room,
        start: calEvent.start.utc().format(this.dateFormat),
        end: calEvent.allDay ? calEvent.end ? calEvent.end.utc().add('days', -1).format(this.dateFormat) : calEvent.start.utc().format(this.dateFormat) : calEvent.end.utc().format(this.dateFormat),
        startTime: calEvent.allDay ? undefined : calEvent.start.utc().format('HH:mm'),
        endTime: calEvent.allDay ? undefined : calEvent.end.utc().format('HH:mm'),
        editable: calEvent.editable,
        participants: calEvent.participants
      };
    },
    calEventsFromDB: function calEventsFromDB() {
      var editable = this.displayMode != 'READ_ONLY';
      var calEvents = [];

      if (this.campaign && this.campaign.events) {
        var campaignEditable = editable && this.displayMode != 'ONE_TO_ONE_EDIT';
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.campaign.events[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var event = _step.value;
            var alreadyAdded = false;
            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
              for (var _iterator2 = calEvents[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                var calEvent = _step2.value;

                if (calEvent.id && calEvent.id == event.id) {
                  alreadyAdded = true;
                  break;
                }
              }
            } catch (err) {
              _didIteratorError2 = true;
              _iteratorError2 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                  _iterator2.return();
                }
              } finally {
                if (_didIteratorError2) {
                  throw _iteratorError2;
                }
              }
            }

            if (!alreadyAdded) {
              calEvents.push(this.DBEventToCalEvent(event, "event-campaign", campaignEditable));
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      if (this.oneToOneEvents) {
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = this.oneToOneEvents[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var _event = _step3.value;
            var _alreadyAdded = false;
            var _iteratorNormalCompletion4 = true;
            var _didIteratorError4 = false;
            var _iteratorError4 = undefined;

            try {
              for (var _iterator4 = calEvents[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                var _calEvent = _step4.value;

                if (_calEvent.id && _calEvent.id == _event.id) {
                  _alreadyAdded = true;
                  break;
                }
              }
            } catch (err) {
              _didIteratorError4 = true;
              _iteratorError4 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
                  _iterator4.return();
                }
              } finally {
                if (_didIteratorError4) {
                  throw _iteratorError4;
                }
              }
            }

            if (!_alreadyAdded) {
              calEvents.push(this.DBEventToCalEvent(_event, "event-one-to-one", editable && !_event.campaignId));
            }
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }
      }

      if (this.welcomeDate) {
        calEvents.push({
          title: this.intl.t("event.one_to_one.welcome_date"),
          start: (0, _moment.default)(this.welcomeDate),
          className: 'event-welcome',
          allDay: (0, _moment.default)(this.welcomeDate).get('hours') == 0,
          editable: false,
          durationEditable: false,
          isWelcome: true
        });
      }

      return calEvents;
    },
    DBEventToCalEvent: function DBEventToCalEvent(event, className, editable) {
      return {
        id: event.id,
        allDay: event.allDay,
        category: event.category,
        mediaUrl: event.mediaUrl,
        title: event.title,
        place: event.place,
        room: event.room,
        className: className,
        description: event.description,
        start: event.allDay ? _moment.default.utc(event.start).format('YYYY-MM-DD') : _moment.default.utc(event.start),
        end: event.allDay ? _moment.default.utc(event.end).add('days', 1).format('YYYY-MM-DD') : _moment.default.utc(event.end),
        editable: editable,
        durationEditable: editable,
        participants: event.users
      };
    },
    calEventToDBEvent: function calEventToDBEvent(calEvent) {
      if (!calEvent.allDay && !calEvent.end) {
        calEvent.end = _moment.default.utc(calEvent.start).add('hours', 2);
      }

      return {
        id: calEvent.id,
        title: calEvent.title,
        category: calEvent.category,
        mediaUrl: calEvent.mediaUrl,
        description: calEvent.description,
        place: calEvent.place,
        room: calEvent.room,
        start: calEvent.allDay ? _moment.default.utc(calEvent.start).toISOString() : _moment.default.utc(calEvent.start).toISOString(),
        end: calEvent.allDay ? calEvent.end ? _moment.default.utc(calEvent.end).add('days', -1).toISOString() : _moment.default.utc(calEvent.start).toISOString() : _moment.default.utc(calEvent.end).toISOString(),
        allDay: calEvent.allDay,
        users: calEvent.participants,
        participants: calEvent.participants,
        participantIds: calEvent.participantIds
      };
    },
    getDBEvents: function getDBEvents() {
      var calEvents = this.$('#calendar').fullCalendar("clientEvents");
      var events = [];

      if (calEvents) {
        var _iteratorNormalCompletion5 = true;
        var _didIteratorError5 = false;
        var _iteratorError5 = undefined;

        try {
          for (var _iterator5 = calEvents[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
            var calEvent = _step5.value;
            events.push(this.calEventToDBEvent(calEvent));
          }
        } catch (err) {
          _didIteratorError5 = true;
          _iteratorError5 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion5 && _iterator5.return != null) {
              _iterator5.return();
            }
          } finally {
            if (_didIteratorError5) {
              throw _iteratorError5;
            }
          }
        }
      }

      return events;
    },
    actions: {
      showNewEventForm: function showNewEventForm() {
        var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
          editable: true
        };
        var calEvent = arguments.length > 1 ? arguments[1] : undefined;
        var view = this.$('#calendar').fullCalendar('getView');

        if (!event.start && !event.end && view.name == 'agendaDay') {
          event.start = view.start.format(this.dateFormat);
          event.end = event.start;
        }

        if (event.startTime == 'ALL_DAY') {
          event.startTime = undefined;
        }

        if (event.endTime == 'ALL_DAY') {
          event.endTime = undefined;
        }

        if (calEvent) {
          event.id = calEvent._id;
        }

        if (this.campaign && !event.place) {
          event.place = this.campaign.place;
        }

        if (!event.participants) {
          event.participants = [];
        }

        var sessionUser = this.session.data.authenticated.user;

        if (!event.id && this.userService.isManager(sessionUser)) {
          event.participants.push({
            id: sessionUser.id,
            username: sessionUser.username
          });
        }

        Ember.set(this, "newEvent", event);
        Ember.set(this, "showNewEvent", true);
        Ember.set(this, "calEvent", calEvent);
      },
      addEvent: function addEvent() {
        var start = _moment.default.utc(this.newEvent.start, this.dateFormat).format("YYYY-MM-DD");

        var allDay = true;

        if (this.newEvent.startTime) {
          start += ' ' + this.newEvent.startTime;
          allDay = false;
        }

        var end = _moment.default.utc(this.newEvent.end, this.dateFormat).format("YYYY-MM-DD");

        if (this.newEvent.endTime) {
          end += ' ' + this.newEvent.endTime;
        } else {
          end = _moment.default.utc(this.newEvent.end, this.dateFormat).add('days', 1).format("YYYY-MM-DD");
        } // new event


        if (!this.calEvent) {
          var event = {
            allDay: allDay,
            title: this.newEvent.title,
            start: start,
            end: end,
            description: this.newEvent.description,
            category: this.newEvent.category,
            mediaUrl: this.newEvent.mediaUrl,
            place: this.newEvent.place,
            room: this.newEvent.room,
            className: this.displayMode == "ONE_TO_ONE_EDIT" ? "event-one-to-one" : "event-campaign",
            participants: this.newEvent.participants,
            participantIds: this.newEvent.participantIds,
            editable: true
          };

          if (this.onNewEvent) {
            this.onNewEvent(this.calEventToDBEvent(event));
          }

          this.$('#calendar').fullCalendar('renderEvent', event, true);
        } else {
          // update event
          Ember.set(this.calEvent, "title", this.newEvent.title);
          Ember.set(this.calEvent, "category", this.newEvent.category);
          Ember.set(this.calEvent, "description", this.newEvent.description);
          Ember.set(this.calEvent, "start", start);
          Ember.set(this.calEvent, "end", end);
          Ember.set(this.calEvent, "mediaUrl", this.newEvent.mediaUrl);
          Ember.set(this.calEvent, "place", this.newEvent.place);
          Ember.set(this.calEvent, "participants", this.newEvent.participants);
          Ember.set(this.calEvent, "participantIds", this.newEvent.participantIds);

          if (this.onUpdateEvent) {
            this.onUpdateEvent(this.calEventToDBEvent(this.calEvent));
          }

          this.$('#calendar').fullCalendar('updateEvent', this.calEvent);
          Ember.set(this, "calEvent", undefined);
        }

        Ember.set(this, "showNewEvent", false);
      },
      removeEvent: function removeEvent() {
        this.$('#calendar').fullCalendar("removeEvents", this.calEvent._id);

        if (this.onRemoveEvent) {
          this.onRemoveEvent(this.calEventToDBEvent(this.calEvent));
        }

        Ember.set(this, "calEvent", undefined);
        Ember.set(this, "showNewEvent", false);
      }
    },
    onGoogleEventsChanged: Ember.observer('googleEvents', 'calendarLoaded', function () {
      if (!this.calendarLoaded) return;
      this.$('#calendar').fullCalendar('removeEvents', function (event) {
        return "google-event" === event.className[0];
      });

      if (this.googleEvents) {
        var _iteratorNormalCompletion6 = true;
        var _didIteratorError6 = false;
        var _iteratorError6 = undefined;

        try {
          for (var _iterator6 = this.googleEvents[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
            var event = _step6.value;
            event.editable = false;
            event.className = 'google-event';
            event.start = event.start;
            event.end = event.end;
          }
        } catch (err) {
          _didIteratorError6 = true;
          _iteratorError6 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion6 && _iterator6.return != null) {
              _iterator6.return();
            }
          } finally {
            if (_didIteratorError6) {
              throw _iteratorError6;
            }
          }
        }

        this.$('#calendar').fullCalendar('renderEvents', this.googleEvents, true);
      }
    }),
    onAzureEventsChanged: Ember.observer('azureEvents', 'calendarLoaded', function () {
      if (!this.calendarLoaded) return;
      this.$('#calendar').fullCalendar('removeEvents', function (event) {
        return "azure-event" === event.className[0];
      });

      if (this.azureEvents) {
        var _iteratorNormalCompletion7 = true;
        var _didIteratorError7 = false;
        var _iteratorError7 = undefined;

        try {
          for (var _iterator7 = this.azureEvents[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
            var event = _step7.value;
            event.editable = false;
            event.className = 'azure-event';
            event.start = event.start;
            event.end = event.end;
          }
        } catch (err) {
          _didIteratorError7 = true;
          _iteratorError7 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion7 && _iterator7.return != null) {
              _iterator7.return();
            }
          } finally {
            if (_didIteratorError7) {
              throw _iteratorError7;
            }
          }
        }

        this.$('#calendar').fullCalendar('renderEvents', this.azureEvents, true);
      }
    })
  });

  _exports.default = _default;
});