define("teelt-frontend/components/input/file-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['file-input'],
    init: function init() {
      this._super.apply(this, arguments);

      var id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
      });
      Ember.set(this, 'id', id);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.initDropzone();

      if (this.withoutButton) {
        this.send('openFileSelector');
      }
    },
    initDropzone: function initDropzone() {
      var _this = this;

      var params = {
        url: 'https://www.teelt.io',
        uploadMultiple: false,
        maxFiles: 1,
        createImageThumbnails: false,
        addRemoveLinks: false,
        autoProcessQueue: false,
        clickable: true,
        addedfile: function addedfile(file) {
          if (!_this.label || _this.replaceLabel) {
            Ember.set(_this, 'label', file.name);
          }

          _this.onChanged(file);
        }
      };

      if (this.acceptedFiles) {
        params.acceptedFiles = this.acceptedFiles;
      }

      var myDropzone = new Dropzone(".dropzone-action-" + this.id, params);
      Ember.set(this, 'dropzone', myDropzone);
    },
    actions: {
      openFileSelector: function openFileSelector() {
        this.dropzone.hiddenFileInput.click();
      },
      cleanFile: function cleanFile() {
        this.dropzone.removeAllFiles();
        Ember.set(this, 'label', null);
        this.onChanged(null);
        Ember.set(this, 'progress', 0);
      }
    }
  });

  _exports.default = _default;
});