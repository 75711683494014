define("teelt-frontend/templates/components/template/easy-mode-recommendations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YddMHi9l",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"t\",[\"template.easy_mode.baseline\"],null],false],[0,\" \"],[7,\"span\"],[11,\"class\",\"recommendations-modal-link\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showRecommendationsModal\"]]],null],[27,\"not\",[[23,[\"showRecommendationsModal\"]]],null]]],[9],[1,[27,\"t\",[\"tempalte.easy_mode.link_label\"],null],false],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showRecommendationsModal\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"onClose\",\"translucentOverlay\",\"containerClass\",\"onClickOverlay\"],[[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showRecommendationsModal\"]]],null],false],null],true,\"select-form-roles-container\",[27,\"action\",[[22,0,[]],\"onClickOverlay\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"id\",\"easy-mode-template-recommendations-modal\"],[11,\"class\",\"modal-container\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"class\",\"btn-close  foreground-primary-color\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showRecommendationsModal\"]]],null],false]],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"close\"],[10],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"modal-content\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"g-window\"],[9],[0,\"\\n          \"],[7,\"img\"],[11,\"class\",\"explore-office-sample\"],[11,\"src\",\"/img/easy_mode/template_recommendations_modal.png\"],[11,\"alt\",\"template_recommendations\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/template/easy-mode-recommendations.hbs"
    }
  });

  _exports.default = _default;
});