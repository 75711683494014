define("teelt-frontend/components/utils/content-editable", ["exports", "teelt-frontend/utils/string-util"], function (_exports, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["content-editable"],
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'uuid', (0, _stringUtil.default)().generateUUID());
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var _this = this;

      setTimeout(function () {
        _this2.$('[contenteditable]').on('focus', function () {
          var $this = $(this);
          $this.data('before', $this.html());
        }).on('focusout', function () {
          var $this = $(this);

          if ($this.data('before') !== $this.html()) {
            var attribute = $this.attr('data-attribute');

            if (attribute && _this.model) {
              var text = $this.html().trim();
              Ember.set(_this.model, attribute, text);

              if (_this.onUpdate) {
                _this.onUpdate();
              }

              $this.html(text);
            }
          }
        });

        var div = document.getElementById(_this.uuid);

        if (_this.disableNewLines) {
          div.addEventListener('keydown', _this.preventNewLines);
        } else {
          div.addEventListener('keydown', _this.handleKeydown);
        }
      }, 200);
    },
    handleKeydown: function handleKeydown(e) {
      if (e.keyCode === 13 && e.shiftKey === true) {
        return true;
      } else if (e.keyCode === 13) {
        e.preventDefault();
        setTimeout(function () {
          e.target.blur();
        }, 0);
        return false;
      }

      return true;
    },
    preventNewLines: function preventNewLines(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        setTimeout(function () {
          e.target.blur();
        }, 0);
        return false;
      }

      return true;
    },
    actions: {
      activeContentEditable: function activeContentEditable() {
        this.$('[contenteditable]').focus();
      }
    }
  });

  _exports.default = _default;
});