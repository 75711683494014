define("teelt-frontend/helpers/crop-module-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cropModuleName = cropModuleName;
  _exports.default = void 0;

  function cropModuleName(params
  /*, hash*/
  ) {
    var croped = params;

    if (window.screen.width < 992) {
      if (params[0].length > 12) {
        croped = params[0].substring(0, 12);
      }
    } else if (window.screen.width >= 992) {
      if (params[0].length > 48) {
        croped = params[0].substring(0, 48);
      }
    }

    return croped;
  }

  var _default = Ember.Helper.helper(cropModuleName);

  _exports.default = _default;
});