define("teelt-frontend/components/settings/table/periods-custom-table-content", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    actions: {
      deletePeriod: function deletePeriod(response) {
        var _this = this;

        if (response) {
          var path = _environment.default.APP.API_HOST + '/periods/' + this.result.id;
          this.ajax.requestPromise(path, 'DELETE').then(function () {
            _this.eventBus.trigger('REFRESH_PERIODS');
          });
        }
      }
    }
  });

  _exports.default = _default;
});