define("teelt-frontend/models/form", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    formRef: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    accessToken: _emberData.default.attr('string'),
    fields: _emberData.default.attr(),
    task: _emberData.default.attr(),
    createdAt: _emberData.default.attr('date'),
    showFinalScore: _emberData.default.attr('boolean'),
    confirmationMessage: _emberData.default.attr('string'),
    response: _emberData.default.attr(),
    responseCount: _emberData.default.attr('number'),
    fieldCount: _emberData.default.attr('number'),
    users: _emberData.default.attr(),
    maxResponseNumber: _emberData.default.attr('number'),
    showToManager: _emberData.default.attr('boolean'),
    viewResultRoles: _emberData.default.attr(),
    audience: _emberData.default.attr(),
    genericForm: _emberData.default.attr(),
    scheduleSftpAutoExport: _emberData.default.attr('boolean'),
    allowSftpAutoExport: _emberData.default.attr('boolean'),
    scheduleTrigger: _emberData.default.attr(),
    scheduleTriggerUnit: _emberData.default.attr(),
    scheduleSftpAutoExportAt: _emberData.default.attr('date'),
    lastSftpExport: _emberData.default.attr('date'),
    updateScheduleSftpAutoExportAt: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});