define("teelt-frontend/routes/application", ["exports", "teelt-frontend/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    beforeModel: function beforeModel() {
      // Do not erase : Retrieve CSRF Token
      this.ajax.requestPromise(_environment.default.APP.API_HOST).then(function (result) {
        if (result == 'OK') {
          console.info("API online");
        }
      }); // lazy-load translations

      if (this.session.isAuthenticated && this.session.data.authenticated.user.lang) {
        return this.intl.setLocale([this.session.data.authenticated.user.lang, 'en', 'fr']);
      }

      var browserLocale = navigator.language || navigator.userLanguage;

      if (browserLocale) {
        var lang = browserLocale.split('-')[0];

        if (this.intl.locales.indexOf(lang) > 0) {
          return this.intl.setLocale([lang, 'en', 'fr']);
        }
      }

      return this.intl.setLocale(['en', 'fr']);
    },
    afterModel: function afterModel() {
      var _this = this;

      _moment.default.locale(this.intl.locale[0]);

      setTimeout(function () {
        _this.observeOpenedMenu();

        _this.checkTalentBoard();
      }, 500);
    },
    checkTalentBoard: function checkTalentBoard() {
      var talentBoardEl = document.getElementById('talent-board');
      if (!talentBoardEl) return;
      var impersonateEl = document.querySelector('.restore-impersonate');
      var switchRoleEl = document.querySelector('.current-role');
      if (impersonateEl) impersonateEl.classList.add('talent-board');
      if (switchRoleEl) switchRoleEl.classList.add('talent-board');
    },
    observeOpenedMenu: function observeOpenedMenu() {
      var menuEL = document.querySelector('.main-menu');

      if (!menuEL) {
        return;
      }

      var impersonateEl = document.querySelector('.restore-impersonate');
      var currentRoleEl = document.querySelector('.current-role');
      var classToAdd = 'menu-opened';

      if (menuEL.classList.contains('opened') && impersonateEl) {
        impersonateEl.classList.add(classToAdd);
      }

      if (menuEL.classList.contains('opened') && currentRoleEl) {
        currentRoleEl.classList.add(classToAdd);
      }

      var openedMenuObserver = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.attributeName === 'class') {
            if (menuEL.classList.contains('opened')) {
              if (currentRoleEl) {
                currentRoleEl.classList.add(classToAdd);
              }

              if (impersonateEl) {
                impersonateEl.classList.add(classToAdd);
              }
            } else {
              if (currentRoleEl) {
                currentRoleEl.classList.remove(classToAdd);
              }

              if (impersonateEl) {
                impersonateEl.classList.remove(classToAdd);
              }
            }
          }
        });
      });
      openedMenuObserver.observe(menuEL, {
        attributes: true
      });
      Ember.set(this, 'openedMenuObserver', openedMenuObserver);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (this.openedMenuObserver) {
        this.openedMenuObserver.disconnect();
      }
    }
  });

  _exports.default = _default;
});