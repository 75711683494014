define("teelt-frontend/components/activity/activity-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    actions: {
      openForm: function openForm() {
        var params = JSON.parse(this.activity.customParams);
        var formId = params.formId;
        var formRoute = params.formType == "ADMINISTRATIVE" ? "administrative" : "forms";
        this.router.transitionTo('board.talent.talent-detail.' + formRoute, this.talent.id).then(function (route) {
          route.set('currentModel.formId', formId);
        });
      }
    }
  });

  _exports.default = _default;
});