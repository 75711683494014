define("teelt-frontend/components/talents/talent-top-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['top-image-container'],
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.set(this, 'authenticatedUser', this.session.data.authenticated.user);
      this.changeTabFromRouteName();
    },
    changeTabFromRouteName: function changeTabFromRouteName() {
      var routeName = this.router.get('currentRouteName');
      var tmp = routeName.split('.');
      var currentRoute = tmp[1];

      if (routeName.indexOf("talent.integration.team") >= 0) {
        currentRoute = 'team';
      }

      Ember.set(this, 'currentRoute', currentRoute);
    },
    onRouteChanged: Ember.observer('router.currentRouteName', function () {
      this.changeTabFromRouteName();
    })
  });

  _exports.default = _default;
});