define("teelt-frontend/components/ihm/meet-people/select-people-animation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['select-people-animation'],
    talents: [],
    loading: true,
    init: function init() {
      this._super.apply(this, arguments);

      var talents = [{
        firstname: 'Justine',
        lastname: 'Monet',
        proEmail: 'J.monet@mail.fr',
        persoEmail: 'J.monet@mail.fr',
        phoneCode: '+33',
        phoneNumber: '602020202',
        profilePictureUrl: '/img/meetpeople/monet.jpg'
      }, {
        firstname: 'Jérôme',
        lastname: 'Durand',
        proEmail: 'J.durand@mail.fr',
        persoEmail: 'georgesC@mail.com',
        phoneCode: '+33',
        phoneNumber: '603030303',
        profilePictureUrl: '/img/meetpeople/durand.jpg'
      }, {
        firstname: 'Laura',
        lastname: 'Richard',
        proEmail: 'L.richard@mail.fr',
        persoEmail: 'L.richard@mail.fr',
        phoneCode: '+33',
        phoneNumber: '604040404',
        profilePictureUrl: '/img/meetpeople/richard.jpg'
      }, {
        firstname: 'Thierry',
        lastname: 'Johnson',
        proEmail: 'T.johnson@mail.fr',
        persoEmail: 'T.johnson@mail.fr',
        phoneCode: '+33',
        phoneNumber: '605050505',
        profilePictureUrl: '/img/meetpeople/johnson.jpg'
      }, {
        firstname: 'Christian',
        lastname: 'Dupont',
        proEmail: 'C.dupond@mail.fr',
        persoEmail: 'C.dupond@mail.fr',
        phoneCode: '+33',
        phoneNumber: '601010101',
        profilePictureUrl: '/img/meetpeople/dupont.jpg'
      }];
      Ember.set(this, 'talents', talents);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var swiperWrapper = this.element.querySelector('.swiper-wrapper');
      var slides = this.element.querySelectorAll('.swiper-slide');
      var slideCount = slides.length;
      var currentIndex = 0;
      setInterval(function () {
        currentIndex = (currentIndex + 1) % slideCount;
        swiperWrapper.style.transform = "translateX(-".concat(currentIndex * 100, "%)");
      }, 3000);
    }
  });

  _exports.default = _default;
});