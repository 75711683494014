define("teelt-frontend/routes/board/experience/experience-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return {
        templateId: params.template_id
      };
    },
    setForceTemplatePage: function setForceTemplatePage(page) {
      var _this = this;

      setTimeout(function () {
        Ember.set(_this.currentModel, 'forceTemplatePage', page);
      });
    }
  });

  _exports.default = _default;
});