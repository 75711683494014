define("teelt-frontend/services/get-background", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    getBackground: function getBackground(model, customizations) {
      if (customizations) {
        var logoUrl = customizations.logoUrl ? customizations.logoUrl : '/logo/blue-teelt-logo.png';
        Ember.set(model, 'backgroundUrl', customizations.backgroundUrl);
        Ember.set(model, 'logoUrl', logoUrl);
        Ember.set(model, 'backgroundVideoUrl', customizations.backgroundVideoUrl);

        var _e = document.getElementById('login-page');

        var _defaultBackgroundImage = "/img/login-bg.jpg";

        if (model.backgroundVideoUrl) {
          var video = document.getElementById('video');
          var src = document.createElement('source');
          src.setAttribute('src', model.backgroundVideoUrl);
          src.setAttribute('type', 'video/mp4');
          video.appendChild(src);
          video.muted = true;
          video.play();
        } else {
          if (model.backgroundUrl) {
            _e.style.backgroundImage = "url(" + model.backgroundUrl + ")";
          } else {
            _e.style.backgroundImage = "url(" + _defaultBackgroundImage + ")";
          }
        }
      } else {
        e.style.backgroundImage = "url(" + defaultBackgroundImage + ")";
      }
    }
  });

  _exports.default = _default;
});