define("teelt-frontend/components/ihm/explore-office/add-explore-office-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['add-explore-office-form'],
    customErrorService: Ember.inject.service('custom-error'),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    intl: Ember.inject.service(),
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    ajax: Ember.inject.service(),
    page: 1,
    init: function init() {
      this._super.apply(this, arguments);

      var allRoles = ["ROLE_MANAGER"];

      if (this.session.data.authenticated.user.company.activeAdministrative) {
        allRoles.push("ROLE_ADMINISTRATIVE");
      }

      if (this.session.data.authenticated.user.company.activeMRH) {
        allRoles.push("ROLE_MRH");
      }

      var roles = this.rolecustomService.getConsultRoles();

      if (roles) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = roles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var role = _step.value;
            allRoles.push("ROLE_CONSULT-" + role.id);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      Ember.set(this, 'allRoles', allRoles);

      if (this.exploreoffice && !this.exploreoffice.confirmationMessage) {
        Ember.set(this.exploreoffice, 'confirmationMessage', this.intl.t('explore_office.confirmation_message_default'));
      }
    },
    actions: {
      onClickOverlay: function onClickOverlay() {
        Ember.set(this, "showRolesModal", false);
      },
      addRemoveRole: function addRemoveRole(role) {
        var viewResultRoles = this.exploreoffice.viewResultRoles;

        if (!viewResultRoles) {
          viewResultRoles = [];
        }

        var index = viewResultRoles.indexOf(role);

        if (index >= 0) {
          viewResultRoles.splice(index, 1);
        } else {
          viewResultRoles.push(role);
        }

        Ember.set(this.exploreoffice, 'viewResultRoles', undefined);
        Ember.set(this.exploreoffice, 'viewResultRoles', viewResultRoles);
      },
      onManagerVisibilityChanged: function onManagerVisibilityChanged() {
        this.setManagerVisibility();
      }
    },
    setManagerVisibility: function setManagerVisibility() {
      Ember.set(this.exploreoffice, 'showToManager', document.getElementById("explore-office-show-to-manager").checked);
    }
  });

  _exports.default = _default;
});