define("teelt-frontend/components/utils/viewer/image-viewer", ["exports", "jquery", "teelt-frontend/utils/string-util", "teelt-frontend/config/environment", "viewerjs"], function (_exports, _jquery, _stringUtil, _environment, _viewerjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pdf-viewer'],
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    customErrorService: Ember.inject.service('custom-error'),
    userService: Ember.inject.service(),
    uiUtils: Ember.inject.service("ui-utils"),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    url: "",
    dataOrigin: "",
    page: 1,
    zoomFactor: 0.9,
    rotationAngle: 0,
    isFullScreen: false,
    viewer: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'uuid', (0, _stringUtil.default)().generateUUID());
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.fileUploadService.getObjectUrl(this.baseUrl).then(function (data) {
        var url = data.url + "&inline";
        Ember.set(_this, 'url', url);

        if (_this.isThumbnail) {
          var imageContainer = document.getElementById('image-thumbnail-' + _this.uuid);
          imageContainer.src = url;
        } else {
          _this.fileUploadService.getObjectUrl(_this.baseUrl).then(function (data) {
            var url = data.url + "&inline";
            Ember.set(_this, 'dataOrigin', url);

            _this.refreshImage();
          }).catch(function (err) {
            _this.customErrorService.handleAjaxError(err);
          });
        }
      }).catch(function (err) {
        _this.customErrorService.handleAjaxError(err);
      });
    },
    refreshImage: function refreshImage() {
      var imageContainer = document.getElementById('image-container-' + this.uuid);
      var oldCanvas = imageContainer.querySelector("canvas");

      if (oldCanvas) {
        imageContainer.removeChild(oldCanvas);
      } //let url = this.imageUrl;


      var imgElem = document.createElement("img");
      imgElem.id = "image-canvas" + this.uuid;
      imgElem.classList.add("image-canvas");
      imgElem.src = this.url;
      imgElem.setAttribute("data-original", this.dataOrigin);
      imageContainer.appendChild(imgElem);

      if (!imgElem) {
        return;
      }

      var viewer = new _viewerjs.default(imgElem, {
        url: 'data-original',
        backdrop: 'static',
        inline: true,
        toolbar: false,
        fullscreen: false,
        title: false,
        button: false,
        navbar: false,
        tooltip: true,
        transition: false,
        scalable: true,
        focus: true,
        move: function move(event) {
          var maxOffsetPercentage = 0.9;
          var viewerData = viewer.viewerData;
          var imageData = viewer.imageData;
          var maxOffsetHorizontal = viewerData.width * maxOffsetPercentage;
          var maxOffsetVertical = viewerData.height * maxOffsetPercentage;
          var detail = event.detail;
          var left = detail.x;
          var top = detail.y;
          var right = viewerData.width - (left + imageData.width);
          var bottom = viewerData.height - (top + imageData.height);

          if ( // Move left
          detail.x < detail.oldX && right > 0 && right > maxOffsetHorizontal || // Move right
          detail.x > detail.oldX && left > 0 && left > maxOffsetHorizontal // Move up
          || detail.y < detail.oldY && bottom > 0 && bottom > maxOffsetVertical // Move down
          || detail.y > detail.oldY && top > 0 && top > maxOffsetVertical) {
            event.preventDefault();
          }
        },
        zoomed: function zoomed(event) {
          var maxOffsetPercentage = 0.9;
          var detail = event.detail; // Zoom out

          if (detail.ratio < detail.oldRatio) {
            var viewerData = viewer.viewerData;
            var imageData = viewer.imageData;
            var maxOffsetHorizontal = viewerData.width * maxOffsetPercentage;
            var maxOffsetVertical = viewerData.height * maxOffsetPercentage;
            var left = imageData.x;
            var top = imageData.y;
            var right = viewerData.width - (left + imageData.width);
            var bottom = viewerData.height - (top + imageData.height);
            var x = 0;
            var y = 0;

            if (right > 0 && right > maxOffsetHorizontal) {
              x = maxOffsetHorizontal - right;
            }

            if (left > 0 && left > maxOffsetHorizontal) {
              x = maxOffsetHorizontal - left;
            }

            if (bottom > 0 && bottom > maxOffsetVertical) {
              y = bottom - maxOffsetVertical;
            }

            if (top > 0 && top > maxOffsetVertical) {
              y = top - maxOffsetVertical;
            } // Move the image into view if it is invisible


            if (x !== 0 || y !== 0) {
              viewer.move(x, y);
            }
          }
        }
      });
      Ember.set(this, 'viewer', viewer);
    },
    actions: {
      zoomIn: function zoomIn() {
        this.viewer.zoom(0.2);
      },
      zoomOut: function zoomOut() {
        this.viewer.zoom(-0.2);
      }
    }
  });

  _exports.default = _default;
});