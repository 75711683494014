define("teelt-frontend/components/talents/talent-documents-blocks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talent-documents-blocks'],
    showDocumentsToSend: true,
    eventBus: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this.eventBus.trigger('FORCE_RELOAD_TALENT');

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});