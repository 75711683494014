define("teelt-frontend/routes/board/admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    model: function model(params, transition) {
      var selectedMenu = 0;

      if (transition && transition.targetName === "board.admin.tool") {
        selectedMenu = 1;
      } else if (transition && transition.targetName === "board.admin.custom-field") {
        selectedMenu = 2;
      }

      return {
        selectedMenu: selectedMenu,
        sliderMenu: [{
          name: this.intl.t('word.companies'),
          route: "board.admin.company"
        }, {
          name: this.intl.t('word.tools_generic'),
          route: "board.admin.tool"
        }, {
          name: this.intl.t('word.custom_fields'),
          route: "board.admin.custom-field"
        }]
      };
    }
  });

  _exports.default = _default;
});