define("teelt-frontend/components/task/new-task/new-task-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['new-tasks-list'],
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    page: 0,
    limit: 16,
    canLoadMore: true,
    query: '',
    showRolesDropdown: false,
    activeRolesFilters: [],
    roles: [{
      name: "all",
      label: "talent.roles.all",
      filter: "all",
      value: true
    }, {
      name: "rh",
      label: "talent.roles.ROLE_RH.default",
      filter: "ROLE_RH",
      value: true
    }, {
      name: "manager",
      label: "talent.roles.ROLE_MANAGER.default",
      filter: "ROLE_MANAGER",
      value: true
    }, {
      name: "talent",
      label: "talent.roles.ROLE_TALENT.default",
      filter: "ROLE_TALENT",
      value: true
    }],
    filters: {
      all: true,
      ROLE_RH: true,
      ROLE_TALENT: true,
      ROLE_MANAGER: true
    },
    ihmType: 'meetPeople',
    init: function init() {
      this._super.apply(this, arguments);

      if (this.session.data.authenticated.user.company.activeOMRole && !this.roles.some(function (role) {
        return role.name === "officeManager";
      })) {
        this.roles.push({
          name: "officeManager",
          label: "talent.roles.ROLE_CUSTOM_OFFICE_MANAGER.default",
          filter: "ROLE_CUSTOM_OFFICE_MANAGER",
          value: true
        });
        Ember.set(this.filters, 'ROLE_CUSTOM_OFFICE_MANAGER', true);
      }

      if (this.session.data.authenticated.user.company.activeAdministrative && !this.roles.some(function (role) {
        return role.name === "administrative";
      })) {
        this.roles.push({
          name: "administrative",
          label: "talent.roles.ROLE_ADMINISTRATIVE.default",
          filter: "ROLE_ADMINISTRATIVE",
          value: true
        });
        Ember.set(this.filters, 'ROLE_ADMINISTRATIVE', true);
      }

      if (this.session.data.authenticated.user.company.activeBuddyRole && !this.roles.some(function (role) {
        return role.name === "buddy";
      })) {
        this.roles.push({
          name: "buddy",
          label: "emails.rules.recipient.buddy.default",
          filter: "BUDDY",
          value: true
        });
        Ember.set(this.filters, 'BUDDY', true);
      }

      if (this.session.data.authenticated.user.company.activeMeetPeople) Ember.set(this, 'showMeetPeopleBtn', true);
      if (this.session.data.authenticated.user.company.activeExploreOffice) Ember.set(this, 'showExploreOfficeBtn', true);
      if (this.session.data.authenticated.user.company.activeExploreOffice && !this.session.data.authenticated.user.company.activeMeetPeople) Ember.set(this, "ihmType", 'exploreOffice');
      this.loadPage();

      if (this.task && this.task.id) {
        Ember.set(this, 'taskId', this.task.id);
      }
    },
    loadPage: function loadPage() {
      var _this = this;

      var params = {};
      params.offset = this.page * this.limit;
      params.limit = this.limit;
      params.type = this.type === 'ihm' ? this.ihmType : this.type;

      if (this.query) {
        params.search = this.query;
      }

      Ember.set(this, 'canLoadMore', false);

      if (!this.filters.all) {
        var filters = [];

        for (var f in this.filters) {
          if (f !== 'all' && this.filters[f]) {
            filters.push(f);
          }
        }

        if (filters.length == 0) {
          this.cleanResults();
          return;
        }

        var filtersTmp = filters.join(',');
        params.filters = encodeURI(filtersTmp);
      }

      var addedTasksIds = [];
      this.template.periods.forEach(function (period) {
        period.tasks.forEach(function (addedTask) {
          addedTasksIds.push(addedTask.id);
        });
      });
      this.store.query('task', params).then(function (data) {
        if (data && data.length >= _this.limit) {
          Ember.set(_this, 'canLoadMore', true);
        }

        var tasks = data.toArray();
        tasks.forEach(function (task) {
          var added = false;
          addedTasksIds.forEach(function (addedTaskId) {
            if (addedTaskId == task.id) {
              added = true;
            }
          });
          task.set('added', added);
        });
        Ember.set(_this, "tasks", tasks);
      }).finally(function () {
        Ember.set(_this, 'isLoading', false);
      });
    },
    cleanResults: function cleanResults() {
      Ember.set(this, 'canLoadMore', true);
      Ember.set(this, 'offset', 0);
      Ember.set(this, 'page', 0);
      Ember.set(this, 'tasks', []);
    },
    actions: {
      onSearchKeyUp: function onSearchKeyUp() {
        var _this2 = this;

        if (this.keyUpAction) {
          Ember.run.cancel(this.keyUpAction);
        }

        var keyUpAction = Ember.run.later(function () {
          Ember.set(_this2, 'page', 0);

          _this2.loadPage();
        }, 300);
        Ember.set(this, 'keyUpAction', keyUpAction);
      },
      next: function next() {
        Ember.set(this, 'page', this.page + 1);
        this.loadPage();
      },
      prev: function prev() {
        Ember.set(this, 'page', this.page - 1);
        this.loadPage();
      },
      selectTask: function selectTask(task) {
        if (task.added) return;

        if (!task) {
          Ember.set(this, 'taskId', undefined);
          this.setTask(undefined);
        } else {
          Ember.set(this, 'taskId', task.id);
          this.setTask(task.toJSON({
            includeId: true
          }));
        }
      },
      toggleRoleFilter: function toggleRoleFilter(role) {
        var value = role.value ? false : true;
        Ember.set(role, 'value', value);
        this.send('filter', role.filter);
      },
      filter: function filter(_filter) {
        var enable = !this.filters[_filter];

        if (_filter == "all" && this.filters[_filter]) {
          for (var f in this.filters) {
            Ember.set(this.filters, f, false);
          }

          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = this.roles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var role = _step.value;
              Ember.set(role, 'value', false);
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        }

        if (enable && _filter == 'all') {
          for (var _f in this.filters) {
            Ember.set(this.filters, _f, true);
          }

          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = this.roles[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var _role = _step2.value;
              Ember.set(_role, 'value', true);
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }
        } else {
          Ember.set(this.filters, _filter, enable);
          Ember.set(this.roles.find(function (role) {
            return role.filter == _filter;
          }), 'value', enable);
          var allEnable = true;

          for (var _f2 in this.filters) {
            if (!this.filters[_f2] && _f2 !== 'all') {
              allEnable = false;
              break;
            }
          }

          Ember.set(this.filters, 'all', allEnable);
          Ember.set(this.roles.find(function (role) {
            return role.filter == 'all';
          }), 'value', allEnable);
        }

        this.cleanResults();
        this.loadPage();
      },
      newTask: function newTask() {
        Ember.set(this, 'newTask', {
          type: this.type,
          assign: this.type == 'sign' ? 'none' : 'talent'
        });
        Ember.set(this, 'showNewTask', true);
      },
      onTaskAdded: function onTaskAdded(task) {
        var taskModel = this.store.createRecord('task', task);
        this.send('selectTask', taskModel);
        this.eventBus.trigger('FORCE_NEXT_STEP');
      },
      changeIhmType: function changeIhmType(type) {
        Ember.set(this, "ihmType", type);
        this.loadPage();
      }
    }
  });

  _exports.default = _default;
});