define("teelt-frontend/authenticators/basic", ["exports", "ember-simple-auth/authenticators/base", "teelt-frontend/config/environment", "jquery", "moment"], function (_exports, _base, _environment, _jquery, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    ajax: Ember.inject.service('ajax'),
    store: Ember.inject.service('store'),
    userService: Ember.inject.service(),
    intl: Ember.inject.service(),
    intercom: Ember.inject.service(),
    customLabelsService: Ember.inject.service('custom-labels-service'),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    loginEndpoint: _environment.default.APP.API_HOST + "/users/me",
    logoutEndpoint: _environment.default.APP.API_HOST + "/users/me/logout",
    authenticate: function authenticate(username, password) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          url: _this.loginEndpoint,
          type: 'GET',
          beforeSend: function beforeSend(xhr) {
            if (username && password) {
              // We encode in UTF8 password
              xhr.setRequestHeader("Authorization", "Basic " + btoa(username.trim() + ":" + unescape(encodeURIComponent(password.trim()))));
            } else if (_this.ajax.xAuthToken) {
              xhr.setRequestHeader("x-auth-token", _this.ajax.xAuthToken);
            }

            xhr.setRequestHeader("GMT-Offset", (0, _moment.default)().utcOffset());
          },
          contentType: 'application/json;charset=utf-8',
          dataType: 'json',
          xhrFields: {
            withCredentials: true
          }
        }).then(function (response, status, xhr) {
          var username = response.username;

          _this.changeLang(response);

          _this.ajax.updateSecurityTokens(xhr);

          setTimeout(function () {
            _this.updateUserLastConnectionDate();

            _this.rolecustomService.reloadCustomRoles();

            _this.customLabelsService.loadCustomLabels();

            _this.updateTimezone();
          }, 2000); //this.intercom.initializeIntercom(response);

          Ember.run(function () {
            resolve({
              "mode": "basic",
              "username": username,
              "user": response
            });
          });
        }, function (xhr) {
          Ember.run(function () {
            reject({
              status: xhr.status === 401 ? 401 : 500
            });
          });
        });
      });
    },
    restore: function restore(data) {
      var _this2 = this;

      if (this.lastRestore) {
        var minutes = Math.round((new Date() - this.lastRestore) / 1000 * 60);

        if (minutes < 15) {
          return new Ember.RSVP.Promise(function (resolve, reject) {
            if (data.username) {
              setTimeout(function () {
                _this2.rolecustomService.reloadCustomRoles();

                _this2.customLabelsService.loadCustomLabels();

                _this2.updateTimezone();
              }, 2000);
              resolve(data);
            } else {
              reject();
            }
          });
        }
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (data.username) {
          _this2.ajax.reloadSecurityTokensFromStore();

          _this2.ajax.requestPromise(_this2.loginEndpoint).then(function (user) {
            Ember.set(data, 'user', user);
            Ember.set(_this2, 'lastRestore', new Date());

            _this2.changeLang(data.user);

            setTimeout(function () {
              _this2.rolecustomService.reloadCustomRoles();

              _this2.customLabelsService.loadCustomLabels();

              _this2.updateTimezone();
            }, 2000);
            resolve(data);
          }).catch(function (err) {
            _this2.invalidate();

            reject();
          });
        } else {
          reject();
        }
      });
    },
    invalidate: function invalidate() {
      // We clear all store
      this.store.unloadAll();
      return this.ajax.requestPromise(this.logoutEndpoint, 'POST');
    },
    changeLang: function changeLang(user) {
      if (user.lang) {
        this.intl.setLocale([user.lang, 'en', 'fr']);
      }
    },
    updateUserLastConnectionDate: function updateUserLastConnectionDate() {
      var path = _environment.default.APP.API_HOST + "/users/me/lastconnection";
      this.ajax.requestPromise(path, 'POST');
    },
    updateTimezone: function updateTimezone() {
      var options = {
        timeZoneName: 'long'
      };
      var timezone = Intl.DateTimeFormat(undefined, options).resolvedOptions().timeZone;
      var path = _environment.default.APP.API_HOST + "/users/me/timezone";
      var param = {
        timezone: timezone
      };
      this.ajax.requestWithBodyPromise(path, "PATCH", "application/json", JSON.stringify(param));
      console.log('TIMEZONE', timezone);
    }
  });

  _exports.default = _default;
});