define("teelt-frontend/components/utils/date-picker", ["exports", "flatpickr", "flatpickr/dist/l10n/fr", "flatpickr/dist/l10n/it", "flatpickr/dist/l10n/es", "flatpickr/dist/l10n/de", "flatpickr/dist/l10n/pt", "flatpickr/dist/l10n/pl"], function (_exports, _flatpickr, _fr, _it, _es, _de, _pt, _pl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['date-picker'],
    isUpdating: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.initFlatpickr();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.flatpickr) {
        this.flatpickr.destroy();
      }
    },
    initFlatpickr: function initFlatpickr() {
      var input = this.element.querySelector('input');
      var self = this;
      var locale = navigator.language.substring(0, 2).toLowerCase();
      this.flatpickr = (0, _flatpickr.default)(input, {
        enableTime: false,
        dateFormat: 'd/m/Y',
        locale: locale,
        monthSelectorType: 'dropdown',
        static: true,
        defaultDate: this.value ? moment(this.value, 'DD-MM-YYYY').toDate() : null,
        disableMobile: true,
        onChange: function onChange(selectedDates, dateStr, instance) {
          if (selectedDates.length > 0 && !self.isUpdating) {
            Ember.set(self, 'isUpdating', true);
            var day = selectedDates[0].getDate();
            var month = selectedDates[0].getMonth() + 1;
            var year = selectedDates[0].getFullYear();
            Ember.set(self, 'value', day + '-' + month + '-' + year);
            instance.setDate(selectedDates[0], false);
            Ember.run.next(function () {
              Ember.set(self, 'isUpdating', false);
            });
          }
        }
      });
    }
  });

  _exports.default = _default;
});