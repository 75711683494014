define("teelt-frontend/helpers/str-to-date-time", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.strToDateTime = strToDateTime;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function strToDateTime(_ref
  /*, hash*/
  ) {
    var _ref2 = _slicedToArray(_ref, 1),
        text = _ref2[0];

    if (!text) return null;
    var date;

    if ((0, _moment.default)(text, _moment.default.ISO_8601, true).isValid()) {
      date = (0, _moment.default)(text, _moment.default.ISO_8601, true);
    } else {
      date = (0, _moment.default)(text, 'DD/MM/YYYY HH:mm', true);

      if (!date.isValid()) {
        date = (0, _moment.default)(text, 'DD/MM/YYYY', true);
      }
    }

    if (!date.isValid()) {
      console.error("Invalid date format:", text);
      return null;
    }

    if (!text.includes(':')) {
      date = date.startOf('day'); // Ajoute 00:00
    }

    return date;
  }

  var _default = Ember.Helper.helper(strToDateTime);

  _exports.default = _default;
});