define("teelt-frontend/components/task/add-task-form", ["exports", "jquery", "teelt-frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['add-task-form'],
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    customErrorService: Ember.inject.service('custom-error'),
    staticlikeData: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    triggerPeriod: Ember.computed('period', function () {
      if (!this.period) return;
      return this.period.period.triggerDate;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'companyHasOfficeManager', this.session.data.authenticated.user.company.activeOMRole);
      Ember.set(this, 'companyHasBuddy', this.session.data.authenticated.user.company.activeBuddyRole);
      Ember.set(this, 'companyHasAdministrative', this.session.data.authenticated.user.company.activeAdministrative);
      Ember.set(this, 'companyHasAdminMRH', this.session.data.authenticated.user.company.activeMRH);
      Ember.set(this, 'currentUserCompany', this.session.data.authenticated.user.company);
      /*setTimeout(() => {
          M.textareaAutoResize(document.querySelector('.materialize-textarea'));
      }, 0);*/

      Ember.set(this, 'periods', [{
        name: "BEFORE"
      }, {
        name: "FIRST"
      }, {
        name: "AFTER"
      }]);
      var assigns = ['talent', 'manager', 'rh'];

      if (this.companyHasAdminMRH) {
        assigns.push('adminMRH');
      }

      if (this.companyHasOfficeManager) {
        assigns.push('officeManager');
      }

      if (this.companyHasBuddy) {
        assigns.push('buddy');
      }

      if (this.companyHasAdministrative) {
        assigns.push('administrative');
      }

      var consultRoles = this.rolecustomService.getConsultRoles();

      if (consultRoles) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = consultRoles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var role = _step.value;
            assigns.push("ROLE_CONSULT-" + role.id);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      Ember.set(this, 'assigns', assigns);
      setTimeout(function () {
        (0, _jquery.default)('#attribution-select').formSelect();
        (0, _jquery.default)('#period-select').formSelect();
      }, 0);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); //if (this.task.id && !this.edited) {
      //this.element.querySelector('input').disabled = true;
      //}


      if (this.task.type == 'notification') {
        Ember.set(this.task, 'notify', true);
      }

      if (this.task.type === "sign") {
        Ember.set(this, "isSignTask", true);
      }
    },
    actions: {
      setAssign: function setAssign(value) {
        Ember.set(this.task, "assign", value);
      },
      onAssignChanged: function onAssignChanged(value) {
        Ember.set(this.task, 'assign', value);
      },
      mandatoryChanged: function mandatoryChanged(value) {
        Ember.set(this.templatePeriodTasks, 'mandatory', value.target.checked);
      },
      planificationChanged: function planificationChanged(value) {
        Ember.set(this.task, 'planning', value.target.checked);

        if (!this.task.planning) {
          Ember.set(this.templatePeriodTasks, 'endOffset', null);
          Ember.set(this.templatePeriodTasks, 'endOffsetUnit', null);
          Ember.set(this.templatePeriodTasks, 'endOffsetDirection', null);
          Ember.set(this.templatePeriodTasks, 'startOffset', null);
          Ember.set(this.templatePeriodTasks, 'startOffsetUnit', null);
          Ember.set(this.templatePeriodTasks, 'startOffsetDirection', null);
          Ember.set(this.templatePeriodTasks, 'showLimitOffset', false);
          Ember.set(this.templatePeriodTasks, 'triggerInitiator', null);
        }
      },
      notificationChanged: function notificationChanged(value) {
        Ember.set(this.task, 'notify', value.target.checked);

        if (!this.task.notify) {
          Ember.set(this.task, 'notification', undefined);
        }
      }
    }
  });

  _exports.default = _default;
});