define("teelt-frontend/components/content/conditions-popup", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    sendNewConditions: function sendNewConditions(conditions) {
      var _this = this;

      var path = _environment.default.APP.API_HOST + '/contents/' + this.content.id + '/conditions';
      this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(conditions)).then(function () {
        Ember.set(_this, 'showContentConditionsModal', false);
        var conditionsObject = {};

        if (_this.content.conditions) {
          conditionsObject = JSON.parse(_this.content.conditions);
        }

        for (var key in conditions) {
          conditionsObject[key] = conditions[key];
        }

        Ember.set(_this.content, 'conditions', JSON.stringify(conditionsObject));
      });
    },
    actions: {
      setConfidential: function setConfidential(bool) {
        this.sendNewConditions({
          afterTalentArrival: bool
        });
      }
    }
  });

  _exports.default = _default;
});