define("teelt-frontend/templates/components/icon/close-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y+GRnVLd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"width\",\"12\"],[11,\"height\",\"12\"],[11,\"viewBox\",\"0 0 9 9\"],[11,\"fill\",\"none\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[9],[0,\"\\n  \"],[7,\"path\"],[11,\"d\",\"M8.875 1.00625L7.99375 0.125L4.5 3.61875L1.00625 0.125L0.125 1.00625L3.61875 4.5L0.125 7.99375L1.00625 8.875L4.5 5.38125L7.99375 8.875L8.875 7.99375L5.38125 4.5L8.875 1.00625Z\"],[11,\"fill\",\"#E74C3C\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/icon/close-svg.hbs"
    }
  });

  _exports.default = _default;
});