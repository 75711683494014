define("teelt-frontend/routes/board/talent/talent-detail", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    queryParams: {
      formId: '',
      templateId: '',
      campaignId: '',
      meetPeopleId: ''
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.eventBus.on('REFRESH_ROUTE', this, 'onRefreshRoute');
    },
    onRefreshRoute: function onRefreshRoute() {
      var _this = this;

      var talent = this.currentModel.talent;
      Ember.set(this.currentModel, 'talent', undefined);
      setTimeout(function () {
        Ember.set(_this.currentModel, 'talent', talent);
      });
    },
    destroy: function destroy() {
      this.eventBus.off('REFRESH_ROUTE', this, 'onRefreshRoute');

      this._super.apply(this, arguments);
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.set('formId', undefined);
        controller.set('meetPeopleId', undefined);
      }
    },
    model: function model(params) {
      var _this2 = this;

      return Ember.RSVP.hash({
        talentId: params.talent_id,
        talent: this.store.findRecord("user", params.talent_id, {
          reload: true,
          include: 'fullTemplate,referentTasks,customFields,events'
        }),
        companyCustomFields: this.ajax.requestPromise(_environment.default.APP.API_HOST + '/custom-fields', 'GET'),
        formId: params.formId,
        templateId: params.templateId,
        campaignId: params.campaignId,
        meetPeopleId: params.meetPeopleId
      }).then(function (model) {
        Ember.set(model.talent, "company.customFields", model.companyCustomFields);
        return model;
      }).catch(function () {
        _this2.transitionTo('index');
      });
    },
    actions: {
      modify: function modify() {
        this.transitionTo('board.talent.talent-edit', this.currentModel.talentId);
      },
      back: function back() {
        if (this.currentModel.templateId) {
          this.router.transitionTo('board.experience.experience-edit', this.currentModel.templateId).then(function (route) {
            route.setForceTemplatePage('talents');
          });
        } else if (this.currentModel.campaignId) {
          this.router.transitionTo('board.event.campaign-assign', this.currentModel.campaignId);
        } else {
          this.transitionTo('board.talents');
        }
      }
    }
  });

  _exports.default = _default;
});