define("teelt-frontend/templates/components/utils/progress-bar-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NmW3iEVk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"progress-value foreground-primary-color\"],[9],[1,[21,\"value\"],false],[0,\"%\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"progress-block background-primary-verylight-color\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"inner-bar outer floating background-primary-color\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/progress-bar-1.hbs"
    }
  });

  _exports.default = _default;
});