define("teelt-frontend/routes/board/talent/talent-by-period", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    queryParams: {
      dateFilter: 'today',
      startDate: '',
      endDate: '',
      targetDateField: '',
      label: '',
      filterType: '',
      templateId: '',
      taskId: '',
      taskName: ''
    },
    model: function model(param) {
      Ember.set(this, 'dateFilter', param.dateFilter);
      Ember.set(this, 'startDate', param.startDate);
      Ember.set(this, 'endDate', param.endDate);
      Ember.set(this, 'targetDateField', param.targetDateField);
      Ember.set(this, 'label', param.label);
      Ember.set(this, 'filterType', param.filterType);
      Ember.set(this, 'templateId', param.templateId);
      Ember.set(this, 'taskId', param.taskId);
      Ember.set(this, 'taskName', param.taskName);
    }
  });

  _exports.default = _default;
});