define("teelt-frontend/routes/board/notification/notification-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        notificationId: params.notification_id,
        notification: this.store.findRecord('notification', params.notification_id, {
          reload: true
        })
      });
    },
    actions: {
      close: function close() {
        if (this.currentModel.notification.type == 'CUSTOM') {
          this.transitionTo('board.emails.notifications-list');
        } else {
          this.transitionTo('board.emails.notifications-system-list');
        }
      }
    }
  });

  _exports.default = _default;
});