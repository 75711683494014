define("teelt-frontend/components/audience/audience-new", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['audience-new'],
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    allTalentsNbs: 0,
    talentsNb: 0,
    step: 1,
    countUrl: _environment.default.APP.API_HOST + "/users/count",
    offset: 0,
    limit: 10,
    search: '',
    canLoadMore: false,
    order: undefined,
    modelName: 'user',
    modelFilter: {
      includes: 'position'
    },
    customKeys: [{
      key: 'photo',
      mandatory: true,
      selected: true
    }, {
      key: 'firstname',
      selected: true
    }, {
      key: 'lastname',
      selected: true
    }, {
      key: 'position',
      selected: true
    }, {
      key: 'welcomeDate',
      selected: true
    }, {
      key: 'talent_progress',
      selected: true
    }, {
      key: 'tasks',
      selected: true
    }, {
      key: 'team',
      selected: false
    }, {
      key: 'template',
      selected: false
    }, {
      key: 'role',
      selected: false
    }],
    complexFilters: [],
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.audience) {
        var audience = this.store.createRecord('audience');
        Ember.set(this, 'audience', audience);
      } else {
        Ember.set(this, 'audience', this.audience.toJSON({
          includeId: true
        }));
        var filters = JSON.parse(this.audience.filters);
        Ember.set(this, 'complexFilters', filters.complexFilters);
        Ember.set(this, 'query', filters.query);
      }
    },
    refreshTalentNb: function refreshTalentNb() {
      if (this.selecteds && this.selecteds.length) {
        Ember.set(this, 'talentsNb', this.selecteds.length);
      } else {
        Ember.set(this, 'talentsNb', this.allTalentsNbs);
      }
    },
    save: function save() {
      var _this = this;

      if (this.audience.id) {
        var audience = this.store.peekRecord('audience', this.audience.id);
        Ember.set(audience, 'name', this.audience.name);
        Ember.set(audience, 'description', this.audience.description);
        Ember.set(audience, 'filters', this.audience.filters);
        audience.save().then(function () {
          _this.router.transitionTo('board.audiences');
        });
      } else {
        this.audience.save().then(function () {
          _this.router.transitionTo('board.audiences');
        });
      }
    },
    actions: {
      onCountChanged: function onCountChanged(count) {
        Ember.set(this, 'allTalentsNbs', count);
        this.refreshTalentNb();
      },
      onSelectionChanged: function onSelectionChanged(params) {
        Ember.set(this.audience, 'filters', JSON.stringify(params));
        this.refreshTalentNb();
      },
      previous: function previous() {
        if (this.step <= 1) {
          this.router.transitionTo('board.audiences');
        } else {
          Ember.set(this, 'step', this.step - 1);
        }
      },
      next: function next() {
        if (this.step >= 2) {
          this.save();
          return;
        }

        Ember.set(this, 'step', this.step + 1);
      }
    }
  });

  _exports.default = _default;
});