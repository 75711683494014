define("teelt-frontend/components/ai/new-module-by-ai/new-module-by-ai-content", ["exports", "teelt-frontend/config/environment", "teelt-frontend/components/ai/new-module-by-ai"], function (_exports, _environment, _newModuleByAi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _newModuleByAi.default.extend({
    classNames: ['new-module-by-ai-content'],
    moduleType: 'content',
    intl: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    initalized: false,
    onShowAIChanged: Ember.observer('showAI', function () {
      var _this = this;

      if (this.showAI && !this.initalized) {
        setTimeout(function () {
          _this.createIAMessage(_this.intl.t('ai.module.content.init'));
        });
        Ember.set(this, 'initalized', true);
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    onAIContent: function onAIContent(content) {
      var msg = "";
      var module = JSON.parse(content);
      Ember.set(this.module, 'title', module.title);
      Ember.set(this.module, 'fullValue', module.fullValue);

      if (module.aiMessage) {
        msg = module.aiMessage.replaceAll('JSON', 'Module');
      }

      this.eventBus.trigger('AI_REFRESH_FULLVALUE');
      return msg;
    }
  });

  _exports.default = _default;
});