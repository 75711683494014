define("teelt-frontend/components/dashboard/satisfaction-rate", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['satisfaction-rate'],
    ajax: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var path = _environment.default.APP.API_HOST + '/forms/satisfaction-rate';
      this.ajax.requestPromise(path, 'GET').then(function (satisfactionRate) {
        Ember.set(_this, 'satisfactionRate', satisfactionRate);
        Ember.set(_this, 'emojis', _this.getEmojis(satisfactionRate));
      });
    },
    getEmojis: function getEmojis(satisfactionRate) {
      var path = '/img/dashboard/';
      var emojis = [{
        'path': path + 'rate-0-20.png',
        'selected': false
      }, {
        'path': path + 'rate-21-40.png',
        'selected': false
      }, {
        'path': path + 'rate-41-60.png',
        'selected': false
      }, {
        'path': path + 'rate-61-80.png',
        'selected': false
      }, {
        'path': path + 'rate-81-100.png',
        'selected': false
      }];

      if (satisfactionRate === null || satisfactionRate === undefined || satisfactionRate === '') {
        return emojis;
      }

      return this.setSelectedEmoji(emojis, satisfactionRate);
    },
    setSelectedEmoji: function setSelectedEmoji(emojis, satisfactionRate) {
      if (satisfactionRate <= 20) {
        emojis[0].selected = true;
        return emojis;
      }

      if (satisfactionRate <= 40) {
        emojis[1].selected = true;
        return emojis;
      }

      if (satisfactionRate <= 60) {
        emojis[2].selected = true;
        return emojis;
      }

      if (satisfactionRate <= 80) {
        emojis[3].selected = true;
        return emojis;
      }

      emojis[4].selected = true;
      return emojis;
    }
  });

  _exports.default = _default;
});