define("teelt-frontend/templates/components/utils/intro-text-talent-ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kZ6/tqLb",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"text\"]]],null,{\"statements\":[[0,\"    \"],[7,\"pre\"],[9],[1,[27,\"xss-protect\",[[23,[\"text\"]]],null],true],[10],[0,\"\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/intro-text-talent-ui.hbs"
    }
  });

  _exports.default = _default;
});