define("teelt-frontend/components/ihm/meet-people/add-meet-people-image-form", ["exports", "teelt-frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['add-meet-people-image-form'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    notify: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    uiUtils: Ember.inject.service("ui-utils"),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    splittedUrls: [],
    blockSave: true,
    selectedMeetPeopleId: null,
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.ajax.requestPromise(_environment.default.APP.API_HOST + '/users/meetPeoples', 'GET').then(function (response) {
        Ember.set(_this, 'meetPeoples', response);
        setTimeout(function () {
          _this.$('#meet-people-select').formSelect();

          if (_this.meetPeople) {
            _this.send('onMeetPeopleChange', _this.meetPeople.id);
          } else {
            _this.send('onMeetPeopleChange', _this.meetPeoples[0].id);
          }
        }, 300);
      });
    },
    initSlideshow: function initSlideshow() {
      var splittedUrls = this.splittedUrls;
      var urls = splittedUrls.join(";");

      if (urls !== "") {
        Ember.set(this, "splittedUrls", urls.split(";"));
      } else {
        Ember.set(this, "splittedUrls", []);
      }
    },
    actions: {
      onMeetPeopleChange: function onMeetPeopleChange(value) {
        Ember.set(this, 'selectedMeetPeopleId', value);
      },
      onClickOverlay: function onClickOverlay() {},
      onSlideshowImagesAdded: function onSlideshowImagesAdded() {
        var _this2 = this;

        var files = (0, _jquery.default)('#slideshow-file-input .files-container')[0].files;
        if (!files) return;
        var splittedUrls = this.splittedUrls;
        var filesMaxIndex = files.length - 1;

        for (var i = 0; i <= filesMaxIndex; i++) {
          var file = (0, _jquery.default)('#slideshow-file-input .files-container')[0].files[i];
          if (!file) return;

          if (file.type !== 'image/gif' && file.type !== 'image/png' && file.type !== 'image/jpeg') {
            this.notify.alert(this.intl.t('meetpeople.alerts.bad_format'), {
              closeAfter: 5000
            });
            (0, _jquery.default)('#slideshow-file-input .files-container')[0].value = "";
            return;
          }

          if (file.size >= 10 * 1024 * 1024) {
            this.notify.alert(this.intl.t('meetpeople.alerts.size_overflow', {
              size: 10
            }), {
              closeAfter: 5000
            });
            (0, _jquery.default)('#slideshow-file-input .files-container')[0].value = "";
            return;
          }

          Ember.set(this, 'blockSave', true);
          this.fileUploadService.signAndUpload(file, false, function (e) {
            return _this2.onMediaProgress(e);
          }).then(function (fileUrl) {
            var urls = splittedUrls;
            urls.push(fileUrl);
            Ember.set(_this2, "splittedUrls", urls);
          }).finally(function () {
            Ember.set(_this2, 'blockSave', false);
            (0, _jquery.default)('#slideshow-file-input .files-container')[0].value = "";
            Ember.set(_this2, 'mediaProgress', 0);

            _this2.initSlideshow();
          });
        }
      },
      onSlideshowImageRemoved: function onSlideshowImageRemoved(imageUrl) {
        var urlsArray = this.splittedUrls;
        var currentFullValue = "";
        urlsArray = urlsArray.filter(function (item) {
          return item !== imageUrl;
        });
        urlsArray.forEach(function (singleUrl) {
          if (currentFullValue === "") {
            currentFullValue += singleUrl;
          } else {
            currentFullValue += ";" + singleUrl;
          }
        });
        Ember.set(this, "splittedUrls", urlsArray);
      },
      saveSlideshow: function saveSlideshow() {
        var _this3 = this;

        var meetPeopleImages = [];
        this.splittedUrls.forEach(function (url) {
          meetPeopleImages.push({
            fileUrl: url
          });
        });
        var userMeetPeople = {
          meetPeople: this.meetPeoples.find(function (mp) {
            return mp.id == _this3.selectedMeetPeopleId;
          }),
          meetPeopleImages: meetPeopleImages
        };
        this.ajax.requestWithBodyPromise(_environment.default.APP.API_HOST + '/meetpeoples/' + this.selectedMeetPeopleId + '/gallery/' + this.session.data.authenticated.user.id, 'POST', 'application/json', JSON.stringify(userMeetPeople)).then(function () {
          Ember.set(_this3, 'blockSave', true);
          Ember.set(_this3, 'splittedUrls', []);
          if (_this3.onSaveSlideshow) _this3.onSaveSlideshow();
        });
      },
      takePicture: function takePicture(fileUrl) {
        var splittedUrls = this.splittedUrls;
        Ember.set(this, "splittedUrls", null);
        splittedUrls.push(fileUrl);
        Ember.set(this, "splittedUrls", splittedUrls);
        Ember.set(this, 'blockSave', false);
      }
    },
    onMediaProgress: function onMediaProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'mediaProgress', progress);
      }
    }
  });

  _exports.default = _default;
});