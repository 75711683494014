define("teelt-frontend/components/ihm/add-ihm-form", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['add-ihm-form'],
    customErrorService: Ember.inject.service('custom-error'),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    intl: Ember.inject.service(),
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    ajax: Ember.inject.service(),
    page: null,
    maxPage: 2,
    audienceIds: null,
    participantIds: null,
    allIds: [],
    allSelected: false,
    audienceUserCount: 0,
    type: 'meet_people',
    init: function init() {
      this._super.apply(this, arguments);

      if (this.task && this.task.meetPeople || this.meetPeople) {
        Ember.set(this, 'page', 2);
        Ember.set(this, 'type', 'meet_people');
      }

      if (this.task && this.task.exploreOffice || this.exploreOffice) {
        Ember.set(this, 'page', 2);
        Ember.set(this, 'type', 'explore_office');
      }

      if (!this.session.data.authenticated.user.company.activeMeetPeople && this.session.data.authenticated.user.company.activeExploreOffice) {
        Ember.set(this, 'type', 'explore_office');
      }

      if (this.page === null) {
        Ember.set(this, 'page', 1);
      }

      Ember.set(this, 'ariane', [this.page]);

      if (!this.ihm) {
        var ihm = this.store.createRecord('ihm', {
          type: 'ihm'
        });
        Ember.set(this, 'ihm', ihm);
      }

      if (!this.meetpeople && this.session.data.authenticated.user.company.activeMeetPeople) {
        var meetpeople = this.store.createRecord('meetpeople', {
          type: 'meet_people'
        });
        Ember.set(this, 'meetpeople', meetpeople);
      } else if (this.meetpeople && this.meetpeople.countAudienceUsers) {
        Ember.set(this, 'audienceUserCount', this.meetpeople.countAudienceUsers);
      }

      if (!this.exploreoffice && this.session.data.authenticated.user.company.activeExploreOffice) {
        var exploreoffice = this.store.createRecord('exploreoffice', {
          type: 'explore_office',
          fields: [{
            type: 'none',
            inputErrors: {}
          }]
        });
        Ember.set(this, 'exploreoffice', exploreoffice);
      }
    },
    onPageChanged: Ember.observer('page', function () {
      if (this.page === 1) {
        this.didInsertPage1();
      }
    }),
    onStepChanged: Ember.observer('step', function () {
      if (this.step == 1) {
        this.$('.step1').show(400);
        this.$('.step2').hide(400);
        this.$('.step3').hide(400);
        this.$('.step4').hide(400);
      } else if (this.step == 2) {
        this.$('.step2').show(400);
        this.$('.step1').hide(400);
        this.$('.step3').hide(400);
        this.$('.step4').hide(400);
      } else if (this.step == 3) {
        this.$('.step3').show(400);
        this.$('.step1').hide(400);
        this.$('.step2').hide(400);
        this.$('.step4').hide(400);
      } else if (this.step == 4) {
        this.$('.step4').show(400);
        this.$('.step1').hide(400);
        this.$('.step2').hide(400);
        this.$('.step3').hide(400);
      }
    }),
    didInsertElement: function didInsertElement() {
      this.didInsertPage1();
      var textareas = document.querySelectorAll('.materialize-textarea');

      if (textareas && textareas.length > 0) {
        textareas.forEach(function (t) {
          M.textareaAutoResize(t);
        });
      }
    },
    didInsertPage1: function didInsertPage1() {
      var _this = this;

      setTimeout(function () {
        _this.$('.select').formSelect();
      });
    },
    isEnoughParticipants: function isEnoughParticipants(userCount) {
      Ember.set(this, 'notEnoughParticipantsError', false);

      if (userCount < this.meetpeople.nbParticipants) {
        Ember.set(this, 'notEnoughParticipantsError', true);
        return false;
      } else {
        Ember.set(this, 'notEnoughParticipantsError', false);
        return true;
      }
    },
    actions: {
      checkNbParticipants: function checkNbParticipants() {
        this.meetpeople.nbParticipants > 5 ? Ember.set(this, 'meetpeople.nbParticipants', 5) : null;
      },
      changeType: function changeType(type, event) {
        if (event && event.target.closest('.explore-office-sample-link')) {
          return;
        }

        Ember.set(this, 'type', type);
      },
      initSelectParticipantSource: function initSelectParticipantSource(source) {
        Ember.set(this, 'showSourceModal', source);
      },
      validateAudienceSelection: function validateAudienceSelection(audienceIds, userCountList) {
        Ember.set(this, 'notEnoughParticipantsError', false);
        var userCount = userCountList.reduce(function (a, b) {
          return a + b;
        }, 0);
        Ember.set(this, 'audienceUserCount', userCount);
        this.isEnoughParticipants(userCount);
        Ember.set(this, 'showSourceModal', false);
        Ember.set(this, 'participantIds', null);
        Ember.set(this.meetpeople, 'participantIds', null);
        Ember.set(this.meetpeople, 'allUsersSelected', false);
        Ember.set(this.meetpeople, 'audienceIds', audienceIds);
      },
      validateParticipantSelection: function validateParticipantSelection() {
        Ember.set(this, 'notEnoughParticipantsError', false);
        Ember.set(this, 'showSourceModal', false);
        Ember.set(this, 'audienceIds', null);
        Ember.set(this.meetpeople, 'audienceIds', null); //set(this.meetpeople, 'participantIds', this.allIds);
        //set(this.meetpeople, 'allUsersSelected', this.allSelected);

        if (this.allSelected) {
          Ember.set(this, 'allIds', []);
          Ember.set(this.meetpeople, 'participantIds', null);
          Ember.set(this.meetpeople, 'allUsersSelected', true);
        } else {
          Ember.set(this.meetpeople, 'allUsersSelected', false);
          Ember.set(this.meetpeople, 'participantIds', this.allIds);
          this.isEnoughParticipants(this.allIds.length);
        }
      },
      initSelectParticipants: function initSelectParticipants() {
        Ember.set(this, 'showSourceModal', true);
      },
      onTalentsSelected: function onTalentsSelected(params) {
        Ember.set(this, 'allIds', params.selecteds);
        Ember.set(this, 'allSelected', params.selectAll);
      },
      selectNotification: function selectNotification(notification) {
        Ember.set(this.meetpeople, 'notificationId', notification.id);
        Ember.set(this, 'canSave', true);
      },
      onClickOverlay: function onClickOverlay() {},
      selectRoles: function selectRoles() {},
      next: function next() {
        var _this2 = this;

        if (this.page == 1 && !this.validatePageOne()) return;

        if (this.type == "meet_people") {
          if (this.page == 2 && !this.validateMeetPeoplePageTwo()) return;
          if (this.page == 3 && !this.validateMeetPeoplePageThree()) return;
        }

        if (this.type == "explore_office") {
          if (this.page == 2 && !this.validateExploreOfficePageTwo()) return;
          if (this.page == 3 && !this.validateExploreOfficePageThree()) return;
        }

        if (this.page == 4 && this.canSave) {
          this.send('save');
          return;
        }

        Ember.set(this, 'page', this.page + 1);
        var ariane = this.ariane;
        ariane.push(this.page);
        Ember.set(this, 'ariane', undefined);
        setTimeout(function () {
          Ember.set(_this2, 'ariane', ariane);
        });
      },
      previous: function previous() {
        var _this3 = this;

        if (this.page == 1) {
          this.onPrevious();
          return;
        }

        Ember.set(this, 'page', this.page - 1);

        if (this.page != 2) {
          if (!document.getElementById('g-window').classList.contains('g-window')) {
            document.getElementById('g-window').classList.add('g-window');
          }
        }

        var ariane = this.ariane;
        ariane.pop();

        if (ariane.length > 0) {
          ariane.pop();
        }

        ariane.push(this.page);
        Ember.set(this, 'ariane', undefined);
        setTimeout(function () {
          Ember.set(_this3, 'ariane', ariane);
        });
      },
      navigateToPage: function navigateToPage(page) {
        Ember.set(this, 'page', page);
      },
      onTypeChange: function onTypeChange(type) {},
      save: function save() {
        var _this4 = this;

        if (!this.validatePageOne()) return;

        if (this.type == "meet_people") {
          if (!this.validateMeetPeoplePageTwo()) return;
          if (!this.validateMeetPeoplePageThree()) return;

          if (!this.meetpeople.notificationId) {
            Ember.set(this, 'notificationError', true);
            return;
          }

          if (this.parentView && this.parentView.task) Ember.set(this.parentView.task, 'type', 'meetPeople');
          var method = this.meetpeople.id ? "PATCH" : "POST";
          this.ajax.requestWithBodyPromise(_environment.default.APP.API_HOST + "/meetpeoples", method, 'application/json', JSON.stringify(this.meetpeople.toJSON({
            includeId: true
          }))).then(function (res) {
            if (_this4.onFinish) {
              _this4.onFinish(res);
            }
          });
        }

        if (this.type == "explore_office") {
          if (!this.validateExploreOfficePageTwo()) return;
          if (!this.validateExploreOfficePageThree()) return;
          if (this.parentView && this.parentView.task) Ember.set(this.parentView.task, 'type', 'exploreOffice');

          var _method = this.exploreoffice.id ? "PATCH" : "POST";

          this.ajax.requestWithBodyPromise(_environment.default.APP.API_HOST + '/exploreoffices', _method, 'application/json', JSON.stringify(this.exploreoffice.toJSON({
            includeId: true
          }))).then(function (res) {
            if (_this4.onFinish) {
              _this4.onFinish(res);
            }
          });
        }
      },
      openExploreOfficeSampleModal: function openExploreOfficeSampleModal() {
        Ember.set(this, "showExploreOfficeSampleModal", true);
      }
    },
    validatePageOne: function validatePageOne() {
      Ember.set(this, 'typeIsNull', false);
      if (!this.ihm) return false;

      if (!this.ihm.type) {
        Ember.set(this, 'typeIsNull', true);
        return false;
      }

      return true;
    },
    validateMeetPeoplePageTwo: function validateMeetPeoplePageTwo() {
      Ember.set(this, 'titleIsNull', false);
      Ember.set(this, 'nbParticipantError', false);
      if (!this.meetpeople) return false;

      if (!this.meetpeople.title) {
        Ember.set(this, 'titleIsNull', true);
        return false;
      }

      if (!this.meetpeople.nbParticipants || this.meetpeople.nbParticipants < 1 || this.meetpeople.nbParticipants > 5) {
        Ember.set(this, 'nbParticipantError', true);
        return false;
      }

      return true;
    },
    validateExploreOfficePageTwo: function validateExploreOfficePageTwo() {
      Ember.set(this, 'titleIsNull', false);
      if (!this.exploreoffice) return false;

      if (!this.exploreoffice.title) {
        Ember.set(this, 'titleIsNull', true);
        return false;
      }

      return true;
    },
    validateMeetPeoplePageThree: function validateMeetPeoplePageThree() {
      Ember.set(this, 'participantError', false);
      if (!this.meetpeople) return false;

      if (!this.meetpeople.participantIds && !this.meetpeople.allUsersSelected && !this.meetpeople.audienceIds) {
        Ember.set(this, 'participantError', true);
        return false;
      }

      if (this.meetpeople.audienceIds) {
        return this.isEnoughParticipants(this.audienceUserCount);
      } else if (!this.meetpeople.allUsersSelected) {
        return this.isEnoughParticipants(this.meetpeople.participantIds.length);
      }

      return true;
    },
    validateExploreOfficePageThree: function validateExploreOfficePageThree() {
      this.resetExploreOfficeFieldsErrors();

      if (!this.exploreoffice || !this.exploreoffice.fields) {
        this.notify.error(this.intl.t('form.errors.missing_required_inputs'));
        return false;
      }

      var errors = false;
      this.exploreoffice.fields.forEach(function (field) {
        if (!field.type || field.type === 'none') {
          Ember.set(field.inputErrors, 'typeIsNull', true);
          errors = true;
          return;
        }

        if (!field.description || field.description === '') {
          Ember.set(field.inputErrors, 'descIsNull', true);
          errors = true;
        }

        if (!field.advise || field.advise === '') {
          Ember.set(field.inputErrors, 'adviseIsNull', true);
          errors = true;
        }

        if (!field.pictureUrl || field.pictureUrl === '') {
          Ember.set(field.inputErrors, 'pictureIsNull', true);
          errors = true;
        }

        if (!field.code || field.code === '') {
          Ember.set(field.inputErrors, 'codeIsNull', true);
          errors = true;
        }
      });

      if (errors) {
        this.notify.error(this.intl.t('form.errors.missing_required_inputs'));
        return false;
      }

      return true;
    },
    resetExploreOfficeFieldsErrors: function resetExploreOfficeFieldsErrors() {
      this.exploreoffice.fields.forEach(function (field) {
        Ember.set(field, "inputErrors", {});
      });
    }
  });

  _exports.default = _default;
});