define("teelt-frontend/services/task-blocking-service", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    userService: Ember.inject.service(),
    session: Ember.inject.service(),
    getPossibleAssigns: function getPossibleAssigns(talent) {
      var possiblesAssign = [];
      var user = this.session.data.authenticated.user;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = user.roles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var role = _step.value;
          var roleToPush = this.getRole(role, talent);

          if (roleToPush) {
            possiblesAssign.push(roleToPush);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return possiblesAssign;
    },
    blockTasksForAllTemplates: function blockTasksForAllTemplates(talent) {
      if (!talent.template) return;

      if (talent.template.subTemplates) {
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = talent.template.subTemplates[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var subTemplate = _step2.value;
            this.blockTasks(talent, subTemplate.template);
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      } else {
        this.blockTasks(talent, talent.template);
      }
    },
    blockTasks: function blockTasks(talent, template) {
      if (template) {
        Ember.set(talent, 'selectedTemplate', template);
      }

      if (!talent || !talent.selectedTemplate) return;
      var periods = undefined;
      var talentCurrentPeriod = undefined;
      periods = this.userService.getTalentPeriods(talent);
      talentCurrentPeriod = this.userService.getTalentCurrentPeriod(talent, periods);
      var periodIndex = 0;
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = talent.selectedTemplate.periods[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var period = _step3.value;

          if (period.tasks) {
            this.reorderPeriodTasks(period.tasks);
            var _iteratorNormalCompletion4 = true;
            var _didIteratorError4 = false;
            var _iteratorError4 = undefined;

            try {
              for (var _iterator4 = period.tasks[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                var task = _step4.value;

                if (talentCurrentPeriod != undefined && task.startOffsetUnit && task.startOffsetDirection) {
                  var offset = task.startOffset;

                  if (task.startOffsetDirection.toLowerCase() == 'before') {
                    offset = -1 * offset;
                  }

                  var triggerDate = periods[talentCurrentPeriod].triggerDate;

                  if (triggerDate == 'assignationDate') {
                    triggerDate = 'templateAssignationDate';
                  }

                  var date = (0, _moment.default)(talent[triggerDate]);

                  switch (task.startOffsetUnit.toLowerCase()) {
                    case 'hour':
                      date.add(offset, 'hours');
                      break;

                    case 'day':
                      date.add(offset, 'days');
                      break;

                    case 'month':
                      date.add(offset, 'months');
                      break;
                  }

                  if (task.startOffsetUnit.toLowerCase() == 'day' || task.startOffsetUnit.toLowerCase() == 'month') {
                    var now = (0, _moment.default)().startOf('day');
                    var dateToCompare = (0, _moment.default)(date).startOf('day');

                    if (now.isBefore(dateToCompare)) {
                      Ember.set(task, 'blockByStart', true);
                    }
                  } else {
                    var _now = (0, _moment.default)();

                    if (_now.isBefore(date)) {
                      Ember.set(task, 'blockByStart', true);
                    }
                  }
                }
              }
            } catch (err) {
              _didIteratorError4 = true;
              _iteratorError4 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
                  _iterator4.return();
                }
              } finally {
                if (_didIteratorError4) {
                  throw _iteratorError4;
                }
              }
            }
          }

          periodIndex++;
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }
    },
    isBlockedByPeriod: function isBlockedByPeriod(talent, taskId, forceAssign) {
      var possiblesAssign = [];

      if (forceAssign) {
        possiblesAssign.push(forceAssign);
      } else {
        possiblesAssign = this.getPossibleAssigns(talent);
      }

      var periods = undefined;
      var talentCurrentPeriod = undefined;
      periods = this.userService.getTalentPeriods(talent);
      talentCurrentPeriod = this.userService.getTalentCurrentPeriod(talent, periods);
      var periodIndex = 0;
      var mandatoryFound = false;
      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = talent.selectedTemplate.periods[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var period = _step5.value;

          if (period.tasks) {
            this.reorderPeriodTasks(period.tasks);
            var _iteratorNormalCompletion6 = true;
            var _didIteratorError6 = false;
            var _iteratorError6 = undefined;

            try {
              for (var _iterator6 = period.tasks[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
                var task = _step6.value;
                var isAssign = this.isTaskAssignTo(task, possiblesAssign, false);

                if (task.id == taskId) {
                  if (isAssign && mandatoryFound && task.startOffset != undefined || talentCurrentPeriod != undefined && talentCurrentPeriod < periodIndex) {
                    return true;
                  }
                }

                if (isAssign && task.periodMandatory && !this.isTaskCompleted(talent, task)) {
                  mandatoryFound = true;
                }
              }
            } catch (err) {
              _didIteratorError6 = true;
              _iteratorError6 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion6 && _iterator6.return != null) {
                  _iterator6.return();
                }
              } finally {
                if (_didIteratorError6) {
                  throw _iteratorError6;
                }
              }
            }
          }

          periodIndex++;
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return != null) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }

      return false;
    },
    isTaskCompleted: function isTaskCompleted(talent, task) {
      if (talent.taskValidations && talent.taskValidations.length) {
        var _iteratorNormalCompletion7 = true;
        var _didIteratorError7 = false;
        var _iteratorError7 = undefined;

        try {
          for (var _iterator7 = talent.taskValidations[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
            var t = _step7.value;

            if (task && task.form && task.form.anonymous) {
              return false;
            }

            if (t.id == task.id) return true;
          }
        } catch (err) {
          _didIteratorError7 = true;
          _iteratorError7 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion7 && _iterator7.return != null) {
              _iterator7.return();
            }
          } finally {
            if (_didIteratorError7) {
              throw _iteratorError7;
            }
          }
        }
      }

      return false;
    },
    reorderPeriodTasks: function reorderPeriodTasks(tasks) {
      tasks.sort(function (t1, t2) {
        var task1 = t1;
        var task2 = t2;
        var value1 = 0;
        var value2 = 0;

        if (task1 && task1.startOffset && task1.startOffsetUnit && task1.startOffsetDirection) {
          value1 = task1.startOffset;

          if (task1.startOffsetUnit == 'day') {
            value1 = value1 * 24;
          }

          if (task1.startOffsetDirection == 'BEFORE') {
            value1 = -1 * value1;
          }
        }

        if (task2 && task2.startOffset && task2.startOffsetUnit && task2.startOffsetDirection) {
          value2 = task2.startOffset;

          if (task2.startOffsetUnit == 'day') {
            value2 = value2 * 24;
          }

          if (task2.startOffsetDirection == 'BEFORE') {
            value2 = -1 * value2;
          }
        }

        if (value1 == value2) return 0;
        return value1 < value2 ? -1 : 1;
      });
    },
    isTaskAssignTo: function isTaskAssignTo(task, possiblesAssign, hideNotifications) {
      var taskAssign = task.assign;

      if (taskAssign == 'system' && task.periodNotificationRecipient && !hideNotifications) {
        taskAssign = task.periodNotificationRecipient.toLowerCase();
      }

      var canView = possiblesAssign.indexOf(taskAssign) >= 0 || possiblesAssign.indexOf(task.assign.split('-')[0]) >= 0;

      if (!canView && task.type == 'sign' && task.signContent && task.signContent.signatories) {
        var _iteratorNormalCompletion8 = true;
        var _didIteratorError8 = false;
        var _iteratorError8 = undefined;

        try {
          for (var _iterator8 = task.signContent.signatories[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
            var signatory = _step8.value;

            if (signatory == "ROLE_TALENT") {
              canView = canView || possiblesAssign.indexOf('talent') >= 0;
            } else if (signatory == "ROLE_MANAGER") {
              canView = canView || possiblesAssign.indexOf('manager') >= 0;
            } else if (signatory == "ROLE_RH") {
              canView = canView || possiblesAssign.indexOf('rh') >= 0;
            } else if (signatory == "ROLE_CUSTOM_OFFICE_MANAGER") {
              canView = canView || possiblesAssign.indexOf('officeManager') >= 0;
            } else if (signatory == "ROLE_MRH") {
              canView = canView || possiblesAssign.indexOf('adminMRH') >= 0;
            } else if (signatory == "ROLE_ADMINISTRATIVE") {
              canView = canView || possiblesAssign.indexOf('administrative') >= 0;
            } else if (signatory.startsWith('ROLE_CONSULT-')) {
              canView = canView || possiblesAssign.indexOf(signatory) >= 0;
            }
          }
        } catch (err) {
          _didIteratorError8 = true;
          _iteratorError8 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion8 && _iterator8.return != null) {
              _iterator8.return();
            }
          } finally {
            if (_didIteratorError8) {
              throw _iteratorError8;
            }
          }
        }
      }

      return canView;
    },
    getRole: function getRole(role, talent) {
      if (role == 'ROLE_RH') {
        return 'rh';
      }

      if (role == "ROLE_ADMINISTRATIVE") {
        return 'administrative';
      }

      if (role == "ROLE_MRH") {
        return 'adminMRH';
      }

      if (!talent) {
        return;
      }

      if (role == "ROLE_CUSTOM_OFFICE_MANAGER") {
        if (!talent.officeManagers) {
          return false;
        }

        var _iteratorNormalCompletion9 = true;
        var _didIteratorError9 = false;
        var _iteratorError9 = undefined;

        try {
          for (var _iterator9 = talent.officeManagers[Symbol.iterator](), _step9; !(_iteratorNormalCompletion9 = (_step9 = _iterator9.next()).done); _iteratorNormalCompletion9 = true) {
            var officeManager = _step9.value;

            if (officeManager.id == this.session.data.authenticated.user.id) {
              return 'officeManager';
            }
          }
        } catch (err) {
          _didIteratorError9 = true;
          _iteratorError9 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion9 && _iterator9.return != null) {
              _iterator9.return();
            }
          } finally {
            if (_didIteratorError9) {
              throw _iteratorError9;
            }
          }
        }
      }

      if (role == "ROLE_MANAGER") {
        if (!talent.manager) {
          return;
        }

        if (talent.manager.id != this.session.data.authenticated.user.id) {
          return;
        }

        return 'manager';
      }

      if (role.indexOf('ROLE_CONSULT-') == 0) {
        return role;
      }

      return false;
    }
  });

  _exports.default = _default;
});