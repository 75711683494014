define("teelt-frontend/components/ihm/explore-office/explore-office-conclusion", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['explore-office-conclusion'],
    ajax: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    session: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.exploreofficeId && !this.exploreoffice) {
        this.get('registerModulesList')(this);
        this.ajax.requestPromise(_environment.default.APP.API_HOST + '/exploreoffices/' + this.exploreofficeId, 'GET').then(function (data) {
          Ember.set(_this, "exploreoffice", data);

          _this.send("downloadPdf", true);

          Ember.set(_this, "exploreOfficePdfDl", false);
        });
      }
    },
    getCompanyLogoUrl: function getCompanyLogoUrl() {
      var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : 'default';
      var path = _environment.default.APP.API_HOST + "/companies/customizations/domain?domain=" + subdomain;
      return this.ajax.requestPromise(path, 'GET').then(function (customizations) {
        return customizations.logoUrl;
      });
    },
    printPdf: function printPdf(companyLogoBase64) {
      var el = document.getElementById("explore-office-pdf");
      var fileName = this.exploreoffice.title + '.pdf';
      var opt = {
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        html2canvas: {
          width: '90%',
          scrollX: 0,
          scrollY: 0,
          windowWidth: '100%',
          scale: 2
        },
        jsPDF: {
          orientation: 'portrait'
        },
        margin: [10, 10, 10, 10],
        pagebreak: {
          mode: ['avoid-all', 'css', 'legacy'],
          before: '.page-break'
        }
      };
      html2pdf().set(opt).from(el).toPdf().get('pdf').then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();
        var footerImg = new Image();
        footerImg.src = '/img/exploreoffice/pdf-footer.png';
        var headerImg = new Image();

        if (companyLogoBase64) {
          headerImg.src = 'data:image/png;base64,' + companyLogoBase64.data;
        } else {
          headerImg.src = '/logo/blue-teelt-logo.png';
        }

        var imagesLoaded = 0;

        var onImageLoad = function onImageLoad() {
          imagesLoaded++;

          if (imagesLoaded === 2) {
            var pageWidth = pdf.internal.pageSize.getWidth();
            var pageHeight = pdf.internal.pageSize.getHeight();
            var footerHeight = footerImg.height * pageWidth / footerImg.width;
            var headerHeight = footerHeight;
            var headerWidth = headerImg.width * headerHeight / headerImg.height;

            for (var i = 1; i <= totalPages; i++) {
              var footerX = 0;
              var footerY = pageHeight - footerHeight;
              var headerX = (pageWidth - headerWidth) / 2;
              var headerY = 0;
              pdf.setPage(i);
              pdf.setFontSize(8);
              pdf.setTextColor(150);
              pdf.addImage(footerImg, 'PNG', footerX, footerY, pageWidth, footerHeight);
              pdf.addImage(headerImg, 'PNG', headerX, headerY, headerWidth, headerHeight);
            }

            pdf.save(fileName);
          }
        };

        footerImg.onload = onImageLoad;
        headerImg.onload = onImageLoad;
      });
    },
    actions: {
      downloadPdf: function downloadPdf() {
        var _this2 = this;

        var reset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        this.getCompanyLogoUrl().then(function (companyLogoUrl) {
          if (companyLogoUrl && companyLogoUrl != '') {
            _this2.fileUploadService.getObjectBase64(companyLogoUrl).then(function (companyLogoBase64) {
              if (companyLogoBase64) {
                _this2.printPdf(companyLogoBase64);

                if (reset) _this2.get('onDownloadComplete')();
              }
            });
          } else {
            _this2.printPdf(null);

            if (reset) _this2.get('onDownloadComplete')();
          }
        });
      }
    }
  });

  _exports.default = _default;
});