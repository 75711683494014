define("teelt-frontend/templates/components/task/task-deadline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O9eaNqa1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"deadline\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"deadline\"],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"alarm_on\"],[10],[1,[21,\"deadline\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/task/task-deadline.hbs"
    }
  });

  _exports.default = _default;
});