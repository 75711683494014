define("teelt-frontend/components/custom-field/field/custom-select", ["exports", "teelt-frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajaxService: Ember.inject.service('ajax'),
    customErrorService: Ember.inject.service('custom-error'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.selectedValue) {
        Ember.set(this, 'waitingTalent', true);
        var url = _environment.default.APP.API_HOST + "/users/" + this.selectedValue + "/fromCustomLink/" + this.field.codeKey;
        this.ajaxService.requestPromise(url, 'GET').then(function (talent) {
          Ember.set(_this, 'talent', talent);
          Ember.set(_this, 'waitingTalent', false);
        });
      }

      this.getFieldData();
    },
    getFieldData: function getFieldData() {
      var _this2 = this;

      this.ajaxService.requestPromise(_environment.default.APP.API_HOST + '/custom-fields/' + this.field.id + '/data', 'GET').then(function (data) {
        Ember.set(_this2, 'data', data.map(function (d) {
          return {
            key: d.id,
            label: d.value
          };
        }));
      }).catch(function (err) {
        _this2.customErrorService.handleAjaxError(err);
      });
    },
    actions: {
      onSelectChanged: function onSelectChanged(value) {
        Ember.set(this, 'selectedValue', value.key);
        Ember.set(this, 'selectedLabel', value.label);
      }
    }
  });

  _exports.default = _default;
});