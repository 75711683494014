define("teelt-frontend/templates/components/form/field/complex/field-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w6hQkMzc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"input-field\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"or\",[[27,\"not\",[[27,\"eq\",[[23,[\"field\",\"isValid\"]],true],null]],null],[27,\"not\",[[23,[\"allowDocumentValidation\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"input/file-input\",null,[[\"icon\",\"label\",\"id\",\"placeholder\",\"onChanged\",\"progress\"],[\"insert_drive_file\",[27,\"capitalize\",[[27,\"t\",[\"word.download_file\"],null]],null],[23,[\"uuid\"]],[27,\"t\",[\"input.file.upload\"],null],[27,\"action\",[[22,0,[]],\"onFileChanged\"],null],[23,[\"progress\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"value\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"show-file\"],[9],[7,\"a\"],[11,\"href\",\"#\"],[3,\"action\",[[22,0,[]],\"openFile\"]],[9],[1,[27,\"t\",[\"form.show_doc\"],[[\"doc\"],[[23,[\"field\",\"title\"]]]]],false],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[27,\"eq\",[[23,[\"field\",\"isValid\"]],false],null],[23,[\"allowDocumentValidation\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"helper-text g-error\"],[9],[1,[27,\"t\",[\"form.validation.reject_reason\"],[[\"reason\"],[[23,[\"field\",\"rejectReason\"]]]]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"and\",[[27,\"eq\",[[23,[\"field\",\"isValid\"]],true],null],[23,[\"allowDocumentValidation\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"helper-text g-success\"],[9],[1,[27,\"t\",[\"form.validation.validated_document\"],null],false],[10],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,[\"haveError\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"helper-text g-error\"],[9],[1,[23,[\"field\",\"validationError\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/form/field/complex/field-file.hbs"
    }
  });

  _exports.default = _default;
});