define("teelt-frontend/services/intercom", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    initializeIntercom: function initializeIntercom() {
      var _this = this;

      setTimeout(function () {
        var user = _this.session.data.authenticated.user;
        var apiUrl = _environment.default.APP.API_HOST + "/users/me/intercom/token";

        _this.ajax.requestPromise(apiUrl, 'GET').then(function (token) {
          Intercom('boot', {
            app_id: "gnrdd901",
            user_id: user.id,
            user_hash: token // This is for secure Intercom connections

          });
        }).catch(function (error) {
          return console.error(error);
        });
      }, 100);
    }
  });

  _exports.default = _default;
});