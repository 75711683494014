define("teelt-frontend/components/homepage/design-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['design-block'],
    fileUploadService: Ember.inject.service('file-upload'),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.template.customBackgroundColor) {
        Ember.set(this.template, 'customBackgroundColor', this.companyBackgroundColor);
      }

      if (!this.template.customForegroundColor) {
        Ember.set(this.template, 'customForegroundColor', this.companyForegroundColor);
      }
    },
    onMediaLogoBackProgress: function onMediaLogoBackProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'logoBackProgress', progress);
      }
    },
    actions: {
      onMediaLogoBackChanged: function onMediaLogoBackChanged(file) {
        var _this = this;

        this.fileUploadService.signAndUpload(file, false, function (e) {
          return _this.onMediaLogoBackProgress(e);
        }).then(function (fileUrl) {
          Ember.set(_this.template, 'customLogoOnBackgroundColorUrl', fileUrl);

          if (_this.onUpdate) {
            _this.onUpdate();
          }
        });
      },
      removeFile: function removeFile(fieldName) {
        Ember.set(this.template, fieldName, null);

        if (this.onUpdate) {
          this.onUpdate();
        }
      },
      changeBackColor: function changeBackColor(color) {
        Ember.set(this.template, 'customBackgroundColor', color);

        if (this.onUpdate) {
          this.onUpdate();
        }
      },
      changeForeColor: function changeForeColor(color) {
        Ember.set(this.template, 'customForegroundColor', color);

        if (this.onUpdate) {
          this.onUpdate();
        }
      },
      handleFocusOut: function handleFocusOut() {
        if (this.onUpdate) {
          this.onUpdate();
        }
      },
      interceptEnter: function interceptEnter(e1, e2) {
        if (e2.keyCode == 13) {
          this.send('handleFocusOut', e2);
          return false;
        }

        return true;
      },
      openFile: function openFile(value) {
        var downloadWaitingUrl = window.location.protocol + '//' + window.location.host + '/downloading';
        var tabOpen = window.open(downloadWaitingUrl, 'newtab');
        tabOpen.location = value;
      },
      resetColor: function resetColor(type) {
        if (type === 'background') {
          Ember.set(this.template, 'customBackgroundColor', this.companyBackgroundColor);
        } else {
          Ember.set(this.template, 'customForegroundColor', this.companyForegroundColor);
        }

        if (this.onUpdate) {
          this.onUpdate();
        }
      }
    }
  });

  _exports.default = _default;
});