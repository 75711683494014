define("teelt-frontend/templates/components/emails/multiple-offset-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WVu6V9Go",
    "block": "{\"symbols\":[\"offset\",\"index\"],\"statements\":[[4,\"each\",[[23,[\"offsets\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"emails/offset-picker\",null,[[\"offset\",\"period\",\"templatePeriodTasks\",\"hideLimiteDate\",\"isOtherNotificationTask\",\"templateNotification\",\"showTaskPreview\",\"index\"],[[22,1,[]],[23,[\"period\"]],[23,[\"templatePeriodTasks\"]],[23,[\"hideLimiteDate\"]],[23,[\"isOtherNotificationTask\"]],[23,[\"templateNotification\"]],[23,[\"showTaskPreview\"]],[22,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/emails/multiple-offset-picker.hbs"
    }
  });

  _exports.default = _default;
});