define("teelt-frontend/components/task/task-startline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['task-startline'],
    intl: Ember.inject.service(),
    startline: Ember.computed('task.startOffsetDirection', 'task.startOffsetUnit', 'task.startOffset', function () {
      if (this.task.startOffsetDirection && this.task.startOffsetUnit) {
        var startline = this.intl.t("task.unit." + this.task.startOffsetUnit);

        if (this.task.startOffset) {
          if (this.task.startOffsetDirection == "BEFORE") {
            startline += "-";
          } else {
            startline += "+";
          }

          startline += this.task.startOffset;
        }

        return startline;
      }
    })
  });

  _exports.default = _default;
});