define("teelt-frontend/components/notification/notification-form", ["exports", "teelt-frontend/utils/email-variables"], function (_exports, _emailVariables) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['notification-form'],
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    customErrorService: Ember.inject.service('custom-error'),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    staticlikeData: Ember.inject.service(),
    userService: Ember.inject.service(),
    notificationMarker: Ember.inject.service(),
    ajax: Ember.inject.service(),
    getEmailNotification: Ember.inject.service(),
    getSmsNotification: Ember.inject.service(),
    getSlackNotification: Ember.inject.service(),
    emailSender: "",
    activeSlack: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('getEmailNotification').setData(null);
      this.get('getSmsNotification').setData(null);
      this.get('getSlackNotification').setData(null);
      Ember.set(this, 'emailSender', this.session.data.authenticated.user.company.emailSender);
      Ember.set(this, 'conditions', {});

      if (this.session.data.authenticated.user.company.activeSlack) {
        Ember.set(this, 'activeSlack', true);
      }

      if (this.notification.contents) {
        this.notification.contents.forEach(function (content) {
          if (content.contentType === "EMAIL") {
            _this.get('getEmailNotification').setData(content);

            if (_this.session.data.authenticated.user.company.htmlSourceCodeAllowed) {
              Ember.set(_this, 'htmlSourceCodeAllowed', true);

              if (content.sourceCode) {
                Ember.set(_this, 'isSourceMode', true);
                setTimeout(function () {
                  _this.initIframeContent(content.sourceCode);
                }, 3000);
              }
            }
          }

          if (content.contentType === "SMS") {
            _this.get('getSmsNotification').setData(content);
          }

          if (content.contentType === "SLACK") {
            _this.get('getSlackNotification').setData(content);
          }
        });
      }

      if (!this.notification) {
        if (this.duplicateId) {
          // TODO DUPE
          Ember.set(this, 'notification', {
            contents: [],
            schedules: []
          });
          this.store.findRecord('notification', this.duplicateId).then(function (notificationToDuplicate) {
            var notificationJSON = notificationToDuplicate.toJSON();
            notificationJSON['id'] = undefined;
            notificationJSON['type'] = 'CUSTOM';
            notificationJSON['name'] = undefined;
            var contents = notificationJSON['contents'];

            if (contents && contents.length) {
              contents.forEach(function (c) {
                c.id = undefined;
                var attachments = c.attachments;

                if (attachments && attachments.length) {
                  attachments.forEach(function (a) {
                    return a.id = undefined;
                  });
                }
              });
            }

            var schedules = notificationJSON['schedules'];

            if (schedules && schedules.length) {
              schedules.forEach(function (s) {
                return s.id = undefined;
              });
            }

            var notification = _this.store.createRecord('notification', notificationJSON);

            Ember.set(_this, 'notification', notification);

            _this.setContent(undefined, "EMAIL");

            _this.setContent(undefined, "SMS");

            Ember.set(_this, 'templateMarkers', _this.notificationMarker.getMarkers(notification));
          });
        } else {
          var notification = this.store.createRecord('notification', {
            type: 'CUSTOM',
            recipient: 'TALENT',
            triggerInitiator: 'action_start',
            contents: [{
              contentType: "EMAIL",
              officeId: null,
              tagId: null,
              subject: "",
              body: "",
              replyTo: ""
            }, {
              contentType: "SMS",
              officeId: null,
              tagId: null,
              subject: "",
              body: "",
              replyTo: ""
            }],
            schedules: [{}],
            associatedRole: null
          });
          Ember.set(this, 'notification', notification);

          if (this.task) {
            Ember.set(this.task, 'notification', notification);
          }

          Ember.set(this, 'templateMarkers', this.notificationMarker.getMarkers(notification));
        }
      } else {
        Ember.set(this, 'editMode', true);

        if (this.notification.type !== 'CUSTOM') {
          if (this.notification.type === "FORGOT_PASSWORD" || this.notification.type.startsWith("NEW_ACCOUNT_")) {
            Ember.set(this, "addForgotLinkButton", true);
          }
        }

        if (this.notification.conditions) {
          var split = this.notification.conditions.split(';');
          split.forEach(function (value) {
            if (value.startsWith("taskCompleted")) {
              var tmp = value.split('=');
              Ember.set(_this.conditions, tmp[0], tmp[1]);
            } else {
              Ember.set(_this.conditions, value, true);
            }
          });
        }

        if (this.notificationsCustomRoles) {
          this.notificationsCustomRoles.forEach(function (n) {
            if (n.notificationId == _this.notification.id) {
              Ember.set(_this, 'customRole', n.customRole);
              Ember.set(_this, 'customType', n.customType);
            }
          });
        }

        Ember.set(this, 'templateMarkers', this.notificationMarker.getMarkers(this.notification));
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.setConditions();
      setTimeout(function () {
        _this2.$('.select').formSelect();
      }, 0);
    },
    validateNotification: function validateNotification() {
      this.resetErrors();

      if (!this.system) {
        if (!this.notification.name) {
          Ember.set(this, 'nameIsNull', true);
          return false;
        }

        if (this.notification.contents) {
          var smsBodyIsNull = true;
          var slackBodyIsNull = true;
          var subjectIsNull = true;
          var emailBodyIsNull = true;
          this.notification.contents.forEach(function (content) {
            if (content.contentType === "SMS") {
              if (content.body) {
                smsBodyIsNull = false;
              }
            }

            if (content.contentType === "SLACK") {
              if (content.body) {
                slackBodyIsNull = false;
              }
            }

            if (content.contentType === "EMAIL") {
              if (content.subject) {
                subjectIsNull = false;
              }

              if (content.creationCode && content.creationCode != "<p><br></p>" || content.sourceCode) {
                emailBodyIsNull = false;
              }
            }
          });

          if (subjectIsNull && !emailBodyIsNull && smsBodyIsNull && slackBodyIsNull) {
            Ember.set(this, 'subjectIsNull', true);
            var element = document.getElementById('email-subject');
            if (element) element.scrollIntoView();
            return false;
          }

          if (subjectIsNull && smsBodyIsNull && slackBodyIsNull) {
            Ember.set(this, 'subjectIsNull', true);
            Ember.set(this, 'smsBodyIsNull', true);
            Ember.set(this, 'slackBodyIsNull', true);

            var _element = document.getElementById('email-subject');

            if (_element) _element.scrollIntoView();
            return false;
          }

          if (!subjectIsNull && emailBodyIsNull && smsBodyIsNull && slackBodyIsNull) {
            Ember.set(this, 'smsBodyIsNull', true);
            Ember.set(this, 'slackBodyIsNull', true);
            Ember.set(this, 'emailBodyIsNull', true);

            var _element2 = document.getElementById('source-code-editor');

            if (_element2) _element2.scrollIntoView();
            return false;
          }
        }

        if (!this.notification.triggerInitiator && !this.notification.isSystem) {
          Ember.set(this, 'triggerInitiatorIsNull', true);
          return false;
        }
      }

      return true;
    },
    resetErrors: function resetErrors() {
      var _this3 = this;

      var errors = ["nameIsNull", "recipientIsNull", "triggerInitiatorIsNull", "subjectIsNull", "smsBodyIsNull", "slackBodyIsNull", "emailBodyIsNull"];
      errors.forEach(function (error) {
        Ember.set(_this3, error, undefined);
      });
    },
    setContent: function setContent(type) {
      var _this4 = this;

      var tagId = this.selectedTagId;
      var officeId = this.selectedOfficeId;
      if (!this.notification || !this.notification.contents) return;
      var defaultContent;
      var officeContent;
      this.notification.contents.forEach(function (content) {
        if (content.contentType !== type) return;
        if (!content.officeId && !content.tagId) defaultContent = content;else if (_this4.tagMode && content.tagId === Number(tagId) && content.tagId !== undefined || !_this4.tagMode && content.officeId === Number(officeId) && content.officeId !== undefined) {
          officeContent = content;
        }
      });

      if (!defaultContent) {
        defaultContent = {
          contentType: type,
          officeId: null,
          tagId: null,
          subject: "",
          body: "",
          replyTo: ""
        };
        this.notification.contents.push(defaultContent);
      }

      if (this.tagMode && !tagId || !this.tagMode && !officeId) return defaultContent;

      if (!officeContent) {
        var copy = {
          contentType: type,
          officeId: this.tagMode ? null : Number(officeId),
          tagId: this.tagMode ? Number(tagId) : null
        };

        if (!defaultContent) {
          copy.subject = "";
          copy.body = "";
          copy.replyTo = "";
        } else {
          copy.subject = defaultContent.subject;
          copy.body = defaultContent.body;
          copy.replyTo = defaultContent.replyTo;
        }

        this.notification.contents.push(copy);
      }
    },
    setConditions: function setConditions() {
      var _this5 = this;

      Ember.set(this, "emailConditions", {
        showTaskRemainsBeforeCondition: false,
        showTaskRemainsOnboardingCondition: false,
        showTaskRemainsAfterCondition: false
      });
      if (!this.notification.contents) return;
      this.notification.contents.forEach(function (content) {
        var emailBody = content.sourceCode ? content.sourceCode : content.creationCode;

        if (emailBody) {
          var variables = (0, _emailVariables.default)().extractVariablesFromEmail(emailBody);

          if (variables) {
            if (variables.includes("%TALENT_TASK_LIST_PERIOD_BEFORE%")) {
              Ember.set(_this5.emailConditions, 'showTaskRemainsBeforeCondition', true);
            }

            if (variables.includes("%TALENT_TASK_LIST_PERIOD_ONBOARDING%")) {
              Ember.set(_this5.emailConditions, 'showTaskRemainsOnboardingCondition', true);
            }

            if (variables.includes("%TALENT_TASK_LIST_PERIOD_AFTER%")) {
              Ember.set(_this5.emailConditions, 'showTaskRemainsAfterCondition', true);
            }
          }
        }
      });

      if (!this.emailConditions.showTaskRemainsBeforeCondition) {
        Ember.set(this.conditions, 'remaining_tasks_period_before_gt_0', false);
      }

      if (!this.emailConditions.showTaskRemainsOnboardingCondition) {
        Ember.set(this.conditions, 'remaining_tasks_period_onboarding_gt_0', false);
      }

      if (!this.emailConditions.showTaskRemainsAfterCondition) {
        Ember.set(this.conditions, 'remaining_tasks_period_after_gt_0', false);
      }
    },
    mapConditionsToRule: function mapConditionsToRule() {
      var conditions = [];

      for (var key in this.conditions) {
        if (this.conditions[key] === true) {
          conditions.push(key);
        } else if (key.startsWith("taskCompleted") && this.conditions[key] !== undefined) {
          conditions.push(key + "=" + this.conditions[key]);
        }
      }

      var conditionsStr = conditions.join(';');
      Ember.set(this.notification, 'conditions', conditionsStr);
    },
    loadTasks: function loadTasks() {
      var _this6 = this;

      setTimeout(function () {
        _this6.$('.task-select').formSelect();
      }, 0);
    },
    initIframeContent: function initIframeContent(sourceCode) {
      var previewFrame = document.getElementById('code-source-iframe');
      if (!previewFrame) return;
      var iframeDocument = previewFrame.contentDocument || previewFrame.contentWindow.document;
      iframeDocument.open();
      iframeDocument.write(sourceCode);
      iframeDocument.close();
    },
    actions: {
      onRecipientChanged: function onRecipientChanged(value) {
        Ember.set(this.notification, "recipient", value);
        Ember.set(this, 'recipientIsNull', undefined);
      },
      onTriggerChanged: function onTriggerChanged(value) {
        Ember.set(this.notification, "triggerInitiator", value);
        Ember.set(this, 'triggerInitiatorIsNull', undefined);

        if (this.notification.triggerInitiator == 'task_completed' || this.notification.triggerInitiator == 'task_not_completed') {
          this.loadTasks();
        }
      },
      onEmailBodyChanged: function onEmailBodyChanged() {
        this.setConditions();
      },
      onEmailSourceCodeChanged: function onEmailSourceCodeChanged() {
        this.setConditions();
      },
      onSmsBodyChanged: function onSmsBodyChanged() {
        this.setConditions();
      },
      onSlackBodyChanged: function onSlackBodyChanged() {
        this.setConditions();
      },
      previous: function previous() {
        if (this.onPrevious) this.onPrevious();
      },
      next: function next() {
        if (!this.validateNotification()) return;
        if (this.canSave) this.send('save');
        if (!this.canSave && this.onFinish()) this.onFinish();
      },
      save: function save() {
        var _this7 = this;

        if (!this.validateNotification()) return;
        this.mapConditionsToRule();
        this.notification.contents.forEach(function (content) {
          if (content.contentType === "EMAIL") if (content.sourceCode) {
            var minifySourceCode = content.sourceCode.replace(/\n|\t/g, ' ').replace(/> *</g, '><');
            Ember.set(content, "sourceCode", minifySourceCode);
            Ember.set(content, "body", minifySourceCode);
          } else if (content.creationCode) {
            Ember.set(content, "body", content.creationCode);
          }
        });
        this.notification.save().then(function (notification) {
          Ember.set(_this7, 'saved', true);

          if (_this7.onFinish) {
            _this7.onFinish(notification);
          }
          /*
          if (notification.customNotificationId) {
            this.router.transitionTo("board.notification.notification-detail", notification.customNotificationId);
          } else {
            this.router.transitionTo("board.notification.notification-detail", notification.id);
          }*/

        }).catch(function (err) {
          console.log(err);

          _this7.customErrorService.handleAjaxError(err);
        });
      },
      close: function close() {
        if (!this.editMode) {
          this.router.transitionTo('board.emails.notifications-list');
        } else {
          this.router.transitionTo("board.notification.notification-detail", this.notification.id);
        }
      },
      onTaskCompletedChanged: function onTaskCompletedChanged(value, text) {
        Ember.set(this.conditions, 'taskCompletedId', value);
        Ember.set(this.conditions, 'taskCompletedName', text.replaceAll(';', ','));
      },
      openEditVersion: function openEditVersion() {
        var _this8 = this;

        if (!this.validateNotification()) return;
        this.notification.save().then(function () {
          if (_this8.openEditVersion) {
            _this8.openEditVersion(_this8.notification);
          }
        });
      },
      toggleShowNotification: function toggleShowNotification(value) {
        if (value == "showEmail") {
          if (this.showEmail) {
            Ember.set(this, 'showEmail', false);
            return;
          }

          Ember.set(this, 'showEmail', true);
          Ember.set(this, 'showSms', false);
          Ember.set(this, 'showSlack', false);
        } else if (value == "showSms") {
          if (this.showSms) {
            Ember.set(this, 'showSms', false);
            return;
          }

          Ember.set(this, 'showEmail', false);
          Ember.set(this, 'showSlack', false);
          Ember.set(this, 'showSms', true);
        } else {
          if (this.showSlack) {
            Ember.set(this, 'showSlack', false);
            return;
          }

          Ember.set(this, 'showEmail', false);
          Ember.set(this, 'showSms', false);
          Ember.set(this, 'showSlack', true);
        }
      }
    }
  });

  _exports.default = _default;
});