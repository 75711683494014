define("teelt-frontend/components/utils/mobile/bottom-pannel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['bottom-pannel'],
    classNameBindings: ['opened:opened', 'show:shown:hidden'],
    openedObserver: Ember.observer('opened', function () {
      this.$().animate({
        height: this.opened ? '70%' : '65px'
      }, 300);
    })
  });

  _exports.default = _default;
});