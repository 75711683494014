define("teelt-frontend/components/talents/talent-detail-block/talent-main-block", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    uiUtils: Ember.inject.service('ui-utils'),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.canCloseWithoutSaving) this.canCloseWithoutSaving(true);

      if (!this.talent.position) {
        Ember.set(this.talent, 'position', {
          name: ''
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    actions: {
      onInputChanged: function onInputChanged() {
        if (this.canCloseWithoutSaving) this.canCloseWithoutSaving(false);
      }
    }
  });

  _exports.default = _default;
});