define("teelt-frontend/services/model-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    isRecord: function isRecord(record) {
      return record.constructor && record.constructor.modelName;
    },
    convertJsonToRecord: function convertJsonToRecord(json, type) {
      console.log('CONVERT', json, type);

      var id = json.id,
          attributes = _objectWithoutProperties(json, ["id"]);

      if (id) {
        var record = this.store.peekRecord(type, id);

        if (record) {
          record.setProperties(attributes);
        } else {
          record = this.store.push({
            data: {
              id: id,
              type: type,
              attributes: attributes
            }
          });
        }

        return record;
      } else {
        return this.store.createRecord(type, attributes);
      }
    }
  });

  _exports.default = _default;
});