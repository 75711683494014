define("teelt-frontend/components/talents/talent-detail-block/talent-template-block", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    uiUtils: Ember.inject.service('ui-utils'),
    templatePath: _environment.default.APP.API_HOST + "/templates?forceAll=true&hideDisabled=true",
    campaignPath: _environment.default.APP.API_HOST + "/events/campaigns",
    init: function init() {
      this._super.apply(this, arguments);

      if (this.canCloseWithoutSaving) this.canCloseWithoutSaving(true);

      if (this.talent.selectedTemplate) {
        Ember.set(this, 'defaultTemplate', this.talent.selectedTemplate.name);
      }

      if (this.talent.campaign) {
        Ember.set(this, 'defaultCampaign', this.talent.campaign.name);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    actions: {
      onTemplateChanged: function onTemplateChanged(value) {
        if (this.canCloseWithoutSaving) this.canCloseWithoutSaving(false);

        if (value) {
          Ember.set(this.talent, 'template', {
            id: value.key
          });
        } else {
          Ember.set(this.talent, 'template', null);
        }
      },
      onCampaignChanged: function onCampaignChanged(value) {
        if (this.canCloseWithoutSaving) this.canCloseWithoutSaving(false);

        if (value) {
          Ember.set(this.talent, 'campaign', {
            id: value.key
          });
        } else {
          Ember.set(this.talent, 'campaign', null);
        }
      }
    }
  });

  _exports.default = _default;
});