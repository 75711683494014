define("teelt-frontend/services/ui-utils", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    dateFormat: 'DD/MM/YYYY',
    filters: Array(),
    datepickerTranslations: {
      en: {
        cancel: "Cancel",
        clear: "Clear",
        done: "OK",
        months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        monthsShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        weekdays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        weekdaysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        weekdaysAbbrev: ["S", "M", "T", "W", "T", "F", "S"]
      },
      fr: {
        cancel: "Annuler",
        clear: "Effacer",
        done: "OK",
        months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
        monthsShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Août", "Sept", "Oct", "Nov", "Déc"],
        weekdays: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
        weekdaysShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
        weekdaysAbbrev: ["D", "L", "M", "M", "J", "V", "S"]
      },
      it: {
        cancel: "Annulla",
        clear: "Cancella",
        done: "OK",
        months: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
        monthsShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
        weekdays: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
        weekdaysShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
        weekdaysAbbrev: ["D", "L", "M", "M", "G", "V", "S"]
      },
      es: {
        cancel: "Cancelar",
        clear: "Borrar",
        done: "OK",
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
        weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        weekdaysShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
        weekdaysAbbrev: ["D", "L", "M", "M", "J", "V", "S"]
      },
      pt: {
        cancel: "Cancelar",
        clear: "Limpar",
        done: "OK",
        months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthsShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        weekdays: ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"],
        weekdaysShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        weekdaysAbbrev: ["D", "S", "T", "Q", "Q", "S", "S"]
      },
      de: {
        cancel: "Abbrechen",
        clear: "Löschen",
        done: "OK",
        months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
        monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
        weekdays: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
        weekdaysShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        weekdaysAbbrev: ["S", "M", "D", "M", "D", "F", "S"]
      },
      pl: {
        cancel: "Anuluj",
        clear: "Wyczyść",
        done: "OK",
        months: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
        monthsShort: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"],
        weekdays: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
        weekdaysShort: ["Niedz.", "Pon.", "Wt.", "Śr.", "Czw.", "Pt.", "Sob."],
        weekdaysAbbrev: ["N", "P", "W", "Ś", "C", "P", "S"]
      }
    },
    initAutocomplete: function initAutocomplete(input, data, attrName, autocompleteCallback) {
      if (!data) return;
      var autocompleteData = {};
      data.forEach(function (element) {
        autocompleteData[element[attrName]] = null;
      });
      input.autocomplete({
        data: autocompleteData,
        onAutocomplete: function onAutocomplete(e, a) {
          autocompleteCallback(e);
        }
      });
    },
    updateAutocompleteData: function updateAutocompleteData(input, data, attrName) {
      if (!data) return;
      var autocompleteData = {};
      data.forEach(function (element) {
        autocompleteData[element[attrName]] = null;
      });
      var instance = M.Autocomplete.getInstance(input);

      if (!instance) {
        console.error('no autocomplete instance');
        return;
      }

      instance.updateData(autocompleteData);

      if (!instance.isOpen) {
        instance.open();
      }
    },
    getBrowserLanguageTranslations: function getBrowserLanguageTranslations() {
      var browserLanguage = navigator.language.substring(0, 2).toLowerCase();
      return this.datepickerTranslations[browserLanguage] || this.datepickerTranslations['en'];
    },
    initDatePicker: function initDatePicker(input, callback, options) {
      var minDate = options && options.minDate ? (0, _moment.default)(options.minDate).toDate() : undefined;
      var maxDate = options && options.maxDate ? (0, _moment.default)(options.maxDate).toDate() : undefined;
      var defaultDate = options && options.defaultDate ? (0, _moment.default)(options.defaultDate).toDate() : undefined;
      var yearRange = options && options.yearRange ? options.yearRange : 100;
      var autoClose = options && options.autoClose != undefined ? options.autoClose : true;
      var setDefaultDate = options && options.setDefaultDate != undefined ? options.setDefaultDate : true;
      var selectedValue;

      function onDoneButtonClick() {
        callback(selectedValue);
      }

      input.datepicker({
        autoClose: autoClose,
        setDefaultDate: setDefaultDate,
        format: 'dd/mm/yyyy',
        minDate: minDate,
        maxDate: maxDate,
        defaultDate: defaultDate,
        firstDay: 1,
        yearRange: yearRange,
        i18n: this.getBrowserLanguageTranslations(),
        onSelect: function onSelect(e) {
          selectedValue = e;
        },
        onDraw: function onDraw(e) {
          var doneButton = e.modalEl.querySelector('.datepicker-done');
          doneButton.removeEventListener('click', onDoneButtonClick);
          doneButton.addEventListener('click', onDoneButtonClick);
          var intervalId = setInterval(function () {
            var selectYearElement = e.modalEl.querySelector('.select-year');

            if (selectYearElement) {
              clearInterval(intervalId);
              selectYearElement.addEventListener('click', function () {
                var dataTargetValue = selectYearElement.querySelector('.select-dropdown.dropdown-trigger').getAttribute('data-target');
                setTimeout(function () {
                  var currentYear = new Date().getFullYear();
                  var yearOptions = document.getElementById(dataTargetValue).querySelectorAll('li');

                  if (yearOptions) {
                    yearOptions.forEach(function (option) {
                      var yearText = option.textContent.trim();

                      if (parseInt(yearText) === currentYear) {
                        option.scrollIntoView({
                          behavior: 'auto',
                          block: 'center'
                        });
                      }
                    });
                  }
                }, 100);
              });
            }
          }, 100);
        }
      });
    },
    initTimePicker: function initTimePicker(input, callback) {
      input.timepicker({
        autoClose: true,
        defaultTime: "00:00",
        twelveHour: false,
        onSelect: function onSelect(hours, minutes) {
          if (callback) {
            callback(hours, minutes);
          }
        }
      });
    },
    initSelectSearchable: function initSelectSearchable(input, data) {
      input.select2({
        data: data
      });
    },
    getOrInitFilters: function getOrInitFilters(view) {
      if (!localStorage.getItem('filters')) {
        var obj = {};
        obj[view] = {};
        localStorage.setItem('filters', JSON.stringify(obj));
      }

      var filters = localStorage.getItem('filters');
      var jsonFilter = JSON.parse(filters);
      return jsonFilter[view];
    },
    setFilters: function setFilters(view, filters) {
      var newFiltersObject = {};
      newFiltersObject[view] = filters;
      localStorage.setItem('filters', JSON.stringify(newFiltersObject));
    },
    setFilter: function setFilter(view, filter) {
      var filters = this.getOrInitFilters(view);

      if (typeof filter.direction === "undefined") {
        delete filters[filter.type];
      } else {
        if (filter.type) {
          filters[filter.type] = filter;
        }
      }

      this.setFilters(view, filters);
    }
  });

  _exports.default = _default;
});