define("teelt-frontend/components/talents/talent-date-edit", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talent-date-edit'],
    uiUtils: Ember.inject.service('ui-utils'),
    times: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'],
    initDatepicker: function initDatepicker() {
      var _this = this;

      setTimeout(function () {
        var options = {
          autoClose: false,
          setDefaultDate: true,
          defaultDate: _this.talent[_this.dateKey] ? _this.talent[_this.dateKey] : new Date()
        };

        if (_this.minDate) {
          options.minDate = _this.minDate;
        }

        _this.uiUtils.initDatePicker(_this.$('#talentdate-picker'), function (result) {
          if (result) {
            Ember.set(_this, 'date', moment.tz(result, moment.tz.guess()));

            _this.updateTalentDate();
          }
        }, options);

        _this.$('.datepicker-footer').before(_this.$('#time-block'));

        if (_this.openAtInit) {
          _this.$('#talentdate-picker').datepicker('open');
        }
      });
    },
    updateTalentDate: function updateTalentDate() {
      if (!this.date) return;
      var timeTmp = this.time.split(':');
      var dateTime = this.date.clone();
      dateTime.hour(Number(timeTmp[0]));
      dateTime.minute(Number(timeTmp[1]));
      Ember.set(this.talent, this.dateKey, dateTime.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'));

      if (this.onChange) {
        this.onChange();
      }
    },
    initTimepicker: function initTimepicker() {
      var _this2 = this;

      setTimeout(function () {
        _this2.$('.select').formSelect();
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      var hours = this.dateKey == 'trialPeriodEndDate' ? 0 : 9;
      var minutes = 0;

      if (this.talent[this.dateKey]) {
        Ember.set(this, 'date', moment(this.talent[this.dateKey]));
        hours = moment(this.talent[this.dateKey]).hours();
        minutes = moment(this.talent[this.dateKey]).minutes();
      } else {
        Ember.set(this, 'date', '');
      }

      Ember.set(this, 'time', (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.initDatepicker();
      this.initTimepicker();
    },
    actions: {
      onTimeChanged: function onTimeChanged(time) {
        Ember.set(this, 'time', time);
      }
    }
  });

  _exports.default = _default;
});