define("teelt-frontend/templates/board/email/email-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kLdpX4C1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"email-detail\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"section-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"g-section-title\"],[9],[0,\"\\n            \"],[7,\"h1\"],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"emails.edit_template\"],null]],null],false],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n    \\n        \"],[1,[27,\"emails/add-email-form\",null,[[\"email\"],[[23,[\"model\",\"email\"]]]]],false],[0,\"\\n\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/email/email-edit.hbs"
    }
  });

  _exports.default = _default;
});