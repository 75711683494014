define("teelt-frontend/components/talents/talent-detail-block/talent-custom-field-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    uiUtils: Ember.inject.service('ui-utils'),
    fileUploadService: Ember.inject.service('file-upload'),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.canCloseWithoutSaving) this.canCloseWithoutSaving(false);
    },
    actions: {
      onInputChanged: function onInputChanged() {
        if (this.canCloseWithoutSaving) this.canCloseWithoutSaving(false);
      },
      onFileChanged: function onFileChanged(field, file) {
        var _this = this;

        if (!file) {
          this.talent.customFields.forEach(function (talentField) {
            if (talentField.id === field.id) {
              Ember.set(talentField.user_customfield, 'fullValue', null);
            }
          });
          return;
        }

        Ember.set(this, 'blockSave', true);
        var customName = this.talent.name + '_' + field.name;
        this.fileUploadService.signAndUpload(file, true, function (e) {
          return _this.onProgress(e);
        }, customName).then(function (fileUrl) {
          Ember.set(_this, 'fullValue', fileUrl);
          if (_this.onFileUploaded) _this.onFileUploaded(fileUrl);

          _this.talent.customFields.forEach(function (talentField) {
            if (talentField.id === field.id) {
              Ember.set(talentField.user_customfield, 'fullValue', fileUrl);
            }
          });
        }).finally(function () {
          Ember.set(_this, 'blockSave', false);
        });
      }
    },
    onProgress: function onProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'progress', progress);
      }
    }
  });

  _exports.default = _default;
});