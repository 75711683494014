define("teelt-frontend/routes/board/form/form-edit", ["exports", "teelt-frontend/routes/parent/confirm-before-transition"], function (_exports, _confirmBeforeTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _confirmBeforeTransition.default.extend({
    queryParams: {
      page: 1,
      pageOnly: false
    },
    model: function model(params) {
      var parentModel = this._super(params);

      parentModel.formId = params.form_id;
      parentModel.form = this.store.findRecord('form', params.form_id, {
        reload: true,
        include: "withData"
      });
      parentModel.initPage = params.page;
      parentModel.pageOnly = params.pageOnly;
      return Ember.RSVP.hash(parentModel);
    }
  });

  _exports.default = _default;
});