define("teelt-frontend/routes/board/event/campaign-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        campaignId: params.campaign_id,
        campaign: this.store.findRecord('events/campaign', params.campaign_id, {
          reload: true
        })
      });
    },
    actions: {
      close: function close() {
        this.transitionTo('board.events');
      }
    }
  });

  _exports.default = _default;
});