define("teelt-frontend/components/session/talent-detail-calendar", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talent-detail-calendar'],
    defaultOneToOneUrl: _environment.default.APP.API_HOST + "/events/onetoone",
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var currentOneTOneEvents = {};

      if (this.talent.events) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.talent.events[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var event = _step.value;
            currentOneTOneEvents[event.id] = event;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      Ember.set(this, "currentOneTOneEvents", currentOneTOneEvents);
    },
    saveEvent: function saveEvent(event) {
      var _this = this;

      var currentEvent = this.currentOneTOneEvents[event.id];

      if (!event.participantIds) {
        event.participantIds = [];
      }

      if (!event.participants) {
        event.participants = [];
      } // Add talent as participant


      if (event.participantIds.indexOf(this.talent.id) < 0) {
        event.participantIds.push(this.talent.id);
        event.participants.push(this.talent);
      } // New Event (Warning a new event have an internal ID (auto generated) and we keep the DB id in currentOneToOneEvent[internalId].id)


      if (!currentEvent) {
        this.ajax.requestWithBodyPromise(this.defaultOneToOneUrl, 'POST', "application/json", JSON.stringify(event)).then(function (newEvent) {
          Ember.set(_this.currentOneTOneEvents, "" + event.id, newEvent);
        });
      } // Update Event
      else {
          var eventId = currentEvent.id;
          this.ajax.requestWithBodyPromise(this.defaultOneToOneUrl + "/" + eventId, 'PATCH', "application/json", JSON.stringify(event)).then(function () {
            Ember.set(_this.currentOneTOneEvents, "" + event.id, event);
          });
        }
    },
    removeEvent: function removeEvent(event) {
      var currentEvent = this.currentOneTOneEvents[event.id];

      if (currentEvent) {
        var eventId = currentEvent.id;
        this.ajax.requestWithBodyPromise(this.defaultOneToOneUrl + "/" + eventId, 'DELETE').then(function () {});
      }
    },
    actions: {
      addEvent: function addEvent() {
        this.eventBus.trigger('NEW_EVENT_POPUP');
      },
      onEventDrop: function onEventDrop(event) {
        this.saveEvent(event);
      },
      onNewEvent: function onNewEvent(event) {
        this.saveEvent(event);
      },
      onEventResize: function onEventResize(event) {
        this.saveEvent(event);
      },
      onUpdateEvent: function onUpdateEvent(event) {
        this.saveEvent(event);
      },
      onRemoveEvent: function onRemoveEvent(event) {
        this.removeEvent(event);
      }
    }
  });

  _exports.default = _default;
});