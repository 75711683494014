define("teelt-frontend/templates/components/utils/searchable-insertable-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oP7iwjck",
    "block": "{\"symbols\":[\"value\"],\"statements\":[[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n  \"],[7,\"input\"],[11,\"class\",\"select-dropdown clickable\"],[11,\"readonly\",\"true\"],[11,\"type\",\"text\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showList\"]]],null],[27,\"not\",[[23,[\"showList\"]]],null]]],[9],[10],[0,\"\\n  \"],[7,\"svg\"],[11,\"class\",\"caret\"],[11,\"height\",\"24\"],[11,\"viewBox\",\"0 0 24 24\"],[11,\"width\",\"24\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[9],[7,\"path\"],[11,\"d\",\"M7 10l5 5 5-5z\"],[9],[10],[7,\"path\"],[11,\"d\",\"M0 0h24v24H0z\"],[11,\"fill\",\"none\"],[9],[10],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"showList\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\"],[11,\"class\",\"list-values\"],[9],[0,\"\\n    \"],[7,\"li\"],[9],[1,[27,\"input\",null,[[\"value\",\"placeholder\",\"input\"],[[23,[\"search\"]],[27,\"t\",[\"content.search_or_add\"],null],[27,\"action\",[[22,0,[]],\"onSearchChange\"],null]]]],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"selected\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"clickable\"],[3,\"action\",[[22,0,[]],\"select\"]],[9],[7,\"a\"],[11,\"href\",\"#\"],[9],[1,[27,\"t\",[\"word.clear\"],null],false],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"search\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[11,\"class\",\"clickable\"],[3,\"action\",[[22,0,[]],\"addNewValue\",[23,[\"search\"]]]],[9],[1,[27,\"t\",[\"content.add_tag\"],[[\"tag\"],[[23,[\"search\"]]]]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[23,[\"filteredValues\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"clickable\"],[3,\"action\",[[22,0,[]],\"select\",[22,1,[]]]],[9],[1,[22,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/searchable-insertable-select.hbs"
    }
  });

  _exports.default = _default;
});