define("teelt-frontend/components/form/forms-block", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['forms-block'],
    userService: Ember.inject.service(),
    blockByPeriod: Ember.computed('completed', 'talentCurrentPeriod', 'form', function () {
      if (this.completed) return false;

      if (this.talentCurrentPeriod != undefined && this.form.periodIndex != undefined) {
        return this.talentCurrentPeriod < this.form.periodIndex;
      }

      return false;
    }),
    blockByStart: Ember.computed('completed', 'form', 'talent', function () {
      if (this.completed) return false;

      if (this.form.startOffsetUnit && this.form.startOffsetDirection) {
        var offset = this.form.startOffset;

        if (this.form.startOffsetDirection.toLowerCase() == 'before') {
          offset = -1 * offset;
        }

        var triggerDate = this.periods[this.talentCurrentPeriod].triggerDate;

        if (triggerDate == 'assignationDate') {
          triggerDate = 'templateAssignationDate';
        }

        var date = (0, _moment.default)(this.talent[triggerDate]);

        switch (this.form.startOffsetUnit.toLowerCase()) {
          case 'hour':
            date.add(offset, 'hours');
            break;

          case 'day':
            date.add(offset, 'days');
            break;

          case 'month':
            date.add(offset, 'months');
            break;
        }

        if (this.form.startOffsetUnit.toLowerCase() == 'day' || this.form.startOffsetUnit.toLowerCase() == 'month') {
          var now = (0, _moment.default)().startOf('day');
          var dateToCompare = (0, _moment.default)(date).startOf('day');

          if (now.isBefore(dateToCompare)) {
            return true;
          }
        } else {
          var _now = (0, _moment.default)();

          if (_now.isBefore(date)) {
            return true;
          }
        }

        return false;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.talent) {
        var periods = this.userService.getTalentPeriods(this.talent);
        Ember.set(this, 'periods', periods);
        Ember.set(this, 'talentCurrentPeriod', this.userService.getTalentCurrentPeriod(this.talent, periods));
      }
    },
    actions: {
      onClick: function onClick() {
        if (this.blockByPeriod) return;
        if (this.blockByStart) return;
        if (this.actionAction) this.actionAction(this.form);
      }
    }
  });

  _exports.default = _default;
});