define("teelt-frontend/templates/components/utils/loading-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IwHbfmdf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"popup-background content-conditions-popup-background\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"content-conditions-popup g-window\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"#0\"],[11,\"class\",\"g-window-close\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showLoadingModal\"]]],null],false],[[\"bubbles\"],[false]]],[9],[7,\"i\"],[11,\"class\",\"material-icons grey-text\"],[9],[0,\"clear\"],[10],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[1,[21,\"loadingMessage\"],false],[10],[0,\"\\n      \"],[1,[21,\"icon/downloading-loader-svg\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/loading-popup.hbs"
    }
  });

  _exports.default = _default;
});