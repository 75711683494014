define("teelt-frontend/components/company/presentation-text-image-modify-modal", ["exports", "teelt-frontend/config/environment", "teelt-frontend/utils/object-util"], function (_exports, _environment, _objectUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    fileUploadService: Ember.inject.service('file-upload'),
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'editableHomepage', (0, _objectUtil.default)().clone(this.homepage));
    },
    didInsertElement: function didInsertElement() {
      var textareas = document.querySelector('.materialize-textarea');

      if (textareas) {
        M.textareaAutoResize(document.querySelector('.materialize-textarea'));
      }
    },
    toggleDisplayPenIconOnEdit: function toggleDisplayPenIconOnEdit(element, willDisplay) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = element.parentElement.children[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var child = _step.value;

          if (child.classList.contains('icon-svg')) {
            child.style.visibility = willDisplay ? 'visible' : 'hidden';
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },
    actions: {
      save: function save() {
        var _this = this;

        if (this.blockSave) return;
        var path = _environment.default.APP.API_HOST + '/homepages/' + this.editableHomepage.id;
        this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(this.editableHomepage)).then(function (homepage) {
          Ember.set(_this.homepage, 'introTitle', homepage.introTitle);
          Ember.set(_this.homepage, 'introMessage', homepage.introMessage);
          Ember.set(_this.homepage, 'introImageUrl', homepage.introImageUrl);
          Ember.set(_this, 'show', false);
        });
      },
      onClose: function onClose() {
        Ember.set(this, 'show', false);
      },
      onMediaChanged: function onMediaChanged(file) {
        var _this2 = this;

        if (!file) return;
        Ember.set(this, 'blockSave', true);
        this.fileUploadService.signAndUpload(file, false, function (e) {
          return _this2.onMediaProgress(e);
        }).then(function (fileUrl) {
          Ember.set(_this2.editableHomepage, 'introImageUrl', fileUrl);
        }).finally(function () {
          Ember.set(_this2, 'blockSave', false);
        });
      }
    },
    onMediaProgress: function onMediaProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'mediaProgress', progress);
      }
    }
  });

  _exports.default = _default;
});