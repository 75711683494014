define("teelt-frontend/templates/components/talents/talents-periods-slick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HgLysXjM",
    "block": "{\"symbols\":[\"period\"],\"statements\":[[7,\"div\"],[11,\"class\",\"slick-container\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"periods\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"talents/talents-periods-slick-card\",null,[[\"period\",\"highlight\",\"actionLabel\",\"actionAction\"],[[22,1,[]],[27,\"eq\",[[22,1,[\"highlight\"]],true],null],[27,\"t\",[\"talent.discover_actions\"],null],[27,\"action\",[[22,0,[]],\"toggleBottomPannel\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talents-periods-slick.hbs"
    }
  });

  _exports.default = _default;
});