define("teelt-frontend/components/content/content-form-sign-preview", ["exports", "pdfjs-dist", "teelt-frontend/config/environment"], function (_exports, _pdfjsDist, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['content-form-sign-preview'],
    fileUploadService: Ember.inject.service('file-upload'),
    ajax: Ember.inject.service(),
    signs: {},
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      _pdfjsDist.default.GlobalWorkerOptions.workerSrc = '/assets/pdf.worker.min.3.11.174.js';

      if (this.content.parameters) {
        Ember.set(this, 'signs', JSON.parse(this.content.parameters));
      }

      if (this.content.fileOrigin == 'personal') {
        this.ajax.requestPromise(_environment.default.APP.API_HOST + '/custom-fields?type=file', 'GET').then(function (customFields) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = customFields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var customField = _step.value;

              if (customField.id == _this2.content.customFieldId) {
                Ember.set(_this2, 'customField', customField);
                break;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        });
      } else if (this.content.documentLink) {
        var urlObject = new URL(this.content.documentLink);
        var filePath = urlObject.pathname;
        var fileName = filePath.split('/').pop();
        Ember.set(this, 'fileName', fileName);
        this.fileUploadService.getObjectBase64(this.content.documentLink).then(function (res) {
          var _this = _this2;
          setTimeout(function () {
            var decodedData = atob(res.data);
            var pdfData = new Uint8Array(decodedData.length);

            for (var i = 0; i < decodedData.length; i++) {
              pdfData[i] = decodedData.charCodeAt(i);
            }

            _pdfjsDist.default.getDocument(pdfData).promise.then(function (pdf) {
              Ember.set(_this, 'pageNb', pdf.numPages);
              Ember.set(_this, 'pdf', pdf);

              _this.showPage(1);
            });
          });
        }).catch(function (err) {
          _this2.customErrorService.handleAjaxError(err);
        });
      }
    },
    showPage: function showPage(nb) {
      var _this3 = this;

      var pdfContainer = document.getElementById("pdf-container");
      var oldCanvas = pdfContainer.querySelector("canvas");

      if (oldCanvas) {
        pdfContainer.removeChild(oldCanvas);
      }

      var zoom = $('body').css("zoom");

      if (!zoom) {
        zoom = 1.0;
      }

      Ember.set(this, 'currentPage', nb);
      this.pdf.getPage(nb).then(function (page) {
        var viewportWidth = page.getViewport({
          scale: 1.0
        }).width;
        var viewportHeight = page.getViewport({
          scale: 1.0
        }).height;
        var pdfContainerWidth = pdfContainer.clientWidth;
        var scale = pdfContainerWidth / viewportWidth;
        Ember.set(_this3, "scale", scale);
        var viewport = page.getViewport({
          scale: scale
        });
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        canvas.id = 'pdf-canvas';
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        pdfContainer.appendChild(canvas);
        page.render({
          canvasContext: context,
          viewport: viewport
        }).promise.then(function () {
          var pageSigns = _this3.signs['page' + nb];
          Ember.set(_this3, 'pageSigns', undefined);
          Ember.set(_this3, 'pageSigns', pageSigns);

          if (pageSigns) {
            for (var i = 0; i < pageSigns.length; i++) {
              var sign = pageSigns[i];
              var x = sign.x;
              var y = sign.y;
              var color = "#000000";

              if (sign.who.role) {
                switch (sign.who.role) {
                  case "ROLE_TALENT":
                    color = "#4DB9FA";
                    break;

                  case "ROLE_MANAGER":
                    color = "#7DDFC3";
                    break;

                  case "ROLE_RH":
                    color = "#FFCF5C";
                    break;

                  case "ROLE_CUSTOM_OFFICE_MANAGER":
                    color = "#7CEBED";
                    break;

                  case "ROLE_ADMINISTRATIVE":
                    color = "#FFC7A6";
                    break;
                }
              }

              context.strokeStyle = color;
              context.lineWidth = 3;
              context.strokeRect(x * _this3.scale, (viewportHeight - y) * _this3.scale - 25, 120, 30);
              context.font = "12px Arial";
              context.textAlign = "center";
              context.textBaseline = "middle";
              context.fillText(i + 1, x * _this3.scale + 10, (viewportHeight - y) * _this3.scale - 10);
            }
          }
        });
      });
    },
    actions: {
      nextPage: function nextPage() {
        if (this.currentPage < this.pageNb) {
          this.showPage(this.currentPage + 1);
        }
      },
      previousPage: function previousPage() {
        if (this.currentPage > 1) {
          this.showPage(this.currentPage - 1);
        }
      }
    }
  });

  _exports.default = _default;
});