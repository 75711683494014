define("teelt-frontend/templates/components/utils/current-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xtrn37WK",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"accreditations\"]],[27,\"gt\",[[23,[\"accreditations\",\"length\"]],1],null]],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"utils/searchable-select\",null,[[\"values\",\"multiple\",\"onValueChange\",\"customClass\",\"role\"],[[23,[\"accreditations\"]],false,[27,\"action\",[[22,0,[]],\"onRoleChanged\"],null],\"switch-role-dropdown\",[23,[\"currentRole\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/current-role.hbs"
    }
  });

  _exports.default = _default;
});