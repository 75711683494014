define("teelt-frontend/components/utils/expandable-section-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['expandable-section-title'],
    classNameBindings: ['isExpanded'],
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.leftArrow) {
        Ember.set(this, 'leftArrow', false);
      }
    },
    actions: {
      toggleExpand: function toggleExpand() {
        if (!this.expandable) return;
        Ember.set(this, 'isExpanded', !this.isExpanded);
      },
      onFilter: function onFilter(filter, value) {
        if (this.onFilter) this.onFilter(filter, value);
      }
    }
  });

  _exports.default = _default;
});