define("teelt-frontend/templates/components/task/utils/custom-period-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k+qjVsAQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"period\"]],\"BEFORE\"],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"t\",[[27,\"if\",[[23,[\"currentUserCompany\",\"periodBeforeNameKey\"]],[23,[\"currentUserCompany\",\"periodBeforeNameKey\"]],\"task.period.BEFORE\"],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"period\"]],\"FIRST\"],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"t\",[[27,\"if\",[[23,[\"currentUserCompany\",\"periodFirstNameKey\"]],[23,[\"currentUserCompany\",\"periodFirstNameKey\"]],\"task.period.FIRST\"],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"period\"]],\"AFTER\"],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"t\",[[27,\"if\",[[23,[\"currentUserCompany\",\"periodAfterNameKey\"]],[23,[\"currentUserCompany\",\"periodAfterNameKey\"]],\"task.period.AFTER\"],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"t\",[[27,\"concat\",[\"task.period.\",[23,[\"period\"]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/task/utils/custom-period-name.hbs"
    }
  });

  _exports.default = _default;
});