define("teelt-frontend/components/ai/modal-chat-completion", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    allMessages: [],
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'flags', ['fr', 'us', 'de', 'es', 'it']);
      var selectedFlag = this.intl.locale[0];
      var selectedLang = selectedFlag;

      if (this.flags.indexOf(selectedFlag) < 0) {
        selectedFlag = 'us';
        selectedLang = 'en';
      }

      Ember.set(this, 'selectedFlag', selectedFlag);
      Ember.set(this, 'selectedLang', selectedLang);
      this.getConsumption();
    },
    getConsumption: function getConsumption() {
      var _this = this;

      var path = _environment.default.APP.API_HOST + "/ai/consumption/" + this.session.data.authenticated.user.company.id;
      Ember.set(this, 'showConsumption', false);
      this.ajax.requestPromise(path).then(function (result) {
        if (result) {
          Ember.set(_this, 'consumption', result.consumption);
          Ember.set(_this, 'showConsumption', true);
        }
      });
    },
    createUserMessage: function createUserMessage(userMessage) {
      var container = document.getElementById('output');
      var newMessage = document.createElement('div');
      container.appendChild(newMessage);
      newMessage.className = "message-user";
      var icon = document.createElement('span');
      newMessage.appendChild(icon);
      icon.className = "material-icons";
      icon.textContent = "chat_bubble";
      var message = document.createElement('span');
      message.className = 'message';
      var messagePre = document.createElement('pre');
      messagePre.textContent = userMessage;
      message.appendChild(messagePre);
      newMessage.appendChild(message);
    },
    createIAMessage: function createIAMessage(iaMessage) {
      var container = document.getElementById('output');
      var newMessage = document.createElement('div');
      container.appendChild(newMessage);
      newMessage.className = "message-assistant";
      var icon = document.createElement('span');
      newMessage.appendChild(icon);
      icon.className = "material-icons";
      icon.textContent = "lightbulb_outline";
      var message = document.createElement('span');
      message.className = 'message';
      var messagePre = document.createElement('pre');
      messagePre.textContent = iaMessage;
      message.appendChild(messagePre);
      newMessage.appendChild(message);
      return messagePre;
    },
    actions: {
      handleKeyUp: function handleKeyUp(value, event) {
        if (event.key === 'Enter' && !event.shiftKey) {
          this.send('chatCompletion');
        }
      },
      chatCompletion: function chatCompletion() {
        var _this2 = this;

        if (this.question && !this.waitingAI) {
          Ember.set(this, 'waitingAI', true);
          var path = _environment.default.APP.API_HOST + "/ai/notification/stream/create";
          var request = {
            lang: this.selectedLang,
            message: this.question,
            oldMessages: JSON.parse(JSON.stringify(this.allMessages))
          };
          this.allMessages.push({
            role: 'user',
            content: this.question
          });
          this.createUserMessage(this.question);
          var iaMessage = this.createIAMessage(" ... ");
          Ember.set(this, 'question', undefined);
          this.ajax.requestWithBodyPromise(path, 'POST', 'application/json', JSON.stringify(request)).then(function (token) {
            path = _environment.default.APP.API_HOST + "/ai/notification/stream?token=" + token;
            var eventSource = new EventSource(path);

            eventSource.onmessage = function (event) {
              var data = event.data.substring(6);

              if (data === 'END') {
                eventSource.close();
                Ember.set(_this2, 'waitingAI', false);

                _this2.allMessages.push({
                  role: 'assistant',
                  content: iaMessage.textContent
                });

                return;
              }

              iaMessage.textContent = data;
              setTimeout(function () {
                var chat = document.getElementById('chat');
                chat.scrollTop = chat.scrollHeight;
              });
            };
          });
        }
      },
      onSelectFlag: function onSelectFlag(flag) {
        switch (flag) {
          case 'us':
            Ember.set(this, 'selectedLang', 'en');
            break;

          default:
            Ember.set(this, 'selectedLang', flag);
        }
      },
      copyClipboard: function copyClipboard(message) {
        var _this3 = this;

        navigator.clipboard.writeText(message).then(function () {
          _this3.notify.info(_this3.intl.t("word.copy_clipboard"));
        }).catch(function (err) {
          _this3.notify.info(_this3.intl.t("word.copy_clipboard"));
        });
      },
      clear: function clear() {
        Ember.set(this, 'allMessages', undefined);
        var container = document.getElementById('output');
        container.innerHTML = '';
      }
    }
  });

  _exports.default = _default;
});