define("teelt-frontend/components/settings/period-new", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['period-new'],
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    tempPeriod: {
      offset: 0,
      duration: 0
    },
    periodFrom: Ember.computed('period.offset', 'period.duration', function () {
      if (this.period.offset < 0) {
        return this.intl.t('settings.periods.table.value.startEnd.before', {
          nb: Math.abs(this.period.offset)
        });
      } else if (this.period.offset > 0) {
        return this.intl.t('settings.periods.table.value.startEnd.after', {
          nb: this.period.offset
        });
      } else {
        return this.intl.t('settings.periods.table.value.startEnd.atDay');
      }
    }),
    periodTo: Ember.computed('period.offset', 'period.duration', function () {
      var offset = this.period.offset + this.period.duration;

      if (offset < 0) {
        return this.intl.t('settings.periods.table.value.startEnd.before', {
          nb: Math.abs(offset)
        });
      } else if (offset > 0) {
        return this.intl.t('settings.periods.table.value.startEnd.after', {
          nb: offset
        });
      } else {
        return this.intl.t('settings.periods.table.value.startEnd.atDay');
      }
    }),
    triggerValues: ['welcomeDate', 'endDate', 'migrationDate'],
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.period) {
        var offset = this.triggerDate == 'assignationDate' ? 0 : -10;
        var period = this.store.createRecord('period', {
          triggerDate: this.triggerDate ? this.triggerDate : 'welcomeDate',
          offset: offset,
          duration: 20
        });
        Ember.set(this, 'period', period);
      }

      if (this.period && this.period.id) {
        this.tempPeriod.offset = this.period.offset;
        this.tempPeriod.duration = this.period.duration;
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var self = this;
      var slider = document.getElementById('period-slider');
      var start = self.period && self.period.id ? self.period.offset : self.period.triggerDate == 'assignationDate' ? 0 : -20;
      var end = self.period && self.period.id ? self.period.offset + self.period.duration : self.period.triggerDate == 'assignationDate' ? 30 : 20;
      noUiSlider.create(slider, {
        start: [start, end],
        step: 1,
        connect: true,
        range: {
          'min': -100,
          'max': 365
        }
      });
      slider.noUiSlider.on('update', function (values) {
        var offset = Number(values[0]);

        if (self.period.triggerDate == 'assignationDate' && offset < 0) {
          offset = 0;
          slider.noUiSlider.set(0);
        }

        var duration = Math.abs(Number(values[1]) - offset);
        Ember.set(self.period, 'offset', offset);
        Ember.set(self.period, 'duration', duration);
      });
    },
    actions: {
      close: function close() {
        Ember.set(this.period, 'offset', this.tempPeriod.offset);
        Ember.set(this.period, 'duration', this.tempPeriod.duration);
        var type = this.period.triggerDate.replace("Date", "");
        this.router.transitionTo('board.setting.periods', type);
      },
      save: function save() {
        var _this = this;

        this.period.save().then(function () {
          var type = _this.period.triggerDate.replace("Date", "");

          _this.router.transitionTo('board.setting.periods', type);
        });
      },
      translatePeriod: function translatePeriod(period) {
        Ember.set(this, "updatePeriod", period);
        Ember.set(this, 'showTranslateCustomModal', true);
        Ember.set(this, 'showActions', false);
      },
      onFinish: function onFinish(response) {
        Ember.set(this.period, 'name', response);
      }
    }
  });

  _exports.default = _default;
});