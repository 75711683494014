define("teelt-frontend/components/activity/activity-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['activity-list'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (typeof this.activity.customParams === "string") {
        var activity = this.activity;
        activity.customParams = JSON.parse(activity.customParams);
        Ember.set(this, 'activity', activity);
      }
    },
    actions: {
      openNotification: function openNotification(notifId) {
        var _this = this;

        this.store.findRecord("notification", notifId, {
          reload: true
        }).then(function (result) {
          Ember.set(_this, "preview", result);
          Ember.set(_this, "showPreviewDialog", true);
        });
      },
      onClickOverlay: function onClickOverlay() {
        Ember.set(this, 'showPreviewDialog', false);
      },
      openTask: function openTask(taskId) {
        var _this2 = this;

        this.store.findRecord("task", taskId, {
          reload: true
        }).then(function (result) {
          Ember.set(_this2, "task", result);
          Ember.set(_this2, "showTaskPreview", true);
        });
      }
    }
  });

  _exports.default = _default;
});