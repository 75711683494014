define("teelt-frontend/components/talents/modal/new-talent-modal", ["exports", "libphonenumber-js", "teelt-frontend/config/environment", "jquery"], function (_exports, _libphonenumberJs, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend((_EmberComponent$exte = {
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service()
  }, _defineProperty(_EmberComponent$exte, "store", Ember.inject.service()), _defineProperty(_EmberComponent$exte, "customErrorService", Ember.inject.service('custom-error')), _defineProperty(_EmberComponent$exte, "userService", Ember.inject.service('user-service')), _defineProperty(_EmberComponent$exte, "uiUtils", Ember.inject.service('ui-utils')), _defineProperty(_EmberComponent$exte, "fileUploadService", Ember.inject.service('file-upload')), _defineProperty(_EmberComponent$exte, "step", 1), _defineProperty(_EmberComponent$exte, "step2AdditionnalFields", {
    dates: [],
    referents: [],
    referentRoleCustoms: [],
    experienceFields: [],
    talentFields: []
  }), _defineProperty(_EmberComponent$exte, "baseRoleCustomUsersPath", _environment.default.APP.API_HOST + "/roles"), _defineProperty(_EmberComponent$exte, "init", function init() {
    var _this = this;

    this._super.apply(this, arguments);

    Ember.set(this, 'contracts', [{
      key: 'cdi',
      name: this.intl.t('contracts.cdi')
    }, {
      key: 'cdd',
      name: this.intl.t('contracts.cdd')
    }, {
      key: 'internship',
      name: this.intl.t('contracts.internship')
    }, {
      key: 'apprenticeship',
      name: this.intl.t('contracts.apprenticeship')
    }, {
      key: 'professional_training_contract_parttime',
      name: this.intl.t('contracts.professional_training_contract_parttime')
    }, {
      key: 'professional_training_contract_fulltime',
      name: this.intl.t('contracts.professional_training_contract_fulltime')
    }, {
      key: 'mission',
      name: this.intl.t('contracts.mission')
    }, {
      key: 'freelance',
      name: this.intl.t('contracts.freelance')
    }, {
      key: 'external',
      name: this.intl.t('contracts.external')
    }, {
      key: 'secondment',
      name: this.intl.t('contracts.secondment')
    }, {
      key: 'cdd_less_than_3_months',
      name: this.intl.t('contracts.cdd_less_than_3_months')
    }, {
      key: 'cdd_more_than_3_months',
      name: this.intl.t('contracts.cdd_more_than_3_months')
    }, {
      key: 'senior',
      name: this.intl.t('contracts.senior')
    }]);
    this.store.findRecord('company', this.session.data.authenticated.user.company.id, {
      reload: true
    }).then(function (company) {
      var additionalFieldsForAddingTalent = company.additionalFieldsForAddingTalent;

      if (additionalFieldsForAddingTalent) {
        Ember.set(_this, 'step3AdditionnalFields', []);
        var datesRequiredFields = [{
          requiredKey: 'endDateRequired',
          label: _this.intl.t('talent.table.keys.endDate'),
          dateKey: 'endDate'
        }, {
          requiredKey: 'migrationDateRequired',
          label: _this.intl.t('talent.table.keys.migrationDate'),
          dateKey: 'migrationDate'
        }, {
          requiredKey: 'trialPeriodEndDateRequired',
          label: _this.intl.t('talent.table.keys.trialPeriodEndDate'),
          dateKey: 'trialPeriodEndDate'
        }];
        var referentsRequiredFields = [{
          requiredKey: 'officeManagerIdsRequired',
          role: 'officeManager',
          searchPath: _this.userService.getUsersSearchPathForSelect('ROLE_CUSTOM_OFFICE_MANAGER')
        }, {
          requiredKey: 'buddyIdRequired',
          role: 'buddy',
          searchPath: _environment.default.APP.API_HOST + '/users'
        }];
        var experienceRequiredFields = [{
          requiredKey: 'templateIdRequired',
          label: _this.intl.t('talent.table.keys.template'),
          searchPath: _environment.default.APP.API_HOST + '/templates?forceAll=true',
          onValueChange: 'onTemplateChanged',
          key: 'template'
        }, {
          requiredKey: 'campaignIdRequired',
          label: _this.intl.t('talent.table.keys.campaign'),
          searchPath: _environment.default.APP.API_HOST + '/events/campaigns',
          onValueChange: 'onCampaignChanged',
          key: 'campaign'
        }];
        var talentRequiredFields = [{
          requiredKey: 'positionIdRequired',
          label: _this.intl.t('talent.table.keys.position'),
          key: 'position'
        }, {
          requiredKey: 'contractIdRequired',
          label: _this.intl.t('talent.table.keys.contract'),
          key: 'contract'
        }, {
          requiredKey: 'teamIdRequired',
          label: _this.intl.t('talent.table.keys.team'),
          key: 'team'
        }];

        _this.ajax.requestPromise(_environment.default.APP.API_HOST + '/companies/required-user-fields', 'GET').then(function (requiredFields) {
          datesRequiredFields.forEach(function (field) {
            if (requiredFields[field.requiredKey] && !_this.step2AdditionnalFields.dates.find(function (f) {
              return f.requiredKey === field.requiredKey;
            })) {
              _this.step2AdditionnalFields.dates.push(field);
            }
          });
          referentsRequiredFields.forEach(function (field) {
            if (requiredFields[field.requiredKey] && !_this.step2AdditionnalFields.referents.find(function (f) {
              return f.requiredKey === field.requiredKey;
            })) {
              _this.step2AdditionnalFields.referents.push(field);
            }
          });
          experienceRequiredFields.forEach(function (field) {
            if (requiredFields[field.requiredKey] && !_this.step2AdditionnalFields.experienceFields.find(function (f) {
              return f.requiredKey === field.requiredKey;
            })) {
              _this.step2AdditionnalFields.experienceFields.push(field);
            }
          });
          talentRequiredFields.forEach(function (field) {
            if (requiredFields[field.requiredKey] && !_this.step2AdditionnalFields.talentFields.find(function (f) {
              return f.requiredKey === field.requiredKey;
            })) {
              _this.step2AdditionnalFields.talentFields.push(field);
            }
          });

          _this.setRoleCustomReferents().then(function () {
            if (_this.hasStep2Fields()) {
              Ember.set(_this, 'showStep2', true);
            }
          }).catch(function (err) {
            _this.customErrorService.handleAjaxError(err);
          }).finally(function () {
            _this.setCustomFields().then(function () {
              if (_this.step3AdditionnalFields.length !== 0) {
                Ember.set(_this, 'showStep3', true);
              }
            }).catch(function (err) {
              _this.customErrorService.handleAjaxError(err);
            });
          });
        });
      }
    });
    var talent = this.store.createRecord('user', {
      position: {},
      experience: {},
      contract: {},
      team: {},
      lang: this.intl.locale[0].substring(0, 2),
      customFields: []
    });
    Ember.set(this, 'talent', talent);
    this.resetErrors();
    var path = this.userService.getUsersSearchPathForSelect('ROLE_MANAGER');
    Ember.set(this, "managerPath", path);
  }), _defineProperty(_EmberComponent$exte, "didInsertElement", function didInsertElement() {
    this._super.apply(this, arguments);

    setTimeout(function () {
      (0, _jquery.default)('.select-contract').formSelect();
    }, 100);
  }), _defineProperty(_EmberComponent$exte, "validateEmail", function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }), _defineProperty(_EmberComponent$exte, "validatePhoneNumber", function validatePhoneNumber() {
    if (!this.talent.phoneNumber) return;
    var country = this.getCountryForPhoneCode(this.talent.phoneCode).toUpperCase();
    var phoneNumberArray = this.talent.phoneNumber.split(' ');
    Ember.set(this.talent, "phoneNumber", phoneNumberArray.join(''));

    if (country === "FR") {
      return /^\d+$/.test(this.talent.phoneNumber) && (0, _libphonenumberJs.isValidPhoneNumber)(phoneNumberArray.join(''), country);
    }

    return (0, _libphonenumberJs.isValidPhoneNumber)(phoneNumberArray.join(''), country);
  }), _defineProperty(_EmberComponent$exte, "getCountryForPhoneCode", function getCountryForPhoneCode(phoneCode) {
    if (!phoneCode) {
      return;
    }

    var countryData = window.intlTelInputGlobals.getCountryData();
    var phoneCodeIso = phoneCode.substring(1);
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = countryData[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var country = _step.value;

        if (country.dialCode == phoneCodeIso) {
          return country.iso2;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
  }), _defineProperty(_EmberComponent$exte, "resetErrors", function resetErrors() {
    Ember.set(this, 'firstnameIsNull', false);
    Ember.set(this, 'lastnameIsNull', false);
    Ember.set(this, 'proEmailIsNull', false);
    Ember.set(this, 'invalidPhoneNumber', false);
    Ember.set(this, 'invalidProEmailError', false);
    Ember.set(this, 'invalidPersoEmailError', false);
  }), _defineProperty(_EmberComponent$exte, "validateStep1Form", function validateStep1Form() {
    this.resetErrors();
    var isOk = true;

    if (!this.talent.firstname || this.talent.firstname.length == 0) {
      Ember.set(this, 'firstnameIsNull', true);
      isOk = false;
    }

    if (!this.talent.lastname || this.talent.lastname.length == 0) {
      Ember.set(this, 'lastnameIsNull', true);
      isOk = false;
    }

    if (!this.talent.proEmail || this.talent.proEmail.length == 0) {
      Ember.set(this, 'proEmailIsNull', true);
      isOk = false;
    }

    if (this.talent.proEmail && !this.validateEmail(this.talent.proEmail)) {
      Ember.set(this, 'invalidProEmailError', true);
      isOk = false;
    }

    if (this.talent.persoEmail != null && this.talent.persoEmail.length == 0) {
      Ember.set(this.talent, 'persoEmail', null);
    }

    if (this.talent.persoEmail && !this.validateEmail(this.talent.persoEmail)) {
      Ember.set(this, 'invalidPersoEmailError', true);
      isOk = false;
    }

    if (this.proEmailAlreadyExistsError) {
      isOk = false;
    }

    if (this.persoEmailAlreadyExistsError) {
      isOk = false;
    }

    if (this.talent.phoneNumber && !this.validatePhoneNumber()) {
      Ember.set(this, 'invalidPhoneNumber', true);
      isOk = false;
    }

    return isOk;
  }), _defineProperty(_EmberComponent$exte, "initStep2", function initStep2() {
    var _this2 = this;

    setTimeout(function () {
      _this2.store.findAll('statics/position').then(function (positions) {
        _this2.uiUtils.initAutocomplete((0, _jquery.default)('#additionnal-fields-autocomplete-position'), positions, 'name', function () {
          Ember.set(_this2, 'newPosition', false);
        });
      });

      (0, _jquery.default)('.select-contract').formSelect();

      _this2.store.findAll('statics/team').then(function (teams) {
        _this2.uiUtils.initAutocomplete((0, _jquery.default)('#additionnal-fields-autocomplete-team'), teams, 'name', function () {
          Ember.set(_this2, 'newTeam', false);
        });
      });
    }, 100);
  }), _defineProperty(_EmberComponent$exte, "hasStep2Fields", function hasStep2Fields() {
    return this.step2AdditionnalFields.dates.length > 0 || this.step2AdditionnalFields.referents.length > 0 || this.step2AdditionnalFields.experienceFields.length > 0 || this.step2AdditionnalFields.talentFields.length > 0 || this.step2AdditionnalFields.referentRoleCustoms.length > 0;
  }), _defineProperty(_EmberComponent$exte, "setRoleCustomReferents", function setRoleCustomReferents() {
    var _this3 = this;

    return this.ajax.requestPromise(_environment.default.APP.API_HOST + '/roles?consultRoles=true&referent=true', 'GET').then(function (roleCustoms) {
      if (!roleCustoms) return;
      roleCustoms.forEach(function (roleCustom) {
        if (roleCustom.required && !_this3.step2AdditionnalFields.referentRoleCustoms.find(function (field) {
          return field.roleType === roleCustom.roleType;
        })) {
          var requiredRoleCustom = {
            roleType: roleCustom.roleType,
            label: roleCustom.label
          };

          _this3.step2AdditionnalFields.referentRoleCustoms.push(requiredRoleCustom);
        }
      });
    }).catch(function (err) {
      _this3.customErrorService.handleAjaxError(err);
    });
  }), _defineProperty(_EmberComponent$exte, "setCustomFields", function setCustomFields() {
    var _this4 = this;

    return this.ajax.requestPromise(_environment.default.APP.API_HOST + '/custom-fields', 'GET').then(function (customFields) {
      if (!customFields) return;
      customFields.forEach(function (customField) {
        if (customField.required && !_this4.step3AdditionnalFields.some(function (field) {
          return field.id === customField.id;
        })) {
          customField.value = '';

          _this4.step3AdditionnalFields.push(customField);
        }
      });
    }).catch(function (err) {
      _this4.customErrorService.handleAjaxError(err);
    });
  }), _defineProperty(_EmberComponent$exte, "onProgress", function onProgress(field, e) {
    if (e.lengthComputable) {
      this.step3AdditionnalFields.forEach(function (talentField) {
        if (talentField.id === field.id) {
          Ember.set(talentField, 'progress', Math.ceil(e.loaded / e.total * 100));
        }
      });
    }
  }), _defineProperty(_EmberComponent$exte, "actions", {
    onClickOverlay: function onClickOverlay() {},
    proEmailFocusOut: function proEmailFocusOut() {
      var _this5 = this;

      Ember.set(this, 'proEmailAlreadyExistsError', false);

      if (this.validateEmail(this.talent.proEmail)) {
        this.store.query('user', {
          username: this.talent.proEmail
        }).then(function (users) {
          if (users && users.length) {
            Ember.set(_this5, 'proEmailAlreadyExistsError', true);
          }
        });
      }
    },
    persoEmailFocusOut: function persoEmailFocusOut() {
      var _this6 = this;

      Ember.set(this, 'persoEmailAlreadyExistsError', false);

      if (this.validateEmail(this.talent.persoEmail)) {
        this.store.query('user', {
          persoEmail: this.talent.persoEmail
        }).then(function (users) {
          if (users && users.length) {
            Ember.set(_this6, 'persoEmailAlreadyExistsError', true);
          }
        });
      }
    },
    save: function save() {
      var _this7 = this;

      if (this.blockSave) return;
      if (!this.validateStep1Form()) return;

      if (this.step3AdditionnalFields && this.step3AdditionnalFields.length > 0) {
        this.step3AdditionnalFields.forEach(function (talentField) {
          if (!talentField.user_customfield) {
            Ember.set(talentField, 'user_customfield', {});
          }

          talentField.user_customfield = {
            fullValue: talentField.value
          };

          _this7.talent.customFields.pushObject(talentField);
        });
      }

      this.talent.set('username', this.talent.proEmail);

      if (this.userService.isOnlyOfficeManager(this.session.data.authenticated.user)) {
        Ember.set(this.talent, 'officeManagerIds', this.session.data.authenticated.user.id);
      }

      this.talent.save().then(function (talent) {
        // handle OM creating talent for other OM and don't have access after creation.
        var authenticatedUser = _this7.session.data.authenticated.user;

        if (_this7.userService.isRH(authenticatedUser) || _this7.userService.isOfficeManager(authenticatedUser) || _this7.userService.isAdminMRH(authenticatedUser)) {
          _this7.router.transitionTo("board.talent.talent-detail", talent.id);
        } else {
          _this7.router.transitionTo("board.talents");
        }
      }).catch(function (err) {
        console.error(err);

        _this7.customErrorService.handleAjaxError(err);
      });
    },
    generateManagerName: function generateManagerName(result) {
      return this.userService.getTalentName(result);
    },
    onManagerChanged: function onManagerChanged(result) {
      if (!result.key) return;
      Ember.set(this.talent, 'manager', {
        id: result.key
      });
    },
    onOfficeManagerChanged: function onOfficeManagerChanged(results) {
      if (!results || !results.length) return;
      Ember.set(this, 'officeManagersSelected', results);
      var omIds = results.map(function (result) {
        return result.key;
      });
      Ember.set(this.talent, 'officeManagerIds', omIds.join(';'));
    },
    onBuddyChanged: function onBuddyChanged(result) {
      if (!result.key) return;
      Ember.set(this.talent, 'buddy', {
        id: result.key,
        username: result.label
      });
    },
    onReferentRoleCustomChanged: function onReferentRoleCustomChanged(roleType, result) {
      var _this8 = this;

      var userRoleCustomReferents = this.talent.userRoleCustomReferents || [];
      var newReferentIds = result.map(function (res) {
        return parseInt(res.key);
      });
      result.forEach(function (res) {
        var referentId = parseInt(res.key);
        var existingReferent = userRoleCustomReferents.find(function (userRoleCustomReferent) {
          return userRoleCustomReferent.roleCustom.roleType === roleType && userRoleCustomReferent.referentId === referentId;
        });

        if (!existingReferent) {
          userRoleCustomReferents.push({
            roleCustom: {
              id: roleType.split('-')[1],
              roleType: roleType
            },
            referentId: referentId,
            userId: _this8.talent.id
          });
        }
      });
      userRoleCustomReferents = userRoleCustomReferents.filter(function (userRoleCustomReferent) {
        return userRoleCustomReferent.roleCustom.roleType !== roleType || newReferentIds.includes(userRoleCustomReferent.referentId);
      });
      Ember.set(this.talent, 'userRoleCustomReferents', userRoleCustomReferents);
    },
    onTemplateChanged: function onTemplateChanged(value) {
      if (value) {
        Ember.set(this.talent, 'template', {
          id: value.key,
          name: value.label
        });
      }
    },
    onCampaignChanged: function onCampaignChanged(value) {
      if (value) {
        Ember.set(this.talent, 'campaign', {
          id: value.key,
          name: value.label
        });
      }
    },
    onDateChanged: function onDateChanged() {},
    onTeamChanged: function onTeamChanged() {
      Ember.set(this, 'newTeam', true);
    },
    onPositionChanged: function onPositionChanged() {
      Ember.set(this, 'newPosition', true);
    },
    onContractChanged: function onContractChanged(value) {
      Ember.set(this.talent, 'contract', {
        name: value
      });
    },
    onFileChanged: function onFileChanged(field, talentName, file) {
      var _this9 = this;

      Ember.set(this, 'blockSave', true);
      var customName = talentName + '_' + field.name;
      this.fileUploadService.signAndUpload(file, true, function (e) {
        return _this9.onProgress(field, e);
      }, customName).then(function (fileUrl) {
        Ember.set(_this9, 'fullValue', fileUrl);
        if (_this9.onFileUploaded) _this9.onFileUploaded(fileUrl);

        _this9.step3AdditionnalFields.forEach(function (talentField) {
          if (talentField.id === field.id) {
            if (!talentField.user_customfield) {
              Ember.set(talentField, 'user_customfield', {});
            }

            Ember.set(talentField.user_customfield, 'fullValue', fileUrl);
            Ember.set(talentField, 'value', file.name);
          }
        });
      }).finally(function () {
        Ember.set(_this9, 'blockSave', false);
      });
    },
    onInputChanged: function onInputChanged() {},
    editCustomField: function editCustomField(id) {
      var _this10 = this;

      setTimeout(function () {
        Ember.set(_this10, 'editCustomField', id);
      });
    },
    nextStep: function nextStep() {
      if (!this.validateStep1Form()) return;
      var step = this.step + 1;

      if (step === 2 && !this.showStep2) {
        step++;
      }

      if (step === 2) {
        this.initStep2();
      }

      Ember.set(this, 'step', step);
      var modalContainerEl = document.querySelector('.modal-container');

      if (modalContainerEl) {
        modalContainerEl.scrollTop = 0;
      }
    },
    previousStep: function previousStep() {
      var step = this.step - 1;

      if (step === 2 && !this.showStep2) {
        step--;
      }

      if (step === 2) {
        this.initStep2();
      }

      Ember.set(this, 'step', step);
      var modalContainerEl = document.querySelector('.modal-container');

      if (modalContainerEl) {
        modalContainerEl.scrollTop = 0;
      }
    },
    skipStep: function skipStep() {
      var _this11 = this;

      if (this.step2AdditionnalFields.dates.length > 0) {
        this.step2AdditionnalFields.dates.forEach(function (dateField) {
          Ember.set(_this11.talent, dateField.dateKey, null);
        });
      }

      ;

      if (this.step2AdditionnalFields.referents.length > 0) {
        this.step2AdditionnalFields.referents.forEach(function (referentField) {
          if (referentField.role === 'officeManager') {
            Ember.set(_this11.talent, referentField.role + 'Ids', null);
            Ember.set(_this11, 'officeManagersSelected', []);
          } else {
            Ember.set(_this11.talent, referentField.role, null);
          }
        });
      }

      if (this.step2AdditionnalFields.referentRoleCustoms.length > 0) {
        this.step2AdditionnalFields.referentRoleCustoms.forEach(function (referentRoleCustom) {
          var userRoleCustomReferents = _this11.talent.userRoleCustomReferents || [];
          userRoleCustomReferents = userRoleCustomReferents.filter(function (userRoleCustomReferent) {
            return userRoleCustomReferent.roleCustom.roleType !== referentRoleCustom.roleType;
          });
          Ember.set(_this11.talent, 'userRoleCustomReferents', userRoleCustomReferents);
        });
      }

      if (this.step2AdditionnalFields.experienceFields.length > 0) {
        this.step2AdditionnalFields.experienceFields.forEach(function (experienceField) {
          Ember.set(_this11.talent, experienceField.key, null);
        });
      }

      if (this.step2AdditionnalFields.talentFields.length > 0) {
        this.step2AdditionnalFields.talentFields.forEach(function (talentField) {
          Ember.set(_this11.talent[talentField.key], 'name', null);
        });
      }

      Ember.set(this, 'step', this.step + 1);
    }
  }), _EmberComponent$exte));

  _exports.default = _default;
});