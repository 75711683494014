define("teelt-frontend/templates/components/talents/talent-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SNLDFlkf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"field-file\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col s12 m6\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row name-row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col s8\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"field-name g-talent-table-name\"],[9],[7,\"span\"],[12,\"class\",[28,[\"field-name-action \",[27,\"if\",[[23,[\"fullValue\"]],\"clickable\"],null]]]],[3,\"action\",[[22,0,[]],\"openFile\"]],[9],[1,[23,[\"field\",\"name\"]],false],[10],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col s4 period-col\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"field\",\"task\",\"period\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"period g-talent-table-tag\"],[9],[1,[27,\"task/utils/custom-period-name\",null,[[\"period\"],[[23,[\"field\",\"task\",\"period\"]]]]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col s12 m3 desciption-col g-talent-table-description\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"task-description\"],[9],[1,[23,[\"field\",\"task\",\"description\"]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col s12 m3 action-col\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"action\"],[9],[7,\"a\"],[11,\"href\",\"#\"],[3,\"action\",[[22,0,[]],\"triggerField\",[23,[\"field\"]]]],[9],[1,[21,\"actionLabel\"],false],[10],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[1,[27,\"custom-field/input-field\",null,[[\"talent\",\"fullValue\",\"field\",\"onFileUploaded\",\"progress\"],[[23,[\"talent\"]],[23,[\"fullValue\"]],[23,[\"field\"]],[27,\"action\",[[22,0,[]],\"onFileUploaded\"],null],[23,[\"progress\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-document.hbs"
    }
  });

  _exports.default = _default;
});