define("teelt-frontend/routes/board/experiences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    model: function model() {
      return {
        isEssential: this.session.data.authenticated.user.company.essential
      };
    }
  });

  _exports.default = _default;
});