define("teelt-frontend/components/activity/activity-email", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['activity-list'],
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    actions: {
      resendNotif: function resendNotif() {
        Ember.set(this, 'showNotifModal', true);
        var params = JSON.parse(this.activity.customParams);
        var email = '';

        if (this.talent[params.recipient]) {
          email = this.talent[params.recipient].proEmail;
        } else {
          email = this.talent.proEmail;
        }

        Ember.set(this, 'email', email);
      },
      send: function send() {
        var _this = this;

        Ember.set(this, 'blocked', true);
        var params = JSON.parse(this.activity.customParams);
        var notifId = params.notifId;
        var path = _environment.default.APP.API_HOST + "/notifications/" + notifId + "/users/" + this.talent.id;

        if (params.recipient) {
          path += "?recipient=" + params.recipient.toUpperCase();
        }

        this.ajax.requestWithBodyPromise(path, 'POST').then(function (result) {
          Ember.set(_this, 'blocked', false);

          if (result) {
            _this.notify.info(_this.intl.t('talent.messages.notification_sent'), {
              closeAfter: 5000
            });

            Ember.set(_this, 'showNotifModal', false);
          } else {
            _this.notify.error(_this.intl.t('talent.errors.notification_failed'), {
              closeAfter: 5000
            });
          }
        }).catch(function () {
          Ember.set(_this, 'blocked', false);

          _this.notify.error(_this.intl.t('talent.errors.notification_failed'), {
            closeAfter: 5000
          });
        });
      },
      onClickOverlay: function onClickOverlay() {}
    }
  });

  _exports.default = _default;
});