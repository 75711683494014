define("teelt-frontend/components/talents/talent-document-by-period", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var periods = this.userService.getTalentPeriods(this.talent);
      Ember.set(this, 'periods', periods);
      Ember.set(this, 'talentCurrentPeriod', this.userService.getTalentCurrentPeriod(this.talent, periods));
    }
  });

  _exports.default = _default;
});