define("teelt-frontend/components/utils/filterizr-filter", ["exports", "filterizr"], function (_exports, _filterizr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['filterizr-filter'],
    eventBus: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.initFilterizr();
      this.eventBus.on('REINIT_FILTERIZR', this, 'initFilterizr');
    },
    destroy: function destroy() {
      this.eventBus.on('REINIT_FILTERIZR', this, 'initFilterizr');

      this._super.apply(this, arguments);
    },
    filtersObserver: Ember.observer('filter.id', 'isExpanded', function () {
      this.initFilterizr();
    }),
    willDestroyElement: function willDestroyElement() {
      if (this.filterizr) {
        this.filterizr.destroy();
      }
    },
    initFilterizr: function initFilterizr() {
      var _this = this;

      if (this.filterizr) {
        this.filterizr.destroy();
      }

      Ember.run.later(function () {
        var options = {
          animationDuration: 0.3,
          gutterPixels: 3,
          multifilterLogicalOperator: 'and',
          filter: _this.filter.groupsSelected,
          layout: 'packed'
        };

        if (document.querySelector('#' + _this.filter.id)) {
          var filterizr = new _filterizr.default('#' + _this.filter.id, options);
          Ember.set(_this, "filterizr", filterizr);

          _this.$('.select-filter-' + _this.filter.id).formSelect();
        }
      }, 0);
    },
    actions: {
      onChange: function onChange(key) {
        var group = key.split('-')[0];
        var value = key.split('-')[1];
        var selected = this.filter.groupsSelected;
        selected[group] = "" + value;
        Ember.set(this.filter, 'groupsSelected', selected);
        var options = this.filterizr.options;
        options.filter = selected;
        this.filterizr.setOptions(options);

        if (this.onFilter) {
          this.onFilter(this.filter, key);
        }
      }
    }
  });

  _exports.default = _default;
});