define("teelt-frontend/components/talents/modal/talents-update-template-modal", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'templatePath', _environment.default.APP.API_HOST + "/templates");
    },
    actions: {
      onClickOverlay: function onClickOverlay() {},
      onSelectChanged: function onSelectChanged(template) {
        Ember.set(this, 'selectedTemplate', template);
      },
      save: function save() {
        if (!this.selectedTemplate) return;
        this.updateTalentsTemplate(this.selectedTemplate);
        Ember.set(this, 'show', false);
      }
    }
  });

  _exports.default = _default;
});