define("teelt-frontend/templates/components/icon/paper-plane-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yP+GOPB1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"width\",\"16\"],[11,\"height\",\"17\"],[11,\"viewBox\",\"0 0 16 17\"],[11,\"fill\",\"none\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[9],[0,\"\\n\"],[7,\"path\"],[11,\"d\",\"M13.9818 3.71489L7.1672 10.5084L9.67323 15.0228L13.9818 3.71489ZM6.37582 9.7195L13.1905 2.92596L1.84737 7.22123L6.37582 9.7195ZM15.2568 0.953644C15.6965 0.778327 16.1362 1.21662 15.9603 1.65491L10.2887 16.513C10.1569 16.9513 9.54133 16.9952 9.32151 16.6007L6.06806 10.8152L0.308581 7.61569C-0.131073 7.39655 -0.087108 6.78294 0.352547 6.60762L15.2568 0.953644Z\"],[11,\"fill\",\"#3CBCFF\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/icon/paper-plane-svg.hbs"
    }
  });

  _exports.default = _default;
});