define("teelt-frontend/templates/components/event/campaign-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TaNY7uG3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"campaigns-list-table-layout\"],[11,\"style\",\"padding: 0 .75rem;\"],[9],[0,\"\\n  \"],[1,[27,\"utils/default-table\",null,[[\"modelName\",\"modelFilter\",\"prefixTranslateKey\",\"customResultContentComponent\",\"countUrl\",\"lineHeight\",\"noResultMessage\",\"sortableKeys\",\"reload\",\"sortedKeyInit\",\"sortedDirectionInit\",\"onSelected\",\"search_ph\",\"keys\",\"complexFilters\"],[\"events/campaign\",[23,[\"modelFilter\"]],\"event.campaign.table.keys.\",\"event/table/campaigns-custom-table-content\",[23,[\"countUrl\"]],58,[27,\"t\",[\"event.no_campaign\"],null],[23,[\"sortableKeys\"]],[23,[\"reloadTimestamp\"]],\"id\",\"DESC\",[27,\"action\",[[22,0,[]],\"onRowSelected\"],null],[27,\"t\",[\"template.search_ph\"],null],[23,[\"keys\"]],[23,[\"complexFilters\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/event/campaign-list.hbs"
    }
  });

  _exports.default = _default;
});