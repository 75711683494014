define("teelt-frontend/components/tool/tool-versions-edit", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['content-versions-edit'],
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.loadVersions();
    },
    audiences: Ember.computed('allAudiences', 'versions', function () {
      if (!this.allAudiences) return;

      if (this.versions) {
        var audiencesUsed = this.versions.map(function (v) {
          return v.audience.id;
        });
        var audiences = this.allAudiences.filter(function (a) {
          return audiencesUsed.indexOf(Number(a.key)) < 0;
        });
        return audiences;
      }

      return this.allAudiences;
    }),
    loadVersions: function loadVersions() {
      var _this = this;

      var path = _environment.default.APP.API_HOST + '/tools/' + this.defaultTool.id + '/versions';
      this.ajax.requestPromise(path).then(function (versions) {
        Ember.set(_this, 'versions', versions);
      });
    },
    loadAudiences: function loadAudiences() {
      var _this2 = this;

      this.store.findAll('audience').then(function (audiences) {
        var selectValues = [];
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = audiences.toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var audience = _step.value;
            selectValues.push({
              key: audience.id,
              label: audience.name
            });
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        Ember.set(_this2, 'allAudiences', selectValues);
      });
    },
    actions: {
      createNewVersion: function createNewVersion() {
        var dataNewTool = this.defaultTool.toJSON();
        dataNewTool.genericTool = {
          id: this.defaultTool.id
        };
        var newTool = this.store.createRecord('tool', dataNewTool);
        this.loadAudiences();
        Ember.set(this, 'newTool', newTool);
      },
      editTool: function editTool(id) {
        var _this3 = this;

        Ember.set(this, 'showActions', false);
        this.store.findRecord('tool', id).then(function (tool) {
          Ember.set(_this3, 'editTool', tool);
        });
      },
      duplicateTool: function duplicateTool(tool) {
        Ember.set(this, 'showActions', false);

        if (!tool) {
          tool = this.defaultTool.toJSON();
        }

        var dataNewTool = JSON.parse(JSON.stringify(tool));
        dataNewTool.genericTool = {
          id: this.defaultTool.id
        };
        dataNewTool.id = undefined;
        var newTool = this.store.createRecord('tool', dataNewTool);
        this.loadAudiences();
        Ember.set(this, 'newTool', newTool);
      },
      openDeleteTool: function openDeleteTool(tool) {
        Ember.set(this, 'showActions', false);
        Ember.set(this, 'toolToDelete', tool);
      },
      removeTool: function removeTool(response) {
        var _this4 = this;

        if (response) {
          this.store.findRecord('tool', this.toolToDelete.id).then(function (tool) {
            tool.deleteRecord();
            tool.save().then(function () {
              _this4.loadVersions();
            });
          });
        }

        Ember.set(this, 'toolToDelete', undefined);
      },
      onClickOverlay: function onClickOverlay() {},
      onAudienceChanged: function onAudienceChanged(audience) {
        Ember.set(this.newTool, 'audience', {
          id: Number(audience.key),
          name: audience.label
        });
      },
      continueToEditTool: function continueToEditTool() {
        if (!this.newTool.audience || !this.newTool.audience.id) return;
        Ember.set(this, 'editTool', this.newTool);
        Ember.set(this, 'newTool', false);
      },
      onFinish: function onFinish() {
        Ember.set(this, 'editTool', false);
        this.loadVersions();
      },
      previous: function previous() {
        Ember.set(this, 'editTool', false);
      }
    }
  });

  _exports.default = _default;
});