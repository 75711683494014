define("teelt-frontend/helpers/show-switch-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    session: Ember.inject.service(),
    compute: function compute() {
      if (!this.session.data.authenticated) return false;
      if (localStorage.getItem('currentRole')) return true;
      var userRoles = this.session.data.authenticated.user.roles;
      if (userRoles.length === 1 && userRoles[0] === 'ROLE_USER') return false;
      return true;
    }
  });

  _exports.default = _default;
});