define("teelt-frontend/components/ihm/meet-people/meet-people-tutorial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['meet-people-tutorial-modal'],
    allSteps: 6,
    selectedStep: 1,
    init: function init() {
      this._super.apply(this, arguments); //create allSteps array


      this.defaultStep ? Ember.set(this, 'selectedStep', this.defaultStep) : Ember.set(this, 'selectedStep', 1);
      var allSteps = [];

      for (var i = 1; i <= this.allSteps; i++) {
        allSteps.push(i);
      }

      Ember.set(this, 'allSteps', allSteps);
    },
    actions: {
      onClickOverlay: function onClickOverlay() {}
    }
  });

  _exports.default = _default;
});