define("teelt-frontend/templates/components/settings/periods-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oNSJuWQj",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"periods-list-table-layout\"],[9],[0,\"\\n  \"],[1,[27,\"utils/default-table\",null,[[\"storageKeysName\",\"modelName\",\"modelFilter\",\"prefixTranslateKey\",\"customResultContentComponent\",\"filters\",\"countUrl\",\"lineHeight\",\"noResultMessage\",\"sortableKeys\",\"reload\",\"sortedKeyInit\",\"sortedDirectionInit\",\"onSelected\",\"search_ph\",\"keys\",\"complexFilters\"],[\"period\",\"period\",[23,[\"modelFilter\"]],\"settings.periods.table.key.\",\"settings/table/periods-custom-table-content\",[23,[\"filters\"]],[23,[\"countUrl\"]],58,[27,\"t\",[\"settings.periods.no_periods\"],null],[23,[\"sortableKeys\"]],[23,[\"reloadTimestamp\"]],\"id\",\"DESC\",[27,\"action\",[[22,0,[]],\"onRowSelected\"],null],[27,\"t\",[\"settings.periods.search\"],null],[23,[\"keys\"]],[23,[\"complexFilters\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/settings/periods-list.hbs"
    }
  });

  _exports.default = _default;
});