define("teelt-frontend/components/form/field/complex/field-email", ["exports", "teelt-frontend/utils/string-util"], function (_exports, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['field-email'],
    intl: Ember.inject.service(),
    actions: {
      onFocusOut: function onFocusOut() {
        Ember.set(this.field, 'validationError', undefined);
        Ember.set(this, 'haveCustomError', false);

        if (!this.value || this.value.length == 0) {
          if (this.required) {
            Ember.set(this, 'haveCustomError', true);
            Ember.set(this.field, 'validationError', this.intl.t("form.errors.validation.required"));
          }
        } else if (!(0, _stringUtil.default)().validateEmail(this.value)) {
          Ember.set(this, 'haveCustomError', true);
          Ember.set(this.field, 'validationError', this.intl.t("form.errors.validation.email"));
        }
      }
    }
  });

  _exports.default = _default;
});