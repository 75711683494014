define("teelt-frontend/helpers/overridable-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    customLabelsService: Ember.inject.service('custom-labels-service'),
    compute: function compute(params) {
      var str = params[1].charAt(params[1].length - 1) === 's' ? 's' : '';
      var customLabels = this.customLabelsService.customLabels;

      if (customLabels && customLabels[params[0]]) {
        return customLabels[params[0]] + str;
      } else {
        return this.intl.t(params[1]);
      }
    }
  });

  _exports.default = _default;
});