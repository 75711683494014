define("teelt-frontend/routes/board/admin-form/form-new", ["exports", "teelt-frontend/routes/parent/confirm-before-transition"], function (_exports, _confirmBeforeTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _confirmBeforeTransition.default.extend({
    model: function model(params) {
      return this._super(params);
    }
  });

  _exports.default = _default;
});