define("teelt-frontend/components/utils/default-table", ["exports", "teelt-frontend/config/environment", "teelt-frontend/utils/jquery-utils"], function (_exports, _environment, _jqueryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    classNames: ['default-table'],
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    storage: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    session: Ember.inject.service(),
    userService: Ember.inject.service('user-service'),
    page: 0,
    pageLoaded: 0,
    limitLoad: 10,
    query: '',
    maxHeight: window.innerHeight - 500,
    lineHeight: 50,
    canLoadMore: true,
    showFilters: false,
    complexFilters: [],
    selectedRecords: [],
    currentKeys: Ember.computed('keys', 'customKeys', 'rand', function () {
      if (this.keys) {
        return this.keys;
      }

      var keys = [];

      if (this.customKeys) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.customKeys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var key = _step.value;

            if (key.selected) {
              keys.push(key.key);
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      return keys;
    }),
    showActions: Ember.computed('query', 'filters', 'complexFilters', 'selectedRecords.[]', 'selectAll', function () {
      if (this.query && this.query.length || this.filters && this.filter.length || this.complexFilters && this.complexFilters.length || this.selectAll) return true;
      if (this.selectedRecords.length > 0) return true;
      return false;
    }),
    changedModelName: Ember.observer('modelName', function () {
      Ember.set(this, 'reload', Date.now());
    }),
    reloadData: Ember.observer('reload', function () {
      this.send('onSearchKeyUp');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'selectedRecords', []);

      if (!this.allDatas) {
        Ember.set(this, 'allDatas', []);
      }

      if (!this.complexFilters) {
        Ember.set(this, 'complexFilters', []);
      }

      if (this.userService.hasAnyRole(this.session.data.authenticated.user, "ROLE_ADMINISTRATIVE") && !this.userService.hasAnyRole(this.session.data.authenticated.user, "ROLE_RH")) {
        Ember.set(this, 'isOnlyAdministrative', true);

        if (this.modelName === 'user' && !this.customKeys.some(function (keyObj) {
          return keyObj.key === 'admin_form';
        })) {
          this.customKeys.push({
            key: 'admin_form',
            selected: true
          });
        }
      }

      if (this.maxNumber) {
        Ember.set(this, 'pageSize', this.maxNumber);
      } else {
        Ember.set(this, 'pageSize', 10);
      }
    },
    destroy: function destroy() {
      this.eventBus.off('CLICK_EVENT', this, 'checkToCloseModal');

      this._super.apply(this, arguments);
    },
    checkToCloseModal: function checkToCloseModal(e) {
      if (!(0, _jqueryUtils.default)().checkIfClickInElement(e, ["filters", "filters-btn", "show-table-action-btn", "table-actions", "custom-keys-btn", "custom-keys-popup", "complex-filters-container", "searchable-select-list-value", "datepicker-modal", "datepicker-controls", "datepicker-table"])) {
        Ember.set(this, 'showFilters', false);
        Ember.set(this, 'showTableActions', false);
        Ember.set(this, 'customKeysOpened', false);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.sortedKeyInit) {
        Ember.set(this, 'sortedKey', this.sortedKeyInit);
        Ember.set(this, 'sortedDirection', this.sortedDirectionInit);
      }

      if (this.storageFullQuery) {
        var fullQuery = this.storage.findJson(this.storageFullQuery, false);

        if (fullQuery) {
          Ember.set(this, 'query', fullQuery.query);
          Ember.set(this, 'filters', fullQuery.filters);
          Ember.set(this, 'complexFilters', fullQuery.complexFilters);
          Ember.set(this, 'selectAll', fullQuery.selectAll);
          Ember.set(this, 'page', fullQuery.page ? fullQuery.page : 0);
        }
      } //set(this, 'pageSize', Math.max(Math.round(this.maxHeight / this.lineHeight), 5));


      this.searchMoreDatas();

      if (this.storageKeysName) {
        var customKeys = this.storage.findJson(this.storageKeysName, true);

        if (customKeys) {
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = customKeys[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var customKey = _step2.value;
              var _iteratorNormalCompletion3 = true;
              var _didIteratorError3 = false;
              var _iteratorError3 = undefined;

              try {
                for (var _iterator3 = this.customKeys[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                  var currentCustomKey = _step3.value;

                  if (customKey.key == currentCustomKey.key) {
                    Ember.set(currentCustomKey, 'selected', customKey.selected);
                  }
                }
              } catch (err) {
                _didIteratorError3 = true;
                _iteratorError3 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
                    _iterator3.return();
                  }
                } finally {
                  if (_didIteratorError3) {
                    throw _iteratorError3;
                  }
                }
              }
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }
        }

        Ember.set(this, 'rand', new Date());
      }

      this.resizeTRs();
      setTimeout(function () {
        _this.eventBus.on('CLICK_EVENT', _this, 'checkToCloseModal');
      }, 300);
    },
    willDestroyElement: function willDestroyElement() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval);
      }
    },
    resizeTRs: function resizeTRs() {
      /*
      let minColumnSize = 160;
      let nbSelectedCols = 0;
      if(!this.customKeys) return;
      for (let i=0; i<this.customKeys.length; i++) {
        if (this.customKeys[i].selected) nbSelectedCols++;
      }
      let minWidth = Math.max(this.$('.table-body').width(), minColumnSize * nbSelectedCols) - 5;
      let tableBodyTrEl = this.$('.table-body .tr');
       if (tableBodyTrEl) {
        setTimeout(() => {
          tableBodyTrEl.width(minWidth);
        }, 300);
      }*/
    },
    searchMoreDatas: function searchMoreDatas() {
      var _this2 = this;

      if (this.countUrl && this.dataCount > 0 && this.dataCount <= this.allDatas.length) return;
      if (!this.canLoadMore || !this.modelName) return;
      var requestId = new Date().getTime();
      Ember.set(this, 'currentRequestId', requestId);
      var params = {};

      if (this.modelFilter) {
        params = JSON.parse(JSON.stringify(this.modelFilter));
        ;
      }

      params.offset = this.pageLoaded * this.limitLoad;
      params.limit = this.limitLoad;
      params.search = this.query;

      if (this.selectedIds) {
        params.selectedIds = this.selectedIds.join(',');
      }

      if (this.sortedKey || this.sortedDirection) {
        params.sortedKey = this.sortedKey;
        params.sortedDirection = this.sortedDirection;
      }

      if (this.complexFilters && this.complexFilters.length > 0) {
        var complexFilters = this.complexFilters.map(function (c) {
          var filter = {
            key: c.key,
            type: c.type,
            operator: c.operator,
            customFieldId: c.customFieldId,
            customFieldType: c.customFieldType,
            multipleSelection: c.multipleSelection
          };

          if (c.customFieldType && c.customFieldType == 'multi_autocomplete_users') {
            filter.value1 = c.value1.split(";").join(',');
          } else if (Array.isArray(c.value1)) {
            filter.value1 = c.value1.map(function (v) {
              return v.key;
            }).join(',');
          } else if (c.value1 && _typeof(c.value1) === 'object' && c.value1.constructor === Object) {
            filter.value1 = "" + c.value1.key;
          } else {
            filter.value1 = "" + c.value1;
          }

          return filter;
        });
        params.complexFilters = JSON.stringify(complexFilters);
      }

      if (this.filters) {
        for (var filterKey in this.filters) {
          if (!this.filters.hasOwnProperty(filterKey) || this.filters[filterKey] === "all") continue;
          params[filterKey] = this.filters[filterKey];
        }
      }

      Ember.set(this, 'canLoadMore', false);
      Ember.set(this, 'isLoading', true);
      this.getRequest(params).then(function (data) {
        if (_this2.selectedIds) {
          var temp = data.toArray();
          data = [];
          var _iteratorNormalCompletion4 = true;
          var _didIteratorError4 = false;
          var _iteratorError4 = undefined;

          try {
            for (var _iterator4 = temp[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
              var item = _step4.value;
              var _iteratorNormalCompletion5 = true;
              var _didIteratorError5 = false;
              var _iteratorError5 = undefined;

              try {
                for (var _iterator5 = _this2.selectedIds[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
                  var selectedId = _step5.value;

                  if (selectedId == item.id) {
                    _this2.selectedRecords[item.id] = true;
                  }
                }
              } catch (err) {
                _didIteratorError5 = true;
                _iteratorError5 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion5 && _iterator5.return != null) {
                    _iterator5.return();
                  }
                } finally {
                  if (_didIteratorError5) {
                    throw _iteratorError5;
                  }
                }
              }

              data.push(item);
            }
          } catch (err) {
            _didIteratorError4 = true;
            _iteratorError4 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
                _iterator4.return();
              }
            } finally {
              if (_didIteratorError4) {
                throw _iteratorError4;
              }
            }
          }
        }

        if (_this2.currentRequestId != requestId) return;

        if (data && data.length >= _this2.limitLoad) {
          Ember.set(_this2, 'canLoadMore', true);
        }

        var allDatas = _this2.allDatas.concat(data.toArray());

        Ember.set(_this2, "allDatas", allDatas);
        _this2.pageLoaded++;

        _this2.showCurrentPage();

        if (_this2.onLoadMore) {
          _this2.onLoadMore(data.toArray());
        }

        if (!_this2.countUrl) {
          Ember.set(_this2, "dataCount", allDatas.length);
          Ember.set(_this2, "nbPages", Math.ceil(allDatas.length / _this2.pageSize));

          _this2.updatePagesLinks();
        }
      }).finally(function () {
        if (!_this2.isDestroyed && !_this2.isDestroying) {
          Ember.set(_this2, 'isLoading', false);
        }

        _this2.saveFullQuery();
      });
    },
    getRequest: function getRequest(params) {
      //TODO : improve this method
      if (this.templateType) {
        params.templateType = this.templateType;
      }

      if (this.templateId) {
        params.templateId = this.templateId;
      }

      if (this.userTasks) {
        if (this.userService.isRH(this.session.data.authenticated.user)) {
          Ember.set(params, "currentRole", "ROLE_RH");
        } else {
          var _iteratorNormalCompletion6 = true;
          var _didIteratorError6 = false;
          var _iteratorError6 = undefined;

          try {
            for (var _iterator6 = this.session.data.authenticated.user.roles[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
              var role = _step6.value;

              if (role != "ROLE_USER") {
                Ember.set(params, "currentRole", role);
                break;
              }
            }
          } catch (err) {
            _didIteratorError6 = true;
            _iteratorError6 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion6 && _iterator6.return != null) {
                _iterator6.return();
              }
            } finally {
              if (_didIteratorError6) {
                throw _iteratorError6;
              }
            }
          }
        }

        return this.ajax.requestWithUrlParamPromise(_environment.default.APP.API_HOST + '/users/me/tasks', 'GET', params);
      } else if (this.arriving) {
        params.dateFilter = this.countParams.dateFilter;
        params.startDate = this.countParams.startDate;
        params.endDate = this.countParams.endDate;
        params.targetDateField = this.countParams.targetDateField;

        if (!params.targetDateField) {
          if (this.countParams.filterType == "completedTasks") {
            return this.ajax.requestWithUrlParamPromise(_environment.default.APP.API_HOST + '/tasks/' + this.countParams.taskId + '/users?completed=true', 'GET', params);
          }

          if (this.countParams.filterType == "notCompletedTasks") {
            return this.ajax.requestWithUrlParamPromise(_environment.default.APP.API_HOST + '/tasks/' + this.countParams.taskId + '/users', 'GET', params);
          }

          return this.ajax.requestWithUrlParamPromise(_environment.default.APP.API_HOST + '/users/count/feedbackCompleted', 'GET', params);
        } else {
          return this.ajax.requestWithUrlParamPromise(_environment.default.APP.API_HOST + '/users/count/period', 'GET', params);
        }
      } else {
        if (this.modelName === "user" && this.isOnlyAdministrative) {
          params.includes += ",forms";
        }

        this.countDatas(params);
        return this.store.query(this.modelName, params);
      }
    },
    countDatas: function countDatas(params) {
      var _this3 = this;

      if (!this.countUrl) return;
      var requestId = new Date().getTime();
      Ember.set(this, 'currentCountRequestId', requestId);

      if (!params) {
        params = {
          search: this.query ? this.query : ''
        };
      }

      if (this.templateType) {
        params.templateType = this.templateType;
      }

      if (this.templateId) {
        params.templateId = this.templateId;
      }

      if (this.filters) {
        for (var filterKey in this.filters) {
          if (!this.filters.hasOwnProperty(filterKey) || this.filters[filterKey] === "all") continue;
          params[filterKey] = this.filters[filterKey];
        }
      }

      if (this.complexFilters && this.complexFilters.length > 0) {
        var data = {
          search: this.query ? this.query : '',
          selectAll: this.selectAll
        };
        var complexFilters = this.complexFilters.map(function (c) {
          var filter = {
            key: c.key,
            type: c.type,
            operator: c.operator,
            customFieldId: c.customFieldId,
            customFieldType: c.customFieldType,
            multipleSelection: c.multipleSelection
          };

          if (c.customFieldType && c.customFieldType == 'multi_autocomplete_users') {
            filter.value1 = c.value1.split(";").join(',');
          } else if (Array.isArray(c.value1)) {
            filter.value1 = c.value1.map(function (v) {
              return v.key;
            }).join(',');
          } else if (c.value1 && _typeof(c.value1) === 'object' && c.value1.constructor === Object) {
            filter.value1 = "" + c.value1.key;
          } else {
            filter.value1 = "" + c.value1;
          }

          return filter;
        });
        data.complexFilters = JSON.stringify(complexFilters);
      }

      var url = this.countUrl;
      this.ajax.requestWithUrlParamPromise(url, 'GET', params).then(function (count) {
        if (_this3.currentCountRequestId != requestId) return;
        Ember.set(_this3, "dataCount", count);
        Ember.set(_this3, "nbPages", Math.ceil(count / _this3.pageSize));

        _this3.updatePagesLinks();

        if (_this3.onCountChanged) {
          _this3.onCountChanged(count);
        }
      });
    },
    countSelected: function countSelected() {
      var _this4 = this;

      if (!this.countUrl && !this.onCountSelected) return;
      var requestId = new Date().getTime();
      Ember.set(this, 'currentSelectedCountRequestId', requestId);
      var selecteds = this.results.filter(function (r) {
        return _this4.selectedRecords[r.id];
      }).map(function (r) {
        return r.id;
      });

      if (!this.selectAll) {
        if (this.onCountSelected) {
          this.onCountSelected(selecteds.length);
        }
      } else {
        var data = {
          search: this.query ? this.query : '',
          selectAll: this.selectAll
        };

        if (this.filters) {
          for (var filterKey in this.filters) {
            if (!this.filters.hasOwnProperty(filterKey) || this.filters[filterKey] === "all") continue;
            data[filterKey] = this.filters[filterKey];
          }
        }

        if (this.complexFilters && this.complexFilters.length > 0) {
          var complexFilters = this.complexFilters.map(function (c) {
            var filter = {
              key: c.key,
              type: c.type,
              operator: c.operator,
              customFieldId: c.customFieldId,
              customFieldType: c.customFieldType
            };

            if (c.customFieldType && c.customFieldType == 'multi_autocomplete_users') {
              filter.value1 = c.value1.split(";").join(',');
            } else if (Array.isArray(c.value1)) {
              filter.value1 = c.value1.map(function (v) {
                return v.key;
              }).join(',');
            } else if (c.value1 && _typeof(c.value1) === 'object' && c.value1.constructor === Object) {
              filter.value1 = "" + c.value1.key;
            } else {
              filter.value1 = "" + c.value1;
            }

            return filter;
          });
          data.complexFilters = JSON.stringify(complexFilters);
        }

        var url = this.countUrl;
        this.ajax.requestWithUrlParamPromise(url, 'GET', data).then(function (count) {
          if (_this4.currentSelectedCountRequestId != requestId) return;

          _this4.onCountSelected(count);
        });
      }
    },
    showCurrentPage: function showCurrentPage() {
      var _this5 = this;

      var allDatas = this.allDatas;
      var results = allDatas.slice(this.page * this.pageSize, (this.page + 1) * this.pageSize);
      Ember.set(this, 'results', results);

      if (this.results.length < this.pageSize) {
        this.searchMoreDatas();
      }

      this.updatePagesLinks();
      this.onSelectionChanged();
      this.resizeTRs();

      if (this.drag && this.page == 0) {
        Ember.run.later(function () {
          _this5.initDragula();
        }, 500);
      }
    },
    updatePagesLinks: function updatePagesLinks() {
      if (!this.nbPages) return;
      var minPage = Math.max(this.page - 3, 0);
      var maxPage = Math.min(this.page + 3, this.nbPages - 1);
      var pages = [];

      for (var i = minPage; i <= maxPage; i++) {
        pages.push(i);
      }

      Ember.set(this, 'pages', pages);
    },
    getFilterNumber: function getFilterNumber() {
      if (!this.filters) return undefined;
      var nb = 0;

      for (var key in this.filters) {
        if (!this.filters.hasOwnProperty(key) || this.filters[key] === 'all' || !this.filters[key]) continue;
        nb++;
      }

      return nb;
    },
    onSelectionChanged: function onSelectionChanged() {
      var _this6 = this;

      if (this.onMultySelectionChanged) {
        var selecteds = this.allDatas.filter(function (r) {
          return _this6.selectedRecords[r.id];
        }).map(function (r) {
          return r.id;
        });
        var params = {
          selecteds: selecteds,
          query: this.query,
          filters: this.filters,
          complexFilters: this.complexFilters,
          selectAll: this.selectAll
        };
        this.onMultySelectionChanged(params);
      }

      if (this.onSelectionChangedNotif) {
        var _params = {
          query: this.query,
          filters: this.filters,
          complexFilters: this.complexFilters
        };
        this.onSelectionChangedNotif(_params);
      }

      this.calculateAllSelected();
    },
    calculateAllSelected: function calculateAllSelected() {
      var _this7 = this;

      if (this.calculateTimeout) {
        clearTimeout(this.calculateTimeout);
        Ember.set(this, 'calculateTimeout', undefined);
      }

      var calculateTimeout = setTimeout(function () {
        _this7.countSelected();
      }, 500);
      Ember.set(this, 'calculateTimeout', calculateTimeout);
    },
    saveFullQuery: function saveFullQuery() {
      if (this.storageFullQuery) {
        var fullQuery = {
          query: this.query,
          filters: this.filters,
          complexFilters: this.complexFilters,
          selectAll: this.selectAll,
          page: this.page
        };
        this.storage.storeJson(this.storageFullQuery, fullQuery, false);
      }
    },
    initDragula: function initDragula() {
      var self = this;
      var container = document.querySelector('.drag-container');
      var drake = dragula({
        containers: [container],
        direction: 'vertical',
        revertOnSpill: false,
        moves: function moves(_el, _source, handle, _sibling) {
          return !!handle.closest('.drag-handle');
        }
      });
      drake.on('drag', function (el) {
        $(document).on('mousemove', function () {
          var mirror = document.querySelector('.gu-mirror');

          if (mirror) {
            var mouseX = event.client;
            var mouseY = event.clientY;
            var offsetX = mirror.offsetWidth / 2;
            var offsetY = mirror.offsetHeight / 2;
            mirror.style.transform = "translate(".concat(mouseX - offsetX, "px, ").concat(mouseY - offsetY, "px)");
          }
        });
        el.classList.add('dragging');
      });
      drake.on('dragend', function () {
        $(document).off('mousemove');
      });
      drake.on('drop', function () {
        self.reorderAfterDrop();
      });
    },
    reorderAfterDrop: function reorderAfterDrop() {
      var _this8 = this;

      var container = document.querySelector('.drag-container');
      var elems = Array.from(container.children);
      var elemsWithIndex = elems.map(function (elem) {
        return {
          elem: elem,
          originalIndex: Number(elem.getAttribute('index'))
        };
      });
      elemsWithIndex.sort(function (a, b) {
        return a.elem.compareDocumentPosition(b.elem) & 2 ? 1 : -1;
      });
      var sortedIndexes = elemsWithIndex.map(function (e) {
        return e.originalIndex;
      }).sort(function (a, b) {
        return b - a;
      });
      elemsWithIndex.forEach(function (elemObj, i) {
        elemObj.elem.setAttribute('index', sortedIndexes[i]);
      });
      elemsWithIndex.forEach(function (elemObj, i) {
        var updateData = JSON.stringify({
          order: sortedIndexes[i]
        });
        var path = _environment.default.APP.API_HOST + '/templates/' + elemObj.elem.getAttribute('drag-id') + '/order';

        _this8.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', updateData).then(function () {});
      });
    },
    updateScrollPos: function updateScrollPos(e) {
      if ($(window).height() - e.clientY < 80) {
        if (!this.scrollInterval) {
          this.startScroll(1);
        }
      } else if (e.clientY < 120) {
        if (!this.scrollInterval) {
          this.startScroll(-1);
        }
      } else {
        this.stopScroll();
      }
    },
    startScroll: function startScroll(direction) {
      Ember.set(this, 'scrollInterval', setInterval(function () {
        $(window).scrollTop($(window).scrollTop() + direction * 4);
      }, 10));
    },
    stopScroll: function stopScroll() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval);
        Ember.set(this, 'scrollInterval', undefined);
      }
    },
    actions: {
      showUserTasks: function showUserTasks(userSelected) {
        var _this9 = this;

        this.ajax.requestPromise(_environment.default.APP.API_HOST + '/users/' + userSelected.id + '?include=fullTemplate,customFields,events', 'GET').then(function (user) {
          Ember.set(userSelected, 'customFields', user.customFields);
          Ember.set(userSelected, 'manager', user.manager);
          Ember.set(userSelected, 'template', user.template);
          Ember.set(userSelected, 'taskValidations', user.taskValidations);
          Ember.set(userSelected, 'displayTasks', userSelected.displayTasks === true ? false : userSelected.displayTasks !== true ? true : false);
          Ember.set(_this9, 'talent', userSelected);

          if (_this9.showTasks == userSelected.id) {
            Ember.set(_this9, 'showTasks', false);
          } else {
            Ember.set(_this9, 'showTasks', userSelected.id);
          }
        });
      },
      onSearchKeyUp: function onSearchKeyUp() {
        var _this10 = this;

        if (this.keyUpAction) {
          Ember.run.cancel(this.keyUpAction);
        }

        var keyUpAction = Ember.run.later(function () {
          Ember.set(_this10, 'canLoadMore', true);
          Ember.set(_this10, 'page', 0);
          Ember.set(_this10, 'pageLoaded', 0);
          Ember.set(_this10, 'allDatas', []);
          Ember.set(_this10, 'results', undefined);

          _this10.searchMoreDatas();
        }, 300);
        Ember.set(this, "keyUpAction", keyUpAction);
      },
      toggleFilters: function toggleFilters() {
        Ember.set(this, 'showFilters', !this.showFilters);
      },
      changePage: function changePage(newPage) {
        if (newPage < 0 || newPage * this.pageSize >= this.dataCount) return;
        Ember.set(this, 'page', newPage);
        this.showCurrentPage();
      },
      changeSort: function changeSort(key) {
        if (!this.sortableKeys.includes(key)) return;
        var previousKey = this.sortedKey;
        Ember.set(this, 'sortedKey', key);

        if (previousKey === key) {
          if (this.sortedDirection === "ASC") {
            Ember.set(this, 'sortedDirection', 'DESC');
          } else {
            Ember.set(this, 'sortedDirection', 'ASC');
          }
        } else {
          Ember.set(this, 'sortedDirection', 'ASC');
        }

        if (this.sortedDirection == 'ASC') {
          Ember.set(this, 'results', this.allDatas.sortBy(key));
        } else {
          Ember.set(this, 'results', this.allDatas.sortBy(key).reverse());
        }

        Ember.set(this, 'canLoadMore', true);
        Ember.set(this, 'page', 0);
        Ember.set(this, 'pageLoaded', 0);
        Ember.set(this, 'allDatas', []);
        this.searchMoreDatas();
      },
      onSelect: function onSelect(result) {
        if (this.onSelected) this.onSelected(result);
      },
      onChangeCustomKey: function onChangeCustomKey(key, value) {
        Ember.set(key, 'selected', value.srcElement.checked);
        Ember.set(this, 'rand', new Date());

        if (this.storageKeysName) {
          this.storage.storeJson(this.storageKeysName, this.customKeys, true);
        }

        this.resizeTRs();
      },
      onFilterChanged: function onFilterChanged(data) {
        var complexFilters = this.complexFilters;
        complexFilters.push(data);
        Ember.set(this, 'complexFilters', null);
        Ember.set(this, 'complexFilters', complexFilters);
        Ember.set(this, 'canLoadMore', true);
        Ember.set(this, 'page', 0);
        Ember.set(this, 'pageLoaded', 0);
        Ember.set(this, 'allDatas', []);
        Ember.set(this, 'results', undefined);
        this.searchMoreDatas();
      },
      removeComplex: function removeComplex(index) {
        var complexFilters = this.complexFilters;
        complexFilters.splice(index, 1);
        Ember.set(this, 'complexFilters', null);
        Ember.set(this, 'complexFilters', complexFilters);
        Ember.set(this, 'canLoadMore', true);
        Ember.set(this, 'page', 0);
        Ember.set(this, 'pageLoaded', 0);
        Ember.set(this, 'allDatas', []);
        Ember.set(this, 'results', undefined);
        this.searchMoreDatas();
      },
      actionSelect: function actionSelect(result) {
        Ember.set(this.selectedRecords, result.id, !this.selectedRecords[result.id]);
        Ember.set(this, 'selectAll', false);
        Ember.set(this, 'rand', new Date());
        this.onSelectionChanged();
      },
      actionSelectAll: function actionSelectAll() {
        Ember.set(this, 'selectAll', !this.selectAll);
        this.onSelectionChanged();
      },
      launchAction: function launchAction(action) {
        var _this11 = this;

        var selecteds = this.allDatas.filter(function (r) {
          return _this11.selectedRecords[r.id];
        }).map(function (r) {
          return r.id;
        });
        var params = {
          action: action,
          selecteds: selecteds,
          query: this.query,
          filters: this.filters,
          complexFilters: this.complexFilters,
          selectAll: this.selectAll
        };
        this.onActionLaunched(params);
        Ember.set(this, 'showTableActions', false);
      }
    }
  });

  _exports.default = _default;
});