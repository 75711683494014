define("teelt-frontend/components/settings/table/perimeter-custom-table-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['perimeter-custom-table-content'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    actions: {
      edit: function edit() {
        Ember.set(this, 'showActions', false);
        this.eventBus.trigger('EDIT_PERIMETER', this.result);
      },
      prepareDeletePerimeter: function prepareDeletePerimeter() {
        Ember.set(this, 'showActions', false);
        Ember.set(this, 'perimeterToDelete', this.result);
      },
      removePerimeter: function removePerimeter(response) {
        var _this = this;

        if (!response) return;
        this.perimeterToDelete.deleteRecord();
        this.perimeterToDelete.save().then(function () {
          _this.eventBus.trigger('REFRESH_PERIMETERS');
        }).catch(function (e) {
          _this.notify.error(_this.intl.t('settings.perimeter.remove_fail'), {
            closeAfter: 5000
          });
        });
      }
    }
  });

  _exports.default = _default;
});