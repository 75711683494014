define("teelt-frontend/templates/components/dashboard/user-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5VAIjX1r",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"db-element\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"header\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[1,[27,\"t\",[\"dashboard.user_info.title\"],null],false],[10],[0,\"\\n    \"],[1,[27,\"utils/btn-info\",null,[[\"info\"],[[27,\"t\",[\"dashboard.talents.info\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"template-type-container\"],[9],[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[28,[\"template-type-btn\",[27,\"if\",[[27,\"eq\",[[23,[\"templateType\"]],\"onboarding\"],null],\" selected\"],null]]]],[3,\"action\",[[22,0,[]],\"changeTemplateType\",\"onboarding\"]],[9],[1,[27,\"t\",[\"dashboard.user_info.onboarding\"],null],false],[10],[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[28,[\"template-type-btn\",[27,\"if\",[[27,\"eq\",[[23,[\"templateType\"]],\"crossboarding\"],null],\" selected\"],null]]]],[3,\"action\",[[22,0,[]],\"changeTemplateType\",\"crossboarding\"]],[9],[1,[27,\"t\",[\"dashboard.user_info.crossboarding\"],null],false],[10],[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[28,[\"template-type-btn\",[27,\"if\",[[27,\"eq\",[[23,[\"templateType\"]],\"offboarding\"],null],\" selected\"],null]]]],[3,\"action\",[[22,0,[]],\"changeTemplateType\",\"offboarding\"]],[9],[1,[27,\"t\",[\"dashboard.user_info.offboarding\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[1,[27,\"dashboard/user-info-content\",null,[[\"templateType\"],[[23,[\"templateType\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"footer\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"board.analytics\"],[[\"class\"],[\"btn waves-effect waves-light background-primary-color\"]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"word.see_list\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/dashboard/user-info.hbs"
    }
  });

  _exports.default = _default;
});