define("teelt-frontend/components/event/campaign-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['campaign-detail'],
    router: Ember.inject.service(),
    modelName: 'user',
    customKeys: [{
      key: 'photo',
      mandatory: true,
      selected: true
    }, {
      key: 'firstname',
      selected: true
    }, {
      key: 'lastname',
      selected: true
    }, {
      key: 'position',
      selected: true
    }, {
      key: 'welcomeDate',
      selected: true
    }, {
      key: 'talent_progress',
      selected: true
    }],
    init: function init() {
      this._super.apply(this, arguments);

      var modelFilter = {};
      modelFilter["campaignId"] = this.campaign.id;
      Ember.set(this, "modelFilter", modelFilter);

      if (this.campaign.users) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.campaign.users[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var user = _step.value;
            Ember.set(user, "campaign", {
              events: this.campaign.events
            });
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }
    },
    actions: {
      modify: function modify() {
        this.router.transitionTo('board.event.campaign-edit', this.campaign.id);
      },
      remove: function remove() {
        Ember.set(this, "showRemoveConfirm", true);
      },
      assign: function assign() {
        this.router.transitionTo('board.event.campaign-assign', this.campaign.id);
      },
      removeConfirmation: function removeConfirmation(response) {
        var _this = this;

        if (response) {
          this.campaign.deleteRecord();
          this.campaign.save().then(function () {
            _this.router.transitionTo('board.events');
          });
        }
      },
      duplicate: function duplicate() {
        this.router.transitionTo('board.event.campaign-new', {
          queryParams: {
            duplicateId: this.campaign.id
          }
        });
      },
      openTalent: function openTalent(id) {// NOTHING
      },
      onCountChanged: function onCountChanged() {},
      onSelectionChanged: function onSelectionChanged() {}
    }
  });

  _exports.default = _default;
});