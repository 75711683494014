define("teelt-frontend/components/settings/integrations/agrume-settings", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['agrume-settings'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      if (!this.session.data.authenticated.user.company.activeAgrume) {
        this.router.transitionTo('board.setting.integrations');
        return;
      }

      var path = _environment.default.APP.API_HOST + "/integrations/agrume/settings";
      this.ajax.requestPromise(path).then(function (agrume) {
        if (!agrume) {
          agrume = {};
        }

        if (agrume.employers) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = agrume.possibleEmployers[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var possibleEmployer = _step.value;
              var _iteratorNormalCompletion2 = true;
              var _didIteratorError2 = false;
              var _iteratorError2 = undefined;

              try {
                for (var _iterator2 = agrume.employers[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                  var employer = _step2.value;

                  if (employer == possibleEmployer.code) {
                    Ember.set(possibleEmployer, 'checked', true);
                  }
                }
              } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                    _iterator2.return();
                  }
                } finally {
                  if (_didIteratorError2) {
                    throw _iteratorError2;
                  }
                }
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        }

        Ember.set(_this2, 'agrume', agrume);
        var employerCustomFieldPath = _environment.default.APP.API_HOST + "/custom-fields?type=multiple_choice";

        _this2.ajax.requestPromise(employerCustomFieldPath).then(function (customFields) {
          Ember.set(_this2, 'employerCustomFields', customFields);
          Ember.set(_this2, 'establishmentCustomFields', customFields);
          Ember.run.later(function () {
            _this2.$('#employerField').select2({});

            if (_this2.agrume.employerCustomFieldId) {
              _this2.$('#employerField').val(_this2.agrume.employerCustomFieldId);

              _this2.$('#employerField').trigger('change');
            }

            var _this = _this2;
            $('#employerField').on('change', function (e) {
              Ember.set(_this.agrume, 'employerCustomFieldId', $(this).val());
            });

            _this2.$('#establishmentField').select2({});

            if (_this2.agrume.establishmentCustomFieldId) {
              _this2.$('#establishmentField').val(_this2.agrume.establishmentCustomFieldId);

              _this2.$('#establishmentField').trigger('change');
            }

            $('#establishmentField').on('change', function (e) {
              Ember.set(_this.agrume, 'establishmentCustomFieldId', $(this).val());
            });
          }, 0);
        });
      });
    },
    verifyAgrume: function verifyAgrume() {
      var ok = true;
      var errors = {};
      Ember.set(this, 'errors', errors);
      return ok;
    },
    loadEstablishments: function loadEstablishments(finished) {
      var _this3 = this;

      var promises = [];
      this.updateEmployerList();
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        var _loop = function _loop() {
          var employer = _step3.value;
          var canLoadEstablishment = false;

          if (_this3.agrume.employers) {
            var _iteratorNormalCompletion4 = true;
            var _didIteratorError4 = false;
            var _iteratorError4 = undefined;

            try {
              for (var _iterator4 = _this3.agrume.employers[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                var selectedEmployer = _step4.value;

                if (employer.code == selectedEmployer) {
                  canLoadEstablishment = true;
                }
              }
            } catch (err) {
              _didIteratorError4 = true;
              _iteratorError4 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
                  _iterator4.return();
                }
              } finally {
                if (_didIteratorError4) {
                  throw _iteratorError4;
                }
              }
            }
          }

          if (canLoadEstablishment) {
            var path = _environment.default.APP.API_HOST + "/integrations/agrume/employers/" + employer.code + "/establishments";
            promises.push(_this3.ajax.requestPromise(path).then(function (establishments) {
              Ember.set(employer, 'establishments', establishments);
            }).catch(function (err) {
              return console.error(err);
            }));
          }
        };

        for (var _iterator3 = this.agrume.possibleEmployers[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          _loop();
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      if (finished) {
        Promise.all(promises).then(function () {
          finished(_this3.agrume.possibleEmployers);
        });
      }
    },
    updateEmployerList: function updateEmployerList() {
      if (this.agrume.possibleEmployers) {
        var selectedEmployers = [];
        var _iteratorNormalCompletion5 = true;
        var _didIteratorError5 = false;
        var _iteratorError5 = undefined;

        try {
          for (var _iterator5 = this.agrume.possibleEmployers[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
            var employer = _step5.value;

            if (employer.checked) {
              selectedEmployers.push(employer.code);
            }
          }
        } catch (err) {
          _didIteratorError5 = true;
          _iteratorError5 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion5 && _iterator5.return != null) {
              _iterator5.return();
            }
          } finally {
            if (_didIteratorError5) {
              throw _iteratorError5;
            }
          }
        }

        Ember.set(this.agrume, 'employers', selectedEmployers);
      }
    },
    actions: {
      previous: function previous() {
        this.router.transitionTo('board.setting.integrations');
      },
      mappingEmployer: function mappingEmployer() {
        var _this4 = this;

        this.updateEmployerList();
        var employerCustomField;

        if (this.agrume.employerCustomFieldId) {
          var _iteratorNormalCompletion6 = true;
          var _didIteratorError6 = false;
          var _iteratorError6 = undefined;

          try {
            for (var _iterator6 = this.employerCustomFields[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
              var customField = _step6.value;

              if (customField.id == this.agrume.employerCustomFieldId) {
                employerCustomField = customField;
                var mappingValues = employerCustomField.mappingValues;

                if (!mappingValues) {
                  mappingValues = {};
                }

                Ember.set(this, 'employerCustomMappings', mappingValues);
                Ember.set(this, 'employerCustomField', employerCustomField);
                break;
              }
            }
          } catch (err) {
            _didIteratorError6 = true;
            _iteratorError6 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion6 && _iterator6.return != null) {
                _iterator6.return();
              }
            } finally {
              if (_didIteratorError6) {
                throw _iteratorError6;
              }
            }
          }
        }

        Ember.set(this, 'showMappingEmployers', true);

        var _this = this;

        setTimeout(function () {
          var _iteratorNormalCompletion7 = true;
          var _didIteratorError7 = false;
          var _iteratorError7 = undefined;

          try {
            var _loop2 = function _loop2() {
              var employer = _step7.value;
              if (!employer.checked) return "continue";
              $('#employer-mapping-' + employer.code).select2();

              if (employerCustomField.mappingValues && employerCustomField.mappingValues[employer.code]) {
                $('#employer-mapping-' + employer.code).val(employerCustomField.mappingValues[employer.code]);
                $('#employer-mapping-' + employer.code).trigger('change');
              }

              $('#employer-mapping-' + employer.code).on('change', function (e) {
                var value = $(this).val();

                if (value && value.length > 0) {
                  if (_this.employerCustomMappings) {
                    _this.employerCustomMappings[employer.code] = value;
                  }
                } else if (_this.employerCustomMappings[employer.code]) {
                  delete _this.employerCustomMappings[employer.code];
                }
              });
            };

            for (var _iterator7 = _this4.agrume.possibleEmployers[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
              var _ret = _loop2();

              if (_ret === "continue") continue;
            }
          } catch (err) {
            _didIteratorError7 = true;
            _iteratorError7 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion7 && _iterator7.return != null) {
                _iterator7.return();
              }
            } finally {
              if (_didIteratorError7) {
                throw _iteratorError7;
              }
            }
          }
        });
      },
      mappingEstablishment: function mappingEstablishment() {
        var _this5 = this;

        this.updateEmployerList();
        this.loadEstablishments(function (employers) {
          var establishmentCustomField;

          if (_this5.agrume.establishmentCustomFieldId) {
            var _iteratorNormalCompletion8 = true;
            var _didIteratorError8 = false;
            var _iteratorError8 = undefined;

            try {
              for (var _iterator8 = _this5.establishmentCustomFields[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
                var customField = _step8.value;

                if (customField.id == _this5.agrume.establishmentCustomFieldId) {
                  establishmentCustomField = customField;
                  var mappingValues = establishmentCustomField.mappingValues;

                  if (!mappingValues) {
                    mappingValues = {};
                  }

                  Ember.set(_this5, 'establishmentCustomMappings', mappingValues);
                  Ember.set(_this5, 'establishmentCustomField', establishmentCustomField);
                  break;
                }
              }
            } catch (err) {
              _didIteratorError8 = true;
              _iteratorError8 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion8 && _iterator8.return != null) {
                  _iterator8.return();
                }
              } finally {
                if (_didIteratorError8) {
                  throw _iteratorError8;
                }
              }
            }
          }

          Ember.set(_this5, 'showMappingEstablishments', true);
          var _this = _this5;
          setTimeout(function () {
            var _iteratorNormalCompletion9 = true;
            var _didIteratorError9 = false;
            var _iteratorError9 = undefined;

            try {
              for (var _iterator9 = employers[Symbol.iterator](), _step9; !(_iteratorNormalCompletion9 = (_step9 = _iterator9.next()).done); _iteratorNormalCompletion9 = true) {
                var employer = _step9.value;
                if (!employer.checked) continue;
                if (!employer.establishments) continue;
                var _iteratorNormalCompletion10 = true;
                var _didIteratorError10 = false;
                var _iteratorError10 = undefined;

                try {
                  var _loop3 = function _loop3() {
                    var establishement = _step10.value;
                    $('#establishment-mapping-' + establishement.id).select2();

                    if (establishmentCustomField.mappingValues && establishmentCustomField.mappingValues[establishement.id]) {
                      $('#establishment-mapping-' + establishement.id).val(establishmentCustomField.mappingValues[establishement.id]);
                      $('#establishment-mapping-' + establishement.id).trigger('change');
                    }

                    $('#establishment-mapping-' + establishement.id).on('change', function (e) {
                      var value = $(this).val();

                      if (value && value.length > 0) {
                        if (_this.establishmentCustomMappings) {
                          _this.establishmentCustomMappings[establishement.id] = value;
                        }
                      } else if (_this.establishmentCustomMappings[establishement.id]) {
                        delete _this.establishmentCustomMappings[establishement.id];
                      }
                    });
                  };

                  for (var _iterator10 = employer.establishments[Symbol.iterator](), _step10; !(_iteratorNormalCompletion10 = (_step10 = _iterator10.next()).done); _iteratorNormalCompletion10 = true) {
                    _loop3();
                  }
                } catch (err) {
                  _didIteratorError10 = true;
                  _iteratorError10 = err;
                } finally {
                  try {
                    if (!_iteratorNormalCompletion10 && _iterator10.return != null) {
                      _iterator10.return();
                    }
                  } finally {
                    if (_didIteratorError10) {
                      throw _iteratorError10;
                    }
                  }
                }
              }
            } catch (err) {
              _didIteratorError9 = true;
              _iteratorError9 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion9 && _iterator9.return != null) {
                  _iterator9.return();
                }
              } finally {
                if (_didIteratorError9) {
                  throw _iteratorError9;
                }
              }
            }
          });
        });
      },
      save: function save() {
        var _this6 = this;

        if (!this.verifyAgrume()) return;
        this.updateEmployerList();
        var path = _environment.default.APP.API_HOST + "/integrations/agrume/settings";
        var method = 'PATCH';
        this.ajax.requestWithBodyPromise(path, method, 'application/json', JSON.stringify(this.agrume)).then(function () {
          _this6.router.transitionTo('board.setting.integrations');
        });
      },
      saveEmployerField: function saveEmployerField() {
        var _this7 = this;

        if (!this.employerCustomField || !this.employerCustomMappings) return;
        var path = _environment.default.APP.API_HOST + "/custom-fields/" + this.employerCustomField.id;
        var method = 'PATCH';
        Ember.set(this.employerCustomField, 'mappingValues', this.employerCustomMappings);
        this.ajax.requestWithBodyPromise(path, method, 'application/json', JSON.stringify(this.employerCustomField)).then(function () {
          _this7.notify.info(_this7.intl.t('settings.integrations.agrume.employer_cf_mapping_completed'));

          Ember.set(_this7, 'showMappingEmployers', false);
        });
      },
      saveEstablishmentField: function saveEstablishmentField() {
        var _this8 = this;

        if (!this.establishmentCustomField || !this.establishmentCustomMappings) return;
        var path = _environment.default.APP.API_HOST + "/custom-fields/" + this.establishmentCustomField.id;
        var method = 'PATCH';
        Ember.set(this.establishmentCustomField, 'mappingValues', this.establishmentCustomMappings);
        this.ajax.requestWithBodyPromise(path, method, 'application/json', JSON.stringify(this.establishmentCustomField)).then(function () {
          _this8.notify.info(_this8.intl.t('settings.integrations.agrume.employer_cf_mapping_completed'));

          Ember.set(_this8, 'showMappingEstablishments', false);
        });
      }
    }
  });

  _exports.default = _default;
});