define("teelt-frontend/templates/components/custom-field/field/custom-multi-autocomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W4PQxS0E",
    "block": "{\"symbols\":[\"talent\",\"index\"],\"statements\":[[7,\"div\"],[11,\"class\",\"input-field\"],[9],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"\"],[3,\"action\",[[22,0,[]],\"focusOnInput\"]],[9],[0,\"\\n    \"],[7,\"li\"],[12,\"class\",[28,[\"keyword-new \",[27,\"if\",[[23,[\"data\",\"length\"]],\"not-empty\"],null]]]],[12,\"id\",[28,[\"keywords-new-\",[23,[\"field\",\"id\"]]]]],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"keywords-container\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"data\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\"],[12,\"class\",[28,[\"keywords keywords-\",[23,[\"field\",\"id\"]]]]],[9],[0,\"\\n              \"],[7,\"span\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"name\"]]],null,{\"statements\":[[0,\"                  \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                  \"],[1,[27,\"talent/talent-name\",[[22,1,[]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"              \"],[10],[0,\"\\n              \"],[7,\"i\"],[11,\"class\",\"material-icons clickable\"],[3,\"action\",[[22,0,[]],\"deleteUser\",[22,2,[]]],[[\"bubbles\"],[false]]],[9],[0,\"clear\"],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"      \"],[10],[0,\"\\n      \"],[7,\"select\"],[12,\"id\",[28,[[27,\"concat\",[\"customField-\",[23,[\"field\",\"id\"]]],null]]]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"onChange\"],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n      \\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/custom-field/field/custom-multi-autocomplete.hbs"
    }
  });

  _exports.default = _default;
});