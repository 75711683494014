define("teelt-frontend/components/settings/notification-settings", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['notification-settings'],
    step: 'list',
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    loadSystemNotifications: function loadSystemNotifications() {
      var _this = this;

      var notifsTypesPrefixes = ['NEW_ACCOUNT_', 'WEEKLY_REPORT_'];
      var roles = {
        "MANAGER": "ROLE_MANAGER",
        "OM": "ROLE_CUSTOM_OFFICE_MANAGER",
        "OFFICE_MANAGER": "ROLE_CUSTOM_OFFICE_MANAGER",
        "RH": "ROLE_RH",
        "ADMINISTRATIVE": "ROLE_ADMINISTRATIVE",
        "MRH": "ROLE_MRH"
      };
      Ember.set(this, 'notifSystems', false);
      var path = _environment.default.APP.API_HOST + '/notifications/systems';
      this.ajax.requestPromise(path).then(function (notifs) {
        var notificationsCustomRoles = [];
        notifs.forEach(function (notif) {
          notif.customRole = false;
          notifsTypesPrefixes.forEach(function (prefixe) {
            if (notif.type.startsWith(prefixe)) {
              var role = notif.type.replace(prefixe, "");

              if (roles[role]) {
                var notificationCustomRole = [];
                notif.customRole = roles[role];
                notif.customType = prefixe + "CUSTOM_ROLE";
                notificationCustomRole.notificationId = notif.id;
                notificationCustomRole.customRole = roles[role];
                notificationCustomRole.customType = prefixe + "CUSTOM_ROLE";
                notificationsCustomRoles.push(notificationCustomRole);
              }
            }
          });
        });
        Ember.set(_this, 'notificationsCustomRoles', notificationsCustomRoles);
        Ember.set(_this, 'notifSystems', notifs);
      });
    },
    actions: {
      editVersion: function editVersion(module) {
        var _this2 = this;

        this.store.findRecord('notification', module.id).then(function (notification) {
          Ember.set(_this2, 'updateVersion', notification);
        });
      },
      handleFocusOut: function handleFocusOut() {
        this.company.save();
      },
      interceptEnter: function interceptEnter(e1, e2) {
        if (e2.keyCode == 13) {
          this.send('handleFocusOut', e2);
          return false;
        }

        return true;
      },
      open: function open(step) {
        Ember.set(this, 'step', step);

        if (step == 'system') {
          this.loadSystemNotifications();
        }
      },
      previous: function previous() {
        Ember.set(this, 'step', 'list');
        Ember.set(this, 'updateNotif', false);
      },
      openModuleActions: function openModuleActions(moduleId) {
        if (this.showActions == moduleId) {
          Ember.set(this, 'showActions', false);
        } else {
          Ember.set(this, 'showActions', moduleId);
        }
      },
      editModule: function editModule(module) {
        var _this3 = this;

        Ember.set(this, 'showActions', false);
        this.store.findRecord('notification', module.id).then(function (notif) {
          if (!notif.companyId) {
            var notificationJSON = notif.toJSON();
            notificationJSON['id'] = undefined;
            var contents = notificationJSON['contents'];

            if (contents && contents.length) {
              contents.forEach(function (c) {
                c.id = undefined;
                var attachments = c.attachments;

                if (attachments && attachments.length) {
                  attachments.forEach(function (a) {
                    return a.id = undefined;
                  });
                }
              });
            }

            var notification = _this3.store.createRecord('notification', notificationJSON);

            Ember.set(_this3, 'updateNotif', notification);
          } else {
            Ember.set(_this3, 'updateNotif', notif);
          }
        });
      },
      onFinish: function onFinish() {
        Ember.set(this, 'updateNotif', false);

        if (this.step == 'system') {
          this.loadSystemNotifications();
        }
      },
      switchDisabled: function switchDisabled(module) {
        var _this4 = this;

        this.store.findRecord('notification', module.id).then(function (notif) {
          if (!notif.companyId) {
            var notificationJSON = notif.toJSON();
            notificationJSON['id'] = undefined;
            var contents = notificationJSON['contents'];

            if (contents && contents.length) {
              contents.forEach(function (c) {
                c.id = undefined;
                var attachments = c.attachments;

                if (attachments && attachments.length) {
                  attachments.forEach(function (a) {
                    return a.id = undefined;
                  });
                }
              });
            }

            var notification = _this4.store.createRecord('notification', notificationJSON);

            Ember.set(notification, 'disabled', !notification.disabled);
            notification.save(function () {
              Ember.set(module, 'disabled', notification.disabled);
            });
          } else {
            Ember.set(notif, 'disabled', !notif.disabled);
            notif.save(function () {
              Ember.set(module, 'disabled', notif.disabled);
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});