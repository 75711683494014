define("teelt-frontend/components/talents/talents-audience-assignment", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talents-audience-assignment'],
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    countUrl: _environment.default.APP.API_HOST + "/users/count",
    modelName: 'user',
    customKeys: [{
      key: 'photo',
      mandatory: true,
      selected: true
    }, {
      key: 'firstname',
      selected: true
    }, {
      key: 'lastname',
      selected: true
    }, {
      key: 'position',
      selected: true
    }, {
      key: 'welcomeDate',
      selected: false
    }, {
      key: 'migrationDate',
      selected: false
    }, {
      key: 'endDate',
      selected: false
    }, {
      key: 'talent_progress',
      selected: true
    }],
    talentActions: [{
      key: 'remove',
      prefixTranslate: 'word.',
      svg: "<svg width=\"15\" height=\"15\" viewBox=\"0 0 10 11\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n          <path d=\"M1.5625 3.52734V8.38242C1.56312 8.58343 1.64563 8.77603 1.79201 8.91817C1.93838 9.0603 2.13674 9.14042 2.34375 9.14102H7.65625C7.86326 9.14042 8.06162 9.0603 8.20799 8.91817C8.35437 8.77603 8.43688 8.58343 8.4375 8.38242V3.52734\" stroke=\"#FF4863\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n          <path d=\"M8.51562 1.85938H1.48438C1.18234 1.85938 0.9375 2.09712 0.9375 2.3904V2.84556C0.9375 3.13884 1.18234 3.37659 1.48438 3.37659H8.51562C8.81766 3.37659 9.0625 3.13884 9.0625 2.84556V2.3904C9.0625 2.09712 8.81766 1.85938 8.51562 1.85938Z\" stroke=\"#FF4863\" stroke-linejoin=\"round\"/>\n          <path d=\"M6.25 6.41174L5 7.62551L3.75 6.41174M5 7.20619V4.89453\" stroke=\"#FF4863\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n          </svg> "
    }],
    init: function init() {
      this._super.apply(this, arguments);

      var modelFilter = {};
      modelFilter[this.assignModel + 'Id'] = this.toAssign.id;
      Ember.set(this, "modelFilter", modelFilter);
      Ember.set(this, 'showTalents', true);

      if (this.toAssign && this.toAssign.type) {
        switch (this.toAssign.type) {
          case "welcome":
            this.selectTalentDateKey('welcomeDate', ['migrationDate', 'endDate']);
            break;

          case "migration":
            this.selectTalentDateKey('migrationDate', ['welcomeDate', 'endDate']);
            break;

          case "end":
            this.selectTalentDateKey('endDate', ['welcomeDate', 'migrationDate']);
            break;
        }
      }

      if (this.session.data.authenticated.user.company.essential) {
        setTimeout(function () {
          var element = document.querySelector('.audience-affect');

          if (element) {
            element.style.display = 'none';
          }
        }, 0);
      }
    },
    selectTalentDateKey: function selectTalentDateKey(key, keysToDisable) {
      var customKeys = this.customKeys;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = customKeys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _customKey = _step.value;

          if (_customKey.key === key) {
            _customKey.selected = true;
          }
        } //search keysToDisable and set selected to false

      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      if (keysToDisable) {
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = keysToDisable[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var keyToDisable = _step2.value;
            var _iteratorNormalCompletion3 = true;
            var _didIteratorError3 = false;
            var _iteratorError3 = undefined;

            try {
              for (var _iterator3 = customKeys[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                var customKey = _step3.value;

                if (customKey.key === keyToDisable) {
                  customKey.selected = false;
                }
              }
            } catch (err) {
              _didIteratorError3 = true;
              _iteratorError3 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
                  _iterator3.return();
                }
              } finally {
                if (_didIteratorError3) {
                  throw _iteratorError3;
                }
              }
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      }

      Ember.set(this, 'customKeys', customKeys);
    },
    getCommonActionParams: function getCommonActionParams() {
      return {
        ids: this.actionParams.selecteds.map(function (id) {
          return Number(id);
        }),
        query: this.actionParams.query,
        complexFilters: this.actionParams.complexFilters ? JSON.stringify(this.actionParams.complexFilters) : undefined,
        selectAll: this.actionParams.selectAll
      };
    },
    onShowTalentAssignModal: Ember.observer('showTalentAssignModal', function () {
      if (this.showTalentAssignModal) {
        document.body.style.setProperty('overflow', 'hidden', 'important');
      } else {
        document.body.style.setProperty('overflow', 'visible');
      }
    }),
    actions: {
      openAudienceSelect: function openAudienceSelect(isStart) {
        var _this = this;

        Ember.set(this, 'startAudiences', undefined);
        Ember.set(this, 'endAudiences', undefined);
        this.store.findAll('audience').then(function (audiences) {
          var selectValues = [];
          var _iteratorNormalCompletion4 = true;
          var _didIteratorError4 = false;
          var _iteratorError4 = undefined;

          try {
            for (var _iterator4 = audiences.toArray()[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
              var audience = _step4.value;
              selectValues.push({
                key: audience.id,
                label: audience.name
              });
            }
          } catch (err) {
            _didIteratorError4 = true;
            _iteratorError4 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
                _iterator4.return();
              }
            } finally {
              if (_didIteratorError4) {
                throw _iteratorError4;
              }
            }
          }

          if (isStart) {
            Ember.set(_this, 'startAudiences', selectValues);
          } else {
            Ember.set(_this, 'endAudiences', selectValues);
          }
        });
      },
      onAudienceChanged: function onAudienceChanged(isStart, audience) {
        var _this2 = this;

        if (audience) {
          var audienceModel = {
            id: audience.key
          };

          if (isStart) {
            Ember.set(this.toAssign, 'startAudience', audienceModel);
          } else {
            Ember.set(this.toAssign, 'endAudience', audienceModel);
          }

          Ember.set(this, 'showTalents', false);
          this.toAssign.save().then(function () {
            Ember.set(_this2, 'startAudiences', undefined);
            Ember.set(_this2, 'endAudiences', undefined);
            Ember.set(_this2, 'showTalents', true);
          });
        }
      },
      remove: function remove(isStart) {
        var _this3 = this;

        Ember.set(this, 'showStartAudienceActions', false);
        Ember.set(this, 'showEndAudienceActions', false);

        if (isStart) {
          Ember.set(this.toAssign, 'startAudience', undefined);
        } else {
          Ember.set(this.toAssign, 'endAudience', undefined);
        }

        Ember.set(this, 'showTalents', false);
        this.toAssign.save().then(function () {
          Ember.set(_this3, 'startAudiences', undefined);
          Ember.set(_this3, 'endAudiences', undefined);
          Ember.set(_this3, 'showTalents', true);
        });
      },
      onCountChanged: function onCountChanged() {},
      onSelectionChanged: function onSelectionChanged() {},
      onRowSelected: function onRowSelected(talent) {
        var queryParams = {};
        queryParams[this.assignModel + "Id"] = this.toAssign.id;
        this.router.transitionTo('board.talent.talent-detail.activities', talent.id, {
          queryParams: queryParams
        });
      },
      onActionLaunched: function onActionLaunched(params) {
        var _this4 = this;

        Ember.set(this, 'actionParams', params);

        switch (params.action) {
          case "remove":
            var queryParams2 = this.getCommonActionParams();
            var params2 = [];

            if (queryParams2.ids) {
              params2.push("ids=" + encodeURIComponent(queryParams2.ids.join(",")));
            }

            if (queryParams2.query) {
              params2.push("query=" + encodeURIComponent(queryParams2.query));
            }

            if (queryParams2.complexFilters) {
              params2.push("complexFilters=" + encodeURIComponent(queryParams2.complexFilters));
            }

            var path = "";

            if (this.assignModel == "campaign") {
              path = _environment.default.APP.API_HOST + '/events/' + this.assignModel + 's/' + this.toAssign.id + "/users";
            } else {
              path = _environment.default.APP.API_HOST + '/' + this.assignModel + 's/' + this.toAssign.id + "/users";
            }

            this.ajax.requestWithBodyPromise(path, 'DELETE', 'application/json', JSON.stringify(queryParams2)).then(function (result) {
              Ember.set(_this4, 'reloadTimestamp', Date.now());
            });
            break;
        }
      },
      onSaveTalentAssign: function onSaveTalentAssign() {
        Ember.set(this, 'showTalentAssignModal', false);
        Ember.set(this, 'reloadTimestamp', Date.now());
      }
    }
  });

  _exports.default = _default;
});