define("teelt-frontend/components/company/presentation-text-image-editable", ["exports", "teelt-frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['presentation-text-image-editable'],
    classNameBindings: ['canEdit'],
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    mobileView: window.innerWidth < 600,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'companyName', this.session.data.authenticated.user.company.name);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.initDropzone();
    },
    updateHomePage: function updateHomePage() {
      var path = _environment.default.APP.API_HOST + '/homepages/' + this.homepage.id;
      this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(this.homepage));
    },
    initDropzone: function initDropzone() {
      var _this = this;

      var myDropzone = new Dropzone(".dropzone-action", {
        url: 'https://www.teelt.io',
        uploadMultiple: false,
        maxFiles: 1,
        createImageThumbnails: false,
        addRemoveLinks: false,
        autoProcessQueue: false,
        acceptedFiles: 'image/jpeg,image/png,image/gif,image/jpg',
        addedfile: function addedfile(file) {
          _this.fileUploadService.signAndUpload(file, false, function (e) {
            return _this.onMediaProgress(e);
          }).then(function (fileUrl) {
            Ember.set(_this.homepage, 'introImageUrl', fileUrl);

            _this.updateHomePage();
          }).finally(function () {});
        }
      });
      Ember.set(this, 'dropzone', myDropzone);
    },
    onMediaProgress: function onMediaProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'mediaProgress', progress);
      }
    },
    actions: {
      openModifyModal: function openModifyModal() {
        Ember.set(this, 'showModifyModal', true);
      },
      openFileDialog: function openFileDialog() {
        this.dropzone.hiddenFileInput.click();
      },
      updateHomePage: function updateHomePage() {
        this.updateHomePage();
      }
    }
  });

  _exports.default = _default;
});