define("teelt-frontend/components/menu/talent-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talent-menu'],
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    totalTaskNumber: Ember.computed('talent.totalEventNumber', 'talent.pastEventNumber', 'talent.remainingTalentTaskNumber', function () {
      var eventnumber = this.talent.totalEventNumber - this.talent.pastEventNumber;
      return eventnumber + this.talent.remainingTalentTaskNumber;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.changeTabFromRouteName();
      this.activeTab = 'home';
    },
    changeTabFromRouteName: function changeTabFromRouteName() {
      var routeName = this.router.get('currentRouteName');
      var tmp = routeName.split('.');
      this.send("changeTab", tmp[1]);

      if (routeName.indexOf("talent.integration.team") >= 0) {
        this.send("changeTab", "team");
      }
    },
    actions: {
      openProfile: function openProfile() {
        this.router.transitionTo('talent.profile.detail');
      },
      openProfileDocuments: function openProfileDocuments() {
        this.router.transitionTo('talent.profile.documents');
      },
      changeTab: function changeTab(tab) {
        Ember.set(this, 'activeTab', tab);
      },
      logout: function logout() {
        var _this = this;

        this.session.invalidate().then(function () {
          _this.router.transitionTo('login.form');
        });
      }
    },
    onRouteChanged: Ember.observer('router.currentRouteName', function () {
      this.changeTabFromRouteName();
    })
  });

  _exports.default = _default;
});