define("teelt-frontend/components/dashboard/dashboard-date-picker", ["exports", "teelt-frontend/config/environment", "teelt-frontend/utils/string-util"], function (_exports, _environment, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    uiUtils: Ember.inject.service('ui-utils'),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'uuid', (0, _stringUtil.default)().generateUUID());

      if (this.range[this.dateKey]) {
        Ember.set(this, 'date', moment(this.range[this.dateKey]));
      } else {
        Ember.set(this, 'date', '');
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.initDatepicker();
    },
    initDatepicker: function initDatepicker() {
      var _this2 = this;

      setTimeout(function () {
        var options = {
          autoClose: false
        };
        var _this = _this2;

        _this2.uiUtils.initDatePicker(_this.$("#dashboard-date-picker-".concat(_this2.uuid)), function (result) {
          if (result) {
            Ember.set(_this2, 'date', moment.tz(result, moment.tz.guess()));

            _this2.updateDate();
          }
        }, options);

        if (_this2.openAtInit) {
          _this.$("#dashboard-date-picker-".concat(_this2.uuid)).datepicker('open');
        }
      });
    },
    updateDate: function updateDate() {
      if (!this.date) return;
      var dateTime = this.date.clone();
      Ember.set(this.range, this.dateKey, dateTime.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'));

      if (this.onChange) {
        this.onChange();
      }
    },
    actions: {}
  });

  _exports.default = _default;
});