define("teelt-frontend/components/company/csv-import-format", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['csvformat-edit', 'g-custom-scrollbar'],
    ajax: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var getCSVFormatUrl = _environment.default.APP.API_HOST + '/companies/' + this.companyId + "/csvformat";
      this.ajax.requestPromise(getCSVFormatUrl).then(function (csvFormat) {
        Ember.set(_this, "csvFormat", csvFormat);
      });
    },
    actions: {
      save: function save() {
        var _this2 = this;

        var getCSVFormatUrl = _environment.default.APP.API_HOST + '/companies/' + this.companyId + "/csvformat";
        this.ajax.requestWithBodyPromise(getCSVFormatUrl, 'POST', 'application/json', JSON.stringify(this.csvFormat)).then(function () {
          _this2.closeCSVImportPopup();
        });
      }
    }
  });

  _exports.default = _default;
});