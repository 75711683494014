define("teelt-frontend/templates/components/custom-field/field/custom-autocomplete-free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V7WqckXx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"input-field custom-autocomplete-free\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"id\",\"class\",\"value\",\"autocomplete\",\"key-up\"],[[27,\"concat\",[\"customField-\",[23,[\"field\",\"id\"]]],null],\"autocomplete\",[23,[\"selectedValue\"]],\"off\",[27,\"action\",[[22,0,[]],\"onChanged\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/custom-field/field/custom-autocomplete-free.hbs"
    }
  });

  _exports.default = _default;
});