define("teelt-frontend/components/custom-field/custom-fields-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['custom-fields-list', 'g-custom-scrollbar'],
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    search: '',
    canAdd: true,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.store.unloadAll('custom-field');

      if (!this.fields) {
        this.store.findAll('custom-field').then(function (fields) {
          Ember.set(_this, 'fields', fields);

          _this.selectFields();
        });
      } else {
        Ember.set(this, 'displayMode', true);
      }
    },
    selectFields: function selectFields() {
      var _this2 = this;

      if (!this.selectedIds || !this.fields) {
        return;
      }

      this.fields.forEach(function (field) {
        if (_this2.selectedIds.includes(Number(field.id))) {
          Ember.set(field, 'selected', true);
        }
      });
    },
    actions: {
      searchCustomFields: function searchCustomFields(search) {
        var _this3 = this;

        this.set('search', search);
        var params = {
          search: this.search
        };
        this.store.query('custom-field', params).then(function (fields) {
          Ember.set(_this3, 'fields', fields);
        });
      },
      editCustomField: function editCustomField(id) {
        this.router.transitionTo('board.custom-field.custom-field-edit', id);
      },
      removeCustomField: function removeCustomField(id) {
        Ember.set(this, "customFieldToRemoveId", id);
        Ember.set(this, "showRemovePopup", true);
      },
      removeConfirm: function removeConfirm(response) {
        var _this4 = this;

        if (response) {
          var customField = this.store.peekRecord('custom-field', this.customFieldToRemoveId);
          customField.deleteRecord();
          customField.save().then(function () {
            _this4.send('searchCustomFields', _this4.search);
          });
        }
      },
      triggerCustomField: function triggerCustomField(field) {
        if (!this.canTriggerCustomField) return;
        field.set('selected', !field.selected);

        if (this.selectedIds) {
          if (field.selected) {
            this.selectedIds.addObject(Number(field.id));
          } else {
            this.selectedIds.removeObject(Number(field.id));
          }
        }
      }
    }
  });

  _exports.default = _default;
});