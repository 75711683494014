define("teelt-frontend/components/talents/talent-information-block-message-modify-modal", ["exports", "teelt-frontend/config/environment", "teelt-frontend/utils/object-util"], function (_exports, _environment, _objectUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    fileUploadService: Ember.inject.service('file-upload'),
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'editableHomepage', (0, _objectUtil.default)().clone(this.homepage));
    },
    didInsertElement: function didInsertElement() {
      var textareas = document.querySelector('.materialize-textarea');

      if (textareas) {
        M.textareaAutoResize(document.querySelector('.materialize-textarea'));
      }
    },
    actions: {
      save: function save() {
        var _this = this;

        var path = _environment.default.APP.API_HOST + '/homepages/' + this.editableHomepage.id;
        this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(this.editableHomepage)).then(function (homepage) {
          Ember.set(_this.homepage, 'managerMessage', homepage.managerMessage);
          Ember.set(_this, 'show', false);
        });
      },
      onClose: function onClose() {
        Ember.set(this, 'show', false);
      }
    }
  });

  _exports.default = _default;
});