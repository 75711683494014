define("teelt-frontend/templates/components/content/content-form-sign-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0rXxg2VM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"content\"]]],null,{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"content\",\"fileOrigin\"]],\"personal\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[9],[1,[27,\"t\",[\"document_sign.personal_file\"],null],false],[10],[0,\"\\n      \"],[7,\"div\"],[9],[7,\"b\"],[9],[1,[23,[\"customField\",\"name\"]],false],[10],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"id\",\"pdf-container\"],[11,\"data-pdfjs\",\"\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"pdf-pages\"],[9],[0,\"\\n      \"],[1,[21,\"currentPage\"],false],[0,\"/\"],[1,[21,\"pageNb\"],false],[0,\"\\n      \"],[7,\"div\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"material-icons left clickable\"],[11,\"style\",\"float:left!important\"],[3,\"action\",[[22,0,[]],\"previousPage\"]],[9],[0,\"chevron_left\"],[10],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"material-icons right clickable\"],[11,\"style\",\"float:right!important\"],[3,\"action\",[[22,0,[]],\"nextPage\"]],[9],[0,\"chevron_right\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/content/content-form-sign-preview.hbs"
    }
  });

  _exports.default = _default;
});