define("teelt-frontend/components/form/field/complex/field-iban", ["exports", "iban"], function (_exports, _iban) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['field-iban'],
    haveCustomError: false,
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      onKeyUp: function onKeyUp() {
        if (!this.value) return;
        var value = this.value.split(" ").join("");

        if (value.length > 0) {
          value = value.match(new RegExp('.{1,4}', 'g')).join("  ");
        }

        Ember.set(this, 'value', value);
      },
      onFocusOut: function onFocusOut() {
        Ember.set(this.field, 'validationError', undefined);
        Ember.set(this, 'haveCustomError', false);

        if (!this.value || this.value.length == 0) {
          if (this.required) {
            Ember.set(this, 'haveCustomError', true);
            Ember.set(this.field, 'validationError', this.intl.t("form.errors.validation.required"));
          }
        } else if (!_iban.default.isValid(this.value)) {
          Ember.set(this, 'haveCustomError', true);
          Ember.set(this.field, 'validationError', this.intl.t("form.errors.validation.iban"));
        }
      }
    }
  });

  _exports.default = _default;
});