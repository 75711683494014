define("teelt-frontend/templates/components/talents/talent-information-block-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9MjVpdvq",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"templateType\"]]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"welcome-date\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"info-block-title\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[[27,\"concat\",[\"talent.my_\",[23,[\"templateType\"]],\"_date\"],null]],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"date\"],[9],[0,\"\\n      \"],[1,[21,\"date\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-information-block-date.hbs"
    }
  });

  _exports.default = _default;
});