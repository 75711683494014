define("teelt-frontend/templates/components/content/contents-documents-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a5IvJBD8",
    "block": "{\"symbols\":[\"content\"],\"statements\":[[4,\"each\",[[23,[\"contents\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"genericContent\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"content/content-document\",null,[[\"content\",\"originContent\",\"canManage\",\"onChange\",\"overrideByOffice\"],[[22,1,[\"genericContent\"]],[22,1,[]],[23,[\"canManage\"]],[27,\"action\",[[22,0,[]],\"reloadContents\"],null],[23,[\"overrideByOffice\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"content/content-document\",null,[[\"content\",\"overrideByOffice\"],[[22,1,[]],[23,[\"overrideByOffice\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/content/contents-documents-list.hbs"
    }
  });

  _exports.default = _default;
});