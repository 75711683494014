define("teelt-frontend/components/ihm/explore-office/explore-office-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['explore-office-item'],
    intl: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.$('.select').formSelect();
      this.addInputListeners();
      Ember.run.later(function () {
        _this.adjustTextareasHeight();

        _this.displayAddStep();
      }, 0);
    },
    actions: {
      onTypeChange: function onTypeChange(type) {
        var _this2 = this;

        Ember.set(this.field, 'type', type);

        if (this.field.type === 'none') {
          Ember.set(this.field, 'description', '');
          Ember.set(this.field, 'advise', '');
        } else {
          Ember.set(this.field, 'description', this.field[type] && this.field[type].description ? this.field[type].description : this.intl.t('explore_office.field.' + type + '.desc.default'));
          Ember.set(this.field, 'advise', this.field[type] && this.field[type].advise ? this.field[type].advise : this.intl.t('explore_office.field.' + type + '.advise.default'));
        }

        Ember.run.later(function () {
          _this2.adjustTextareasHeight();

          _this2.displayAddStep();

          _this2.addInputListeners();
        }, 0);
      },
      delete: function _delete() {
        if (this.onDelete) this.onDelete(this.field);
      },
      onMediaChanged: function onMediaChanged(file) {
        var _this3 = this;

        if (!file) return;
        Ember.set(this, 'blockSave', true);
        var field = this.field;
        this.fileUploadService.signAndUpload(file, false, function (e) {
          return _this3.onMediaProgress(e);
        }).then(function (fileUrl) {
          Ember.set(field, 'pictureUrl', fileUrl);
        }).finally(function () {
          Ember.set(_this3, 'blockSave', false);

          _this3.displayAddStep();
        });
      },
      onInput: function onInput() {
        this.displayAddStep();
      }
    },
    adjustTextareasHeight: function adjustTextareasHeight() {
      var _this4 = this;

      var textareas = document.querySelectorAll('.materialize-textarea');

      if (textareas) {
        textareas.forEach(function (textarea) {
          if (textarea.id !== 'field-clue' + _this4.index) {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
          }
        });
      }
    },
    onMediaProgress: function onMediaProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'mediaProgress', progress);
      }
    },
    addInputListeners: function addInputListeners() {
      var input = document.getElementById('field-code' + this.index);

      if (input) {
        input.addEventListener('input', this.filterNumericInput);
        input.addEventListener('keypress', this.filterNumericKeypress);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var input = document.getElementById('field-code' + this.index);

      if (input) {
        input.removeEventListener('input', this.filterNumericInput);
        input.removeEventListener('keypress', this.filterNumericKeypress);
      }
    },
    filterNumericInput: function filterNumericInput(event) {
      var input = event.target;
      input.value = input.value.replace(/\D/g, '');
    },
    filterNumericKeypress: function filterNumericKeypress(event) {
      if (event.which < 48 || event.which > 57) {
        event.preventDefault();
      }
    },
    displayAddStep: function displayAddStep() {
      Ember.set(this, 'canAddNewStep', false);

      if (this.index < 4) {
        Ember.set(this, 'canAddNewStep', true);
      }
    }
  });

  _exports.default = _default;
});