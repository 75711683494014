define("teelt-frontend/templates/talent/integration/team/members-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p8RBExMt",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"model\",\"team\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"talents/talent-team\",null,[[\"team\",\"onSearch\"],[[23,[\"model\",\"team\"]],[27,\"route-action\",[\"onSearch\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"or\",[[23,[\"model\",\"canLoadMore\"]],[23,[\"model\",\"search\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"controls center\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"btn company-background company-text-color\"],[3,\"action\",[[22,0,[]],\"loadMore\"]],[9],[1,[27,\"t\",[\"word.show_more\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/talent/integration/team/members-list.hbs"
    }
  });

  _exports.default = _default;
});