define("teelt-frontend/components/utils/circle-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['circle-number'],
    width: 50,
    showPercent: function showPercent() {
      var progress = this.$("#progress")[0];

      if (!progress) {
        return;
      }

      var percent = this.percent ? this.percent : 0;
      var perimeter = Math.PI * this.width;
      var stroke_percentage = perimeter - percent / 100 * perimeter;
      progress.style.strokeDasharray = perimeter;
      progress.style.strokeDashoffset = perimeter;
      setTimeout(function () {
        progress.style.strokeDashoffset = stroke_percentage;
      }, 0);
    },
    setColor: function setColor() {
      this.$().removeClass('orange-b');
      this.$().removeClass('green-b');
      this.$().removeClass('red-b');

      if (this.colorClass) {
        this.$().addClass(this.colorClass);
      } else if (this.value === 0) {
        this.$().addClass('green-b');
      } else if (this.value <= 10) {
        this.$().addClass('orange-b');
      } else {
        this.$().addClass('red-b');
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.width) {
        this.set('width', 50);
      }

      this.showPercent();
      this.setColor();
      setTimeout(function () {
        var offset = 0;

        if (_this.inner) {
          offset = _this.strokeWidth ? _this.strokeWidth : 1;
        }

        _this.$().css("width", _this.width + offset);

        _this.$().css("height", _this.width + offset);
      }, 0);
    },
    onWidthChanged: Ember.observer('width', function () {
      this.showPercent();
    }),
    onPercentChanged: Ember.observer('percent', function () {
      this.showPercent();
    }),
    onValueChanged: Ember.observer('value', function () {
      this.setColor();
    })
  });

  _exports.default = _default;
});