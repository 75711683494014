define("teelt-frontend/components/notification/notification-detail", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['notification-detail'],
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    userService: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    selectedLang: "en",
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'isAdmin', this.userService.hasAnyRole(this.session.data.authenticated.user, 'ROLE_ADMIN'));

      if (this.session.data.authenticated.user.lang) {
        Ember.set(this, 'selectedLang', this.session.data.authenticated.user.lang);
      }

      this.initLanguages();

      if (this.notification.contents) {
        var contentOfficeIds = [];
        this.notification.contents.forEach(function (content) {
          if (!content.officeId) return;
          contentOfficeIds.push(content.officeId);
        });
        Ember.set(this, 'contentOfficeIds', contentOfficeIds);
        var selectedTemplateIds = [];

        if (this.notification.templates) {
          this.notification.templates.forEach(function (template) {
            selectedTemplateIds.push(template.id);
          });
        }

        Ember.set(this.notification, 'selectedTemplateIds', selectedTemplateIds);
      }

      this.initAssociatedRoles();
    },
    initAssociatedRoles: function initAssociatedRoles() {
      Ember.set(this, "associatedRoles", this.notification.associatedRole != null ? this.notification.associatedRole.split(" ").filter(function (e) {
        return e != '';
      }) : []);
    },
    initLanguages: function initLanguages() {
      var langs = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.intl.locales[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var locale = _step.value;
          langs.push(locale.substring(0, 2).toUpperCase());
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      Ember.set(this, 'langs', langs);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.notification && this.notification.conditions) {
        Ember.set(this, 'conditions', this.notification.conditions.split(';'));
      }

      this.$('#lang-select').formSelect();
    },
    actions: {
      modify: function modify() {
        this.router.transitionTo('board.notification.notification-edit', this.notification.id);
      },
      remove: function remove() {
        Ember.set(this, "showRemoveConfirm", true);
      },
      duplicate: function duplicate() {
        this.router.transitionTo('board.notification.notification-new', {
          queryParams: {
            duplicateId: this.notification.id
          }
        });
      },
      assign: function assign() {
        this.router.transitionTo('board.notification.notification-template-assign', this.notification.id);
      },
      restore: function restore() {
        Ember.set(this, "showRestoreConfirm", true);
      },
      removeConfirmation: function removeConfirmation(response) {
        var _this = this;

        if (response) {
          this.notification.deleteRecord();
          this.notification.save().then(function () {
            if (_this.notification.isSystem) {
              _this.router.transitionTo('board.emails.notifications-system-list');
            } else {
              _this.router.transitionTo('board.emails.notifications-list');
            }
          });
        }
      },
      onOfficeChanged: function onOfficeChanged(officeId) {
        if (!officeId) officeId = undefined;
        Ember.set(this, 'showOfficeId', Number(officeId));
      },
      onTagChanged: function onTagChanged(tagId) {
        if (!tagId) tagId = undefined;
        Ember.set(this, 'showTagId', Number(tagId));
      },
      onLangChanged: function onLangChanged(lang) {
        Ember.set(this, "selectedLang", lang);
      },
      toggleShowTemplates: function toggleShowTemplates() {
        Ember.set(this, 'showTemplates', !this.showTemplates);
      }
    }
  });

  _exports.default = _default;
});