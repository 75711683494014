define("teelt-frontend/components/talents/talent-detail-navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.changeTabFromRouteName();
      Ember.set(this, "emailIncididentReportStartDate", moment().subtract(1, 'days').startOf('day').hour(9).toDate());
      Ember.set(this, "emailIncididentReportEndDate", moment().startOf('day').hour(9).toDate());
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('#templateSelect').formSelect();
    },
    onTemplateChanged: Ember.observer('talent.template', function () {
      var _this = this;

      setTimeout(function () {
        _this.$('#templateSelect').formSelect();
      });
    }),
    changeTabFromRouteName: function changeTabFromRouteName() {
      var routeName = this.router.get('currentRouteName');

      if (routeName.indexOf('talent-detail.activities') >= 0) {
        this.send("setActiveTab", "activities");
      } else if (routeName.indexOf('talent-detail.actions') >= 0) {
        this.send("setActiveTab", "actions");
      } else if (routeName.indexOf('talent-detail.my-actions') >= 0) {
        this.send("setActiveTab", "my_actions");
      } else if (routeName.indexOf('talent-detail.forms') >= 0) {
        this.send("setActiveTab", "forms");
      } else if (routeName.indexOf('talent-detail.administrative') >= 0) {
        this.send("setActiveTab", "administrative");
      } else if (routeName.indexOf('talent-detail.calendar') >= 0) {
        this.send("setActiveTab", "calendar");
      } else if (routeName.indexOf('talent-detail.ihm') >= 0) {
        this.send("setActiveTab", "ihm");
      }
    },
    actions: {
      setActiveTab: function setActiveTab(tab) {
        Ember.set(this, "currentTab", tab);
      },
      onTemplateChanged: function onTemplateChanged(templateId) {
        Ember.set(this.talent, 'selectedTemplateId', Number(templateId));
        this.eventBus.trigger('REFRESH_ROUTE');
      }
    },
    onRouteChanged: Ember.observer('router.currentRouteName', function () {
      this.changeTabFromRouteName();
    })
  });

  _exports.default = _default;
});