define("teelt-frontend/helpers/is-module-name-croped", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isModuleNameCroped = isModuleNameCroped;
  _exports.default = void 0;

  function isModuleNameCroped(params
  /*, hash*/
  ) {
    if (window.screen.width < 992) {
      if (params[0].length > 12) return true;
    } else if (window.screen.width >= 992) {
      if (params[0].length > 48) return true;
    } else {
      return false;
    }
  }

  var _default = Ember.Helper.helper(isModuleNameCroped);

  _exports.default = _default;
});