define("teelt-frontend/components/utils/bar-chart", ["exports", "chart.js"], function (_exports, _chart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
      });
      Ember.set(this, 'id', id);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.initChartFromProps();
    },
    initChartFromProps: function initChartFromProps() {
      var percentages = [];

      if (this.calculatePercent) {
        var total = this.data.values.reduce(function (acc, value) {
          return acc + value;
        }, 0);
        percentages = this.data.values.map(function (value) {
          return Math.round(value / total * 100 * 10) / 10;
        });
      } else {
        percentages = this.data.values;
      }

      var percentYAxis = this.percentYAxis;
      var percentXAxis = this.percentXAxis;
      var colors = this.colors;
      var data = {
        labels: this.data.labels,
        datasets: [{
          data: this.percentYAxis ? percentages : this.data.values,
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1
        }]
      };
      var type = this.barType ? this.barType : 'bar';
      var showPercentOnBars = this.showPercentOnBars;
      var config = {
        type: type,
        data: data,
        showPercentOnBars: showPercentOnBars,
        options: {
          responsive: true,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                fontSize: 18,
                callback: function callback(value) {
                  var val = value.length > 20 ? value.substring(0, 17) + '...' : value;
                  val = percentYAxis && type != 'horizontalBar' ? val + '%' : val;
                  return val;
                }
              }
            }],
            xAxes: [{
              ticks: {
                beginAtZero: true,
                fontSize: 12,
                callback: function callback(value) {
                  var val = value.length > 20 ? value.substring(0, 17) + '...' : value;
                  val = percentXAxis ? val + '%' : val;
                  return val;
                }
              }
            }]
          },
          legend: {
            display: false
          },
          tooltips: {
            bodyFontSize: 18,
            titleFontSize: 18,
            callbacks: {
              title: function title(tooltipItems, data) {
                var title = tooltipItems[0].xLabel;
                if (!title || typeof title !== 'string') return title;
                return title.split(' ').reduce(function (acc, word) {
                  if (acc.length && acc[acc.length - 1].length + word.length > 40) {
                    acc.push('');
                  }

                  acc[acc.length - 1] += word + ' ';
                  return acc;
                }, ['']);
              },
              label: function label(tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var currentValue = dataset.data[tooltipItem.index];
                var percentage = percentYAxis ? currentValue + '%' : currentValue;
                return percentage;
              }
            }
          },
          hover: {
            animationDuration: 0
          },
          animation: {
            duration: 1,
            onComplete: function onComplete() {
              var chartInstance = this.chart,
                  ctx = chartInstance.ctx;
              ctx.font = _chart.default.helpers.fontString(_chart.default.defaults.global.defaultFontSize, _chart.default.defaults.global.defaultFontStyle, _chart.default.defaults.global.defaultFontFamily);

              if (type == 'horizontalBar') {
                ctx.textAlign = 'right';
                ctx.textBaseline = 'middle';
              } else {
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
              }

              this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                  var data = dataset.data[index];

                  if (!isNaN(data) && data > 0) {
                    data = parseFloat(data);
                    data = percentYAxis ? data + '%' : data;
                    if (!showPercentOnBars) return;

                    if (type == 'horizontalBar') {
                      ctx.fillText(data, bar._model.x - 5, bar._model.y);
                    } else {
                      ctx.fillText(data, bar._model.x, bar._model.y + 15);
                    }
                  }
                });
              });
            }
          }
        }
      };
      var barChart = new _chart.default(document.getElementById(this.id), config);
    }
  });

  _exports.default = _default;
});