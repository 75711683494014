define("teelt-frontend/templates/components/talents/talent-tasks-by-period", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NpXgFtEN",
    "block": "{\"symbols\":[\"period\",\"index\"],\"statements\":[[7,\"div\"],[11,\"class\",\"task-selection\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"right-actions float-right\"],[9],[0,\"\\n     \"],[7,\"button\"],[12,\"class\",[28,[\"btn g-talent-btn \",[27,\"if\",[[27,\"eq\",[[23,[\"taskView\"]],\"todo\"],null],\"selected\"],null]]]],[3,\"action\",[[22,0,[]],\"toggleTaskView\",\"todo\"]],[9],[1,[27,\"t\",[\"task.to_realise_short\"],null],false],[10],[0,\"\\n     \"],[7,\"button\"],[12,\"class\",[28,[\"btn g-talent-btn \",[27,\"if\",[[27,\"eq\",[[23,[\"taskView\"]],\"done\"],null],\"selected\"],null]]]],[3,\"action\",[[22,0,[]],\"toggleTaskView\",\"done\"]],[9],[1,[27,\"t\",[\"task.done\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[1,[27,\"t\",[\"talent.your_actions\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"periods\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"talents/talent-tasks-by-period-period\",null,[[\"talent\",\"period\",\"taskView\",\"tasksCompleted\",\"tasksToRealise\",\"highlight\",\"showPeriod\",\"forceContentTaskId\",\"periodIndex\"],[[23,[\"talent\"]],[22,1,[]],[23,[\"taskView\"]],[23,[\"tasksCompleted\"]],[23,[\"tasksToRealise\"]],[27,\"lte\",[[22,1,[\"index\"]],[23,[\"talentCurrentPeriod\"]]],null],[22,1,[\"showPeriod\"]],[23,[\"forceContentTaskId\"]],[22,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\"],[1,[27,\"talents/talent-tasks-by-period-period-completed\",null,[[\"showPeriod\",\"highlight\"],[[27,\"eq\",[[23,[\"talent\",\"talentProgression\"]],100],null],[27,\"eq\",[[23,[\"talent\",\"talentProgression\"]],100],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-tasks-by-period.hbs"
    }
  });

  _exports.default = _default;
});