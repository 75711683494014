define("teelt-frontend/models/content", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    category: _emberData.default.attr(),
    categoryId: _emberData.default.attr('number'),
    type: _emberData.default.attr('string'),
    section: _emberData.default.attr('string'),
    mediaUrl: _emberData.default.attr('string'),
    fullValue: _emberData.default.attr('string'),
    template: _emberData.default.attr(),
    conditions: _emberData.default.attr(),
    offices: _emberData.default.attr(),
    genericContent: _emberData.default.attr(),
    toDownload: _emberData.default.attr('boolean'),
    isVisible: _emberData.default.attr('boolean'),
    audience: _emberData.default.attr(),
    parameters: _emberData.default.attr(),
    defaultContentId: _emberData.default.attr(),
    thumbnailUrl: _emberData.default.attr('string'),
    overrideWithAudience: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});