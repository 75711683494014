define("teelt-frontend/components/audience/audience-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['audience-selection'],
    store: Ember.inject.service(),
    page: 0,
    limit: 6,
    search: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.loadAudiences();
    },
    loadAudiences: function loadAudiences() {
      var _this = this;

      Ember.set(this, 'canLoadMore', false);
      this.store.query('audience', {
        offset: this.page * this.limit,
        limit: this.limit,
        search: this.search
      }).then(function (audiences) {
        if (audiences && audiences.length >= _this.limit) {
          Ember.set(_this, 'canLoadMore', true);
        }

        Ember.set(_this, 'audiences', audiences);
      });
    },
    actions: {
      next: function next() {
        Ember.set(this, 'page', this.page + 1);
        this.loadAudiences();
      },
      prev: function prev() {
        Ember.set(this, 'page', this.page - 1);
        this.loadAudiences();
      },
      selectAudience: function selectAudience(audience) {
        if (audience) {
          Ember.set(this.campaign, 'audience', {
            id: audience.id
          });
        } else {
          Ember.set(this.campaign, 'audience', undefined);
        }
      },
      onSearchKeyUp: function onSearchKeyUp() {
        var _this2 = this;

        if (this.searchCall) {
          clearTimeout(this.searchCall);
        }

        var searchCall = setTimeout(function () {
          _this2.loadAudiences();
        }, 300);
        Ember.set(this, 'searchCall', searchCall);
      }
    }
  });

  _exports.default = _default;
});