define("teelt-frontend/models/statics/content-category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    order: _emberData.default.attr('number'),
    contents: _emberData.default.attr(),
    genericContentCategoryId: _emberData.default.attr('number'),
    contentCategory: _emberData.default.attr(),
    description: _emberData.default.attr('string'),
    filter: _emberData.default.attr('string'),
    nameOverride: Ember.computed('name', 'contentCategory', function () {
      if (!this.contentCategory) return this.name;
      return this.contentCategory.name;
    })
  });

  _exports.default = _default;
});