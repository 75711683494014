define("teelt-frontend/components/task/task-translation", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['new-task'],
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    step: 0,
    error: {},
    displayControls: true,
    selectedLang: "en",
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.set(this, 'taskTranslations', [{
        lang: "fr"
      }, {
        lang: "en"
      }, {
        lang: "es"
      }, {
        lang: "de"
      }, {
        lang: "it"
      }, {
        lang: "pt"
      }, {
        lang: "pl"
      }]);
      var tempData = this.taskTranslations;
      this.editTask.taskTranslations.forEach(function (taskTranslation) {
        var lang = taskTranslation.lang;
        var index = tempData.findIndex(function (taskTranslation) {
          return taskTranslation.lang == lang;
        });

        if (index > -1) {
          tempData[index] = taskTranslation;
        }

        Ember.set(_this, 'taskTranslations', null);
        Ember.set(_this, 'taskTranslations', tempData);
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('.step').hide();
      this.$('.step' + this.step).show(400);
    },
    actions: {
      openTaskTranslationActions: function openTaskTranslationActions(taskTranslationId) {
        if (this.showTranslationActions == taskTranslationId) {
          Ember.set(this, 'showTranslationActions', false);
        } else {
          Ember.set(this, 'showTranslationActions', taskTranslationId);
        }
      },
      deleteTaskTranslation: function deleteTaskTranslation(taskTranslationtoDelete) {
        var _this2 = this;

        var path = _environment.default.APP.API_HOST + '/tasks/' + this.editTask.id + '/tasktranslations/' + taskTranslationtoDelete.id;
        this.ajax.requestPromise(path, 'DELETE').then(function () {
          var tempData = _this2.taskTranslations;

          var index = _this2.taskTranslations.findIndex(function (taskTranslation) {
            return taskTranslationtoDelete.id == taskTranslation.id;
          });

          tempData.splice(index, 1, {
            lang: taskTranslationtoDelete.lang
          });
          Ember.set(_this2, 'taskTranslations', null);
          Ember.set(_this2, 'taskTranslations', tempData);

          if (_this2.onFinish) {
            _this2.onFinish();
          }

          Ember.set(_this2, 'showTranslationActions', false);
        });
      },
      addTaskTranslation: function addTaskTranslation(taskTranslation) {
        Ember.set(this, 'selectedTaskTranslation', taskTranslation);
        this.$('.step').hide(400);
        Ember.set(this, 'step', this.step + 1);
        this.$('.step' + this.step).show(400);
      },
      previous: function previous() {
        this.$('.step').hide(400);
        Ember.set(this, 'step', this.step - 1);
        this.$('.step' + this.step).show(400);
      },
      next: function next() {
        this.$('.step' + this.step).show(400);
        Ember.set(this, 'step', this.step + 1);
        this.$('.step' + this.step).show(400);
      },
      save: function save() {
        var _this3 = this;

        var path = _environment.default.APP.API_HOST + '/tasks/' + this.editTask.id + '/tasktranslations';
        this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(this.selectedTaskTranslation)).then(function (response) {
          Ember.set(_this3, 'selectedTaskTranslation', response);
          var tempData = _this3.taskTranslations;

          var index = _this3.taskTranslations.findIndex(function (taskTranslation) {
            return taskTranslation.lang == response.lang;
          });

          tempData[index] = response;
          Ember.set(_this3, 'taskTranslations', null);
          Ember.set(_this3, 'taskTranslations', tempData);

          _this3.send('previous');

          if (_this3.onFinish) {
            _this3.onFinish();
          }

          Ember.set(_this3, 'showTranslationActions', false);
        });
      }
    }
  });

  _exports.default = _default;
});