define("teelt-frontend/templates/components/form/field/field-opinion-labels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wUZVo8B3",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"input-field number\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"autofocus\",\"min\",\"max\",\"class\"],[\"number\",[23,[\"field\",\"properties\",\"fromValue\"]],\"true\",\"0\",\"1\",[27,\"if\",[[23,[\"wrongFromValue\"]],\"invalid\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"os-to\"],[9],[1,[27,\"t\",[\"form.field.os_to\"],null],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"input-field number\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"min\",\"max\",\"class\"],[\"number\",[23,[\"field\",\"properties\",\"toValue\"]],\"5\",\"10\",[27,\"if\",[[23,[\"wrongToValue\"]],\"invalid\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"input-field\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\",\"autofocus\",\"class\",\"maxlength\",\"placeholder\"],[[23,[\"field\",\"properties\",\"labels\",\"left\"]],\"true\",[27,\"if\",[[23,[\"nameIsNull\"]],\"invalid\"],null],\"20\",[27,\"t\",[\"form.field.os_labels.left\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"input-field\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\",\"autofocus\",\"class\",\"maxlength\",\"placeholder\"],[[23,[\"field\",\"properties\",\"labels\",\"right\"]],\"true\",[27,\"if\",[[23,[\"nameIsNull\"]],\"invalid\"],null],\"20\",[27,\"t\",[\"form.field.os_labels.right\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/form/field/field-opinion-labels.hbs"
    }
  });

  _exports.default = _default;
});