define("teelt-frontend/helpers/auto-break-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.autoBreakLine = autoBreakLine;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function autoBreakLine(_ref
  /*, hash*/
  ) {
    var _ref2 = _slicedToArray(_ref, 2),
        text = _ref2[0],
        maxLineLength = _ref2[1];

    var words = text.split(' ');
    var currentLine = '';
    var result = '';
    words.forEach(function (word) {
      if (word.length > 20) {
        // Si le mot est plus long que 20 caractères, le couper et ajouter une rupture de ligne
        for (var i = 0; i < word.length; i += 20) {
          result += word.substring(i, i + 20) + '<br>';
        }

        currentLine = ''; // Reset currentLine after breaking long word
      } else {
        if ((currentLine + word).length <= maxLineLength) {
          // Si le mot peut être ajouté à la ligne actuelle
          currentLine += (currentLine ? ' ' : '') + word;
        } else {
          // Ajouter la ligne actuelle au résultat et commencer une nouvelle ligne
          result += currentLine + '<br>';
          currentLine = word;
        }
      }
    }); // Ajouter la dernière ligne restante

    if (currentLine) {
      result += currentLine;
    }

    return result;
  }

  var _default = Ember.Helper.helper(autoBreakLine);

  _exports.default = _default;
});