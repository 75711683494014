define("teelt-frontend/services/file-upload", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    customErrorService: Ember.inject.service('custom-error'),
    uploadFile: function uploadFile(file, uploadUrl, onProgress, customName) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', uploadUrl);

        var csrfToken = _this.ajax.getCSRFToken();

        var authToken = _this.ajax.getXAuthToken();

        if (csrfToken) {
          xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
        }

        if (authToken) {
          xhr.setRequestHeader('X-AUTH-TOKEN', authToken);
        }

        xhr.withCredentials = true;
        xhr.responseType = 'json';

        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              resolve(xhr);
            } else {
              reject(xhr);
            }
          }
        };

        if (xhr.upload && onProgress) {
          xhr.upload.addEventListener("progress", onProgress, false);
        }

        var formData = new FormData();
        formData.append('files', file);

        if (customName) {
          formData.append('customName', customName);
        }

        xhr.send(formData);
      });
    },
    signAndUpload: function signAndUpload(file, cfdtl, progressCallback, customName) {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        var uploadUrl = _environment.default.APP.API_HOST + '/files/upload?cfdtl=' + cfdtl;

        _this2.uploadFile(file, uploadUrl, progressCallback, customName).then(function (xhr) {
          var data = xhr.response;
          resolve(data.url);
        }).catch(function (err) {
          _this2.customErrorService.handleAjaxError(err);

          reject(err);
        });
      });
    },
    getObjectUrl: function getObjectUrl(url) {
      var path = _environment.default.APP.API_HOST + '/files/getObject';
      return this.ajax.requestWithBodyPromise(path, 'POST', 'application/json', JSON.stringify({
        url: url
      }));
    },
    getObjectBase64: function getObjectBase64(url) {
      var path = _environment.default.APP.API_HOST + '/files/downloadbase64?url=' + encodeURIComponent(url);
      return this.ajax.requestPromise(path);
    }
  });

  _exports.default = _default;
});