define("teelt-frontend/services/form-zip-service", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    downloadFormZip: function downloadFormZip(formIds, fileName, talentId) {
      console.log('DOWNLOAD FORM ZIP', formIds, fileName, talentId);
      fileName = fileName.replace(' ', '-') + '.zip';
      var path = _environment.default.APP.API_HOST + '/forms/results/zip/' + fileName + '?formIds=' + formIds.join(",");

      if (talentId) {
        path += "&talentId=" + talentId;
      }

      if (talentId) {
        var element = document.createElement('a');
        element.setAttribute('href', path);
        element.setAttribute('download', true);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        this.notify.info(this.intl.t('downloading.message'));
      } else {
        this.ajax.requestPromise(path);
        this.notify.info(this.intl.t('downloading.message_all'));
      }
    }
  });

  _exports.default = _default;
});