define("teelt-frontend/components/form/form-versions-edit", ["exports", "jquery", "teelt-frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['content-versions-edit'],
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.loadVersions();
    },
    audiences: Ember.computed('allAudiences', 'versions', function () {
      if (!this.allAudiences) return;

      if (this.versions) {
        var audiencesUsed = this.versions.map(function (v) {
          return v.audience.id;
        });
        var audiences = this.allAudiences.filter(function (a) {
          return audiencesUsed.indexOf(Number(a.key)) < 0;
        });
        return audiences;
      }

      return this.allAudiences;
    }),
    loadVersions: function loadVersions() {
      var _this = this;

      var path = _environment.default.APP.API_HOST + '/forms/' + this.defaultForm.id + '/versions';

      if (this.defaultForm.type === 'ADMINISTRATIVE') {
        path += '?administrative=true';
      }

      this.ajax.requestPromise(path).then(function (versions) {
        Ember.set(_this, 'versions', versions);
      });
    },
    loadAudiences: function loadAudiences() {
      var _this2 = this;

      this.store.query('audience', {
        limit: 500
      }).then(function (audiences) {
        var selectValues = [];
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = audiences.toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var audience = _step.value;
            selectValues.push({
              key: audience.id,
              label: audience.name
            });
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        Ember.set(_this2, 'allAudiences', selectValues);
      });
    },
    actions: {
      createNewVersion: function createNewVersion() {
        var dataNewForm = this.defaultForm.toJSON();
        dataNewForm.genericForm = {
          id: this.defaultForm.id
        };
        var newForm = this.store.createRecord('form', dataNewForm);
        this.loadAudiences();
        Ember.set(this, 'newForm', newForm);
      },
      editForm: function editForm(form) {
        var _this3 = this;

        Ember.set(this, 'showActions', false);
        this.store.findRecord('form', form.id).then(function (response) {
          Ember.set(_this3, 'editForm', response);
          Ember.set(_this3.editForm, 'defaultFormId', form.defaultFormId);
        });
      },
      duplicateForm: function duplicateForm(form) {
        Ember.set(this, 'showActions', false);

        if (!form) {
          form = this.defaultForm.toJSON();
        }

        var dataNewForm = JSON.parse(JSON.stringify(form));
        dataNewForm.genericForm = {
          id: this.defaultForm.id
        };
        dataNewForm.id = undefined;
        var newForm = this.store.createRecord('form', dataNewForm);
        this.loadAudiences();
        Ember.set(this, 'newForm', newForm);
      },
      openDeleteForm: function openDeleteForm(form) {
        Ember.set(this, 'showActions', false);
        Ember.set(this, 'formToDelete', form);
      },
      removeForm: function removeForm(response) {
        var _this4 = this;

        if (response) {
          var type = this.formToDelete.type === 'ADMINISTRATIVE' ? 'administrative' : 'form';
          var path = _environment.default.APP.API_HOST + '/modules/' + type + '/' + this.formToDelete.id;
          this.ajax.requestPromise(path, 'DELETE').then(function () {
            _this4.loadVersions();
          }).catch(function (error) {});
        }

        Ember.set(this, 'formToDelete', undefined);
      },
      onClickOverlay: function onClickOverlay() {
        Ember.set(this, 'showPreviewDialog', false);
      },
      onAudienceChanged: function onAudienceChanged(audience) {
        Ember.set(this.newForm, 'audience', {
          id: Number(audience.key),
          name: audience.label
        });
      },
      continueToEditForm: function continueToEditForm() {
        if (!this.newForm.audience || !this.newForm.audience.id) return;
        Ember.set(this, 'editForm', this.newForm);
        Ember.set(this, 'newForm', false);
      },
      onFinish: function onFinish() {
        Ember.set(this, 'editForm', false);
        this.loadVersions();
      },
      previous: function previous() {
        Ember.set(this, 'editForm', false);
      },
      showPreview: function showPreview(form) {
        Ember.set(this, 'showPreviewDialog', true);
        Ember.set(this, 'preview', form);
      }
    }
  });

  _exports.default = _default;
});