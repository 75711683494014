define("teelt-frontend/services/get-slack-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    data: null,
    setData: function setData(data) {
      this.set('data', data);
    },
    getData: function getData() {
      return this.get('data');
    }
  });

  _exports.default = _default;
});