define("teelt-frontend/templates/board/setting/teelt-ai", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "injjXPTa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"section-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"setting-header\"],[9],[0,\"\\n            \"],[7,\"h1\"],[9],[1,[27,\"t\",[\"settings.ai.title\"],null],false],[10],[0,\"\\n            \"],[7,\"p\"],[9],[1,[27,\"t\",[\"settings.ai.baseline\"],null],false],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"setting-items item-containers \"],[9],[0,\"\\n\\n            \"],[7,\"div\"],[11,\"class\",\"items-container\"],[9],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"item-container\"],[9],[0,\"\\n                    \"],[7,\"div\"],[11,\"class\",\"item-title setting-item-title\"],[9],[0,\"\\n                        \"],[1,[27,\"t\",[\"ai.description.title\"],null],false],[0,\"\\n                    \"],[10],[0,\"\\n                    \"],[7,\"div\"],[11,\"class\",\"item-description setting-item-description\"],[9],[0,\"\\n                        \"],[1,[27,\"t\",[\"ai.description.baseline\"],null],false],[0,\"\\n                    \"],[10],[0,\"\\n                    \"],[1,[27,\"textarea\",null,[[\"id\",\"value\",\"key-up\"],[\"aiDescText\",[23,[\"model\",\"aiDescription\"]],[27,\"route-action\",[\"handleKeyUp\"],null]]]],false],[0,\"\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/setting/teelt-ai.hbs"
    }
  });

  _exports.default = _default;
});