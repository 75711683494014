define("teelt-frontend/services/ajax", ["exports", "jquery", "moment"], function (_exports, _jquery, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    storage: Ember.inject.service(),
    errorCount: 0,
    notConnectedErrorShown: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _jquery.default)(document).ajaxError(function (event, xhr, ajaxOptions, thrownError) {
        if (xhr.readyState == 0 && xhr.statusText !== 'abort' && !_this.notConnectedErrorShown) {
          Ember.set(_this, 'notConnectedErrorShown', true);

          _this.notify.error(_this.intl.t('page_not_found.not_connected'));

          setTimeout(function () {
            Ember.set(_this, 'notConnectedErrorShown', false);
          }, 500);
        }
      });
    },
    getCSRFToken: function getCSRFToken() {
      return this.csrfToken;
    },
    getXAuthToken: function getXAuthToken() {
      return this.xAuthToken;
    },
    request: function request(url, type, success_callback, error_callback) {
      var params = {
        url: url,
        type: type
      };
      this.requestParams(params, success_callback, error_callback);
    },
    requestPromise: function requestPromise(url, type) {
      var params = {
        url: url,
        type: type
      };
      return this.requestParamsPromise(params);
    },
    requestWithUrlParam: function requestWithUrlParam(url, type, data, success_callback, error_callback) {
      var params = {
        url: url,
        type: type,
        data: data
      };
      this.requestParams(params, success_callback, error_callback);
    },
    requestWithUrlParamPromise: function requestWithUrlParamPromise(url, type, data) {
      var params = {
        url: url,
        type: type,
        data: data
      };
      return this.requestParamsPromise(params);
    },
    requestWithBody: function requestWithBody(url, type, contentType, data, success_callback, error_callback) {
      var params = {
        url: url,
        type: type,
        contentType: contentType,
        data: data
      };
      this.requestParams(params, success_callback, error_callback);
    },
    requestWithBodyPromise: function requestWithBodyPromise(url, type, contentType, data) {
      var params = {
        url: url,
        type: type,
        contentType: contentType,
        data: data
      };
      return this.requestParamsPromise(params);
    },
    requestBlobPromise: function requestBlobPromise(url, type, blobresponse) {
      var params = {
        url: url,
        type: type,
        success: blobresponse
      };
      return this.requestParamsPromise(params);
    },
    requestParams: function requestParams(params, success_callback, error_callback) {
      var _this2 = this;

      params["xhrFields"] = {
        withCredentials: true
      };

      params.beforeSend = function (xhr) {
        xhr.setRequestHeader("gmt-offset", (0, _moment.default)().utcOffset());

        var csrfToken = _this2.getCSRFToken();

        if (csrfToken) {
          xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
        }

        if (_this2.xAuthToken) {
          xhr.setRequestHeader('x-auth-token', _this2.xAuthToken);
        }
      };

      _jquery.default.ajax(params).then(function (data, status, xhr) {
        _this2.treatCSRF(xhr);

        _this2.updateSecurityTokens(xhr);

        success_callback(data);
      }, function (data, status, xhr) {
        if (data.status === 201 || data.status === 200) {
          _this2.treatCSRF(xhr);

          _this2.updateSecurityTokens(xhr);

          success_callback(data);
        } else {
          error_callback(data);

          _this2.treatError(data);
        }
      }, function (data) {
        if (data.status === 401) {
          if (params.url.indexOf('logout') < 0) {
            _this2.get("session").invalidate();
          }
        }

        error_callback(data);

        _this2.treatError(data);
      });
    },
    requestParamsPromise: function requestParamsPromise(params) {
      var _this3 = this;

      params["xhrFields"] = {
        withCredentials: true
      };

      params.beforeSend = function (xhr) {
        xhr.setRequestHeader("gmt-offset", (0, _moment.default)().utcOffset());

        var csrfToken = _this3.getCSRFToken();

        if (csrfToken) {
          xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
        }

        if (_this3.xAuthToken) {
          xhr.setRequestHeader('x-auth-token', _this3.xAuthToken);
        }
      };

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax(params).then(function (data, status, xhr) {
          _this3.treatCSRF(xhr);

          _this3.updateSecurityTokens(xhr);

          resolve(data, xhr);
        }, function (data) {
          if (data.status === 401 && _this3.get("session.isAuthenticated")) {
            _this3.get("session").invalidate();
          }

          if (data.status === 201 || data.status === 200) {
            _this3.treatCSRF(xhr);

            _this3.updateSecurityTokens(xhr);

            resolve(data);
          } else {
            _this3.verifyErrorCount();

            reject(data);
          }
        });
      });
    },
    treatCSRF: function treatCSRF(xhr) {
      var csrfToken = xhr.getResponseHeader('X-CSRF-TOKEN');

      if (csrfToken) {
        Ember.set(this, 'csrfToken', csrfToken);
      }
    },
    updateSecurityTokens: function updateSecurityTokens(xhr) {
      var xAuthToken = xhr.getResponseHeader('X-Auth-Token');

      if (xAuthToken) {
        this.storage.store('xAuthToken', xAuthToken, true);
        this.xAuthToken = xAuthToken;
      }
    },
    reloadSecurityTokensFromStore: function reloadSecurityTokensFromStore() {
      var xAuthToken = this.storage.find('xAuthToken', true);

      if (xAuthToken) {
        this.xAuthToken = xAuthToken;
      }
    },
    verifyErrorCount: function verifyErrorCount() {
      var _this4 = this;

      Ember.set(this, 'errorCount', this.errorCount + 1);
      console.log('ERROR_COUNT', this.errorCount);

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      var timeout = setTimeout(function () {
        if (_this4.errorCount > 3) {
          Ember.set(_this4, 'errorCount', 0);

          _this4.get("session").invalidate();
        } else {
          Ember.set(_this4, 'errorCount', 0);
        }
      }, 2000);
      Ember.set(this, 'timeout', timeout);
    }
  });

  _exports.default = _default;
});