define("teelt-frontend/templates/components/event/event-date-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ONlPtqdL",
    "block": "{\"symbols\":[\"timeSelect\",\"timeSelect\"],\"statements\":[[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"timeKey\"]],\"startTime\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"time-block\"],[9],[0,\"\\n    \"],[7,\"select\"],[11,\"id\",\"time-select\"],[12,\"class\",[28,[\"select \",[21,\"class\"],\" \"]]],[12,\"disabled\",[21,\"disabled\"]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"TimeChanged\"],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"times\"]]],null,{\"statements\":[[0,\"            \"],[7,\"option\"],[12,\"selected\",[27,\"eq\",[[23,[\"event\",\"startTime\"]],[22,2,[]]],null]],[9],[1,[22,2,[]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"timeKey\"]],\"endTime\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"time-block\"],[9],[0,\"\\n    \"],[7,\"select\"],[11,\"id\",\"time-select\"],[11,\"class\",\"select invalid \"],[12,\"disabled\",[21,\"disabled\"]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"TimeChanged\"],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"times\"]]],null,{\"statements\":[[0,\"            \"],[7,\"option\"],[12,\"selected\",[27,\"eq\",[[23,[\"event\",\"endTime\"]],[22,1,[]]],null]],[9],[1,[22,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/event/event-date-edit.hbs"
    }
  });

  _exports.default = _default;
});