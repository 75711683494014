define("teelt-frontend/components/communication/communication-planning", ["exports", "teelt-frontend/utils/jquery-utils"], function (_exports, _jqueryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['communication-planning'],
    eventBus: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      setTimeout(function () {
        _this.eventBus.on('CLICK_EVENT', _this, 'checkToCloseModal');
      }, 300);
    },
    destroy: function destroy() {
      this.eventBus.off('CLICK_EVENT', this, 'checkToCloseModal');

      this._super.apply(this, arguments);
    },
    checkToCloseModal: function checkToCloseModal(e) {
      if (!(0, _jqueryUtils.default)().checkIfClickInElement(e, []) && !(0, _jqueryUtils.default)().isMonthArrowClicked(e)) {
        this.send('closeEdits');
      }
    },
    actions: {
      cleanReccurence: function cleanReccurence() {
        Ember.set(this.campaign, 'recurrenceValue', undefined);
        Ember.set(this.campaign, 'recurrenceUnit', undefined);
        Ember.set(this, 'showEdit', undefined);
      },
      openEditRecurrence: function openEditRecurrence() {
        if (this.showEdit !== 'recurrence') {
          if (!this.campaign.recurrenceUnit) {
            Ember.set(this.campaign, 'recurrenceUnit', 'MONTH');
          }

          Ember.set(this, 'showEdit', 'recurrence');
          setTimeout(function () {
            $('.select-recurrencePeriod').formSelect();
          });
        }
      },
      onReccurrencePeriodChanged: function onReccurrencePeriodChanged(value) {
        Ember.set(this.campaign, "recurrenceUnit", value);
      },
      closeEdits: function closeEdits() {
        Ember.set(this, 'showEdit', false);
      }
    }
  });

  _exports.default = _default;
});