define("teelt-frontend/templates/components/utils/expendable-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BZ8fnG/2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[12,\"class\",[28,[\"expendable-text \",[27,\"if\",[[23,[\"lineThrough\"]],[27,\"if\",[[23,[\"lineThroughAnimate\"]],\"line-through-animate\",\"line-through\"],null]],null]]]],[12,\"id\",[21,\"id\"]],[9],[0,\"\\n  \"],[1,[27,\"linkify\",[[23,[\"expendableText\"]]],[[\"target\",\"defaultScheme\"],[\"_blank\",\"https\"]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"isOverflow\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"showReadMore\"]]],null,{\"statements\":[[0,\"      ...\\n      \"],[7,\"span\"],[11,\"class\",\"show-more\"],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"href\",\"#\"],[3,\"action\",[[22,0,[]],\"readMore\"],[[\"bubbles\"],[false]]],[9],[1,[27,\"t\",[\"word.read_more\"],null],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"showReadLess\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"show-more\"],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"href\",\"#\"],[3,\"action\",[[22,0,[]],\"readLess\"],[[\"bubbles\"],[false]]],[9],[1,[27,\"t\",[\"word.hide\"],null],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/expendable-text.hbs"
    }
  });

  _exports.default = _default;
});