define("teelt-frontend/templates/components/dashboard/dashboard-date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BDGf9d0d",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"text\"],[9],[0,\"\\n    \"],[1,[21,\"label1\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[12,\"id\",[28,[\"dashboard-date-picker-\",[21,\"uuid\"]]]],[11,\"class\",\"focus\"],[11,\"contenteditable\",\"true\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"dateKey\"]],\"startDate\"],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"moment-format\",[[23,[\"date\"]],\"LL\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[23,[\"dateKey\"]],\"endDate\"],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"moment-format\",[[23,[\"date\"]],\"LL\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/dashboard/dashboard-date-picker.hbs"
    }
  });

  _exports.default = _default;
});