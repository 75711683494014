define("teelt-frontend/templates/components/dashboard/user-info-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v07X1C/A",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"loading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"loading-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"loading\"],[9],[0,\"\\n      \"],[1,[21,\"icon/loading-svg\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"stats-block\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"dashboard.user_info.user_count\"],[[\"count\"],[[23,[\"count\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"stats-block\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"dashboard.user_info.completion_rate\"],[[\"completionRate\"],[[23,[\"completionRate\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"stats-block\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"dashboard.user_info.connection_rate\"],[[\"connectionRate\"],[[23,[\"connectionRate\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/dashboard/user-info-content.hbs"
    }
  });

  _exports.default = _default;
});