define("teelt-frontend/services/count-tasks-service", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    session: Ember.inject.service(),
    userService: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.loadTasksCount();
      this.eventBus.on('REFRESH_TASKS_COUNT', this, 'loadTasksCount');
      this.eventBus.on('VALIDATE_TASK', this, 'loadTasksCount');
      this.eventBus.on('AUTHENTICATION_CHANGED', this, 'loadTasksCount');
      var interval = setInterval(function () {
        _this.loadTasksCount();
      }, 15 * 60 * 1000);
      Ember.set(this, 'interval', interval);
    },
    destroy: function destroy() {
      this.eventBus.off('REFRESH_TASKS_COUNT', this, 'loadTasksCount');
      this.eventBus.off('VALIDATE_TASK', this, 'loadTasksCount');
      this.eventBus.off('AUTHENTICATION_CHANGED', this, 'loadTasksCount');

      if (this.interval) {
        clearInterval(this.interval);
      }

      this._super.apply(this, arguments);
    },
    loadTasksCount: function loadTasksCount() {
      var _this2 = this;

      if (!this.session.isAuthenticated) return;
      var params = {};

      if (this.userService.isRH(this.session.data.authenticated.user)) {
        params.currentRole = 'ROLE_RH';
      } else {
        var role = this.session.data.authenticated.user.roles.find(function (r) {
          return r !== 'ROLE_USER' && r !== 'ROLE_CONSULT';
        });

        if (role) {
          params.currentRole = role;
        }
      }

      var path = _environment.default.APP.API_HOST + '/tasks/me/tasks/count';
      this.ajax.requestWithUrlParamPromise(path, 'GET', params).then(function (tasksCount) {
        Ember.set(_this2, 'tasksCount', tasksCount);
      });
    }
  });

  _exports.default = _default;
});