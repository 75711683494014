define("teelt-frontend/components/tag/tags-form", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['tags-form'],
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    path: _environment.default.APP.API_HOST + '/tags',
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.ajax.requestPromise(this.path, 'GET').then(function (tags) {
        if (!tags) tags = [];
        Ember.set(_this, 'tags', tags);
      });
    },
    actions: {
      addTag: function addTag() {
        var _this2 = this;

        if (!this.tagToAdd || this.tagToAdd.trim().length == 0) return;
        this.ajax.requestWithBodyPromise(this.path, 'POST', 'application/json', JSON.stringify({
          name: this.tagToAdd
        })).then(function (tag) {
          var tags = _this2.tags;
          tags.push(tag);
          Ember.set(_this2, 'tags', undefined);
          Ember.set(_this2, 'tags', tags);
          Ember.set(_this2, 'tagToAdd', '');
        }).catch(function (err) {
          if (err.responseJSON.code === 'error_tag_already_exists') {
            _this2.notify.error(_this2.intl.t('tags.already_exists'));

            return;
          }

          console.error(err);
        });
      },
      deleteTag: function deleteTag(tag, index) {
        var _this3 = this;

        var path = this.path + '/' + tag.id;
        this.ajax.requestPromise(path, 'DELETE').then(function () {
          var tags = _this3.tags;
          tags.splice(index, 1);
          Ember.set(_this3, 'tags', undefined);
          Ember.set(_this3, 'tags', tags);
        });
      }
    }
  });

  _exports.default = _default;
});