define("teelt-frontend/components/form/form-results", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-results'],
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    isQuizz: Ember.computed('form', function () {
      return this.form.type === 'QUIZZ';
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var fieldIds = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.form.fields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var field = _step.value;
          fieldIds.push(field.id);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var path = _environment.default.APP.API_HOST + '/forms/' + this.form.id + '/results';
      this.ajax.requestPromise(path, 'GET').then(function (results) {
        var responses = [];
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = results[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var result = _step2.value;
            var response = [];
            responses.push(response);
            response.push(result.talent.id);
            response.push(result.talent.username);
            var _iteratorNormalCompletion3 = true;
            var _didIteratorError3 = false;
            var _iteratorError3 = undefined;

            try {
              for (var _iterator3 = fieldIds[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                var fieldId = _step3.value;
                var added = false;
                var _iteratorNormalCompletion4 = true;
                var _didIteratorError4 = false;
                var _iteratorError4 = undefined;

                try {
                  for (var _iterator4 = result.fields[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                    var resultField = _step4.value;

                    if (resultField.fieldId == fieldId) {
                      response.push(JSON.parse(resultField.value));
                      added = true;
                      break;
                    }
                  }
                } catch (err) {
                  _didIteratorError4 = true;
                  _iteratorError4 = err;
                } finally {
                  try {
                    if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
                      _iterator4.return();
                    }
                  } finally {
                    if (_didIteratorError4) {
                      throw _iteratorError4;
                    }
                  }
                }

                if (!added) {
                  response.push('');
                }
              }
            } catch (err) {
              _didIteratorError3 = true;
              _iteratorError3 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
                  _iterator3.return();
                }
              } finally {
                if (_didIteratorError3) {
                  throw _iteratorError3;
                }
              }
            }

            if (_this.isQuizz) {
              response.push(result.result);
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        Ember.set(_this, 'responses', responses);
      });
    },
    actions: {
      exportToCSV: function exportToCSV() {
        var toExport = [];
        var titles = [this.intl.t("word.emails")];
        toExport.push(titles);
        var _iteratorNormalCompletion5 = true;
        var _didIteratorError5 = false;
        var _iteratorError5 = undefined;

        try {
          for (var _iterator5 = this.form.fields[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
            var field = _step5.value;
            titles.push(field.title);
          }
        } catch (err) {
          _didIteratorError5 = true;
          _iteratorError5 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion5 && _iterator5.return != null) {
              _iterator5.return();
            }
          } finally {
            if (_didIteratorError5) {
              throw _iteratorError5;
            }
          }
        }

        if (this.isQuizz) {
          titles.push(this.intl.t("form.result"));
        }

        var _iteratorNormalCompletion6 = true;
        var _didIteratorError6 = false;
        var _iteratorError6 = undefined;

        try {
          for (var _iterator6 = this.responses[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
            var response = _step6.value;
            var line = [];
            toExport.push(line);

            for (var i = 1; i < response.length; i++) {
              line.push(response[i]);
            }
          }
        } catch (err) {
          _didIteratorError6 = true;
          _iteratorError6 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion6 && _iterator6.return != null) {
              _iterator6.return();
            }
          } finally {
            if (_didIteratorError6) {
              throw _iteratorError6;
            }
          }
        }

        var universalBOM = "\uFEFF";
        var CsvString = "";
        toExport.forEach(function (RowItem, RowIndex) {
          RowItem.forEach(function (ColItem, ColIndex) {
            CsvString += '"' + ColItem.toString().replaceAll('"', '""') + '";';
          });
          CsvString = CsvString.substring(0, CsvString.length - 1) + "\r\n";
        });
        var blob = new Blob([universalBOM + CsvString], {
          type: 'text/csv; charset=utf-18'
        });
        var csvUrl = URL.createObjectURL(blob);
        var x = document.createElement("A");
        x.setAttribute("href", csvUrl);
        x.setAttribute("download", "results.csv");
        document.body.appendChild(x);
        x.click();
      }
    }
  });

  _exports.default = _default;
});