define("teelt-frontend/components/settings/integration-list", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['integration-list'],
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    url: null,
    isSlackToken: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var haveIntegration = false;

      if (this.session.data.authenticated.user.company.activeLucca) {
        haveIntegration = true;
        Ember.set(this, 'activeLucca', true);
      }

      if (this.session.data.authenticated.user.company.activeSlack) {
        haveIntegration = true;
        Ember.set(this, 'activeSlack', true);
        Ember.set(this, 'isSlackToken', this.session.data.authenticated.user.company.isSlackToken);
        this.ajax.requestPromise(_environment.default.APP.API_HOST + "/integrations/slack/auth_url").then(function (url) {
          Ember.set(_this, 'url', url);
        });
      }

      if (this.session.data.authenticated.user.company.activeTalentSoft) {
        haveIntegration = true;
        Ember.set(this, 'activeTalentSoft', true);
      }

      if (this.session.data.authenticated.user.company.activeAgrume) {
        haveIntegration = true;
        Ember.set(this, 'activeAgrume', true);
      }

      if (this.session.data.authenticated.user.company.activeFlatchr) {
        haveIntegration = true;
        Ember.set(this, 'activeFlatchr', true);
      }

      Ember.set(this, 'haveIntegration', haveIntegration);
    },
    actions: {
      editLucca: function editLucca() {
        this.router.transitionTo('board.setting.integration.lucca');
      },
      editTalentSoft: function editTalentSoft() {
        this.router.transitionTo('board.setting.integration.talentsoft');
      },
      disconnectSlack: function disconnectSlack() {
        var _this2 = this;

        this.ajax.requestPromise(_environment.default.APP.API_HOST + "/integrations/slack/disconnect").then(function () {
          Ember.set(_this2.session.data.authenticated.user.company, 'isSlackToken', false);

          _this2.session.store.persist(_this2.session.data);

          Ember.set(_this2, 'isSlackToken', false);
        }).catch(function (error) {
          console.log(error);
        });
      },
      refreshLuccaEstablishments: function refreshLuccaEstablishments() {
        var _this3 = this;

        var path = _environment.default.APP.API_HOST + "/integrations/lucca/settings/establishments/refresh";
        var method = 'PATCH';
        this.ajax.requestWithBodyPromise(path, method).then(function () {
          _this3.notify.info(_this3.intl.t('settings.integrations.lucca.establishment_refresh_completed'));
        }).catch(function () {
          _this3.notify.error(_this3.intl.t('settings.integrations.lucca.establishment_refresh_failed'));
        });
        Ember.set(this, 'openActionLucca', false);
      },
      refreshLuccaDepartments: function refreshLuccaDepartments() {
        var _this4 = this;

        var path = _environment.default.APP.API_HOST + "/integrations/lucca/settings/departments/refresh";
        var method = 'PATCH';
        this.ajax.requestWithBodyPromise(path, method).then(function () {
          _this4.notify.info(_this4.intl.t('settings.integrations.lucca.department_refresh_completed'));
        }).catch(function () {
          _this4.notify.error(_this4.intl.t('settings.integrations.lucca.department_refresh_failed'));
        });
        Ember.set(this, 'openActionLucca', false);
      },
      editAgrume: function editAgrume() {
        this.router.transitionTo('board.setting.integration.agrume');
      },
      editFlatchr: function editFlatchr() {
        this.router.transitionTo('board.setting.integration.flatchr');
      }
    }
  });

  _exports.default = _default;
});