define("teelt-frontend/components/settings/integrations/flatchr-settings", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    classNames: ['flatchr-setting'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      if (!this.session.data.authenticated.user.company.activeFlatchr) {
        this.router.transitionTo('board.settings');
        return;
      }

      var flatchrPromise = this.ajax.requestPromise(_environment.default.APP.API_HOST + "/integrations/flatchr/settings").then(function (flatchr) {
        if (!flatchr) flatchr = {};
        return flatchr;
      });
      var customFieldsPromise = this.ajax.requestPromise(_environment.default.APP.API_HOST + "/custom-fields?type=multiple_choice").then(function (customFields) {
        Ember.set(_this2, 'customFields', customFields);
        return customFields;
      });
      Promise.all([flatchrPromise, customFieldsPromise]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
            flatchr = _ref2[0];

        if (flatchr.statusCustomFieldId) {
          return _this2.ajax.requestPromise(_environment.default.APP.API_HOST + "/custom-fields/" + flatchr.statusCustomFieldId).then(function (statusCustomField) {
            Ember.set(_this2, 'flatchr', flatchr);
            Ember.set(_this2, 'statusCustomField', statusCustomField);
            Ember.run.later(function () {
              _this2.$('#statusField').select2({});

              if (flatchr.statusCustomFieldId) {
                _this2.$('#statusField').val(flatchr.statusCustomFieldId);

                _this2.$('#statusField').trigger('change');
              }

              _this2.$('#triggerStatus').select2({});

              if (flatchr.triggerStatus) {
                _this2.$('#triggerStatus').val(flatchr.triggerStatus);

                _this2.$('#triggerStatus').trigger('change');
              }

              var _this = _this2;
              $('#statusField').on('change', function (e) {
                Ember.set(_this.flatchr, 'statusCustomFieldId', $(this).val());
              });
              $('#triggerStatus').on('change', function (e) {
                Ember.set(_this.flatchr, 'triggerStatus', $(this).val());
              });
            }, 0);
            return statusCustomField;
          });
        } else {
          Ember.set(_this2, 'flatchr', flatchr);
          Ember.run.later(function () {
            _this2.$('#statusField').select2({});

            if (flatchr.statusCustomFieldId) {
              _this2.$('#statusField').val(flatchr.statusCustomFieldId);

              _this2.$('#statusField').trigger('change');
            }

            _this2.$('#triggerStatus').select2({});

            if (flatchr.triggerStatus) {
              _this2.$('#triggerStatus').val(flatchr.triggerStatus);

              _this2.$('#triggerStatus').trigger('change');
            }

            var _this = _this2;
            $('#statusField').on('change', function (e) {
              Ember.set(_this.flatchr, 'statusCustomFieldId', $(this).val());
            });
            $('#triggerStatus').on('change', function (e) {
              Ember.set(_this.flatchr, 'triggerStatus', $(this).val());
            });
          }, 0);
        }

        return null;
      });
    },
    verifyFlatchr: function verifyFlatchr() {
      var ok = true;
      var errors = {};

      if (!this.flatchr.companyKey || this.flatchr.companyKey.length == 0) {
        errors.companyKey = true;
        ok = false;
      }

      if (!this.flatchr.id && (!this.flatchr.apikey || this.flatchr.apikey.length == 0)) {
        errors.apikey = true;
        ok = false;
      }

      Ember.set(this, 'errors', errors);
      return ok;
    },
    actions: {
      previous: function previous() {
        this.router.transitionTo('board.setting.integrations');
      },
      save: function save() {
        var _this3 = this;

        if (!this.verifyFlatchr()) return;
        var path = _environment.default.APP.API_HOST + "/integrations/flatchr/settings";
        var method = 'PATCH';
        this.ajax.requestWithBodyPromise(path, method, 'application/json', JSON.stringify(this.flatchr)).then(function () {
          Ember.run.later(function () {
            window.location.reload();
          }, 1000);
        }).catch(function () {
          _this3.notify.error(_this3.intl.t('settings.integrations.flatchr.saved_failed'));
        });
      },
      refreshStatus: function refreshStatus() {
        var _this4 = this;

        var path = _environment.default.APP.API_HOST + "/integrations/flatchr/settings/status/refresh";
        var method = 'PATCH';
        this.ajax.requestPromise(path, method).then(function () {
          _this4.notify.success(_this4.intl.t('settings.integrations.flatchr.refreshed'));
        }).catch(function () {
          _this4.notify.error(_this4.intl.t('settings.integrations.flatchr.refreshed_failed'));
        });
      }
    }
  });

  _exports.default = _default;
});