define("teelt-frontend/components/communication/communications-list", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    countUrl: _environment.default.APP.API_HOST + "/communications/count",
    classNames: ['communication-list'],
    modelName: 'communication',
    modelFilter: {},
    keys: ['icon', 'name', 'status', 'date', 'users', 'open', 'click', 'actions'],
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'reloadTimestamp', new Date());
      this.eventBus.on('REFRESH_COMMUNICATIONS', this, 'onCommunicationRefresh');
    },
    destroy: function destroy() {
      this.eventBus.off('REFRESH_COMMUNICATIONS', this, 'onCommunicationRefresh');

      this._super.apply(this, arguments);
    },
    onCommunicationRefresh: function onCommunicationRefresh() {
      Ember.set(this, 'reloadTimestamp', new Date());
    },
    actions: {
      onRowSelected: function onRowSelected(result) {
        if (result.sends && result.sends.length > 0) {
          this.router.transitionTo('board.communication.communication-result', result);
        }
      }
    }
  });

  _exports.default = _default;
});