define("teelt-frontend/components/content/contents-documents-blocks-categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['contents-documents-list', 'contents-documents-blocks'],
    selectedIndexes: [],
    store: Ember.inject.service(),
    contentService: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.loadContents();
    },
    loadContents: function loadContents() {
      var _this = this;

      this.store.query('content', {
        filter: this.section,
        overrideWithAudience: true
      }).then(function (contents) {
        var routeName = _this.router.get('currentRouteName');

        contents = routeName.startsWith('board') ? contents : _this.contentService.hideNotVisiblesContents(contents);

        if ((!contents || contents.length === 0) && _this.show) {
          Ember.set(_this, 'show', false);
          return;
        }

        var filteredContentsByCategory = [];
        var filteredContents = contents.filter(function (content) {
          var contentToDownload = content.toDownload || content.genericContent && content.genericContent.toDownload;
          var category = content.category || content.genericContent && content.genericContent.category;
          var categoryId = category.id;
          var searchIndex = filteredContentsByCategory.findIndex(function (item) {
            return item.categoryId === categoryId && item.toDownload === contentToDownload;
          });
          var searchToDownloadIndex = filteredContentsByCategory.findIndex(function (item) {
            return item.toDownload === true;
          });

          if (!contentToDownload) {
            if (searchIndex != -1) {
              filteredContentsByCategory[searchIndex].contents.push(content);
            } else {
              filteredContentsByCategory.push({
                toDownload: false,
                categoryId: categoryId,
                category: category,
                contents: [content],
                order: category.order
              });
            }
          } else {
            if (searchToDownloadIndex != -1) {
              filteredContentsByCategory[searchToDownloadIndex].contents.push(content);
            } else {
              filteredContentsByCategory.unshift({
                toDownload: true,
                categoryId: categoryId,
                category: {
                  companyId: category.companyId,
                  createdAt: category.createdAt,
                  id: category.id,
                  name: "To download",
                  order: category.order
                },
                contents: [content]
              });
            }
          }

          return _this.toDownload && contentToDownload || !_this.toDownload && !contentToDownload;
        });
        filteredContentsByCategory.sort(function (c1, c2) {
          return c1.order - c2.order;
        });

        if (_this.selectedIndexes.length != 0) {
          var newArray = [];

          _this.selectedIndexes.forEach(function (index) {
            newArray.push(filteredContentsByCategory[index]);
          });

          Ember.set(_this, 'filteredContentsByCategory', newArray);
        } else {
          Ember.set(_this, 'filteredContentsByCategoryFilter', filteredContentsByCategory);
          Ember.set(_this, 'filteredContentsByCategory', filteredContentsByCategory);
        }
      });
    },
    actions: {
      reloadContents: function reloadContents() {
        this.loadContents();
      },
      categoryFilterSelected: function categoryFilterSelected(index) {
        this.$('.category-item-' + index).addClass('active');

        if (this.selectedIndexes.length != 0) {
          var searchIndex = this.selectedIndexes.findIndex(function (item) {
            return item === index;
          });

          if (searchIndex === -1) {
            this.$('.category-item-' + index).addClass('active');
            this.selectedIndexes.push(index);
          } else {
            this.$('.category-item-' + index).removeClass('active');
            this.selectedIndexes.splice(searchIndex, 1);
          }
        } else {
          this.$('.category-item-' + index).addClass('active');
          Ember.set(this, 'selectedIndexes', [index]);
        }

        this.loadContents();
      }
    }
  });

  _exports.default = _default;
});