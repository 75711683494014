define("teelt-frontend/templates/board/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hSum723G",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"id\",\"events\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"section-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"g-section-title foreground-primary-color\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"vertical-center\"],[9],[0,\"\\n              \"],[7,\"h1\"],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"word.events\"],null]],null],false],[10],[0,\"\\n              \"],[1,[27,\"utils/btn-info-tooltip\",null,[[\"info\",\"position\"],[[27,\"t\",[\"page.event.title.tooltip\"],null],\"right\"]]],false],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"baseline\"],[9],[1,[27,\"t\",[\"event.campaign.baseline\"],null],false],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col right add-campaign-block\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"board.event.campaign-new\"],[[\"class\"],[\"new-campaign-button waves-effect waves-light btn-large background-primary-color\"]],{\"statements\":[[0,\"                \"],[7,\"i\"],[11,\"class\",\"material-icons left\"],[11,\"style\",\"float:left!important\"],[9],[0,\"add\"],[10],[0,\"\\n                 \"],[1,[27,\"t\",[\"event.campaign.new_campaign\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[1,[21,\"event/campaign-list\"],false],[0,\"\\n\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/events.hbs"
    }
  });

  _exports.default = _default;
});