define("teelt-frontend/routes/board/templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    getSelectedMenuFromTransition: function getSelectedMenuFromTransition(transition) {
      var selectedMenu = 0;

      if (transition && transition.targetName === "board.templates.tasks") {
        selectedMenu = 1;
      } else if (transition && transition.targetName === "board.templates.tools") {
        selectedMenu = 2;
      } else if (transition && transition.targetName === "board.templates.forms") {
        selectedMenu = 3;
      } else if (transition && transition.targetName === "board.templates.admin-forms") {
        selectedMenu = 4;
      }

      return selectedMenu;
    },
    model: function model(params, transition) {
      var selectedMenu = this.getSelectedMenuFromTransition(transition);
      return {
        selectedMenu: selectedMenu,
        sliderMenu: [{
          name: this.intl.t('word.integration_kit'),
          route: "board.templates.templates-list"
        }, {
          name: this.intl.t('word.tasks'),
          route: "board.templates.tasks"
        }, {
          name: this.intl.t('word.tools'),
          route: "board.templates.tools"
        }, {
          name: this.intl.t('word.forms'),
          route: "board.templates.forms"
        }, {
          name: this.intl.t('administrative.menu'),
          route: "board.templates.admin-forms"
        }]
      };
    },
    actions: {
      willTransition: function willTransition(transition) {
        var selectedMenu = this.getSelectedMenuFromTransition(transition);
        Ember.set(this.currentModel, "selectedMenu", selectedMenu);
      }
    }
  });

  _exports.default = _default;
});