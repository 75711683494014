define("teelt-frontend/components/form/field/field-opinion-preview", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['field-opinion-preview'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.value) {
        try {
          Ember.set(this, 'value', JSON.parse(this.value));
        } catch (err) {}
      }

      this.resize();
      this.$(window).on('resize', function () {
        _this.resize();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$(window).off('resize');
    },
    fromObserver: Ember.observer('field.properties.fromValue', function () {
      var _this2 = this;

      setTimeout(function () {
        _this2.resize();
      }, 0);
    }),
    toObserver: Ember.observer('field.properties.toValue', function () {
      var _this3 = this;

      setTimeout(function () {
        _this3.resize();
      }, 0);
    }),
    resize: function resize() {
      var containerWidth = (0, _jquery.default)('.field-opinion-preview').width();
      if (!this.field.properties.fromValue || !this.field.properties.toValue) return;
      var steps = this.field.properties.toValue - this.field.properties.fromValue + 1;
      if (steps <= 0) return;

      if (steps > 11) {
        Ember.set(this, 'showInput', true);
        return;
      } else {
        Ember.set(this, 'showInput', false);
      }

      var markWidth = containerWidth / steps - 6;

      if (markWidth < 25) {
        Ember.set(this, 'showInput', true);
      } else {
        Ember.set(this, 'showInput', false);
        if (markWidth > 50) markWidth = 50;
        this.$('.opinion-mark').width(markWidth);
        this.$('.opinion-mark').height(markWidth);
      }
    },
    actions: {
      onMarkClick: function onMarkClick(mark) {
        if (this.disabled) return;
        Ember.set(this, 'value', mark);
      }
    }
  });

  _exports.default = _default;
});