define("teelt-frontend/components/content/contents-documents-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['contents-documents-list'],
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    contentService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.loadContents();
    },
    loadContents: function loadContents() {
      var _this = this;

      this.store.query('content', {
        filter: this.section,
        type: 'filecard'
      }).then(function (contents) {
        var routeName = _this.router.get('currentRouteName');

        contents = routeName.startsWith('board') ? contents : _this.contentService.hideNotVisiblesContents(contents);

        if ((!contents || contents.length === 0) && _this.show) {
          Ember.set(_this, 'show', false);
          return;
        }

        Ember.set(_this, 'contents', contents);
      });
    },
    actions: {
      reloadContents: function reloadContents() {
        this.loadContents();
      }
    }
  });

  _exports.default = _default;
});