define("teelt-frontend/templates/components/icon/hide-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aV6Db2qI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[11,\"aria-hidden\",\"true\"],[11,\"role\",\"img\"],[11,\"width\",\"1em\"],[11,\"height\",\"1em\"],[11,\"preserveAspectRatio\",\"xMidYMid meet\"],[11,\"viewBox\",\"0 0 24 24\"],[9],[0,\"\\n    \"],[7,\"path\"],[11,\"fill\",\"currentColor\"],[11,\"d\",\"m16.17 19l-2-2H6l3-4l2.25 3l.82-1.1L5 7.83V19zM7.83 5L19 16.17V5z\"],[11,\"opacity\",\".3\"],[9],[10],[0,\"\\n    \"],[7,\"path\"],[11,\"fill\",\"currentColor\"],[11,\"d\",\"M19 5v11.17l2 2V5c0-1.1-.9-2-2-2H5.83l2 2H19zM2.81 2.81L1.39 4.22L3 5.83V19c0 1.1.9 2 2 2h13.17l1.61 1.61l1.41-1.41L2.81 2.81zM5 19V7.83l7.07 7.07l-.82 1.1L9 13l-3 4h8.17l2 2H5z\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/icon/hide-svg.hbs"
    }
  });

  _exports.default = _default;
});