define("teelt-frontend/components/task/new-task", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['new-task'],
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    staticlikeData: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    step: 0,
    error: {},
    displayControls: true,
    templatePeriodTasks: {
      mandatory: false,
      showLimitOffset: false,
      triggerInitiator: "action_start",
      editable: true
    },
    selectedType: 'form',
    templateNotification: {
      triggerInitiator: "talent_created",
      notificationRecipient: "",
      editable: true
    },
    activeSlack: false,
    showMultipleOffsetPicker: true,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.eventBus.on('FORCE_NEXT_STEP', this, 'forceNextStep');
      Ember.set(this, 'taskPath', _environment.default.APP.API_HOST + '/tasks');
      Ember.set(this, 'consultRoles', this.rolecustomService.getConsultRoles());
      Ember.set(this, 'conditions', {});

      if (this.session.data.authenticated.user.company) {
        Ember.set(this, 'company', this.session.data.authenticated.user.company);
      }

      if (this.session.data.authenticated.user.company.activeSlack) {
        Ember.set(this, 'activeSlack', true);
      }

      var taskTypes = ['form', 'administrative', 'content'];

      if (this.session.data.authenticated.user.company.activeSignContent) {
        taskTypes.push('sign');
      }

      if (this.session.data.authenticated.user.company.activeMeetPeople || this.session.data.authenticated.user.company.activeExploreOffice) {
        taskTypes.push('ihm');
      }

      taskTypes.push('none');

      if (this.resetAll) {
        this.resetContainer();
        Ember.set(this, 'resetAll', false);
      }

      if (this.isNotificationTask) {
        Ember.set(this.templatePeriodTasks, 'triggerInitiator', null);
      }

      if (!this.editTask) {
        if (this.isNotificationTask) {
          if (!this.task) {
            Ember.set(this, 'task', {
              type: 'notification',
              assign: 'system'
            });
          } else {
            Ember.set(this.task, 'type', 'notification');
            Ember.set(this.task, 'assign', 'system');
          }

          Ember.set(this, 'minStep', 5);
          Ember.set(this, 'step', 5);
        } else if (this.isOtherNotificationTask) {
          Ember.set(this.templateNotification, 'template', this.template.toJSON({
            includeId: true
          }));
          Ember.set(this, 'minStep', 5);
          Ember.set(this, 'step', 5);
        } else {
          Ember.set(this, 'edited', false);

          if (!this.task) {
            Ember.set(this, 'task', {
              type: 'form',
              assign: 'talent'
            });
          }
        }
      } else {
        if (this.isOtherNotificationTask) {
          Ember.set(this, 'templateNotification', this.editTask);
          Ember.set(this.templateNotification, 'template', this.template.toJSON({
            includeId: true
          }));
          Ember.set(this.templatePeriodTasks, 'startOffset', this.templateNotification.startOffset);
          Ember.set(this.templatePeriodTasks, 'startOffsetDirection', this.templateNotification.startOffsetDirection);
          Ember.set(this.templatePeriodTasks, 'startOffsetUnit', this.templateNotification.startOffsetUnit);
          this.setSmsContentFromContents();
          this.setEmailFromContents();
          this.setSlackContentFromContents();

          if (this.templateNotification.task) {
            Ember.set(this.conditions, 'taskCompletedId', this.templateNotification.task.id);
            Ember.set(this.conditions, 'taskCompletedName', this.templateNotification.task.name.replaceAll(';', ','));
          }

          Ember.set(this, 'minStep', 7);
          Ember.set(this, 'step', 7);
          Ember.set(this, 'edited', true);
          setTimeout(function () {
            _this.$('.select').formSelect();
          }, 300);
        } else {
          var task = JSON.parse(JSON.stringify(this.editTask));

          if (task.assign == 'system') {
            Ember.set(this, 'isNotificationTask', true);
          }

          if (this.editTask.id && !task.id) {
            Ember.set(task, 'id', this.editTask.id);
          }

          if (task.type === 'meetPeople' || task.type === 'exploreOffice') {
            task.type = 'ihm';
          }

          Ember.set(this, 'task', task);

          if (this.forceStep) {
            Ember.set(this, 'minStep', this.forceStep);
            Ember.set(this, 'step', this.forceStep);
          } else if (this.isNotificationTask) {
            Ember.set(this, 'minStep', 5);
            Ember.set(this, 'step', 5);
          } else {
            Ember.set(this, 'minStep', 2);
            Ember.set(this, 'step', 10);
          }

          if (this.showPlanning) {
            this.setPlanning(task);
          }
        }
      }

      Ember.set(this, 'taskTypes', taskTypes);
      Ember.set(this, 'customFields', this.staticlikeData.companyCustomFields);

      if (this.editOption) {
        Ember.set(this, 'ariane', []);
      } else {
        Ember.set(this, 'ariane', [this.step]);
      }
    },
    destroy: function destroy() {
      this.eventBus.off('FORCE_NEXT_STEP', this, 'forceNextStep');

      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('.step').hide();
      this.$('.step' + this.step).show(400);
    },
    validateStep2: function validateStep2() {
      Ember.set(this.error, 'nameIsNull', undefined);

      if (!this.task.name || this.task.name.trim() == '') {
        Ember.set(this.error, 'nameIsNull', true);
        return false;
      }

      return true;
    },
    setPlanning: function setPlanning(task) {
      var _this2 = this;

      var path = _environment.default.APP.API_HOST + '/templates/' + this.template.id + '/periods/' + this.period.id + '/tasks/' + task.id;
      this.ajax.requestPromise(path, 'GET').then(function (temp) {
        if (temp) {
          if (temp.startOffset && temp.startOffsetUnit && temp.startOffsetDirection) {
            Ember.set(_this2.task, 'planning', true);
          }

          if (_this2.task.notification) {
            Ember.set(_this2.task, 'notify', true);
          }

          if (temp.endOffset && temp.endOffsetUnit && temp.endOffsetDirection) {
            Ember.set(_this2.templatePeriodTasks, "showLimitOffset", true);
          } else {
            Ember.set(_this2.templatePeriodTasks, "showLimitOffset", false);
          }

          Ember.set(_this2.templatePeriodTasks, 'mandatory', temp.mandatory);
          Ember.set(_this2.templatePeriodTasks, 'id', temp.id);
          Ember.set(_this2.templatePeriodTasks, 'startOffset', temp.startOffset);
          Ember.set(_this2.templatePeriodTasks, 'startOffsetUnit', temp.startOffsetUnit);
          Ember.set(_this2.templatePeriodTasks, 'startOffsetDirection', temp.startOffsetDirection);
          Ember.set(_this2.templatePeriodTasks, 'endOffset', temp.endOffset);
          Ember.set(_this2.templatePeriodTasks, 'endOffsetUnit', temp.endOffsetUnit);
          Ember.set(_this2.templatePeriodTasks, 'endOffsetDirection', temp.endOffsetDirection);

          if (temp.notification) {
            Ember.set(_this2.templatePeriodTasks, 'notification', temp.notification);
          }

          if (!_this2.isNotificationTask) {
            _this2.send('onTriggerChanged', temp.triggerInitiator);
          }

          if (_this2.isNotificationTask) {
            _this2.send('onRecipientChanged', temp.notificationRecipient);
          }
        }
      });
    },
    setEmailFromContents: function setEmailFromContents() {
      var emailContent = this.getEmailContentFromContents();

      if (emailContent) {
        Ember.set(this, "email", emailContent);
      } else {
        var _emailContent = {
          contentType: "EMAIL",
          subject: "",
          body: "",
          disabled: true,
          replyTo: "",
          officeId: null,
          tagId: null,
          cc: ""
        };
        this.notificationPreview.contents.push(_emailContent);
        Ember.set(this, "email", _emailContent);
      }
    },
    getEmailContentFromContents: function getEmailContentFromContents() {
      if (this.isOtherNotificationTask) {
        Ember.set(this, "notificationPreview", this.templateNotification.notification);
      } else {
        Ember.set(this, "notificationPreview", this.task.notification);
      }

      for (var i = 0; i < this.notificationPreview.contents.length; i++) {
        var content = this.notificationPreview.contents[i];

        if (content.contentType === "EMAIL") {
          return this.notificationPreview.contents[i];
        }
      }
    },
    setSmsContentFromContents: function setSmsContentFromContents() {
      var smsContent = this.getSmsContentFromContents();

      if (smsContent) {
        Ember.set(this, "sms", smsContent);
      } else {
        var _smsContent = {
          contentType: "SMS",
          body: "",
          disabled: true
        };
        this.notificationPreview.contents.push(_smsContent);
        Ember.set(this, "sms", _smsContent);
      }
    },
    getSmsContentFromContents: function getSmsContentFromContents() {
      if (this.isOtherNotificationTask) {
        Ember.set(this, "notificationPreview", this.templateNotification.notification);
      } else {
        Ember.set(this, "notificationPreview", this.task.notification);
      }

      for (var i = 0; i < this.notificationPreview.contents.length; i++) {
        var content = this.notificationPreview.contents[i];
        if (content.contentType === "SMS") return this.notificationPreview.contents[i];
      }
    },
    setSlackContentFromContents: function setSlackContentFromContents() {
      var slackContent = this.getSlackContentFromContents();

      if (slackContent) {
        Ember.set(this, "slack", slackContent);
      } else {
        var _slackContent = {
          contentType: "SLACK",
          body: "",
          disabled: true
        };
        this.notificationPreview.contents.push(_slackContent);
        Ember.set(this, "slack", _slackContent);
      }
    },
    getSlackContentFromContents: function getSlackContentFromContents() {
      if (this.isOtherNotificationTask) {
        Ember.set(this, "notificationPreview", this.templateNotification.notification);
      } else {
        Ember.set(this, "notificationPreview", this.task.notification);
      }

      for (var i = 0; i < this.notificationPreview.contents.length; i++) {
        var content = this.notificationPreview.contents[i];
        if (content.contentType === "SLACK") return this.notificationPreview.contents[i];
      }
    },

    /** FORMS */
    initTaskForm: function initTaskForm() {
      if (!this.task.form) {
        Ember.set(this.task, 'form', {
          type: this.task.type == 'administrative' ? 'ADMINISTRATIVE' : 'FEEDBACK',
          maxResponseNumber: 1,
          fields: [{
            type: "short_text",
            properties: {},
            required: false,
            inputErrors: {}
          }]
        });
      }
    },

    /** END FORMS */
    initTaskSignContent: function initTaskSignContent() {
      if (!this.task.signContent || this.task.signContent && !this.task.signContent.id) {
        Ember.set(this.task, 'signContent', this.store.createRecord('signcontent'));
      }
    },

    /** NOTIFS */
    showNotifModal: function showNotifModal() {
      Ember.set(this, 'showNotificationModal', true);
    },
    resetErrorsNotification: function resetErrorsNotification() {
      Ember.set(this.error, 'notification', {});
    },
    validateNotification: function validateNotification() {
      this.resetErrorsNotification();
      var isOk = true;

      if (!this.task.notification.name) {
        Ember.set(this.error.notification, 'nameIsNull', true);
        isOk = false;
      }

      if (!this.task.notification.triggerInitiator && !this.notification.isSystem) {
        Ember.set(this.error.notification, 'triggerInitiatorIsNull', true);
        isOk = false;
      }

      return isOk;
    },
    resetContainer: function resetContainer() {
      Ember.set(this, 'templatePeriodTasks', undefined);
      Ember.set(this, 'templatePeriodTasks', {
        mandatory: false,
        showLimitOffset: false,
        triggerInitiator: "action_start",
        notificationRecipient: ""
      });
      Ember.set(this, 'task', undefined);
      Ember.set(this, 'templateNotification', undefined);

      if (this.isOtherNotificationTask) {
        Ember.set(this, 'templateNotification', {
          triggerInitiator: "talent_created",
          notificationRecipient: "",
          template: this.template.toJSON({
            includeId: true
          })
        });
      } else {
        Ember.set(this, 'task', {
          type: 'form',
          assign: 'talent'
        });
      }
    },
    forceNextStep: function forceNextStep() {
      this.send('next');
    },
    actions: {
      onTriggerChanged: function onTriggerChanged(value) {
        var _this3 = this;

        Ember.set(this, 'showMultipleOffsetPicker', false);

        if (this.isOtherNotificationTask) {
          Ember.set(this.templateNotification, "triggerInitiator", value);

          if (value == 'custom_field') {
            setTimeout(function () {
              _this3.$('.select').formSelect();
            }, 300);
            this.send('onCustomFieldChanged', this.customFields[0].id);
          }
        } else {
          Ember.set(this.templatePeriodTasks, "triggerInitiator", value);
        }

        if (this.isOtherNotificationTask) {
          this.templateNotification.triggerInitiator == "task_completed" || this.templateNotification.triggerInitiator == 'task_not_completed' ? "" : delete this.templateNotification.task;
        }

        setTimeout(function () {
          Ember.set(_this3, 'showMultipleOffsetPicker', true);
        }, 100);
      },
      onCustomFieldChanged: function onCustomFieldChanged(value) {
        Ember.set(this.templateNotification, "customFieldId", value);
      },
      onRecipientChanged: function onRecipientChanged(value) {
        this.isOtherNotificationTask ? Ember.set(this.templateNotification, "notificationRecipient", value) : Ember.set(this.templatePeriodTasks, "notificationRecipient", value);
      },
      onTaskCompletedChanged: function onTaskCompletedChanged(value, text) {
        var _this4 = this;

        Ember.set(this.conditions, 'taskCompletedId', value);
        Ember.set(this.conditions, 'taskCompletedName', text.replaceAll(';', ','));
        var path = _environment.default.APP.API_HOST + '/tasks/' + value;
        this.ajax.requestPromise(path, 'GET').then(function (task) {
          if (task) {
            Ember.set(_this4.templateNotification, 'task', task);
          }
        });
      },
      setTask: function setTask(task) {
        Ember.set(this, 'task', undefined);

        if (!task) {
          Ember.set(this, 'task', {
            type: this.selectedType,
            assign: this.selectedType == 'sign' ? 'none' : 'talent'
          });
        } else {
          if (task.type == 'sign') {
            Ember.set(task, 'assign', 'none');
          }

          Ember.set(this, 'task', task);
        }
      },
      changeType: function changeType(type) {
        Ember.set(this, 'selectedType', type);

        if (this.task) {
          Ember.set(this.task, 'type', this.selectedType);

          if (this.selectedType == 'sign') {
            Ember.set(this.task, 'assign', 'none');
          } else {
            Ember.set(this.task, 'assign', 'talent');
          }
        }
      },
      previous: function previous() {
        var _this5 = this;

        if (this.minStep && this.step <= this.minStep) {
          Ember.set(this, 'show', false);
          return;
        }

        if (this.step == 0) {
          //set(this,'task',undefined);
          Ember.set(this, 'show', false);
          return;
        }

        if (this.step == 1) {
          //a revoir
          var type = this.task.type;
          Ember.set(this, 'task', undefined);
          Ember.set(this, 'task', {
            type: type,
            assign: 'talent'
          }); //a revoir

          Ember.set(this, 'step', 1);
        }

        if (this.edited) {
          if (this.step == 2) {
            Ember.set(this, 'show', false);
            return;
          }
        }

        if (this.step == 2) {
          if (!this.showStep1) {
            Ember.set(this, 'step', 1);
          } else {
            Ember.set(this, 'step', 2);
          }
        }

        if (this.step == 4) {
          if (this.task.type == 'notification') {
            Ember.set(this, 'step', 3);
          } else {
            Ember.set(this, 'step', 4);
          }
        }

        if (this.step == 5) {
          if (this.isNotificationTask || this.isOtherNotificationTask) {
            Ember.set(this, 'step', 0);
            this.send('previous');
            return;
          } else if (this.showPlanning) {
            Ember.set(this, 'step', 3);
          } else if (this.task.type == 'content') {
            Ember.set(this, 'step', 4);
          } else if (this.task.type == 'none' || this.task.type == 'notification') {
            Ember.set(this, 'step', 3);
          } else if (this.task.type == 'form' || this.task.type == 'administrative') {
            if (this.task.form && !this.task.form.id) {
              Ember.set(this, 'step', 5);
            } else if (this.task.form && this.task.form.id) {
              Ember.set(this, 'step', 4);
            } else {
              Ember.set(this, 'step', 4);
            }
          }
        }

        if (this.step == 7) {
          Ember.set(this, 'step', 6);
        }

        if (this.step == 10) {
          if (this.isNotificationTask || this.isOtherNotificationTask) {
            Ember.set(this, 'step', 8);
          } else if (this.showPlanning) {
            if (this.task.notify) {
              Ember.set(this, 'step', 8);
            } else {
              Ember.set(this, 'step', 3);
            }
          } else if (this.task.type == 'none') {
            Ember.set(this, 'step', 3);
          } else if (this.task.type == 'form' || this.task.type == 'administrative') {
            if (this.task.form && !this.task.form.id) {
              Ember.set(this, 'step', 5);
            } else if (this.task.form && this.task.form.id) {
              Ember.set(this, 'step', 4);
            }
          } else if (this.task.type == 'content') {
            Ember.set(this, 'step', 4);
          } else if (this.task.type == 'notification') {
            if (this.task.notification && !this.task.notification.id) {
              Ember.set(this, 'step', 7);
            } else if (this.task.notification && this.task.notification.id) {
              Ember.set(this, 'step', 6);
            }
          } else if (this.task.type == 'sign') {
            Ember.set(this, 'step', 4);
          } else if (this.task.type == 'ihm' || this.task.type == 'meetPeople' || this.task.type == 'exploreOffice') {
            Ember.set(this.task, 'type', 'ihm');
            Ember.set(this, 'step', 4);
          } else if (this.task.type == 'sign') {
            Ember.set(this, 'step', 4);
          }
        }

        if (this.step == 8 && !this.session.data.authenticated.user.company.activePlanningTaskNotif && !this.isNotificationTask && !this.isOtherNotificationTask) {
          Ember.set(this, 'step', 6);
        }

        this.$('.step').hide(400);
        Ember.set(this, 'step', this.step - 1);
        this.$('.step' + this.step).show(400);

        if (this.task && this.task.type) {
          this.$('.step' + this.step + "_" + this.task.type).show(400);
        }

        if (this.step == 7) {
          setTimeout(function () {
            _this5.$('.select').formSelect();
          }, 300);
        }

        var ariane = this.ariane;
        ariane.pop();

        if (ariane.length > 0) {
          ariane.pop();
        }

        ariane.push(this.step);
        Ember.set(this, 'ariane', undefined);
        setTimeout(function () {
          Ember.set(_this5, 'ariane', ariane);
        });
      },
      next: function next() {
        var _this6 = this;

        if (this.step == 7) {
          Ember.set(this, 'step', 9);
        }

        if (this.step == 5) {
          if (!this.isOtherNotificationTask) {
            if (this.showPlanning && (!this.task.notification || !this.task.notification.id)) {
              return;
            } else if (this.task.notification && this.task.notification.id) {
              if (this.showPlanning) {
                if (this.isNotificationTask) {
                  Ember.set(this.task, 'name', "TEMPLATE_".concat(this.template.id, "_PERIOD_").concat(this.period.id, "_NOTIFICATION_").concat(this.task.notification.id));
                }

                this.setSmsContentFromContents();
                this.setEmailFromContents();
                this.setSlackContentFromContents();
                Ember.set(this, 'step', 6);
              } else {
                Ember.set(this, 'step', 9);
              }
            }
          } else {
            if (this.showPlanning && (!this.templateNotification.notification || !this.templateNotification.notification.id)) {
              return;
            } else if (this.templateNotification.notification && this.templateNotification.notification.id) {
              if (this.showPlanning) {
                this.setSmsContentFromContents();
                this.setEmailFromContents();
                this.setSlackContentFromContents();
                Ember.set(this, 'step', 6);
              } else {
                Ember.set(this, 'step', 9);
              }
            }
          }
        }

        if (this.step == 4 && !this.showNotificationModal) {
          if (!this.showPlanning) {
            Ember.set(this.task, 'notification', undefined);
            Ember.set(this, 'lastStep', this.step);
            Ember.set(this, 'step', 9);
          }
        }

        if (this.step == 3) {
          if (this.showPlanning) {
            if (this.notify) {
              Ember.set(this, 'step', 3);
            } else {
              Ember.set(this, 'step', 9);
            }
          } else if (this.task.type == 'form' || this.task.type == 'administrative') {
            this.initTaskForm();

            if (this.task.form.id) {
              Ember.set(this, 'step', this.step + 1);
            }
          } else if (this.task.type == 'content') {
            if (this.task.content && this.task.content.id) {
              Ember.set(this, 'step', 4);
            }
          } else if (this.task.type == 'sign') {
            if (this.task.signContent && this.task.signContent.id) {
              Ember.set(this, 'step', 9);
            } else {
              this.initTaskSignContent();
            }

            Ember.set(this, 'lastStep', this.step);
          } else if (this.task.type == 'ihm') {
            if ((this.task.meetPeople || this.task.exploreOffice) && !this.task.noModuleSelected) {
              if (this.task.meetPeople) Ember.set(this.task, 'type', 'meetPeople');
              if (this.task.exploreOffice) Ember.set(this.task, 'type', 'exploreOffice');
              Ember.set(this, 'step', 4);
            }
          }
        }

        if (this.step == 2) {
          if (this.showPlanning) {
            if (this.task.notify) {
              Ember.set(this, 'step', 4);
            } else {
              Ember.set(this, 'step', 9);
            }
          } else if (!this.validateStep2()) {
            return;
          } else if (this.task && this.task.id && !this.edited) {
            if (this.editFromTaskList && this.task.type != 'none') {
              Ember.set(this, 'step', 2);
            } else {
              Ember.set(this, 'step', 9);
            }
          } else if (this.task.type == 'notification') {
            Ember.set(this, 'step', 4);
          } else if (this.task.type == 'none') {
            if (this.task.notify) {
              Ember.set(this, 'step', 4);
            } else {
              Ember.set(this, 'step', 9);
            }
          }
        }

        if (this.step == 1) {
          if (this.showPlanning) {
            if (!this.task.id) {
              return;
            }
          }

          if (this.task.notification && this.task.notification.id) {
            Ember.set(this.task, 'notify', true);
          }

          if (!this.edited) {
            if (this.task.notification && this.task.notification.id) {
              this.setPlanning(this.task);
            }
          } else if (this.task && this.task.id && !this.edited) {
            Ember.set(this, 'step', 1);
          }
        }

        if (!this.showStep1 && this.step == 0) {
          Ember.set(this, 'step', 1);
        }

        if (!this.isOtherNotificationTask && this.step >= 6 && !this.task.notify && !this.isNotificationTask) {
          Ember.set(this.task, 'notification', undefined);
        }

        if (this.step == 3 && this.showPlanning && !this.task.notify && this.edited) {
          delete this.task.notification;
          delete this.editTask.notification;
        }

        if (this.step == 6 && !this.session.data.authenticated.user.company.activePlanningTaskNotif && !this.isNotificationTask && !this.isOtherNotificationTask) {
          Ember.set(this.templatePeriodTasks, "triggerInitiator", 'action_start');
          Ember.set(this, 'step', 9);
        }

        this.$('.step').hide(400);
        Ember.set(this, 'step', this.step + 1);

        if (this.step == 5 && !this.task.notify) {
          Ember.set(this, 'lastStep', this.step);
          Ember.set(this, 'step', 10);
        }

        this.$('.step' + this.step).show(400);

        if (this.task && this.task.type) {
          this.$('.step' + this.step + "_" + this.task.type).show(400);
        }

        if (this.step == 7) {
          setTimeout(function () {
            _this6.$('.select').formSelect();
          }, 300);
        }

        var ariane = this.ariane;
        ariane.push(this.step);
        Ember.set(this, 'ariane', undefined);
        setTimeout(function () {
          Ember.set(_this6, 'ariane', ariane);
        });
      },
      nextAI: function nextAI() {
        Ember.set(this, 'stepGroup', 'AI');
        Ember.set(this, 'step', this.step + 100);
      },
      previousAI: function previousAI() {
        Ember.set(this, 'step', this.step - 100);

        if (this.step == 0) {
          Ember.set(this, 'stepGroup', undefined);
        }
      },
      choiceNotif: function choiceNotif(response) {
        if (response) {
          Ember.set(this, 'step', 4);
          this.send('next');
        } else {
          Ember.set(this.task, 'notification', undefined);
          Ember.set(this, 'lastStep', this.step);
          Ember.set(this, 'step', 9);
          this.send('next');
        }

        Ember.set(this, 'showNotificationModal', false);
      },
      onFinishForm: function onFinishForm() {
        this.send('next');
      },
      onFinishSign: function onFinishSign(content) {
        Ember.set(this.task, 'signContent', content.toJSON({
          includeId: true
        }));
        this.send('next');
      },
      onFinishIhm: function onFinishIhm(ihm) {
        Ember.set(this.task, this.task.type, ihm);
        Ember.set(this.task, 'type', this.task.type);
        this.send('next');
      },
      onFinishContent: function onFinishContent(content) {
        Ember.set(this.task, "content", content.toJSON({
          includeId: true
        }));
        this.send('next');
      },
      editOptionsFromResume: function editOptionsFromResume(value) {
        if (value == "editAction") {
          Ember.set(this, 'step', 1);
        } else if (value == "editNotification") {
          Ember.set(this, 'step', 4);
        } else if (value == "editModule") {
          Ember.set(this, 'step', 2);
        } else if (value == "removeNotification") {
          Ember.set(this.task, 'notification', undefined);
          Ember.set(this.task, 'notify', false);
          Ember.set(this, 'step', 9);
        }

        var ariane = this.ariane;

        while (ariane.length > 0) {
          ariane.pop();

          if (ariane[ariane.length - 1] == this.step) {
            break;
          }
        }

        Ember.set(this, 'ariane', ariane);
        this.send('next');
      },
      onFinishNotif: function onFinishNotif() {
        //set(this.task,'notification', this.task.notification.toJSON() );
        if (this.task.planning) {
          Ember.set(this, 'step', 6);
        } else {
          Ember.set(this, 'step', 9);
        }

        this.send('next');
      },
      addContentToTask: function addContentToTask(content) {
        if (!content) {
          Ember.set(this.task, 'content', undefined);
          return;
        }

        Ember.set(this.task, 'content', content.toJSON({
          includeId: true
        }));
      },
      save: function save() {
        var _this7 = this;

        if (!this.edited) {
          Ember.set(this.templatePeriodTasks, 'editable', true);
        }

        if (this.isNotificationTask) {
          if (this.templatePeriodTasks.notificationRecipient == "") {
            this.notify.info(this.intl.t('emails.rules.recipient.no_recipient_seleted_error'), {
              closeAfter: 5000
            });
            return;
          }
        } else {
          Ember.set(this.templatePeriodTasks, 'notificationRecipient', null);
        }

        if (this.showPlanning) {
          if (isNaN(this.templatePeriodTasks.startOffset)) {
            Ember.set(this.templatePeriodTasks, 'startOffset', 0);
          }

          ;

          if (isNaN(this.templatePeriodTasks.endOffset)) {
            Ember.set(this.templatePeriodTasks, 'endOffset', 0);
          }

          ;

          if (!this.task.planning && !this.isNotificationTask) {
            Ember.set(this.templatePeriodTasks, 'startOffset', null);
            Ember.set(this.templatePeriodTasks, 'endOffset', null);
            Ember.set(this.templatePeriodTasks, 'startOffsetUnit', null);
            Ember.set(this.templatePeriodTasks, 'endOffsetUnit', null);
            Ember.set(this.templatePeriodTasks, 'startOffsetDirection', null);
            Ember.set(this.templatePeriodTasks, 'endOffsetDirection', null);
          }

          if (this.task.notification && this.task.notification.id) {
            delete this.templatePeriodTasks.notification;
            Ember.set(this.templatePeriodTasks, 'notification', this.task.notification);
          } else {
            Ember.set(this.templatePeriodTasks, 'notification', null);
          }
        }

        if (this.edited || this.editFromTaskList) {
          if (this.task.type === 'ihm') Ember.set(this.task, 'type', this.editTask.type);
          var path = _environment.default.APP.API_HOST + '/tasks/' + this.task.id;
          this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(this.task)).then(function (task) {
            for (var att in _this7.task) {
              Ember.set(_this7.editTask, att, _this7.task[att]);
            }

            if (_this7.showPlanning) {
              Ember.set(_this7.editTask, 'periodMandatory', _this7.templatePeriodTasks.mandatory);
              Ember.set(_this7.editTask, 'periodNotificationRecipient', _this7.templatePeriodTasks.notificationRecipient.toLowerCase());
              var id = _this7.templatePeriodTasks.id;
              delete _this7.templatePeriodTasks.id;

              var _path = _environment.default.APP.API_HOST + '/templates/' + _this7.template.id + '/periods/' + id;

              _this7.ajax.requestWithBodyPromise(_path, 'PATCH', 'application/json', JSON.stringify(_this7.templatePeriodTasks)).then(function (task) {
                Ember.set(_this7, 'show', false);
              });
            } else {
              Ember.set(_this7, 'show', false);
            }

            if (_this7.onFinish) {
              _this7.onFinish();
            }
          });
        } else {
          if (this.task.id) {
            if (this.onTaskAdded) {
              this.onTaskAdded(this.task, this.templatePeriodTasks);
            }

            Ember.set(this, 'show', false);
          } else {
            var _path2 = _environment.default.APP.API_HOST + '/tasks';

            this.ajax.requestWithBodyPromise(_path2, 'POST', 'application/json', JSON.stringify(this.task)).then(function (task) {
              if (_this7.onTaskAdded) {
                _this7.onTaskAdded(task, _this7.templatePeriodTasks);
              }

              Ember.set(_this7, 'show', false);
            });
          }
        }
      },
      saveOtherNotification: function saveOtherNotification() {
        var _this8 = this;

        if (!this.edited) {
          Ember.set(this.templateNotification, 'editable', true);
        }

        if ((this.templateNotification.triggerInitiator == 'task_completed' || this.templateNotification.triggerInitiator == 'task_not_completed') && !this.templateNotification.task) {
          return;
        }

        if (this.templateNotification.notificationRecipient == "") {
          this.notify.info(this.intl.t('emails.rules.recipient.no_recipient_seleted_error'), {
            closeAfter: 5000
          });
          return;
        }

        if (this.templateNotification.triggerInitiator == 'custom_date' && !this.templateNotification.customDate) {
          this.notify.info(this.intl.t('emails.rules.custom_date.no_date_selected_error'), {
            closeAfter: 5000
          });
          return;
        }

        if (isNaN(this.templatePeriodTasks.startOffset)) {
          Ember.set(this.templatePeriodTasks, 'startOffset', 0);
        }

        ;

        if (isNaN(this.templatePeriodTasks.endOffset)) {
          Ember.set(this.templatePeriodTasks, 'endOffset', 0);
        }

        ;
        Ember.set(this.templateNotification, 'startOffset', this.templatePeriodTasks.startOffset);
        Ember.set(this.templateNotification, 'startOffsetUnit', this.templatePeriodTasks.startOffsetUnit);
        Ember.set(this.templateNotification, 'startOffsetDirection', this.templatePeriodTasks.startOffsetDirection);

        if (this.templateNotification.triggerInitiator == 'custom_date') {
          Ember.set(this.templateNotification, 'startOffset', null);
          Ember.set(this.templateNotification, 'startOffsetUnit', null);
          Ember.set(this.templateNotification, 'startOffsetDirection', null);
          Ember.set(this.templateNotification, 'endOffset', null);
          Ember.set(this.templateNotification, 'endOffsetUnit', null);
          Ember.set(this.templateNotification, 'endOffsetDirection', null);
          Ember.set(this.templateNotification, 'customFieldId', null);
          Ember.set(this.templateNotification, 'task', null);
        } else if (this.templateNotification.triggerInitiator == 'task_completed' || this.templateNotification.triggerInitiator == 'task_not_completed') {
          Ember.set(this.templateNotification, 'customFieldId', null);
          Ember.set(this.templateNotification, 'customDate', null);
        } else if (this.templateNotification.triggerInitiator == 'custom_field') {
          Ember.set(this.templateNotification, 'customDate', null);
          Ember.set(this.templateNotification, 'task', null);
        } else {
          Ember.set(this.templateNotification, 'customDate', null);
        }

        var path = _environment.default.APP.API_HOST + '/templates/' + this.templateNotification.template.id + '/notifications/' + this.templateNotification.notification.id;
        var method = 'POST';

        if (this.edited) {
          path = _environment.default.APP.API_HOST + '/templates/' + this.templateNotification.template.id + '/notifications/' + this.templateNotification.id;
          method = 'PATCH';
        }

        Ember.set(this.templateNotification, 'template', undefined);
        this.ajax.requestWithBodyPromise(path, method, 'application/json', JSON.stringify(this.templateNotification)).then(function (template) {
          if (_this8.onOtherTaskAdded && !_this8.edited) {
            _this8.onOtherTaskAdded(template);
          } else if (_this8.onOtherTaskUpdated && _this8.edited) {
            _this8.onOtherTaskUpdated(template);
          }

          Ember.set(_this8, 'show', false);

          if (_this8.closeActions) {
            _this8.closeActions();
          }
        });
      }
    }
  });

  _exports.default = _default;
});