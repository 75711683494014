define("teelt-frontend/templates/components/task/task-startline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vGKvTQ48",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"startline\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"startline\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[7,\"svg\"],[11,\"width\",\"18\"],[11,\"height\",\"18\"],[11,\"viewBox\",\"0 0 18 18\"],[11,\"fill\",\"none\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[9],[0,\"\\n    \"],[7,\"path\"],[11,\"d\",\"M8 7.67969C8 7.67969 6.5 4.67969 4.0625 4.67969C2.37125 4.67969 1 6.02344 1 7.67969C1 9.33594 2.37125 10.6797 4.0625 10.6797C5.23469 10.6797 6.28125 9.97938 7 9.17969\"],[11,\"stroke\",\"#FFFFFF\"],[11,\"stroke-miterlimit\",\"10\"],[11,\"stroke-linecap\",\"round\"],[9],[10],[0,\"\\n    \"],[7,\"path\"],[11,\"d\",\"M8 7.67969C8 7.67969 9.5 10.6797 11.9375 10.6797C13.6287 10.6797 15 9.33594 15 7.67969C15 6.02344 13.6287 4.67969 11.9375 4.67969C10.7653 4.67969 9.71875 5.38 9 6.17969\"],[11,\"stroke\",\"#FFFFFF\"],[11,\"stroke-miterlimit\",\"10\"],[11,\"stroke-linecap\",\"round\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/task/task-startline.hbs"
    }
  });

  _exports.default = _default;
});