define("teelt-frontend/services/staticlike-data", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    companyCustomFields: undefined,
    companyCustomFieldsPeriod: 30 * 60 * 1000,
    companyCustomFieldsPromise: undefined,
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    eventBus: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.refreshCompanyCustomFields();
      this.initIntervals();
      this.eventBus.on('RELOAD_CUSTOMFIELDS', this, function () {
        _this.refreshCompanyCustomFields();
      });
    },
    destroy: function destroy() {
      this.eventBus.off('RELOAD_CUSTOMFIELDS');

      this._super.apply(this, arguments);
    },
    refreshCompanyCustomFields: function refreshCompanyCustomFields() {
      var _this2 = this;

      var promise = this.ajaxService.requestPromise(_environment.default.APP.API_HOST + '/custom-fields', 'GET').then(function (fields) {
        Ember.set(_this2, 'companyCustomFields', fields);
        return fields;
      }).catch(function (err) {
        console.error("[dev] Failed to refresh companyCustomFields");
      });
      Ember.set(this, 'companyCustomFieldsPromise', promise);
    },
    initIntervals: function initIntervals() {
      this.initCompanyCustomFieldsInterval();
    },
    initCompanyCustomFieldsInterval: function initCompanyCustomFieldsInterval() {
      var _this3 = this;

      setInterval(function () {
        _this3.refreshCompanyCustomFields();
      }, this.companyCustomFieldsPeriod);
    },
    companyHasCustomField: function companyHasCustomField(codeKey) {
      return this.companyCustomFieldsPromise.then(function (fields) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = fields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var cf = _step.value;
            if (cf.codeKey === codeKey) return true;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return false;
      });
    }
  });

  _exports.default = _default;
});