define("teelt-frontend/components/ihm/meet-people/notification-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'new-task-notification-select',
    store: Ember.inject.service(),
    page: 0,
    limit: 16,
    canLoadMore: true,
    query: '',
    onTypeChanged: Ember.observer('task.type', function () {
      this.loadPage();
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.loadPage();

      if (this.defaultNotificationId) {
        this.store.findRecord('notification', this.defaultNotificationId).then(function (data) {
          _this.send('selectNotification', data);
        });
      }
    },
    loadPage: function loadPage() {
      var _this2 = this;

      var params = {};
      params.offset = this.page * this.limit;
      params.limit = this.limit;
      params.type = 'CUSTOM';
      params.selectedId = this.notificationId;

      if (this.query) {
        params.search = this.query;
      }

      Ember.set(this, 'canLoadMore', false);
      this.store.query('notification', params).then(function (data) {
        if (data && data.length >= _this2.limit) {
          Ember.set(_this2, 'canLoadMore', true);
        }

        Ember.set(_this2, "notifications", data.toArray());
      }).finally(function () {
        Ember.set(_this2, 'isLoading', false);
      });
    },
    actions: {
      onSearchKeyUp: function onSearchKeyUp() {
        var _this3 = this;

        if (this.keyUpAction) {
          Ember.run.cancel(this.keyUpAction);
        }

        var keyUpAction = Ember.run.later(function () {
          Ember.set(_this3, 'page', 0);

          _this3.loadPage();
        }, 300);
        Ember.set(this, 'keyUpAction', keyUpAction);
      },
      next: function next() {
        Ember.set(this, 'page', this.page + 1);
        this.loadPage();
      },
      prev: function prev() {
        Ember.set(this, 'page', this.page - 1);
        this.loadPage();
      },
      selectNotification: function selectNotification(notification) {
        if (notification) {
          Ember.set(this, 'notificationId', notification.id);
        } else {
          Ember.set(this, 'notificationId', undefined);
        }

        if (this.onSelectNotification) {
          this.onSelectNotification(notification);
        }
      },
      newNotification: function newNotification() {
        var notification = this.store.createRecord('notification', {
          type: 'CUSTOM',
          recipient: 'TALENT',
          triggerInitiator: 'action_start',
          contents: [{
            contentType: "EMAIL",
            officeId: null,
            tagId: null,
            lang: this.selectedLang,
            disabled: true,
            subject: "",
            body: "",
            replyTo: ""
          }, {
            contentType: "SMS",
            officeId: null,
            tagId: null,
            lang: this.selectedLang,
            disabled: true,
            subject: "",
            body: "",
            replyTo: ""
          }],
          schedules: [{}],
          associatedRole: null
        });
        Ember.set(this, 'newNotif', notification);
        Ember.set(this, 'showNewNotif', true);
      },
      onFinishNewNotif: function onFinishNewNotif() {
        Ember.set(this, 'showNewNotif', false);
        this.loadPage();
      }
    }
  });

  _exports.default = _default;
});