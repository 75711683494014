define("teelt-frontend/components/content/content-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['content-card'],
    classNameBindings: ['content.selected:background-primary-color'],
    googleIframeStartTag: '<iframe src="https://www.google.com/maps/',
    didRender: function didRender() {
      if (this.content.type === "video" && this.content.mediaUrl) {
        this.resizeVideo();
      }
    },
    resizeVideo: function resizeVideo() {
      var videoContainer = this.$('.video-content');
      var video = this.$('video')[0];
      var self = this;
      video.addEventListener("loadedmetadata", function (e) {
        var width = this.videoWidth,
            height = this.videoHeight;
        var ratio = width / height;
        var containerRatio = videoContainer.width() / videoContainer.height();

        if (self.$('video')) {
          if (ratio > containerRatio) {
            self.$('video').css('width', 'auto');
            self.$('video').css('height', '100%');
          } else {
            self.$('video').css('width', '100%');
            self.$('video').css('height', 'auto');
          }
        }
      }, false);
    }
  });

  _exports.default = _default;
});