define("teelt-frontend/templates/talent/profile/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kmAdpG+7",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"id\",\"talent-integration-document\"],[11,\"class\",\"g-talent-shadow-block background-container\"],[9],[0,\"\\n    \"],[1,[27,\"talents/talent-documents-blocks\",null,[[\"talent\"],[[23,[\"model\",\"talent\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/talent/profile/documents.hbs"
    }
  });

  _exports.default = _default;
});