define("teelt-frontend/templates/components/utils/progress-bar-file-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WLUvHx03",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"hideOnZero\"]],[27,\"not\",[[27,\"gt\",[[23,[\"value\"]],0],null]],null]],null]],null,{\"statements\":[[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"outer-bar\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"inner-bar\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text-value center\"],[9],[1,[27,\"if\",[[23,[\"value\"]],[23,[\"value\"]],\"0\"],null],false],[0,\"%\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/progress-bar-file-input.hbs"
    }
  });

  _exports.default = _default;
});