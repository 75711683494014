define("teelt-frontend/components/ai/new-module-by-ai", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['new-module-by-ai'],
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    allMessages: [],
    iaConversation: [],
    createUserMessage: function createUserMessage(userMessage) {
      var container = document.getElementById('output');
      var newMessage = document.createElement('div');
      container.appendChild(newMessage);
      newMessage.className = "message-user";
      var icon = document.createElement('span');
      newMessage.appendChild(icon);
      icon.className = "material-icons";
      icon.textContent = "chat_bubble";
      var message = document.createElement('span');
      message.className = 'message';
      var messagePre = document.createElement('pre');
      messagePre.textContent = userMessage;
      message.appendChild(messagePre);
      newMessage.appendChild(message);
    },
    createIAMessage: function createIAMessage(iaMessage) {
      var container = document.getElementById('output');
      var newMessage = document.createElement('div');
      container.appendChild(newMessage);
      newMessage.className = "message-assistant";
      var icon = document.createElement('span');
      newMessage.appendChild(icon);
      icon.className = "material-icons";
      icon.textContent = "lightbulb_outline";
      var message = document.createElement('span');
      message.className = 'message';
      var messagePre = document.createElement('pre');
      messagePre.textContent = iaMessage;
      message.appendChild(messagePre);
      newMessage.appendChild(message);
      return messagePre;
    },
    actions: {
      handleKeyUp: function handleKeyUp() {},
      chatCompletion: function chatCompletion() {
        var _this = this;

        if (this.question && !this.waitingAI) {
          Ember.set(this, 'waitingAI', true);
          var path = _environment.default.APP.API_HOST + "/ai/modules/" + this.moduleType;
          var message = this.question;

          if (this.module) {
            Ember.set(this, 'moduleId', this.module.id);
            Ember.set(this.module, 'aiMessage', '');
            message += "\n Voici le dernier JSON à jour que tu devras reprendre pour ta réponse : " + JSON.stringify(this.module);
          }

          var request = {
            message: message,
            oldMessages: JSON.parse(JSON.stringify(this.allMessages))
          };
          this.allMessages.push({
            role: 'user',
            content: this.question
          });
          this.iaConversation.push({
            role: 'user',
            content: this.question
          });
          this.createUserMessage(this.question);
          Ember.set(this, 'question', '');
          var iaMessage = this.createIAMessage(" ... ");
          this.ajax.requestWithBodyPromise(path, "POST", "application/json", JSON.stringify(request)).then(function (response) {
            Ember.set(_this, 'waitingAI', false);
            var msg = '';

            if (response.choices && response.choices.length) {
              var choice = response.choices[0];

              if (choice && choice.message) {
                var _message = choice.message;

                if (_message.content) {
                  var content = _message.content.trim();

                  content = content.replaceAll('```json', '');
                  content = content.replaceAll('```', '');

                  try {
                    if (_this.onAIContent) {
                      msg = _this.onAIContent(content);
                    } else {
                      var module = JSON.parse(content);
                      Ember.set(_this, 'module', module);
                      Ember.set(_this.module, 'id', _this.moduleId);

                      if (module.aiMessage) {
                        msg = module.aiMessage.replaceAll('JSON', 'Module');
                      }
                    }
                  } catch (err) {
                    console.log(err);
                    msg = _this.intl.t("ai.module.error");
                  }

                  _this.allMessages.push({
                    role: 'assistant',
                    content: content
                  });
                }
              }
            }

            iaMessage.textContent = msg;
          });
        }
      }
    }
  });

  _exports.default = _default;
});