define("teelt-frontend/routes/board/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    userService: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    model: function model() {
      return {
        lastTalents: this.store.query('user', {
          limit: 8
        }),
        loadingTalents: true
      };
    },
    afterModel: function afterModel(model) {
      model.lastTalents.then(function () {
        Ember.set(model, 'loadingTalents', false);
      });
    }
  });

  _exports.default = _default;
});