define("teelt-frontend/components/task/task-deadline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['task-deadline'],
    intl: Ember.inject.service(),
    deadline: Ember.computed('talent', 'period', 'task.endOffsetDirection', 'task.endOffsetUnit', 'task.endOffset', function () {
      if (!this.talent || !this.period || !this.task || !this.talent[this.period.triggerDate]) return;
      var deadline = moment(this.talent[this.period.triggerDate]);

      if (this.task.endOffsetDirection && this.task.endOffsetUnit) {
        if (this.task.endOffset) {
          if (this.task.endOffsetDirection == "BEFORE") {
            deadline = deadline.subtract(this.task.endOffset, this.task.endOffsetUnit);
          } else {
            deadline = deadline.add(this.task.endOffset, this.task.endOffsetUnit);
          }
        }

        if (this.task.endOffsetUnit == 'day') {
          return deadline.format('DD/MM/YYYY');
        } else {
          return deadline.format('DD/MM/YYYY HH:mm');
        }
      }
    })
  });

  _exports.default = _default;
});