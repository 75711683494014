define("teelt-frontend/routes/board/admin/company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.session.data.authenticated.user.roles.includes('ROLE_ADMIN')) {
        this.router.transitionTo('board.talents');
      }
    },
    actions: {
      openCompany: function openCompany(id) {
        this.transitionTo('board.company.company-detail', id);
      }
    }
  });

  _exports.default = _default;
});