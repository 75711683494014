define("teelt-frontend/templates/components/utils/email-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DuNMtOav",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"utils/html-editor/html-editor\",null,[[\"html\"],[[23,[\"company\",\"emailSignature\"]]]]],false],[0,\"\\n\\n\"],[7,\"button\"],[11,\"class\",\"waves-effect waves-light btn background-primary-color float-right mt-10 \"],[3,\"action\",[[22,0,[]],\"save\"]],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"word.save\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/email-sign.hbs"
    }
  });

  _exports.default = _default;
});