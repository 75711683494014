define("teelt-frontend/templates/components/content/sign-pending-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BCxwbxFK",
    "block": "{\"symbols\":[\"p\"],\"statements\":[[4,\"modal-dialog\",null,[[\"onClose\",\"translucentOverlay\",\"containerClass\",\"onClickOverlay\"],[[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showSignPending\"]]],null],false],null],true,\"basic-confirm\",[27,\"action\",[[22,0,[]],\"onClickOverlay\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"modal-container\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"btn-close btn-transparent foreground-primary-color\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showSignPending\"]]],null],false]],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"close\"],[10],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"inner-container\"],[9],[0,\"\\n      \"],[7,\"h1\"],[11,\"class\",\"foreground-primary-color\"],[9],[1,[27,\"t\",[\"word.signatories\"],null],false],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"sign-pending-container\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"pendingData\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"sign-pending-row\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"role-icon\"],[9],[1,[27,\"utils/get-role-icon\",null,[[\"role\"],[[22,1,[\"role\"]]]]],false],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"role-label\"],[9],[1,[27,\"role/role-custom-name\",[[22,1,[\"label\"]]],null],false],[10],[0,\" \\n            \"],[7,\"div\"],[11,\"class\",\"state\"],[9],[1,[27,\"t\",[[27,\"concat\",[\"word.\",[22,1,[\"state\"]]],null]],null],false],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\" \\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/content/sign-pending-popup.hbs"
    }
  });

  _exports.default = _default;
});