define("teelt-frontend/components/ihm/explore-office/explore-office-builder", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-builder'],
    actions: {
      addNewField: function addNewField() {
        var _this = this;

        var scrollPosition = (0, _jquery.default)('.update-module-page') ? (0, _jquery.default)('.update-module-page').scrollTop() : 0;
        var fields = this.exploreoffice.fields;
        Ember.set(this.exploreoffice, 'fields', undefined);
        fields.pushObject({
          type: 'none',
          inputErrors: {}
        });
        setTimeout(function () {
          Ember.set(_this.exploreoffice, 'fields', fields);
          setTimeout(function () {
            if (scrollPosition) {
              (0, _jquery.default)('.update-module-page').scrollTop(scrollPosition + 300);
            }
          });
        });
      },
      onDeleteItem: function onDeleteItem(item) {
        var _this2 = this;

        var scrollPosition = (0, _jquery.default)('.update-module-page') ? (0, _jquery.default)('.update-module-page').scrollTop() : 0;
        var fields = this.exploreoffice.fields;
        Ember.set(this.exploreoffice, 'fields', undefined);
        fields.removeObject(item);
        setTimeout(function () {
          Ember.set(_this2.exploreoffice, 'fields', fields);
          setTimeout(function () {
            if (scrollPosition) {
              (0, _jquery.default)('.update-module-page').scrollTop(scrollPosition - 300);
            }
          });
        });
      }
    }
  });

  _exports.default = _default;
});