define("teelt-frontend/templates/components/template/template-campaign-assign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5EeL27lQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n\\n    \"],[1,[27,\"talents/talents-list\",null,[[\"canTriggerTalent\",\"templateType\",\"showTemplateInfos\",\"selectedIds\",\"onMultySelectionChanged\",\"maxNumber\"],[true,[23,[\"module\",\"type\"]],[23,[\"showTemplateInfos\"]],[23,[\"module\",\"selectedTalentIds\"]],[27,\"action\",[[22,0,[]],\"onTalentsSelected\"],null],10]]],false],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"g-main-controls\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"close\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"class\",\"left waves-effect waves-light btn-large foreground-primary-color border-primary-color\"],[3,\"action\",[[22,0,[]],[23,[\"close\"]]]],[9],[0,\"\\n            \"],[1,[27,\"capitalize\",[[27,\"t\",[\"word.cancel\"],null]],null],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n        \"],[7,\"button\"],[11,\"class\",\"right waves-effect waves-light btn-large background-primary-color\"],[3,\"action\",[[22,0,[]],\"save\"]],[9],[0,\"\\n            \"],[1,[27,\"capitalize\",[[27,\"t\",[\"word.save\"],null]],null],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/template/template-campaign-assign.hbs"
    }
  });

  _exports.default = _default;
});