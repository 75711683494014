define("teelt-frontend/helpers/role/role-custom-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Helper.extend({
    rolecustomService: Ember.inject.service('rolecustom-service'),
    eventBus: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.eventBus.on('ROLELIST_UPDATED', this, 'onRoleListUpdated');
    },
    destroy: function destroy() {
      this.eventBus.off('ROLELIST_UPDATED', this, 'onRoleListUpdated');

      this._super.apply(this, arguments);
    },
    onRoleListUpdated: function onRoleListUpdated() {
      var _this = this;

      Ember.run.join(function () {
        _this.recompute();
      });
    },
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          role = _ref2[0],
          _ref2$ = _ref2[1],
          ignoreIfEmpty = _ref2$ === void 0 ? false : _ref2$;

      var roleName = this.rolecustomService.getRoleName(role);

      if (roleName) {
        return roleName;
      }

      if (!ignoreIfEmpty) {
        return role;
      }
    }
  });

  _exports.default = _default;
});