define("teelt-frontend/components/event/event-date-edit", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['event-calendar'],
    uiUtils: Ember.inject.service('ui-utils'),
    times: ['00:00', '00:15', '00:30', '00:45', '01:00', '01:15', '01:30', '01:45', '02:00', '02:15', '02:30', '02:45', '03:00', '03:15', '03:30', '03:45', '04:00', '04:15', '04:30', '04:45', '05:00', '05:15', '05:30', '05:45', '06:00', '06:15', '06:30', '06:45', '07:00', '07:15', '07:30', '07:45', '08:00', '08:15', '08:30', '08:45', '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45', '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45', '15:00', '15:15', '15:30', '15:45', '16:00', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45', '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45', '21:00', '21:15', '21:30', '21:45', '22:00', '22:15', '22:30', '22:45', '23:00', '23:15', '23:30', '23:45'],
    initTimepicker: function initTimepicker() {
      var _this = this;

      setTimeout(function () {
        _this.$('.select').formSelect();

        _this.$('.time-block .dropdown-trigger').on('click', function () {
          var container = _this.$('.time-block .dropdown-content');

          if (container) {
            var targetElement = container.find('li span').filter(function (index, span) {
              return _this.$(span).text().trim() === '08:00';
            }).parent();

            if (targetElement.length > 0) {
              var offsetTop = targetElement[0].offsetTop;
              container.scrollTop(offsetTop);
            }
          }
        });
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      var hours = 9;
      var minutes = 0;

      if (this.event[this.timeKey]) {
        hours = moment(this.event[this.timeKey]).hours();
        minutes = moment(this.event[this.timeKey]).minutes();
      }

      Ember.set(this, 'time', (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.initTimepicker();
    },
    actions: {
      TimeChanged: function TimeChanged(time) {
        if (this.onTimeChanged) {
          this.onTimeChanged(time, this.timeKey);
        }
      }
    }
  });

  _exports.default = _default;
});