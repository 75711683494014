define("teelt-frontend/templates/components/form/forms-blocks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YpVfhE1R",
    "block": "{\"symbols\":[\"form\",\"period\"],\"statements\":[[7,\"div\"],[11,\"class\",\"forms-periods-filter\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"periods\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"category-container\"],[3,\"action\",[[22,0,[]],\"toggleCurrentPeriod\",[22,2,[]]]],[9],[0,\"\\n      \"],[7,\"a\"],[12,\"class\",[28,[\"period-filter \",[27,\"if\",[[27,\"eq\",[[22,2,[]],[23,[\"currentPeriod\"]]],null],\"active\"],null]]]],[9],[1,[27,\"capitalize\",[[22,2,[\"name\"]]],null],false],[10],[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[28,[\"blue-line \",[27,\"if\",[[27,\"eq\",[[22,2,[]],[23,[\"currentPeriod\"]]],null],\"active\"],null]]]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"forms\"]]],null,{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,1,[\"periodId\"]],[23,[\"currentPeriod\",\"id\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"form/forms-block\",null,[[\"form\",\"actionLabel\",\"actionAction\",\"completed\",\"talent\",\"period\"],[[22,1,[]],[23,[\"actionLabel\"]],[23,[\"actionAction\"]],[23,[\"completed\"]],[23,[\"talent\"]],[23,[\"currentPeriod\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/form/forms-blocks.hbs"
    }
  });

  _exports.default = _default;
});