define("teelt-frontend/templates/board/form/satisfaction-rate-forms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "88BWV1Rk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"satisfaction-rate-forms\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"section-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"g-section-title foreground-primary-color\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"vertical-center\"],[9],[0,\"\\n              \"],[7,\"h1\"],[9],[1,[27,\"t\",[\"dashboard.satisfaction_rate.forms.title\"],null],false],[10],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"baseline\"],[9],[0,\"\\n              \"],[1,[27,\"t\",[\"dashboard.satisfaction_rate.description\"],null],false],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"  \\n    \"],[1,[21,\"form/satisfaction-rate-forms-list\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/form/satisfaction-rate-forms.hbs"
    }
  });

  _exports.default = _default;
});