define("teelt-frontend/templates/components/utils/color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qBMNoqDm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"round-color clickable\"],[12,\"style\",[28,[\"background-color:\",[21,\"color\"]]]],[3,\"action\",[[22,0,[]],\"toggle\"]],[9],[10],[0,\"\\n\"],[7,\"div\"],[12,\"id\",[28,[\"picker-\",[21,\"uuid\"]]]],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/color-picker.hbs"
    }
  });

  _exports.default = _default;
});