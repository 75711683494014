define("teelt-frontend/templates/board/contents/contents-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JshK6kEy",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"content/contents-generic-list\",null,[[\"canManage\"],[true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/contents/contents-list.hbs"
    }
  });

  _exports.default = _default;
});