define("teelt-frontend/components/template/table/templates-custom-table-content", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['templates-custom-table-content'],
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.key == 'users') {
        var path = _environment.default.APP.API_HOST + '/templates/' + this.result.id + '/users';
        this.ajax.requestPromise(path).then(function (users) {
          Ember.set(_this, 'users', users);
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('.template-actions').hide();
    },
    actions: {
      edit: function edit() {
        Ember.set(this, 'showActions', false);
        this.router.transitionTo('board.experience.experience-edit', this.result.id);
      },
      assign: function assign() {
        Ember.set(this, 'showActions', false);
        this.router.transitionTo('board.experience.experience-edit', this.result.id).then(function (route) {
          route.setForceTemplatePage('talents');
        });
      },
      duplicate: function duplicate() {
        var _this2 = this;

        Ember.set(this, 'showActions', false);
        Ember.set(this, 'isLoading', true);
        this.ajax.requestPromise(_environment.default.APP.API_HOST + '/templates/' + this.result.id + '/duplicate', 'POST').then(function (savedTemplate) {
          Ember.set(_this2, 'isLoading', false);

          _this2.router.transitionTo('board.experience.experience-edit', savedTemplate.id);
        });
      },
      makeTemplate: function makeTemplate(response) {
        var _this3 = this;

        if (!response) return;
        Ember.set(this, 'showActions', false);
        Ember.set(this, 'isLoading', true);
        this.ajax.requestPromise(_environment.default.APP.API_HOST + '/templates/' + this.result.id + '/model', 'POST').then(function (savedTemplate) {
          Ember.set(_this3, 'isLoading', false);

          _this3.router.transitionTo('board.experience.experience-edit', savedTemplate.id);
        });
      },
      prepareDeleteExperience: function prepareDeleteExperience() {
        Ember.set(this, 'showActions', false);
        Ember.set(this, 'experienceToDelete', this.result);
      },
      removeExperience: function removeExperience(response) {
        var _this4 = this;

        if (!response) return;
        this.experienceToDelete.deleteRecord();
        this.experienceToDelete.save().then(function () {
          _this4.eventBus.trigger('REFRESH_EXPERIENCES');
        });
      }
    }
  });

  _exports.default = _default;
});