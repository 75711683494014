define("teelt-frontend/components/dashboard/email-not-sent-alert", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['email-not-sent-alert'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    count: 0,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.set(this, 'loading', true);
      var params = {
        complexFilters: JSON.stringify([{
          key: 'emailIncident',
          type: 'boolean',
          operator: 'EQUAL',
          value1: 'true'
        }])
      };
      this.ajax.requestPromise(_environment.default.APP.API_HOST + '/activities/users-in-email-incident', 'GET').then(function (result) {
        Ember.set(_this, 'count', result);
        Ember.set(_this, 'loading', false);
      }).finally(function () {
        Ember.set(_this, 'loading', false);
      });
    },
    actions: {
      gotoList: function gotoList() {
        this.router.transitionTo('board.talents', {
          queryParams: {
            complexFilter: 'emailIncident'
          }
        }).then(function () {
          window.location.reload();
        });
      }
    }
  });

  _exports.default = _default;
});