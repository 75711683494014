define("teelt-frontend/components/tool/tool-detail", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['tool-detail'],
    ajax: Ember.inject.service(),
    actions: {
      openEditLink: function openEditLink() {
        Ember.set(this, 'linkFormOpened', true);
      },
      saveLink: function saveLink() {
        var apiUrl = _environment.default.APP.API_HOST + "/tools/" + this.tool.id + "/link";
        this.ajax.requestWithBodyPromise(apiUrl, 'PATCH', 'application/json', JSON.stringify({
          link: this.tool.company_tool.link
        }));
        Ember.set(this, 'linkFormOpened', false);
      }
    }
  });

  _exports.default = _default;
});