define("teelt-frontend/models/events/campaign", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    place: _emberData.default.attr('string'),
    fromDate: _emberData.default.attr('string'),
    toDate: _emberData.default.attr('string'),
    events: _emberData.default.attr(),
    selectedTalentIds: _emberData.default.attr(),
    users: _emberData.default.attr(),
    assignRequest: _emberData.default.attr(),
    startAudience: _emberData.default.attr(),
    endAudience: _emberData.default.attr()
  });

  _exports.default = _default;
});