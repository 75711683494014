define("teelt-frontend/routes/board/setting/period-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        periodId: params.period_id,
        period: this.store.findRecord('period', params.period_id, {
          reload: true
        })
      });
    }
  });

  _exports.default = _default;
});