define("teelt-frontend/routes/talent/integration/team/members-list", ["exports", "teelt-frontend/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    getTalentCompanyUrl: _environment.default.APP.API_HOST + "/users/me/team/company",
    ajax: Ember.inject.service(),
    offset: 0,
    limit: 16,
    afterModel: function afterModel(model, transition) {
      Ember.set(this, 'offset', 0);
      Ember.set(this, 'limit', 16);
      Ember.set(this, 'search', undefined);
      Ember.set(this, 'templateId', model.templateId);
      Ember.set(this, 'welcomeDate', model.welcomeDate);
      transition.send('loadMore', true);
    },
    actions: {
      onSearch: function onSearch(value) {
        Ember.set(this.currentModel, 'search', value);
      },
      loadMore: function loadMore(refresh) {
        var _this = this;

        var url = this.getTalentCompanyUrl + "?offset=" + this.offset + "&limit=" + this.limit;

        if (this.search && this.search != '') {
          url += "&search=" + this.search;
        }

        if (this.templateId) {
          url += "&templateId=" + this.templateId;
        }

        if (this.welcomeDate) {
          var minWelcomeDate = _moment.default.utc(this.welcomeDate).startOf('month').format('YYYY-MM-DD HH:mm');

          var maxWelcomeDate = _moment.default.utc(this.welcomeDate).endOf('month').format('YYYY-MM-DD HH:mm');

          url += "&startDate=" + minWelcomeDate + "&endDate=" + maxWelcomeDate;
          url += "&targetDateField=welcomeDate";
        }

        this.ajax.requestPromise(url).then(function (users) {
          Ember.set(_this.currentModel, "canLoadMore", true);
          var usersArray = users.toArray();
          var currentUsers = undefined;

          if (!refresh) {
            currentUsers = _this.currentModel.team ? _this.currentModel.team.users : undefined;
          }

          if (!currentUsers) {
            currentUsers = usersArray;
          } else if (usersArray.length > 0) {
            currentUsers = currentUsers.concat(usersArray);
          }

          if (usersArray.length != _this.limit) {
            Ember.set(_this.currentModel, "canLoadMore", false);
          }

          Ember.set(_this.currentModel, 'team', {
            users: currentUsers
          });
          _this.offset += _this.limit;
        });
      }
    }
  });

  _exports.default = _default;
});