define("teelt-frontend/components/talents/talent-events", ["exports", "teelt-frontend/config/environment", "moment", "jquery", "teelt-frontend/utils/object-util"], function (_exports, _environment, _moment, _jquery, _objectUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talent-events'],
    defaultMediaUrl: _environment.default.APP.DEFAULT_EVENT_MEDIA_URL,
    getGoogleLoginLink: _environment.default.APP.API_HOST + "/googleapis/loginlink",
    getAzureLoginLink: _environment.default.APP.API_HOST + "/azureapis/loginlink",
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    googleCalendar: Ember.inject.service('google-calendar'),
    eventBus: Ember.inject.service(),
    uiUtils: Ember.inject.service('ui-utils'),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'selected', ["0", "4"]);
      Ember.set(this, 'isAfterWelcomeDate', new Date() > new Date(this.talent.welcomeDate));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.getSharedCalendars();
      this.initCalendar();
    },
    events: Ember.computed('talent.remainingEvents', 'categories', function () {
      var events = this.talent.remainingEvents;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = events[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var event = _step.value;
          this.cleanUsers(event);
          this.addFilters(event, this.categories);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return events;
    }),
    categories: Ember.computed('talent.remainingEvents', function () {
      var events = this.talent.remainingEvents;
      return this.getAllCategories(events);
    }),
    nextEvent: Ember.computed('events', function () {
      if (!this.events) return;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = this.events[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var event = _step2.value;

          if ((0, _moment.default)(event.start).isAfter(_moment.default.utc())) {
            return event;
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      return this.events[this.events.length - 1];
    }),
    filters: Ember.computed('categories', 'selected', function () {
      var dateFilters = [{
        key: '1',
        label: this.intl.t("event.filter.date.today")
      }, {
        key: '2',
        label: this.intl.t("event.filter.date.this_week")
      }, {
        key: '3',
        label: this.intl.t("event.filter.date.this_month")
      }];
      var filters = [];
      filters.push({
        id: 'events-container',
        values: dateFilters,
        customId: "event-date",
        allKey: "0",
        allLabel: this.intl.t("event.filter.date.all"),
        groupsSelected: this.selected
      });
      filters.push({
        id: 'events-container',
        values: this.categories.map(function (c) {
          return {
            key: Math.abs((0, _objectUtil.default)().hashCode(c)),
            label: c
          };
        }),
        customId: "event-categories",
        allKey: "4",
        allLabel: this.intl.t("event.all_categories"),
        groupsSelected: this.selected
      });
      return filters;
    }),
    getAllCategories: function getAllCategories(events) {
      var categories = [];
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = events[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var event = _step3.value;

          if (event.category && categories.indexOf(event.category.name) < 0) {
            categories.push(event.category.name);
          }
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      return categories;
    },

    /**
     * Filters
     * 0 : All time
     * 1 : Today
     * 2 : This week
     * 3 : This month
     * 4 : All categories
     * 5 ... N category
     * @param {*} event
     * @param {*} categories
     */
    addFilters: function addFilters(event, categories) {
      var today = _moment.default.utc();

      var start = _moment.default.utc(event.start);

      var filters = [];
      filters.push(0);

      if (start.isSame(today, 'day')) {
        filters.push(1);
        filters.push(2);
        filters.push(3);
      } else if (start.isBefore(today.endOf('week'))) {
        filters.push(2);
        filters.push(3);
      } else if (start.isBefore(today.endOf('month'))) {
        filters.push(3);
      }

      filters.push(4);

      if (event.category) {
        filters.push(Math.abs((0, _objectUtil.default)().hashCode(event.category.name)));
      }

      Ember.set(event, "filters", filters.join(', '));
    },
    cleanUsers: function cleanUsers(event) {
      if (event.users) {
        var users = [];
        var _iteratorNormalCompletion4 = true;
        var _didIteratorError4 = false;
        var _iteratorError4 = undefined;

        try {
          for (var _iterator4 = event.users[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
            var user = _step4.value;

            if (user.id != this.talent.id) {
              users.push(user);
            }
          }
        } catch (err) {
          _didIteratorError4 = true;
          _iteratorError4 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
              _iterator4.return();
            }
          } finally {
            if (_didIteratorError4) {
              throw _iteratorError4;
            }
          }
        }

        Ember.set(event, 'users', users);
      }
    },
    getSharedCalendars: function getSharedCalendars() {
      var _this = this;

      if (this.talent.company.useGoogleCalendar) {
        this.googleCalendar.isConnected().then(function (connected) {
          Ember.set(_this, "isConnectedGoogle", connected);
        }).catch(function () {});
      }

      if (this.talent.company.useAzureCalendar) {
        var url = _environment.default.APP.API_HOST + "/azureapis/calendar/connected";
        return this.ajax.requestPromise(url, "GET").then(function (connected) {
          Ember.set(_this, "isConnectedAzure", connected);
        }).catch(function () {});
      }
    },
    initCalendar: function initCalendar() {
      var _this2 = this;

      var locale = "en";

      if (this.intl.locale && this.intl.locale.length > 0) {
        locale = this.intl.locale[0].split("-")[0];
      }

      var minDate = undefined;
      var maxDate = undefined;

      if (this.events) {
        minDate = this.events[0].start;
        maxDate = this.events[this.events.length - 1].end;
      }

      (0, _jquery.default)('#talent-events-calendar').flatpickr({
        locale: locale,
        inline: true,
        disableMobile: "true",
        // Disable using mobile internal datepicker,
        minDate: minDate,
        maxDate: maxDate,
        monthSelectorType: 'static',
        onChange: function onChange(selectedDates, dateStr, instance) {},
        onMonthChange: function onMonthChange() {},
        onYearChange: function onYearChange() {},
        enable: [function (date) {
          var enabled = false;

          if (_this2.events) {
            var _iteratorNormalCompletion5 = true;
            var _didIteratorError5 = false;
            var _iteratorError5 = undefined;

            try {
              for (var _iterator5 = _this2.events[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
                var event = _step5.value;
                var eventStartDate = (0, _moment.default)(event.start);
                var eventEndDate = (0, _moment.default)(event.end);

                if (eventStartDate.isSame(date, 'day') || eventEndDate.isSame(date, 'day')) {
                  enabled = true;
                }
              }
            } catch (err) {
              _didIteratorError5 = true;
              _iteratorError5 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion5 && _iterator5.return != null) {
                  _iterator5.return();
                }
              } finally {
                if (_didIteratorError5) {
                  throw _iteratorError5;
                }
              }
            }
          }

          return enabled;
        }]
      });
    },
    actions: {
      onFilter: function onFilter(filter, group_value) {},
      disconnectGoogleCalendar: function disconnectGoogleCalendar() {
        var _this3 = this;

        this.ajax.requestWithBodyPromise(this.getGoogleLoginLink, 'DELETE').then(function () {
          Ember.set(_this3, "isConnectedGoogle", false);
        });
      },
      connectGoogleCalendar: function connectGoogleCalendar() {
        this.ajax.requestWithBodyPromise(this.getGoogleLoginLink, 'POST', "application/json", JSON.stringify({
          redirect: window.location.href
        })).then(function (authLink) {
          var link = authLink.link.replace(/%25STATE%25/g, authLink.token + "_" + false);
          window.location.replace(link);
        });
      },
      connectAzureApis: function connectAzureApis() {
        this.ajax.requestWithBodyPromise(this.getAzureLoginLink, 'POST', "application/json", JSON.stringify({
          redirect: window.location.href,
          shared: false
        })).then(function (res) {
          window.location.replace(res.link);
        });
      },
      disconnectAzureApis: function disconnectAzureApis() {
        var _this4 = this;

        this.ajax.requestWithBodyPromise(this.getAzureLoginLink, 'DELETE', "application/json", JSON.stringify({
          redirect: window.location.href
        })).then(function (res) {
          Ember.set(_this4, "isConnectedAzure", false);
        });
      },
      eventShowMoreAction: function eventShowMoreAction(action, eventId) {
        if (action == 'MORE') {
          (0, _jquery.default)('#event-' + eventId + ' .event-description').addClass('read-more');
        } else {
          (0, _jquery.default)('#event-' + eventId + ' .event-description').removeClass('read-more');
        }

        this.eventBus.trigger('REINIT_FILTERIZR');
        var elem = (0, _jquery.default)('#event-' + eventId);

        if (elem && elem.length > 0) {
          (0, _jquery.default)("html").animate({
            scrollTop: elem.offset().top - 100
          }, 400);
        }
      }
    }
  });

  _exports.default = _default;
});