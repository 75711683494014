define("teelt-frontend/components/menu/main-menu", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['main-menu'],
    classNameBindings: ['openedMenu:opened', 'closed'],
    activeTab: 'companies',
    defaultProfileImageUrl: _environment.default.APP.DEFAULT_PROFILE_IMAGE_URL,
    helpCenterLink: "https://teelt.notion.site/Base-de-connaissance-31fc93106d2e450596ae496fe8ba9704",
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    customLabelsService: Ember.inject.service('custom-labels-service'),
    countTasksService: Ember.inject.service('count-tasks-service'),
    talentLabel: Ember.computed('customLabelsService.customLabels', function () {
      var customLabels = this.customLabelsService.customLabels;

      if (customLabels && customLabels['talentLabel']) {
        var str = customLabels['talentLabel'].charAt(customLabels['talentLabel'].length - 1) === 's' ? '' : 's';
        return customLabels['talentLabel'] + str;
      } else {
        return this.intl.t('word.talents');
      }
    }),
    tasksCount: Ember.computed('countTasksService.tasksCount', function () {
      return this.countTasksService.tasksCount;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.set(this, 'openedMenu', true);
    },
    changeTabFromRouteName: function changeTabFromRouteName() {
      var routeName = this.router.get('currentRouteName');
      var currentUrl = this.router.get('currentURL');

      if (routeName.indexOf('board.admin.company') >= 0 || routeName.indexOf('board.company') >= 0) {
        this.send("changeTab", "admin");
      } else if (routeName.indexOf('board.talent') >= 0) {
        this.send("changeTab", "talents");
      } else if (routeName.indexOf('board.experience') >= 0) {
        this.send("changeTab", "experiences");
      } else if (routeName.indexOf('board.modules') >= 0) {
        this.send("changeTab", "modules");
      } else if (routeName.indexOf('board.tasks') >= 0) {
        this.send("changeTab", "tasks");
      } else if (routeName.indexOf('board.event') >= 0) {
        this.send("changeTab", "events");
      } else if (routeName.indexOf('board.content') >= 0) {
        this.send("changeTab", "content");
      } else if (routeName.indexOf('board.emails') >= 0) {
        this.send("changeTab", "emails");
      } else if (routeName.indexOf('board.dashboard') >= 0) {
        this.send("changeTab", "dashboard");
      } else if (routeName.indexOf('board.analytics') >= 0) {
        this.send("changeTab", "analytics");
      } else if (routeName.indexOf('board.settings') >= 0) {
        this.send("changeTab", "settings");
      } else if (routeName.indexOf('board.audiences') >= 0) {
        this.send("changeTab", "audiences");
      } else if (routeName.indexOf('board.communication') >= 0) {
        this.send('changeTab', 'communications');
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.changeTabFromRouteName();
      Ember.set(this, 'authenticatedUser', this.session.data.authenticated.user);
    },
    actions: {
      changeTab: function changeTab(tab) {
        Ember.set(this, 'activeTab', tab);
      }
    },
    onRouteChanged: Ember.observer('router.currentRouteName', function () {
      this.changeTabFromRouteName();
    })
  });

  _exports.default = _default;
});