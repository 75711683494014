define("teelt-frontend/routes/talent/integration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    afterModel: function afterModel(model, transition) {
      var _this = this;

      Ember.set(model, "router", this.router);
      var sliderMenu = [];

      if (model.talent.selectedTemplate && model.talent.selectedTemplate.id) {
        var menuTask = {
          id: 'tasks',
          name: model.talent.selectedTemplate.talentPlatformName ? model.talent.selectedTemplate.talentPlatformName : model.talent.selectedTemplate.name,
          route: "talent.integration.tasks"
        };

        if (model.talent.template.subTemplates && model.talent.template.subTemplates.length) {
          menuTask['icon'] = 'keyboard_arrow_down';
        }

        sliderMenu.push(menuTask);

        if (model.talent.template.subTemplates && model.talent.template.subTemplates.length) {
          this.initMultiTemplateSelector();
        }
      }

      if (model.talent.remainingEvents && model.talent.remainingEvents.length) {
        sliderMenu.push({
          name: this.intl.t('integration.resume.my_events'),
          route: "talent.integration.events"
        });
      }

      if (model.talent.selectedTemplate) {
        this.store.query('content', {
          filter: "template" + model.talent.selectedTemplate.id
        }).then(function (contents) {
          if (contents && contents.length && _this.currentModel.sliderMenu.filterBy('route', "talent.integration.resources").length == 0) {
            var i = 0;

            for (i; i < _this.currentModel.sliderMenu.length; i++) {
              if (_this.currentModel.sliderMenu[i].route === "talent.integration.tools" || _this.currentModel.sliderMenu[i].route === "talent.integration.forms") {
                break;
              }
            }

            _this.currentModel.sliderMenu.insertAt(i, {
              name: _this.intl.t('menu.my_resources'),
              route: "talent.integration.resources"
            });

            var _selectedMenu = _this.getSelectedMenuFromTransition(model, transition);

            Ember.set(model, 'selectedMenu', _selectedMenu);
          }
        });
      }

      if (model.talent.hasTools) {
        sliderMenu.push({
          name: this.intl.t('integration.resume.my_tools'),
          route: "talent.integration.tools"
        });
      }

      if (model.talent.hasForms) {
        sliderMenu.push({
          name: this.intl.t('integration.resume.my_forms'),
          route: "talent.integration.forms"
        });
      }

      if (model.talent.hasAdministrativeForms) {
        sliderMenu.push({
          name: this.intl.t('administrative.menu'),
          route: "talent.integration.administratives"
        });
      }

      if (model.talent.company.activeMeetPeople && model.talent.hasMeetPeoples || model.talent.company.activeExploreOffice && model.talent.hasExploreOffices) {
        sliderMenu.push({
          name: this.intl.t('word.ihm'),
          route: "talent.integration.ihm"
        });
      }

      Ember.set(model, 'sliderMenu', sliderMenu);
      var selectedMenu = this.getSelectedMenuFromTransition(model, transition);
      Ember.set(model, 'selectedMenu', selectedMenu);
    },
    initMultiTemplateSelector: function initMultiTemplateSelector() {
      var _this2 = this;

      setTimeout(function () {
        var taskMenu = document.querySelectorAll('.slider-id-tasks'); // Parcourir tous les éléments avec la classe 'hover-element'

        taskMenu.forEach(function (element) {
          // Détecter l'entrée de la souris (hover)
          element.addEventListener('mouseover', function () {
            Ember.set(_this2.currentModel, "showTemplates", true);

            if (_this2.currentModel.multiCall) {
              clearTimeout(_this2.currentModel.multiCall);
            }

            var multiCall = setTimeout(function () {
              Ember.set(_this2.currentModel, "showTemplates", false);
            }, 4000);
            Ember.set(_this2.currentModel, 'multiCall', multiCall);
          });
        });
      }, 100);
    },
    getSelectedMenuFromTransition: function getSelectedMenuFromTransition(model, transition) {
      if (transition.targetName === 'talent.integration.index') {
        this.router.transitionTo(model.sliderMenu[0].route);
      }

      var selectedMenu = -1;

      if (transition) {
        for (var i = 0; i < model.sliderMenu.length; i++) {
          var menu = model.sliderMenu[i];

          if (menu.route === transition.targetName) {
            selectedMenu = i;
          }
        }
      }

      return selectedMenu;
    },
    actions: {
      willTransition: function willTransition(transition) {
        var selectedMenu = this.getSelectedMenuFromTransition(this.currentModel, transition);
        Ember.set(this.currentModel, "selectedMenu", selectedMenu);
      },
      onMenuSelected: function onMenuSelected(index, selected) {
        if (selected.id && selected.id == 'tasks') {
          if (this.currentModel.talent.template && this.currentModel.talent.template.subTemplates && this.currentModel.talent.template.subTemplates.length > 0) {
            Ember.set(this.currentModel, "showTemplates", !this.currentModel.showTemplates);
          } else {
            this.router.transitionTo("talent.integration.tasks");
          }
        }
      },
      changeTemplate: function changeTemplate(templateId) {
        var url = window.location.protocol + "//" + window.location.host + "/talent/integration/tasks?selectedTemplateId=" + templateId;

        if (url == window.location.href) {
          this.router.transition("talent.integration.tasks");
        } else {
          window.location.href = url;
        }
      }
    }
  });

  _exports.default = _default;
});