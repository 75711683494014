define("teelt-frontend/templates/components/task/task-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dGPX5be6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"onClose\",\"translucentOverlay\",\"containerClass\",\"onClickOverlay\"],[[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showTaskPreview\"]]],null],false],null],true,\"preview-popup\",[27,\"action\",[[22,0,[]],\"onClickOverlay\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"modal-container task-preview-container\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"class\",\"btn-close btn-transparent foreground-primary-color\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showTaskPreview\"]]],null],false]],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"close\"],[10],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"inner-container\"],[9],[0,\"\\n        \"],[1,[27,\"task/add-task-form\",null,[[\"task\",\"showTaskPreview\",\"templatePeriodTasks\",\"showPlanning\",\"period\"],[[23,[\"task\"]],[23,[\"showTaskPreview\"]],[23,[\"templatePeriodTasks\"]],[23,[\"showPlanning\"]],[23,[\"period\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/task/task-preview.hbs"
    }
  });

  _exports.default = _default;
});