define("teelt-frontend/components/utils/viewer/basic-image-viewer", ["exports", "teelt-frontend/utils/string-util", "viewerjs"], function (_exports, _stringUtil, _viewerjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'uuid', (0, _stringUtil.default)().generateUUID());
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var imageContainer = document.getElementById('image-container-' + this.uuid);
      var viewer = new _viewerjs.default(imageContainer, {});
    }
  });

  _exports.default = _default;
});