define("teelt-frontend/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FxQYpXAi",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"outlet\"],false],[0,\"\\n\"],[1,[27,\"ember-notify\",null,[[\"closeAfter\"],[4000]]],false],[0,\"\\n\"],[1,[21,\"utils/restore-impersonate\"],false],[0,\"\\n\"],[1,[21,\"utils/scrollbottom-action\"],false],[0,\"\\n\"],[1,[21,\"session/auto-disconnect-message\"],false],[0,\"\\n\\n\"],[4,\"if\",[[27,\"is-authenticated\",null,null]],null,{\"statements\":[[0,\"  \"],[1,[21,\"session/auto-disconnect\"],false],[0,\"\\n\"],[4,\"if\",[[27,\"show-switch-role\",null,null]],null,{\"statements\":[[0,\"    \"],[1,[21,\"utils/current-role\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"new-version-notifier\",[],[[\"@updateMessage\",\"@updateInterval\",\"@showReload\",\"@reloadButtonText\"],[[27,\"t\",[\"auto_update.message\"],null],60000,true,[27,\"t\",[\"auto_update.button\"],null]]]],[0,\"\\n\\n\"],[1,[21,\"utils/banner/meet-people-inactivity-banner\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/application.hbs"
    }
  });

  _exports.default = _default;
});