define("teelt-frontend/templates/components/notification/schedules-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/mL/o7H0",
    "block": "{\"symbols\":[\"firstSchedule\"],\"statements\":[[4,\"if\",[[23,[\"notification\",\"schedules\"]]],null,{\"statements\":[[4,\"let\",[[27,\"object-at\",[[23,[\"notification\",\"schedules\"]],0],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[0,\"\\n      \"],[1,[22,1,[\"quantity\"]],false],[0,\" \"],[1,[27,\"t\",[[27,\"concat\",[\"emails.rules.offset_picker.unit.\",[22,1,[\"unit\"]]],null]],null],false],[0,\" \"],[1,[27,\"t\",[[27,\"concat\",[\"emails.rules.offset_picker.direction.\",[22,1,[\"direction\"]]],null]],null],false],[0,\" \"],[1,[27,\"t\",[[27,\"concat\",[\"emails.rules.trigger_after.\",[23,[\"notification\",\"triggerInitiator\"]]],null]],null],false],[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"notification\",\"schedules\",\"length\"]],1],null]],null,{\"statements\":[[0,\"        (+\"],[1,[27,\"sub\",[[23,[\"notification\",\"schedules\",\"length\"]],1],null],false],[0,\" \"],[1,[27,\"t\",[\"emails.notification.alert\"],[[\"alerts\"],[[23,[\"notification\",\"schedules\",\"length\"]]]]],false],[0,\")\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"-\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/notification/schedules-summary.hbs"
    }
  });

  _exports.default = _default;
});