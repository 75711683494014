define("teelt-frontend/helpers/task/count-task-for-period-assign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countTask = countTask;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function countTask(_ref
  /*, hash*/
  ) {
    var _ref2 = _slicedToArray(_ref, 5),
        template = _ref2[0],
        periodId = _ref2[1],
        possiblesAssign = _ref2[2],
        talent = _ref2[3],
        filter = _ref2[4];

    var count = 0;

    if (template.periods) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = template.periods[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var tPeriod = _step.value;

          if (tPeriod.period.id == periodId) {
            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
              for (var _iterator2 = tPeriod.tasks[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                var task = _step2.value;

                if (task.assign != 'system') {
                  var canView = possiblesAssign.indexOf(task.assign) >= 0 || possiblesAssign.indexOf(task.assign.split('-')[0]) >= 0;

                  if (!canView && task.type == 'sign' && task.signContent && task.signContent.signatories) {
                    var _iteratorNormalCompletion3 = true;
                    var _didIteratorError3 = false;
                    var _iteratorError3 = undefined;

                    try {
                      for (var _iterator3 = task.signContent.signatories[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                        var signatory = _step3.value;

                        if (signatory == "ROLE_TALENT") {
                          canView = canView || possiblesAssign.indexOf('talent') >= 0;
                        } else if (signatory == "ROLE_MANAGER") {
                          canView = canView || possiblesAssign.indexOf('manager') >= 0;
                        } else if (signatory == "ROLE_RH") {
                          canView = canView || possiblesAssign.indexOf('rh') >= 0;
                        } else if (signatory == "ROLE_CUSTOM_OFFICE_MANAGER") {
                          canView = canView || possiblesAssign.indexOf('officeManager') >= 0;
                        } else if (signatory == "ROLE_MRH") {
                          canView = canView || possiblesAssign.indexOf('adminMRH') >= 0;
                        } else if (signatory == "ROLE_ADMINISTRATIVE") {
                          canView = canView || possiblesAssign.indexOf('administrative') >= 0;
                        } else if (signatory.startsWith('ROLE_CONSULT-')) {
                          canView = canView || possiblesAssign.indexOf(signatory) >= 0;
                        }
                      }
                    } catch (err) {
                      _didIteratorError3 = true;
                      _iteratorError3 = err;
                    } finally {
                      try {
                        if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
                          _iterator3.return();
                        }
                      } finally {
                        if (_didIteratorError3) {
                          throw _iteratorError3;
                        }
                      }
                    }
                  }

                  if (canView) {
                    var late = false;

                    if (task.endOffsetUnit) {
                      var talentDate = talent[tPeriod.period.triggerDate];

                      if (talentDate) {
                        var limitDate = moment(talentDate).add(task.endOffset, task.endOffsetUnit);

                        if (limitDate.isBefore(moment())) {
                          late = true;
                        }
                      }
                    }

                    var completed = false;

                    if (talent.taskValidations && talent.taskValidations.length) {
                      var _iteratorNormalCompletion4 = true;
                      var _didIteratorError4 = false;
                      var _iteratorError4 = undefined;

                      try {
                        for (var _iterator4 = talent.taskValidations[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                          var t = _step4.value;

                          if (t.id == task.id) {
                            completed = true;
                          }
                        }
                      } catch (err) {
                        _didIteratorError4 = true;
                        _iteratorError4 = err;
                      } finally {
                        try {
                          if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
                            _iterator4.return();
                          }
                        } finally {
                          if (_didIteratorError4) {
                            throw _iteratorError4;
                          }
                        }
                      }
                    }

                    if (filter == 'all') {
                      count++;
                    } else if (filter == 'late' && late) {
                      count++;
                    } else if (filter == 'future' && !completed && !late) {
                      count++;
                    } else if (filter == 'not-completed' && !completed) {
                      count++;
                    }
                  }
                }
              }
            } catch (err) {
              _didIteratorError2 = true;
              _iteratorError2 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                  _iterator2.return();
                }
              } finally {
                if (_didIteratorError2) {
                  throw _iteratorError2;
                }
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }

    return count;
  }

  var _default = Ember.Helper.helper(countTask);

  _exports.default = _default;
});