define("teelt-frontend/components/event/add-event", ["exports", "moment", "teelt-frontend/config/environment", "jquery"], function (_exports, _moment, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dateFormat: 'DD/MM/YYYY',
    uiUtils: Ember.inject.service('ui-utils'),
    store: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    ajax: Ember.inject.service(),
    searchUsersUrl: _environment.default.APP.API_HOST + "/users",
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.newEvent.category) {
        Ember.set(this.newEvent, 'category', {});
      }

      this.loadCategories().then(function () {
        _this.setSelectedCategory();
      });
      this.initElements();
    },
    resetErrors: function resetErrors() {
      Ember.set(this, 'titleIsNull', undefined);
      Ember.set(this, 'startIsNull', undefined);
      Ember.set(this, 'endIsNull', undefined);
      Ember.set(this, 'endBeforeStart', undefined);
      Ember.set(this, "bad_start_time_format", undefined);
      Ember.set(this, "bad_end_time_format", undefined);
    },
    getParticipants: function getParticipants(event) {
      var participants = [];

      if (event.participants) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = event.participants[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var participant = _step.value;
            if (participants.indexOf(participant.id) >= 0) continue;
            participants.push(participant.id);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      return participants;
    },
    validateEvent: function validateEvent(event) {
      if (this.blockSave) return false;
      this.resetErrors();
      var ok = true;

      if (!event.title) {
        Ember.set(this, 'titleIsNull', true);
        ok = false;
      }

      if (!event.start) {
        Ember.set(this, 'startIsNull', true);
        ok = false;
      }

      if (!event.end) {
        Ember.set(this, 'endIsNull', undefined);
        ok = false;
      }

      if (event.start && event.end) {
        var start = _moment.default.utc(event.start, this.dateFormat).format("YYYY-MM-DD");

        var completeStart = _moment.default.utc(start + ' ' + (event.startTime ? event.startTime : '00:00:00'));

        var end = _moment.default.utc(event.end, this.dateFormat).format("YYYY-MM-DD");

        var completeEnd = _moment.default.utc(end + ' ' + (event.endTime ? event.endTime : '23:59:59'));

        if (completeStart.isAfter(completeEnd)) {
          Ember.set(this, 'endBeforeStart', true);
          ok = false;
        }
      }

      var regex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

      if (event.startTime && !regex.test(event.startTime)) {
        Ember.set(this, "bad_start_time_format", true);
        ok = false;
      }

      if (event.endTime && !regex.test(event.endTime)) {
        Ember.set(this, "bad_send_time_format", true);
        ok = false;
      }

      return ok;
    },
    loadCategories: function loadCategories() {
      var _this2 = this;

      return this.store.findAll('statics/event-category').then(function (categories) {
        if (categories) {
          Ember.set(_this2, 'categories', categories);

          _this2.uiUtils.initAutocomplete((0, _jquery.default)('#autocomplete-category'), categories, "name", function () {});
        } else {
          Ember.set(_this2, 'categories', []);
        }
      });
    },
    setSelectedCategory: function setSelectedCategory() {
      var _this3 = this;

      this.categories.forEach(function (category) {
        Ember.set(category, 'selected', false);

        if (Number(category.id) === _this3.newEvent.categoryId) {
          Ember.set(category, 'selected', true);
        }
      });
    },
    onMediaProgress: function onMediaProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'mediaProgress', progress);
      }
    },
    actions: {
      addEvent: function addEvent() {
        if (!this.validateEvent(this.newEvent)) return;
        var data = this.$('#participants').select2('data');

        if (data) {
          var _participants = [];
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = data[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var participant = _step2.value;

              _participants.push({
                id: participant.id,
                username: participant.text
              });
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          Ember.set(this.newEvent, "participants", _participants);
        }

        var participants = this.getParticipants(this.newEvent);
        Ember.set(this.newEvent, 'participantIds', participants);
        this.addEvent(this.newEvent);
      },
      onTimeChanged: function onTimeChanged(time, timekey) {
        Ember.set(this.newEvent, timekey, time);
      },
      onCategoryChanged: function onCategoryChanged() {},
      onMediaChanged: function onMediaChanged(file) {
        var _this4 = this;

        if (!file) return;
        Ember.set(this, 'blockSave', true);
        this.fileUploadService.signAndUpload(file, false, function (e) {
          return _this4.onMediaProgress(e);
        }).then(function (fileUrl) {
          Ember.set(_this4.newEvent, 'mediaUrl', fileUrl);
        }).finally(function () {
          Ember.set(_this4, 'blockSave', false);
        });
      }
    },
    initEndDatepicker: function initEndDatepicker(minDate) {
      var _this5 = this;

      this.uiUtils.initDatePicker(this.$('#event-end'), function (date) {
        Ember.set(_this5.newEvent, "end", (0, _moment.default)(date).format(_this5.dateFormat));
      }, {
        minDate: minDate,
        maxDate: this.toDate,
        defaultDate: this.newEvent.end ? _moment.default.utc(this.newEvent.end, this.dateFormat).format('YYYY-MM-DD') : minDate
      });
    },
    initElements: function initElements() {
      var _this6 = this;

      Ember.run.later(function () {
        var textareas = document.querySelector('.materialize-textarea');

        if (textareas) {
          M.textareaAutoResize(textareas);
        }

        _this6.uiUtils.initDatePicker(_this6.$('#event-start'), function (date) {
          _this6.initEndDatepicker(date);

          if (!_this6.newEvent.end) {
            Ember.set(_this6.newEvent, "end", (0, _moment.default)(date).format(_this6.dateFormat));
          }
        }, {
          minDate: _this6.fromDate,
          maxDate: _this6.toDate,
          defaultDate: _this6.newEvent.start ? _moment.default.utc(_this6.newEvent.start, _this6.dateFormat).format('YYYY-MM-DD') : _this6.fromDate
        });

        _this6.initEndDatepicker(_this6.fromDate);

        _this6.$('#participants').select2({
          ajax: {
            url: _this6.searchUsersUrl,
            contentType: 'application/json;charset=utf-8',
            dataType: 'json',
            xhrFields: {
              withCredentials: true
            },
            headers: {
              'X-CSRF-TOKEN': _this6.ajax.getCSRFToken(),
              'x-auth-token': _this6.ajax.xAuthToken
            },
            data: function data(params) {
              var query = {
                search: params.term
              };
              return query;
            },
            processResults: function processResults(data) {
              var results = [];

              if (data) {
                var _iteratorNormalCompletion3 = true;
                var _didIteratorError3 = false;
                var _iteratorError3 = undefined;

                try {
                  for (var _iterator3 = data[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                    var talent = _step3.value;
                    var name = talent.username;

                    if (talent.lastName) {
                      name = (talent.firstName ? talent.firstName + ' ' : '') + talent.lastName;
                    }

                    results.push({
                      id: talent.id,
                      text: name,
                      username: talent.username
                    });
                  }
                } catch (err) {
                  _didIteratorError3 = true;
                  _iteratorError3 = err;
                } finally {
                  try {
                    if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
                      _iterator3.return();
                    }
                  } finally {
                    if (_didIteratorError3) {
                      throw _iteratorError3;
                    }
                  }
                }

                return {
                  results: results
                };
              }
            }
          }
        });

        _this6.$("#participants > option").prop("selected", "selected");

        _this6.$("#participants").trigger("change");
      });
    }
  });

  _exports.default = _default;
});