define("teelt-frontend/templates/components/content/content-assign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VKvBMJte",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"sections\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[1,[27,\"t\",[\"word.sections\"],null],false],[10],[0,\"\\n  \"],[7,\"ul\"],[9],[0,\"\\n    \"],[7,\"li\"],[12,\"class\",[27,\"if\",[[27,\"includes\",[[23,[\"sections\"]],\"home\"],null],\"selected\"],null]],[3,\"action\",[[22,0,[]],\"toggleHome\"]],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"float-left selected-icon\"],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"check\"],[10],[10],[0,\"\\n      \"],[1,[27,\"t\",[\"word.home\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"templates-block\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"word.templates\"],null]],null],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"sectionsLoaded\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"template/templates-list\",null,[[\"assignCategoryMode\",\"canTriggerTemplate\",\"assignMode\",\"selectedIds\"],[true,true,true,[23,[\"selectedTemplateIds\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"controls center\"],[9],[0,\"\\n    \"],[4,\"link-to\",[\"board.contents.contents-list\"],[[\"class\"],[\"btn btn-raised btn-white\"]],{\"statements\":[[1,[27,\"t\",[\"word.cancel\"],null],false]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-raised btn-primary waves-effect waves-light\"],[11,\"type\",\"button\"],[3,\"action\",[[22,0,[]],\"save\"]],[9],[1,[27,\"t\",[\"word.save\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/content/content-assign.hbs"
    }
  });

  _exports.default = _default;
});