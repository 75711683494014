define("teelt-frontend/templates/components/talents/talent-top-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IPt4kimB",
    "block": "{\"symbols\":[\"companykey\"],\"statements\":[[7,\"div\"],[11,\"class\",\"top-image\"],[12,\"style\",[27,\"background-cover\",[[27,\"if\",[[23,[\"authenticatedUser\",\"company\",\"backgroundUrl\"]],[23,[\"authenticatedUser\",\"company\",\"backgroundUrl\"]],\"/img/login-bg.jpg\"],null],\"top\"],null]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"current-page truncate\"],[9],[0,\"\\n\"],[4,\"let\",[[27,\"get\",[[23,[\"authenticatedUser\",\"company\"]],[27,\"concat\",[[23,[\"currentRoute\"]],\"MenuNameKey\"],null]],null]],null,{\"statements\":[[4,\"if\",[[22,1,[]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"t\",[[22,1,[]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[27,\"t\",[[27,\"concat\",[\"menu.\",[23,[\"currentRoute\"]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-top-image.hbs"
    }
  });

  _exports.default = _default;
});