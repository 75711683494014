define("teelt-frontend/utils/object-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = objectUtil;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function objectUtil() {
    return {
      clone: function clone(obj) {
        var copy;
        if (null === obj || _typeof(obj) != "object") return obj;

        if (obj instanceof Date) {
          copy = new Date();
          copy.setTime(obj.getTime());
          return copy;
        }

        if (obj instanceof Array) {
          copy = [];

          for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = objectUtil().clone(obj[i]);
          }

          return copy;
        }

        if (obj instanceof Object) {
          copy = {};

          for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = objectUtil().clone(obj[attr]);
          }

          return copy;
        }

        console.error("failed to copy obj : type not handled");
      },
      hashCode: function hashCode(str) {
        var hash = 0,
            i,
            chr;
        if (str.length === 0) return hash;

        for (i = 0; i < str.length; i++) {
          chr = str.charCodeAt(i);
          hash = (hash << 5) - hash + chr;
          hash |= 0; // Convert to 32bit integer
        }

        return hash;
      }
    };
  }
});