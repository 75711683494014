define("teelt-frontend/templates/components/utils/order-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CuCycUdY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"order\",\"type\"]],[23,[\"type\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"simple-arrow\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"order\",\"direction\"]],\"ASC\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"arrow_drop_up\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"         \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"arrow_drop_down\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"double-arrow\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"arrow_drop_up\"],[10],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"material-icons arrow-down\"],[9],[0,\"arrow_drop_down\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/order-button.hbs"
    }
  });

  _exports.default = _default;
});