define("teelt-frontend/routes/board/event/campaign-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var model = {
        saved: true
      };
      model.campaignId = params.campaign_id;
      model.campaign = this.store.findRecord('events/campaign', params.campaign_id, {
        reload: true
      });
      return Ember.RSVP.hash(model);
    }
  });

  _exports.default = _default;
});