define("teelt-frontend/utils/jquery-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = jqueryUtil;

  function jqueryUtil() {
    return {
      checkIfClickInElement: function checkIfClickInElement(event, idsToCheck) {
        var elem = event.target;
        var ids = [];

        if (elem.id) {
          ids.push(elem.id);
        }

        while (elem.parentNode && elem.parentNode.nodeName.toLowerCase() != 'body') {
          elem = elem.parentNode;

          if (elem.id) {
            ids.push(elem.id);
          }

          if (elem.classList) {
            ids = ids.concat(Array.from(elem.classList));
          }
        }

        if (ids.length == 0) return true;
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = idsToCheck[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var id = _step.value;

            if (ids.indexOf(id) >= 0) {
              return true;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return false;
      },
      isMonthArrowClicked: function isMonthArrowClicked(e) {
        var elt = e.target;
        if (!elt || !elt.classList) return false;
        if (elt.classList.contains("month-prev") || elt.classList.contains("month-next") || elt.classList.contains("datepicker-day-button")) return true;
        if (!elt.parentNode || !elt.parentNode.classList) return false;
        if (elt.parentNode.classList.contains("month-prev") || elt.parentNode.classList.contains("month-next") || elt.parentNode.classList.contains("datepicker-day-button")) return true;
        if (!elt.parentNode.parentNode || !elt.parentNode.parentNode.classList) return false;
        if (elt.parentNode.parentNode.classList.contains("month-prev") || elt.parentNode.parentNode.classList.contains("month-next") || elt.parentNode.parentNode.classList.contains("datepicker-day-button")) return true;
        return false;
      }
    };
  }
});