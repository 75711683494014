define("teelt-frontend/components/event/campaign-list", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    classNames: ['campaigns-list'],
    modelFilter: {},
    countUrl: _environment.default.APP.API_HOST + "/events/campaigns/count",
    keys: ['name', 'date', 'users', 'actions'],
    init: function init() {
      this._super.apply(this, arguments);

      this.eventBus.on('REFRESH_CAMPAIGNS', this, 'onCampaignRefresh');
      Ember.set(this, 'reloadTimestamp', new Date());
    },
    destroy: function destroy() {
      this.eventBus.off('REFRESH_CAMPAIGNS', this, 'onCampaignRefresh');

      this._super.apply(this, arguments);
    },
    onCampaignRefresh: function onCampaignRefresh() {
      Ember.set(this, 'reloadTimestamp', new Date());
    },
    actions: {
      onRowSelected: function onRowSelected(result) {
        this.router.transitionTo('board.event.campaign-edit', result.id);
      }
    }
  });

  _exports.default = _default;
});