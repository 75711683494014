define("teelt-frontend/templates/components/utils/viewer/image-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G0w84b31",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isThumbnail\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\"],[11,\"src\",\"\"],[11,\"class\",\"thumb-img\"],[12,\"id\",[28,[\"image-thumbnail-\",[21,\"uuid\"]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"id\",[28,[\"image-viewer-\",[21,\"uuid\"]]]],[11,\"class\",\"viewer-container \"],[9],[0,\"\\n\\n      \"],[7,\"div\"],[12,\"id\",[28,[\"image-container-\",[21,\"uuid\"]]]],[11,\"class\",\"viewer-image-container\"],[9],[0,\"\\n\\n      \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"zoom-controls\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"add-zoom clickable\"],[3,\"action\",[[22,0,[]],\"zoomIn\"]],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[11,\"style\",\"float:left!important\"],[9],[0,\"add\"],[10],[0,\"\\n    \"],[10],[7,\"div\"],[11,\"class\",\"remove-zoom clickable\"],[3,\"action\",[[22,0,[]],\"zoomOut\"]],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[11,\"style\",\"float:left!important\"],[9],[0,\"remove\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/viewer/image-viewer.hbs"
    }
  });

  _exports.default = _default;
});