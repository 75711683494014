define("teelt-frontend/templates/components/icon/analytics-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "us2p2ukv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"width\",\"43px\"],[11,\"height\",\"38px\"],[11,\"viewBox\",\"0 0 101.968 101.968\"],[11,\"version\",\"1.1\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[11,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[9],[0,\"\\n\"],[7,\"g\"],[9],[0,\"\\n\\t\"],[7,\"g\"],[11,\"fill\",\"#72A6D4\"],[9],[0,\"\\n\\t\\t\"],[7,\"path\"],[11,\"d\",\"M24.715,47.432L7.968,64.86v29.406c0,0.828,0.671,1.5,1.5,1.5h20.334c0.828,0,1.5-0.672,1.5-1.5V49.158l-4.69-1.726\\n\\t\\t\\tH24.715z\"],[9],[10],[0,\"\\n\\t\\t\"],[7,\"path\"],[11,\"d\",\"M66.135,61.1H45.801c-0.828,0-1.5,0.672-1.5,1.5v31.666c0,0.828,0.672,1.5,1.5,1.5h20.334c0.829,0,1.5-0.672,1.5-1.5V62.6\\n\\t\\t\\tC67.635,61.772,66.964,61.1,66.135,61.1z\"],[9],[10],[0,\"\\n\\t\\t\"],[7,\"path\"],[11,\"d\",\"M101.724,29.49c-0.777,0.406-1.652,0.621-2.53,0.621c-1.276,0-2.521-0.45-3.5-1.27l-3.694-3.088l-13.365,14.58v53.934\\n\\t\\t\\tc0,0.828,0.672,1.5,1.5,1.5h20.334c0.829,0,1.5-0.672,1.5-1.5v-64.93C101.885,29.387,101.81,29.445,101.724,29.49z\"],[9],[10],[0,\"\\n\\t\\t\"],[7,\"path\"],[11,\"d\",\"M57.797,54.094c1.144,0.419,2.424,0.108,3.248-0.788l30.839-33.643l7.217,6.032c0.353,0.294,0.847,0.349,1.254,0.136\\n\\t\\t\\tc0.407-0.214,0.646-0.648,0.605-1.107L99.396,7.235c-0.055-0.625-0.606-1.086-1.231-1.029l-17.49,1.563\\n\\t\\t\\tc-0.458,0.041-0.846,0.354-0.982,0.791C79.646,8.706,79.631,8.854,79.644,9c0.026,0.294,0.167,0.572,0.403,0.769l7.229,6.043\\n\\t\\t\\tL57.98,47.769L24.535,35.463c-1.118-0.41-2.373-0.121-3.198,0.735l-20.5,21.333c-1.148,1.195-1.11,3.095,0.084,4.242\\n\\t\\t\\tc0.583,0.561,1.332,0.837,2.079,0.837c0.788,0,1.574-0.309,2.164-0.921l19.141-19.92L57.797,54.094z\"],[9],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/icon/analytics-svg.hbs"
    }
  });

  _exports.default = _default;
});