define("teelt-frontend/templates/components/utils/flag-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iEgSgUoM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"select\"],[12,\"class\",[28,[\"flag-select-\",[21,\"uuid\"]]]],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/flag-selector.hbs"
    }
  });

  _exports.default = _default;
});