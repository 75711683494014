define("teelt-frontend/templates/board/email/email-rule-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HCcDrNI1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"email-detail\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"section-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"g-section-title\"],[9],[0,\"\\n            \"],[7,\"h1\"],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"word.emails\"],null]],null],false],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"g-window\"],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"href\",\"#0\"],[11,\"class\",\"g-window-close\"],[3,\"action\",[[22,0,[]],\"close\"]],[9],[7,\"i\"],[11,\"class\",\"material-icons grey-text\"],[9],[0,\"clear\"],[10],[10],[0,\"\\n        \"],[1,[27,\"emails/email-rule-detail\",null,[[\"rule\"],[[23,[\"model\",\"rule\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/email/email-rule-detail.hbs"
    }
  });

  _exports.default = _default;
});