define("teelt-frontend/components/content/existing-content-picker-popup", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax'),
    customErrorService: Ember.inject.service('custom-error'),
    multiSelect: true,
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.selectedContents) {
        Ember.set(this, 'selectedContents', []);
      } else {
        Ember.set(this, 'originalSelectedContents', this.selectedContents);
      }
    },
    didRender: function didRender() {
      this.showSelectAllContentsBtn();
      var el = document.querySelector('.select-all-contents-btn');

      if (el) {
        el.innerHTML = this.isAllContentsSelected() ? this.intl.t('content.unselect_all') : this.intl.t('content.select_all');
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.selectedContents) {
        this.selectedContents.forEach(function (content) {
          Ember.set(content, 'selected', undefined);
        });
      }

      this._super.apply(this, arguments);
    },
    isAllContentsSelected: function isAllContentsSelected() {
      var els = document.querySelectorAll('.selected-icon');
      var isAllSelected = true;
      els.forEach(function (el) {
        if (el.firstElementChild === null) {
          isAllSelected = false;
          return;
        }
      });
      return isAllSelected;
    },
    showSelectAllContentsBtn: function showSelectAllContentsBtn() {
      var showSelectAllContentsBtn = false;
      var el = document.querySelector('.contents-generic-ul.force');
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = el.children[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var child = _step.value;

          if (!child.classList.contains('disabled')) {
            showSelectAllContentsBtn = true;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      Ember.set(this, 'showSelectAllBtn', showSelectAllContentsBtn);
    },
    actions: {
      onSelectContent: function onSelectContent(content) {
        if (content.selected) {
          if (this.multiSelect) {
            this.selectedContents.addObject(content);
          } else {
            this.selectedContents.forEach(function (c) {
              Ember.set(c, 'selected', undefined);
            });
            Ember.set(this, 'selectedContents', [content]);
          }
        } else {
          this.selectedContents.removeObject(content);
        }
      },
      addContents: function addContents() {
        var _this = this;

        if (this.addAction) {
          this.addAction(this.selectedContents);
          Ember.set(this, 'showAddExistingContentPopup', false);
          return;
        }

        if (this.selectedContents.length === 0) {
          Ember.set(this, 'showAddExistingContentPopup', false);
          return;
        }

        var contents = [];
        this.selectedContents.forEach(function (content) {
          var contentJSObj = content.toJSON();
          contentJSObj.createCopy = true;
          contentJSObj.copySection = _this.section;
          contentJSObj.id = Number(content.id);
          contents.push(contentJSObj);
        });
        var path = _environment.default.APP.API_HOST + '/contents/multi';
        this.ajaxService.requestWithBodyPromise(path, 'POST', 'application/json', JSON.stringify(contents)).then(function () {
          _this.notify.info(_this.intl.t('content.info.generic_content_added'), {
            closeAfter: 5000
          });

          Ember.set(_this, 'showAddExistingContentPopup', false);
          Ember.set(_this, 'originalSelectedContents', _this.selectedContents);

          if (_this.onAdd) {
            _this.onAdd();
          }
        }).catch(function (err) {
          _this.customErrorService.handleAjaxError(err);
        });
      },
      close: function close() {
        Ember.set(this, 'selectedContents', this.originalSelectedContents);
        Ember.set(this, 'showAddExistingContentPopup', false);
      },
      selectAllContents: function selectAllContents() {
        var _this2 = this;

        if (!this.isAllContentsSelected()) {
          this.contents.forEach(function (c) {
            if (_this2.selectedCategories.includes(c.category.name)) {
              if (!c.selected) {
                Ember.set(c, 'selected', true);

                _this2.selectedContents.addObject(c);
              }
            }
          });
        } else {
          this.contents.forEach(function (c) {
            if (!c.disabled) {
              if (_this2.selectedCategories.includes(c.category.name)) {
                Ember.set(c, 'selected', undefined);

                _this2.selectedContents.removeObject(c);
              }
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});