define("teelt-frontend/components/settings/modal/new-role-modal", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    ajax: Ember.inject.service(),
    classNames: ['new-role-modal'],
    init: function init() {
      this._super.apply(this, arguments);

      var role = {
        roleType: 'ROLE_CONSULT',
        referent: false
      };
      Ember.set(this, 'edit', role);
    },
    verifyRole: function verifyRole() {
      var ok = true;
      var errors = {};

      if (!this.edit.label || this.edit.label.length == 0) {
        errors.label = true;
        ok = false;
      }

      if (!this.edit.acronym || this.edit.acronym.length == 0) {
        errors.acronym = true;
        ok = false;
      }

      Ember.set(this, 'errors', errors);
      return ok;
    },
    actions: {
      onClickOverlay: function onClickOverlay() {},
      toggleReferent: function toggleReferent() {
        Ember.set(this.edit, 'referent', !this.edit.referent);
      },
      save: function save() {
        var _this = this;

        if (!this.verifyRole()) return;
        var path = _environment.default.APP.API_HOST + "/roles";
        var method = 'POST';
        this.ajax.requestWithBodyPromise(path, method, 'application/json', JSON.stringify(this.edit)).then(function () {
          _this.eventBus.trigger('REFRESH_ROLES');

          Ember.set(_this, 'showAddRoleForm', false);
        });
      }
    }
  });

  _exports.default = _default;
});