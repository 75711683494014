define("teelt-frontend/components/ihm/explore-office/explore-office-sample-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onClickOverlay: function onClickOverlay() {
        Ember.set(this, "showExploreOfficeSampleModal", false);
      }
    }
  });

  _exports.default = _default;
});