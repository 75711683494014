define("teelt-frontend/components/event/mobile-calendar", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["mobile-calendar"],
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.storeEvents();
      var screenWidth = window.innerWidth;
      Ember.set(this, "screenWidth", screenWidth);
    },
    storeEvents: function storeEvents() {
      var events = [];

      if (this.oneToOneEvents) {
        events = events.concat(this.oneToOneEvents);
      }

      if (this.campaign && this.campaign.events) {
        events = events.concat(this.campaign.events);
      }

      if (this.googleEvents) {
        events = events.concat(this.googleEvents);
      }

      if (this.azureEvents) {
        events = events.concat(this.azureEvents);
      }

      Ember.set(this, "allEvents", events);
    },
    showEventsOnCalendar: function showEventsOnCalendar() {
      var locale = "en";

      if (this.intl.locale && this.intl.locale.length > 0) {
        locale = this.intl.locale[0].split("-")[0];
      }

      if (this.allEvents) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.allEvents[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var event = _step.value;

            var eventDate = _moment.default.utc(event.start);

            eventDate.locale(locale);

            var eventEndDate = _moment.default.utc(event.end);

            while (eventDate.isSame(eventEndDate, 'day') || eventDate.isBefore(eventEndDate)) {
              var eventDateFormat = eventDate.format('MMMM D, YYYY');
              this.$("[aria-label='" + eventDateFormat + "']").addClass('day-with-event');
              eventDate.add(1, 'day');
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      setTimeout(function () {
        var locale = "en";

        if (_this.intl.locale && _this.intl.locale.length > 0) {
          locale = _this.intl.locale[0].split("-")[0];
        }

        _this.$("#mobile-calendar").flatpickr({
          locale: locale,
          inline: true,
          disableMobile: "true",
          // Disable using mobile internal datepicker
          onChange: function onChange(selectedDates, dateStr, instance) {
            _this.showEventForDate(selectedDates[0]);

            _this.showEventsOnCalendar();
          },
          onMonthChange: function onMonthChange() {
            _this.showEventsOnCalendar();
          },
          onYearChange: function onYearChange() {
            _this.showEventsOnCalendar();
          }
        });

        _this.showEventsOnCalendar();

        _this.showEventForDate(_moment.default.utc());
      }, 0);
    },
    showEventForDate: function showEventForDate(date) {
      var dateSelected = _moment.default.utc((0, _moment.default)(date).format('YYYY-MM-DD'));

      if (this.allEvents) {
        // 1- We filter events for date selected
        var filterEvents = [];
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = this.allEvents[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var event = _step2.value;
            var start = event.allDay ? _moment.default.utc(event.start).startOf('day') : _moment.default.utc(event.start);
            var end = event.allDay ? _moment.default.utc(event.end).endOf('day') : _moment.default.utc(event.end);

            if (start.isSame(dateSelected, 'day')) {
              filterEvents.push(event);
            } else if (dateSelected.isAfter(start) && dateSelected.isBefore(end)) {
              filterEvents.push(event);
            }
          } // 2- We order events

        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        filterEvents.sort(function (e1, e2) {
          var start1 = e1.allDay ? _moment.default.utc(e1.start).startOf('day') : _moment.default.utc(e1.start);
          var start2 = e2.allDay ? _moment.default.utc(e2.start).startOf('day') : _moment.default.utc(e2.start);

          if (start1.isSame(start2)) {
            return 0;
          }

          if (start1.isBefore(start2)) {
            return -1;
          }

          return 1;
        }); // 3- We add increment

        var eventsForDay = [];
        var minHour = 23;
        var maxHour = 0;

        for (var _i = 0, _filterEvents = filterEvents; _i < _filterEvents.length; _i++) {
          var _event = _filterEvents[_i];
          _event.increment = 0;
          var startEvent = _event.allDay ? _moment.default.utc(_event.start).startOf('day') : _moment.default.utc(_event.start);

          if (startEvent.isBefore((0, _moment.default)(dateSelected).startOf('day'))) {
            startEvent = (0, _moment.default)(dateSelected).startOf('day');
          }

          _event.daystart = startEvent;
          var endEvent = _event.allDay ? _moment.default.utc(_event.end).endOf('day') : _moment.default.utc(_event.end);

          if (endEvent.isAfter((0, _moment.default)(dateSelected).endOf('day'))) {
            endEvent = (0, _moment.default)(dateSelected).endOf('day');
          }

          _event.dayend = endEvent;
          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = eventsForDay[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var dayEvent = _step3.value;
              var endDayEvent = dayEvent.dayend;

              if (startEvent.isBefore(endDayEvent)) {
                _event.increment++;
              }
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }

          eventsForDay.push(_event);
          minHour = Math.min(minHour, startEvent.get('hour') - 1);
          maxHour = Math.max(maxHour, endEvent.get('hour') + 1);
        }

        if (minHour < 0) {
          minHour = 0;
        }

        var timeline = [];

        for (var i = minHour; i <= maxHour; i++) {
          timeline.push(i);
        }

        Ember.set(this, "minHour", minHour);
        Ember.set(this, "maxHour", maxHour);
        Ember.set(this, "timeline", timeline);
        Ember.set(this, "eventsForDay", eventsForDay);
        this.addEventsToHTML();
      }
    },
    addEventsToHTML: function addEventsToHTML() {
      var _this2 = this;

      setTimeout(function () {
        // 40px == 60 min
        var pixelsForOneHour = 40;
        var incrementPixel = 35;

        for (var i = 0; i < _this2.eventsForDay.length; i++) {
          var event = _this2.eventsForDay[i];
          var top = (event.daystart.get('hour') - _this2.minHour) * pixelsForOneHour + event.daystart.get('minute') * pixelsForOneHour / 60;
          var left = event.increment * incrementPixel;
          var height = pixelsForOneHour * event.dayend.diff(event.daystart, 'minute') / 60;

          if (height > 24 * pixelsForOneHour) {
            height = 24 * pixelsForOneHour;
          }

          var width = _this2.screenWidth - 60 - left - 5 * event.increment;

          _this2.$("#event-" + i).css({
            top: top,
            left: left,
            height: height,
            width: width,
            maxWidth: width,
            position: 'absolute'
          });
        }
      }, 0);
    },
    actions: {
      showEvent: function showEvent(event) {
        Ember.set(this, "eventToShow", event);
        Ember.set(this, "showEventDetail", true);
      }
    },
    onGoogleEventsChanged: Ember.observer('googleEvents', function () {
      this.storeEvents();
      this.showEventsOnCalendar();
    }),
    onAzureEventsChanged: Ember.observer('azureEvents', function () {
      this.storeEvents();
      this.showEventsOnCalendar();
    })
  });

  _exports.default = _default;
});