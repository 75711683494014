define("teelt-frontend/routes/board/experience/experience-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    queryParams: {
      duplicateId: '',
      defaultTemplateId: '',
      defaultTemplateType: ''
    },
    model: function model(params) {
      if (params.duplicateId) {
        return Ember.RSVP.hash({
          templateToDuplicate: this.store.findRecord('template', params.duplicateId, {
            reload: true,
            include: 'full'
          })
        });
      }

      if (params.defaultTemplateId) {
        return {
          step: 3,
          defaultTemplateId: params.defaultTemplateId,
          template: this.store.createRecord('template', {
            type: params.defaultTemplateType,
            name: "",
            description: "",
            defaultTemplateId: params.defaultTemplateId
          })
        };
      }

      return {
        step: 1,
        template: this.store.createRecord('template', {
          type: 'welcome',
          name: "",
          description: ""
        })
      };
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      if (model.templateToDuplicate) {
        var templateJSON = model.templateToDuplicate.toJSON();
        templateJSON['id'] = undefined;
        var template = this.store.createRecord('template', templateJSON);
        template.save().then(function (template2) {
          setTimeout(function () {
            _this.transitionTo('board.experience.experience-edit', template2.id);
          });
        });
      }
    },
    specificActionByStep: function specificActionByStep() {
      var _this2 = this;

      if (this.currentModel.step < 1 && this.currentModel.defaultTemplateId) {
        this.transitionTo('board.experience.experience-models');
      } else if (this.currentModel.step < 1) {
        this.transitionTo('board.experiences');
      } else if (this.currentModel.step > 3) {
        this.currentModel.template.save().then(function (template) {
          setTimeout(function () {
            _this2.transitionTo('board.experience.experience-edit', template.id);
          });
        });
      }
    },
    actions: {
      next: function next() {
        if (this.currentModel.step == 3 && (!this.currentModel.template.name || this.currentModel.template.name.length == 0 || this.currentModel.template.description.length == 0)) {
          var errors = {};

          if (!this.currentModel.template.name || this.currentModel.template.name.length == 0) {
            errors.name = true;
          }

          if (!this.currentModel.template.description || this.currentModel.template.description.length == 0) {
            errors.description = true;
          }

          Ember.set(this.currentModel, 'errors', errors);
          return;
        }

        Ember.set(this.currentModel, 'step', this.currentModel.step + 1);
        this.specificActionByStep();
      },
      previous: function previous() {
        Ember.set(this.currentModel, 'step', this.currentModel.step - 1);

        if (this.currentModel.step <= 1) {
          Ember.set(this.currentModel.template, 'defaultTemplateId', undefined);
        }

        this.specificActionByStep();
      }
    }
  });

  _exports.default = _default;
});