define("teelt-frontend/services/custom-company-property", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajaxService: Ember.inject.service('ajax'),
    init: function init() {
      this._super.apply(this, arguments);
    },
    reload: function reload() {
      var _this = this;

      var path = _environment.default.APP.API_HOST + '/users/me/companyCustomProperties';
      this.ajaxService.requestPromise(path, 'GET').then(function (properties) {
        Ember.set(_this, 'properties', properties);
      });
    }
  });

  _exports.default = _default;
});