define("teelt-frontend/components/talents/modal/talents-export-modal", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.loadParameters();
    },
    loadParameters: function loadParameters() {
      var _this = this;

      var otpURL = _environment.default.APP.API_HOST + "/users/otp";
      Ember.set(this, 'downloadUrl', undefined);
      this.ajax.requestPromise(otpURL).then(function (otp) {
        var downloadUrl = _environment.default.APP.API_HOST + "/users/export/csv?";
        var params = ["otp=" + encodeURIComponent(otp)];

        if (!_this.exportFull) {
          var columns = [];
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = _this.customKeys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var customKey = _step.value;

              if (customKey.selected) {
                columns.push(customKey.key);
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          params.push("columns=" + encodeURIComponent(columns.join(",")));
        }

        if (_this.exportQueryParams.ids) {
          params.push("ids=" + encodeURIComponent(_this.exportQueryParams.ids.join(",")));
        }

        if (_this.exportQueryParams.query) {
          params.push("query=" + encodeURIComponent(_this.exportQueryParams.query));
        }

        if (_this.exportQueryParams.complexFilters) {
          params.push("complexFilters=" + encodeURIComponent(_this.exportQueryParams.complexFilters));
        }

        if (_this.exportQueryParams.selectAll) {
          params.push("selectAll=true");
        }

        if (_this.exportQueryParams.dateFilter) {
          params.push("dateFilter=" + encodeURIComponent(_this.exportQueryParams.dateFilter));
        }

        if (_this.exportQueryParams.startDate) {
          params.push("startDate=" + encodeURIComponent(_this.exportQueryParams.startDate));
        }

        if (_this.exportQueryParams.endDate) {
          params.push("endDate=" + encodeURIComponent(_this.exportQueryParams.endDate));
        }

        if (_this.exportQueryParams.targetDateField) {
          params.push("targetDateField=" + encodeURIComponent(_this.exportQueryParams.targetDateField));
        }

        if (_this.exportQueryParams.filterType) {
          params.push("filterType=" + encodeURIComponent(_this.exportQueryParams.filterType));
        }

        if (_this.exportFull) {
          params.push("withCustomField=true");
        }

        downloadUrl += params.join("&");
        Ember.set(_this, 'downloadUrl', downloadUrl);
      });
    },
    actions: {
      onClickOverlay: function onClickOverlay() {},
      selectChoice: function selectChoice(full) {
        Ember.set(this, 'exportFull', full);
        this.loadParameters();
      }
    }
  });

  _exports.default = _default;
});