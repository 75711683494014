define("teelt-frontend/templates/components/talents/talent-information-block-message-modify-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u/xxYc6m",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"popup-background\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"talent-information-block-modify-modal g-window\"],[9],[0,\"\\n   \"],[7,\"a\"],[11,\"href\",\"#0\"],[11,\"class\",\"g-window-close\"],[3,\"action\",[[22,0,[]],\"onClose\"]],[9],[7,\"i\"],[11,\"class\",\"material-icons foreground-primary-color\"],[9],[0,\"clear\"],[10],[10],[0,\"\\n\\n   \"],[7,\"div\"],[11,\"class\",\"modal-title foreground-primary-color\"],[9],[1,[27,\"t\",[\"homepage.modify_manager_message\"],null],false],[10],[0,\"\\n   \"],[7,\"div\"],[11,\"class\",\"modal-baseline\"],[9],[1,[27,\"t\",[\"homepage.manager_message_modal.max_size\"],null],false],[10],[0,\"\\n\\n   \"],[7,\"div\"],[11,\"class\",\"input-field\"],[9],[0,\"\\n     \"],[1,[27,\"textarea\",null,[[\"id\",\"value\",\"maxlength\"],[\"managermessage-text\",[23,[\"editableHomepage\",\"managerMessage\"]],\"65000\"]]],false],[0,\"\\n   \"],[10],[0,\"\\n\\n   \"],[7,\"div\"],[11,\"class\",\"controls right\"],[9],[0,\"\\n     \"],[7,\"a\"],[11,\"class\",\"btn hoverable-color-verylight background-primary-color\"],[3,\"action\",[[22,0,[]],\"save\"]],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"word.save\"],null]],null],false],[10],[0,\"\\n   \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-information-block-message-modify-modal.hbs"
    }
  });

  _exports.default = _default;
});