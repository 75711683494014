define("teelt-frontend/templates/board/audience/audience-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NaINIJtZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"audience-edit\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"section-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n      \"],[1,[27,\"audience/audience-new\",null,[[\"audience\"],[[23,[\"model\"]]]]],false],[0,\"\\n\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/audience/audience-edit.hbs"
    }
  });

  _exports.default = _default;
});