define("teelt-frontend/templates/talent/integration/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kznw2tot",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"talents/talent-events\",null,[[\"talent\"],[[23,[\"model\",\"talent\"]]]]],false],[0,\"\\n \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/talent/integration/events.hbs"
    }
  });

  _exports.default = _default;
});