define("teelt-frontend/routes/board/notification/send-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        talentId: params.talent_id,
        talent: this.store.findRecord("user", params.talent_id)
      });
    },
    actions: {
      close: function close() {
        this.transitionTo('board.talent.talent-detail', this.currentModel.talentId);
      }
    }
  });

  _exports.default = _default;
});