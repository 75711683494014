define("teelt-frontend/components/analytics/type/ihm-analytic", ["exports", "teelt-frontend/config/environment", "teelt-frontend/utils/jquery-utils"], function (_exports, _environment, _jqueryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-analytic-detail'],
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    userService: Ember.inject.service('user-service'),
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    notify: Ember.inject.service(),
    showRolesDropdown: false,
    query: '',
    prefixTranslateKey: "talent.table.keys.",
    params: {
      dateFilter: 'all',
      startDate: null,
      endDate: null,
      targetDateField: 'createdAt'
    },
    responseNb: 0,
    complexFilters: [],
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.loadForms();
      setTimeout(function () {
        _this.eventBus.on('CLICK_EVENT', _this, 'checkToCloseModal');
      }, 300);
    },
    destroy: function destroy() {
      this.eventBus.off('CLICK_EVENT', this, 'checkToCloseModal');

      this._super.apply(this, arguments);
    },
    checkToCloseModal: function checkToCloseModal(e) {
      if (this.isDestroyed) return;

      if (!(0, _jqueryUtils.default)().checkIfClickInElement(e, ["show-roles-btn", "roles"])) {
        Ember.set(this, 'showRolesDropdown', false);
      }
    },
    loadForms: function loadForms(data) {
      var _this2 = this;

      Ember.set(this, 'showChart', false);

      if (data) {
        Ember.set(this, 'params', {
          dateFilter: 'all',
          startDate: null,
          endDate: null,
          targetDateField: 'createdAt'
        });
        this.params.dateFilter = data.dateFilter;
        this.params.startDate = data.startDate;
        this.params.endDate = data.endDate;
        this.params.targetDateField = data.targetDateField;
      }

      this.params.complexFilters = JSON.stringify(this.complexFilters);
      this.params.include = 'full';
      Ember.set(this, 'isLoading', true);
      this.ajax.requestWithUrlParamPromise(_environment.default.APP.API_HOST + '/form-reports/' + this.formReportId, 'GET', this.params).then(function (formReport) {
        _this2.updateCustomFilters(JSON.parse(formReport.complexFilters));

        Ember.set(_this2, 'responseCount', formReport.responseCount);
        Ember.set(_this2, 'completionRate', formReport.completionRate);
        Ember.set(_this2, 'countEmailSent', formReport.connexionRate);
        Ember.set(_this2, 'countMeetPeopleImage', formReport.countMeetPeopleImage);
        Ember.set(_this2, 'countMeetPeoplePlayer', formReport.countMeetPeoplePlayer);
        Ember.set(_this2, 'countEmailSent', formReport.countEmailSent);
        Ember.set(_this2, 'formReport', formReport);
        Ember.set(_this2, 'isLoading', false);
      }).catch(function (error) {
        Ember.set(_this2, 'isLoading', false);

        _this2.notify.error(_this2.intl.t('analytics.loading_error'), {
          closeAfter: 5000
        });
      });
    },
    updateCustomFilters: function updateCustomFilters(data) {
      if (data) {
        var complexFilters = this.complexFilters;

        var _loop = function _loop(i) {
          if (!complexFilters.some(function (item) {
            return JSON.stringify(item) === JSON.stringify(data[i]);
          })) {
            complexFilters.push(data[i]);
          }
        };

        for (var i = 0; i < data.length; i++) {
          _loop(i);
        }

        Ember.set(this, 'complexFilters', []);
        Ember.set(this, 'complexFilters', complexFilters);
      }
    },
    printPdfReport: function printPdfReport() {
      var elem = document.getElementById("print-form-report"); // hide div with have class "no-print"

      var noPrint = document.getElementsByClassName("no-print");

      for (var i = 0; i < noPrint.length; i++) {
        noPrint[i].style.display = "none";
      }

      var s4elements = elem.querySelectorAll('.s4.print');
      s4elements.forEach(function (col) {
        col.classList.remove('s4');
        col.classList.add('s6');
      });
      elem.querySelectorAll('.response-element').forEach(function (responseContainer) {
        responseContainer.style = 'font-weight: lighter; font-size: 2.5em;';
      });
      var footer = this.intl.t('analytics.report.pdf.footer');
      var opt = {
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        html2canvas: {
          width: '90%',
          scrollX: 0,
          scrollY: 0,
          windowWidth: '100%',
          scale: 2
        },
        jsPDF: {
          orientation: 'portrait'
        },
        margin: [10, 10, 10, 10],
        pagebreak: {
          mode: 'avoid-all'
        },
        filename: this.formReport.title + '.pdf'
      };
      html2pdf().set(opt).from(elem).toPdf().get('pdf').then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();
        var w = 20;
        var h = 20;

        for (var _i = 1; _i <= totalPages; _i++) {
          var x = pdf.internal.pageSize.getWidth() - w;
          var y = pdf.internal.pageSize.getHeight() - h;
          pdf.setPage(_i);
          pdf.setFontSize(8);
          pdf.setTextColor(150);
          pdf.text(footer, pdf.internal.pageSize.getWidth() - w - 40, pdf.internal.pageSize.getHeight() - h / 2);
          pdf.setFillColor(255, 24, 0, 0);
          pdf.rect(x, y, w, h, "F");
          var img = new Image();
          img.src = '/logo/teelt-logo.png';
          pdf.addImage(img, 'png', x, y, w, h);
        }
      }).save(); // show div with have class "no-print"

      setTimeout(function () {
        for (var _i2 = 0; _i2 < noPrint.length; _i2++) {
          noPrint[_i2].style.display = "block";
        }

        ;
        s4elements.forEach(function (col) {
          col.classList.remove('s6');
          col.classList.add('s4');
        });
        elem.querySelectorAll('.response-element').forEach(function (responseContainer) {
          responseContainer.style = '';
        });
      }, 100);
    },
    actions: {
      onFilterChanged: function onFilterChanged(data) {
        var complexFilters = this.complexFilters;
        complexFilters.push(data);
        Ember.set(this, 'complexFilters', []);
        Ember.set(this, 'complexFilters', complexFilters);
        Ember.set(this, 'results', undefined);
        this.loadForms();
      },
      removeComplex: function removeComplex(index) {
        var _this3 = this;

        var complexFilters = this.complexFilters;
        complexFilters.splice(index, 1);
        Ember.set(this, 'complexFilters', null);
        Ember.set(this, 'complexFilters', complexFilters);
        Ember.set(this, 'results', undefined);
        this.send('saveFilters');
        setTimeout(function () {
          _this3.loadForms();
        }, 400);
      },
      loadData: function loadData(data) {
        this.loadForms(data);
      },
      saveFilters: function saveFilters() {
        var _this4 = this;

        var complexFilters = this.complexFilters;
        if (this.complexFilters.length == 0) complexFilters = null;
        this.store.findRecord('form-report', this.formReportId, {
          include: 'light'
        }).then(function (formReport) {
          Ember.set(formReport, 'complexFilters', JSON.stringify(complexFilters));
          formReport.save();

          _this4.notify.info(_this4.intl.t('analytics.filter_saved'), {
            closeAfter: 3000
          });
        });
      },
      interceptEnter: function interceptEnter(e) {
        if (e.keyCode == 13) {
          this.send('handleFocusOut', e);
          return false;
        }

        return true;
      },
      handleFocusOut: function handleFocusOut(e) {
        var value = e.target.outerText;
        $('#' + e.target.id).text(value);
        this.send('patchFormReport', value);
        e.preventDefault();
        e.target.blur();
      },
      patchFormReport: function patchFormReport(value) {
        var _this5 = this;

        this.store.findRecord('form-report', this.formReport.id, {
          include: 'light'
        }).then(function (formReport) {
          Ember.set(formReport, 'title', value);
          Ember.set(_this5.formReport, 'title', value);
          formReport.save();
        });
      },
      print: function print() {
        this.printPdfReport();
      }
    }
  });

  _exports.default = _default;
});