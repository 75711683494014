define("teelt-frontend/services/translation", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    allTranslations: [],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var interval = setInterval(function () {
        Ember.set(_this, 'allTranslations', []);
      }, 15 * 60 * 1000);
      Ember.set(this, 'interval', interval);
    },
    destroy: function destroy() {
      if (this.interval) {
        clearInterval(this.interval);
      }

      this._super.apply(this, arguments);
    },
    getTranslations: function getTranslations(code) {
      return this.allTranslations[code];
    },
    loadTranslations: function loadTranslations(code) {
      var _this2 = this;

      var path = _environment.default.APP.API_HOST + "/translations/" + code;
      return this.ajax.requestPromise(path).then(function (translations) {
        Ember.set(_this2.allTranslations, code, translations);
      });
    },
    removeTranslationsInStorage: function removeTranslationsInStorage(code) {
      delete this.allTranslations[code];
    }
  });

  _exports.default = _default;
});