define("teelt-frontend/components/settings/periods-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    classNames: ['periods-list'],
    modelFilter: {},
    keys: ['name', 'triggerDate', 'createdAt', 'start', 'end', 'actions'],
    init: function init() {
      this._super.apply(this, arguments);

      this.eventBus.on('REFRESH_PERIODS', this, 'refresh');
      Ember.set(this.modelFilter, 'triggerDate', this.triggerDate);
    },
    destroy: function destroy() {
      this.eventBus.off('REFRESH_PERIODS', this, 'refresh');

      this._super.apply(this, arguments);
    },
    refresh: function refresh() {
      Ember.set(this, 'reloadTimestamp', new Date());
    },
    actions: {
      onRowSelected: function onRowSelected(result) {
        this.router.transitionTo('board.setting.period-edit', result.id);
      }
    }
  });

  _exports.default = _default;
});