define("teelt-frontend/components/input/select-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['select-button'],
    didInsertElement: function didInsertElement() {
      var self = this;
      this.$('.select-button-container').on('blur', function () {
        Ember.set(self, 'showDropdown', false);
      });
    },
    actions: {
      open: function open() {
        Ember.set(this, 'showDropdown', true);
      },
      selectItem: function selectItem(item) {
        if (this.onSelect) {
          this.onSelect(item);
        }
      }
    }
  });

  _exports.default = _default;
});