define("teelt-frontend/components/task/new-task/new-task-resume", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    moduleType: Ember.computed('task', 'task.form', function () {
      if (this.task.form) {
        return 'form';
      }
    }),
    moduleName: Ember.computed('task.form.title', function () {
      if (this.task.form) {
        return this.task.form.title;
      }

      if (this.task.content) {
        return this.task.content.title;
      }

      if (this.task.signContent) {
        return this.task.signContent.title;
      }

      if (this.task.meetPeople) {
        return this.task.meetPeople.title;
      }

      if (this.task.exploreOffice) {
        return this.task.exploreOffice.title;
      }
    }),
    moduleDesc: Ember.computed('task.form.description', function () {
      if (this.task.form) {
        return this.task.form.description;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      editOptions: function editOptions(value) {
        this.onFinish(value);
      }
    }
  });

  _exports.default = _default;
});