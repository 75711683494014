define("teelt-frontend/mixins/reset-scroll-position", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('html,body').scrollTop(0);
    }
  });

  _exports.default = _default;
});