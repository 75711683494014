define("teelt-frontend/components/talents/modal/talents-update-manager-modal", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userService: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.initSelectManager();
    },
    initSelectManager: function initSelectManager() {
      var _this = this;

      this.userService.getUsersForSelect('ROLE_MANAGER').then(function (managers) {
        Ember.set(_this, "selectValues", managers);
      });
    },
    actions: {
      onClickOverlay: function onClickOverlay() {},
      onSelectChanged: function onSelectChanged(manager) {
        Ember.set(this, 'selectedManager', manager);
      },
      save: function save() {
        if (!this.selectedManager) return;
        this.updateTalentsManager(this.selectedManager);
        Ember.set(this, 'show', false);
      }
    }
  });

  _exports.default = _default;
});