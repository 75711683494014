define("teelt-frontend/templates/components/form/field/field-choice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vHeEoHkA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\"],[11,\"class\",\"material-icons\"],[3,\"action\",[[22,0,[]],\"delete\"]],[9],[0,\"close\"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"formType\"]],\"QUIZZ\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"i\"],[12,\"class\",[28,[\"material-icons check \",[27,\"if\",[[23,[\"choice\",\"isValid\"]],\"valid\"],null]]]],[3,\"action\",[[22,0,[]],\"switchValid\"]],[9],[0,\"check\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"input-field\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\",\"autofocus\",\"class\",\"maxlength\",\"autocomplete\"],[[23,[\"choice\",\"value\"]],\"true\",[27,\"if\",[[23,[\"choice\",\"inputErrors\",\"labelIsNull\"]],\"invalid\",[27,\"if\",[[23,[\"choice\",\"isValid\"]],\"valid\"],null]],null],\"255\",\"off\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"quantifiable\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"input-field quantity\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"value\",\"placeholder\",\"maxLength\",\"autocomplete\"],[[23,[\"choice\",\"quantity\"]],[27,\"t\",[\"form.field.multiple_choice_quantity\"],null],\"3\",\"off\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/form/field/field-choice.hbs"
    }
  });

  _exports.default = _default;
});