define("teelt-frontend/components/notification/notification-template-assign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var selectedTemplateIds = [];

      if (this.notification.templates) {
        this.notification.templates.forEach(function (template) {
          selectedTemplateIds.push(template.id);
        });
      }

      Ember.set(this.notification, 'selectedTemplateIds', selectedTemplateIds);
    },
    actions: {
      save: function save() {
        var _this = this;

        this.notification.save().then(function () {
          _this.router.transitionTo('board.notification.notification-detail', _this.notification.id);
        });
      }
    }
  });

  _exports.default = _default;
});