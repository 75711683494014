define("teelt-frontend/templates/components/session/talent-detail-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7U5uGHfp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"calendar-header\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"clickable\"],[3,\"action\",[[22,0,[]],\"addEvent\"]],[9],[1,[27,\"t\",[\"event.add_event\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col s12 calendar-container\"],[9],[0,\"\\n    \"],[1,[27,\"event/event-calendar\",null,[[\"campaign\",\"oneToOneEvents\",\"displayMode\",\"welcomeDate\",\"defaultDate\",\"onNewEvent\",\"onDrop\",\"onResize\",\"onUpdateEvent\",\"onRemoveEvent\",\"addParticipants\",\"googleEvents\",\"azureEvents\"],[[23,[\"talent\",\"campaign\"]],[23,[\"talent\",\"events\"]],\"ONE_TO_ONE_EDIT\",[23,[\"talent\",\"welcomeDate\"]],[27,\"max-date\",[[23,[\"talent\",\"welcomeDate\"]],[27,\"now\",null,null]],null],[27,\"action\",[[22,0,[]],\"onNewEvent\"],null],[27,\"action\",[[22,0,[]],\"onEventDrop\"],null],[27,\"action\",[[22,0,[]],\"onEventResize\"],null],[27,\"action\",[[22,0,[]],\"onUpdateEvent\"],null],[27,\"action\",[[22,0,[]],\"onRemoveEvent\"],null],true,[23,[\"googleEvents\"]],[23,[\"azureEvents\"]]]]],false],[0,\"\\n      \\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/session/talent-detail-calendar.hbs"
    }
  });

  _exports.default = _default;
});