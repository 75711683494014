define("teelt-frontend/app", ["exports", "teelt-frontend/resolver", "ember-load-initializers", "teelt-frontend/config/environment", "quill", "teelt-frontend/mixins/reset-scroll-position", "teelt-frontend/mixins/google-tag-manager", "teelt-frontend/mixins/domain-redirect"], function (_exports, _resolver, _emberLoadInitializers, _environment, _quill, _resetScrollPosition, _googleTagManager, _domainRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  Ember.Route.reopen(_resetScrollPosition.default);
  Ember.Route.reopen(_googleTagManager.default);
  Ember.Route.reopen(_domainRedirect.default);
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  window.Quill = _quill.default;
  var _default = App;
  _exports.default = _default;
});