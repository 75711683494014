define("teelt-frontend/components/rgpd/rgpd-modal", ["exports", "jquery", "moment", "teelt-frontend/config/environment"], function (_exports, _jquery, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['rgpd-modal'],
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.set(this, 'locales', this.intl.locales);
      var path = _environment.default.APP.API_HOST + '/companies/rgpds';
      this.ajax.requestPromise(path, 'GET').then(function (rgpds) {
        var allRGPDS = {};

        if (rgpds && rgpds.length) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = rgpds[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var rgpd = _step.value;
              allRGPDS[rgpd.lang] = rgpd;
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        }

        Ember.set(_this, 'allRGPDS', allRGPDS);

        _this.send('onLangChanged', _this.intl.locale[0]);
      });
    },
    actions: {
      onLangChanged: function onLangChanged(lang) {
        var rgpd = this.allRGPDS[lang];

        if (rgpd) {
          Ember.set(this, 'rgpd', rgpd);
        } else {
          rgpd = {
            lang: lang
          };
          var allRGPDS = this.allRGPDS;
          allRGPDS[lang] = rgpd;
          Ember.set(this, 'allRGPDS', allRGPDS);
          Ember.set(this, 'rgpd', rgpd);
        }
      },
      save: function save() {
        var _this2 = this;

        var rgpds = [];

        for (var lang in this.allRGPDS) {
          rgpds.push(this.allRGPDS[lang]);
        }

        var path = _environment.default.APP.API_HOST + '/companies/rgpds';
        this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(rgpds)).then(function () {
          Ember.set(_this2, 'show', false);
        });
      }
    }
  });

  _exports.default = _default;
});