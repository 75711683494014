define("teelt-frontend/components/talents/talent-permissions", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['users-management'],
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    session: Ember.inject.service(),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    selectedRole: 'all',
    modelName: 'user',
    perimeterPath: _environment.default.APP.API_HOST + "/perimeters",
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'addRequest', {
        roles: this.talent.roles,
        emails: [this.talent.username]
      });
      this.eventBus.on('ROLELIST_UPDATED', this, 'onRolesRefresh');
      var allRoles = ["ROLE_RH"];

      if (this.session.data.authenticated.user.company.activeOMRole && !this.session.data.authenticated.user.company.essential) {
        allRoles.push("ROLE_CUSTOM_OFFICE_MANAGER");
      }

      allRoles.push("ROLE_MANAGER");

      if (this.session.data.authenticated.user.company.activeAdministrative && !this.session.data.authenticated.user.company.essential) {
        allRoles.push("ROLE_ADMINISTRATIVE");
      }

      if (this.session.data.authenticated.user.company.activeMRH && !this.session.data.authenticated.user.company.essential) {
        allRoles.push("ROLE_MRH");
      }

      if (!this.session.data.authenticated.user.company.essential) {
        var roles = this.rolecustomService.getConsultRoles();

        if (roles) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = roles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var role = _step.value;
              allRoles.push("ROLE_CONSULT-" + role.id);
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        }
      }

      Ember.set(this, 'allRoles', allRoles);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      setTimeout(function () {
        _this.$('.select').formSelect();
      }, 0);
    },
    destroy: function destroy() {
      this.eventBus.off('ROLELIST_UPDATED', this, 'onRolesRefresh');

      this._super.apply(this, arguments);
    },
    onRolesRefresh: function onRolesRefresh() {
      var _this2 = this;

      var allRoles = this.allRoles;
      Ember.set(this, 'allRoles', undefined);
      setTimeout(function () {
        Ember.set(_this2, 'allRoles', allRoles);
        setTimeout(function () {
          _this2.$('.select').formSelect();
        });
      });
    },
    actions: {
      onClickOverlay: function onClickOverlay() {},
      onRoleChanged: function onRoleChanged(role) {
        Ember.set(this, 'selectedRole', role);
      },
      openAddModal: function openAddModal() {
        Ember.set(this, 'updateUser', false);
        Ember.set(this, 'addRequest', {
          roles: [],
          emails: [''],
          perimeter: undefined
        });
        Ember.set(this, 'showAddModal', true);
      },
      addRemoveRoleToAddRequest: function addRemoveRoleToAddRequest(role) {
        var _this3 = this;

        var roles = this.addRequest.roles;
        var index = roles.indexOf(role);

        if (index >= 0) {
          roles.splice(index, 1);
        } else {
          roles.push(role);
        }

        Ember.set(this.addRequest, "roles", undefined);
        setTimeout(function () {
          Ember.set(_this3.addRequest, "roles", roles);
        });
      },
      add: function add() {
        Ember.set(this, 'mustSelectPerimeter', false);

        if (this.addRequest.roles.includes("ROLE_MRH") && (this.talent.perimeter == null || this.talent.perimeter.id == 0)) {
          Ember.set(this, 'mustSelectPerimeter', true);
          this.notify.error(this.intl.t('settings.perimeter.no_perimeter_selected_for_mrh'), {
            closeAfter: 3000
          });
          return;
        }

        Ember.set(this.talent, 'roles', this.addRequest.roles);

        if (this.onSave) {
          this.onSave();
        }
      },
      onPerimeterChanged: function onPerimeterChanged(perimeter) {
        Ember.set(this.talent, 'perimeter', {
          id: perimeter.key
        });
        Ember.set(this, 'mustSelectPerimeter', false);

        if (this.addRequest.roles.includes("ROLE_MRH") && (this.talent.perimeter == null || this.talent.perimeter.id == 0)) {
          Ember.set(this, 'mustSelectPerimeter', true);
        }
      }
    }
  });

  _exports.default = _default;
});