define("teelt-frontend/templates/board/admin/tool", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hFfXrNUs",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"tool/tools-list\",null,[[\"generic\",\"canAdministrate\"],[true,true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/admin/tool.hbs"
    }
  });

  _exports.default = _default;
});