define("teelt-frontend/templates/components/talents/talent-information-block-progression", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KAygedSt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"progress-block company-background\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"info-block-title company-text-color \"],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"bookmark\"],[10],[1,[27,\"t\",[\"word.progress\"],null],false],[10],[0,\"\\n  \"],[1,[27,\"utils/circle-number\",null,[[\"value\",\"percent\",\"colorClass\",\"width\",\"inner\",\"strokeWidth\"],[[27,\"concat\",[[23,[\"talent\",\"talentProgression\"]],\"%\"],null],[23,[\"talent\",\"talentProgression\"]],\"white-b\",120,true,8]]],false],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/talents/talent-information-block-progression.hbs"
    }
  });

  _exports.default = _default;
});