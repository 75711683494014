define("teelt-frontend/components/event/campaign-assign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var selectedTalentIds = [];

      if (this.campaign.users) {
        this.campaign.users.forEach(function (user) {
          selectedTalentIds.push(user.id);
        });
      }

      Ember.set(this.campaign, 'selectedTalentIds', selectedTalentIds);
    },
    actions: {
      onMultySelectionChanged: function onMultySelectionChanged(params) {
        Ember.set(this.campaign, 'selectedTalentIds', params.selecteds);
      },
      save: function save() {
        var _this = this;

        this.campaign.save().then(function () {
          _this.router.transitionTo('board.event.campaign-detail', _this.campaign.id);
        });
      }
    }
  });

  _exports.default = _default;
});