define("teelt-frontend/utils/string-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = stringUtil;

  function stringUtil() {
    return {
      generateUUID: function generateUUID() {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxxxxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = (dt + Math.random() * 16) % 16 | 0;
          dt = Math.floor(dt / 16);
          return (c == 'x' ? r : r & 0x3 | 0x8).toString(16);
        });
        return uuid;
      },
      validateEmail: function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },
      validateSocialNumber: function validateSocialNumber(socialNumber) {
        var calculSocialNumber = socialNumber.split(" ").join("");
        calculSocialNumber = calculSocialNumber.replace("A", "1");
        calculSocialNumber = calculSocialNumber.replace("a", "1");
        calculSocialNumber = calculSocialNumber.replace("B", "2");
        calculSocialNumber = calculSocialNumber.replace("b", "2");
        if (!calculSocialNumber.match(/^[0-9\ ]+$/)) return false;
        var sum = parseInt(calculSocialNumber.substring(0, calculSocialNumber.length - 2));
        var controlKey = 97 - sum % 97;
        if (calculSocialNumber.substring(calculSocialNumber.length - 2) == controlKey) return true;
        return false;
      },
      capitalize: function capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
    };
  }
});