define("teelt-frontend/components/ihm/meet-people/meet-people-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['meet-people-block'],
    userService: Ember.inject.service(),
    blockByPeriod: Ember.computed('talentCurrentPeriod', 'meetPeople', function () {
      if (this.meetPeople.hasBeenLaunched) return false;

      if (this.talentCurrentPeriod != undefined && this.meetPeople.periodIndex != undefined) {
        return this.talentCurrentPeriod < this.meetPeople.periodIndex;
      }

      return false;
    }),
    blockByStart: Ember.computed('meetPeople', 'talent', function () {
      if (this.talent) {
        if (this.meetPeople.hasBeenLaunched) return false;

        if (this.meetPeople.startOffsetUnit && this.meetPeople.startOffsetDirection) {
          var offset = this.meetPeople.startOffset;

          if (this.meetPeople.startOffsetDirection.toLowerCase() == 'before') {
            offset = -1 * offset;
          }

          var triggerDate = this.periods[this.talentCurrentPeriod].triggerDate;

          if (triggerDate == 'assignationDate') {
            triggerDate = 'templateAssignationDate';
          }

          var date = moment(this.talent[triggerDate]);

          switch (this.meetPeople.startOffsetUnit.toLowerCase()) {
            case 'hour':
              date.add(offset, 'hours');
              break;

            case 'day':
              date.add(offset, 'days');
              break;

            case 'month':
              date.add(offset, 'months');
              break;
          }

          if (this.meetPeople.startOffsetUnit.toLowerCase() == 'day' || this.meetPeople.startOffsetUnit.toLowerCase() == 'month') {
            var now = moment().startOf('day');
            var dateToCompare = moment(date).startOf('day');

            if (now.isBefore(dateToCompare)) {
              return true;
            }
          } else {
            var _now = moment();

            if (_now.isBefore(date)) {
              return true;
            }
          }

          return false;
        }
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.talent) {
        var periods = this.userService.getTalentPeriods(this.talent);
        Ember.set(this, 'periods', periods);
        Ember.set(this, 'talentCurrentPeriod', this.userService.getTalentCurrentPeriod(this.talent, periods));
      }
    },
    actions: {
      lauchGame: function lauchGame() {
        if (this.blockByPeriod) return;
        if (this.blockByStart) return;
        if (this.onLauchGame) this.onLauchGame(this.meetPeople);
      },
      showMyContact: function showMyContact() {
        if (this.onShowMyContact) this.onShowMyContact(this.meetPeople);
      },
      addImageToGallery: function addImageToGallery() {
        if (this.onAddImageToGallery) this.onAddImageToGallery(this.meetPeople);
      }
    }
  });

  _exports.default = _default;
});