define("teelt-frontend/templates/components/menu/slider-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QYmQNgVR",
    "block": "{\"symbols\":[\"menuItem\",\"index\"],\"statements\":[[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"menu\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[12,\"class\",[28,[\"slider-item slider-item-\",[22,2,[]],\" \",[27,\"if\",[[22,1,[\"id\"]],[27,\"concat\",[\"slider-id-\",[22,1,[\"id\"]]],null]],null],\" \",[27,\"if\",[[27,\"eq\",[[23,[\"selected\"]],[22,2,[]]],null],\"active\"],null]]]],[3,\"action\",[[22,0,[]],\"switchTo\",[22,2,[]]]],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"menut-type\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n\"],[4,\"if\",[[22,1,[\"icon\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\"],[11,\"class\",\"material-icons right\"],[9],[1,[22,1,[\"icon\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/menu/slider-menu.hbs"
    }
  });

  _exports.default = _default;
});