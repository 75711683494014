define("teelt-frontend/components/task/my-tasks-list", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['my-tasks-list'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    userService: Ember.inject.service(),
    defaultProfileImageUrl: _environment.default.APP.DEFAULT_PROFILE_IMAGE_URL,
    limit: 3,
    offset: 0,
    canLoadMore: false,
    storeUsers: [],
    modelName: 'user',
    filter: 'all',
    params: {},
    complexeFilters: [],
    modelFilter: {
      includes: 'position'
    },
    customKeys: [{
      key: 'photo',
      mandatory: true,
      selected: true
    }, {
      key: 'firstname',
      selected: true
    }, {
      key: 'lastname',
      selected: true
    }, {
      key: 'position',
      selected: true
    }, {
      key: 'welcomeDate',
      selected: true
    }, {
      key: 'talent_progress',
      selected: true
    }, {
      key: 'user_tasks',
      selected: true
    }],
    sortableKeys: ['firstname', 'lastname', 'position', 'welcomeDate'],
    countAllTasksForAllUsers: Ember.computed('tasks', function () {
      var count = 0;

      if (this.tasks) {
        this.tasks.forEach(function (task) {
          count += task.moreAffected;
        });
      }

      return count;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.storeUsers = [];

      if (this.userService.isRH(this.session.data.authenticated.user)) {
        Ember.set(this.params, "currentRole", "ROLE_RH");
      } else {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.session.data.authenticated.user.roles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var role = _step.value;

            if (role != "ROLE_USER" && role != "ROLE_CONSULT") {
              Ember.set(this.params, "currentRole", role);
              break;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      if (this.displayMode == 'shortlist') {
        this.getTasks();
      }
    },
    getUsersWithTasks: function getUsersWithTasks() {
      return this.ajax.requestWithUrlParamPromise(_environment.default.APP.API_HOST + "/users/me/tasks", 'GET', this.params);
    },
    getTasksOfUser: function getTasksOfUser(id) {
      return this.ajax.requestWithUrlParamPromise(_environment.default.APP.API_HOST + "/users/me/userTasks", 'GET', "userId=" + id);
    },
    getTasks: function getTasks() {
      var _this = this;

      var path = _environment.default.APP.API_HOST + '/tasks/me/tasks';

      if (this.filter != 'all') {
        path = path + '?search=' + this.filter;
      }

      Ember.set(this, 'loading', true);
      this.ajax.requestWithUrlParamPromise(path, 'GET', this.params).then(function (myTasks) {
        Ember.set(_this, 'loading', false);
        var result = [];

        if (myTasks) {
          myTasks.forEach(function (task) {
            Ember.set(task.task, 'users', task.users);
            Ember.set(task.task, 'moreAffected', Object.keys(task.users).length);
            result.push(task.task);
          });
        }

        Ember.set(_this, 'tasks', result);

        if (result.length > 5) {
          Ember.set(_this, 'fullWidth', true);
        }
      }).catch(function () {
        Ember.set(_this, 'loading', false);
      });
    },
    actions: {
      selectFilter: function selectFilter(filter) {
        Ember.set(this, 'filter', filter);
        this.getTasks();
        Ember.set(this, 'showPossibleFilters', false);
      },
      goto_mytasks: function goto_mytasks() {
        this.router.transitionTo('board.task.my-tasks');
      },
      openTalent: function openTalent(talent) {
        this.router.transitionTo('board.talent.talent-detail.my-actions', talent.id);
      },
      onTriggerTask: function onTriggerTask(task) {},
      onFormTaskClick: function onFormTaskClick(talent, task) {
        this.router.transitionTo("board.talent.talent-detail", talent.id, {
          queryParams: {
            scrollToForms: true
          }
        });
      }
    },
    isTaskValidForManager: function isTaskValidForManager(user) {
      return user.manager && user.manager && user.manager.id === this.session.data.authenticated.user.id;
    },
    isTaskValidForRoleManager: function isTaskValidForRoleManager(user) {
      var valid = false;

      if (user.customFields) {
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = user.customFields[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var customField = _step2.value;

            if (customField.codeKey === 'officeManager' && customField.user_customfield && customField.user_customfield.fullValue == this.session.data.authenticated.user.id) {
              valid = true;
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      }

      return valid;
    },
    isTaskValidated: function isTaskValidated(validatedTasks, taskId) {
      if (!validatedTasks) return false;
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = validatedTasks[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var task = _step3.value;

          if (task.id == taskId) {
            return true;
          }
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      return false;
    },
    decorateTask: function decorateTask(task, refTasks) {
      if (!refTasks) return;
      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = refTasks[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var internTask = _step4.value;
          if (task.id != internTask.id) continue;
          Ember.set(task, "period", internTask.period);
          Ember.set(task, "name", internTask.name);
          Ember.set(task, "description", internTask.description);
          break;
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }
    }
  });

  _exports.default = _default;
});