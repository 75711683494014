define("teelt-frontend/components/talents/talent-detail", ["exports", "teelt-frontend/config/environment", "jquery", "teelt-frontend/utils/jquery-utils"], function (_exports, _environment, _jquery, _jqueryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    userService: Ember.inject.service('user-service'),
    fileUploadService: Ember.inject.service('file-upload'),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    staticlikeData: Ember.inject.service('staticlike-data'),
    uiUtils: Ember.inject.service('ui-utils'),
    customErrorService: Ember.inject.service('custom-error'),
    customLabelsService: Ember.inject.service('custom-labels-service'),
    defaultProfileImageUrl: _environment.default.APP.DEFAULT_PROFILE_IMAGE_URL,
    defaultOneToOneUrl: _environment.default.APP.API_HOST + "/events/oto",
    templatePath: _environment.default.APP.API_HOST + "/templates?forceAll=true&hideDisabled=true",
    buddyPath: _environment.default.APP.API_HOST + "/users",
    campaignPath: _environment.default.APP.API_HOST + "/events/campaigns",
    baseRoleCustomUsersPath: _environment.default.APP.API_HOST + "/roles",
    showRemoveConfirm: false,
    showMoreTalentInformations: false,
    showMoreTalentCustomFields: false,
    closeWithoutSaving: true,
    backupPositionName: '',
    collapseReferentBlock: true,
    collapseTemplateBlock: true,
    collapseInformationBlock: true,
    collapseCustomFieldBlock: true,
    talentTags: Ember.computed('talent', function () {
      if (this.talent.tags) {
        return this.talent.tags.join(', ');
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'contracts', [{
        key: 'cdi',
        name: this.intl.t('contracts.cdi')
      }, {
        key: 'cdd',
        name: this.intl.t('contracts.cdd')
      }, {
        key: 'internship',
        name: this.intl.t('contracts.internship')
      }, {
        key: 'apprenticeship',
        name: this.intl.t('contracts.apprenticeship')
      }, {
        key: 'professional_training_contract_parttime',
        name: this.intl.t('contracts.professional_training_contract_parttime')
      }, {
        key: 'professional_training_contract_fulltime',
        name: this.intl.t('contracts.professional_training_contract_fulltime')
      }, {
        key: 'mission',
        name: this.intl.t('contracts.mission')
      }, {
        key: 'freelance',
        name: this.intl.t('contracts.freelance')
      }, {
        key: 'external',
        name: this.intl.t('contracts.external')
      }, {
        key: 'secondment',
        name: this.intl.t('contracts.secondment')
      }, {
        key: 'cdd_less_than_3_months',
        name: this.intl.t('contracts.cdd_less_than_3_months')
      }, {
        key: 'cdd_more_than_3_months',
        name: this.intl.t('contracts.cdd_more_than_3_months')
      }, {
        key: 'senior',
        name: this.intl.t('contracts.senior')
      }]);

      if (this.userService.hasAnyRole(this.session.data.authenticated.user, 'ROLE_RH', 'ROLE_CUSTOM_OFFICE_MANAGER', 'ROLE_MRH')) {
        Ember.set(this, 'edit', true);
      } else {
        Ember.set(this, 'edit', false);
      }

      if (this.talent.profilePictureUrl) {
        if (this.talent.profilePictureUrl.includes('cloud.ovh.net')) {
          Ember.set(this, 'showDownload', true);
        }
      }

      var omIds = this.talent.officeManagerIds ? ("" + this.talent.officeManagerIds).split(';') : [];

      if (this.talent.officeManagers) {
        this.talent.officeManagers.map(function (om) {
          if (!omIds.includes(String(om.id))) {
            omIds.push(om.id);
          }
        });
      }

      var res = omIds.join(';');
      Ember.set(this.talent, 'officeManagerIds', res);
      this.checkCanImpersonate();

      if (this.talent.position) {
        Ember.set(this, 'backupPositionName', this.talent.position.name);
      } else {
        Ember.set(this.talent, 'position', {
          name: ''
        });
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var currentOneTOneEvents = {};

      if (this.talent.events) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.talent.events[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var event = _step.value;
            currentOneTOneEvents[event.id] = event;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      if (this.scrollToForms && this.$('#forms').length) {
        Ember.set(this, 'showFormsByDefault', true);
        (0, _jquery.default)("html").animate({
          scrollTop: this.$('#forms').offset().top - 100
        }, 400);
      }

      Ember.set(this, "currentOneTOneEvents", currentOneTOneEvents);
      this.initProfileImageDropzone();
      setTimeout(function () {
        _this2.eventBus.on('CLICK_EVENT', _this2, 'checkToCloseModal');
      }, 300);
      this.updateRoleCustomReferents();
      this.updateTalentRoles();
    },
    destroy: function destroy() {
      this.eventBus.off('CLICK_EVENT', this, 'checkToCloseModal');

      this._super.apply(this, arguments);
    },
    checkToCloseModal: function checkToCloseModal(e) {
      if (!(0, _jqueryUtils.default)().checkIfClickInElement(e, []) && !(0, _jqueryUtils.default)().isMonthArrowClicked(e)) {
        this.send('closeEdits');
      }
    },
    updateRoleCustomReferents: function updateRoleCustomReferents() {
      var _this3 = this;

      Ember.set(this, 'referentRoleCustoms', []);
      var roleCustomPath = _environment.default.APP.API_HOST + "/roles?consultRoles=true&referent=true";
      this.ajax.requestPromise(roleCustomPath, 'GET').then(function (result) {
        if (result) {
          result.forEach(function (role) {
            var path = _environment.default.APP.API_HOST + "/users/" + _this3.talent.id + "/custom-roles/" + role.roleType + "/referents";

            _this3.ajax.requestPromise(path, 'GET').then(function (result) {
              Ember.set(role, 'selectedUsers', result);
            });
          });
          Ember.set(_this3, 'referentRoleCustoms', result);
        }
      });
    },
    toggleDisplayPenIconOnEdit: function toggleDisplayPenIconOnEdit(element, willDisplay) {
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = element.parentElement.children[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var child = _step2.value;

          if (child.classList.contains('icon-svg')) {
            child.style.visibility = willDisplay ? 'visible' : 'hidden';
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    },
    initProfileImageDropzone: function initProfileImageDropzone() {
      var _this = this;

      var myDropzone = new Dropzone(".profile-image-dropzone-action", {
        url: 'https://www.teelt.io',
        uploadMultiple: false,
        maxFiles: 1,
        createImageThumbnails: false,
        addRemoveLinks: false,
        autoProcessQueue: false,
        acceptedFiles: 'image/jpeg,image/png,image/gif,image/jpg',
        addedfile: function addedfile(file) {
          _this.fileUploadService.signAndUpload(file, false).then(function (fileUrl) {
            Ember.set(_this.talent, 'profilePictureUrl', fileUrl);

            _this.talent.save();
          }).finally(function () {});
        }
      });
      Ember.set(this, 'profileImageDropzone', myDropzone);
    },
    checkCanImpersonate: function checkCanImpersonate() {
      var canImpersonate = false;

      if (this.userService.hasAnyRole(this.talent, 'ROLE_ADMIN', 'ROLE_RH')) {
        canImpersonate = false;
      } else if (this.userService.hasAnyRole(this.session.data.authenticated.user, "ROLE_RH", "ROLE_MRH")) {
        canImpersonate = true;
      }

      Ember.set(this, 'canImpersonate', canImpersonate);
    },
    getCustomTalentLabel: function getCustomTalentLabel(params) {
      var str = params[1].charAt(params[1].length - 1) === 's' ? 's' : '';
      var customLabels = this.customLabelsService.customLabels;

      if (customLabels && customLabels[params[0]]) {
        return customLabels[params[0]] + str;
      } else {
        return this.intl.t(params[1]);
      }
    },
    updateTalentRoles: function updateTalentRoles() {
      var talentRoles = this.userService.sortRolesByPriority(this.talent.roles).filter(function (role) {
        return role !== 'ROLE_USER' && role !== 'ROLE_CONSULT';
      });
      Ember.set(this, 'talentRoles', talentRoles);

      if (talentRoles.includes('ROLE_RH')) {
        Ember.set(this, 'isRH', true);
      }

      if (talentRoles.includes('ROLE_MANAGER')) {
        Ember.set(this, 'isManager', true);
      }
    },
    actions: {
      clean: function clean(attr) {
        Ember.set(this.talent, attr, undefined);
        this.send('saveTalent');
      },
      deleteMultiTemplate: function deleteMultiTemplate(index) {
        var subTemplates = this.talent.template.subTemplates;
        subTemplates.splice(index, 1);
        Ember.set(this.talent.template, 'subTemplates', subTemplates);
        var template = this.talent.template;
        Ember.set(this.talent, 'template', undefined);
        Ember.set(this.talent, 'template', template);
        this.send('saveTalent');
      },
      downloadImage: function downloadImage() {
        if (!this.talent.profilePictureUrl) {
          return;
        }

        var url = this.talent.profilePictureUrl;
        this.fileUploadService.getObjectBase64(url).then(function (res) {
          var binaryData = atob(res.data);
          var uint8Array = new Uint8Array(binaryData.length);

          for (var i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
          }

          var blob = new Blob([uint8Array]);
          var url = URL.createObjectURL(blob);
          var lienDeTelechargement = document.createElement('a');
          lienDeTelechargement.href = url;
          lienDeTelechargement.download = res.fileName.split("images/").join("");
          lienDeTelechargement.click();
          URL.revokeObjectURL(url);
        });
      },
      showMoreTalentInformations: function showMoreTalentInformations(customField) {
        if (customField) {
          Ember.set(this, 'showMoreTalentCustomFields', !this.showMoreTalentCustomFields);
        } else {
          Ember.set(this, 'showMoreTalentInformations', !this.showMoreTalentInformations);
        }
      },
      close: function close() {
        if (this.onClose) {
          this.onClose();
        } else {
          this.router.transitionTo('board.talents');
        }
      },
      remove: function remove() {
        Ember.set(this, "showRemoveConfirm", true);
      },
      removeConfirmation: function removeConfirmation(response) {
        var _this4 = this;

        if (response) {
          var talent = this.store.peekRecord('user', this.talent.get('id'));
          talent.deleteRecord();
          talent.save().then(function () {
            _this4.send('close');
          });
        }
      },
      invite: function invite() {
        Ember.set(this, "showInviteConfirm", true);
      },
      inviteConfirmation: function inviteConfirmation(response) {
        var _this5 = this;

        if (response) {
          this.userService.sendReinvitation(this.talent.username).then(function () {
            _this5.notify.info(_this5.intl.t('talent.messages.talent_invited'), {
              closeAfter: 5000
            });
          });
        }
      },
      showSendNotification: function showSendNotification() {
        Ember.set(this, 'showSendNotificationModal', true);
      },
      impersonate: function impersonate() {
        var _this6 = this;

        this.session.authenticate('authenticator:impersonate', this.talent.id).then(function () {
          if (_this6.userService.hasAnyRole(_this6.session.data.authenticated.user, 'ROLE_ADMIN')) {
            _this6.router.transitionTo("board.admin.company");
          } else if (_this6.userService.hasAnyRole(_this6.session.data.authenticated.user, 'ROLE_RH', 'ROLE_MANAGER', 'ROLE_CUSTOM_OFFICE_MANAGER', 'ROLE_ADMINISTRATIVE', 'ROLE_MRH', 'ROLE_CONSULT')) {
            _this6.router.transitionTo("board.talents").then(function () {
              window.location.reload();
            });
          } else {
            _this6.router.transitionTo("talent.home");
          }

          localStorage.setItem('restoreImpersonate', true);

          _this6.eventBus.trigger('VERIFY_RESTORE_IMPERSONATE');
        }, function (err) {});
      },
      interceptEnter: function interceptEnter(e) {
        if (e.keyCode == 13) {
          this.send('handleFocusOut', e);
          return false;
        }

        return true;
      },
      handleFocusOut: function handleFocusOut(e) {
        var value = e.target.outerText;
        var targetField = e.target.attributes.name.nodeValue.toString();
        var tmp = targetField.split('-');

        if (tmp.length == 1) {
          Ember.set(this.talent, targetField, value);
        } else if (tmp.length == 2) {
          var att = {};
          att[tmp[1]] = value;
          Ember.set(this.talent, tmp[0], att);
        }

        (0, _jquery.default)('#' + e.target.id).text(value);

        if (e.keyCode != 13) {
          this.send('saveTalent');
        }

        e.preventDefault();
        e.target.blur();
      },
      onSaveCustomField: function onSaveCustomField() {
        var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        this.send('closeEdits', force);
      },
      openEditPhone: function openEditPhone() {
        Ember.set(this, 'editPhoneNumber', true);
      },
      onBlurEditPhone: function onBlurEditPhone(e) {
        this.send('saveTalent');
        Ember.set(this, 'editPhoneNumber', false);
      },
      onKeyPressEditPhone: function onKeyPressEditPhone(e) {
        if (e.keyCode == 13) {
          this.send('onBlurEditPhone', e);
          return false;
        }

        return true;
      },
      closeEdits: function closeEdits(force) {
        if (force || this.showEdit == 'team') {
          this.send('saveTalent');
        }

        if (this.showEdit == 'trialPeriod' || this.editCustomField) {
          this.send('saveTalent');
        }

        if (this.showEdit && this.showEdit.startsWith('referentRoleCustom-')) {
          this.updateRoleCustomReferents();
        }

        Ember.set(this, 'editCustomField', false);
        Ember.set(this, 'showEdit', false);
        Ember.set(this, 'showTalentActions', false);

        if (this.editPhoneNumber) {
          this.send('onBlurEditPhone');
        }
      },
      saveTalent: function saveTalent() {
        var _this7 = this;

        if (!this.edit) return;
        var customFields = this.talent.company.customFields;

        if (this.talent.customFields) {
          this.talent.customFields.forEach(function (field) {
            if (field && field.type === 'multiple_choice' && field.user_customfield && field.user_customfield.fullValue == 'null') {
              Ember.set(field.user_customfield, 'fullValue', null);
            }
          });
        }

        this.talent.save().then(function () {
          if (_this7.session.data.authenticated.user.id == _this7.talent.id) {
            Ember.set(_this7.session.data.authenticated.user, "lang", _this7.talent.lang);
            Ember.set(_this7.session.data.authenticated.user, "roles", _this7.talent.roles);

            _this7.session.store.persist(_this7.session.data);

            _this7.intl.setLocale([_this7.talent.lang, 'en', 'fr']);
          }

          Ember.set(_this7.talent, 'company.customFields', customFields);

          if (_this7.talent.position) {
            Ember.set(_this7, 'backupPositionName', _this7.talent.position.name);
          }

          _this7.notify.info(_this7.intl.t('talent.modifications_saved'), {
            closeAfter: 3000
          });

          Ember.set(_this7, 'ShowEditTalentPopUp', false);

          if (_this7.updateRoleCustoms) {
            _this7.updateRoleCustomReferents();

            Ember.set(_this7, 'updateRoleCustoms', false);
          }

          _this7.updateTalentRoles();
        }).catch(function (err) {
          _this7.talent.rollbackAttributes();

          _this7.customErrorService.handleAjaxError(err);
        });
      },
      saveTalentPermission: function saveTalentPermission() {
        this.send('saveTalent');
        Ember.set(this, 'editTalentPermission', false);
      },
      cancelEdit: function cancelEdit(response) {
        if (!response) {
          this.talent.rollbackAttributes();

          if (this.talent.position) {
            Ember.set(this.talent.position, 'name', this.backupPositionName);
          } //try to rollback custom fields


          this.talent.customFields.forEach(function (field) {
            Ember.set(field.user_customfield, 'fullValue', field.user_customfield.fullValueAsString);
          });
          Ember.set(this, 'ShowEditTalentPopUp', false);
        }
      },
      canCloseWithoutSaving: function canCloseWithoutSaving(value) {
        Ember.set(this, 'closeWithoutSaving', value);
      },
      initCloseBlockEdit: function initCloseBlockEdit() {
        if (this.closeWithoutSaving) {
          this.send('cancelEdit', false);
        } else {
          Ember.set(this, 'showCancelPopUp', true);
        }
      },
      openEditManager: function openEditManager() {
        if (this.showEdit !== 'manager') {
          var path = this.userService.getUsersSearchPathForSelect('ROLE_MANAGER');
          Ember.set(this, "managerPath", path);
          Ember.set(this, "showEdit", 'manager');
        }
      },
      generateManagerName: function generateManagerName(result) {
        return this.userService.getTalentName(result);
      },
      onManagerChanged: function onManagerChanged(result) {
        var _this8 = this;

        if (!result.key) return;
        Ember.set(this.talent, 'manager', {
          id: result.key
        });
        this.send('saveTalent');
        setTimeout(function () {
          Ember.set(_this8, 'showEdit', false);
        });
      },
      openEditOfficeManager: function openEditOfficeManager() {
        if (this.showEdit !== 'officeManager') {
          var omIds = [];

          if (this.talent.officeManagers) {
            omIds = this.talent.officeManagers.map(function (om) {
              return {
                key: om.id
              };
            });
          }

          Ember.set(this, 'officeManagersSelected', omIds);
          var path = this.userService.getUsersSearchPathForSelect('ROLE_CUSTOM_OFFICE_MANAGER');
          Ember.set(this, "officeManagerPath", path);
          Ember.set(this, "showEdit", 'officeManager');
        }
      },
      onOfficeManagerChanged: function onOfficeManagerChanged(results) {
        if (!results || !results.length) return;
        var omIds = results.map(function (result) {
          return result.key;
        });
        Ember.set(this.talent, 'officeManagerIds', omIds.join(';'));
        this.send('saveTalent');
      },
      openEditBuddy: function openEditBuddy() {
        if (this.showEdit !== 'buddy') {
          Ember.set(this, "showEdit", 'buddy');
        }
      },
      onBuddyChanged: function onBuddyChanged(result) {
        var _this9 = this;

        if (!result.key) return;
        Ember.set(this.talent, 'buddy', {
          id: result.key,
          username: result.label
        });
        this.send('saveTalent');
        setTimeout(function () {
          Ember.set(_this9, 'showEdit', false);
        });
      },
      openEditReferentRoleCustom: function openEditReferentRoleCustom(id) {
        var referentRoleCustomId = 'referentRoleCustom-' + id;

        if (this.showEdit !== referentRoleCustomId) {
          var path = _environment.default.APP.API_HOST + "/roles?consultRoles=true&referent=true";
          Ember.set(this, "referentRoleCustomPath", path);
          Ember.set(this, "showEdit", referentRoleCustomId);
        }
      },
      onReferentRoleCustomChanged: function onReferentRoleCustomChanged(roleType, result) {
        var _this10 = this;

        var userRoleCustomReferents = this.talent.userRoleCustomReferents || [];
        var newReferentIds = result.map(function (res) {
          return parseInt(res.key);
        });
        result.forEach(function (res) {
          var referentId = parseInt(res.key);
          var existingReferent = userRoleCustomReferents.find(function (userRoleCustomReferent) {
            return userRoleCustomReferent.roleCustom.roleType === roleType && userRoleCustomReferent.referentId === referentId;
          });

          if (!existingReferent) {
            userRoleCustomReferents.push({
              roleCustom: {
                id: roleType.split('-')[1],
                roleType: roleType
              },
              referentId: referentId,
              userId: _this10.talent.id
            });
          }
        });
        userRoleCustomReferents = userRoleCustomReferents.filter(function (userRoleCustomReferent) {
          return userRoleCustomReferent.roleCustom.roleType !== roleType || newReferentIds.includes(userRoleCustomReferent.referentId);
        });
        Ember.set(this.talent, 'userRoleCustomReferents', userRoleCustomReferents); // Sauvegarde les changements

        this.send('saveTalent');
      },
      deleteRoleCustomReferent: function deleteRoleCustomReferent(referentId, roleType) {
        var userRoleCustomReferents = this.talent.userRoleCustomReferents;
        var userRoleCustomReferent = userRoleCustomReferents.find(function (userRoleCustomReferent) {
          return userRoleCustomReferent.roleCustom.roleType == roleType && userRoleCustomReferent.referentId == referentId;
        });
        userRoleCustomReferents = userRoleCustomReferents.filter(function (URCR) {
          return URCR !== userRoleCustomReferent;
        });
        Ember.set(this.talent, 'userRoleCustomReferents', userRoleCustomReferents);
        Ember.set(this, 'updateRoleCustoms', true);
        this.send('saveTalent');
        Ember.set(this, 'showEdit', false);
      },
      editCustomField: function editCustomField(id) {
        var _this11 = this;

        setTimeout(function () {
          Ember.set(_this11, 'editCustomField', id);
        });
      },
      validateCustom: function validateCustom() {
        if (this.editCustomField) {
          Ember.set(this, 'editCustomField', false);
          this.send('saveTalent');
        }
      },
      onTemplateChanged: function onTemplateChanged(value) {
        var _this12 = this;

        if (value) {
          Ember.set(this.talent, 'template', {
            id: value.key
          });
        } else {
          Ember.set(this.talent, 'template', null);
        }

        this.send('saveTalent');
        setTimeout(function () {
          Ember.set(_this12, 'showEdit', false);
        });
      },
      onMultiTemplateChanged: function onMultiTemplateChanged(index, value) {
        var _this13 = this;

        var subTemplates = this.talent.template.subTemplates;

        if (value) {
          subTemplates[index] = {
            template: {
              id: value.key
            }
          };
        } else {
          subTemplates[index] = null;
        }

        this.send('saveTalent');
        setTimeout(function () {
          Ember.set(_this13, 'showEdit', false);
        });
      },
      onCampaignChanged: function onCampaignChanged(value) {
        var _this14 = this;

        if (value) {
          Ember.set(this.talent, 'campaign', {
            id: value.key
          });
        } else {
          Ember.set(this.talent, 'campaign', null);
        }

        this.send('saveTalent');
        setTimeout(function () {
          Ember.set(_this14, 'showEdit', false);
        });
      },
      openContractEdit: function openContractEdit() {
        if (this.showEdit !== 'contract') {
          Ember.set(this, 'showEdit', 'contract');
          setTimeout(function () {
            (0, _jquery.default)('.select-contract').formSelect();
          });
        }
      },
      onContractChanged: function onContractChanged(value) {
        Ember.set(this.talent, 'contract', {
          name: value
        });
        this.send('saveTalent');
        Ember.set(this, 'showEdit', false);
      },
      openEditTeam: function openEditTeam() {
        var _this15 = this;

        if (this.showEdit !== 'team') {
          if (!this.talent.team) {
            Ember.set(this.talent, 'team', {
              name: ''
            });
          }

          Ember.set(this, 'showEdit', 'team');
          setTimeout(function () {
            _this15.store.findAll('statics/team').then(function (teams) {
              _this15.uiUtils.initAutocomplete((0, _jquery.default)('#autocomplete-team'), teams, "name", function () {
                Ember.set(_this15, "newTeam", false);
              });
            });
          });
        }
      },
      onTeamChanged: function onTeamChanged(e) {
        Ember.set(this, 'newTeam', true);
      },
      openLang: function openLang() {
        if (this.showEdit !== 'lang') {
          Ember.set(this, 'showEdit', 'lang');
          setTimeout(function () {
            (0, _jquery.default)('.select-lang').formSelect();
          });
        }
      },
      onLangChanged: function onLangChanged(value) {
        Ember.set(this.talent, "lang", value);
        this.send('saveTalent');

        if (this.session.data.authenticated.user.id == this.talent.id) {
          Ember.set(this.session.data.authenticated.user, "lang", value);
          this.session.store.persist(this.session.data);
          this.intl.setLocale([value, 'en', 'fr']);
        }

        Ember.set(this, 'showEdit', false);
      },
      openFileDialog: function openFileDialog() {
        this.profileImageDropzone.hiddenFileInput.click();
      },
      doNothing: function doNothing() {},
      openTalentActions: function openTalentActions() {
        Ember.set(this, 'showTalentActions', !this.showTalentActions);
      },
      openAddManagerPopup: function openAddManagerPopup() {
        var managerToAdd = {
          roles: ['ROLE_USER', 'ROLE_MANAGER'],
          company: this.session.data.authenticated.user.company,
          lang: this.session.data.authenticated.user.lang
        };
        Ember.set(this, 'managerToAdd', managerToAdd);
        Ember.set(this, 'showAddManagerPopup', true);
      },
      addNewManager: function addNewManager() {
        var _this16 = this;

        this.validateManagerForm();
        this.managerToAdd.proEmail = this.managerToAdd.username;
        this.checkExistingProEmail();
        if (this.managerProEmailAlreadyExistsError) return;
        var manager = this.store.createRecord('user', this.managerToAdd);
        manager.save().then(function (talent) {
          if (manager.invite) {
            _this16.inviteManager(talent);
          }

          Ember.set(_this16.talent, 'manager', {
            id: talent.id
          });

          _this16.send('saveTalent');

          Ember.set(_this16, 'showAddManagerPopup', false);
        }).catch(function (err) {
          _this16.customErrorService.handleAjaxError(err);
        });
      },
      luccaSync: function luccaSync() {
        var _this17 = this;

        var path = _environment.default.APP.API_HOST + '/integrations/lucca/users/' + this.talent.id;
        this.ajax.requestPromise(path, 'POST').then(function () {
          Ember.set(_this17.talent, 'lastLuccaSync', new Date());

          _this17.notify.info(_this17.intl.t('settings.integrations.lucca.messages.sync_successfull'), {
            closeAfter: 5000
          });
        }).catch(function (err) {
          var response = err.responseJSON;

          if (response) {
            if (response.message) {
              _this17.checkLuccaSettingsErrors(response.message);

              return;
            }
          }

          _this17.notify.error(_this17.intl.t('settings.integrations.lucca.messages.sync_unsuccessfull'), {
            closeAfter: 5000
          });
        });
      },
      slackSync: function slackSync() {
        var _this18 = this;

        var path = _environment.default.APP.API_HOST + '/integrations/slack/users/' + this.talent.id;
        this.ajax.requestPromise(path, 'POST').then(function () {
          Ember.set(_this18.talent, 'syncSlack', true);

          _this18.notify.info(_this18.intl.t('settings.integrations.lucca.messages.sync_successfull'), {
            closeAfter: 5000
          });
        }).catch(function () {
          _this18.notify.error(_this18.intl.t('settings.integrations.lucca.messages.sync_unsuccessfull'), {
            closeAfter: 5000
          });
        });
      },
      deleteManager: function deleteManager() {
        Ember.set(this.talent, 'manager', null);
        this.send('saveTalent');
      },
      deleteOfficeManager: function deleteOfficeManager(id) {
        var omIds = this.talent.officeManagerIds.split(";");
        var newOmIds = [];
        var newOm = [];
        omIds.forEach(function (element) {
          if (element != id) newOmIds.push(element);
        });
        this.talent.officeManagers.map(function (om) {
          if (om.id !== id) newOm.push(om);
        });
        Ember.set(this.talent, 'officeManagerIds', newOmIds.join(';'));
        Ember.set(this.talent, 'officeManagers', newOm);
        this.send('saveTalent');
      },
      deleteBuddy: function deleteBuddy() {
        Ember.set(this.talent, 'buddy', null);
        this.send('saveTalent');
      },
      OnTalentPositionClick: function OnTalentPositionClick() {
        var _this19 = this;

        setTimeout(function () {
          _this19.store.findAll('statics/position').then(function (positions) {
            _this19.uiUtils.initAutocomplete((0, _jquery.default)('#autocomplete-position'), positions, "name", function () {});
          });
        });
      },
      onPositionChanged: function onPositionChanged() {
        var _this20 = this;

        setTimeout(function () {
          Ember.set(_this20, 'showEdit', false);

          _this20.send('saveTalent');
        }, 500);
      },
      onTalentPositionNameBlur: function onTalentPositionNameBlur(e) {
        this.send('handleFocusOut', e);

        if (typeof this.talent.position === "undefined") {
          document.getElementById('talent-position').innerHTML = this.intl.t('talent.position.label');
        } else if (this.talent.position.name === "") {
          document.getElementById('talent-position').innerHTML = this.intl.t('talent.position.label');
        }
      },
      newMultiTemplate: function newMultiTemplate() {
        var _this21 = this;

        if (this.talent.template.type != 'multi') {
          var existingTemplate = this.talent.template;
          var _template = {
            type: 'multi',
            subTemplates: []
          };

          if (existingTemplate) {
            _template.subTemplates.push({
              template: existingTemplate,
              templateAssignationDate: this.talent.templateAssignationDate
            });
          }

          _template.subTemplates.push({});

          Ember.set(this.talent, 'template', _template);
        } else {
          this.talent.template.subTemplates.push({});
        }

        var template = this.talent.template;
        Ember.set(this.talent, 'template', undefined);
        setTimeout(function () {
          return Ember.set(_this21.talent, 'template', template);
        });
      },
      goToExperience: function goToExperience(templateId) {
        this.router.transitionTo("board.experience.experience-edit", templateId);
      },
      switchRole: function switchRole(role, event) {
        var _this22 = this;

        if (role === 'rh') {
          Ember.set(this, 'isRH', event.target.checked);
          role = 'ROLE_RH';
        }

        if (role === 'manager') {
          Ember.set(this, 'isManager', event.target.checked);
          role = 'ROLE_MANAGER';
        }

        var roles = this.talent.roles;
        var index = roles.indexOf(role);

        if (index >= 0) {
          roles.splice(index, 1);
        } else {
          roles.push(role);
        }

        setTimeout(function () {
          Ember.set(_this22.talent, 'roles', roles);

          _this22.updateTalentRoles();

          _this22.send('saveTalent');
        });
      }
    },
    checkExistingProEmail: function checkExistingProEmail() {
      var _this23 = this;

      Ember.set(this, 'managerProEmailAlreadyExistsError', false);

      if (this.validateEmail(this.managerToAdd.proEmail)) {
        this.store.query('user', {
          username: this.managerToAdd.proEmail
        }).then(function (users) {
          if (users && users.length > 0) {
            Ember.set(_this23, 'managerProEmailAlreadyExistsError', true);
          }
        });
      }
    },
    validateManagerForm: function validateManagerForm() {
      Ember.set(this, 'managerProEmailIsNull', false);
      var isOk = true;

      if (!this.managerToAdd.username) {
        Ember.set(this, 'managerProEmailIsNull', true);
        isOk = false;
      }

      return isOk;
    },
    validateEmail: function validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    inviteManager: function inviteManager(manager) {
      var _this24 = this;

      var path = _environment.default.APP.API_HOST + "/notifications/systems/INVIT/users/" + manager.id;
      this.ajax.requestWithBodyPromise(path, 'POST').then(function (result) {
        if (result) {
          _this24.notify.info(_this24.intl.t('talent.messages.talent_invited', {
            talent: _this24.getCustomTalentLabel(['talentLabel', 'word.talent'])
          }), {
            closeAfter: 5000
          });
        } else {
          _this24.notify.error(_this24.intl.t('talent.errors.notification_failed'), {
            closeAfter: 5000
          });
        }
      }).catch(function (err) {
        _this24.notify.error(_this24.intl.t('talent.errors.notification_failed'), {
          closeAfter: 5000
        });
      });
    },
    checkLuccaSettingsErrors: function checkLuccaSettingsErrors(errorMessage) {
      var _this25 = this;

      if (!errorMessage) {
        this.notify.error(this.intl.t('settings.integrations.lucca.messages.sync_unsuccessfull'), {
          closeAfter: 5000
        });
        return;
      }

      var elementsIds = errorMessage.split(",");
      elementsIds.forEach(function (id) {
        if (id.startsWith('talent-')) {
          Ember.set(_this25, 'collapseInformationBlock', false);
          Ember.run.later(function () {
            var element = document.getElementById(id);

            if (element) {
              var text = element.innerText;

              if (["", "null", "-"].includes(text)) {
                element.classList.add("error");
                element.addEventListener("focus", function () {
                  element.classList.remove("error");
                });
              }
            }
          });
        } else if (id.startsWith('custom-field-')) {
          Ember.set(_this25, 'collapseCustomFieldBlock', false);
          Ember.set(_this25, 'showMoreTalentCustomFields', true);
          Ember.run.later(function () {
            var element = document.getElementById(id);

            if (element) {
              var text = element.innerText;

              if (["", "null", "-"].includes(text)) {
                element.classList.add("error");
                element.addEventListener("focus", function () {
                  element.classList.remove("error");
                });
              }
            }
          });
        }

        if (id.startsWith('customfield-') || id.startsWith('talent-')) {
          _this25.notify.error(_this25.intl.t('settings.integrations.lucca.messages.sync_impossible.' + id), {
            closeAfter: 5000
          });
        }
      });
    }
  });

  _exports.default = _default;
});