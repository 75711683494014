define("teelt-frontend/components/form/forms-list-talent-element", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    userService: Ember.inject.service(),
    session: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var user = this.session.data.authenticated.user;
      Ember.set(this, 'isRH', this.userService.isRH(user));
      Ember.set(this, 'isManager', this.userService.isManager(user));
      Ember.set(this, 'isOfficeManager', this.userService.isOfficeManager(user));
      Ember.set(this, 'canViewResponses', false);
      var canViewRoles = ['ROLE_RH', 'ROLE_CUSTOM_OFFICE_MANAGER'];

      if (this.form.viewResultRoles) {
        canViewRoles = canViewRoles.concat(this.form.viewResultRoles);
      }

      if (this.userService.hasAnyRole(user, canViewRoles)) {
        Ember.set(this, 'canViewResponses', true);
      }

      var path = _environment.default.APP.API_HOST + '/forms/' + this.form.id + '/completedFor/' + this.talentId;
      this.ajax.requestPromise(path, 'GET').then(function (res) {
        if (res.completed) {
          Ember.set(_this, 'completed', true);
        } else {
          Ember.set(_this, 'completed', false);
        }

        if (res.deleted) {
          Ember.set(_this, 'deleted', true);
        } else {
          Ember.set(_this, 'deleted', false);
        }

        Ember.set(_this, 'result', res.result);
        Ember.set(_this, 'maxResult', res.maxResult);
      });
    },
    actions: {
      startForm: function startForm() {
        if (this.startForm) this.startForm(this.form);
      },
      showResponses: function showResponses() {
        if (this.showResponses) this.showResponses(this.form);
      },
      deleteResponses: function deleteResponses() {
        var _this2 = this;

        if (this.showResponses) {
          var path = _environment.default.APP.API_HOST + '/forms/' + this.form.id + '/deleteresponse/' + this.talentId;
          this.ajax.requestPromise(path, 'DELETE').then(function () {
            Ember.set(_this2, 'deleted', true);
          });
        }
      }
    }
  });

  _exports.default = _default;
});