define("teelt-frontend/templates/components/utils/basic-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O48cJOFZ",
    "block": "{\"symbols\":[\"prop\",\"index\"],\"statements\":[[7,\"div\"],[11,\"class\",\"select-wrapper\"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"opened\"]]],null],true]],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"class\",\"type\",\"readonly\",\"value\"],[\"select-dropdown dropdown-trigger\",\"text\",\"true\",[23,[\"selectedLabel\"]]]]],false],[0,\"\\n    \"],[7,\"ul\"],[12,\"class\",[28,[\"dropdown-content select-dropdown \",[27,\"if\",[[23,[\"opened\"]],\"forceOpen\"],null]]]],[11,\"tabindex\",\"0\"],[9],[0,\"\\n        \"],[7,\"li\"],[3,\"action\",[[22,0,[]],\"select\",[23,[\"filter\",\"allKey\"]]],[[\"bubbles\"],[false]]],[9],[7,\"span\"],[9],[4,\"if\",[[23,[\"filter\",\"allLabel\"]]],null,{\"statements\":[[1,[23,[\"filter\",\"allLabel\"]],false]],\"parameters\":[]},{\"statements\":[[1,[27,\"t\",[\"word.all\"],null],false]],\"parameters\":[]}],[10],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"filter\",\"values\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\"],[3,\"action\",[[22,0,[]],\"select\",[22,1,[\"key\"]]],[[\"bubbles\"],[false]]],[9],[7,\"span\"],[9],[1,[22,1,[\"label\"]],false],[10],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"svg\"],[11,\"class\",\"caret\"],[11,\"height\",\"24\"],[11,\"viewBox\",\"0 0 24 24\"],[11,\"width\",\"24\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[9],[7,\"path\"],[11,\"d\",\"M7 10l5 5 5-5z\"],[9],[10],[7,\"path\"],[11,\"d\",\"M0 0h24v24H0z\"],[11,\"fill\",\"none\"],[9],[10],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/basic-filter.hbs"
    }
  });

  _exports.default = _default;
});