define("teelt-frontend/templates/components/icon/email-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "70ocSTxx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"width\",\"20\"],[11,\"height\",\"21\"],[11,\"viewBox\",\"0 0 20 21\"],[11,\"fill\",\"none\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[9],[0,\"\\n\"],[7,\"circle\"],[11,\"cx\",\"10\"],[11,\"cy\",\"10.8711\"],[11,\"r\",\"10\"],[11,\"fill\",\"#F4F4F4\"],[9],[10],[0,\"\\n\"],[7,\"path\"],[11,\"d\",\"M13 8.37109H7C6.86739 8.37109 6.74021 8.42377 6.64645 8.51754C6.55268 8.61131 6.5 8.73849 6.5 8.87109V12.8711C6.5 13.0037 6.55268 13.1309 6.64645 13.2246C6.74021 13.3184 6.86739 13.3711 7 13.3711H13C13.1326 13.3711 13.2598 13.3184 13.3536 13.2246C13.4473 13.1309 13.5 13.0037 13.5 12.8711V8.87109C13.5 8.73849 13.4473 8.61131 13.3536 8.51754C13.2598 8.42377 13.1326 8.37109 13 8.37109V8.37109ZM12.45 8.87109L10 10.5661L7.55 8.87109H12.45ZM7 12.8711V9.09859L9.8575 11.0761C9.89935 11.1051 9.94907 11.1207 10 11.1207C10.0509 11.1207 10.1007 11.1051 10.1425 11.0761L13 9.09859V12.8711H7Z\"],[11,\"fill\",\"#2D65E9\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/icon/email-svg.hbs"
    }
  });

  _exports.default = _default;
});