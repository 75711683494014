define("teelt-frontend/components/menu/slider-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['slider-menu'],
    doTransition: false,
    router: Ember.inject.service(),
    actions: {
      switchTo: function switchTo(index) {
        Ember.set(this, 'selected', index);

        if (index >= 0 && this.onSelected) {
          this.onSelected(this.selected, this.menu.objectAt(this.selected));
        }
      }
    },
    selectedObserver: Ember.observer('selected', function () {
      if (this.menu.objectAt(this.selected).queryParams) {
        this.router.transitionTo(this.menu.objectAt(this.selected).route, {
          queryParams: this.menu.objectAt(this.selected).queryParams
        });
      } else if (this.menu.objectAt(this.selected).route) {
        this.router.transitionTo(this.menu.objectAt(this.selected).route);
      }
    })
  });

  _exports.default = _default;
});