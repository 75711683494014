define("teelt-frontend/components/talents/talent-detail-actions-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["talent-detail-actions-block"]
  });

  _exports.default = _default;
});