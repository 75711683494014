define("teelt-frontend/components/notification/notification-email-content", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['notification-content-block'],
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    staticlikeData: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    customErrorService: Ember.inject.service('custom-error'),
    maxTotalAttachmentSize: 10485760,
    // 10Mo
    getEmailNotification: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.setEmailFromContents();
      Ember.set(this, 'showEditor', true);

      if (this.notification.disabled) {
        this.send('onDisable');
      }

      Ember.set(this, "showEmailContent", this.router.currentRouteName === "board.notification.send-notification");

      if (this.withoutHeader) {
        this.send('toggleShowEmailContent');
      }

      if (this.session.data.authenticated.user.company.htmlSourceCodeAllowed) {
        Ember.set(this, 'htmlSourceCodeAllowed', true);

        if (this.email.sourceCode) {
          Ember.set(this, 'isSourceMode', true);
        }
      }
    },
    initIframeContent: function initIframeContent() {
      var _this = this;

      var content = encodeURIComponent(this.email.creationCode);
      var url = "data:text/html;charset=utf-8," + content;
      Ember.set(this, 'notificationIframeURL', url);
      setTimeout(function () {
        var iframe = _this.$('iframe')[0];

        if (!iframe) return;
        var context = iframe.contentWindow.document;
        var $body = (0, _jquery.default)('body', context); //$body.html(this.email.body);

        var style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = "\n          .templateMarker {\n              display: inline-block;\n              padding: .25em .4em;\n              font-size: 75%;\n              font-weight: 700;\n              line-height: 1;\n              text-align: center;\n              white-space: nowrap;\n              vertical-align: baseline;\n              border-radius: .25rem;\n              background-color: #ffc107;\n          }\n\n          p {\n              margin:0\n          }\n\n          body {\n              padding:20px;\n          }\n      ";
        $body.append(style);
      });
    },
    setEmailFromContents: function setEmailFromContents() {
      var emailContent = this.getEmailContentFromContents();

      if (emailContent) {
        Ember.set(this, "email", emailContent);
      } else {
        var _emailContent = {
          contentType: "EMAIL",
          subject: "",
          body: "",
          disabled: true,
          replyTo: "",
          officeId: null,
          tagId: null,
          cc: "",
          sourceCode: "",
          creationCode: ""
        };
        this.notification.contents.push(_emailContent);
        Ember.set(this, "email", _emailContent);
      }
    },
    getEmailContentFromContents: function getEmailContentFromContents() {
      if (!this.notification.contents) return;

      for (var i = 0; i < this.notification.contents.length; i++) {
        var content = this.notification.contents[i];

        if (content.contentType === "EMAIL" && (this.tagMode && content.tagId == (this.showContentForTagId ? this.showContentForTagId : null) || !this.tagMode && content.officeId == (this.showContentForOfficeId ? this.showContentForOfficeId : null))) {
          return this.notification.contents[i];
        }
      }
    },
    onAttachmentProgress: function onAttachmentProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'attachmentProgress', progress);
      }
    },
    actions: {
      toggleShowEmailContent: function toggleShowEmailContent() {
        var _this2 = this;

        Ember.set(this, 'showEmailContent', !this.showEmailContent);

        if (this.showEmailContent && this.displayMode) {
          Ember.run.later(function () {
            _this2.initIframeContent();
          }, 0);
        }
      },
      onActivate: function onActivate() {
        if (!this.notification.contents) return;
        this.notification.contents.forEach(function (c) {
          if (c.contentType === "EMAIL") {
            Ember.set(c, "disabled", false);
          }
        });
      },
      onDisable: function onDisable() {
        if (!this.notification.contents) return;
        this.notification.contents.forEach(function (c) {
          if (c.contentType === "EMAIL") {
            Ember.set(c, "disabled", true);
          }
        });
      },
      onBodyChanged: function onBodyChanged() {
        this.get('getEmailNotification').setData(this.email);

        if (this.onBodyChanged) {
          this.onBodyChanged();
        }
      },
      onSourceCodeChanged: function onSourceCodeChanged() {
        this.get('getEmailNotification').setData(this.email);

        if (this.onSourceCodeChanged) {
          this.onSourceCodeChanged();
        }
      },
      onAttachmentChanged: function onAttachmentChanged(file) {
        var _this3 = this;

        if (!file) return;

        if (this.getCurrentTotalAttachmentSize() + file.size > this.maxTotalAttachmentSize) {
          this.customErrorService.alert('error_file_maxsize_reached');
          return;
        }

        this.fileUploadService.signAndUpload(file, true, function (e) {
          return _this3.onAttachmentProgress(e);
        }).then(function (fileUrl) {
          var attachments = _this3.email.attachments;

          if (!attachments) {
            attachments = [];
          }

          attachments.push({
            fileName: file.name,
            fileUrl: fileUrl,
            size: file.size
          });
          Ember.set(_this3.email, "attachments", null);
          Ember.set(_this3.email, "attachments", attachments);
          Ember.set(_this3, 'attachmentProgress', null);
        });
      },
      openAttachment: function openAttachment(url) {
        var _this4 = this;

        var downloadWaitingUrl = window.location.protocol + "//" + window.location.host + "/downloading";
        var tabOpen = window.open(downloadWaitingUrl, 'newtab');
        this.fileUploadService.getObjectUrl(url).then(function (data) {
          var url = data.url + "&inline";
          tabOpen.location = url;
        }).catch(function (err) {
          _this4.customErrorService.handleAjaxError(err);
        });
      },
      deleteAttachment: function deleteAttachment(index) {
        var attachments = this.email.attachments;
        attachments.splice(index, 1);
        Ember.set(this.email, "attachments", null);
        Ember.set(this.email, "attachments", attachments);
      }
    },
    getCurrentTotalAttachmentSize: function getCurrentTotalAttachmentSize() {
      var attachments = this.email.attachments;

      if (attachments) {
        var totalSize = 0;
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = attachments[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var attachment = _step.value;
            totalSize += attachment.size;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return totalSize;
      }

      return 0;
    }
  });

  _exports.default = _default;
});