define("teelt-frontend/components/utils/flag-selector", ["exports", "jquery", "teelt-frontend/utils/string-util"], function (_exports, _jquery, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['flag-selector'],
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'uuid', (0, _stringUtil.default)().generateUUID());

      if (!this.flags) {
        Ember.set(this, 'flags', ['fr', 'us', 'de', 'es', 'it', 'pt', 'pl']);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var data = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.flags[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var flag = _step.value;
          data.push({
            id: flag,
            text: flag
          });
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var formatFlag = function formatFlag(country) {
        if (country.id) {
          return (0, _jquery.default)('<span class="flag-icon flag-icon-' + country.id.toLowerCase() + '"></span>');
        }
      };

      (0, _jquery.default)('.flag-select-' + this.uuid).select2({
        templateResult: formatFlag,
        templateSelection: formatFlag,
        data: data
      });

      if (this.defaultValue) {
        (0, _jquery.default)('.flag-select-' + this.uuid).val(this.defaultValue);
        (0, _jquery.default)('.flag-select-' + this.uuid).trigger('change');
      }

      (0, _jquery.default)('.flag-select-' + this.uuid).on('select2:select', function (e) {
        var data = e.params.data;

        if (_this.onSelect) {
          _this.onSelect(data.id);
        }
      });
    }
  });

  _exports.default = _default;
});