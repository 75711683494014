define("teelt-frontend/templates/components/utils/all-done-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P1EDzvlH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"center\"],[9],[0,\"\\n  \"],[7,\"img\"],[12,\"src\",[21,\"imageSrc\"]],[11,\"alt\",\"All done\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"all-done-title\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"all-done-description\"],[9],[1,[21,\"description\"],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/all-done-image.hbs"
    }
  });

  _exports.default = _default;
});