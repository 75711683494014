define("teelt-frontend/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E+j30AzW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"login-page-container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"id\",\"login-page\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"video-container\"],[9],[0,\"\\n      \"],[7,\"video\"],[11,\"id\",\"video\"],[11,\"autoplay\",\"\"],[11,\"loop\",\"\"],[11,\"muted\",\"\"],[11,\"plays-inline\",\"\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"center-div\"],[9],[0,\"\\n      \"],[1,[21,\"outlet\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/login.hbs"
    }
  });

  _exports.default = _default;
});