define("teelt-frontend/templates/board/talent/talent-by-period", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zrks7Uyl",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"id\",\"arriving-today\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"section-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"g-section-title\"],[9],[0,\"\\n            \"],[7,\"h1\"],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"taskName\"]]],null,{\"statements\":[[0,\"              \"],[7,\"p\"],[9],[1,[21,\"taskName\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[1,[27,\"talents/talent-by-period-list\",null,[[\"dateFilter\",\"startDate\",\"endDate\",\"targetDateField\",\"filterType\",\"templateId\",\"taskId\"],[[23,[\"dateFilter\"]],[23,[\"startDate\"]],[23,[\"endDate\"]],[23,[\"targetDateField\"]],[23,[\"filterType\"]],[23,[\"templateId\"]],[23,[\"taskId\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/talent/talent-by-period.hbs"
    }
  });

  _exports.default = _default;
});