define("teelt-frontend/components/talents/talents-periods-slick-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talents-periods-slick-card'],
    classNameBindings: ['highlight:highlight'],
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.period.key == "FINISH") {
        Ember.set(this, 'actionLabel', this.intl.t("talent.back_to_home"));
      }
    },
    actions: {
      onActionTriggered: function onActionTriggered() {
        if (this.actionAction) {
          this.actionAction(this.period);
        }
      }
    }
  });

  _exports.default = _default;
});