define("teelt-frontend/templates/components/notification/notification-template-assign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AJY7LORf",
    "block": "{\"symbols\":[],\"statements\":[[0,\" \"],[7,\"div\"],[11,\"class\",\"g-window\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"board.notification.notification-detail\",[23,[\"notification\",\"id\"]]],[[\"class\"],[\"g-window-close\"]],{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"material-icons grey-text\"],[9],[0,\"clear\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[27,\"template/templates-list\",null,[[\"canTriggerTemplate\",\"selectedIds\"],[true,[23,[\"notification\",\"selectedTemplateIds\"]]]]],false],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"controls center\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-raised btn-primary waves-effect waves-light\"],[11,\"type\",\"button\"],[3,\"action\",[[22,0,[]],\"save\"]],[9],[1,[27,\"t\",[\"word.save\"],null],false],[10],[0,\"\\n        \"],[4,\"link-to\",[\"board.notification.notification-detail\",[23,[\"notification\",\"id\"]]],[[\"class\"],[\"btn btn-raised btn-white\"]],{\"statements\":[[1,[27,\"t\",[\"word.cancel\"],null],false]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/notification/notification-template-assign.hbs"
    }
  });

  _exports.default = _default;
});